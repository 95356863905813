import React from 'react';
import { HomeClaimsFNOL } from '../../../../helpers/ageasTelephoneNumbers';
import FaqP from '../../FaqP';

export const title = 'Details not recognised e.g. policy number, date of birth';
export const content = (
  <>
    <FaqP>
      If your details are not recognised, we unfortunately cannot send you the
      authentication code to complete necessary security checks. Below are
      common reasons why your details may not be recognised and advice on things
      to check.
    </FaqP>
    <FaqP>
      <strong>Invalid policy number</strong> - If the details you have provided
      do not match our records, please check you have entered all characters
      correctly and try again. If you think they are correct, please call us on{' '}
      <HomeClaimsFNOL />.
    </FaqP>
    <FaqP>
      <strong>Invalid date of birth</strong> - Please make sure you are entering
      the date of birth as shown on your policy schedule.
    </FaqP>
    <FaqP>
      If you are still having difficulty reporting your claim online, please
      contact our claims team on <HomeClaimsFNOL /> where one of our agents will
      be able to assist you.
    </FaqP>
  </>
);

export const pair = { title, content };
