import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const plumbingFixQuestion =
  'Will you send out a plumber to fix the leak?';
const PlumbingFixContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        Repairs to the pipe work, water or heating system of your home are often
        not covered by your insurance policy. Please refer to your policy
        wording for more information.
      </FaqP>
      <FaqP>
        For more information about making a claim, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Leaking or Escape of water in the home',
              'What should I expect when making a claim for escape or leakage of water?',
            ])
          }
        >
          &apos;What should I expect when making a claim for escape or leakage
          of water?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

export default PlumbingFixContent;

PlumbingFixContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
PlumbingFixContent.defaultProps = {
  nestingControl: undefined,
};
