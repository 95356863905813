import styled from 'styled-components';
import media from '../../../components/MediaQuery/MediaQueryMin';

const Footer = styled.footer`
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${props => props.theme.greyNavBg};
  margin-top: 50px;
  padding: 90px 0 30px;
  ${media.desktop`
    padding: 90px 0 30px;
  `};
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -98px;
    left: -5%;
    width: 110%;
    height: 140px;
    border-radius: 50%;
    background: ${props => props.theme.white};
  }
`;

const FooterContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const FooterLinks = styled.div`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  p {
    padding: 0 30px 10px 0;
    color: ${props => props.theme.greyDarker};
  }
  ul {
    li {
      display: inline-block;
      margin-bottom: 0;
      &:not(:last-child)::after {
        content: '•';
        font-size: 14px;
        color: ${props => props.theme.greyDarker};
        margin: 0.6em;
      }
      a {
        color: ${props => props.theme.greyDarker};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  span {
    display: block;
    text-align: center;
    ${media.desktop`
      min-width: 200px;
      padding-right: 50px;
      img {
        width: 100%;
      }
    `};
  }
`;

const FooterText = styled.div`
  padding: 24px;
`;

/**
 * Addes styling to the SI verion wrapped in {span} tag
 */
const VersionInformation = styled.div`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #646464;

  margin-bottom: 20px;
  ${media.desktop`
    margin-bottom: none;
  `};
`;

export { Footer, FooterLinks, FooterText, FooterContainer, VersionInformation };
