import styled, { css } from 'styled-components';
import { ThemedPanel } from 'ageas-ui-components';

// ageas-ui-components changed width of ThemedPanel from 100% to 96%
// This allows us to override that
const StandardPanel = styled(ThemedPanel)`
  width: ${props => props?.width || '100%'};
  ${props =>
    props.noBorder &&
    css`
      border-radius: 0px;
      box-shadow: none;
      border: 0px;
      padding: 0px;
    `}
`;

export default StandardPanel;
