import React from 'react';
import effectOfWaterDamageQuestions from '../EffectOfWaterDamage';
import accidentalDamageQuestions from '../AccidentalDamage';
import weatherDamageQuestions from '../WeatherDamage';
import contentsClaimQuestions from '../ContentsClaim';
import freezerFoodDamageQuestions from '../FreezerFood';
import policyExcessQuestions from '../PolicyExcess';

import questionsComplaints from '../Complaints';
import buildingDamageQuestions from '../BuildingDamage';
import ageasApprovedSuppliersFactors from '../AgeasApprovedSuppliers';
import questionsConsiderations from '../Considerations';
import questionsOnlineClaimSupport from '../OnlineClaimSupport';
import questionsWearAndTear from '../WearAndTear';
import questionsExcessProtection from '../ExcessProtection';

const renderQuestion = ({ title, content }, i) => (
  <React.Fragment key={i}>
    <div>{title}</div>
    <div>{content}</div>
    <hr />
  </React.Fragment>
);
const renderQuestions = questions => (
  <>
    {questions.map(renderQuestion)}
    <br />
    <br />
  </>
);

const QuestionsList = () => {
  return (
    <>
      {renderQuestions(effectOfWaterDamageQuestions)}
      {renderQuestions(accidentalDamageQuestions)}
      {renderQuestions(weatherDamageQuestions)}
      {renderQuestions(contentsClaimQuestions)}
      {renderQuestions(policyExcessQuestions)}
      {renderQuestions(freezerFoodDamageQuestions)}
      {renderQuestions(questionsComplaints)}
      {renderQuestions(buildingDamageQuestions)}
      {renderQuestions(ageasApprovedSuppliersFactors)}
      {renderQuestions(questionsConsiderations)}
      {renderQuestions(questionsOnlineClaimSupport)}
      {renderQuestions(questionsWearAndTear)}
      {renderQuestions(questionsExcessProtection)}
    </>
  );
};

export default QuestionsList;
