import styled from 'styled-components';

// App.css applies a default padding of 0 to all elements
// This is to undo that for unordered lists as the bullet point is part of the
// padding
const StandardUL = styled.ul`
  padding-inline-start: 18px;
`;

export default StandardUL;
