import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const timeTakenForFreezerClaimTitle =
  'How long will my damage to fridge/freezer food claim take?';
export const timeTakenForFreezerClaimContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        We want to make claiming as easy as possible, so we look to settle all
        fridge/freezer food claims on the first call.
      </FaqP>
      <FaqP>
        For more information, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Damage to fridge/freezer food in the home',
              'What can I expect when making a claim for damage to fridge/freezer food?',
            ])
          }
        >
          &apos;What can I expect when making a claim for damage to
          fridge/freezer food?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

timeTakenForFreezerClaimContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
timeTakenForFreezerClaimContent.defaultProps = {
  nestingControl: undefined,
};

export default timeTakenForFreezerClaimContent;
