import React from 'react';
import FaqP from '../../FaqP';

export const buildingInsuranceCoverTitle =
  'What is covered by buildings insurance?';
export const buildingInsuranceCoverContent = (
  <>
    <FaqP>
      This will depend on the cover you took out when you purchased your
      buildings policy.
    </FaqP>
    <FaqP>
      Essentially, your standard buildings insurance policy will cover the cost
      of repairs to your home if it is damaged by an insured event such as
      theft, fire, flood and escape/leakage of water from pipes, storage tanks,
      etc. If you also purchased the optional add-on cover of Accidental Damage,
      that will cover you for other instances relating to accidental damage
      (which is damage that is unexpected and unintended, caused by something
      sudden and which is not deliberate).
    </FaqP>
    <FaqP>
      There will be exclusions for each type of peril, so please refer to your
      home insurance policy booklet for full details of your cover.
    </FaqP>
  </>
);
