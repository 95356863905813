import React from 'react';
import { HomeClaimsLandingFNOL } from '../../../../helpers/ageasTelephoneNumbers';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const eowDeclineClaimQuestion =
  'What happens if my claim is declined and I do not agree with the decision?';
const EOWDeclineClaimContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        Please contact our Claims Team on <HomeClaimsLandingFNOL /> in the first
        instance to discuss your concerns. This will give us the opportunity to
        understand your concerns and provide clarity on the reason for declining
        your claim.
      </FaqP>
      <FaqP>
        For more information, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Leaking or Escape of water in the home',
              'When would my claim not be covered?',
            ])
          }
        >
          &apos;When would my claim not be covered?&apos;
        </ButtonLink>
      </FaqP>

      <FaqP>
        If you remain dissatisfied with the decision, you have the right to
        register a complaint. For more information, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'How to complain',
              'How do I make a complaint about my claim?',
            ])
          }
        >
          &apos;How do I make a complaint about my claim?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

EOWDeclineClaimContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
EOWDeclineClaimContent.defaultProps = {
  nestingControl: undefined,
};

export default EOWDeclineClaimContent;
