import React from 'react';
import PropTypes from 'prop-types';
import { FIELD_NAMES } from '../motorYourDetailsHelper';
import AddressConfirmUpdate from '../ConfirmUpdate/AddressConfirmUpdate';

const formatPhAddress = ({
  addressLineOne,
  addressLineTwo,
  addressLineThree,
  addressLineFour,
  addressPostcode,
} = {}) =>
  [
    addressLineOne,
    addressLineTwo,
    addressLineThree,
    addressLineFour,
    addressPostcode,
  ]
    .map(x => x?.trim())
    .filter(x => !!x);

/**
 * Component for displaying data relating to policy, for confirmation/
 * update
 */
const PolicyConfirmDetails = ({ policyData, validateFields }) => (
  <>
    <AddressConfirmUpdate
      title="Policyholder address:"
      value={formatPhAddress(policyData?.contactDetails)}
      validate={validateFields}
    />
    {/*
// TODO more PH-specific fields go here
*/}
  </>
);

PolicyConfirmDetails.propTypes = {
  policyData: PropTypes.instanceOf(Object),
  validateFields: PropTypes.func,
};

PolicyConfirmDetails.defaultProps = {
  policyData: undefined,
  validateFields: undefined,
};

export default PolicyConfirmDetails;
