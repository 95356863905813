/* THIS SHOULD MATCH THE FILE IN THE PORTAL WHITELABEL REPO */

/* Ageas Brand Guideline font settings (maybe) */

/* ** Do not access these font settings directly from Components! ** */

const FontTheme = {
  weight: {
    lighter: 300,
    normal: 400,
    bold: 700,
  },

  size: {
    small: '20px',
    medium: '24px',
    large: '30px',
  },
};

export default FontTheme;
