import React from 'react';
import PropTypes from 'prop-types';
import { ThemedErrorMessage } from 'ageas-ui-components';
import FieldStyled from '../FieldStyled/FieldStyled';

// For documentation see:
// /docs/components/Forms/SectionError/SectionError.md

const SectionError = ({ name, validate }) => {
  return (
    <FieldStyled name={name} validate={validate}>
      {({ meta }) => (
        <>
          {(meta.error || meta.submitError) && meta.touched && (
            <ThemedErrorMessage hasIcon>
              {meta.error || meta.submitError}
            </ThemedErrorMessage>
          )}
        </>
      )}
    </FieldStyled>
  );
};

SectionError.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

SectionError.defaultProps = {
  validate: () => {},
};

export default SectionError;
