import React from 'react';
import PropTypes from 'prop-types';
import MessagesSvgRead from './MessagesSVGRead.svg';
import MessagesSvgUnread from './MessagesSVGUnread.svg';

const MessagesSvg = ({ unread = false }) => (
  <img
    src={unread ? MessagesSvgUnread : MessagesSvgRead}
    alt={`${unread ? 'Unread ' : ''}Messages`}
  />
);

export default MessagesSvg;

MessagesSvg.propTypes = {
  unread: PropTypes.bool,
};

MessagesSvg.defaultProps = {
  unread: undefined,
};
