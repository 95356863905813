import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { ThemedErrorMessage } from 'ageas-ui-components';
import {
  SubText,
  BasicSection,
} from '../../StandardPageWrapper/StandardPageWrapperStyle';
import config from '../../../helpers/config';
import axiosHelper from '../../../helpers/axios';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import HubNotifications from '../../HubNotifications/HubNotifications';
import HubUploadedDocuments from '../../HubUploadedDocuments/HubUploadedDocuments';
import HomeHubUploader from '../../DocumentUploader/HomeHubUploader';
import documentUploadTypes from '../../../helpers/documentUploadTypes';
import { dataLoadFailingError } from '../../../helpers/errorMessages';
import { HubContext, hubDataContext } from '../../../helpers/hubContextHelper';
import {
  updateNotifications,
  updateNotificationStatus,
  closeNotificationsByCode,
} from '../../../helpers/hubNotificationsHelper';
import SupplierDetails from './SupplierDetails';

const displayClaimDetails = (
  data,
  suppliers,
  expandNewSupplierUpdates,
  closeSupplierMilestoneNotification,
) => {
  const displayPolicyHolder = () => {
    return data.policyHolders.map(claim => claim.name).join(', ');
  };

  const displayPolicyNumber = () => {
    if (!data.externalPolicyReference || data.externalPolicyReference === ' ') {
      return data.policyReference;
    }
    return data.externalPolicyReference;
  };

  const displaySupplierDetails = () => {
    return suppliers.map(supplier => (
      <SupplierDetails
        key={supplier.code}
        supplier={supplier}
        expandNewSupplierUpdates={expandNewSupplierUpdates}
        closeNotification={closeSupplierMilestoneNotification}
      />
    ));
  };

  return (
    <>
      <BasicSection>
        <SubText>Policyholder: {displayPolicyHolder()}</SubText>
        <SubText>Policy number: {displayPolicyNumber()}</SubText>
      </BasicSection>
      {!isEmpty(suppliers) && (
        <BasicSection>
          <strong>Contacts</strong>
          <SubText>
            Our specialist suppliers have full authority to manage claims
            without the need to refer back to us for approval. If you would like
            an update, please contact them directly in the first instance and
            they will be able to assist you.
          </SubText>
          {displaySupplierDetails()}
        </BasicSection>
      )}
    </>
  );
};

const ClaimDetails = ({ claimReference, perilCode, activeTab }) => {
  const [claimData, setClaimData] = useState(null);
  const [documents, setDocuments] = useState(null);
  const localDocumentsId = useRef(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoadFail, setDataLoadFail] = useState(false);
  const axiosCancelToken = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const hubContext = useContext(HubContext);
  const { dataState } = hubContext;
  const { notifications } = dataState;
  const [expandNewSupplierUpdates, setExpandNewSupplierUpdates] =
    useState(false);
  const [suppliers, setSuppliers] = useState(null);

  const [displayUploaderModal, setDisplayUploaderModal] = useState(null);
  const [uploaderNotificationCode, setUploaderNotificationCode] =
    useState(undefined);
  const [uploaderNotificationSequence, setUploaderNotificationSequence] =
    useState(undefined);

  const closeSupplierMilestoneNotification = () =>
    closeNotificationsByCode(hubContext, hubDataContext, 'SM');

  const onSuccessfulUpload = (
    _uploadedDocument,
    payload,
    _uploaderDocumentsState,
    formData,
  ) => {
    const documentType = documentUploadTypes[formData.category];
    // Add document to uploaded documents list
    if (documentType.correspondenceType) {
      localDocumentsId.current += 1;
      setDocuments([
        ...documents,
        {
          sequence: localDocumentsId.current,
          origin: 'localUploader',
          correspondenceType: documentType.correspondenceType,
        },
      ]);
    }

    // Clear associated notifications

    // If payload had a notificationSequence, close this notification
    if (payload.notificationSequence) {
      updateNotificationStatus(
        hubContext,
        hubDataContext,
        payload.notificationSequence,
        'closed',
      );
      // Else if notificationCode found
    } else if (documentType?.notificationCode) {
      // close all matching open notifications
      closeNotificationsByCode(
        hubContext,
        hubDataContext,
        documentType.notificationCode,
      );
    }
  };

  useEffect(() => {
    // When messages are loaded (ActiveTab changes to "messages"), clear any
    // unread messages notifications
    if (activeTab === 'messages' && notifications?.length) {
      closeNotificationsByCode(hubContext, hubDataContext, 'HO');
    }
    // When payment detail are loaded (ActiveTab changes to "settlement"),
    // clear any unread payment made notifications
    if (activeTab === 'settlement' && notifications?.length) {
      closeNotificationsByCode(hubContext, hubDataContext, 'PY');
    }
  }, [activeTab]);

  const onViewClick = notification => {
    if (['OF', 'PY'].includes(notification.code)) {
      history.push(`${location.pathname}?tab=settlement`);
      if (notification.code === 'PY') {
        updateNotificationStatus(
          hubContext,
          hubDataContext,
          notification.sequence,
          'closed',
        );
      }
    }
    if (notification.code === 'HO') {
      history.push(`${location.pathname}?tab=messages`);
      updateNotificationStatus(
        hubContext,
        hubDataContext,
        notification.sequence,
        'closed',
      );
    }
    if (notification.code === 'SM') {
      setExpandNewSupplierUpdates(true);
      updateNotificationStatus(
        hubContext,
        hubDataContext,
        notification.sequence,
        'closed',
      );
    }
  };

  const onUploadClick = notification => {
    setUploaderNotificationCode(notification.code);
    setUploaderNotificationSequence(notification.sequence);
    setDisplayUploaderModal('uploadDocumentModal');
  };

  const getClaimsDetails = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .get(
        `${
          config.client.getHomeHubClaimDetails_endpoint
        }?claimReference=${encodeURIComponent(claimReference)}`,
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(({ data }) => {
        setIsLoading(false);
        const {
          notifications: notificationsList,
          documents: documentsList,
          suppliers: suppliersList,
          ...rest
        } = data;
        setClaimData(rest);
        updateNotifications(hubContext, hubDataContext, notificationsList);
        setDocuments(documentsList);
        setSuppliers(suppliersList);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setDataLoadFail(true);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClaimsDetails();
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  if (dataLoadFail) {
    return (
      <ThemedErrorMessage hasIcon>{dataLoadFailingError()}</ThemedErrorMessage>
    );
  }

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      {!isLoading && (
        <>
          {claimData &&
            displayClaimDetails(
              claimData,
              suppliers,
              expandNewSupplierUpdates,
              closeSupplierMilestoneNotification,
            )}
          {!!notifications?.length && (
            <HubNotifications
              notifications={notifications}
              actions={{ view: onViewClick, upload: onUploadClick }}
            />
          )}
          <HomeHubUploader
            claimReference={claimReference}
            perilCode={perilCode}
            notificationCodes={
              uploaderNotificationCode && [uploaderNotificationCode]
            }
            notificationSequence={uploaderNotificationSequence}
            externalModalState={[displayUploaderModal, setDisplayUploaderModal]}
            onSuccessfulUpload={onSuccessfulUpload}
          />
          {!!documents?.length && (
            <HubUploadedDocuments documents={documents} />
          )}
        </>
      )}
    </>
  );
};

export default ClaimDetails;

ClaimDetails.propTypes = {
  claimReference: PropTypes.string,
  perilCode: PropTypes.string,
  activeTab: PropTypes.string,
};

ClaimDetails.defaultProps = {
  claimReference: undefined,
  perilCode: undefined,
  activeTab: undefined,
};
