import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundRoute from '../NotFoundRoute';
import EclaimsRoute from '../EclaimsRoute';
import getTPAPaths from './motorClaimsTpHubRoutePaths';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import { BrokerContext } from '../../helpers/brokerContextHelper';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import { BrokerLoginMotorTPA } from '../../views/NonDirectAuthentication/BrokerLogin/BrokerLogin';
import { OTPVerificationMotorTPA } from '../../views/NonDirectAuthentication/OTPVerification/OTPVerification';
import { MobileEmailAuthenticationMotorTPA } from '../../views/NonDirectAuthentication/MobileEmailAuthentication/MobileEmailAuthentication';
import MotorTpHubDetail from '../../views/MotorTpHubDetail/MotorTpHubDetail';
import {
  HubContextMotorTp,
  localStorageHubPropertyNameMotorTp,
} from '../../helpers/hubContextHelper';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';

const motorTPHubClaimsPrivateProps = {
  private: true,
  authTypes: ['motortpotp'],
  lobs: 'motorTPA',
};

const PATHS = getTPAPaths();

const MotorClaimsTpLoginRoutes = () => {
  return (
    <DataContextProvider Context={BrokerContext}>
      <Switch>
        <Route exact path={PATHS.LOGIN} component={BrokerLoginMotorTPA} />
        <Route
          exact
          path={PATHS.VALIDATE_CONTACT}
          component={MobileEmailAuthenticationMotorTPA}
        />
        <Route
          exact
          path={PATHS.OTP_VERIFY}
          component={OTPVerificationMotorTPA}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

const MotorClaimsTpHubRoutes = () => {
  return (
    <DataContextProvider
      Context={HubContextMotorTp}
      localStoragePropertyName={localStorageHubPropertyNameMotorTp}
      localStorageExpiryDays={config.client.localStorageHubExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path={PATHS.ROOT}
          component={MotorTpHubDetail}
          {...motorTPHubClaimsPrivateProps}
        />
        <Route path={PATHS.LOGIN} component={MotorClaimsTpLoginRoutes} />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default MotorClaimsTpHubRoutes;
