import {
  UnableToProceedWithClaimHome,
  serverErrorHome,
} from '../../helpers/errorMessages';

export const validationErrorMapping = {
  EXCESS_ENDORSEMENT: UnableToProceedWithClaimHome,
  SPECIFIED_ITEM: UnableToProceedWithClaimHome,
};

export const getValidationErrorMessage = errorCode => {
  const errorMessage = validationErrorMapping[errorCode];
  if (errorMessage) return errorMessage();
  return serverErrorHome();
};
