import React from 'react';
import FaqP from '../../FaqP';

export const freezerStopWorkingTitle =
  'Can I claim for my fridge/freezer that has stopped working?';
export const freezerStopWorkingContent = (
  <>
    <FaqP>
      Fridges and freezers are not covered under this section of your policy.
    </FaqP>
    <FaqP>
      Depending on the reason that the fridge/freezer has stopped working,
      another section of your policy may or may not cover this.
    </FaqP>
    <FaqP>
      For example, if the fridge/freezer has simply stopped working due to its
      age (mechanical failure or breakdown), this will not be covered under any
      section of your policy.
    </FaqP>
    <FaqP>
      For more information, please read the Contents section of your policy
      booklet.
    </FaqP>
  </>
);
