import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const RenderOnFirstActive = ({ active = false, children }) => {
  const [render, setRender] = useState(active);
  useEffect(() => {
    if (active && !render) {
      setRender(true);
    }
  }, [active]);
  return <>{render && children}</>;
};
RenderOnFirstActive.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};
RenderOnFirstActive.defaultProps = {
  active: undefined,
  children: undefined,
};

export default RenderOnFirstActive;
