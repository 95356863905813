import { isoToDmyAndHms } from '../../dateTimeConverter';
import { booleanToYesNo, mapInvolvedPartyToLocal } from '../helpers';

export const mapServerToIncidentDetailsPageAnimalDamage = incidentDetails => {
  const { circumstances } = incidentDetails || {};
  const { animalDamage, howDamageHappened } = circumstances || {};
  const { animalType, ownerKnown, ownerResponsible, ownerDetails } =
    animalDamage || {};

  if (howDamageHappened !== 'animal') {
    return {};
  }

  return {
    animaltype: animalType?.trim(),
    animalOwnerKnown: booleanToYesNo(ownerKnown),
    animalOwnerResponsible: booleanToYesNo(ownerResponsible),
    ...mapInvolvedPartyToLocal(ownerDetails, 'animalOwnerDetails'),
  };
};

export const mapServerToIncidentDetailsPagePropertyDamage = incidentDetails => {
  const { circumstances } = incidentDetails || {};
  const { propertyDamage, howDamageHappened } = circumstances || {};
  const {
    propertyType,
    propertyTypeOtherDetails,
    ownerKnown,
    ownerType,
    ownerDetails,
  } = propertyDamage || {};

  if (howDamageHappened !== 'property') {
    return {};
  }

  return {
    propertyTypeDamaged: propertyType?.trim(),
    propertyTypeDamagedOtherDetails: propertyTypeOtherDetails?.trim(),
    propertyOwnerKnown: booleanToYesNo(ownerKnown),
    propertyOwnerType: ownerType?.trim(),
    ...mapInvolvedPartyToLocal(ownerDetails, 'propertyOwnerDetails'),
  };
};

export const mapServerToIncidentDetailsPageRoadSurfaceSpillageMud =
  roadSurface => {
    const { spillageMud, roadSurfaceIssueType } = roadSurface || {};
    const {
      warningSigns,
      whoCausedKnown,
      whoCausedType,
      whoCausedDetails,
      whenAppearedKnown,
      whenAppeared,
    } = spillageMud || {};

    if (roadSurfaceIssueType !== 'spillageMud') {
      return {};
    }

    return {
      spillageMudWarningSigns: booleanToYesNo(warningSigns),
      spillageMudWhoCausedKnown: booleanToYesNo(whoCausedKnown),
      spillageMudWhoCausedType: whoCausedType?.trim(),
      ...mapInvolvedPartyToLocal(
        whoCausedDetails,
        'spillageMudWhoCausedDetails',
      ),
      spillageMudWhenAppearedKnown: booleanToYesNo(whenAppearedKnown),
      spillageMudWhenAppeared: whenAppeared?.trim(),
    };
  };

export const mapServerToIncidentDetailsPageRoadSurfaceUngritted =
  roadSurface => {
    const { ungrittedRoad, roadSurfaceIssueType } = roadSurface || {};
    const {
      mainRoad,
      accessRoad,
      publicTransport,
      ownerKnown,
      ownerType,
      ownerDetails,
    } = ungrittedRoad || {};

    if (roadSurfaceIssueType !== 'ungrittedRoad') {
      return {};
    }

    return {
      ungrittedRoadMainRoad: booleanToYesNo(mainRoad),
      ungrittedRoadAccessRoad: booleanToYesNo(accessRoad),
      ungrittedRoadPublicTransport: booleanToYesNo(publicTransport),
      ungrittedRoadOwnerKnown: booleanToYesNo(ownerKnown),
      ungrittedRoadOwnerType: ownerType?.trim(),
      ...mapInvolvedPartyToLocal(ownerDetails, 'ungrittedRoadOwnerDetails'),
    };
  };

export const mapServerToIncidentDetailsPageRoadSurfacePothole = roadSurface => {
  const { pothole, roadSurfaceIssueType } = roadSurface || {};
  const { councilDetails } = pothole || {};

  if (roadSurfaceIssueType !== 'pothole') {
    return {};
  }

  return {
    ...mapInvolvedPartyToLocal(councilDetails, 'potholeCouncilDetails'),
  };
};

export const mapServerToIncidentDetailsPageRoadSurface = incidentDetails => {
  const { circumstances } = incidentDetails || {};
  const { roadSurface, howDamageHappened } = circumstances || {};
  const { roadSurfaceIssueType } = roadSurface || {};

  if (howDamageHappened !== 'roadSurface') {
    return {};
  }

  return {
    roadSurfaceIssueType: roadSurfaceIssueType?.trim(),
    ...mapServerToIncidentDetailsPageRoadSurfaceSpillageMud(roadSurface),
    ...mapServerToIncidentDetailsPageRoadSurfaceUngritted(roadSurface),
    ...mapServerToIncidentDetailsPageRoadSurfacePothole(roadSurface),
  };
};

/**
 * Map JSON Blob returned from server to context/pageForm format
 * required for Incident Details page
 * @param {*} claimData property of JSON Blob
 * @returns data in Incident Details page form format
 */
export const mapServerToIncidentDetailsPage = ({
  incidentDetails,
  locationDetails,
} = {}) => {
  const { incidentDate, vrn, incidentDescription, circumstances, hasCCTV } =
    incidentDetails || {};
  const { street, town, country } = locationDetails || {};
  const { howDamageHappened } = circumstances || {};

  const incidentDateTime = incidentDate
    ? isoToDmyAndHms(incidentDate)
    : undefined;

  return {
    incidentDate: incidentDateTime?.date,
    incidentTime: incidentDateTime?.time,
    vehicleRegistration: vrn?.trim(),

    incidentDetails: incidentDescription?.trim(),
    howDamageHappened: howDamageHappened?.trim(),
    hasCCTV: booleanToYesNo(hasCCTV),

    incidentStreet: street?.trim(),
    incidentTown: town?.trim(),
    incidentCountry: country?.trim(),

    ...mapServerToIncidentDetailsPageAnimalDamage(incidentDetails),
    ...mapServerToIncidentDetailsPagePropertyDamage(incidentDetails),
    ...mapServerToIncidentDetailsPageRoadSurface(incidentDetails),
  };
};

export default mapServerToIncidentDetailsPage;
