/* THIS SHOULD MATCH THE FILE IN THE PORTAL WHITELABEL REPO */

/* Component Library Color links for Ageas Theme */

/* Component color links to be added here for each new Component created,
   and added to the same file in Portal-Whitelabel */

import color from './AgeasColorTheme';
import font from './FontTheme';
import AgeasIconSet from './AgeasIcons.json';

const ComponentLibraryTheme = {
  button: {
    primary: {
      background: color.primary,
      text: color.white,
      border: color.primary,

      hover: {
        background: color.primaryLight,
        border: color.primaryLight,
      },

      inverted: {
        background: color.white,
        text: color.primary,
        border: color.primary,

        hover: {
          background: color.primary,
          text: color.white,
          border: color.primary,
        },
      },
    },

    secondary: {
      background: color.secondaryDark,
      text: color.white,
      border: color.secondaryDark,

      hover: {
        background: color.secondary,
        border: color.secondary,
      },

      inverted: {
        background: color.white,
        text: color.secondaryDark,
        border: color.secondaryDark,

        hover: {
          background: color.secondaryDark,
          text: color.white,
          border: color.secondaryDark,
        },
      },
    },

    tertiary: {
      background: color.greyDarker,
      text: color.white,
      border: color.greyDarker,

      hover: {
        background: color.greyDarker,
        border: color.greyDarker,
      },

      inverted: {
        background: color.white,
        text: color.greyDarker,
        border: color.greyDarker,

        hover: {
          background: color.greyDarker,
          text: color.white,
          border: color.greyDarker,
        },
      },
    },
  },

  title: {
    primary: {
      text: color.primary,
    },
    secondary: {
      text: color.greyDarkest,
    },
    tertiary: {
      text: color.greyDarker,
    },

    lighter: {
      fontWeight: font.weight.lighter,
    },
    normal: {
      fontWeight: font.weight.normal,
    },
    bold: {
      fontWeight: font.weight.bold,
    },

    small: {
      fontSize: font.size.small,
    },

    medium: {
      fontSize: font.size.medium,
    },

    large: {
      fontSize: font.size.large,
    },
  },

  modal: {
    inner: {
      background: color.white,
    },
    footer: {
      borderTop: `1px solid ${color.greyLight}`,
    },
    primary: {
      header: {
        background: color.primary,
        text: color.white,
        borderBottom: 'none',
        icon: {
          border: `1px solid ${color.white}`,
          opacity: 1,
          hover: {
            opacity: 1,
          },
        },
      },
    },

    secondary: {
      header: {
        background: color.secondary,
        text: color.white,
        borderBottom: 'none',
        icon: {
          border: 0,
          opacity: 0.2,
          size: `16px`,
          hover: {
            opacity: 0.5,
          },
        },
      },
    },

    tertiary: {
      header: {
        background: color.white,
        text: color.greyDarker,
        borderBottom: `1px solid ${color.greyLight}`,
        icon: {
          border: 0,
          opacity: 0.2,
          size: `16px`,
          hover: {
            opacity: 0.5,
          },
        },
      },
    },
  },

  tabs: {
    tabItem: {
      background: color.greyDarker,
      text: color.white,
      border: color.greyDark,

      hover: {
        background: color.greyDark,
        text: color.primary,
      },

      active: {
        background: color.white,
        text: color.primary,
      },
    },
    tabPanel: {
      background: color.white,
      border: color.greyDark,
      shadow: color.greyLight,
    },
  },

  radioGroup: {
    button: {
      border: `1px solid ${color.primary}`,
      selected: {
        border: `1px solid ${color.primary}`,

        disabled: {
          border: `1px solid ${color.greyDarker}`,
        },
      },
      error: {
        border: `1px solid ${color.error}`,
      },
      disabled: {
        border: `1px solid ${color.greyDark}`,
      },
    },

    label: {
      color: color.primary,
      fill: color.primary,
      hover: {
        fill: color.white,
        background: color.primaryLight,
        color: color.white,
      },
      focus: {
        fill: color.white,
        background: color.primaryLight,
        color: color.white,
      },
    },

    input: {
      checked: {
        fill: color.white,
        background: color.primary,
        color: color.white,

        disabled: {
          fill: color.white,
          background: color.greyDarker,
          color: color.white,
        },
      },
      disabled: {
        fill: color.white,
        background: color.greyDark,
        color: color.white,
      },
    },
  },

  radioGroupPayment: {
    label: {
      color: color.primary,
      hover: {
        color: color.white,
      },
    },
  },

  checkbox: {
    background: color.white,
    tick: {
      unchecked: color.white,
      checked: color.primary,
    },

    focus: color.primaryLight,
    border: color.greyDarker,
  },

  panel: {
    primary: {
      background: color.primary,
      text: color.white,
    },
    secondary: {
      background: color.secondaryDark,
      text: color.white,
    },
    tertiary: {
      background: color.greyLight,
      text: color.greyDarker,
    },
    contentColor: color.greyDarker,
    border: {
      withBoxShadow: color.greyLighter,
      withoutBoxShadow: color.black,
    },
  },

  input: {
    background: color.white,
    highlight: color.primary,
    text: {
      default: color.greyDarkest,
      active: color.primary,
    },
    border: {
      default: color.greyDark,
      active: color.primary,
    },
    containerBackground: color.white,
    link: color.link,
  },

  lineBreak: {
    primary: color.primary,
    secondary: color.secondary,
    tertiary: color.greyLight,
  },

  adornedNumericInput: {
    primary: {
      background: color.primary,
      text: color.white,
      border: color.primary,
    },
    secondary: {
      background: color.greyLighter,
      text: color.greyDarker,
      border: color.greyDarker,
    },
    tertiary: {
      background: color.white,
      text: color.white,
      border: color.white,
    },
  },

  dropdown: {
    color: color.greyDarkest,
    backgroundColor: color.white,
    border: color.greyDark,
    focus: {
      border: color.primary,
    },
    error: {
      border: color.error,
    },
  },

  hyperlink: {
    primary: color.primary,
    secondary: color.secondary,
    tertiary: color.greyDarkest,
    white: color.white,

    hover: {
      primary: color.primaryLight,
      secondary: color.secondaryLight,
      tertiary: color.greyDark,
      white: color.greyLighter,
    },

    renewal: color.pink,
  },

  paragraph: {
    text: color.greyDarkest,
    white: {
      text: color.white,
    },
    warning: {
      background: color.warning,
    },
  },

  errorMessage: {
    color: color.error,
  },

  contentWrapper: {
    background: color.greyLight,
    primary: {
      background: color.primary,
    },
  },

  detailDisplay: {
    background: color.greyLighter,
    text: color.greyDarkest,
    border: `1px solid ${color.greyLight}`,
  },

  hint: {
    primary: {
      text: color.primary,
      background: color.white,
      border: `1px solid ${color.primary}`,

      button: {
        text: color.primary,
      },

      arrow: {
        background: color.white,
        border: `1px solid ${color.primary}`,
      },
    },

    secondary: {
      text: color.secondary,
      background: color.white,
      border: `1px solid ${color.secondary}`,

      button: {
        text: color.secondary,
      },

      arrow: {
        border: `1px solid ${color.secondary}`,
      },
    },

    tertiary: {
      text: color.greyDarker,
      background: color.greyLight,
      border: `1px solid ${color.greyDarker}`,

      button: {
        text: color.greyDarker,
      },

      arrow: {
        border: `1px solid ${color.greyDarker}`,
      },
    },
  },

  countdown: {
    container: {
      background: color.greyLight,
    },
    ring: {
      border: color.grey,
      background: color.greyLighter,
      text: color.greyDarker,
    },
    positive: color.secondary,
    negative: color.error,
  },

  icons: {
    set: AgeasIconSet,
  },

  chatbot: {
    primaryColor: color.primary,
    panelBackgroundColor: color.greyLightest,
    chatBubbles: {
      userBackgroundColor: color.primary,
      userTextColor: color.white,
      vaBackgroundColor: color.greyLight,
      vaTextColor: color.greyDarker,
      typingBackgroundColor: color.greyLight,
      typingDotColor: color.greyDarker,
    },
    composer: {
      textareaFocusOutlineColor: color.primaryLight,
    },
    buttons: {
      textColor: color.white,
      backgroundColor: color.primary,
      hoverBackgroundColor: color.primaryLight,
    },
  },

  // TODO: Code below to be removed once panel gets replaced with ThemedPanel.
  panelColor: color.greyDarker,
  panelTitleBackground: color.primary,

  // DropDown/Input
  // TODO: Code below to be removed once input gets replaced with ThemedInput.
  inputBorderColor: color.greyDark,
  inputBackgroundColor: color.white,
  inputActiveBorderColor: color.primary,
  defaultLinkColor: color.link,

  // TODO: Code to be removed once replaced with ThemedAdornedNumericInput.
  adornedText: color.greyDarker,
  adornedBorder: color.greyDarker,
  adornedBorderPrimary: color.primary,
  adornedSpanBackground: color.greyLighter,
  adornedSpanBackgroundPrimary: color.primary,

  // TODO: Code to be removed once replaced with ThemedDetailDisplay.
  detailBoxBorder: color.greyLight,
  detailBoxBackground: color.greyLighter,

  /* ------------------------------------ */

  backgroundColor: color.white,

  wrapperBackground: color.greyLight,

  contentWrapperBackground: color.primary,

  /* Text */
  textPrimary: color.greyDarkest,
  textSecondary: color.white,
  textThemed: color.primary,

  textError: color.error,

  headerText: color.greyDarker,
  headerContainerBackground: color.primary,

  titlePrimary: color.primary,
  titleItalic: color.greyDarker,
  titleGrey: color.greyDarker,

  paragraphBackground: color.white,
  paragraphTextWhite: color.white,

  // Buttons RadioGroup/CollapsibleView
  buttonPrimaryColor: color.primary,
  buttonPrimaryHover: color.primaryLight,
  buttonPrimaryInvertedHover: color.primary,
  buttonSecondary: color.secondaryDark,
  buttonBorderColor: color.primary,
  buttonBorderPrimary: color.primary,
  buttonActiveColor: color.primary,
  buttonErrorColor: color.error,
  buttonBackgroundPrimary: color.primary,
  radioLabelHoverFill: color.white,
  radioLabelHoverColor: color.white,
  radioInputCheckedFill: color.white,
  radioInputCheckedColor: color.white,
  buttonPrimaryText: color.white,
  buttonSecondaryText: color.black,

  arrowColor: color.primaryDark,

  /* RadioGroupInput */
  buttonHoverColor: color.primaryLight,

  warningBackgroundColor: color.warning,
  errorMessageColor: color.error,

  formTitleBackgroundColor: color.greyLight,

  accordionbackground: color.greyLighter,
  accordionBody: color.greyLight,
  iconHolderBackground: color.greyLighter,

  modalHeader: color.primary,
  modalSvgBorder: color.white,

  // TODO: Code below to be removed once Hint gets replaced with ThemedHint.
  hintColor: color.primary,
  hintIcon: color.primary,
  hintBorder: color.primary,
  hintTextBorder: color.primary,
  hintArrowBorder: color.primary,
  hideHintColor: color.primary,
  hintCloseButtonColor: color.greyDarkest,

  dropdownBorder: color.primary,

  notificationWrapperBackground: color.white,

  navText: color.white,

  logoColor: color.white,

  bulletColor: color.secondaryDark,
};

export default ComponentLibraryTheme;
