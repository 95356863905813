import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import axiosHelper from '../../../helpers/axios';
import StandardAutoComplete from '../StandardAutoComplete/StandardAutoComplete';
import { consoleError } from '../../../helpers/consoleLog';

const ABICodeAutoComplete = ({ url, ...rest }) => {
  const axiosCancelToken = useRef(null);
  const createAxiosCancelToken = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosCancelToken.current;
  };

  const cancelAxios = () => {
    if (axiosCancelToken?.current?.cancel) {
      axiosCancelToken.current.cancel();
    }
  };

  // On page load
  useEffect(() => {
    // Abort axios on unmount
    return () => {
      cancelAxios();
    };
  }, []);

  /**
   * Async data fetch method for ThemedInputWithAutoComplete
   * (via StandardAutoComplete)
   *
   * @param {*} userInput Text search (API requires minimum 3 chars)
   * @returns array of {label,value} results. Empty array if no matches or
   * error. String 'abort' if axios request was cancelled.
   */
  const getSuggestionData = userInput => {
    cancelAxios();
    return axiosHelper
      .get(`${url}?filter=${encodeURIComponent(userInput)}`, {
        cancelToken: createAxiosCancelToken().token,
      })
      .then(({ data }) => data)
      .catch(error => {
        if (axios.isCancel(error)) {
          return 'abort';
        }
        consoleError('AutoComplete data fetch fail', error);
        return [];
      });
  };

  return (
    <StandardAutoComplete getSuggestionData={getSuggestionData} {...rest} />
  );
};

ABICodeAutoComplete.propTypes = {
  url: PropTypes.string.isRequired,
};
export default ABICodeAutoComplete;
