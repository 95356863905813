import { booleanToYesNo } from '../helpers';

/**
 * Map JSON Blob returned from server to context/pageForm format
 * required for Contact Details page
 * @param {*} claimData property of JSON Blob
 * @returns data in Contact Details page form format, or undefined if section
 * not present
 */
export const mapServerToVehicleDamageDetailsPage = ({ vehicleDamage } = {}) => {
  if (!vehicleDamage) {
    return undefined;
  }
  const { isDamaged, damageDetails } = vehicleDamage;

  const {
    damageLevels,
    undersideIsDamaged,
    hasMechanicalDamage,
    mechanicalDamages,
    hasPreviousDamage,
    previousDamageDetails,
    airbagsTriggered,
    wasDrivenAway,
    isDrivable,
  } = damageDetails || {};

  const { underside: undersideDamageLevel, ...damageLevelsLocal } =
    damageLevels || {};
  const hasDamageLevels = !!Object.keys(damageLevelsLocal).length;

  // Return mapped object
  return {
    vehicleIsDamaged: booleanToYesNo(isDamaged),
    damageLevels: hasDamageLevels ? damageLevelsLocal : undefined,
    undersideIsDamaged: booleanToYesNo(undersideIsDamaged),
    undersideDamageLevel,
    hasMechanicalDamage: booleanToYesNo(hasMechanicalDamage),
    mechanicalDamages,
    hasPreviousDamage,
    previousDamageDetails: previousDamageDetails?.trim(),
    airbagsTriggered: booleanToYesNo(airbagsTriggered),
    wasDrivenAway: booleanToYesNo(wasDrivenAway),
    isDrivable: booleanToYesNo(isDrivable),
  };
};

export default mapServerToVehicleDamageDetailsPage;
