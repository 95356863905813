import { caseConverter } from './caseConverter';

const PERILS_BASIC = {
  STORM: {
    code: 'ST',
    description: 'Storm Damage',
  },
  ACCIDENTAL_DAMAGE: {
    code: 'AD',
    description: 'Accidental Damage',
  },
  ACCIDENTAL_LOSS: {
    code: 'AL',
    description: 'Accidental Loss',
  },
  FREEZER_FOOD: {
    code: 'FF',
    description: 'Freezer Food',
  },
  NON_STANDARD_PERIL: { code: 'AT', description: 'Non Standard Peril' },
  ESCAPE_OF_OIL: { code: 'EO', description: 'Escape of Oil' },
  EARTHQUAKE: { code: 'EQ', description: 'Earthquake' },
  ESCAPE_OF_WATER: { code: 'EW', description: 'Escape of Water' },
  EXPLOSION: { code: 'EX', description: 'Explosion' },
  FLOOD: { code: 'FL', description: 'Flood' },
  FIRE: { code: 'FR', description: 'Fire' },
  IMPACT_AERIAL_DEVICE: { code: 'IA', description: 'Impact - Aerial Device' },
  IMPACT_FALLEN_TREES: { code: 'IF', description: 'Impact - Fallen Trees' },
  IMPACT_VEHICLE: { code: 'IV', description: 'Impact - Vehicle' },
  IMPACT_WILD_ANIMALS: { code: 'IW', description: 'Impact - Wild Animals' },
  LIABILITY: { code: 'LB', description: 'Liability' },
  LIGHTNING: { code: 'LG', description: 'Lightning' },
  MALICIOUS_DAMAGE: { code: 'MD', description: 'Malicious Damage' },
  RIOT_AND_CIVIL_COMMOTION: {
    code: 'RC',
    description: 'Riot and Civil Commotion',
  },
  SUBSIDENCE: { code: 'SB', description: 'Subsidence' },
  SMOKE: { code: 'SM', description: 'Smoke' },
  THEFT: { code: 'TF', description: 'Theft' },
  UNDERGROUND_SERVICES_DAMAGE: {
    code: 'UG',
    description: 'Underground Services - Damage',
  },
  BORDEREAU: { code: 'ZZ', description: 'Bordereau' },
};

const PERIL_LONG_CODES = Object.keys(PERILS_BASIC);

export const PERIL_CODES = PERIL_LONG_CODES.map(
  peril => PERILS_BASIC[peril].code,
);

// Add other descriptions
// lower:             lower case
// upper:             UPPER CASE
// capitaliseFirst:   Capitalise first word
// capitalise:        Capitalise All Words
PERIL_LONG_CODES.forEach(perilCode => {
  const peril = PERILS_BASIC[perilCode];
  peril.lower = caseConverter(peril.description, 'lower');
  peril.upper = caseConverter(peril.description, 'upper');
  peril.capitaliseFirst = caseConverter(peril.description, 'capitaliseFirst');
  peril.capitalise = caseConverter(peril.description, 'capitalise');
});

export const PERILS = PERILS_BASIC;
export const PERILS_LIST = Object.keys(PERILS).map(
  peril => PERILS_BASIC[peril],
);

export const getPerilByCode = code =>
  PERILS_LIST.find(peril => peril.code === code);
