import React from 'react';
import PropTypes from 'prop-types';
import config from '../../helpers/config';

export const checkFeatureSwitch = (featureKey, acceptableValue = []) => {
  if (featureKey && acceptableValue.length) {
    if (
      !config.client[featureKey] ||
      acceptableValue.indexOf(config.client[featureKey]) < 0
    ) {
      return false;
    }
  }
  return true;
};

const FeatureSwitch = ({ featureKey, acceptableValue = [], children }) => {
  if (checkFeatureSwitch(featureKey, acceptableValue)) {
    return <> {children} </>;
  }
  return null;
};

export default FeatureSwitch;

FeatureSwitch.propTypes = {
  featureKey: PropTypes.string,
  acceptableValue: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

FeatureSwitch.defaultProps = {
  featureKey: undefined,
  children: undefined,
  acceptableValue: undefined,
};
