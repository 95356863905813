import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import {
  occupationFields,
  employmentStatuses,
  employmentCodes,
  employerCodes,
  employStatusErrorField,
} from '../constants/LookupValues';

const OccupationSetup = {
  EmploymentStatusField: occupationFields.employmentStatus,
  occupationField: occupationFields.occupation,
  employerField: occupationFields.employer,
};

function getErrorMessage(occupation) {
  let message = messages.INVALID_OCCUPATION;
  const regex = /^'[aeiouAEIOU].*/; // if we have an occupation status starting with a vowel we need to use An rather than a. Not that it is sent with single quote eg. ("'Student'")
  let article = 'A ';
  if (occupation.match(regex) != null) {
    article = 'An ';
  }
  message = message.replace('#EmpStatus#', article + occupation);

  return message;
}

function mergeAlternatives(alternatives) {
  let mergedAlternatives = Joi.alternatives();
  alternatives.forEach(alternative => {
    mergedAlternatives = mergedAlternatives.when(alternative.field, {
      is: alternative.is,
      then: alternative.then.error(() => alternative.error),
    });
  });
  return mergedAlternatives;
}

function getAlternatives(config, codeType) {
  const nonEmployedCodes = codeType.housePerson
    .concat(codeType.student)
    .concat(codeType.unemployed)
    .concat(codeType.retired);

  return employmentStatuses.student
    .map(item => {
      return {
        field: config.EmploymentStatusField,
        is: item,
        then: Joi.valid(codeType.student),
        error: getErrorMessage(employStatusErrorField.Student),
      };
    })
    .concat(
      employmentStatuses.retired.map(item => {
        return {
          field: config.EmploymentStatusField,
          is: item,
          then: Joi.valid(codeType.retired),
          error: getErrorMessage(employStatusErrorField.Retired),
        };
      }),
    )
    .concat(
      employmentStatuses.unemployed.map(item => {
        return {
          field: config.EmploymentStatusField,
          is: item,
          then: Joi.valid(codeType.unemployed),
          error: getErrorMessage(employStatusErrorField.Unemployed),
        };
      }),
    )
    .concat(
      employmentStatuses.housePerson.map(item => {
        return {
          field: config.EmploymentStatusField,
          is: item,
          then: Joi.valid(codeType.housePerson),
          error: getErrorMessage(employStatusErrorField.HousePerson),
        };
      }),
    )
    .concat(
      employmentStatuses.employed.map(item => {
        return {
          field: config.EmploymentStatusField,
          is: item,
          then: Joi.invalid(nonEmployedCodes),
          error: getErrorMessage(employStatusErrorField.Employed),
        };
      }),
    );
}

/**
 * @description: Validates fields using config to ensure occupations are consistent
 * @example employmentStatus must match up with occupation and employer so cannot have unemployed status with an employed occupation.
 * @param config The configuration for the fields object should contain { EmploymentStatusField: '', occupationField: '', employerField: '' }
 * @succeeds when the status, occupation and employer fields are consistent
 * @fails when status, occupation and employer fields are not consistent
 */
export const employmentValidations = (config = OccupationSetup) => {
  return Joi.object()
    .pattern(Joi.valid(config.EmploymentStatusField), Joi.string().required())
    .concat(
      Joi.object().pattern(
        Joi.valid(config.occupationField),
        mergeAlternatives(getAlternatives(config, employmentCodes)),
      ),
    )
    .concat(
      Joi.object().pattern(
        Joi.valid(config.employerField),
        mergeAlternatives(getAlternatives(config, employerCodes)),
      ),
    );
};

export default employmentValidations;

/*
    return Joi.object().pattern(Joi.valid(keys), Joi.object().valid("true").error(()=>"ERROR IN BLAH"));
    */
