import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Dropdown, Label, ErrorMessage } from 'ageas-ui-components';
import expandArrow from '../../assets/expandArrow.svg';

const InputContainer = styled.fieldset`
  border: none;
  display: block;
  text-align: left;
`;

const LabelText = styled.div`
  padding-right: 30px;
`;

const DropdownWrapper = styled.div`
  width: 560px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

class TimeDropdownInput extends Component {
  state = {
    hours: '',
    minutes: '',
  };

  componentDidMount() {
    this.updateDefaultTime();
  }

  componentDidUpdate() {
    this.updateCallback(false);
    this.updateDefaultTime();
  }

  parseDefaultHours = defaultHours => {
    const parsedHours = parseInt(defaultHours, 10);
    if (typeof parsedHours === 'number' && parsedHours >= 0 && parsedHours < 24)
      return parsedHours;
    return '';
  };

  parseDefaultMinutes = defaultMinutes => {
    const parsedMinutes = parseInt(defaultMinutes, 10);
    if (
      typeof parsedMinutes === 'number' &&
      parsedMinutes >= 0 &&
      parsedMinutes < 60
    )
      return parsedMinutes;
    return '';
  };

  updateDefaultTime = () => {
    const { hoursDefaultValue, minutesDefaultValue } = this.props;
    const { hours, minutes } = this.state;
    // Update initial hours state if specified and valid
    const defaultHours = this.parseDefaultHours(hoursDefaultValue);
    if (hours === '' && defaultHours !== hours) {
      this.setState({ hours: defaultHours.toString() });
      this.forceUpdate();
    }

    // Update initial minutes state if specified and valid
    const defaultMinutes = this.parseDefaultMinutes(minutesDefaultValue);
    if (minutes === '' && defaultMinutes !== minutes) {
      this.setState({ minutes: defaultMinutes.toString() });
      this.forceUpdate();
    }
  };

  updateCallback = clearDate => {
    const { hours, minutes } = this.state;
    const { handleTime } = this.props;

    if (minutes !== '' && hours !== '') {
      handleTime(
        `${(hours.length === 1 ? '0' : '') + hours}:${
          minutes.length === 1 ? '0' : ''
        }${minutes}`,
      );
    }
    if (clearDate) {
      handleTime(null);
    }
  };

  handleSelect = (event, selectType) => {
    this.setState({
      [selectType]: event.target.value,
    });

    this.updateCallback(true);
  };

  renderNumberOption = (value, leadingZero) => {
    let formatValue = value.toString();
    if (leadingZero && value >= -9 && value <= 9) {
      formatValue = `0${formatValue}`;
    }
    return (
      <option key={value} value={value}>
        {formatValue}
      </option>
    );
  };

  render() {
    const {
      title,
      isRequired,
      errorMsg,
      dropdownId,
      hint,
      size,
      placeholderHours,
      placeholderMinutes,
      formInput,
      disabled,
      // hoursDefaultValue,
      // minutesDefaultValue,
    } = this.props;
    const { hours, minutes } = this.state;

    return (
      <InputContainer>
        {title && (
          <legend>
            <Label hint={hint} size={size} fieldName={dropdownId}>
              <LabelText>
                {title} {isRequired && '*'}
              </LabelText>
            </Label>
          </legend>
        )}
        <DropdownWrapper>
          <Dropdown
            aria-label="Select Hours"
            name={`${formInput.name}Hours`}
            id={`${dropdownId}Hours`}
            onChange={event => this.handleSelect(event, 'hours')}
            data-testid={`${dropdownId}HoursPicker`}
            hasError={
              errorMsg !== '' && errorMsg.toLowerCase().includes('hours')
            }
            dropdownIcon={expandArrow}
            size="x-small"
            disabled={disabled}
            value={hours}
          >
            <option value="" key="">
              {placeholderHours}
            </option>
            {_.range(0, 24, 1).map(value => {
              return this.renderNumberOption(value, true);
            })}
          </Dropdown>
          <Dropdown
            aria-label="Select Minutes"
            name={`${formInput.name}Minutes`}
            id={`${dropdownId}Minutes`}
            onChange={event => this.handleSelect(event, 'minutes')}
            data-testid={`${dropdownId}MinutesPicker`}
            hasError={
              errorMsg !== '' && errorMsg.toLowerCase().includes('minutes')
            }
            dropdownIcon={expandArrow}
            size="x-small"
            disabled={disabled}
            // defaultValue={minutesDefaultValue}
            value={minutes}
          >
            <option value="" key="">
              {placeholderMinutes}
            </option>
            {_.range(0, 60, 1).map(value => {
              return this.renderNumberOption(value, true);
            })}
          </Dropdown>
        </DropdownWrapper>
        {errorMsg && (
          <div
            aria-live="assertive"
            aria-relevant="additions removals"
            id={`${dropdownId}-error-area-selector`}
            data-testid={`${dropdownId}-error-area-selector`}
          >
            <ErrorMessage hasIcon>{errorMsg}</ErrorMessage>
          </div>
        )}
      </InputContainer>
    );
  }
}

TimeDropdownInput.propTypes = {
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  errorMsg: PropTypes.string,
  handleTime: PropTypes.func,
  dropdownId: PropTypes.string.isRequired,
  hint: PropTypes.node,
  size: PropTypes.string,
  placeholderHours: PropTypes.string,
  placeholderMinutes: PropTypes.string,
  formInput: PropTypes.shape({ name: PropTypes.string }),
  disabled: PropTypes.bool,
  hoursDefaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minutesDefaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TimeDropdownInput.defaultProps = {
  title: '',
  isRequired: false,
  errorMsg: '',
  handleTime: () => {},
  hint: undefined,
  size: '',
  placeholderHours: '-hours-',
  placeholderMinutes: '-minutes-',
  formInput: {},
  disabled: false,
  hoursDefaultValue: '',
  minutesDefaultValue: '',
};

export default TimeDropdownInput;
