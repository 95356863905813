import {
  ageasApprovedSuppliersTitle,
  ageasApprovedSuppliersContent,
} from './AgeasApprovedSuppliers';

const ageasApprovedSuppliersFactors = [
  {
    title: ageasApprovedSuppliersTitle,
    content: ageasApprovedSuppliersContent,
  },
];
export default ageasApprovedSuppliersFactors;
