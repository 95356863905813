import { caseConverter } from './caseConverter';

const MOTOR_CLAIM_TYPES_BASIC = {
  ACCIDENT: {
    code: 'A',
    description: 'Accident',
  },
  FIRE: {
    code: 'F',
    description: 'Fire',
  },
  THEFT: {
    code: 'T',
    description: 'Theft',
  },
  WINDSCREEN: {
    code: 'W',
    description: 'Windscreen',
  },
};

const MOTOR_CLAIM_TYPES_LONG_CODES = Object.keys(MOTOR_CLAIM_TYPES_BASIC);

export const MOTOR_CLAIM_TYPES_CODES = MOTOR_CLAIM_TYPES_LONG_CODES.map(
  type => MOTOR_CLAIM_TYPES_BASIC[type].code,
);

// Add other descriptions
// lower:             lower case
// upper:             UPPER CASE
// capitaliseFirst:   Capitalise first word
// capitalise:        Capitalise All Words
MOTOR_CLAIM_TYPES_LONG_CODES.forEach(typeCode => {
  const type = MOTOR_CLAIM_TYPES_BASIC[typeCode];
  type.lower = caseConverter(type.description, 'lower');
  type.upper = caseConverter(type.description, 'upper');
  type.capitaliseFirst = caseConverter(type.description, 'capitaliseFirst');
  type.capitalise = caseConverter(type.description, 'capitalise');
});

export const MOTOR_CLAIM_TYPES = MOTOR_CLAIM_TYPES_BASIC;

export const MOTOR_CLAIM_TYPES_LIST = Object.keys(MOTOR_CLAIM_TYPES).map(
  typeCode => MOTOR_CLAIM_TYPES_BASIC[typeCode],
);

export const getClaimTypeByCode = code =>
  MOTOR_CLAIM_TYPES_LIST.find(type => type.code === code);
