import React from 'react';

import GenericMotorFormPageWithPolicyFetch from './GenericMotorFormPageWithPolicyFetch';
import STAGES from './motorClaimNewHelpers';

const PAGE_SECTION_NAME = 'claimOutcome';

const MotorClaimOutcome = () => {
  return (
    <GenericMotorFormPageWithPolicyFetch
      pageSectionName={PAGE_SECTION_NAME}
      breadcrumbId="claimOutcome"
      pageTitle="Claim Outcome"
      showContinue={false}
      stage={STAGES.OUTCOME.name}
    />
  );
};

export default MotorClaimOutcome;
