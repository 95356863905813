import styled from 'styled-components';
import media from '../MediaQuery/MediaQuery';

export const EditWrapper = styled.div`
  width: 80px;
  margin-bottom: 25px;
  display: inline-block;
  ${media.phone`
    width: 35%;
    margin-bottom: 0px;
  `}
`;

export const SummaryContent = styled.div`
  display: flex;
  align-items: flex-end;
  ${media.phone`
    flex-wrap: wrap;
  `}
`;
export const FieldFlexColumn = styled.div`
  width: 100%;
`;
export const EditFlexColumn = styled.div`
  width: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  ${media.phone`
    width:100%;
    margin-bottom: 0px;
    text-align: right;
  `}
`;
