import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  nestingControlPropTypes,
  nestingControlDefault,
} from './accordionNestingProp';

const nestingControlNextLevel = nestingControl => {
  return {
    ...nestingControl,
    isNested: true,
  };
};

const cloneChild = (
  child,
  toggleSection,
  currentSelection,
  selectionChanged,
  nestingControl,
  index,
) => {
  // Child is not a valid element for cloning (e.g. is text node or undefined)
  // return as-is
  if (!React.isValidElement(child)) {
    return child;
  }

  const key = `${child.props.title || 'x'}_${index}`;
  // Basic new props _ applicable to any chldren
  const newProps = {
    key,
  };
  // Component new props - applicable only if child is a component
  if (typeof child.type === 'function') {
    newProps.nestingControl = { ...nestingControl };
    if (child.props.title) {
      newProps.toggle = toggleSection(child.props.title);
      newProps.isSelected = currentSelection === child.props.title;
      newProps.nestingControl.path = [
        ...newProps.nestingControl.path,
        child.props.title,
      ];
      if (newProps.isSelected) {
        // Scroll into view logic - scroll into view if has just become
        // selected, and if forcePath either equals this path (implied if
        // selected and path lengths match) or if no force path
        if (
          selectionChanged &&
          (!newProps.nestingControl.forcePath?.length ||
            newProps.nestingControl.path?.length ===
              newProps.nestingControl.forcePath?.length)
        ) {
          newProps.scrollIntoView = true;
        }
      }
      // Only send down forcePath if child is selected
      // No sense forcing re-render of children that will not match
      else {
        newProps.nestingControl.forcePath = undefined;
      }
    }
  }
  return React.cloneElement(child, newProps);
};

const Accordion = ({ children, nestingControl, defaultSelection }) => {
  const nestingControlFull = {
    ...nestingControlDefault,
    ...nestingControl,
  };
  const selectionRef = useRef({
    current: defaultSelection,
    prior: defaultSelection,
  });
  // Do not direct update setCurrentSelection(), update via setSelection()
  const [currentSelection, setCurrentSelection] = useState(defaultSelection);
  let forcePath;
  let setForcePath;
  if (nestingControl.nestLevel === 1) {
    [forcePath, setForcePath] = useState(undefined);
    nestingControlFull.forcePath = forcePath;
    nestingControlFull.setForcePath = setForcePath;
  }

  // Maintain real-time record of current and previous selection, for informing
  // scrollIntoView prop for children
  const setSelection = selection => {
    selectionRef.current.prior = selectionRef.current.current;
    selectionRef.current.current = selection;
    setCurrentSelection(selection);
  };

  // Cascade and Force close prop set? Deselect self
  useEffect(() => {
    if (
      nestingControlFull.cascadeClose &&
      nestingControlFull.forceClose &&
      currentSelection
    ) {
      setSelection(undefined);
    }
  }, [
    nestingControlFull.cascadeClose,
    nestingControlFull.forceClose,
    currentSelection,
  ]);

  // path and force path set? Show child if matches
  useEffect(() => {
    // Run if forcePath
    if (
      nestingControlFull.forcePath?.length &&
      nestingControlFull.forcePath?.length >= nestingControlFull.nestLevel
    ) {
      // Level 1? Always matches. Not level 1? match ancestors
      if (
        nestingControlFull.nestLevel === 1 ||
        nestingControlFull.path.every(
          (path, i) => path === nestingControlFull.forcePath[i],
        )
      ) {
        // Set self to next child in force path
        setSelection(
          nestingControlFull.forcePath[nestingControlFull.nestLevel - 1],
        );
      }
    }
  }, [nestingControlFull.path, nestingControlFull.forcePath]);

  // Header clicked? Toggle
  const toggleSection = title => {
    return event => {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      if (event?.stopPropagation) {
        event.stopPropagation();
      }
      if (title === currentSelection) {
        setSelection(undefined);
      } else {
        setSelection(title);
      }
      // This item was toggled? Clear out force path, it no longer applies
      nestingControlFull.setForcePath(undefined);
    };
  };

  const nestingControlChild = nestingControlNextLevel(nestingControlFull);

  // Multiple children
  if (Array.isArray(children)) {
    return children.map((child, index) => {
      return cloneChild(
        child,
        toggleSection,
        currentSelection,
        selectionRef.current.current !== selectionRef.current.prior,
        nestingControlChild,
        index,
      );
    });
  }

  // Single child (or no child)
  return cloneChild(
    children,
    toggleSection,
    currentSelection,
    selectionRef.current.current !== selectionRef.current.prior,
    nestingControlChild,
  );
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nestingControl: nestingControlPropTypes,
  defaultSelection: PropTypes.string,
};

Accordion.defaultProps = {
  children: [],
  nestingControl: undefined,
  defaultSelection: undefined,
};

export default Accordion;
