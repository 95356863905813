import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  ThemedLabel,
  ThemedTextAreaInput,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';

// For documentation see:
// /docs/components/Forms/StandardTextarea/StandardTextarea.md

const TextArea = styled(ThemedTextAreaInput)`
  background-color: white;
  font-family: inherit;
  ${({ resize }) =>
    resize &&
    css`
      resize: ${resize};
    `}
`;

const StandardTextarea = ({
  id,
  name,
  label,
  secondaryLabel,
  validate,
  children,
  onChange,
  fieldProps,
  blockProps,
  resize,
  ...props
}) => {
  const idRef = useRef(null);
  if (!idRef.current) {
    idRef.current = id || `StandardTextarea-${name}-${_uniqueId()}`;
  }

  const onTextareaChange = (event, input) => {
    input.onChange(event);
    onChange(event);
  };

  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input, meta }) => (
        <>
          {label && (
            <ThemedLabel fieldName={idRef.current}>
              {label}
              {secondaryLabel && (
                <>
                  <br />
                  <small>{secondaryLabel}</small>
                </>
              )}
            </ThemedLabel>
          )}
          <FieldInnerBlockContainer {...blockProps}>
            <TextArea
              {...props}
              id={idRef.current}
              {...input}
              fieldName={input.name}
              onChange={event => {
                onTextareaChange(event, input);
              }}
              validInput={{
                isValid: !(meta.error && meta.touched),
              }}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
              hasError={meta.error && meta.touched}
              resize={resize}
            />
          </FieldInnerBlockContainer>
          {meta.error && meta.touched && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validate: PropTypes.func,
  children: PropTypes.node,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  fieldProps: PropTypes.shape({}),
  blockProps: PropTypes.shape({}),
  resize: PropTypes.string,
};

StandardTextarea.defaultProps = {
  id: undefined,
  label: '',
  secondaryLabel: undefined,
  validate: () => {},
  children: undefined,
  onChange: () => {},
  maxLength: undefined,
  fieldProps: {},
  blockProps: {},
  resize: undefined,
};

export default StandardTextarea;
