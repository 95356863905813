// TODO Update font families when fonts for Rias are included
import styled, { css } from 'styled-components';

import media, { sizes } from '../../../components/MediaQuery/MediaQueryMin';
import Icon from '../../../components/Icon/Icon';

const hideVisually = () => {
  return {
    border: '0',
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  };
};

const Header = styled.header`
  width: 100%;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;

  ${media.desktop`
    max-width: 1000px;
    min-width: auto;
  `}
`;

const LogoContainer = styled.figure`
  align-items: center;
  display: flex;
  margin: 0;
  width: ${(100 / 12) * 5}%;

  &:hover {
    cursor: pointer;
  }

  ${media.tablet`
    width: ${(100 / 12) * 8}%;
  `}
`;

const Logo = styled.img`
  box-sizing: border-box;
  padding: 12px;
  max-width: 175px;
  width: 100%;
`;

const LogoText = styled.figcaption`
  color: ${({ theme }) => theme.header.title};
  font-size: 1.85em;
  padding-left: 5px;
  text-decoration: none;
  text-transform: uppercase;

  // Hide text visually only on mobile
  @media (max-width: ${sizes.tablet - 1}px) {
    ${hideVisually()}
  }
`;

const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  text-align: right;

  ${media.tablet`
    padding: 15px 15px 0 15px;
  `}
`;

const TitleWrapper = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.header.input};
  border-radius: 0 0 5px 5px;
  display: flex;
  font-size: 1em;
  margin: 0 0 5px 0;
  overflow: hidden;
  text-align: left;

  & > div {
    background-color: ${({ theme }) => theme.header.input};
    height: 100%;
  }

  ${media.tablet`
    display: none;
  `}
`;

const MobileTitle = styled.h3`
  color: ${({ theme }) => theme.header.input};
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 12px 15px 12px 6px;
`;

const PlusIcon = styled(Icon).attrs(({ theme }) => ({
  brandIconSet: 'rias',
  color: theme.white,
}))``;

const HelpTitle = styled.h3`
  color: #666;
  display: none;
  font-size: 1em;
  margin: 0 15px 5px 0;

  ${media.tablet`
    display: block;
  `}
`;

const ContactDetails = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.header.input};
  border-radius: 5px;
  min-width: 180px;
  padding: 10px 10px 20px 10px;
  position: absolute;
  right: 0;
  top: 47px;
  z-index: 2;

  ${({ visible }) =>
    !visible &&
    css`
      @media (max-width: ${sizes.tablet - 1}px) {
        ${hideVisually()}
      }
    `}

  ${media.tablet`
    border: none;
    border-radius: 0;
    margin: 0 0 10px 0;
    padding: 0;
    position: static;
    right: initial;
    top: initial;
    z-index: initial;
  `}
`;

const PhoneIcon = styled(Icon).attrs(({ theme }) => ({
  brandIconSet: 'rias',
  color: theme.header.phone,
}))`
  width: 29px;
  height: 29px;

  ${media.tablet`
    width: 39px;
    height: 39px;
  `}

  ${media.desktop`
  // TODO - change size to 44 when proper font family is inc
    width: 39px;
    height: 39px;
  `}
`;

const PhoneNumber = styled.a`
  // TODO - add Din Condensens bold font
  align-items: center;
  color: ${({ theme }) => theme.header.phone};
  display: flex;
  // TODO - change size to 1.5 when proper font family is inc
  font-size: 1em;
  justify-content: flex-end;
  line-height 1;
  margin: 0;
  position: relative;
  text-decoration: none;

  ${media.tablet`
    // TODO - change size to 2 when proper font family is inc
    font-size: 1.4em;
  `}

  ${media.desktop`
    // TODO - change size to 2.25 when proper font family is inc
    font-size: 2em;
  `}
`;

const OpenHours = styled.p`
  color: #4c4c4c;
  font-size: 0.75em;
  margin: 0;

  ${media.tablet`
    margin: 10px 0;
  `}
`;

export {
  Container,
  Header,
  LogoContainer,
  Logo,
  LogoText,
  HelpContainer,
  TitleWrapper,
  MobileTitle,
  PlusIcon,
  HelpTitle,
  ContactDetails,
  PhoneNumber,
  PhoneIcon,
  OpenHours,
};
