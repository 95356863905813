import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import StandardTextarea from '../../StandardTextarea/StandardTextarea';
import { FieldHeader } from '../../../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';

const DEFAULT_FIELD_NAME = 'incidentDetails';

export const getIncidentDetailsSchema = (fieldName = DEFAULT_FIELD_NAME) => {
  return {
    [fieldName]: stringRequired(
      [fieldName],
      'Please enter incident details',
    ).concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(/\S/, [fieldName], 'Please enter incident details'),
    ),
  };
};

const IncidentDetails = ({
  id,
  name = DEFAULT_FIELD_NAME,
  customDescription,
  maxLength = 1000,
  headerProps,
  ...rest
}) => {
  return (
    <>
      <FieldHeader {...headerProps}>
        {customDescription || (
          <>
            Please tell us in your own words what happened ({maxLength}{' '}
            characters max.)
          </>
        )}
      </FieldHeader>
      <StandardTextarea
        id={id}
        name={name}
        maxLength={maxLength}
        aria-label="incident details"
        {...rest}
      />
    </>
  );
};

IncidentDetails.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  customDescription: PropTypes.string,
  maxLength: PropTypes.number,
  headerProps: PropTypes.shape({}),
};
IncidentDetails.defaultProps = {
  id: undefined,
  name: undefined,
  customDescription: undefined,
  maxLength: undefined,
  headerProps: undefined,
};

export default IncidentDetails;
