import jwt from 'jsonwebtoken';

const getPublicPayloadFromJWTToken = token => {
  const options = {
    algorithm: ['RS256'],
  };
  const decoded = jwt.decode(token, options);
  if (!decoded) {
    throw new Error('Could not decode token');
  }
  return decoded;
};

export default getPublicPayloadFromJWTToken;
