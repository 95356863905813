import axios from 'axios';
import axiosHelper from './axios';
import config from './config';
import { consoleError } from './consoleLog';
import { policyName } from './policyContextHelper';

/**
 * Request policy details from server (unless already present) and if valid use
 * to update page context
 * * Calls setFieldDefaultsFromPolicyContext with provided policyDetails
 * * If returns true, and stops here
 * * Else requests fresh policyDetails from server
 * * Calls setFieldDefaultsFromPolicyContext with retrieved policyDetails
 * @param {*} policyReference For current claim
 * @param {*} policyDetails Current policyDetails context dataState
 * @param {*} updatePolicyDataContext Updater for policyDetails context
 * @param {*} cancelToken Axios cancelToken
 * @param {*} setFieldDefaultsFromPolicyContext Function to validate if
 * policyDetails context is ok to default from, and if so, update page context
 * as needed.
 * Function parameters:
 * * policyDetails
 * Function processing:
 * * Validate if policyDetails contains required data.
 * * If yes, return true (and use to update page context to default fields)
 * * If no, return false
 *
 * @returns Updated policyDetails if success, or undefined if axios cancel, or
 * throws error
 */
const motorContinuableClaimFetchPolicyData = async (
  policyReference,
  policyDetails,
  incidentDateTime,
  vehicleRegistration,
  updatePolicyDataContext,
  cancelToken,
  setFieldDefaultsFromPolicyContext = () => false,
) => {
  // If context set and valid for policy, test if required data is present.
  // If yes, stop here
  if (
    policyDetails?.incidentDateTime &&
    policyDetails?.incidentDateTime === incidentDateTime &&
    policyDetails?.vehicleRegistration &&
    policyDetails?.vehicleRegistration === vehicleRegistration &&
    policyDetails?.policyReference &&
    policyDetails?.policyReference === policyReference &&
    setFieldDefaultsFromPolicyContext(policyDetails)
  ) {
    return policyDetails;
  }
  // Required details not present or not suitable, retrieve
  try {
    const result = await axiosHelper.get(
      `${
        config.client.getMotorPolicyDetails_endpoint
      }?policyReference=${encodeURIComponent(
        policyReference,
      )}&incidentDate=${encodeURIComponent(
        incidentDateTime,
      )}&vrn=${encodeURIComponent(vehicleRegistration)}`,
      {
        cancelToken,
      },
    );

    const policyDetailsNew = {
      policyReference,
      incidentDateTime,
      vehicleRegistration,
      data: result.data,
    };
    updatePolicyDataContext(policyName.policyDetails, policyDetailsNew);
    // Default fields in this page's context from fetched policy data
    setFieldDefaultsFromPolicyContext(policyDetailsNew);

    return policyDetailsNew;
  } catch (error) {
    if (axios.isCancel(error)) {
      return undefined;
    }
    consoleError('fetch policy details error', error);
    throw error;
  }
};

export default motorContinuableClaimFetchPolicyData;
