import React from 'react';
import { Route } from 'react-router-dom';
import cookie from 'react-cookies';
import NotFoundRoute from './NotFoundRoute';
import { NotFoundErrorView } from '../views/ErrorViews/NotFoundErrorView';
import { withContext } from '../contexts/AuthContext';
import config from '../helpers/config';
import { checkFeatureSwitch } from '../components/FeatureSwitch/FeatureSwitch';
import NavigateTo from '../components/Navigation/NavigateTo/NavigateTo';
import getTPAPaths from './MotorClaimsTpHubRoutes/motorClaimsTpHubRoutePaths';

const RedirectToNotFound = () => {
  return <NotFoundRoute component={NotFoundErrorView} />;
};

const RedirectToLogin = (authTypes = [], lobs = [], targetPath) => {
  // Where are we redirecting to? Portal, motor OTP, or home OTP/basic?
  const lob = lobs[0] || 'motor';
  const type = cookie.load('last_login_method');
  // portal, only if last type was portal and route allows portal
  if (type === 'portal' && authTypes.some(x => x === 'portal')) {
    if (typeof window !== 'undefined') {
      window.location.assign(
        `${config.client.portalLoginUrl}&target=makeMotorClaim`,
      );
    }
    return null;
  }

  // type = OTP or unknown
  let path;
  const params = new URLSearchParams();
  if (lob === 'motor') {
    path = `/motorclaim/brokerlogin`;
    if (type === 'portal') {
      params.append('portalLink', 'true');
    }
  } else if (lob === 'motorTPA') {
    path = getTPAPaths().LOGIN;
  } else {
    path = `/homeclaim/brokerlogin`;
  }

  params.append('target', targetPath);
  const paramStr = params.toString();
  if (paramStr) {
    path += `?${paramStr}`;
  }
  return <NavigateTo path={path} />;
};

const toArray = value => {
  let valueOut = value;
  if (typeof value !== 'undefined' && !Array.isArray(value)) {
    valueOut = [value];
  }
  return valueOut;
};

// Class component
class EclaimsRoute extends Route {
  render() {
    // Knock out if feature key not enabled
    if (
      !checkFeatureSwitch(
        this.props.featureKey,
        toArray(this.props?.acceptableValue),
      )
    ) {
      return <Route component={RedirectToNotFound} />;
    }

    // Knock out if not logged in
    if (this.props.private) {
      const authTypes = toArray(this.props.authTypes);
      const lobs = toArray(this.props.lobs);
      if (!this.props.checkLogin(authTypes, lobs)) {
        return (
          <Route>
            {RedirectToLogin(
              authTypes,
              lobs,
              this.props.location.pathname + this.props.location.search,
            )}
          </Route>
        );
      }
    }

    return super.render();
  }
}

export default withContext(EclaimsRoute);
