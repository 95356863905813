import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

const validKeys = ['registration', 'registrationNumber'];

/**
 * @description: Validate the field is a valid UK car Registration plate.
 * @param keys Fields to validate
 * @succeeds when field is a valid car registration plate
 * @fails when field is not a valid car registration plate
 */
export const registrationNumber = (
  keys = validKeys,
  message = messages.INVALID_REGISTRATION,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex.registrationNumber)
      .required()
      .error(() => message),
  );
};

export default registrationNumber;
