import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

import { regex } from '../constants/RegularExpressions';

const validKeys = [];

/**
 * @description: Validate the field is at least the minimum specified
 * @param minVal the minimum the field should be
 * @param canBeEqual if true (default) then the validation will succeed if the value is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when value of field is greater than or equal to the minMonths parameter
 * @fails when value of the field is less than min parameter
 */
export const min = (
  minVal,
  keys = validKeys,
  canBeEqual = true,
  message = messages.MIN,
) => {
  if (canBeEqual) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.number()
        .min(minVal)
        .required()
        .error(() => message),
    );
  }
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.number()
      .greater(minVal)
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field is at least the length specified
 * @param length the minimum length the field should be
 * @param keys Fields to validate
 * @param config if true then the validation will allow numbers alone
 * @succeeds when length of field is greater than or equal to the length parameter
 * @fails when length of the field is less than length parameter
 */
export const minLength = (
  length,
  keys = validKeys,
  message = messages.MIN_LENGTH,
  config = {},
) => {
  if (config.isNumber) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.string()
        .regex(regex.dobYear)
        .min(length)
        .error(() => message),
    );
  }
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .min(length)
      .error(() => message),
  );
};

/**
 * @description: Validate the age of the person is at least the minimum age specified
 * @param age the minimum age the person should be
 * @param canBeEqual if true (default) then the validation will succeed if the age is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when age of the person is greater than or equal to the minAge parameter
 * @fails when age of the person is less than minAge parameter
 */
export const minAge = (
  age,
  keys = validKeys,
  canBeEqual = true,
  message = messages.MIN_AGE,
) => min(age, keys, canBeEqual, message);

/**
 * @description: Validate the age of the person is at least the minimum age in days specified
 * @param minDays the minimum amount of days the person should be
 * @param canBeEqual if true (default) then the validation will succeed if the age is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when age of the person in days is greater than or equal to the minDays parameter
 * @fails when age of the person in days is less than minDays parameter
 */
export const minAgeInDays = (
  minDays,
  keys = validKeys,
  canBeEqual = true,
  message = messages.MIN_AGEINDAYS,
) => min(minDays, keys, canBeEqual, message);

/**
 * @description: Validate the age of the person is at least the minimum age in months specified
 * @param minMonths the minimum amount of months the person should be
 * @param canBeEqual if true (default) then the validation will succeed if the age is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when age of the person in months is greater than or equal to the minMonths parameter
 * @fails when age of the person in months is less than minMonths parameter
 */
export const minAgeInMonths = (
  minMonths,
  keys = validKeys,
  canBeEqual = true,
  message = messages.MIN_AGEINMONTHS,
) => min(minMonths, keys, canBeEqual, message);
