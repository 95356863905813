import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
// import ReCAPTCHA from './ReCAPTCHADummy';

const ReCAPTCHAHelper = ({ apiCallback = () => {}, ...props }) => {
  const apiRef = useRef({ executeAsync: () => 'dummytoken' });

  useEffect(() => {
    apiCallback(apiRef);
  }, []);

  return <ReCAPTCHA ref={apiRef} {...props} />;
};

export default ReCAPTCHAHelper;

ReCAPTCHAHelper.propTypes = {
  apiCallback: PropTypes.func,
};

ReCAPTCHAHelper.defaultProps = {
  apiCallback: undefined,
};
