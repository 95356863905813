import styled, { css } from 'styled-components';

export const Status = styled.span`
  ${({ open }) =>
    !open
      ? ''
      : css`
          color: green;
        `};
`;

export const HistoryTitleHolder = styled.div`
  margin: 21px 0px 21px 0px;
`;
