export const yesNoToBoolean = (value, ifOther = undefined) => {
  const valueSanitised = value?.trim()?.toLowerCase();
  if (valueSanitised === 'yes') {
    return true;
  }
  if (valueSanitised === 'no') {
    return false;
  }
  return ifOther;
};

export const isYes = value => value === 'yes';

export const booleanToYesNo = (value, ifOther = undefined) => {
  if (value === true) {
    return 'yes';
  }
  if (value === false) {
    return 'no';
  }
  return ifOther;
};

export const isOther = value => value?.trim() === 'other';

export default yesNoToBoolean;
