import { hideThirdParty } from './SingleDriveCircumstanceHelper';
import { dateTimeConverter } from './dateTimeConverter';

const addressMapper = ele => {
  const address = {
    houseNumberName: ele.houseNumberName || undefined,
    address1: ele.address1 || undefined,
    address2: ele.address2 || undefined,
    address3: ele.address3 || undefined,
    address4: ele.address4 || undefined,
    postcode: ele.postcode?.replace(/ /g, '') || undefined,
  };
  let anythingThere = false;

  Object.keys(address).forEach(item => {
    if (address[item] !== undefined) {
      anythingThere = true;
    }
  });

  return anythingThere ? address : undefined;
};

const contactDetailsMapper = ele => {
  const contactDetails = {
    address: addressMapper(ele),
    homeTelephone: ele.homeTelephone || undefined,
    mobileTelephone: ele.mobileTelephone || undefined,
    emailAddress: ele.emailAddress || undefined,
  };
  let anythingThere = false;

  Object.keys(contactDetails).forEach(item => {
    if (contactDetails[item] !== undefined) {
      anythingThere = true;
    }
  });

  return anythingThere ? contactDetails : undefined;
};

const personDetailsMapper = formData => {
  if (formData !== undefined) {
    return formData.map(ele => {
      return {
        title: ele.title || undefined,
        firstName: ele.firstName || undefined,
        lastName: ele.lastName || undefined,
        contactDetails: contactDetailsMapper(ele),
      };
    });
  }
  return undefined;
};

const personDetailsMapperSingle = formData => {
  if (!formData) {
    return undefined;
  }
  return personDetailsMapper([formData[0]])[0];
};

const tpField = formData => {
  if (
    !hideThirdParty(formData.motorIncidentDetail.circumstanceCode) &&
    formData.involvedPartiesDetail.tpVehicleKnown === 'yes'
  ) {
    return [
      {
        vehicleRegistration:
          formData.involvedPartiesDetail.tpVehicleVRN?.replace(/ /g, '') ||
          undefined,
        taxi: formData.involvedPartiesDetail.tpVehicleIsTaxi === 'yes',
        foreign: formData.involvedPartiesDetail.tpVehicleIsForeign === 'yes',
        driver: personDetailsMapperSingle(
          formData.involvedPartiesDetail.tpDriverFields,
        ),
        passengers: {
          passengerCount: +formData.involvedPartiesDetail.tpHasPassengers,
          details: personDetailsMapper(
            formData.involvedPartiesDetail.tpPassengers,
          ),
        },
      },
    ];
  }

  return undefined;
};

const buildCreateClaimObject = formData => {
  return {
    incidentDetails: {
      policyReference: formData.motorIncidentDetail.policyNumber,
      incidentDate: dateTimeConverter(
        formData.motorIncidentDetail.incidentDate,
        formData.motorIncidentDetail.incidentTime,
      ).toISOString(),
      circumstance: {
        nearestRoad: formData.motorIncidentDetail.incidentStreet,
        nearestTown: formData.motorIncidentDetail.incidentTown,
        circumstanceCode: formData.motorIncidentDetail.circumstanceCode,
      },
      insuredDetails: {
        contactDetails: {
          homeTelephone: formData.motorContactDetail.homeTelephone,
          mobileTelephone: formData.motorContactDetail.mobileTelephone,
          emailAddress: formData.motorContactDetail.email,
          allowSMS: formData.motorContactDetail.smsUpdate === 'Yes',
        },
        vehicleRegistration: formData.motorIncidentDetail.vehicleRegistration,
        insuredDriver: {
          id: +formData.motorContactDetail.driver,
          name: '',
        },
        passengers: {
          passengerCount: +formData.involvedPartiesDetail.phHasPassengers,
          details: personDetailsMapper(
            formData.involvedPartiesDetail.phPassengers,
          ),
        },
      },

      thirdParties: tpField(formData),
    },
  };
};

export default buildCreateClaimObject;
