import React, { useState, useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Form } from 'react-final-form';
import { getTheme } from 'ageas-ui-themes';
import axios from 'axios';
import {
  ThemedButton,
  ThemedCheckbox,
  ThemedCheckedList,
  ThemedErrorMessage,
  Icon,
} from 'ageas-ui-components';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import axiosHelper from '../../helpers/axios';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import config from '../../helpers/config';
import {
  tpAssistLinkExpired,
  tpAssistLinkPreviouslyUsed,
  tpAssistServerError,
} from '../../helpers/errorMessages';
import useQuery from '../../hooks/useQuery/useQuery';
import {
  ContentHolder,
  PageContainer,
  PageSection,
  TitleText,
  TopText,
  TopTextSection,
  TopTextTitle,
} from '../../assets/styles/GenericInsurance.style';
import YesOrNo from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import VehicleRepairs from '../../assets/icons/TPAssist/vehicle_repairs.svg';
import ArrangeReplacementVehicle from '../../assets/icons/TPAssist/arrange_replacement_vehicle.svg';
import InjuryClaim from '../../assets/icons/TPAssist/personal_accident_insurance.svg';
import TPAssistFAQAccordion from '../../components/TPAssistFAQ/Accordion';
import { checkBusinessHours } from '../../helpers/checkBusinessHours';
import { getBusinessHoursDescriptive } from '../../helpers/businessHoursData';
import P from '../../components/StandardTags/P';

const Theme = getTheme(brand);

const TPAssistIcons = [
  {
    id: 'repairs',
    label: 'Vehicle repairs',
    alt: 'Vehicle repairs icon',
    src: VehicleRepairs,
  },
  {
    id: 'replacementVehicle',
    label: 'Replacement vehicle',
    alt: 'Replacement vehicle icon',
    src: ArrangeReplacementVehicle,
  },
  {
    id: 'injury',
    label: 'Injury claim',
    alt: 'Injury claim icon',
    src: InjuryClaim,
  },
];

const VehicleRepairsCheckedList = [
  'Quick and efficient service',
  'Free collection and delivery of your vehicle',
  'Genuine parts used on all repairs',
  'Minimum of a three year guarantee on all repairs',
  'Self-authorising garages - removing the need to obtain an estimate meaning repairs can start straight away',
  'Robust quality control programme with all of our approved repairers',
  'Free wash and vacuum',
  'You can choose to get your vehicle repaired at a garage of your choice. If you would like to use your own garage, simply advise us which garage you would like to use and we will liaise with them directly',
];

const IconGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

const IconImage = styled.img`
  padding-bottom: 10px;
  max-height: 80px;
`;

const IconLabel = styled.label`
  font-weight: bold;
  text-align: center;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1em;
`;

const TPAssist = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [accept, setAccept] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [linkExpiredError, setLinkExpiredError] = useState(false);
  const [linkPreviouslyUsedError, setlinkPreviouslyUsedError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [acceptError, setAcceptError] = useState(false);
  const axiosCancelToken = useRef(null);
  const queryParams = useQuery();
  const uuid = queryParams.get('uid');

  const onAcceptCheckboxChange = event => {
    setAccept(event.target.checked);
  };

  const validateUUID = () => {
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .get(
        `${
          config.client.tpAssistValidateUUID_endpoint
        }?uid=${encodeURIComponent(uuid)}`,
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(() => {
        setIsLoading(false);
        setIsValidated(true);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          const errorCode = error.response?.data?.data?.errorCode;
          if (errorCode === 'LinkExpired') {
            setLinkExpiredError(true);
          } else if (errorCode === 'AlreadyAccepted') {
            setlinkPreviouslyUsedError(true);
          } else {
            setServerError(true);
          }
        }
      });
  };

  const onSubmit = values => {
    setIsLoading(true);
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .post(
        config.client.tpAssistAccept_endpoint,
        {
          uid: uuid,
          accept: true,
          ...(values.driveable && {
            driveable: values.driveable === 'yes',
          }),
        },
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(() => {
        setIsLoading(false);
        setConfirmed(true);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setAcceptError(true);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Redirect to the CMS TPA page if a UUID string has not been provided
    if (!uuid || !/^[0-9A-F]{32}$/.test(uuid)) {
      window.location.assign('https://www.ageas.co.uk/make-a-claim/tpa/');
    }

    validateUUID();

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const renderLinkExpiredError = () => {
    return (
      linkExpiredError && (
        <ThemedErrorMessage hasIcon>{tpAssistLinkExpired()}</ThemedErrorMessage>
      )
    );
  };

  const renderLinkPreviouslyUsedError = () => {
    return (
      linkPreviouslyUsedError && (
        <ThemedErrorMessage hasIcon>
          {tpAssistLinkPreviouslyUsed()}
        </ThemedErrorMessage>
      )
    );
  };

  const renderServerErrorComponent = error => {
    return (
      error && (
        <ThemedErrorMessage hasIcon>{tpAssistServerError()}</ThemedErrorMessage>
      )
    );
  };

  const renderIcons = () => {
    return (
      TPAssistIcons && (
        <IconGroupWrapper>
          {TPAssistIcons.map(icon => (
            <IconContainer key={icon.id}>
              <IconImage src={icon.src} id={icon.id} alt={icon.alt} />
              <IconLabel htmlFor={icon.id}>{icon.label}</IconLabel>
            </IconContainer>
          ))}
          <ContentHolder>
            Want to find out more? See &apos;The benefits of claiming through
            Ageas&apos; below.
          </ContentHolder>
        </IconGroupWrapper>
      )
    );
  };

  const renderThankYou = () => {
    const businessHours = checkBusinessHours('motorTPA');
    return (
      <MessageContainer>
        {/* <ThemedIcon
          color="#90BC0D"
          size={30}
          icon="tick-roundel"
        /> */}
        <Icon
          className="icon-warning"
          brandIconSet="ageas"
          icon="tick-roundel"
          color="green"
          size={34}
        />
        <ContentHolder>
          {businessHours && (
            <strong>
              Thank you. A member of our Third Party Assistance team will call
              you shortly to help with your claim and answer any questions.
            </strong>
          )}
          {!businessHours && (
            <>
              <strong>
                Thank you. A member of our Third Party Assistance team will call
                you as soon as possible during our opening hours* to help with
                your claim and answer any questions.
              </strong>
              <P>* Opening hours: {getBusinessHoursDescriptive('motorTPA')}.</P>
            </>
          )}
        </ContentHolder>
      </MessageContainer>
    );
  };

  const renderQuestions = () => {
    return (
      isValidated &&
      !linkExpiredError &&
      !linkPreviouslyUsedError &&
      !serverError && (
        <>
          {!confirmed && (
            <>
              <ContentHolder>
                <ThemedCheckbox
                  fieldName="accept"
                  name="interestedCheckbox"
                  checked={accept}
                  onChange={onAcceptCheckboxChange}
                  text="If you are considering using our services and are interested in hearing more about it, please tick the box."
                  boldLabel={false}
                />
              </ContentHolder>
              {accept && (
                <>
                  <P>
                    Thank you for expressing an interest in our services. In
                    order for us to help you, please could you provide us with
                    some additional information:
                  </P>
                  <Form onSubmit={onSubmit}>
                    {({ handleSubmit }) => (
                      <form method="post" onSubmit={handleSubmit}>
                        <YesOrNo
                          name="driveable"
                          title={<strong>Is your vehicle driveable?</strong>}
                        />
                        {!confirmed && (
                          <ThemedButton secondary type="submit">
                            Confirm
                          </ThemedButton>
                        )}
                      </form>
                    )}
                  </Form>
                </>
              )}
              {renderServerErrorComponent(acceptError)}
            </>
          )}
          {confirmed && renderThankYou()}
        </>
      )
    );
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      <ThemeProvider theme={Theme}>
        <PageContainer>
          <BrandHeader />
          <main>
            <TopText>
              <TopTextSection>
                <TopTextTitle>Third Party Assistance</TopTextTitle>
                <TitleText>
                  Had a Motor accident with an Ageas customer?
                </TitleText>
              </TopTextSection>
            </TopText>
            <PageSection>
              {renderLinkExpiredError()}
              {renderLinkPreviouslyUsedError()}
              {renderServerErrorComponent(serverError)}
              <ContentHolder>
                We understand that if you have been involved in an accident
                which was caused by the fault of one of our customers, it can be
                both distressing and inconvenient. At Ageas, we would like to
                help you by managing your claim from start to finish, giving you
                peace of mind when you need it most.
              </ContentHolder>
              <ContentHolder>
                <TitleText primary>This is how we can help you:</TitleText>
              </ContentHolder>
              {renderIcons()}
              <ContentHolder>{renderQuestions()}</ContentHolder>
              <ContentHolder>
                <TitleText primary>
                  The benefits of claiming through Ageas:
                </TitleText>
              </ContentHolder>
              <ContentHolder>
                <TitleText>Vehicle repairs</TitleText>
                We will do our best to repair your vehicle and we have a
                national network of approved repairers, offering a wide range of
                benefits:
              </ContentHolder>
              <ThemedCheckedList
                items={VehicleRepairsCheckedList}
                icon="tick"
                iconColor={Theme.title.primary.text}
              />
              <ContentHolder>
                <TitleText>Replacement vehicle</TitleText>
                If you use one of our approved repairers, they will be able to
                provide you with a free courtesy car for the duration of the
                repairs. You will be insured as you would in your own vehicle.
                If this does not meet your mobility needs, we can provide you
                with a replacement vehicle through one of our hand-picked hire
                providers.
              </ContentHolder>
              <ContentHolder>
                <TitleText>Injury claim</TitleText>
                It is natural after an accident to feel shaken up. Personal
                injury is where someone experiences physical pain, commonly in
                their neck and back, perhaps with associated headaches, or you
                may have a pre-existing injury which has been made worse. If you
                or your passengers have suffered injuries following the
                accident, there are several ways we can help you. We can arrange
                treatment, instruct an independent medical examiner to assess
                your injuries and one of our team can discuss compensation with
                you.
              </ContentHolder>
              <ContentHolder>
                <TPAssistFAQAccordion />
              </ContentHolder>
            </PageSection>
          </main>
          <BrandFooter />
        </PageContainer>
      </ThemeProvider>
    </>
  );
};

export default TPAssist;
