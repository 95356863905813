import React, { useState } from 'react';
import { ThemedModal, ThemedButton } from 'ageas-ui-components';

import { headerInfo } from '../../../../data/whitelabel.config';

import { Text, CustomModalFooter } from './useModalOnExit.style';

const modalOnExit = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const {
    homeLink,
    texts: [title, description],
  } = headerInfo;
  const toggleModal = () =>
    setModalVisible(curIsModalVisible => !curIsModalVisible);

  const Modal = () =>
    isModalVisible ? (
      <ThemedModal contentLeft onCloseClick={toggleModal}>
        <Text>{title}</Text>
        {description && <Text>{description}</Text>}
        <CustomModalFooter>
          <ThemedButton
            onClick={() => {
              window.location.assign(homeLink);
            }}
            small
            primary
            inverted
            type="button"
          >
            Leave
          </ThemedButton>
          <ThemedButton onClick={toggleModal} small primary type="button">
            Stay
          </ThemedButton>
        </CustomModalFooter>
      </ThemedModal>
    ) : null;

  return { toggleModal, Modal };
};

export default modalOnExit;
