import React from 'react';
import { Switch } from 'react-router-dom';
import NotFoundRoute from '../NotFoundRoute';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import HubClaimsHistoryMotor from '../../views/ClaimsHistory/HubClaimsHistoryMotor';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  HubContextMotor,
  localStorageHubPropertyNameMotor,
} from '../../helpers/hubContextHelper';
import MotorHubDetail from '../../views/MotorHubDetail/MotorHubDetail';
import EclaimsRoute from '../EclaimsRoute';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import config from '../../helpers/config';

const hubClaimsPrivateProps = {
  private: true,
  authTypes: ['portal', 'otp'],
  lobs: 'motor',
};

const MotorClaimsHubRoutes = () => {
  return (
    <DataContextProvider
      Context={HubContextMotor}
      localStoragePropertyName={localStorageHubPropertyNameMotor}
      localStorageExpiryDays={config.client.localStorageHubExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path="/motorhub"
          component={HubClaimsHistoryMotor}
          {...hubClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path="/motorhub/claimdetails"
          component={MotorHubDetail}
          {...hubClaimsPrivateProps}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default MotorClaimsHubRoutes;
