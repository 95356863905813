import React from 'react';
import PropTypes from 'prop-types';
import StandardInput from '../StandardInput/StandardInput';

// For documentation see:
// /docs/components/Forms/Address/PostcodeField.md

// Basic Postcode format regex (does not validate character combinations)
export const postcodeBasicMatchRegex =
  /^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/;

// If postcode matches basic format after removal of blanks and
// upper case conversion
//   (AA999AA)
//   (AA9A9AA)
//   (A999AA))
//   (A9A9AA)
//   (AA99AA)
//   (A99AA)
// Returns strictly formatted
//   (AA99 9AA)
//   (AA9A 9AA)
//   (A99 9AA)
//   (A9A 9AA)
//   (AA9 9AA)
//   (A9 9AA)
// Else returns same
export const formatPostcode = value => {
  // Return as-is if not a string (initially undefined)
  if (typeof value !== 'string') {
    return value;
  }

  // format - convert to upper case, and remove blanks
  let formattedPostcode = value.replace(' ', '').toUpperCase();
  // if formatted value not match basic pattern, return original value
  if (!postcodeBasicMatchRegex.test(formattedPostcode)) {
    return value || undefined;
  }

  // Is validly formatted: Add space (AA999AA -> AA99 9AA) and return
  formattedPostcode = `${formattedPostcode.substring(
    0,
    formattedPostcode.length - 3,
  )} ${formattedPostcode.substring(formattedPostcode.length - 3)}`;
  return formattedPostcode;
};

//

const PostcodeField = ({ name, label, validate, format, ...props }) => {
  const fieldProps = {};
  if (format) {
    fieldProps.parse = formatPostcode;
  }

  return (
    <StandardInput
      {...props}
      name={name}
      label={label}
      validate={validate}
      maxLength="8"
      fieldProps={fieldProps}
    />
  );
};
PostcodeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.bool,
};
PostcodeField.defaultProps = {
  name: 'postcode',
  label: '',
  validate: () => {},
  format: false,
};

export default PostcodeField;
