import React from 'react';
import FaqP from '../../FaqP';

export const freezerFoodCoverTitle =
  'What am I covered for, in terms of damage to fridge/freezer food?';
export const freezerFoodCoverContent = (
  <>
    <FaqP>
      Cover is provided for fridge or freezer food that is damaged due to a rise
      or fall in temperature or contamination by the chemicals inside them.
    </FaqP>
    <FaqP>
      Please note limits apply and they will be shown in your policy
      documentation.
    </FaqP>
  </>
);
