import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from '../../assets/icons/claimTypes/times-circle-regular.svg';
import {
  BodyStyle,
  Header,
  HeaderWrapper,
  Container,
  NavWrapper,
  ConformationButton,
  CustomCheckBox,
  Imagetag,
  MenuContainer,
  BackgroundDiv,
} from './MenuMobileStyle';

const Mobilemenu = ({
  stormItems,
  stormItemsCall,
  selectedItems,
  openItem,
  openMenuItem,
  buttonSelection,
}) => {
  const [selectedItemsLocal, setSelectedItemsLocal] = useState([
    ...selectedItems,
  ]);
  const [open, setOpen] = useState(openItem);

  useEffect(() => {
    if (openItem) {
      setSelectedItemsLocal([...selectedItems]);
    }
  }, [selectedItems, openItem]);

  useEffect(() => {
    setOpen(openItem);
  }, [openItem]);

  // Undo changes (reset lcoal state to match global)
  const closeMenu = () => {
    setSelectedItemsLocal([...selectedItems]);
    setOpen(false);
    openMenuItem(false);
  };

  // Saving changes, send entire local state to parent to update master state
  const modifyData = () => {
    stormItemsCall([...selectedItemsLocal]);
    openMenuItem(false);
  };

  // Toggle item on/off in local state
  const checkedData = event => {
    const id = event.target.value;
    const selectedItemIndex = selectedItemsLocal.findIndex(item => item === id);
    if (selectedItemIndex >= 0) {
      selectedItemsLocal.splice(selectedItemIndex, 1);
    } else {
      const newItem = stormItems.find(item => item.id === id);
      if (newItem) {
        selectedItemsLocal.push(newItem.id);
      }
    }
    setSelectedItemsLocal([...selectedItemsLocal]);
  };

  /* eslint-disable */ 
  const Mobilemenudata = () => {
    return stormItems?.map(data => {
      if ((data.categoryMobile === 'garden' && !buttonSelection)
      || (data.categoryMobile !== 'garden' && buttonSelection)) {
        const checked = selectedItemsLocal.some(item => item === data.id);
        return (
          <Container checked={checked} key={data.id}>
            <input
              type="checkbox"
              checked={checked}
              value={data.id}
              onChange={checkedData}
              aria-label={data.name}
            />
            <CustomCheckBox />
            {data.name}
          </Container>
        );
      }
    });
  };

  return (
    <>
      {open && (
        <>
          <BodyStyle />
          <Header>
            <BackgroundDiv />
            <HeaderWrapper>
              <NavWrapper>
                <Imagetag src={Image} data-testid="close" onClick={closeMenu} />
                <br />
                <br />
                <MenuContainer>{Mobilemenudata()}</MenuContainer>
                <ConformationButton
                  data-testid="confirmbutton"
                  onClick={modifyData}
                  type="button"
                >
                  Confirm selection
                </ConformationButton>
              </NavWrapper>
            </HeaderWrapper>
          </Header>
        </>
      )}
    </>
  );
};

export default Mobilemenu;

Mobilemenu.propTypes = {
  stormItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      isChecked: PropTypes.bool,
    }),
  ),
  stormItemsCall: PropTypes.func,
  openMenuItem: PropTypes.func,
  openItem: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  buttonSelection: PropTypes.bool,
};
Mobilemenu.defaultProps = {
  stormItems: undefined,
  stormItemsCall: undefined,
  selectedItems: [],
  openMenuItem: undefined,
  openItem: undefined,
  buttonSelection: undefined,
};
