import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Spacer from '../FieldSpacer/FieldSpacer';

// For documentation see:
// /docs/components/Forms/FieldStyled/FieldStyled.md

const FieldStyled = ({ marginBottom, ...props }) => {
  return (
    <Spacer marginBottom={marginBottom}>
      <Field {...props} />
    </Spacer>
  );
};

FieldStyled.propTypes = {
  marginBottom: PropTypes.string,
};

FieldStyled.defaultProps = {
  marginBottom: undefined,
};

export default FieldStyled;
