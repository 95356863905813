import React from 'react';
import FaqP from '../../FaqP';

export const contentsOutsideHomeTitle =
  'Are my contents covered outside of my home?';
export const contentsOutsideHomeContent = (
  <>
    <FaqP>
      Standard contents cover will provide cover whilst your items are within
      the boundary of your home.
    </FaqP>
    <FaqP marginBottom={false}>
      There are a few common exceptions to this that are included in some
      contents policies such as items covered whilst a member of your family is
      at university. Please check your contents policy wording to see what is
      included.
    </FaqP>
    <FaqP>
      Contents which you commonly carry with you will also be covered outside of
      your home, if you have the optional &apos;Everyday or Specified personal
      belongings&apos; extension. Please check your policy documentation for any
      exclusions or limits that might apply.
    </FaqP>
  </>
);
