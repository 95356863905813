import { ErrorMessage, Label } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import expandArrow from '../../../assets/expandArrow.svg';
import ContextAxios from '../../../components/ContextAxios/ContextAxios';
import IncidentDate from '../../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import FieldStyled from '../../../components/Forms/FieldStyled/FieldStyled';
import TimeDropdownInput from '../../../components/TimeDropdownInput/TimeDropdownInput';
import policyVRNListAxiosFunction from '../../../helpers/axiosFunctions/policyVRNListAxios';
import {
  callUsMotorWithHours,
  noActivePolicyMotorWithHours,
  noDataMotorWithHours,
} from '../../../helpers/errorMessages';
import {
  PolicyContext,
  policyName,
} from '../../../helpers/policyContextHelper';
import PolicyVRNSelectField from '../../MotorIncidentDetail/PolicyVRNSelectField';
import { FieldHeaderSpaced } from '../../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import { incidentDetailsFields } from '../motorIncidentDetailsHelper';

const onTimeChange = (returnedTime, input) => {
  if (!returnedTime || !/^\d{2}:\d{2}$/.test(returnedTime)) {
    input.onChange(undefined);
    input.onFocus(undefined);
    return;
  }
  const splitTime = returnedTime.split(':');
  const hours = splitTime[0];
  const minutes = splitTime[1];

  if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
    input.onChange(returnedTime);
    input.onBlur(returnedTime);
  }
};

const incidentDateTimeIsValid = (values, errors) => {
  return !!(
    !errors.incidentDate &&
    !errors.incidentTime &&
    values.incidentDate &&
    values.incidentTime
  );
};

const IncidentDetailsKeyFields = ({
  form,
  values,
  errors,
  validateFields,
  disabled,
}) => {
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyVRNListContext = { ...policyDataContext.policyVRNList };

  const vrnToPolicy = vrn => {
    // find the entry in the vrnPolicy state for this VRN
    return (
      policyVRNListContext.data?.find(element => element.vrn === vrn)
        ?.policyNumber || undefined
    );
    // call the mutator function to update the policyNumber hidden field with
    // the found policy number
  };

  const onVRNChange = (input, vrn, fieldUpdater) => {
    // Call Standard RFF onchange
    input.onChange(vrn);

    // Update RFF field
    fieldUpdater('policyNumber', vrnToPolicy(vrn));
  };

  return (
    <>
      <ContextAxios
        keyList={[values.incidentDate, values.incidentTime]}
        axiosFunction={policyVRNListAxiosFunction}
        enableUpdate={incidentDateTimeIsValid(values, errors)}
        dataContextObject={PolicyContext}
        dataContextName={policyName.policyVRNList}
      />
      <FieldHeaderSpaced>
        We&apos;d like you to give us more details about the incident.
      </FieldHeaderSpaced>
      <IncidentDate
        name={incidentDetailsFields.INCIDENT_DATE}
        id={incidentDetailsFields.INCIDENT_DATE}
        label="When did the incident happen?"
        secondaryLabel=""
        validate={validateFields}
        disabled={disabled}
      />
      <FieldStyled
        name={incidentDetailsFields.INCIDENT_TIME}
        validate={validateFields}
        disabled={disabled}
        pointerEvents="none"
      >
        {({ input, meta }) => (
          <>
            <Label fieldName={input.name}>What time did this happen?</Label>
            <TimeDropdownInput
              formInput={input}
              handleTime={value => onTimeChange(value, input)}
              inclDay
              dropdownId="registrationTime"
              dropdownIcon={expandArrow}
              placeholderHours="Hours"
              placeholderMinutes="Minutes"
              hoursDefaultValue={input.value.split(':')[0]}
              minutesDefaultValue={input.value.split(':')[1]}
              disabled={disabled}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
      {incidentDateTimeIsValid(values, errors) && (
        <FieldStyled
          name={incidentDetailsFields.VEHICLE_REGISTRATION}
          validate={validateFields}
        >
          {({ input, meta }) => (
            <PolicyVRNSelectField
              {...input}
              title="Select the registration number of the vehicle involved"
              fieldName={input.name}
              id={input.name}
              selectedOption={input.value}
              overrideCallUsMessage={callUsMotorWithHours}
              overrideNoPolicyMessage={noActivePolicyMotorWithHours}
              overrideNoDataMessage={noDataMotorWithHours}
              onChange={vrn => {
                onVRNChange(input, vrn, form.mutators.fieldUpdateMutator);
              }}
              maxButtons={4}
              errorMsg={meta.error && meta.touched ? meta.error : undefined}
              dataContextObject={PolicyContext}
              dataContextName={policyName.policyVRNList}
              disabled={disabled}
              displayMakeModel
              headingStyles={{ fontSize: '19px' }}
            />
          )}
        </FieldStyled>
      )}
    </>
  );
};

export default IncidentDetailsKeyFields;

IncidentDetailsKeyFields.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  validateFields: PropTypes.func,
  disabled: PropTypes.bool,
};

IncidentDetailsKeyFields.defaultProps = {
  validateFields: undefined,
  disabled: false,
};
