import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { UnorderedListHeaderContainer, HeaderButton } from './Header.style';
import getTPAPaths from '../../../routes/MotorClaimsTpHubRoutes/motorClaimsTpHubRoutePaths';

const HeaderContainer = styled.button`
  display: none;
  @media (max-width: 575px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    height: 63px;
    width: 98px;
    background: transparent;
    padding: 0;
    margin: 0;
    background-color: ${({ open }) => (open ? '#8e419c' : '#ebebeb')};
    transition: background-color 0.6s ease-in-out;
    position: ${({ open }) => (open ? 'fixed' : 'absolute')};
    z-index: 1;
    outline: none;
    // margin-right: 67px;
  }
`;

const HamBurgerMenu = styled.div`
  width: 14px;
  height: 1.1rem;
  position: fixed;
  top: 15px;
  right: 72px;
  cursor: pointer;
  z-index: 20;
  display: none;
  @media (max-width: 575px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    position: absolute;
  }
  div {
    width: 19px;
    height: 2px;
    display: block;
    margin-top: 4px;
    background-color: ${({ open }) => (open ? '#fff' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    margin-left: 5px;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const SpanContainer = styled.div`
  display: none;
  @media (max-width: 575px) {
    left: 0;
    display: block;
    margin-left: 30px;
    font-size: 18px;
    margin-top: -10px;
    color: ${({ open }) => (open ? '#fff' : '#2c2c2c')};
  }
`;

const MenuBar = props => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { dataTestId, logoutButton, claimButton, lob } = props;
  const historyHook = useHistory();

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const content = open ? 'Close' : 'Menu';
  /* istanbul ignore next */
  const menuFunction = () => {
    if (window.innerWidth <= 575) {
      if (open === false) {
        document.getElementById('topLayer').style.position = 'sticky';
        document.getElementById('topLayer').style.top = '-1px';
        document.getElementById('topLayer').style.background = '#fff';
        document.getElementById('topLayer').style.zIndex = '1';
        document.getElementById('logoProperty').style.top = '-45%';
        document.body.style.overflow = 'hidden';
      }
      if (open === true) {
        document.getElementById('topLayer').style = '';
        document.getElementById('logoProperty').style.top = '';
        document.body.style.overflow = '';
      }
    }
    setOpen(!open);
  };

  const onClickLogoutButton = () => {
    if (lob === 'motorTPA') {
      historyHook.push(
        `${getTPAPaths().LOGIN}?logout&target=${getTPAPaths().ROOT}`,
      );
    } else if (lob === 'motor') {
      if (location.pathname.includes('hub')) {
        historyHook.push('/motorclaim/brokerlogin?logout&target=/motorhub');
      } else {
        historyHook.push(
          '/motorclaim/brokerlogin?logout&target=/motorclaim/history',
        );
      }
    } else {
      historyHook.push('/homeclaim/brokerlogin?logout&target=/homehub');
    }
  };

  const onClickNewClaimButton = () => {
    if (lob === 'motor') {
      historyHook.push('/carinsurance');
    } else {
      historyHook.push('/homeinsurance');
    }
  };

  return (
    <>
      <HeaderContainer
        data-testid={dataTestId}
        open={open}
        onClick={menuFunction}
      >
        <HamBurgerMenu open={open}>
          <div />
          <div />
          <div />
        </HamBurgerMenu>

        <SpanContainer open={open}>{content}</SpanContainer>
      </HeaderContainer>
      <UnorderedListHeaderContainer open={open}>
        {claimButton && (
          <li>
            <HeaderButton primary type="button" onClick={onClickNewClaimButton}>
              Make a new claim
            </HeaderButton>
          </li>
        )}
        {logoutButton && (
          <li>
            <HeaderButton primary type="button" onClick={onClickLogoutButton}>
              Log out
            </HeaderButton>
          </li>
        )}
      </UnorderedListHeaderContainer>
    </>
  );
};
export default MenuBar;

MenuBar.propTypes = {
  dataTestId: PropTypes.string,
  claimButton: PropTypes.bool,
  logoutButton: PropTypes.bool,
  lob: PropTypes.string,
};
MenuBar.defaultProps = {
  dataTestId: 'headerContainerTestId',
  claimButton: false,
  logoutButton: false,
  lob: undefined,
};
