import React from 'react';
import FaqP from '../../FaqP';

export const insureCoverStormDamageTitle = 'What is covered by storm damage?';
export const insureCoverStormDamageContent = (
  <>
    <FaqP>
      This will depend on the cover you have. If you have buildings cover, in
      the event of a successful claim, any part of your building which has been
      damaged as a result of the extreme weather will be covered.
    </FaqP>
    <FaqP>
      If you have contents cover, then any damaged contents will be covered.
    </FaqP>
    <FaqP>Policy limits are shown in your policy documentation.</FaqP>
    <FaqP>
      Fences, gates, trees and hedges are not covered in the event of storm
      damage under your buildings cover. Please refer to your policy booklet as
      some garden items may be covered under contents cover if you have that.
    </FaqP>
  </>
);
