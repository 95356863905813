import moment from 'moment';
import { messages } from '../constants/ErrorMessages';

export function validStatus(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      validSingleAndSpouseStatus: messages.SINGLE_AND_SPOUSE,
      validSingleAndCivilStatus: messages.SINGLE_AND_CIVIL_PARTNER,
      validMustBeMarried: messages.MUST_BE_MARRIED,
      validMustBeCivilPartner: messages.MUST_BE_CIVIL_PARTNER,
    },
    rules: [
      {
        name: 'validStatus',
        params: {
          compareRelationKey: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          compareProposerRelationKey: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const relationshipValue = state.parent[params.compareRelationKey];
          const proposerMaritalStatus =
            state.parent[params.compareProposerRelationKey];

          if (value === 'S' && relationshipValue === 'SPOUSEREL') {
            return this.createError(
              'string.validSingleAndSpouseStatus',
              { value },
              state,
              options,
            );
          }
          if (value === 'S' && relationshipValue === 'CivPrtnr') {
            return this.createError(
              'string.validSingleAndCivilStatus',
              { value },
              state,
              options,
            );
          }
          if (
            value === 'M' &&
            relationshipValue === 'SPOUSEREL' &&
            proposerMaritalStatus !== 'M'
          ) {
            return this.createError(
              'string.validMustBeMarried',
              { value },
              state,
              options,
            );
          }
          if (
            value === 'V' &&
            relationshipValue === 'CivPrtnr' &&
            proposerMaritalStatus !== 'V'
          ) {
            return this.createError(
              'string.validMustBeCivilPartner',
              { value },
              state,
              options,
            );
          }
          return value;
        },
      },
    ],
  };
}

export function validRelation(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      validRelationMarried: messages.ONLY_ONE_SPOUSE,
      validRelationMustBeMarriedProposer: messages.MUST_BE_MARRIED,
      validRelationCivilPartner: messages.ONLY_ONE_CIVIL_PARTNER,
      validRelationCivilPartnerProposer: messages.MUST_BE_CIVIL_PARTNER,
      validRelationSingleSpouse: messages.ONE_SPOUSE,
    },
    rules: [
      {
        name: 'validRelation',
        params: {
          maritalStatus: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          proposerRelation: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          driversObject: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          editDriverIndex: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const maritalStatusValue = state.parent[params.maritalStatus];
          const proposerMaritalStatusValue =
            state.parent[params.proposerRelation];

          if (value === 'SPOUSEREL') {
            if (maritalStatusValue !== 'M') {
              return this.createError(
                'string.validRelationMarried',
                { value },
                state,
                options,
              );
            }
            if (proposerMaritalStatusValue !== 'M') {
              return this.createError(
                'string.validRelationMustBeMarriedProposer',
                { value },
                state,
                options,
              );
            }

            const additionalDriverArray = state.parent[params.driversObject];
            const editDriverIndex = state.parent[params.editDriverIndex];
            const multipleSpousesArray = additionalDriverArray
              ? additionalDriverArray.filter(
                  driver =>
                    driver[state.key] === value &&
                    (editDriverIndex === undefined || editDriverIndex === null
                      ? true
                      : additionalDriverArray[editDriverIndex][state.key] !==
                        value),
                )
              : [];

            if (multipleSpousesArray.length > 0) {
              return this.createError(
                'string.validRelationSingleSpouse',
                { value },
                state,
                options,
              );
            }
          }

          if (value === 'CivPrtnr' && maritalStatusValue !== 'V') {
            return this.createError(
              'string.validRelationCivilPartner',
              { value },
              state,
              options,
            );
          }
          if (value === 'CivPrtnr' && proposerMaritalStatusValue !== 'V') {
            return this.createError(
              'string.validRelationCivilPartnerProposer',
              { value },
              state,
              options,
            );
          }
          return value;
        },
      },
    ],
  };
}

export function validRelationDateOfBirth(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      validYoungerRelation: messages.YOUNGER_THAN_PROPOSER,
      validOlderRelation: messages.OLDER_THAN_PROPOSER,
    },
    rules: [
      {
        name: 'validRelationDateOfBirth',
        params: {
          proposerDateOfBirth: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          insuredDateOfBirth: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const comparer = moment(
            state.parent[params.insuredDateOfBirth],
            'DD/MM/YYYY',
          );
          const originalDate = moment(
            state.parent[params.proposerDateOfBirth],
            'DD/MM/YYYY',
          );

          if (value === 'PARENT' && originalDate.isBefore(comparer)) {
            return this.createError(
              'string.validYoungerRelation',
              {
                value,
                proposerDateOfBirth: params.proposerDateOfBirth,
                insuredDateOfBirth: params.insuredDateOfBirth,
              },
              state,
              options,
            );
          }
          if (value === 'OFFSPRNG' && originalDate.isAfter(comparer)) {
            return this.createError(
              'string.validOlderRelation',
              { value },
              state,
              options,
            );
          }
          return value;
        },
      },
    ],
  };
}

export function validPassword(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      validPasswordValue: 'Enter Valid Password',
    },
    rules: [
      {
        name: 'validPassword',
        params: {
          comparePassword: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const comparer = state.parent[params.comparePassword];
          if (value !== comparer) {
            return this.createError(
              'string.validPasswordValue',
              {
                value,
              },
              state,
              options,
            );
          }
          return value;
        },
      },
    ],
  };
}
