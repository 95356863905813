import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../../StandardRadio/StandardRadio';

const DEFAULT_FIELD_NAME = 'yesOrNo';
const getOptions = allowUnknown => {
  const OPTIONS = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  if (allowUnknown) {
    const UNKNOWN = {
      value: 'unknown',
      label: allowUnknown === true ? 'Unknown' : allowUnknown,
    };
    OPTIONS.push(UNKNOWN);
  }
  return OPTIONS;
};

export const yesOrNoSchema = (
  fieldName = DEFAULT_FIELD_NAME,
  message = 'Please select an option',
) => {
  return {
    [fieldName]: stringRequired([fieldName], message),
  };
};

const YesOrNo = ({
  name = DEFAULT_FIELD_NAME,
  title,
  allowUnknown = false,
  ...rest
}) => {
  const options = useMemo(() => getOptions(allowUnknown), [allowUnknown]);

  return (
    <StandardRadio
      name={name}
      title={title}
      options={options}
      medium
      vertical
      {...rest}
    />
  );
};

YesOrNo.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  allowUnknown: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
YesOrNo.defaultProps = {
  name: undefined,
  title: '',
  allowUnknown: undefined,
};

export default YesOrNo;
