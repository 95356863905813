import React, { useContext } from 'react';
import { PolicyContext } from '../../../helpers/policyContextHelper';
import {
  TitleTextSpaced,
  VrnDisplay,
} from '../../../components/StandardPageWrapper/StandardPageWrapperStyle';

const formatVRN = vrn =>
  `${vrn.substring(0, vrn.length - 3)} ${vrn.substring(vrn.length - 3)}`
    .trim()
    .toUpperCase();

const DisplayVehicleInfo = () => {
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyDetails = { ...policyDataContext.policyDetails };
  const { vrn, make, model } = policyDetails?.data?.vehicles?.[0] || {};

  const vrnFormatted = formatVRN(vrn);
  const makeModel = [make, model]
    .filter(x => !!x)
    .join(' ')
    .trim();

  return (
    <>
      <TitleTextSpaced primary>{makeModel}</TitleTextSpaced>
      <VrnDisplay>{vrnFormatted}</VrnDisplay>
    </>
  );
};

export default DisplayVehicleInfo;
