import React, { useEffect, useContext, useState, useRef } from 'react';
import axios from 'axios';
import { ThemedButton } from 'ageas-ui-components';
import config from '../../helpers/config';
import axiosHelper from '../../helpers/axios';
import {
  MotorClaimContextNew,
  motorClaimDataContext,
} from '../../helpers/motorClaimHelperNew';
import {
  date3YearsMotorWithHours,
  getErrorMessage,
} from '../../helpers/errorMessages';
import { run3YearDateValidation } from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import { validateField } from '../../helpers/validationHelper';
import { OTHER_UNKNOWN_VRN } from '../MotorIncidentDetail/PolicyVRNSelectField';
import { PolicyContext } from '../../helpers/policyContextHelper';
import {
  ButtonsBottomContainer,
  FieldHeader,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import StandardDropdown from '../../components/Forms/StandardDropdown/StandardDropdown';
import {
  DAMAGE_OCCURRED_LIST,
  ROAD_SURFACE_ISSUE_TYPE_LIST,
  incidentDetailsFields,
  schema,
} from './motorIncidentDetailsHelper';
import YesOrNo from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardP from '../../components/StandardTags/P';
import StandardTextarea from '../../components/Forms/StandardTextarea/StandardTextarea';
import YesNoPopup from './YesNoPopup';
import { dmyAndHmsToIso } from '../../helpers/dateTimeConverter';
import { consoleError } from '../../helpers/consoleLog';
import { updateLocalMeta } from '../../helpers/motorClaimNewDataMapper/motorClaimNewDataMapper';
import { updateLocalPageSectionIntoContext } from '../../helpers/updateContinuableMotorClaimData';
import IncidentDetailsKeyFields from './MotorIncidentDetailsFieldSets/IncidentDetailsKeyFields';
import IncidentLocationFields from './MotorIncidentDetailsFieldSets/IncidentLocationFields';
import IncidentDamageAnimalFields from './MotorIncidentDetailsFieldSets/IncidentDamageAnimalFields';
import IncidentDamageRoadSurfaceMudFields from './MotorIncidentDetailsFieldSets/IncidentDamageRoadSurfaceMudFields';
import IncidentDamagePropertyFields from './MotorIncidentDetailsFieldSets/IncidentDamagePropertyFields';
import IncidentDamageRoadSurfacePotholeFields from './MotorIncidentDetailsFieldSets/IncidentDamageRoadSurfacePotholeFields';
import IncidentDamageRoadSurfaceUngrittedFields from './MotorIncidentDetailsFieldSets/IncidentDamageRoadSurfaceUngrittedFields';
import GenericMotorFormPage, {
  ExtractFormStateApi,
} from './GenericMotorFormPage';

const LOCKDOWN_WARNING =
  'The incident date & time and vehicle registration cannot be changed after this point. Are you sure you want to continue?';

const buildCreateClaimObject = values => {
  return {
    policyReference: values.policyNumber,
    incidentDate: dmyAndHmsToIso(values.incidentDate, values.incidentTime),
    vehicleRegistration: values.vehicleRegistration,
  };
};

const validateFields = (value, allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    // Skip validation for optional fields which have mandatory validation by
    // default
    if (
      !value?.trim() &&
      ['postcode', 'emailAddress', 'homeTelephone', 'workTelephone'].includes(
        meta.name.split('.').pop(),
      )
    ) {
      return undefined;
    }
    error = validateField(value, meta, schema);

    if (error) {
      return getErrorMessage(error);
    }
  }

  // Custom validation - incident happened 3yrs ago
  if (meta.name === incidentDetailsFields.INCIDENT_DATE) {
    const incidentError = run3YearDateValidation(
      allValues.incidentDate,
      'motor',
      date3YearsMotorWithHours,
    );
    if (incidentError) {
      return incidentError;
    }
  }

  // Custom validation - date and time not in the future
  if (
    meta.name === incidentDetailsFields.INCIDENT_TIME &&
    value &&
    allValues.incidentDate
  ) {
    const splitDate = allValues.incidentDate.split('/');
    const splitTime = value.split(':');
    const dateObj = new Date(
      splitDate[2],
      splitDate[1] - 1,
      splitDate[0],
      splitTime[0],
      splitTime[1],
    );
    const now = new Date();
    if (dateObj.getTime() > now) {
      return 'The date and time of the claim cannot be in the future';
    }
  }

  return undefined;
};

const incidentDateTimeIsValid = (values, errors) => {
  return !!(
    !errors.incidentDate &&
    !errors.incidentTime &&
    values.incidentDate &&
    values.incidentTime
  );
};

const PAGE_SECTION_NAME = 'incidentDetails';

const MotorIncidentDetails = () => {
  const [render, setRender] = useState(false);
  const { dataState, updateDataState } = useContext(MotorClaimContextNew);
  const [keyFieldsLocked, setLocked] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyVRNListContext = { ...policyDataContext.policyVRNList };
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [unsavedData, setUnsavedData] = useState(false);

  const createAxiosCancelToken = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosCancelToken.current;
  };

  const resetServerError = () => {
    setServerError(false);
  };

  const onFormValuesChange = () => {
    resetServerError();
    // We only care about maintaining our own record of keyFieldsLocked
    // if we still have control of the form (haven't created claim yet)
    // As opposed to GenericMotorFormPage being in control
    if (!keyFieldsLocked) {
      setUnsavedData(true);
    }
  };

  const createClaim = async values => {
    resetServerError();
    setIsLoading(true);
    const cancelToken = createAxiosCancelToken().token;

    // Data should be validated by now, so save to actual and remove unvalidated
    let claimEntryData = updateLocalPageSectionIntoContext(
      dataState,
      PAGE_SECTION_NAME,
      values,
      updateDataState,
      true,
    );
    setUnsavedData(false);

    try {
      const getClaimObject = buildCreateClaimObject(values);
      const res = await axiosHelper.post(
        config.client.createBasicMotorClaim_endpoint,
        getClaimObject,
        {
          cancelToken,
        },
      );
      updateDataState(motorClaimDataContext.claimDetails, {
        claimReference: res.data.claimReference,
        policyReference: values.policyNumber,
      });
      const { stage, uid } = res.data;
      claimEntryData = updateLocalMeta(claimEntryData, { stage, uid });
      updateDataState(motorClaimDataContext.claimEntryData, claimEntryData);
      setIsLoading(false);
      setLocked(true);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setServerError(true);
        consoleError('axios error', error);
      }
    }
  };

  const vrnDropdownIsOk = values => {
    return !!(
      policyVRNListContext.status === 'loaded' &&
      policyVRNListContext?.data?.length &&
      values.vehicleRegistration &&
      values.vehicleRegistration !== OTHER_UNKNOWN_VRN
    );
  };

  const showDateVrnContinue = (values, errors) => {
    return !!(
      !keyFieldsLocked &&
      vrnDropdownIsOk(values) &&
      incidentDateTimeIsValid(values, errors) &&
      !serverError
    );
  };

  const lockdownNotConfirmed = () => {
    setLocked(false);
    setShowConfirmPopup(false);
  };

  const lockdownConfirmed = values => {
    setShowConfirmPopup(false);
    createClaim(values);
  };

  const handleDateVrnContinueClick = () => {
    setShowConfirmPopup(true);
  };

  // On page load
  useEffect(() => {
    window.scrollTo(0, 0);
    setRender(true);
    if (dataState?.claimDetails?.claimReference) {
      setLocked(true);
    }

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  if (!render) {
    return null;
  }

  return (
    <GenericMotorFormPage
      spinner={isLoading}
      showContinue={keyFieldsLocked}
      serverError={serverError}
      expectClaimReference={false}
      unsavedData={unsavedData}
      pageSectionName={PAGE_SECTION_NAME}
      breadcrumbId="incidentDetails"
      pageTitle="Incident Details"
      onFormValuesChange={onFormValuesChange}
      nextPage="/motorclaim/newclaim/contactdetails"
    >
      <ExtractFormStateApi>
        {({ values, form, errors }) => (
          <>
            <IncidentDetailsKeyFields
              form={form}
              values={values}
              errors={errors}
              validateFields={validateFields}
              disabled={keyFieldsLocked}
            />

            {showDateVrnContinue(values, errors) && (
              <ButtonsBottomContainer>
                <ThemedButton
                  secondary
                  type="button"
                  onClick={handleDateVrnContinueClick}
                >
                  Continue
                </ThemedButton>
              </ButtonsBottomContainer>
            )}
            {showConfirmPopup && (
              <YesNoPopup
                titleDescription={LOCKDOWN_WARNING}
                onConfirmYes={() => {
                  lockdownConfirmed(values);
                }}
                onConfirmNo={lockdownNotConfirmed}
              />
            )}

            {keyFieldsLocked && (
              <>
                <FieldHeader>Where did this happen?</FieldHeader>
                <IncidentLocationFields validateFields={validateFields} />

                <StandardDropdown
                  name={incidentDetailsFields.HOW_DAMAGE_HAPPENED}
                  label="Tell us how the damage occurred"
                  aria-label="Tell us how the damage occurred"
                  options={DAMAGE_OCCURRED_LIST}
                  small
                  validate={validateFields}
                />

                {!!values[incidentDetailsFields.HOW_DAMAGE_HAPPENED] && (
                  <>
                    <StandardTextarea
                      name={incidentDetailsFields.INCIDENT_DETAILS}
                      label="In your own words, please describe in more detail what happened in the incident"
                      aria-label="Please describe what happened in the incident"
                      maxLength={1000}
                      validate={validateFields}
                      blockProps={{ marginTop: '0' }}
                      resize="none"
                    />
                    {values[incidentDetailsFields.HOW_DAMAGE_HAPPENED] ===
                      'animal' && (
                      <IncidentDamageAnimalFields
                        values={values}
                        validateFields={validateFields}
                      />
                    )}
                    {values[incidentDetailsFields.HOW_DAMAGE_HAPPENED] ===
                      'property' && (
                      <IncidentDamagePropertyFields
                        values={values}
                        validateFields={validateFields}
                      />
                    )}
                    {values[incidentDetailsFields.HOW_DAMAGE_HAPPENED] ===
                      'roadSurface' && (
                      <>
                        <StandardDropdown
                          name={incidentDetailsFields.ROAD_SURFACE_ISSUE_TYPE}
                          label="What was the problem with the road surface?"
                          aria-label="What was the problem with the road surface?"
                          options={ROAD_SURFACE_ISSUE_TYPE_LIST}
                          small
                          validate={validateFields}
                        />
                        {values[
                          incidentDetailsFields.ROAD_SURFACE_ISSUE_TYPE
                        ] === 'spillageMud' && (
                          <IncidentDamageRoadSurfaceMudFields
                            values={values}
                            validateFields={validateFields}
                          />
                        )}
                        {values[
                          incidentDetailsFields.ROAD_SURFACE_ISSUE_TYPE
                        ] === 'pothole' && (
                          <IncidentDamageRoadSurfacePotholeFields
                            validateFields={validateFields}
                          />
                        )}
                        {values[
                          incidentDetailsFields.ROAD_SURFACE_ISSUE_TYPE
                        ] === 'ungrittedRoad' && (
                          <IncidentDamageRoadSurfaceUngrittedFields
                            values={values}
                            validateFields={validateFields}
                          />
                        )}
                      </>
                    )}
                    <YesOrNo
                      name={incidentDetailsFields.HAS_CCTV}
                      title="Do you have any CCTV of the incident?"
                      validate={validateFields}
                    />
                    {values[incidentDetailsFields.HAS_CCTV] === 'yes' && (
                      <StandardP>
                        We&apos;ll send you a link so you can upload the CCTV
                        footage after we&apos;ve completed setting your claim
                        up.
                      </StandardP>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ExtractFormStateApi>
    </GenericMotorFormPage>
  );
};

export default MotorIncidentDetails;
