import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../BreadcrumbStandard/Breadcrumb';

const BreadcrumbClaims = ({ items, activeItem }) => {
  let currentItem;
  if (activeItem) {
    currentItem = items.findIndex(item => item.id === activeItem);
    if (currentItem < 0) {
      currentItem = undefined;
    }
  }

  return (
    <Breadcrumb
      items={items}
      currentItem={currentItem}
      maxInnerWidth="400px"
      maxNarrowInnerWidth="200px"
      paddingBottomOffset="-20px"
    />
  );
};

export default BreadcrumbClaims;

BreadcrumbClaims.defaultProps = {
  activeItem: undefined,
};

BreadcrumbClaims.propTypes = {
  activeItem: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
