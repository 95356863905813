import React from 'react';
import FaqP from '../../FaqP';

export const policyExcessInfoTitle = 'What is a policy excess?';
export const policyExcessInfoContent = (
  <>
    <FaqP>
      A policy excess is the amount you have to pay towards the cost of your
      claim.
    </FaqP>
    <FaqP>There are different excesses that may apply.</FaqP>
    <FaqP>
      There is a standard (or compulsory) policy excess which applies to all
      claims, although the amount you need to pay may increase depending on the
      type of claim you want to make. For example, the standard excess on your
      policy may be £100 but is likely to increase for a claim for water damage
      to your property.
    </FaqP>
    <FaqP>
      You may have also added a voluntary excess when you took your policy out
      in exchange for a lower premium. This excess will be added to the standard
      (or compulsory) excess in the event of a claim.
    </FaqP>
    <FaqP>
      Details of all your policy excesses can be found on your policy
      documentation.
    </FaqP>
  </>
);
