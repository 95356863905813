import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext, keyframes } from 'styled-components';
import config from '../../helpers/config';

// Icons
import ageasChatbotIcon from '../../assets/images/ageas_chatbot_icon.png';
import { ChatBotContext } from '../../contexts/ChatBotContext';

const Container = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9999;
  text-align: right;
  @media screen and (max-width: 767px) {
    bottom: 47px;
  }
`;

const Image = styled.img`
  width: 96px;
  cursor: pointer;
  transition: transform 0.15s;

  &:hover {
    transform: scale(1.2);
  }
`;

const failAnimation = keyframes`
  0% { opacity: 0; margin-bottom: 0; }
  5% { opacity: 1; margin-bottom: 16px; }
  95% { opacity: 1; margin-bottom: 16px; }
  100% { opacity: 0; margin-bottom: 0; }
`;

const FailBubble = styled.div`
  border-radius: 10px;
  border-bottom-right-radius: 0;
  background-color: ${props =>
    props.theme.chatbot.chatBubbles.vaBackgroundColor};
  color: ${props => props.theme.chatbot.chatBubbles.vaTextColor};
  padding: 16px 24px;
  animation: ${failAnimation} 5s;
  margin-right: 48px;
`;

const urls = {
  ageasuk: {
    closed: ageasChatbotIcon,
    open: ageasChatbotIcon,
  },
};

const ChatBot = ({ onOpen, onClose, enabled, instanceName }) => {
  if (!enabled || !config.client.FEATURE_CHATBOT_ENABLED) {
    return null;
  }

  const theme = useContext(ThemeContext);
  const { boost, setBoost } = useContext(ChatBotContext);
  const [errorShowing, setErrorShowing] = useState(false);

  const showError = () => {
    if (errorShowing) {
      return;
    }

    setErrorShowing(true);
    setTimeout(() => setErrorShowing(false), 5000);
  };

  const showPanel = boostInstance => {
    boostInstance.chatPanel.show();
    onOpen();
  };

  // Use any branding icons, but default to Ageas if not found
  const icons =
    urls[instanceName] !== undefined ? urls[instanceName] : urls.ageasuk;

  const onClick = () => {
    if (boost !== null) {
      showPanel(boost);
      return;
    }

    if (!window.boostInit) {
      showError();
      return;
    }

    const options = {
      chatPanel: {
        header: {
          filters: {
            filterValues: ['homeclaims'],
          },
        },
        settings: {
          conversationId: window.sessionStorage.getItem(
            'chatbotConversationId',
          ),
        },
        styling: {
          ...theme.chatbot,
          fontFamily: 'Arial',
          avatarUrl: icons.open,
          position: {
            spacingRight: 16,
          },
        },
      },
    };

    const boostInstance = window.boostInit(instanceName, options);

    setBoost(boostInstance);

    boostInstance.chatPanel.addEventListener('conversationIdChanged', event => {
      window.sessionStorage.setItem(
        'chatbotConversationId',
        event.detail.conversationId,
      );
    });

    boostInstance.chatPanel.addEventListener('chatPanelClosed', () => {
      onClose();
    });

    showPanel(boostInstance);
  };

  return (
    <Container>
      {errorShowing && (
        <FailBubble data-testid="chatBotFailBubble">
          Sorry, I&apos;m not available at the moment.
        </FailBubble>
      )}
      <Image
        data-testid="chatBotButton"
        src={icons.closed}
        alt="Chatbot"
        onClick={onClick}
      />
    </Container>
  );
};

ChatBot.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  enabled: PropTypes.bool,
  instanceName: PropTypes.string,
};

ChatBot.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  enabled: true,
  instanceName: 'ageasuk',
};

export default ChatBot;
