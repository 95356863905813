import React, { useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage } from 'ageas-ui-components';
import config from '../../helpers/config';
import axiosHelper from '../../helpers/axios';
import {
  ErrorTextContainer,
  PageSectionV2,
} from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import {
  HubContextMotorTp,
  hubDataContextMotorTp,
} from '../../helpers/hubContextHelper';
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import { motorTpDataLoadFailingError } from '../../helpers/errorMessages';
import { HubNotificationsPlain } from '../../components/HubNotifications/HubNotifications';
import {
  closeNotificationsByCode,
  updateNotificationStatus,
  updateNotifications,
} from '../../helpers/hubNotificationsHelper';
import { MotorTPHubUploader } from '../../components/DocumentUploader/MotorHubUploader';
import HubUploadedDocuments from '../../components/HubUploadedDocuments/HubUploadedDocuments';
import MOTOR_TP_HUB_UPLOADABLE_TYPES_OBJECT, {
  MOTOR_TP_HUB_DOCUMENT_TYPES_BY_CORRESPONDENCE_TYPE,
} from '../../helpers/motorTPDocumentUploadTypes';
import MotorTpHubClaimDetails from '../../components/MotorTpHubClaimDetails/MotorTpHubClaimDetails';
import { MotorTpHubMessenger } from '../../components/HubMessenger/FloatingHubMessenger';
import { HubPaymentsFull } from '../../components/HubPayments/HubPayments';
import { phoneNumbers } from '../../helpers/ageasTelephoneNumbers';
import MotorHubSettlements from '../../components/MotorHubSettlements/MotorHubSettlements';

const NotificationsCard = styled.div`
  padding-bottom: 20px;
`;

const Card = ({ children, cardRef, ...rest }) => {
  return (
    <div ref={cardRef}>
      <StandardPanel padding="23px 21px" panelPadding="0" primary {...rest}>
        {children || <></>}
      </StandardPanel>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  cardRef: PropTypes.shape({}),
};

Card.defaultProps = {
  children: undefined,
  cardRef: undefined,
};

const MotorTpHubDetail = () => {
  const [render, setRender] = useState(false);
  const hubContext = useContext(HubContextMotorTp);
  const { dataState } = hubContext;
  const { notifications = [] } = dataState;
  const unreadMessages = notifications.some(
    n => n.code === 'HO' && n.status === 'open',
  );
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [claimData, setClaimData] = useState(null);

  const [settlementData, setSettlementData] = useState(null);
  const settlementCardRef = React.createRef();
  const [paymentsData, setPaymentsData] = useState(null);

  const [dataLoadFail, setDataLoadFail] = useState(false);

  const [displayUploaderModal, setDisplayUploaderModal] = useState(null);
  const [documents, setDocuments] = useState([]);
  const localDocumentsId = useRef(0);
  const [uploaderNotificationCode, setUploaderNotificationCode] =
    useState(undefined);
  const [uploaderNotificationSequence, setUploaderNotificationSequence] =
    useState(undefined);

  const messengerPanelState = useState(false);
  const [messagesOpen, setMessagesOpen] = messengerPanelState;
  const messengerCardRef = React.createRef();

  // When Notification View button is clicked
  const onViewClick = notification => {
    if (notification.code === 'HO') {
      setMessagesOpen(true);
      messengerCardRef?.current?.scrollIntoView();
    } else if (notification.code === 'OF') {
      settlementCardRef.current.scrollIntoView();
    }
  };

  // When Notification Upload button clicked
  const onUploadClick = (notification, _event, { subAction }) => {
    setUploaderNotificationSequence(notification.sequence);
    if (subAction === 'allDocTypes') {
      setUploaderNotificationCode(undefined);
    } else {
      setUploaderNotificationCode(notification.code);
    }
    setDisplayUploaderModal('uploadDocumentModal');
  };

  // Close new message notifications when messenger is opened
  useEffect(() => {
    if (messagesOpen) {
      closeNotificationsByCode(hubContext, hubDataContextMotorTp, 'HO');
    }
  }, [messagesOpen]);

  const axiosCall = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosHelper.get(config.client.getMotorTpHubClaimDetails_endpoint, {
      cancelToken: axiosCancelToken.current.token,
    });
  };

  const getClaimDetails = () => {
    axiosCall()
      .then(({ data }) => {
        setIsLoading(false);
        const {
          settlements,
          leasedVehicle,
          notifications: notificationsList = [],
          payments = [],
          ...rest
        } = data;
        setClaimData(rest);
        updateNotifications(
          hubContext,
          hubDataContextMotorTp,
          notificationsList,
        );
        setSettlementData({ ...settlements, leasedVehicle });
        setPaymentsData(payments);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setDataLoadFail(true);
          setIsLoading(false);
        }
      });
  };

  // On page load
  useEffect(() => {
    window.scrollTo(0, 0);
    setRender(true);
    getClaimDetails();

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const onSuccessfulUpload = (
    _uploadedDocument,
    payload,
    _uploaderDocumentsState,
    formData,
  ) => {
    const documentType =
      MOTOR_TP_HUB_UPLOADABLE_TYPES_OBJECT[formData.category];
    // Add document to uploaded documents list
    if (documentType.correspondenceType) {
      localDocumentsId.current += 1;
      setDocuments([
        ...documents,
        {
          sequence: localDocumentsId.current,
          origin: 'localUploader',
          correspondenceType: documentType.correspondenceType,
        },
      ]);
    }

    // Clear associated notifications

    // If payload had a notificationSequence, close this notification
    if (payload.notificationSequence) {
      updateNotificationStatus(
        hubContext,
        hubDataContextMotorTp,
        payload.notificationSequence,
        'closed',
      );
    }
    if (documentType?.notificationCode) {
      // close all matching open notifications
      closeNotificationsByCode(
        hubContext,
        hubDataContextMotorTp,
        documentType.notificationCode,
      );
      if (documentType.notificationCode !== 'MD') {
        closeNotificationsByCode(hubContext, hubDataContextMotorTp, 'MD');
      }
    }
  };

  if (!render) {
    return null;
  }

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <StandardPageWrapper lob="motorTPA" thirdParty>
        <PageSectionV2>
          {dataLoadFail ? (
            <ErrorTextContainer>
              <ThemedErrorMessage hasIcon>
                {motorTpDataLoadFailingError()}
              </ThemedErrorMessage>
            </ErrorTextContainer>
          ) : (
            <>
              {!!notifications?.length && !isLoading && (
                <NotificationsCard>
                  <HubNotificationsPlain
                    notifications={notifications}
                    actions={{
                      view: onViewClick,
                      upload: onUploadClick,
                    }}
                  />
                </NotificationsCard>
              )}
              <Card title="Claim Details">
                <MotorTpHubClaimDetails claimDetails={claimData} />
              </Card>
              <Card title="Upload Documents">
                <MotorTPHubUploader
                  claimReference={claimData?.claimReference}
                  notificationCodes={
                    uploaderNotificationCode && [uploaderNotificationCode]
                  }
                  notificationSequence={uploaderNotificationSequence}
                  externalModalState={[
                    displayUploaderModal,
                    setDisplayUploaderModal,
                  ]}
                  onSuccessfulUpload={onSuccessfulUpload}
                />
                {!!documents?.length && (
                  <HubUploadedDocuments
                    documents={documents}
                    documentTypes={
                      MOTOR_TP_HUB_DOCUMENT_TYPES_BY_CORRESPONDENCE_TYPE
                    }
                  />
                )}
              </Card>
              <Card title="Settlements" cardRef={settlementCardRef}>
                <MotorHubSettlements
                  settlementData={settlementData}
                  isLoading={isLoading}
                  tluHandlerTelephone={phoneNumbers.tpAssist}
                  isTP
                  acceptOfferUrl={
                    config.client.motorTpAcceptAndPayOffer_endpoint
                  }
                  Context={HubContextMotorTp}
                />
              </Card>
              <Card title="Payments">
                <HubPaymentsFull
                  payments={paymentsData}
                  isLoading={isLoading}
                  isTP
                />
              </Card>
            </>
          )}
        </PageSectionV2>
      </StandardPageWrapper>
      <MotorTpHubMessenger
        externalOpenState={messengerPanelState}
        messengerRef={messengerCardRef}
        unread={unreadMessages}
      />
    </>
  );
};

export default MotorTpHubDetail;
