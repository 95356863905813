import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { ThemedButton } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { min } from 'ageasvalidation/module/schemas/Min';
import { max } from 'ageasvalidation/module/schemas/Max';
import { validateField } from '../../helpers/validationHelper';
import JewelleryItemSubform, { sanitiseValues } from './JewelleryItemSubForm';
import { getErrorMessage } from '../../helpers/errorMessages';

const schema = {
  specifiedItemName: stringRequired(
    ['specifiedItemName'],
    'Please select a specified item of jewellery',
  ),
  itemName: stringRequired(['itemName'], 'Please select an item of jewellery'),
  itemDescription: stringRequired(
    ['itemDescription'],
    'Please enter a description',
  ).concat(
    regularExpression(/\S/, ['itemDescription'], 'Please enter a description'),
  ),
  itemPurchased: stringRequired(
    ['itemPurchased'],
    'Please enter a store or retail outlet',
  ).concat(
    regularExpression(
      /\S/,
      ['itemPurchased'],
      'Please enter a store or retail outlet',
    ),
  ),
  itemReceipt: stringRequired(['itemReceipt'], 'Please select an option'),
  itemCost: stringRequired(['itemCost'], 'Please enter a valid value')
    .concat(min(1, ['itemCost'], true, 'Please enter a valid value'))
    .concat(max(9999.99, ['itemCost'], true, 'Please enter a valid value'))
    .concat(
      regularExpression(
        /^[0-9]{1,4}(\.[0-9]{2})?$/,
        ['itemCost'],
        'Please enter a valid value',
      ),
    ),
};

const validateFieldSchema = (value, allValues, meta) => {
  let error;
  let result;
  if (schema[meta.name]) {
    error = validateField(value, meta, schema);
    if (error) {
      return getErrorMessage(error);
    }
  }
  return result;
};

const JewelleryItemForm = ({
  initialValues,
  onSubmit,
  onCancel,
  specifiedItems = [],
  allSpecifiedItems = [],
}) => {
  const onFormSubmit = values => {
    onSubmit(sanitiseValues(values, specifiedItems));
  };

  const fieldBlurIntercept = event => {
    // Disable React Final Form field onBlur if new focus target is cancel
    // button (prevent field errors from appearing and moving page under mouse)
    return (
      event.relatedTarget?.getAttribute('data-is-cancel-button') !== 'true'
    );
  };

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      {({ handleSubmit, values, form }) => (
        <form method="post" onSubmit={handleSubmit}>
          <JewelleryItemSubform
            values={values}
            specifiedItems={specifiedItems}
            allSpecifiedItems={allSpecifiedItems}
            form={form}
            validate={validateFieldSchema}
            beforeAnyBlur={fieldBlurIntercept}
          />
          <ThemedButton small primary type="submit">
            Save
          </ThemedButton>{' '}
          {onCancel && (
            <ThemedButton
              small
              primary
              inverted
              type="button"
              onClick={onCancel}
              data-is-cancel-button
            >
              Cancel
            </ThemedButton>
          )}
        </form>
      )}
    </Form>
  );
};

export default JewelleryItemForm;

JewelleryItemForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  specifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  allSpecifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
};

JewelleryItemForm.defaultProps = {
  initialValues: {},
  onCancel: undefined,
  specifiedItems: undefined,
  allSpecifiedItems: undefined,
};
