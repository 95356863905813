import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ThemedLabel from 'ageas-ui-components/lib/components/ThemedLabel/ThemedLabel';
import { Input, ThemedErrorMessage } from 'ageas-ui-components';
import FieldStyled from '../FieldStyled/FieldStyled';

const TextInputGroup = styled.div`
  position: relative;
  border-collapse: separate;
  float: none;
  box-sizing: border-box;
  margin-bottom: 4px;
  border-radius: 5px;
`;

const InputHolder = styled.div`
  display: table;
  position: relative;
  width: 100%;
`;

const AdornSpan = styled.span`
  display: table-cell;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  z-index: 1;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 4px 0 0 4px;
  background-color: #8e419c;
  color: #fff;
  border: 1px solid #8e419c;
`;

const StandardCurrencyDecimalInput = props => {
  const {
    title,
    name,
    validate = () => {},
    adornText,
    isRequired,
    invalidInput,
    maxLength,
    'aria-label': ariaLabel,
    'data-testid': testId,
    beforeBlur,
    ...rest
  } = props;

  const filterNonNumericKeys = event => {
    // Check whether the char is a numeric char
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46)
      return true;
    event.preventDefault();

    // If not numeric, dismiss it
    /* eslint no-param-reassign: "error" */
    event.returnValue = false;
    return false;
  };

  const onInputBlur = (event, input) => {
    if (!beforeBlur || beforeBlur(event) !== false) input.onBlur(event);
  };

  return (
    <FieldStyled name={name} validate={validate} {...rest}>
      {({ input, meta }) => (
        <TextInputGroup>
          {title && <ThemedLabel>{title}</ThemedLabel>}
          <InputHolder {...rest} data-testid="inputHolder">
            <AdornSpan>{adornText}</AdornSpan>
            <Input
              maxLength={maxLength}
              id={input.name}
              data-testid={testId}
              aria-label={ariaLabel}
              {...input}
              onBlur={event => {
                onInputBlur(event, input);
              }}
              style={{ width: '100px', margin: '0px' }}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
              onKeyPress={filterNonNumericKeys}
            />
          </InputHolder>
          {meta.error && meta.touched && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
        </TextInputGroup>
      )}
    </FieldStyled>
  );
};

export default StandardCurrencyDecimalInput;

StandardCurrencyDecimalInput.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  adornText: PropTypes.string,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  invalidInput: PropTypes.shape({
    isInvalid: PropTypes.bool,
    icon: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  maxLength: PropTypes.string,
  'data-testid': PropTypes.string,
  'aria-label': PropTypes.string,
  beforeBlur: PropTypes.func,
};

StandardCurrencyDecimalInput.defaultProps = {
  name: undefined,
  title: '',
  adornText: '£',
  isRequired: false,
  validate: undefined,
  invalidInput: {},
  maxLength: undefined,
  'data-testid': 'decimalCurrencyInput',
  'aria-label': 'decimal currency input',
  beforeBlur: undefined,
};
