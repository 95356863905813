import styled, { css } from 'styled-components';
import media from '../MediaQuery/MediaQuery';

export const AccordionContentHeader = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 8px;

  font-size: 22px;
`;

export const AccordionSectionContainerBase = styled.div`
  width: 100%;
`;

const accordionSectionContainer002 = css`
  border-width: 1px;
  border-style: solid;
  border-color: rgb(227, 229, 237);
  border-bottom-width: 0px;
  &:last-child {
    border-bottom-width: 1px;
  }
  box-sizing: border-box;
`;
export const AccordionSectionContainer = styled(AccordionSectionContainerBase)`
  ${props => props.accordionNestLevel === 2 && accordionSectionContainer002}
`;

const accordionHeaderText001 = css`
  flex-grow: 0;
  ${media.phone`
    flex-grow: 1;
  `}
`;

export const AccordionHeaderText = styled.div`
  padding: 3px;
  min-width: 30px;
  min-height: 33px;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  font-size: 22px;
  ${props =>
    props.titlePaddingLeft &&
    css`
      padding-left: ${props.titlePaddingLeft};
    `}
  ${props => props.accordionNestLevel === 1 && accordionHeaderText001}
`;

export const AccordionHeader = styled.button`
  width: 100%;

  box-sizing: border-box;

  display: flex;
  align-items: center;

  box-shadow: none;
  border: none;
  border-radius: 4px;

  // Fancy focus - only focus-visible, which means does not apply to
  // focus by mouse click
  // plain focus fallback for older browsers, regular focus
  &:focus {
    outline: 2px solid rgb(142, 65, 156);
  }
  // Remove plain focus fallback for modern browsers
  &:focus:not(:focus-visible) {
    outline: none;
  }
  // focus-visible for modern browsers
  &:focus-visible {
    outline: 2px solid rgb(142, 65, 156);
  }

  cursor: pointer;
  background: none;
  font: inherit;
  color: inherit;
`;

export const AccordionHeaderContainerBase = styled.div`
  width: 100%;
  padding: 5px; // Another 2px comes from inner button border
  &:hover {
    background-color: rgb(211, 7, 115);
    color: white;
  }
  &:hover ${AccordionHeader} {
    outline-color: white;
  }
`;
const accordionHeaderContainer001 = css`
  color: black;
  font-weight: bold;
  background-color: rgb(243, 243, 243);
  ${AccordionSectionContainer}:not(:hover):nth-child(odd) & {
    background-color: rgb(235, 235, 235);
  }
  padding: 8px;
`;
const accordionHeaderContainerOpen002 = css`
  background-color: rgb(211, 7, 115);
  color: white;
  & ${AccordionHeader} {
    outline-color: white;
  }
`;
const accordionHeaderContainer002 = css`
  color: black;
  ${props => props.isSelected && accordionHeaderContainerOpen002};
`;
const accordionHeaderContainerOpen003 = css`
  font-weight: bold;
`;
const accordionHeaderContainer003 = css`
  color: black;
  ${props => props.isSelected && accordionHeaderContainerOpen003};
`;
export const AccordionHeaderContainer = styled(AccordionHeaderContainerBase)`
  ${props =>
    (props.accordionNestLevel === 1 && accordionHeaderContainer001) ||
    (props.accordionNestLevel === 2 && accordionHeaderContainer002) ||
    accordionHeaderContainer003}
`;

export const AccordionHeaderIconLeft = styled.div`
  padding: 3px;
  padding-right: 2px;
  width: 36px;
  height: 36px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AccordionHeaderIconLeftActive = styled(AccordionHeaderIconLeft)`
  display: none;
  ${AccordionHeaderContainer}:hover & {
    display: flex;
  }
  ${props =>
    props.isSelected &&
    css`
      display: flex;
    `};
`;
export const AccordionHeaderIconLeftInactive = styled(AccordionHeaderIconLeft)`
  display: flex;
  ${AccordionHeaderContainer}:hover & {
    display: none;
  }
  ${props =>
    props.isSelected &&
    css`
      display: none;
    `};
`;

export const AccordionHeaderIconLeft003 = styled(AccordionHeaderIconLeft)`
  color: rgb(142, 65, 156);
  font-size: 0.6em;
  ${AccordionHeaderContainer}:hover & {
    color: inherit;
  }
`;
export const AccordionHeaderIconRight002Open = css`
  color: inherit;
`;
export const AccordionHeaderIconRight002 = css`
  color: rgb(42, 143, 230);
  ${AccordionHeaderContainer}:hover & {
    color: inherit;
  }
  ${props => props.isSelected && AccordionHeaderIconRight002Open};
`;
export const AccordionHeaderIconRightBase = styled.div`
  padding: 3px;
  padding-left: 2px;
  width: 36px;
  height: 36px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AccordionHeaderIconRight = styled(AccordionHeaderIconRightBase)`
  ${props => props.accordionNestLevel === 2 && AccordionHeaderIconRight002}
`;

export const AccordionBodyOpen001 = css`
  padding: 8px;
`;
export const AccordionBodyOpen002 = css`
  padding-top: 8px;
`;
const accordionBodyOpen = css`
  opacity: 1;
  max-height: ${props => props.maxHeight || '8000px'};
  visibility: visible;
  transition:
    max-height 4s ease-out,
    opacity 0.5s ease-out;
  ${props => props.accordionNestLevel === 1 && AccordionBodyOpen001}
  ${props => props.accordionNestLevel === 2 && AccordionBodyOpen002}
`;
export const AccordionBody002 = css`
  background-color: rgb(248, 244, 250);
`;
export const AccordionBodyBase = styled.div`
  width: 100%;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  ${props => props.isSelected && accordionBodyOpen};
`;
export const AccordionBody = styled(AccordionBodyBase)`
  ${props => props.accordionNestLevel === 2 && AccordionBody002}
`;

export const AccordionBodyPaddedBase = styled.div`
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
  ${props =>
    props.padForIconLeft &&
    css`
      padding-left: 38px;
    `};
  ${props =>
    props.padForIconRight &&
    css`
      padding-right: 38px;
    `};

  font-size: 19px;
`;
