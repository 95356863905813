import React from 'react';
import { HomeClaimsFNOL } from '../../../../helpers/ageasTelephoneNumbers';
import FaqP from '../../FaqP';

export const title = 'Authentication code not received';
export const content = (
  <>
    <FaqP>
      We apologise that you are having trouble making a claim online. We take
      the protection of our customers&apos; information very seriously and
      always apply appropriate levels of security.
    </FaqP>
    <FaqP>
      In order for us to verify who you are, when you log in, we will send you
      an authentication code to either a mobile phone number or email address,
      which you provided when taking out your policy with us. If your details
      are not recognised, we unfortunately cannot send you a code to verify your
      identity.
    </FaqP>
    <FaqP>
      If you have not received an authentication code to complete security
      checks, you will need to contact our claims team to report your claim on{' '}
      <HomeClaimsFNOL />.
    </FaqP>
  </>
);

export const pair = { title, content };
