import React from 'react';
import PropTypes from 'prop-types';
import PerilCategoryUploader from '../DocumentUploader/PerilCategoryUploader';
import documentUploadTypes from '../../helpers/documentUploadTypes';
import { PERILS } from '../../helpers/perilCodesDescriptions';

const DOCUMENT_TYPES = [
  documentUploadTypes.DAMAGE_PHOTOS,
  documentUploadTypes.ESTIMATE,
  documentUploadTypes.RECEIPT_INVOICE,
  documentUploadTypes.TENANCY_AGREEMENT,
];

const EOWDocumentUploader = ({ claimReference }) => {
  return (
    <PerilCategoryUploader
      claimReference={claimReference}
      documentTypes={DOCUMENT_TYPES}
      perilDescription={PERILS.ESCAPE_OF_WATER.description}
    />
  );
};

export default EOWDocumentUploader;

EOWDocumentUploader.propTypes = {
  claimReference: PropTypes.string.isRequired,
};
