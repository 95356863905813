import React from 'react';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import { getIcons } from 'ageas-ui-themes';

const Icon = ({ brandIconSet, color, size, icon, className }) => {
  return (
    <div data-testid={`icon-${icon}`}>
      <IcomoonReact
        className={className}
        iconSet={getIcons(brandIconSet)}
        color={color}
        size={size}
        icon={icon}
      />
    </div>
  );
};

Icon.propTypes = {
  brandIconSet: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Icon.defaultProps = {
  brandIconSet: '',
  color: '',
  size: '',
  className: '',
};

export default Icon;
