import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Ul from '../../components/StandardTags/UL';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import {
  PageContainer,
  TitleText,
  PageSectionColoured,
  PageSection,
  TopText,
  TopTextSection,
  TopTextTitle,
  PhoneNumbersSection,
  PhoneNumbersTextContainer,
  PhoneNumbersContainer,
  NavLink,
} from './HomeClaimsWrapperStyle';
import PhoneNumberBox from '../CarInsurance/PhoneNumberBox';
import { phoneNumbers } from '../../helpers/ageasTelephoneNumbers';
import { brand } from '../../../data/whitelabel.config';
import makingClaimsEasyText from '../../helpers/makingClaimsEasyText';

const Theme = getTheme(brand);

const HomeClaimsTemplate = ({
  children,
  backToClaimsLink,
  makingClaimsEasy,
  getInTouch,
}) => {
  return (
    <PageContainer>
      <ThemeProvider theme={Theme}>
        <BrandHeader />
        <main>
          {makingClaimsEasy && (
            <TopText>
              <TopTextSection>
                <TopTextTitle>{makingClaimsEasyText.titleJSX}</TopTextTitle>
                {makingClaimsEasyText.textJSX}
              </TopTextSection>
            </TopText>
          )}

          {backToClaimsLink && (
            <PageSection>
              <TitleText primary>Home claims</TitleText>
              <NavLink to="/">
                <FontAwesomeIcon icon={faChevronLeft} /> back to claims
              </NavLink>
            </PageSection>
          )}

          <PageSectionColoured>{children}</PageSectionColoured>

          {getInTouch && (
            <PhoneNumbersSection>
              <PhoneNumbersTextContainer>
                <TitleText>Do you need urgent support?</TitleText>
                <Ul>
                  <li>Is your property uninhabitable or unsecured?</li>
                  <li>
                    Do you need to make us aware of any exceptional
                    circumstances affecting you or someone you live with?
                  </li>
                  <li>
                    Please get in touch over the phone and we&apos;ll get you to
                    the right person as soon as possible.
                  </li>
                </Ul>
              </PhoneNumbersTextContainer>
              <PhoneNumbersContainer>
                <PhoneNumberBox
                  title="Make a claim"
                  number={phoneNumbers.homeClaimsLandingFNOL}
                />
                <PhoneNumberBox
                  title="Legal Expenses claims"
                  number={phoneNumbers.legalExpenses}
                />
                <PhoneNumberBox
                  title="Home Emergency claims"
                  number={phoneNumbers.homeEmergency}
                />
              </PhoneNumbersContainer>
            </PhoneNumbersSection>
          )}
        </main>
        <BrandFooter />
      </ThemeProvider>
    </PageContainer>
  );
};

HomeClaimsTemplate.propTypes = {
  children: PropTypes.node,
  backToClaimsLink: PropTypes.bool,
  makingClaimsEasy: PropTypes.bool,
  getInTouch: PropTypes.bool,
};
HomeClaimsTemplate.defaultProps = {
  children: undefined,
  backToClaimsLink: false,
  makingClaimsEasy: false,
  getInTouch: false,
};

export default HomeClaimsTemplate;
