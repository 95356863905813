import React, { useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage } from 'ageas-ui-components';
import config from '../../helpers/config';
import axiosHelper from '../../helpers/axios';
import {
  ErrorTextContainer,
  PageSectionV2,
} from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import {
  HubContextMotor,
  hubDataContextMotor,
} from '../../helpers/hubContextHelper';
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import BackButtonSection from '../../components/StandardPageWrapper/BackButtonSection';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import MotorHubClaimDetails from '../../components/MotorHubClaimDetails/MotorHubClaimDetails';
import { dataLoadFailingError } from '../../helpers/errorMessages';
import { HubNotificationsPlain } from '../../components/HubNotifications/HubNotifications';
import { updateNotifications } from '../../helpers/hubNotificationsHelper';
import MotorHubSettlements from '../../components/MotorHubSettlements/MotorHubSettlements';
import { HubPaymentsFull } from '../../components/HubPayments/HubPayments';
import { phoneNumbers } from '../../helpers/ageasTelephoneNumbers';
import HubStatementBuilder from '../../components/HubStatementBuilder/HubStatementBuilder';

const BACK_BUTTON_PATH = '/motorhub';

const NotificationsCard = styled.div`
  padding-bottom: 20px;
`;

const Card = ({ children, cardRef, isLoading = false, ...rest }) => {
  return (
    <div ref={cardRef}>
      <StandardPanel padding="23px 21px" panelPadding="0" primary {...rest}>
        {(!isLoading && children) || <></>}
      </StandardPanel>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  cardRef: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

Card.defaultProps = {
  children: undefined,
  cardRef: undefined,
  isLoading: undefined,
};

const MotorHubDetail = () => {
  const history = useHistory();
  const [render, setRender] = useState(false);
  const hubContext = useContext(HubContextMotor);
  const { dataState } = hubContext;
  const { notifications } = dataState;
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [claimPolicyData, setClaimPolicyData] = useState(null);
  const [settlementData, setSettlementData] = useState(null);
  const [paymentsData, setPaymentsData] = useState(null);
  const statementRequestsCardRef = React.createRef();
  const [statementRequests, setStatementRequests] = useState(undefined);

  const [dataLoadFail, setDataLoadFail] = useState(false);
  const settlementCardRef = React.createRef();

  const onViewClick = notification => {
    if (notification.code === 'OF') {
      settlementCardRef.current.scrollIntoView();
    } else if (notification.code === 'SB') {
      statementRequestsCardRef.current.scrollIntoView();
    }
  };

  const axiosCall = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosHelper.get(
      `${
        config.client.getMotorHubClaimDetails_endpoint
      }?claimReference=${encodeURIComponent(
        dataState.claimDetails?.claimReference,
      )}`,
      {
        cancelToken: axiosCancelToken.current.token,
      },
    );
  };

  const getClaimDetails = () => {
    axiosCall()
      .then(({ data }) => {
        setIsLoading(false);
        const {
          settlements,
          notifications: notificationsList,
          payments = [],
          statementBuilderRequests,
          ...rest
        } = data;
        if (!rest.tluHandlerTelephone?.trim()) {
          rest.tluHandlerTelephone = phoneNumbers.tluTeamHuntGroup;
        }
        setClaimPolicyData(rest);
        updateNotifications(hubContext, hubDataContextMotor, notificationsList);
        setSettlementData(settlements);
        setPaymentsData(payments);
        setStatementRequests(statementBuilderRequests);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setDataLoadFail(true);
          setIsLoading(false);
        }
      });
  };

  // On page load
  useEffect(() => {
    // Return to previous page if no claim ref to load
    if (!dataState.claimDetails?.claimReference) {
      history.push('/motorhub');
      return undefined;
    }

    window.scrollTo(0, 0);
    setRender(true);
    getClaimDetails();

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  if (!render) {
    return null;
  }

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <StandardPageWrapper lob="motor">
        <PageSectionV2>
          <BackButtonSection to={BACK_BUTTON_PATH} />
          {dataLoadFail ? (
            <ErrorTextContainer>
              <ThemedErrorMessage hasIcon>
                {dataLoadFailingError('motor')}
              </ThemedErrorMessage>
            </ErrorTextContainer>
          ) : (
            <>
              {!!notifications?.length && !isLoading && (
                <NotificationsCard>
                  <HubNotificationsPlain
                    notifications={notifications}
                    actions={{ view: onViewClick }}
                  />
                </NotificationsCard>
              )}
              <Card title="Claim Details">
                <MotorHubClaimDetails {...claimPolicyData} />
              </Card>
              <Card title="Settlements" cardRef={settlementCardRef}>
                <MotorHubSettlements
                  settlementData={settlementData}
                  isLoading={isLoading}
                  tluHandlerTelephone={claimPolicyData?.tluHandlerTelephone?.trim()}
                />
              </Card>
              <Card title="Payments">
                <HubPaymentsFull
                  payments={paymentsData}
                  isLoading={isLoading}
                />
              </Card>
              <Card
                title="Statement Builder"
                isLoading={isLoading}
                cardRef={statementRequestsCardRef}
              >
                <HubStatementBuilder
                  claimReference={dataState.claimDetails?.claimReference}
                  statementRequests={statementRequests}
                  setStatementRequests={setStatementRequests}
                  piHandlerTelephone={claimPolicyData?.piHandlerTelephone?.trim()}
                />
              </Card>
            </>
          )}
        </PageSectionV2>
      </StandardPageWrapper>
    </>
  );
};

export default MotorHubDetail;
