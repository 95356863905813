import React from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { MotorClaimsTelLink, AgeasGlassTelLink } from './ageasTelephoneNumbers';
import ThemedIconButton from '../components/ThemedIconButton/ThemedIconButton';
import P from '../components/StandardTags/P';
import { checkFeatureSwitch } from '../components/FeatureSwitch/FeatureSwitch';

const LEGACY_CLAIM_URL = '/motorclaim/incidentdetails';
const NEW_CLAIM_URL = '/motorclaim/history';
const WAS_ANYBODY_INJURED_QUESTION_TEXT =
  'Was anybody injured as a result of the incident or did it involve a pedestrian, a pedal cyclist or a motor cyclist?';

const renderPleaseCallUs = () => {
  return (
    <P>
      Please call us on{' '}
      <strong>
        <MotorClaimsTelLink />
      </strong>{' '}
    </P>
  );
};

const renderGlassClaimContent = () => {
  return (
    <p>
      Glass repair and replacement is provided by Autoglass. Please{' '}
      <a href="https://www.autoglass.co.uk/ideal/damage/ins/ageas/">
        click&nbsp;here
      </a>{' '}
      to book your appointment online. Alternatively, you can call them on{' '}
      <AgeasGlassTelLink />
    </p>
  );
};

const renderContinueButton = (renderDisclaimer = () => {}) => {
  return (
    <>
      <P>
        Please submit your claim using our online claim journey. By completing
        your claim online, we will be able to validate your claim and take the
        appropriate action needed immediately. As your policy has an excess, it
        is important that you check what this excess is <u>before</u> submitting
        your claim. The excess is the amount that you will have to pay when
        making your claim.
      </P>
      <ThemedIconButton
        type="button"
        secondary
        onClick={renderDisclaimer}
        iconRight={faChevronCircleRight}
      >
        Continue
      </ThemedIconButton>
    </>
  );
};

const progressiveQuestionMotorHelper = (renderDisclaimer = () => {}) => {
  return [
    {
      id: 1,
      answers: [
        {
          id: 'tradeVehicle',
          description:
            'A claim to a vehicle insured on a fleet or motor trade policy',
          content: renderPleaseCallUs(),
        },
        {
          id: 'singleVehicle',
          description: 'Accident involving my vehicle only',
          nextId: checkFeatureSwitch('FEATURE_SVA_JOURNEY_ENABLED', [
            'version1',
          ])
            ? 7
            : 2,
        },
        {
          id: 'multiVehicle',
          description: 'Accident involving more than 1 vehicle',
          nextId: 2,
        },
        {
          id: 'fireDamage',
          description: 'Fire damage to my vehicle',
          content: renderPleaseCallUs(),
        },
        {
          id: 'theft',
          description:
            'Theft of my vehicle including any resulting vehicle damage',
          content: renderPleaseCallUs(),
        },
        {
          id: 'windscreen',
          description: 'Damage to the windscreen or other glass in my vehicle',
          content: renderGlassClaimContent(),
        },
        {
          id: 'anyOtherReason',
          description: 'Any other reason',
          content: renderPleaseCallUs(),
        },
      ],
    },
    {
      id: 2,
      title: WAS_ANYBODY_INJURED_QUESTION_TEXT,
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: renderPleaseCallUs(),
        },
        {
          id: 'no',
          description: 'No',
          nextId: 3,
        },
      ],
    },
    {
      id: 3,
      title:
        'Do you think you or the named driver could have been at fault for the incident?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: renderPleaseCallUs(),
        },
        {
          id: 'no',
          description: 'No',
          nextId: 4,
        },
        {
          id: 'dontKnow',
          description: "Don't know",
          content: renderPleaseCallUs(),
        },
      ],
    },
    {
      id: 4,
      title: 'Were more than just your vehicle and 1 other involved?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: renderPleaseCallUs(),
        },
        {
          id: 'no',
          description: 'No',
          nextId: 5,
        },
        {
          id: 'dontKnow',
          description: "Don't know",
          content: renderPleaseCallUs(),
        },
      ],
    },
    {
      id: 5,
      title:
        'Was any property (buildings, walls, lamp posts etc) damaged as a result of this incident?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: renderPleaseCallUs(),
        },
        {
          id: 'no',
          description: 'No',
          nextId: 6,
        },
        {
          id: 'dontKnow',
          description: "Don't know",
          content: renderPleaseCallUs(),
        },
      ],
    },
    {
      id: 6,
      title:
        'Did this incident involve a head on collision or a collision on a roundabout?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: renderPleaseCallUs(),
        },
        {
          id: 'no',
          description: 'No',
          content: renderContinueButton(() =>
            renderDisclaimer(LEGACY_CLAIM_URL),
          ),
        },
        {
          id: 'dontKnow',
          description: "Don't know",
          content: renderPleaseCallUs(),
        },
      ],
    },
    {
      id: 7,
      title: WAS_ANYBODY_INJURED_QUESTION_TEXT,
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: renderPleaseCallUs(),
        },
        {
          id: 'no',
          description: 'No',
          content: renderContinueButton(() => renderDisclaimer(NEW_CLAIM_URL)),
        },
      ],
    },
  ];
};
export default progressiveQuestionMotorHelper;
