import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

import validAddress from '../extensions/ValidAddress';

const JoiExt = Joi.extend(validAddress);

/**
 * @description: validates an address house name/number
 * @param keys: fieldnames to validate
 * @param message: message to display when validation fails.
 */
export const houseNameOrNumber = (
  keys = ['houseNameOrNumber'],
  message = messages.INVALID_ADDRESS_LINE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExt.any()
      .required()
      .validAddress()
      .error(() => message),
  );
};

/**
 * @description: validates an address line
 * @param keys: fieldnames to validate
 * @param message: message to display when validation fails.
 */
export const addressLine = (
  keys = ['addressLine'],
  message = messages.INVALID_ADDRESS_LINE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .optional()
      .allow('')
      .max(32)
      .regex(regex.addressLine)
      .error(() => message),
  );
};
