import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ChatBotContext = React.createContext();

const ChatBotContextProvider = ({ children }) => {
  const [boost, setBoost] = useState(null);

  const value = useMemo(
    () => ({
      boost,
      setBoost,
    }),
    [boost, setBoost],
  );

  return (
    <ChatBotContext.Provider value={value}>{children}</ChatBotContext.Provider>
  );
};

ChatBotContextProvider.propTypes = {
  children: PropTypes.node,
};

ChatBotContextProvider.defaultProps = {
  children: undefined,
};

export { ChatBotContext, ChatBotContextProvider };
