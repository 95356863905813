const buildingDamagedItem = [
  {
    id: 'flatRoof',
    name: 'Flat roof',
  },
  {
    id: 'roofTiles',
    name: 'Roof tiles',
  },
  {
    id: 'guttering',
    name: 'Guttering',
  },
  {
    id: 'flashing',
    name: 'Flashing',
  },
  {
    id: 'chimney',
    name: 'Chimney',
  },
  {
    id: 'rendering',
    name: 'Rendering',
  },
  {
    id: 'pebbleDash',
    name: 'Pebble dash',
  },
  {
    id: 'conservatoryRoof',
    name: 'Conservatory roof',
  },
  {
    id: 'gardenWalls',
    name: 'Garden walls',
    categoryMobile: 'garden',
  },
  {
    id: 'outbuildings',
    name: 'Outbuildings',
    categoryMobile: 'garden',
  },
];

export default buildingDamagedItem;
