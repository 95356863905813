import Joi from '@hapi/joi';
import moment from 'moment';

import { messages } from '../constants/ErrorMessages';
import { greaterThanEqual } from '../extensions/ValidDate';

const JoiExtended = Joi.extend(greaterThanEqual);

const ValidKeys = ['licenceDate'];

/**
 * ------- TODO - Update README -------
 * @description Validates that the number of years the person has had a driving licence is consistent with them receiving it on or after their 17th birthday
 * @param differenceBetweenLicenceAndDOB The difference in years between the  selected licence age and the customer's DoB
 * @param yearsLicenceHeld The number of years the licence has been held for
 * @param message The message to display when validation fails
 * @succeeds when the number of years they have held licence is consistent  with gaining it after their 17th birthday or when the years the licence has  been held for is 0 (in which case another field would be displayed on the  front end)
 * @fails when the number of years they have held licence is NOT consistent  with gaining it after their 17th birthday
 */
export const licenceHeldForYears = (
  differenceBetweenLicenceAndDOB = 'differenceBetweenLicenceAndDOB',
  customerLicenceDuration = 'customerLicenceDuration',
  message = messages.INVALID_LICENCE_HELD,
) => {
  return Joi.object().pattern(
    Joi.valid(customerLicenceDuration),
    Joi.when(Joi.ref(customerLicenceDuration), {
      is: Joi.number().valid(0),
      otherwise: Joi.number()
        .max(Joi.ref(differenceBetweenLicenceAndDOB))
        .error(() => message),
    }),
  );
};

/**
 * @description Validates that the date the person received their driving licence is on or after their 17th birthday
 * @param dateOfBirth The person's date of birth (DD/MM/YYYY)
 * @param keys The list of fields to validate against
 * @param message The message to display when validation fails
 * @succeeds when licence date is on or after 17th birthday
 * @fails when licence date is before 17th birthday
 */
export const licenceAfter17thBirthday = (
  dateOfBirth,
  keys = ValidKeys,
  message = messages.INVALID_LICENCE_DATE,
) => {
  const dob = moment(dateOfBirth, 'DD/MM/YYYY')
    .add(17, 'years')
    .format('DD/MM/YYYY');

  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExtended.string()
      .greaterThanEqual(dob)
      .error(() => message),
  );
};
