import React from 'react';
import { extentDamageTitle, extentDamageContent } from './ExtentDamage';
import {
  insureCoverStormDamageTitle,
  insureCoverStormDamageContent,
} from './InsureCoverStormDamage';
import { myPolicyExcessTitle, myPolicyExcessContent } from './MyPolicyExcess';
import {
  stormClaimStatusTitle,
  stormClaimStatusContent,
} from './StormClaimStatus';
import {
  stormDamageClaimTitle,
  StormDamageClaimContent,
} from './StormDamageClaim';
import { stormDetailTitle, stormDetailContent } from './StormDetail';
import {
  stormClaimNotBeCoverTitle,
  StormClaimNotBeCoverContent,
} from './StormClaimNotBeCover';
import {
  temporaryRepairsTitle,
  temporaryRepairsContent,
} from './TemporaryRepairs';
import {
  userNotAcceptClaimDeclineTitle,
  UserNotAcceptClaimDeclineContent,
} from './UserNotAcceptClaimDecline';
import {
  userStartRepairsTitle,
  UserStartRepairsContent,
} from './UserStartRepairs';
import {
  stormProgressClaimTitle,
  stormProgressClaimContent,
} from './StormProgressClaim';

const weatherDamageQuestions = [
  {
    title: stormDetailTitle,
    content: stormDetailContent,
  },
  {
    title: stormDamageClaimTitle,
    content: <StormDamageClaimContent />,
  },
  {
    title: insureCoverStormDamageTitle,
    content: insureCoverStormDamageContent,
  },
  {
    title: stormClaimStatusTitle,
    content: stormClaimStatusContent,
  },
  {
    title: myPolicyExcessTitle,
    content: myPolicyExcessContent,
  },
  {
    title: userNotAcceptClaimDeclineTitle,
    content: <UserNotAcceptClaimDeclineContent />,
  },
  {
    title: stormClaimNotBeCoverTitle,
    content: <StormClaimNotBeCoverContent />,
  },
  {
    title: temporaryRepairsTitle,
    content: temporaryRepairsContent,
  },
  {
    title: userStartRepairsTitle,
    content: <UserStartRepairsContent />,
  },
  {
    title: extentDamageTitle,
    content: extentDamageContent,
  },
  {
    title: stormProgressClaimTitle,
    content: stormProgressClaimContent,
  },
];

export default weatherDamageQuestions;
