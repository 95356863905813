import React from 'react';
import FaqP from '../../FaqP';

export const buildingClaimTitle =
  'What should I expect when making a buildings claim?';
export const buildingClaimContent = (
  <>
    <FaqP>
      Based on the information you provide, we will check your policy to ensure
      you are covered and let you know how we can help.
    </FaqP>
    <FaqP>
      We may be able to offer you a cash settlement for certain claims to enable
      you to get the repairs completed yourself.
    </FaqP>

    <FaqP>
      If there is more work to be done, we may instruct one of our approved
      contractors to contact you directly and make arrangements for the repairs.
    </FaqP>
    <FaqP>
      If the damage is substantial, we will send out an expert to manage the
      claim and talk you through the best course of action.
    </FaqP>
    <FaqP>
      You may be asked to provide supporting documents for your claim e.g.
      photos, quotations, receipts or proof of purchase in some cases. Details
      of where to send this will be provided by your claims adviser.
    </FaqP>
    <FaqP>
      If the damage has been caused by water, depending on the time of year and
      the extent of the damage, we do advise that you turn on the heating and
      open the windows to help dry out the property as much as possible, in some
      cases this is all that is needed to resolve the issues.
    </FaqP>
  </>
);
