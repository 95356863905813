import React from 'react';

export const localStoragePropertyName = 'motorClaimNewFormState';

export const motorClaimDataContext = {
  claimDetails: 'claimDetails',
  claimEntryData: 'claimEntryData',
};

export const MotorClaimContextNew = React.createContext({});

export const motorClaimContextData = {
  motorClaimDataContext,
  MotorClaimContextNew,
  localStoragePropertyName,
};
