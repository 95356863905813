import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../../StandardRadio/StandardRadio';
import { FieldHeader } from '../../../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';

const DEFAULT_FIELD_NAME = 'homeAwayLocation';
const LOCATION_LIST = [
  {
    value: 'home',
    label: 'In the home',
  },
  {
    value: 'away',
    label: 'Away from the home',
  },
];

export const getHomeAwayLocationSchema = (fieldName = DEFAULT_FIELD_NAME) => {
  return {
    [fieldName]: stringRequired([fieldName], 'Please select a location'),
  };
};

const HomeAwayLocation = ({
  id,
  name = DEFAULT_FIELD_NAME,
  customDescription,
  headerProps,
  ...rest
}) => {
  return (
    <>
      <FieldHeader {...headerProps}>
        {customDescription || <>Please select where the incident happened</>}
      </FieldHeader>
      <StandardRadio
        name={name}
        options={LOCATION_LIST}
        blockProps={{ marginTop: '0px' }}
        brandIconSet="ageas"
        medium
        vertical
        {...rest}
      />
    </>
  );
};

HomeAwayLocation.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  customDescription: PropTypes.string,
  headerProps: PropTypes.shape({}),
};
HomeAwayLocation.defaultProps = {
  id: undefined,
  name: undefined,
  customDescription: undefined,
  headerProps: undefined,
};

export default HomeAwayLocation;
