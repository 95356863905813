import React from 'react';
import FaqP from '../../FaqP';

export const myExcessPayTitle =
  'What is my excess, when will I have to pay it and how will you collect it?';
export const myExcessPayContent = (
  <>
    <FaqP>Your excess is shown on your policy documentation.</FaqP>
    <FaqP>
      Your excess will be deducted from the total amount to be paid by us when
      we settle the claim.
    </FaqP>
  </>
);
