const titleCodes = [
  { value: '001', label: 'Mr' },
  { value: '002', label: 'Mrs' },
  { value: '003', label: 'Ms' },
  { value: '004', label: 'Miss' },
  { value: '005', label: 'Doctor' },
  { value: '006', label: 'Father' },
  { value: '007', label: 'Hon' },
  { value: '008', label: 'HRH' },
  { value: '009', label: 'Lady' },
  { value: '010', label: 'Lord' },
  { value: '011', label: 'Professor' },
  { value: '012', label: 'Reverend' },
  { value: '013', label: 'Sir' },
  { value: '014', label: 'PC' },
  { value: '015', label: 'Baron' },
  { value: '016', label: 'Baroness' },
  { value: '017', label: 'Dame' },
  { value: '018', label: 'Mx' },
];

export const titleCodeToObject = titleCode => {
  return titleCodes.find(title => title.value === titleCode);
};
export const titleCodeToText = titleCode => {
  return titleCodeToObject(titleCode)?.label;
};

export default titleCodes;
