import React from 'react';
import FaqP from '../../FaqP';

export const contentsInfoTitle = 'What are contents?';
export const contentsInfoContent = (
  <>
    <FaqP>
      Contents insurance will cover the items and valuables within your home
      that are not permanent features, fixtures or fittings of the building.
    </FaqP>
    <FaqP>
      This will usually include things like your furniture, kitchen appliances,
      jewellery and entertainment systems.
    </FaqP>
    <FaqP>
      Some items, such as carpets, may seem like buildings but are, in fact,
      considered contents.
    </FaqP>
    <FaqP>
      If you are unsure what your item falls under and need to make a claim, get
      in touch with the Claims Team and they will be able to work this out for
      you.
    </FaqP>
  </>
);
