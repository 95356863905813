import PropTypes from 'prop-types';

export const STATEMENT_TYPES = {
  CAUSATION: 'causation',
};
export const STATEMENT_TYPES_LIST = Object.values(STATEMENT_TYPES);

export const MODAL_MODE = {
  DECLARATION: 'declaration',
  QUESTIONS: 'questions',
  COMPLETION: 'completion',
  LOAD_ERROR: 'loadError',
};

export const hasDeclaration = statementRequest =>
  !!statementRequest.statementText?.length;
export const isCausation = statementRequest =>
  statementRequest.type === STATEMENT_TYPES.CAUSATION;

export const getQuestionsForType = (statementRequest = {}) => {
  return statementRequest?.type
    ? statementRequest[statementRequest.type]
    : undefined;
};

export const statementTextPropType = PropTypes.arrayOf(PropTypes.string);

export const injuriesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    location: PropTypes.string,
  }),
);

export const statementRequestPropType = PropTypes.shape({
  sequenceNumber: PropTypes.number,
  type: PropTypes.oneOf(STATEMENT_TYPES_LIST),
  status: PropTypes.string,
  tpName: PropTypes.string,
  injuries: injuriesPropType,
  statementText: statementTextPropType,
  causation: PropTypes.shape({}),
});

export const sanitiseText = (x, ifUnknown = '-unknown-') =>
  x?.trim() || ifUnknown;

export default statementRequestPropType;
