import React from 'react';
import PropTypes from 'prop-types';
import {
  TitleText,
  PhoneNumberBoxStyle,
  PhoneNumber,
} from './CarHomeInsurance.style';

const PhoneNumberBox = ({ title, number, ...rest }) => {
  return (
    <PhoneNumberBoxStyle {...rest}>
      <TitleText>{title}</TitleText>
      <PhoneNumber number={number} />
    </PhoneNumberBoxStyle>
  );
};

export default PhoneNumberBox;

PhoneNumberBox.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  number: PropTypes.string.isRequired,
};
