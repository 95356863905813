import React from 'react';
import PropTypes from 'prop-types';
import { FIELD_NAMES, RELATIONSHIP_LIST } from '../motorYourDetailsHelper';
import ConfirmUpdate from '../ConfirmUpdate/ConfirmUpdate';
import StandardDate from '../../../components/Forms/StandardDate/StandardDate';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import ABICodeAutoComplete from '../../../components/Forms/ABICodeAutoComplete/ABICodeAutoComplete';
import StandardDropdown from '../../../components/Forms/StandardDropdown/StandardDropdown';
import config from '../../../helpers/config';

const validateAutoComplete = (
  validateFields,
  { label, value } = {},
  allValues,
  meta,
) => {
  return (
    validateFields &&
    (validateFields(label, allValues, meta) ||
      validateFields(value, allValues, meta))
  );
};

/**
 * Component for displaying data UW questions for change of owner/keeper
 */
const OwnerKeeperConfirmDetails = ({
  confirmTitle,
  driverName,
  validateFields,
  subjectType,
  simplifiedProduct,
  hint,
}) => {
  const subject = subjectType === 'O' ? 'owner' : 'keeper';
  const fieldNameSubject = subjectType === 'O' ? 'OWNER' : 'KEEPER';
  const fieldNamePrefix = `VEHICLE_${fieldNameSubject}`;

  return (
    <ConfirmUpdate
      title={confirmTitle}
      value={driverName}
      yesNoName={FIELD_NAMES[`${fieldNamePrefix}_IS_CORRECT`]}
      validate={validateFields}
      hint={hint}
    >
      {!(subjectType === 'K' && simplifiedProduct) && (
        <>
          <StandardDate
            name={FIELD_NAMES[`${fieldNamePrefix}_CHANGED_DATE`]}
            id={FIELD_NAMES[`${fieldNamePrefix}_CHANGED_DATE`]}
            label={`Please confirm the date the ${subject} was changed`}
            validate={validateFields}
          />
          <InvolvedParty
            name={FIELD_NAMES[`${fieldNamePrefix}_NAME`]}
            header={`Name of the new ${subject}`}
            hideAddress
            hideContact
            validate={validateFields}
          />
          <StandardDate
            name={FIELD_NAMES[`${fieldNamePrefix}_DATE_OF_BIRTH`]}
            id={FIELD_NAMES[`${fieldNamePrefix}_DATE_OF_BIRTH`]}
            label={`Date of birth of the new ${subject}`}
            validate={validateFields}
          />
          {simplifiedProduct && (
            <ABICodeAutoComplete
              name={FIELD_NAMES[`${fieldNamePrefix}_STATUS`]}
              label={`What is the relationship of the new ${subject} to the policyholder?`}
              selectionMsg="Please select from the list below"
              validate={(...args) =>
                validateAutoComplete(validateFields, ...args)
              }
              url={config.client.getOwnerKeeperStatusList_endpoint}
              minCharacters={0}
              openOnZeroInput
              noSuggestionsMsg="No results found, please try typing another relationship type"
              placeholder="Start typing"
              blockProps={{ medium: true }}
            />
          )}
          {!simplifiedProduct && (
            <StandardDropdown
              name={FIELD_NAMES[`${fieldNamePrefix}_RELATIONSHIP_TO_PH`]}
              label={`What is the relationship of the new ${subject} to the policyholder?`}
              aria-label={`What is the relationship of the new ${subject} to the policyholder?`}
              options={RELATIONSHIP_LIST}
              small
              validate={validateFields}
            />
          )}
        </>
      )}
    </ConfirmUpdate>
  );
};

OwnerKeeperConfirmDetails.propTypes = {
  confirmTitle: PropTypes.string.isRequired,
  driverName: PropTypes.string.isRequired,
  validateFields: PropTypes.func.isRequired,
  subjectType: PropTypes.string.isRequired,
  simplifiedProduct: PropTypes.bool.isRequired,
  hint: PropTypes.node,
};

OwnerKeeperConfirmDetails.defaultProps = {
  hint: undefined,
};

export default OwnerKeeperConfirmDetails;
