import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

const validKeys = ['bankAccount', 'accountNumber'];
const validSortCodeKeys = ['sortCode'];

/**
 * @description: validates a UK bank account number (8 digits)
 * @param keys: fieldnames to validate
 * @param message: message to display when validation fails.
 */
export const ukBankAccount = (
  keys = validKeys,
  message = messages.INVALID_ACCOUNT_NUMBER,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex.ukBankAccount)
      .required()
      .error(() => message),
  );
};

/**
 * @description: validates a uk sort code (6 digits or 00-00-00)
 * @param keys: fieldnames to validate
 * @param message: message to display when validation fails.
 */
export const ukSortCode = (
  // eslint-disable-next-line no-unused-vars
  _keys = validSortCodeKeys,
  message = messages.INVALID_SORT_CODE,
) => {
  return Joi.object().pattern(
    Joi.valid(validSortCodeKeys),
    Joi.string()
      .regex(regex.ukSortCode)
      .required()
      .error(() => message),
  );
};
