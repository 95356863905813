import React from 'react';
import FaqP from '../../FaqP';

export const contentsInsuranceCoverageTitle =
  'What is covered by contents insurance?';
export const contentsInsuranceCoverageContent = (
  <>
    <FaqP>
      This will depend on the cover you took out when you purchased your
      contents policy.
    </FaqP>
    <FaqP>
      Essentially, your standard contents insurance policy will cover the cost
      of repairing or replacing the contents of your home if they are damaged by
      an insured event such as theft, fire, flood and leakage of water. If you
      also purchased the optional add-on cover of Accidental Damage, that will
      cover you for other instances relating to accidental damage (which is
      damage that is unexpected and unintended, caused by something sudden and
      which is not deliberate).
    </FaqP>
    <FaqP>
      There will be exclusions for each type of peril, so please refer to your
      home insurance policy booklet for full details of your cover.
    </FaqP>
  </>
);
