import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { ThemedTitle } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';
import { MotorClaimsTelLink } from '../../helpers/ageasTelephoneNumbers';
import StandardPanel from '../../components/StandardPanel/StandardPanel';

const Theme = getTheme(brand);
const PageContainer = styled.div`
  max-width: 1100px;
  margin: auto;
`;
const CustomThemedTitle = styled.div`
  font-weight: normal;
  text-align: left;
  font-size: 22px;
  padding: 0px;
`;

const ThankYouEClaim = history => {
  const [claimNumber, setClaimNumber] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setClaimNumber(history?.history?.location?.state?.claimReference);
  }, []);
  return (
    <ThemeProvider theme={Theme}>
      <BrandHeader suppressWarningOnClick />
      <main>
        <PageContainer>
          <StandardPanel
            primary
            title="Thank you"
            padding="23px 21px 0px 21px"
            panelPadding="0px 0px 16px 0px"
          >
            <br />
            <CustomThemedTitle>
              Thank you for submitting your claim, one of our claims handlers
              will be in touch by the end of the next working day.
            </CustomThemedTitle>
            <br />
            <Spacer>
              <ThemedTitle small>
                {' '}
                The claim number for your policy is:{' '}
                <strong> {claimNumber}</strong>
              </ThemedTitle>
            </Spacer>
            <Spacer>
              <ThemedTitle small>
                Please make a note of this number for your records.
              </ThemedTitle>
            </Spacer>
            <p>
              If you need to contact us please call:&nbsp;
              <strong>
                <MotorClaimsTelLink />
              </strong>
            </p>
          </StandardPanel>
        </PageContainer>
      </main>
      <BrandFooter />
    </ThemeProvider>
  );
};
export default ThankYouEClaim;

ThankYouEClaim.propTypes = {
  history: PropTypes.shape({
    state: PropTypes.shape({
      claimReference: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};
