import { formatISOTime, reverseDate } from './dateTimeConverter';

export const getSupplierMilestoneDescription = (
  type,
  data,
  additionalData = {},
) => {
  const SUPPLIER_MILESTONE_DESCRIPTIONS = {
    receivedInstruction: `Instruction received ${
      data.receivedTime ? `at ${formatISOTime(data.receivedTime)}` : ''
    } on ${reverseDate(data.receivedDate)}`,
    customerContacted: `You were contacted ${
      data.contactedTime ? `at ${formatISOTime(data.contactedTime)}` : ''
    } on ${reverseDate(data.contactedDate)}`,
    visitScheduled: `The appointment has been booked for ${
      data.scheduledTime ? `${formatISOTime(data.scheduledTime)} on` : ''
    } ${reverseDate(data.scheduledDate)}`,
    visitCompleted: `The appointment was completed ${
      data.completedTime ? `at ${formatISOTime(data.completedTime)}` : ''
    } on ${reverseDate(data.completedDate)}`,
    repairScheduled: `The repairs are scheduled to begin on ${reverseDate(
      data.scheduledDate,
    )}`,
    repairStarted: `The necessary repairs started on ${reverseDate(
      data.startedDate,
    )}`,
    repairCompleted: `The repairs have been completed on ${reverseDate(
      data.completedDate,
    )}`,
    additionalSupplierAppointed: `${additionalData?.name} has been appointed to assist with your claim`,
    reportIssuedToAgeas:
      'Report has been sent to Ageas, which we aim to review within 6 working days',
    itemCollected: `The item needing repair was collected on ${reverseDate(
      data.collectedDate,
    )}`,
    itemReturned: `The repaired item was returned on ${reverseDate(
      data.returnedDate,
    )}`,
    itemReplaced: `The replacement item was sent on ${reverseDate(
      data.providedDate,
    )}`,
  };
  return SUPPLIER_MILESTONE_DESCRIPTIONS[type] || 'Unknown update type';
};

export default getSupplierMilestoneDescription;
