import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const stormDetailTitle = 'What counts as storm damage?';
export const stormDetailContent = (
  <>
    <FaqP>
      Storm damage occurs when a property has been damaged due to an extreme
      weather event, usually including very high winds, extreme rain, hail or
      snow.
    </FaqP>
    <FaqP marginBottom={false}>
      Depending on your policy, your building would be covered if damage
      occurred under the following circumstances:
    </FaqP>
    <StandardUl>
      <li>Where wind speeds are recorded over 55mph</li>
      <li>
        Where rainfall reaches more than 25mm/1 inch of rain within a 1 hour
        period
      </li>
      <li>Where hailstones exceed 20mm/0.8 inches in diameter</li>
      <li>
        Where there are 30cm/12 inches or more of snowfall within a 24 hour
        period
      </li>
    </StandardUl>
    <FaqP>
      We will determine if these conditions are met by checking local weather
      records.
    </FaqP>
  </>
);
