import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  BackButtonContainer,
  NavigationLink,
} from './StandardPageWrapperStyle';

const BackButtonSection = ({ to }) => {
  return (
    <BackButtonContainer>
      <NavigationLink to={to}>
        <FontAwesomeIcon icon={faChevronLeft} /> back to My Claims
      </NavigationLink>
    </BackButtonContainer>
  );
};

export default BackButtonSection;

BackButtonSection.propTypes = {
  to: PropTypes.string.isRequired,
};
