import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ThemedLabel, ThemedErrorMessage } from 'ageas-ui-components';
import { dateRequired } from 'ageasvalidation/lib/schemas/Required';
import { stringNotEqualTo } from 'ageasvalidation/lib/schemas/String';
import { dateLessThanOrEqualTo } from 'ageasvalidation/lib/schemas/Date';

import FieldStyled from '../../FieldStyled/FieldStyled';
import DateDropdownInput from '../../../DateDropdownInput/DateDropdownInput';
import expandArrow from '../../../../assets/expandArrow.svg';
import { date3Years } from '../../../../helpers/errorMessages';

export const DEFAULT_NAME = 'incidentDate';
export const DEFAULT_RANGE_YEARS = 12;

export const run3YearDateValidation = (
  incidentDate,
  lob,
  errorMessage = date3Years,
) => {
  const date3YearsAgo = moment().subtract(3, 'years').format('MM-DD-YYYY');

  if (incidentDate) {
    const splitDate = incidentDate.split('/');
    const date3YearsAgoObj = new Date(date3YearsAgo);
    const incidentDateObj = new Date(
      splitDate[2],
      splitDate[1] - 1,
      splitDate[0],
    );
    if (incidentDateObj.getTime() <= date3YearsAgoObj.getTime()) {
      return errorMessage(lob);
    }
  }
  return undefined;
};

export const getIncidentDateValidationSchema = (fieldName = DEFAULT_NAME) => {
  const dateToday = moment().format('DD/MM/YYYY');

  return {
    [fieldName]: stringNotEqualTo(
      'Incomplete date',
      [fieldName],
      'Please select the date of incident',
    )
      .concat(
        stringNotEqualTo(
          'Invalid date',
          [fieldName],
          'Please select a valid date',
        ),
      )
      .concat(dateRequired([fieldName], 'Please select the date of incident'))
      .concat(
        dateLessThanOrEqualTo(
          dateToday,
          [fieldName],
          'The date of the claim cannot be in the future',
        ),
      ),
  };
};

const LabelStyling = styled.div`
  margin-bottom: -18px;
`;
const Spacer = styled.div`
  margin-top: 10px;
`;

/**
 * Incident Date field
 * @param {*} props
 * @returns JSX
 * @deprecated use StandardDate instead
 */
const IncidentDate = ({
  id,
  name = DEFAULT_NAME,
  label,
  secondaryLabel,
  themedLabel = true,
  validate = () => {},
  alwaysShowError = false,
  children,
  // onChange = () => {},
  fieldProps,
  maxYears = DEFAULT_RANGE_YEARS,
  ...props
}) => {
  const onDateChange = (returnedDate, input) => {
    let dateString;
    if (returnedDate === null) {
      dateString = 'Incomplete date';
      input.onFocus(dateString);
    } else if (Number.isNaN(returnedDate)) {
      dateString = 'Invalid date';
      input.onBlur(dateString);
    } else {
      dateString = moment.utc(returnedDate, 'x', true).format('DD/MM/YYYY');
      input.onBlur(dateString);
    }
    input.onChange(dateString);
  };

  const renderId = `${id || 'incidentDate'}-`;

  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input, meta }) => (
        <>
          {themedLabel && (
            <LabelStyling>
              <ThemedLabel fieldName={id}>
                {label}
                {secondaryLabel && (
                  <Spacer>
                    <small>{secondaryLabel}</small>
                  </Spacer>
                )}
              </ThemedLabel>
            </LabelStyling>
          )}
          <DateDropdownInput
            formInput={input}
            handleDate={value => onDateChange(value, input)}
            aria-label={label}
            inclDay
            dropdownId={renderId}
            dropdownIcon={expandArrow}
            placeholderDay="DD"
            placeholderMonth="MM"
            placeholderYear="YYYY"
            dateDefaultValue={input.value.split('/')[0]}
            monthDefaultValue={input.value.split('/')[1]}
            yearDefaultValue={input.value.split('/')[2]}
            maxYears={maxYears}
            {...props}
          />
          {meta.error && (meta.touched || alwaysShowError) && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

export default IncidentDate;

IncidentDate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  themedLabel: PropTypes.bool,
  validate: PropTypes.func,
  alwaysShowError: PropTypes.bool,
  children: PropTypes.node,
  // onChange: PropTypes.func,
  fieldProps: PropTypes.shape({}),
  maxYears: PropTypes.number,
};

IncidentDate.defaultProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  secondaryLabel: undefined,
  themedLabel: undefined,
  validate: undefined,
  fieldProps: undefined,
  alwaysShowError: undefined,
  children: undefined,
  // onChange: undefined,
  maxYears: undefined,
};
