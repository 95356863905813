import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';
import config from '../../../../helpers/config';

export const ageasApprovedSuppliersTitle =
  'Factors currently affecting suppliers appointed to repair damaged property';
export const ageasApprovedSuppliersContent = (
  <>
    <FaqP>
      You will no doubt be aware from the various media stories that a number of
      factors are affecting businesses this year, including certain suppliers we
      may appoint to validate the damage to your property and make any repairs
      covered under the terms of your policy. They include:
    </FaqP>
    <FaqP>
      <strong>Initial contact from our building inspection teams</strong>
    </FaqP>
    <FaqP>
      The recent storms have led to an unprecedented surge in claims and as a
      result, following our instructions to them, our surveyor inspection teams
      are taking slightly longer to make contact with customers. Currently, they
      will make contact within {config?.client?.text?.stormSurveyorSla} to
      arrange the inspection of damage, with the actual visit being scheduled
      within seven working days where possible.
    </FaqP>
    <FaqP>
      <strong>Delays in obtaining materials and completing repairs</strong>
    </FaqP>
    <FaqP>
      Where a repair to damaged buildings is to be carried out by one of our
      suppliers, there may well be a delay in being able to start and complete
      the repair. There are several reasons causing this at present which
      include:
    </FaqP>
    <StandardUl>
      <li>
        <strong>A shortage of building material availability in the UK</strong>
      </li>
      <FaqP>
        Many materials are imported into the UK and a combination of Brexit, the
        Covid19 pandemic reducing manufacturing output, an increase in demand
        for global and UK infrastructure projects (like HS2 and house building)
        and the Suez Canal blockage have all meant demand is much higher than
        usual and supply much lower. Roofing tiles, timber, plaster and concrete
        are some of the materials impacted by these factors. As a result it is
        taking longer to obtain these and therefore repair timeframes are taking
        longer.
      </FaqP>
      <li>
        <strong>A shortage of labour</strong>
      </li>
      <FaqP>
        A significant proportion of labour for the construction industry has
        traditionally come from Europe and Brexit plus the Covid19 pandemic has
        seen that labour leave the UK. There has also been significant demand
        for labour for UK infrastructure projects such as house building and
        HS2.
      </FaqP>
      <li>
        <strong>Covid19 self-isolation</strong>
      </li>
      <FaqP>
        If a supplier&apos;s contractor/tradesperson has to self-isolate due to
        Covid19 whilst in the process of undertaking repairs at a property,
        whilst every effort will be made to arrange for an alternative
        tradesperson to continue the work and prevent any delays, customers
        should be aware that in most instances this is not possible. A
        contractor will allocate their tradespeople to different customer
        repairs and they will have works planned on different jobs weeks in
        advance. It is often not possible to move them from one job to another.
        As soon as the tradespeople who have had to self-isolate are well enough
        to return to work, contact with a planned date to re-commence repairs
        will be made. However, due to tradespeople having jobs planned weeks in
        advance they may not be able to re-attend the site as soon as their
        period of self-isolation ends.
      </FaqP>
    </StandardUl>
  </>
);
