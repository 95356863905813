const postcodeSplitter = (postcodeIn = '', strict = true) => {
  const postcode = postcodeIn.replace(/[^a-zA-z0-9]/g, '');
  const splitPoint = postcode.length - 3;

  const start = postcode.substring(0, splitPoint)?.trim() || undefined;
  const end = postcode.substring(splitPoint)?.trim() || undefined;

  const out = {};

  if (strict && (!start || !end)) {
    return out;
  }
  const outward = start || end;
  if (outward) {
    out.outward = outward;
  }
  const inward = start ? end : undefined;
  if (inward) {
    out.inward = inward;
  }

  return out;
};

export default postcodeSplitter;
