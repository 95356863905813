const MainThemeDimensions = {
  buttonIconMinWidth: '100px',
  buttonMinWidth: '84px',
  smallButtonWidth: '100px',
  mediumButtonWidth: '122px',
  largeButtonWidth: '152px',
  buttonBorderWidth: '1px',
  buttonLabelFontWeight: '500',
  buttonIconBorderRadius: '4px',
  buttonBorderRadius: '24px',
  buttonIconMarginRight: '5px',
  buttonMarginRight: '20px',
  buttonIconFontWeight: '700',
  buttonFontWeight: '400',
};

export default MainThemeDimensions;
