import React, { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { TitleText } from '../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';
import WeatherResultSet from './WeatherResultSet';
import weatherFunction from '../../helpers/weatherResultDataHelper';
import { StormContext } from '../../helpers/stormContextHelper';
import HailSnowCriteriaMet from './HailSnowCriteriaMet';
import P from '../StandardTags/P';
import NavigateTo from '../Navigation/NavigateTo/NavigateTo';

const WeatherResult = () => {
  const { dataState: weatherDataContext } = useContext(StormContext);
  const weatherDataListContext = { ...weatherDataContext?.weatherData };
  const [navigatePath, setNavigatePath] = useState(null);
  const [renderPage, setRenderPage] = useState(false);

  useEffect(() => {
    if (isEmpty(weatherDataListContext)) {
      setNavigatePath('/homeinsurance');
    } else {
      setRenderPage(true);
    }
  }, [weatherDataListContext]);

  const {
    claimReference = {},
    status = {},
    relevantPolicyData = {},
    weatherNetDecision = {},
  } = weatherDataListContext;
  const { applicableExcess } = relevantPolicyData;
  const { conditionMet } = weatherNetDecision;

  const { referrals = [], declines = [] } = status;
  // Java returns coded value like this so using it exactly
  const isEndorsementRefer = referrals.includes('ExcessEndorsementRefer');
  const isWeatherRefer = referrals.includes('WeatherRefer');
  const isWeatherDecline = declines.includes('NoInsuredPerilInForce');

  if (navigatePath) {
    return <NavigateTo path={navigatePath} />;
  }
  // server side render will not have localStorage. Suppress page content load
  // if context (form localStorage) is unavailable, so initial server side
  // page render does not display incorrect info in the split second before
  // client side updates from localStorage
  if (!renderPage) {
    return null;
  }

  return (
    <>
      <TitleText>Weather condition validation</TitleText>
      <P>
        In order to assess your claim, we check records at official weather
        stations to see if the weather conditions met the criteria detailed in
        your policy within 7 days of the date the damage was noticed.
      </P>
      <P>
        Weather results for {relevantPolicyData.riskPostcode} in 7 days leading
        up to {weatherDataContext?.weatherIncidentDate}.
      </P>
      <WeatherResultSet data={weatherFunction(weatherDataListContext)} />
      <br />
      <HailSnowCriteriaMet
        isEndorsementRefer={isEndorsementRefer}
        isWeatherRefer={isWeatherRefer}
        isWeatherDecline={isWeatherDecline}
        applicableExcess={applicableExcess}
        claimReference={claimReference}
        weatherConditionMet={conditionMet}
      />
      <br />
    </>
  );
};

export default WeatherResult;
