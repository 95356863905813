import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import { brand } from '../../../data/whitelabel.config';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import {
  ExcessNoAcceptText,
  ReferredText,
  PaymentSettlementText,
} from '../ThankYouEClaim/ThankYouEClaimHome';

const Theme = getTheme(brand);

const ThankYouFreezerFood = history => {
  const [resultData, setResultData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setResultData(history?.history?.location?.state);
  }, []);

  const renderResult = () => {
    if (resultData?.isAccept === false) {
      return <ExcessNoAcceptText claimReference={resultData?.isClaimRef} />;
    }

    if (resultData?.isAccept === true && resultData?.isReferredData) {
      return (
        <ReferredText
          claimReference={resultData?.isClaimRef}
          itemDescription="freezer food"
        />
      );
    }
    return (
      <PaymentSettlementText
        claimReference={resultData?.isClaimRef}
        itemDescription="freezer food"
        settlementAmount={resultData?.isSettlementAmount}
      />
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <ClaimBreadcrumbStandard activeItem="thankYou" />
        <PageSectionBare>
          <Subsection>{resultData?.isComplete && renderResult()}</Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default ThankYouFreezerFood;
