import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import EclaimsRoute from '../EclaimsRoute';
import { HomeClaimsHistoryAccidentalLoss } from '../../views/HomeClaimsHistory/HomeClaimsHistory';
import { PersonalLossClaimAccidentalLoss } from '../../views/PersonalLossClaim/PersonalLossClaim';
import { PersonalLossResultAccidentalLoss } from '../../views/PersonalLossResult/PersonalLossResult';
import PersonalLossThankYouPage from '../../views/PersonalLossThankYouPage/PersonalLossThankYouPage';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  AccidentalLossContext,
  localStorageAccidentalLossPropertyName,
} from '../../helpers/personalLossContextHelper';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import { perilRoutes } from './HomeClaimRoutePaths';

const homeClaimsPrivateProps = {
  private: true,
  authTypes: ['otp', 'basic'],
  lobs: 'home',
};

const AccidentalLossClaimRoutes = () => {
  return (
    <DataContextProvider
      Context={AccidentalLossContext}
      localStoragePropertyName={localStorageAccidentalLossPropertyName}
      localStorageExpiryDays={
        config.client.localStorageAccidentalDamageExpiryDays
      }
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path={perilRoutes.AL.history}
          component={HomeClaimsHistoryAccidentalLoss}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.AL.incidentDetails}
          component={PersonalLossClaimAccidentalLoss}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.AL.results}
          component={PersonalLossResultAccidentalLoss}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.AL.thankYou}
          component={PersonalLossThankYouPage}
          {...homeClaimsPrivateProps}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default AccidentalLossClaimRoutes;
