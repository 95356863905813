import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemedErrorMessage } from 'ageas-ui-components';
import FieldSpinner from '../../components/Forms/FieldSpinner/FieldSpinner';
import DropdownRadio from '../../components/Forms/DropdownRadio/DropdownRadio';
import {
  noDataMotor,
  callUsMotor,
  noActivePolicyMotor,
} from '../../helpers/errorMessages';

// We now have the onchange item only firing when one of the parms changes
// SO can we simply always do a request in this instance, and use abort
// to cancel the existing if present?
// No, we need to check keys because of context.

const Heading = styled.div`
  font-weight: normal;
  font-size: ${({ styles }) => styles?.fontSize || '21px'};
  margin-bottom: 12px;
`;

export const OTHER_UNKNOWN_VRN = '**';
const OTHER_UNKNOWN_VRN_TEXT = 'Other/Unknown';

const dropdownProps = {
  containerProps: {
    medium: true,
  },
};

const PolicyVRNSelectField = ({
  dataContextObject,
  dataContextName,
  selectedOption,
  overrideCallUsMessage,
  overrideNoPolicyMessage,
  overrideNoDataMessage,
  title,
  displayMakeModel,
  maxButtons,
  headingStyles,
  ...props
}) => {
  const { dataState: policyDataContext } = useContext(dataContextObject);
  const policyVRNListContext = { ...policyDataContext[dataContextName] };

  const buildOptionsList = () => {
    const radioButtons =
      maxButtons && policyVRNListContext.data.length < maxButtons;
    const optionsList = policyVRNListContext.data.map(vehicle => {
      const makeModel = `${vehicle.make} ${vehicle.model}`.trim();
      return {
        label:
          vehicle.vrn +
          (!radioButtons && displayMakeModel && makeModel
            ? ` (${makeModel})`
            : ''),
        subLabel: radioButtons && displayMakeModel && makeModel && (
          <small>({makeModel})</small>
        ),
        value: vehicle.vrn,
      };
    });
    optionsList.push({
      label: OTHER_UNKNOWN_VRN_TEXT,
      value: OTHER_UNKNOWN_VRN,
      title: displayMakeModel ? OTHER_UNKNOWN_VRN_TEXT : undefined,
    });
    return optionsList;
  };

  const renderFieldErrors = () => {
    if (policyVRNListContext.status === 'error') {
      return (
        <ThemedErrorMessage hasIcon>
          {overrideNoDataMessage()}
        </ThemedErrorMessage>
      );
    }

    if (selectedOption === OTHER_UNKNOWN_VRN) {
      return (
        <ThemedErrorMessage hasIcon>
          {overrideCallUsMessage()}
        </ThemedErrorMessage>
      );
    }

    return null;
  };

  const renderField = () => {
    return (
      <>
        <Heading styles={headingStyles}>{title}</Heading>
        {policyVRNListContext.status !== 'error' && (
          <DropdownRadio
            dropdownProps={dropdownProps}
            {...props}
            selectedOption={selectedOption}
            title={title}
            options={buildOptionsList()}
            maxButtons={maxButtons}
            radioProps={{ subLabel: true }}
          />
        )}
        {renderFieldErrors()}
      </>
    );
  };

  // No status yet or no key provided, return nothing
  if (!policyVRNListContext.status || policyVRNListContext.status === 'nokey') {
    return null;
  }

  // Is loading, display spinner - no title
  if (policyVRNListContext.status === 'loading') {
    return <FieldSpinner />;
  }

  // Render not found error - no header, just error
  if (
    policyVRNListContext.status === 'loaded' &&
    !policyVRNListContext?.data?.length
  ) {
    return (
      <ThemedErrorMessage hasIcon>
        {overrideNoPolicyMessage()}
      </ThemedErrorMessage>
    );
  }

  // Render field itself with errors, or spinner
  return renderField();
};

PolicyVRNSelectField.defaultProps = {
  title: '',
  selectedOption: undefined,
  overrideCallUsMessage: callUsMotor,
  overrideNoPolicyMessage: noActivePolicyMotor,
  overrideNoDataMessage: noDataMotor,
  displayMakeModel: false,
  maxButtons: null,
  headingStyles: undefined,
};

PolicyVRNSelectField.propTypes = {
  title: PropTypes.string,
  selectedOption: PropTypes.string,
  overrideCallUsMessage: PropTypes.func,
  overrideNoPolicyMessage: PropTypes.func,
  overrideNoDataMessage: PropTypes.func,
  dataContextObject: PropTypes.instanceOf(Object).isRequired,
  dataContextName: PropTypes.string.isRequired,
  displayMakeModel: PropTypes.bool,
  maxButtons: PropTypes.number,
  headingStyles: PropTypes.instanceOf(Object),
};

export default PolicyVRNSelectField;
