import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

export const PageOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.loadingSpinnerBackgroundColor};
`;

export const SpinnerIcon = styled(FontAwesomeIcon)`
  margin: 0 auto;
  color: ${props => props.theme.loadingSpinner};
`;

const SpinnerContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  animation: none;
`;

const LoadingSpinnerText = styled.h2`
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  margin: 24px auto 0;
  color: ${props => props.theme.loadingSpinner};
`;

const LoadingSpinner = props => {
  const { textToDisplay, className } = props;
  return (
    <FocusLock group="spinnerGroup">
      <PageOverlay className={className}>
        <SpinnerContainer>
          <SpinnerIcon
            icon={faSpinner}
            data-testid="loading-spinner"
            size="2x"
            spin
          />
          <FocusLock group="spinnerGroup">
            {textToDisplay && (
              <LoadingSpinnerText>{textToDisplay}</LoadingSpinnerText>
            )}
          </FocusLock>
        </SpinnerContainer>
      </PageOverlay>
    </FocusLock>
  );
};

export default LoadingSpinner;

LoadingSpinner.propTypes = {
  textToDisplay: PropTypes.string,
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  textToDisplay: null,
  className: null,
};
