import PropTypes from 'prop-types';
import React from 'react';
import StandardDropdown from '../../../components/Forms/StandardDropdown/StandardDropdown';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';
import {
  COUNTRIES_LIST,
  incidentDetailsFields,
} from '../motorIncidentDetailsHelper';

const defaultHintProps = {
  iconMargin: '0 0 0 4px',
  trueButtons: true,
};

const defaultLabelProps = {
  primary: true,
  hintType: 'inlineFull',
  hintProps: defaultHintProps,
};

const IncidentLocationFields = ({ validateFields }) => {
  return (
    <>
      <StandardInput
        name={incidentDetailsFields.INCIDENT_STREET}
        label="Street"
        aria-label="Incident street or road"
        small
        maxLength="50"
        labelProps={{
          hint: 'Please enter the closest street or road to the incident as possible',
          ...defaultLabelProps,
        }}
        validate={validateFields}
      />
      <StandardInput
        name={incidentDetailsFields.INCIDENT_TOWN}
        label="Town or city"
        aria-label="Incident town or city"
        small
        maxLength="50"
        labelProps={{
          hint: 'Please enter the closest village, town or city to the incident as possible',
          ...defaultLabelProps,
        }}
        validate={validateFields}
      />
      <StandardDropdown
        name={incidentDetailsFields.INCIDENT_COUNTRY}
        label="Country"
        aria-label="Incident country"
        options={COUNTRIES_LIST}
        small
        validate={validateFields}
      />
    </>
  );
};

export default IncidentLocationFields;

IncidentLocationFields.propTypes = {
  validateFields: PropTypes.func,
};

IncidentLocationFields.defaultProps = {
  validateFields: undefined,
};
