import getTPAPaths from '../routes/MotorClaimsTpHubRoutes/motorClaimsTpHubRoutePaths';

const getLineOfBusinessSubfolder = lineOfBusiness => {
  if (lineOfBusiness === 'home') {
    return '/homeclaim';
  }
  if (lineOfBusiness === 'motorTPA') {
    return getTPAPaths().ROOT;
  }
  return '/motorclaim';
};

export const getRootLoginPathByLob = lineOfBusiness => {
  if (lineOfBusiness === 'motorTPA') {
    return getTPAPaths().LOGIN;
  }
  return `${getLineOfBusinessSubfolder(lineOfBusiness)}/brokerlogin`;
};

export const getValidateContactPathByLob = lineOfBusiness => {
  if (lineOfBusiness === 'motorTPA') {
    return getTPAPaths().VALIDATE_CONTACT;
  }
  return `${getRootLoginPathByLob(lineOfBusiness)}/validatecontact`;
};

export const getOtpVerifyContactPathByLob = lineOfBusiness => {
  if (lineOfBusiness === 'motorTPA') {
    return getTPAPaths().OTP_VERIFY;
  }
  return `${getRootLoginPathByLob(lineOfBusiness)}/otpverify`;
};

export const getPolicyDetailsVerifyPathByLob = lineOfBusiness => {
  if (lineOfBusiness === 'motorTPA') {
    return getTPAPaths().POLICY_DETAILS_VERIFY;
  }
  return `${getRootLoginPathByLob(
    lineOfBusiness,
  )}/${lineOfBusiness}policydetailsverify`;
};

export const getLoginPathByLob = (lineOfBusiness, page) => {
  if (page === 'validatecontact') {
    return getValidateContactPathByLob(lineOfBusiness);
  }
  if (page === 'otpverify') {
    return getOtpVerifyContactPathByLob(lineOfBusiness);
  }
  if (page === 'policydetailsverify') {
    return getPolicyDetailsVerifyPathByLob(lineOfBusiness);
  }
  return getRootLoginPathByLob(lineOfBusiness);
};

export const getOTPSuccessDestinationByLob = lineOfBusiness => {
  if (lineOfBusiness === 'home') {
    return '/homeinsurance';
  }
  if (lineOfBusiness === 'motorTPA') {
    return getTPAPaths().ROOT;
  }
  return '/motorclaim/incidentdetails';
};

export default getLineOfBusinessSubfolder;
