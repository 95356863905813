import axiosHelper from '../axios';
import config from '../config';
import { dateTimeConverter } from '../dateTimeConverter';

// keySet must be an array:
// ["incident date dd/mm/yyyy","incident time hh:mm","policy number"]
// cancelToken must be applied to the axios request
// Return falsey to indicate validation failure
// Return string to indicate validation failure weith custom status
// Else return promise, with the data that will be stored to context
const driverListAxios = (keySet, cancelToken) => {
  // validate/convert
  // 3 keys
  if (keySet.length < 3) {
    return false;
  }

  // All truthy values
  if (!keySet.every(key => !!key)) {
    return false;
  }

  // Incident date and time are valid
  const incidentDateTime = dateTimeConverter(keySet[0], keySet[1]);
  if (!incidentDateTime.isValid()) {
    return false;
  }

  // Return axios request promise
  return (
    axiosHelper
      .get(
        `${
          config.client.userpolicydriverlist_endpoint
        }?policyNumber=${encodeURIComponent(
          keySet[2],
        )}&effectivedate=${encodeURIComponent(incidentDateTime.toISOString())}`,
        {
          cancelToken,
        },
      )
      // Our own .then to extract the required data from the larger object
      .then(data => {
        return data?.data?.policyDriverList || [];
      })
  );
};

export default driverListAxios;
