// Create a list of specifiedItems that is valid to be added, by exlcuding any
// already added.

// arrayRecords = array of objects with itemKey
// specifiedItems = array of objects with itemKey
// index - current record's position in arrayRecords

// Filter list of specified items to exclude any entries that are present
// in arrayRecords entries (unless that entry is the current entry defined by
// index)

// If no current entry (e.g. for new record), set index to -1 or undefined

export const filterAllowedSpecifiedItems = (
  arrayRecords = [],
  specifiedItems = [],
  currentArrayRecordsIndex = -1,
) => {
  if (!arrayRecords.length) {
    return specifiedItems;
  }
  return specifiedItems.filter(item => {
    // Loop through every entry in the array
    return arrayRecords.every((arrayRecord, arrayRecordIndex) => {
      // Except current entry, and except those that don't have a specified item
      if (
        arrayRecordIndex !== currentArrayRecordsIndex &&
        arrayRecord.specifiedItemName &&
        arrayRecord.specifiedItemName !== 'other'
      ) {
        // disallow if specified item is already allocated to this entry
        if (item.itemKey === arrayRecord.specifiedItemName) {
          return false;
        }
      }
      return true;
    });
  });
};

export default filterAllowedSpecifiedItems;
