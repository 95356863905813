import React from 'react';
import PropTypes from 'prop-types';

import AccordionSection from './AccordionSection';
import Accordion from './NestedAccordionCore';
import {
  nestingControlPropTypes,
  nestingControlDefault,
} from './accordionNestingProp';

const NestedAccordion = ({
  children,
  nestingControl,
  defaultSelection,
  ...rest
}) => {
  const nestingControlFull = {
    ...nestingControlDefault,
    ...nestingControl,
  };

  if (nestingControlFull.isNested) {
    return (
      <AccordionSection nestingControl={nestingControlFull} {...rest}>
        <Accordion defaultSelection={defaultSelection}>{children}</Accordion>
      </AccordionSection>
    );
  }

  return (
    <Accordion
      nestingControl={nestingControlFull}
      defaultSelection={defaultSelection}
    >
      {children}
    </Accordion>
  );
};

NestedAccordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nestingControl: nestingControlPropTypes,
  defaultSelection: PropTypes.string,
};

NestedAccordion.defaultProps = {
  children: [],
  nestingControl: undefined,
  defaultSelection: undefined,
};

export default NestedAccordion;
