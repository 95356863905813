import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const NoBreak = styled.span`
  display: inline-block;
`;

export const Anchor = styled.a`
  ${({ color }) => css`
    color: ${color};
  `}
`;

const TelephoneLink = ({ number, suffix, ...rest }) => {
  return (
    <NoBreak>
      <Anchor href={`tel:${number}`} {...rest}>
        {number}
      </Anchor>
      {suffix}
    </NoBreak>
  );
};
TelephoneLink.propTypes = {
  number: PropTypes.string.isRequired,
  suffix: PropTypes.string,
};
TelephoneLink.defaultProps = {
  suffix: undefined,
};

export default TelephoneLink;
