import styled, { createGlobalStyle } from 'styled-components';
import media from '../MediaQuery/MediaQuery';

export const BodyStyle = createGlobalStyle`
  body {
    overflow: auto;
  }
  ${media.phone`
    body {
      overflow: hidden;
    }
  `}
`;

export const Header = styled.div`
  display: none;
  ${media.phone`
  position: relative;
  display: block;
  height: 424px;
  margin-top: -90%;
  `}
`;

export const HeaderWrapper = styled.div`
  display: none;
  ${media.phone`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  `}
`;

export const NavWrapper = styled.div`
  display: none;
  ${media.phone`
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  width: Max(220px, 20 %);
  bottom: 0;
  z-index: 1;
  opacity: 1;
  transform: translateX(-100 %);
  background-color: #fff;
  transition: background-color 0.6s ease-in-out 0s;
  display: block;
  min-width: 80%;
  height: fit-content;
  box-shadow: 0px 0px 8px 5px #888888;
  & div {
    padding: 6%;
  }
  `}
`;

/* The container */
export const Container = styled.label`
  display: none;
  ${media.phone`
  min-height: 45px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-20) / var(--unnamed-line-spacing-24)
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0-2);
  text-align: left;
  font: normal normal bold 20px/24px Arial;
  color: #2c2c2c;
  opacity: 1;
  border: 1px solid ${props => (props.checked ? 'lightgray' : '#eee')};
  background: #eee;
  margin-bottom: 2%;
  padding-top: 12px;
  display: block;
  position: relative;
  padding-left: 46px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & input:checked ~ span {
    background-color: #5a8909;
  }
  & input:checked ~ span:after {
    display: block;
  }
  & span:after {
    top: 1px;
    width: 34%;
    height: 14px;
    left: 35%;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  `}
`;

/* Create a custom checkbox */
export const CustomCheckBox = styled.span`
  display: none;
  ${media.phone`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: lightgray;
  margin-top: 12px;
  margin-left: 16px;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  `}
`;
export const ConformationButton = styled.button`
  display: none;
  ${media.phone`
  display: block;
  top: 600px;
  left: 128px;
  width: 100%;
  height: 50px;
  background: var(--ageas-green-6fa313) 0% 0% no-repeat padding-box;
  background: #6fa313 0% 0% no-repeat padding-box;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-20) / var(--unnamed-line-spacing-24)
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0-2);
  color: var(--ageas-uk-bg-1_rgb);
  text-align: center;
  font: normal normal bold 20px/24px Arial;
  color: #fafafa;
  opacity: 1;
  font-size: 18px;
  `}
`;

export const Imagetag = styled.img`
  display: none;
  ${media.phone`
  display: flex;
  width: 35px;
  right: 10px;
  position: absolute;
  margin: 6px;
  top: 8px;
  `}
`;

export const MenuContainer = styled.div`
  display: none;
  ${media.phone`
  display: block;
  min-height: 125px;
  overflow: auto;
  `}
`;

export const BackgroundDiv = styled.div`
  display: none;
  ${media.phone`
  position: relative;
  display: block;
  height: 1200px;
  top: -342px;
  background: white;
  opacity: 0.8;
  z-index: 1;
  `}
`;
