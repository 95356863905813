import defaultHeader from './defaultHeader/Header';
import ageas from './ageas/Header';
import BenefitsBanner from './benefitsBanner/BenefitsBanner';
import rias from './rias/RiasHeader';

export default {
  defaultHeader,
  ageas,
  BenefitsBanner,
  rias,
};
