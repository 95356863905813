import React from 'react';
import PropTypes from 'prop-types';
import { validateField } from '../../helpers/validationHelper';
import LostPropertyNumberField, {
  getLostPropertyValidationSchema,
} from '../LostPropertyNumberField/LostPropertyNumberField';
import TheftCrimeNumberField, {
  getTheftCrimeValidationSchema,
} from '../TheftCrimeNumberField/TheftCrimeNumberField';
import { getErrorMessage } from '../../helpers/errorMessages';
import JewelleryItemFieldArray from '../JewelleryItems/JewelleryItemFieldArray';

export const LOST_PROPERTY_NUMBER_FIELDNAME = 'lossJewelleryPropertyNumber';
export const THEFT_CRIME_NUMBER_FIELDNAME = 'theftJewelleryCrimeNumber';
export const JEWELLERY_ITEMS = 'jewelleryItems';

export const schema = {
  ...getLostPropertyValidationSchema(LOST_PROPERTY_NUMBER_FIELDNAME),
  ...getTheftCrimeValidationSchema(THEFT_CRIME_NUMBER_FIELDNAME),
};

const validateFields = (value, allFields, meta) => {
  let error;
  if (schema[meta.name]) {
    error = validateField(value, meta, schema);
    if (error) {
      return getErrorMessage(error);
    }
  }
  return undefined;
};

const JewelleryItemFields = ({
  validate,
  onItemAdd,
  onItemEdit,
  perilCode,
  specifiedItems = [],
  specifiedItemsStatus,
  onInvalidItemRemove,
}) => {
  return (
    <>
      {perilCode === 'AL' && (
        <LostPropertyNumberField
          name={LOST_PROPERTY_NUMBER_FIELDNAME}
          validate={validate || validateFields}
        />
      )}

      {perilCode === 'TF' && (
        <TheftCrimeNumberField
          name={THEFT_CRIME_NUMBER_FIELDNAME}
          validate={validate || validateFields}
        />
      )}

      <JewelleryItemFieldArray
        name={JEWELLERY_ITEMS}
        label="Please enter the details of your jewellery items (2 items max.)"
        specifiedItems={specifiedItems}
        specifiedItemsStatus={specifiedItemsStatus}
        onAdd={onItemAdd}
        onEdit={onItemEdit}
        addLabel="Add item"
        editLabel="Edit item"
        minRows={1}
        maxRows={2}
        onInvalidItemRemove={onInvalidItemRemove}
      />
    </>
  );
};

export default JewelleryItemFields;

JewelleryItemFields.propTypes = {
  validate: PropTypes.func,
  onItemAdd: PropTypes.func,
  onItemEdit: PropTypes.func,
  perilCode: PropTypes.string,
  specifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  specifiedItemsStatus: PropTypes.string,
  onInvalidItemRemove: PropTypes.func,
};

JewelleryItemFields.defaultProps = {
  validate: undefined,
  onItemAdd: undefined,
  onItemEdit: undefined,
  perilCode: undefined,
  specifiedItems: undefined,
  specifiedItemsStatus: undefined,
  onInvalidItemRemove: undefined,
};
