import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

const validKeys = ['postcode'];

/**
 * @description: validates a UK Postcode (00000-00000-00000 or 000000-0000-000000)
 * @param keys: fieldnames to validate
 * @param message: message to display when validation fails.
 */
export const ukPostcode = (
  keys = validKeys,
  message = messages.INVALID_POSTCODE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex.ukPostcode)
      .required()
      .error(() => message),
  );
};

export default ukPostcode;
