import React from 'react';

export const localStorageFreezerFoodPropertyName = 'freezerFoodClaimFormState';

export const freezerFoodDamageDataContext = {
  policyDetails: 'policyDetails',
  freezerFoodDamageData: 'freezerFoodDamageData',
  amount: 'amount',
  settlementAmount: 'settlementAmount',
  detailsCompleted: 'detailsCompleted',
};

export const FreezerFoodContext = React.createContext({});
