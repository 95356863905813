import { pair as howToMakeAClaim } from './HowToMakeAClaim';
import { pair as notSure } from './NotSure';
import { pair as theClaimsHub } from './TheClaimsHub';
import { pair as whatCanIExpect } from './WhatCanIExpect';
import { pair as whatDoINeed } from './WhatDoINeed';

const questions = [
  howToMakeAClaim,
  whatDoINeed,
  whatCanIExpect,
  notSure,
  theClaimsHub,
];

export default questions;
