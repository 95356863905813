import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { ContentWrapper, Title } from 'ageas-ui-components';
import styled from 'styled-components';
import { getImages, getIcons } from 'ageas-ui-themes';
import media from '../MediaQuery/MediaQuery';
import { HOME_HUB_DOCUMENT_TYPES_BY_CORRESPONDENCE_TYPE } from '../../helpers/documentUploadTypes';
import { brand } from '../../../data/whitelabel.config';

const ImageStore = getImages(brand);
const DocumentsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 600px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 15px;
  ${media.phone`
    font-size: 12px;
  `};
`;

const ListIcon = styled.img`
  width: 24px;
  height: 24px;
  ${media.phone`
    width: 20px;
    height: 20px;
  `}
`;

const ListText = styled.div`
  flex-grow: 1;
`;
const ListRight = styled.div`
  flex-shrink: 0;
  color: #8e419c;
`;

const rollUpDocuments = (documents, documentTypes) => {
  const rolledUp = {};
  documents.forEach(({ correspondenceType }) => {
    if (!rolledUp[correspondenceType]) {
      rolledUp[correspondenceType] = {
        description: documentTypes[correspondenceType]?.description || '',
        count: 1,
      };
    } else {
      rolledUp[correspondenceType].count += 1;
    }
  });
  return rolledUp;
};

const renderDocument = (key, { description, count }) => {
  return (
    <ListItem key={key}>
      <ListIcon src={ImageStore.pdfIcon} alt="documentIcon" />
      <ListText>
        {description}
        {count > 1 ? ` (${count})` : ''}
      </ListText>
      <ListRight>
        <IcomoonReact
          iconSet={getIcons(brand)}
          icon="tick"
          color="#8e419c"
          size={34}
        />
        Received
      </ListRight>
    </ListItem>
  );
};

const HubUploadedDocuments = ({
  documents = [],
  documentTypes = HOME_HUB_DOCUMENT_TYPES_BY_CORRESPONDENCE_TYPE,
}) => {
  return (
    <ContentWrapper>
      <Title color="grey" size="l">
        Uploaded documents
      </Title>
      <DocumentsList>
        {Object.entries(rollUpDocuments(documents, documentTypes)).map(
          ([key, value]) => renderDocument(key, value),
        )}
      </DocumentsList>
    </ContentWrapper>
  );
};

export default HubUploadedDocuments;

HubUploadedDocuments.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.number,
      origin: PropTypes.string,
      correspondenceType: PropTypes.string,
    }),
  ),
  documentTypes: PropTypes.shape({}),
};

HubUploadedDocuments.defaultProps = {
  documents: undefined,
  documentTypes: undefined,
};
