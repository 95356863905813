import { isEmpty } from 'lodash';
import windSpeedIcon from '../assets/icons/weatherIcons/icon_wind.svg';
import TickIcon from '../assets/icons/weatherIcons/ui-icon-tick.svg';
import RainfallIcon from '../assets/icons/weatherIcons/icon_rain.svg';
import HailStonesIcon from '../assets/icons/weatherIcons/icon_hail.svg';
import SnowfallIcon from '../assets/icons/weatherIcons/icon_snow.svg';
import CrossIcon from '../assets/icons/weatherIcons/ui_icon_cross.svg';
import QuestionIcon from '../assets/icons/QuestionMark/QuestionIcon.svg';

const weatherFunction = data => {
  if (isEmpty(data)) {
    return undefined;
  }

  const { conditionMet, result = {} } = data?.weatherNetDecision || {};
  const { wind: windLimit, rain: rainLimit } =
    data?.relevantPolicyData?.weatherLimits || {};

  const { rain = {}, hail = {}, wind = {}, snow = {} } = result;
  const {
    conditionMet: rainConditionMet = false,
    worstRecordedDate: rainWorstRecordedDate,
    worstRecorded: rainWorstRecorded,
  } = rain;
  const {
    conditionMet: windConditionMet = false,
    worstRecordedDate: windWorstRecordedDate,
    worstRecorded: windWorstRecorded,
  } = wind;
  const { conditionMet: hailConditionMet = false } = hail;
  const { conditionMet: snowConditionMet = false } = snow;

  const windDateText = windWorstRecordedDate.split('-').reverse().join('/');
  const rainDateText = rainWorstRecordedDate.split('-').reverse().join('/');

  const tickIconSize = '35px';
  const questionIconSize = '18px';
  const crossIconSize = '25px';

  return [
    {
      name: 'Wind Speed',
      id: 1,
      weatherIcon: `${windSpeedIcon}`,
      description: `Wind Speed must be recorded over ${windLimit}mph`,
      resultIcon: `${
        conditionMet && windConditionMet ? `${TickIcon}` : `${CrossIcon}`
      }`,
      resultIconName: `${
        conditionMet && windConditionMet ? tickIconSize : crossIconSize
      }`,
      date: windDateText,
      speed: windWorstRecorded,
      conditionMetValue: windConditionMet,
    },
    {
      name: 'Rainfall',
      id: 2,
      weatherIcon: `${RainfallIcon}`,
      description: `Must reach more than ${rainLimit}mm/1 inch of rain within a 1 hour period`,
      resultIcon: `${
        conditionMet && rainConditionMet ? `${TickIcon}` : `${CrossIcon}`
      }`,
      resultIconName: `${
        conditionMet && rainConditionMet ? tickIconSize : crossIconSize
      }`,
      date: rainDateText,
      speed: rainWorstRecorded,
      conditionMetValue: rainConditionMet,
    },
    {
      name: 'Hailstones',
      id: 3,
      weatherIcon: `${HailStonesIcon}`,
      description: 'Hailstones must exceed 20mm/0.8 inches in diameter',
      resultIcon: `${
        conditionMet && hailConditionMet ? `${QuestionIcon}` : `${CrossIcon}`
      }`,
      resultIconName: `${
        conditionMet && hailConditionMet ? questionIconSize : crossIconSize
      }`,
    },
    {
      name: 'Snowfall',
      id: 4,
      weatherIcon: `${SnowfallIcon}`,
      description:
        'Where there are 30cm/12 inches or more of snowfall within a 24 hour period',
      resultIcon: `${
        conditionMet && snowConditionMet ? `${QuestionIcon}` : `${CrossIcon}`
      }`,
      resultIconName: `${
        conditionMet && snowConditionMet ? questionIconSize : crossIconSize
      }`,
    },
  ];
};
export default weatherFunction;
