import React from 'react';
import FaqP from '../../FaqP';

export const title = 'What do I need to make an online claim?';
export const content = (
  <>
    <FaqP>
      To get your online claim journey started, all you will need is your policy
      number. This will be shown on your policy schedule.
    </FaqP>
    <FaqP>
      We&apos;ll ask you for key details about your claim, such as: what
      happened, what you&apos;re claiming for, evidence to support your claim
      (you&apos;ll be able to upload this online).
    </FaqP>
  </>
);

export const pair = { title, content };
