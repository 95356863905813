import React from 'react';
import PropTypes from 'prop-types';
import { buildFullName } from '../../../helpers/buildFullName';

import FieldArrayDisplay from '../FieldArrayDisplay/FieldArrayDisplay';

// For documentation see:
// /docs/components/Forms/Person/PersonFieldArray.md

const PersonFieldArray = ({
  name,
  label,
  onAdd,
  onEdit,
  addLabel,
  editLabel,
  showAddButton,
  enableAddButton,
  defaultRows,
}) => {
  return (
    <FieldArrayDisplay
      name={name}
      label={label}
      ariaLabel={!label ? 'Person list' : undefined}
      onAdd={onAdd}
      onEdit={onEdit}
      addLabel={addLabel}
      editLabel={editLabel}
      showAddButton={showAddButton}
      enableAddButton={enableAddButton}
      defaultRows={defaultRows}
      displayMapper={buildFullName}
    />
  );
};

export default PersonFieldArray;

PersonFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  addLabel: PropTypes.string.isRequired,
  editLabel: PropTypes.string.isRequired,
  defaultRows: PropTypes.number,
  showAddButton: PropTypes.bool,
  enableAddButton: PropTypes.bool,
};

PersonFieldArray.defaultProps = {
  label: '',
  onAdd: () => {},
  onEdit: () => {},
  defaultRows: 0,
  showAddButton: true,
  enableAddButton: true,
};
