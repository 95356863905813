import React from 'react';
import PropTypes from 'prop-types';
import { getImages } from 'ageas-ui-themes';
import { UploadDocuments, CustomList } from 'ageas-ui-components';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import documentUploadTypes, {
  documentUploadTypesList,
  FILE_TYPES,
  mapToValueLabel,
} from '../../helpers/documentUploadTypes';

import P from '../StandardTags/P';
import { perilCategoryDocumentUploadErrorHome } from '../../helpers/errorMessages';
import readableJoin from '../../helpers/readableJoin';
import UploaderCategoryCustomField from './UploaderCategoryCustomField';
import { UploadModalHeader } from './DocumentUploaderComponents';

const ImageStore = getImages('ageas');

const UPLOAD_SUCCESS_INFO = (
  <>
    <P>Thank you for providing your documentation.</P>
    <P>
      If you need to upload more than one document click &quot;Add
      another&quot;, otherwise click &quot;Close&quot; when you&apos;ve
      finished.
    </P>
  </>
);

const buildAxiosErrorMessage = (
  claimReference,
  perilDescription,
  _payload,
  data,
) =>
  perilCategoryDocumentUploadErrorHome(
    claimReference,
    perilDescription.toLowerCase(),
    documentUploadTypes[data.category]?.correspondenceType,
    documentUploadTypes[data.category]?.description?.toLowerCase(),
  );

const getUploaderProps = (claimReference, documentTypes, perilDescription) => {
  const BULLET_DESCRIPTION = documentTypes.map(
    type => type.bulletDescription || type.description,
  );
  const SENTENCE_DESCRIPTION = readableJoin(
    documentTypes.map(type => type?.sentenceDescription || type?.description),
  ).toLowerCase();

  const UPLOAD_INSTRUCTIONS = (
    <>
      <P>We can accept the following:</P>
      <CustomList
        items={BULLET_DESCRIPTION}
        bulletIcon={ImageStore.greenTick}
      />
    </>
  );

  return {
    // Dynamic:
    payloadKeys: { claimReference },
    uploadFailError: (...props) =>
      buildAxiosErrorMessage(claimReference, perilDescription, ...props),
    uploadModalHeader: (
      <UploadModalHeader>
        This is where you can upload your {SENTENCE_DESCRIPTION}.
      </UploadModalHeader>
    ),
    instructionsSideSection: UPLOAD_INSTRUCTIONS,
    CustomUploadFields: () => (
      <UploaderCategoryCustomField
        documentTypesList={documentTypes.map(mapToValueLabel)}
      />
    ),

    // Static:
    axiosPost: axiosHelper.post,
    axiosPostURL: config.client.uploadDocument_endpoint,
    acceptFileTypesList: FILE_TYPES,
    fileSizeLimit: 7,
    brand: 'ageas',
    header: 'Upload a document',
    instructionsTopSection:
      'This is where you can provide us with documentation in support of your claim.',
    footer: (
      <P>
        We can accept original copies, photos or screenshots (as long as we can
        see the whole document).
      </P>
    ),
    uploadedDocumentsHeader: 'Uploaded documents',
    uploadedDocumentsInfo: 'Below are the documents you have uploaded to us.',
    successfulUploadHeader:
      'Upload successful, we have received your documents',
    successfulUploadInfo: UPLOAD_SUCCESS_INFO,
    customUploadFieldsDataFormatter: ({ category }) => {
      return { category: documentUploadTypes[category].category };
    },
    modalWrapperProps: {
      upload: {
        role: 'dialog',
        'aria-label': 'Upload a document',
      },
      another: {
        role: 'dialog',
        'aria-label': 'Upload another document',
      },
      close: {
        role: 'dialog',
        'aria-label': 'Confirm close document upload',
      },
    },
  };
};

const PerilCategoryUploader = ({
  claimReference,
  documentTypes = documentUploadTypesList,
  perilDescription,
  ...rest
}) => {
  return (
    <UploadDocuments
      {...getUploaderProps(claimReference, documentTypes, perilDescription)}
      {...rest}
    />
  );
};

export default PerilCategoryUploader;

PerilCategoryUploader.propTypes = {
  claimReference: PropTypes.string,
  perilDescription: PropTypes.string,
  documentTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

PerilCategoryUploader.defaultProps = {
  claimReference: undefined,
  perilDescription: undefined,
  documentTypes: undefined,
};
