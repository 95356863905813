/* eslint-disable no-param-reassign */
import Joi from '@hapi/joi';

import { subMotorClaim } from '../extensions/ValidMotorClaimsType';

const JoiExt = Joi.extend(subMotorClaim);

/**
 * @description: Validate the field is a valid sub-claim
 * @param keys Fields to validate
 * @param claimRef reference to main claim field
 * @param message the message to display when sub-claim is not selected
 * @succeeds when the sub-claim type dropdown is selected
 * @fails when sub-claim type dropdown is not selected
 */
export const subMotorClaimType = (keys, motorClaimType, message) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExt.string()
      .required()
      .subMotorClaim(motorClaimType)
      .error(err => {
        // based on error type will return the error message
        if (err && err[0] && !err[1] && err[0].type === 'any.empty') {
          return message[0];
        }
        if (err && err[1]) {
          const errType = err[1].type;
          switch (errType) {
            case 'string.theftClaim':
              return message[1];
            case 'string.fireClaim':
              return message[2];
            case 'string.multipleVehicleClaim':
              return message[3];
            case 'string.singleVehicleClaim':
              return message[4];
            case 'string.stormClaim':
              return message[5];
            case 'string.vehicleRecoveredClaim':
              return message[6];
            case 'string.vehicleNotrecoveredClaim':
              return message[7];
            case 'string.vandalClaim':
              return message[8];
            case 'string.windscreenClaim':
              return message[9];
            default:
              break;
          }
        }
        return message[0];
      }),
  );
};

export default subMotorClaimType;
