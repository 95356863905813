/* eslint-disable react/forbid-prop-types */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const SpyOnValues = ({ enable = true, values, callback, otherData }) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const otherDataRef = useRef(otherData);
  otherDataRef.current = otherData;
  const enableRef = useRef(false);
  enableRef.current = enable;

  useEffect(() => {
    if (enableRef.current) {
      callbackRef.current(values, otherDataRef.current);
    }
  }, ['default', ...values]);

  return null;
};

export default SpyOnValues;

SpyOnValues.propTypes = {
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  enable: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  otherData: PropTypes.any,
};

SpyOnValues.defaultProps = {
  enable: undefined,
  otherData: undefined,
};
