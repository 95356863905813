import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  DesktopBuilding,
  HouseDesktopImage,
  HoverButton,
  ButtonIcon,
  ButtonTextContainer,
  ContainerIcon,
  HoverButtonContainer,
  HoverButtonContentContainer,
  ImageButtonWrapper,
} from './BuildingGardenDesktopStyle';
import HouseImages from '../../assets/damage-selector_desktop.svg';

const buttonListList = [
  {
    top: '12%',
    left: '28%',
    absolute: true,
    buttonIds: ['chimney'],
    listName: 'chimney',
    id: 'chimney',
  },
  {
    top: '19%',
    left: '59%',
    absolute: true,
    buttonIds: ['roofTiles', 'guttering', 'flashing'],
    listName: 'house roof',
    id: 'houseRoof',
  },
  {
    top: '50%',
    left: '42%',
    absolute: true,
    buttonIds: ['rendering', 'pebbleDash'],
    listName: 'wall',
    id: 'wall',
  },
  {
    top: '62%',
    left: '4%',
    absolute: true,
    buttonIds: ['flatRoof'],
    listName: 'flat roof',
    id: 'flatRoof',
  },
  {
    top: '62%',
    left: '67%',
    absolute: true,
    buttonIds: ['conservatoryRoof'],
    listName: 'conservatory roof',
    id: 'conservatoryRoof',
  },
  {
    top: '71%',
    left: '83%',
    absolute: true,
    buttonIds: ['outbuildings'],
    listName: 'outbuildings',
    id: 'outbuildings',
  },
  {
    top: '92%',
    left: '73%',
    absolute: true,
    buttonIds: ['gardenWalls'],
    listName: 'garden walls',
    id: 'gardenWalls',
  },
];

// Render a single button
const renderButton = (
  button,
  isChecked,
  toggleChecked,
  enableClick = false,
) => {
  const onClick = event => {
    if (enableClick) {
      toggleChecked(button.id);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onKeyDown = event => {
    if (
      enableClick &&
      (event.key === 'Enter' ||
        event.key === ' ' ||
        event.which === 13 || // enter (fallback)
        event.which === 32) // space (fallback)
    ) {
      toggleChecked(button.id);
      event.preventDefault();
      event.stopPropagation();
    }
  };
  return (
    <HoverButton
      key={button.id}
      isChecked={isChecked}
      disabled={!enableClick}
      type="button"
      data-testid={`${button.name}damage`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-label={(isChecked ? 'Remove ' : 'Add ') + button.name}
    >
      <ButtonIcon />
      <ButtonTextContainer>{button.name}</ButtonTextContainer>
    </HoverButton>
  );
};

// Render a list of buttons
const RenderButtonList = ({
  top,
  left,
  buttonIds,
  toggleChecked,
  data,
  selectedItems = [],
  absolute = true,
  setOpen,
  listName,
  open,
  zIndex,
}) => {
  const timerId = useRef(undefined);
  const [delayOpen, setDelayOpen] = useState(false);
  const filteredButtons = data?.filter(button =>
    buttonIds.some(id => id === button.id),
  );

  // delayOpen state variable = open, but is set to true after a short delay
  // On touchscreens, click and mouseenter fire at once, and click can end up
  // activating first button in list instead. So activate list buttons after
  // a delay to prevent this
  useEffect(() => {
    if (timerId.current) {
      window.clearTimeout(timerId.current);
    }
    if (open) {
      timerId.current = window.setTimeout(() => {
        setDelayOpen(true);
      }, 100);
    } else {
      setDelayOpen(false);
    }
  }, [open]);

  // Enter/Spacebar key handler - open list on focus of menu icon & enter/space
  const onKeyDown = event => {
    if (
      !open &&
      (event.key === 'Enter' ||
        event.key === ' ' ||
        event.which === 13 || // enter (fallback)
        event.which === 32) // space (fallback)
    ) {
      setOpen(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  // Render list if length > 0
  if (filteredButtons?.length) {
    let checkedButtons = 0;
    const renderedButtons = filteredButtons?.map(button => {
      const isChecked = selectedItems.some(item => item === button.id);
      if (isChecked) {
        checkedButtons += 1;
      }
      return renderButton(button, isChecked, toggleChecked, delayOpen);
    });
    let checkedType = 'none';
    if (checkedButtons === renderedButtons.length) {
      checkedType = 'full';
    }
    if (checkedButtons < filteredButtons.length && checkedButtons > 0) {
      checkedType = 'partial';
    }

    return (
      <HoverButtonContainer
        left={left}
        top={top}
        checkedType={checkedType}
        data-testid={`${filteredButtons?.length}multiple`}
        absolute={absolute}
        zIndex={zIndex}
        open={open}
        onClick={event => {
          // Prevent click on menu propagating to below menu (which can close
          // menu)
          event.preventDefault();
          event.stopPropagation();
        }}
        onMouseOver={event => {
          setOpen(true);
          event.preventDefault();
          event.stopPropagation();
        }}
        onMouseLeave={event => {
          setOpen(false);
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ContainerIcon
          tabIndex={!open ? '0' : undefined}
          onKeyDown={onKeyDown}
          onClick={event => {
            if (!open) {
              setOpen(true);
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          aria-label={`Show ${listName} options`}
          role="button"
        />
        <HoverButtonContentContainer>
          {renderedButtons}
        </HoverButtonContentContainer>
      </HoverButtonContainer>
    );
  }
  return null;
};

const renderButtonLists = (
  data,
  selectedItems,
  toggleChecked,
  openList,
  setListStatus,
) => {
  // z-index applied in reverse order, so later buttons appear below earlier
  // ones (applied as css z-index in styled component)
  let nextZIndex = buttonListList.length;
  return buttonListList.map(({ id, ...rest }) => {
    const zIndex = nextZIndex;
    nextZIndex -= 1;
    return (
      <RenderButtonList
        key={id}
        toggleChecked={toggleChecked}
        data={data}
        selectedItems={selectedItems}
        open={openList === id}
        setOpen={value => setListStatus(id, value)}
        zIndex={zIndex}
        {...rest}
      />
    );
  });
};

const BuildingGardenDesktop = ({
  damageItem,
  toggleChecked,
  selectedItems,
}) => {
  return (
    <BuildingGardenDesktopItems
      data={damageItem}
      toggleChecked={toggleChecked}
      selectedItems={selectedItems}
    />
  );
};

const BuildingGardenDesktopItems = ({ data, toggleChecked, selectedItems }) => {
  const [openList, setOpenList] = useState(undefined);

  const setListStatus = (listId, value) => {
    setOpenList(listId && value ? listId : undefined);
  };
  // Close any open menu if background clicked
  const backgroundClick = () => {
    if (openList) {
      setOpenList(undefined);
    }
  };

  return (
    <DesktopBuilding onClick={backgroundClick}>
      <ImageButtonWrapper>
        <HouseDesktopImage
          src={HouseImages}
          alt="House image"
          aria-label="House image"
        />
        {renderButtonLists(
          data,
          selectedItems,
          toggleChecked,
          openList,
          setListStatus,
        )}
      </ImageButtonWrapper>
    </DesktopBuilding>
  );
};

export default BuildingGardenDesktop;

const dataArray = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
);

BuildingGardenDesktop.propTypes = {
  damageItem: dataArray,
  toggleChecked: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

BuildingGardenDesktop.defaultProps = {
  damageItem: undefined,
  toggleChecked: undefined,
  selectedItems: undefined,
};

BuildingGardenDesktopItems.propTypes = {
  data: dataArray,
  toggleChecked: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

BuildingGardenDesktopItems.defaultProps = {
  data: undefined,
  toggleChecked: undefined,
  selectedItems: undefined,
};

RenderButtonList.propTypes = {
  top: PropTypes.string,
  left: PropTypes.string,
  buttonIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  toggleChecked: PropTypes.func,
  data: dataArray,
  absolute: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool,
  listName: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  zIndex: PropTypes.number,
};
RenderButtonList.defaultProps = {
  top: undefined,
  left: undefined,
  toggleChecked: undefined,
  data: [],
  absolute: false,
  open: false,
  selectedItems: undefined,
  zIndex: undefined,
};
