import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemedErrorMessage } from 'ageas-ui-components';
import { DAMAGE_LEVELS } from './damageValues';
import VehicleDamageButtonGrid from './VehicleDamageButtonGrid';
import { FIELD_NAME } from './VehicleDamageModalForm';
import VehicleDamageResultsDisplay from './VehicleDamageResultsDisplay';
import media from '../../MediaQuery/MediaQuery';
import FieldStyled from '../FieldStyled/FieldStyled';

const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: start;
  justify-content: flex-start;
  ${media.tablet`
    flex-direction: column;
  `}
  & > * {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
  }
`;

const VehicleDamageSelectorGridInner = ({
  input,
  meta,
  Context,
  displayResults = true,
  containerLabel,
}) => {
  const { onAdd, hasBeenTouched } = useContext(Context);
  const refs = useRef();
  refs.current = { input, meta };

  const values = input.value || {};

  const onAreaDamageChange = (damageArea, damage) => {
    const newValue = { ...refs.current.input.value };
    if (!damage || damage === DAMAGE_LEVELS.NONE.name) {
      delete newValue[damageArea.name];
    } else {
      newValue[damageArea.name] = damage;
    }
    refs.current.input.onFocus();
    refs.current.input.onChange(newValue);
    refs.current.input.onBlur();
  };

  const openModal = damageArea => {
    const update = newValues =>
      onAreaDamageChange(damageArea, newValues.damage);
    const initialValues = {
      [FIELD_NAME]: refs.current.input?.value[damageArea.name],
    };
    onAdd(update, `Damage to ${damageArea.label}`, initialValues);
  };

  return (
    <>
      <Container role="region" aria-label={containerLabel}>
        <VehicleDamageButtonGrid values={values} onButtonClick={openModal} />

        {displayResults && (
          <VehicleDamageResultsDisplay values={values} marginBottom="none" />
        )}
      </Container>

      {meta.error && (meta.touched || hasBeenTouched) && (
        <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
      )}
    </>
  );
};

VehicleDamageSelectorGridInner.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  Context: PropTypes.instanceOf(Object).isRequired,
  displayResults: PropTypes.bool,
  containerLabel: PropTypes.string,
};
VehicleDamageSelectorGridInner.defaultProps = {
  displayResults: undefined,
  containerLabel: undefined,
};

const VehicleDamageSelectorGridInnerBool = ({
  input,
  meta,
  displayResults = true,
  containerLabel,
}) => {
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const refs = useRef();
  refs.current = { input, meta };

  const values = input.value || [];

  const onButtonClick = damageArea => {
    setHasBeenTouched(true);
    let newValue = [...refs.current.input.value];
    // Remove if present
    if (newValue.includes(damageArea.name)) {
      newValue = newValue.filter(area => area !== damageArea.name);
    }
    // else add
    else {
      newValue.push(damageArea.name);
    }

    refs.current.input.onFocus();
    refs.current.input.onChange(newValue);
    refs.current.input.onBlur();
  };

  return (
    <>
      <Container role="region" aria-label={containerLabel}>
        <VehicleDamageButtonGrid
          values={values}
          onButtonClick={onButtonClick}
          booleanMode
        />

        {displayResults && (
          <VehicleDamageResultsDisplay
            values={values}
            marginBottom="none"
            booleanMode
          />
        )}
      </Container>

      {meta.error && (meta.touched || hasBeenTouched) && (
        <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
      )}
    </>
  );
};

VehicleDamageSelectorGridInnerBool.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  displayResults: PropTypes.bool,
  containerLabel: PropTypes.string,
};
VehicleDamageSelectorGridInnerBool.defaultProps = {
  displayResults: undefined,
  containerLabel: undefined,
};

const VehicleDamageSelectorGrid = ({
  name,
  Context,
  validate,
  containerLabel,
  ...rest
}) => {
  return (
    <FieldStyled name={name} validate={validate}>
      {({ input, meta }) =>
        Context ? (
          <VehicleDamageSelectorGridInner
            input={input}
            meta={meta}
            Context={Context}
            containerLabel={containerLabel}
            {...rest}
          />
        ) : (
          <VehicleDamageSelectorGridInnerBool
            input={input}
            meta={meta}
            containerLabel={containerLabel}
            {...rest}
          />
        )
      }
    </FieldStyled>
  );
};

export default VehicleDamageSelectorGrid;

VehicleDamageSelectorGrid.propTypes = {
  name: PropTypes.string.isRequired,
  Context: PropTypes.instanceOf(Object),
  validate: PropTypes.func,
  containerLabel: PropTypes.string,
};
VehicleDamageSelectorGrid.defaultProps = {
  Context: undefined,
  validate: undefined,
  containerLabel: undefined,
};
