import Joi from '@hapi/joi';

const arrayMinSize = (fieldName, minimumSize, message) => {
  // return undefined;
  return Joi.object().pattern(
    Joi.valid(fieldName),
    Joi.array()
      .min(minimumSize)
      .error(function buildErrorMessage() {
        return (
          message || `${fieldName} array size less than minimum ${minimumSize}`
        );
      }),
  );
};

export default arrayMinSize;
