import { jewelleryItemTypeToText } from './jewelleryItemTypes';

export const getSpecifiedItemByCode = (specifiedItemName, specifiedItems) => {
  return specifiedItems.find(
    specificItem => specificItem.itemKey === specifiedItemName,
  );
};

export const buildSpecifiedItemDescription = specifiedItem => {
  return `${specifiedItem.description} £${specifiedItem.insuredValue.toFixed(
    2,
  )}`.trim();
};

export const getSpecifiedItemDescription = (
  specifiedItemName,
  specifiedItems,
) => {
  const specifiedItem = getSpecifiedItemByCode(
    specifiedItemName,
    specifiedItems,
  );
  return specifiedItem
    ? buildSpecifiedItemDescription(specifiedItem)
    : undefined;
};

export const buildJewelleryItemName = (
  { itemName, specifiedItemName, itemCost },
  index,
  specifiedItems,
) => {
  // Specified item name
  if (
    specifiedItems?.length &&
    specifiedItemName &&
    specifiedItemName !== 'other'
  ) {
    const specifiedItemDescription = getSpecifiedItemDescription(
      specifiedItemName,
      specifiedItems,
    );
    if (specifiedItemDescription) {
      return specifiedItemDescription;
    }
  }

  // Unspecified item name
  let name = jewelleryItemTypeToText(itemName)?.trim() || `Unknown #${index}`;
  if (itemCost) {
    name += ` £${parseFloat(itemCost).toFixed(2)}`;
  }
  return name;
};

export default buildJewelleryItemName;
