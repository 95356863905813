import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import { BrokerContext } from '../../helpers/brokerContextHelper';
import {
  OTPVerificationHome,
  PolicyDetailsVerificationHome,
} from '../../views/NonDirectAuthentication/OTPVerification/OTPVerification';
import { BrokerLoginHome } from '../../views/NonDirectAuthentication/BrokerLogin/BrokerLogin';
import { MobileEmailAuthenticationHome } from '../../views/NonDirectAuthentication/MobileEmailAuthentication/MobileEmailAuthentication';
import EclaimsRoute from '../EclaimsRoute';
import HomeStormClaimRoutes from './StormClaimRoutes';
import AccidentalDamageClaimRoutes from './AccidentalDamageClaimRoutes';
import AccidentalLossClaimRoutes from './AccidentalLossClaimRoutes';
import TheftClaimRoutes from './TheftClaimRoutes';
import FreezerFoodClaimRoutes from './FreezerFoodClaimRoutes';
import ChatBot from '../../components/ChatBot/ChatBot';
import { perilBaseRoutes } from './HomeClaimRoutePaths';
import EscapeOfWaterClaimRoutes from './EscapeOfWaterClaimRoutes';

// TODO remove this stub once nothing is using /homeclaim/testpage
/* istanbul ignore next */
const TestPage = () => {
  return <h1>Page need to be build</h1>;
};

const BrokerLoginRoutes = () => {
  return (
    <DataContextProvider Context={BrokerContext}>
      <Switch>
        <Route
          exact
          path="/homeclaim/brokerlogin"
          component={BrokerLoginHome}
        />
        <Route
          exact
          path="/homeclaim/brokerlogin/validatecontact"
          component={MobileEmailAuthenticationHome}
        />
        <Route
          exact
          path="/homeclaim/brokerlogin/otpverify"
          component={OTPVerificationHome}
        />
        <Route
          exact
          path="/homeclaim/brokerlogin/homepolicydetailsverify"
          component={PolicyDetailsVerificationHome}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

const HomeClaimRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/homeclaim/testpage" component={TestPage} />
        <Route path="/homeclaim/brokerlogin" component={BrokerLoginRoutes} />
        <EclaimsRoute
          path="/homeclaim/storm/"
          component={HomeStormClaimRoutes}
          featureKey="FEATURE_HOME_CLAIM_STORM"
          acceptableValue={['version1']}
        />
        <EclaimsRoute
          path={perilBaseRoutes.AD}
          component={AccidentalDamageClaimRoutes}
          featureKey="FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE"
          acceptableValue={['version1']}
        />
        <EclaimsRoute
          path={perilBaseRoutes.AL}
          component={AccidentalLossClaimRoutes}
          featureKey="FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS"
          acceptableValue={['version1']}
        />
        <EclaimsRoute
          path={perilBaseRoutes.TF}
          component={TheftClaimRoutes}
          featureKey="FEATURE_HOME_CLAIM_THEFT"
          acceptableValue={['version1']}
        />
        <EclaimsRoute
          path={perilBaseRoutes.FF}
          component={FreezerFoodClaimRoutes}
          featureKey="FEATURE_HOME_CLAIM_FREEZERFOOD_DAMAGE"
          acceptableValue={['version1']}
        />
        <EclaimsRoute
          path={perilBaseRoutes.EW}
          component={EscapeOfWaterClaimRoutes}
          featureKey="FEATURE_HOME_CLAIM_ESCAPE_OF_WATER"
          acceptableValue={['version1']}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
      <ChatBot />
    </>
  );
};
export default HomeClaimRoutes;
