import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import EclaimsRoute from '../EclaimsRoute';
import { HomeClaimsHistoryTheft } from '../../views/HomeClaimsHistory/HomeClaimsHistory';
import { PersonalLossClaimTheft } from '../../views/PersonalLossClaim/PersonalLossClaim';
import { PersonalLossResultTheft } from '../../views/PersonalLossResult/PersonalLossResult';
import PersonalLossThankYouPage from '../../views/PersonalLossThankYouPage/PersonalLossThankYouPage';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  TheftContext,
  localStorageTheftPropertyName,
} from '../../helpers/personalLossContextHelper';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import { perilRoutes } from './HomeClaimRoutePaths';

const homeClaimsPrivateProps = {
  private: true,
  authTypes: ['otp', 'basic'],
  lobs: 'home',
};

const TheftClaimRoutes = () => {
  return (
    <DataContextProvider
      Context={TheftContext}
      localStoragePropertyName={localStorageTheftPropertyName}
      localStorageExpiryDays={
        config.client.localStorageAccidentalDamageExpiryDays
      }
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path={perilRoutes.TF.history}
          component={HomeClaimsHistoryTheft}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.TF.incidentDetails}
          component={PersonalLossClaimTheft}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.TF.results}
          component={PersonalLossResultTheft}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.TF.thankYou}
          component={PersonalLossThankYouPage}
          {...homeClaimsPrivateProps}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default TheftClaimRoutes;
