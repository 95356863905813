import React from 'react';
import FaqP from '../../FaqP';

export const inhabitableDamageQuestion =
  "What if I can't live at home due to the damage?";
export const inhabitableDamageContent = (
  <FaqP>
    We understand that people like to stay in their own homes where possible, so
    we can provide facilities like temporary kitchens to enable you to do so. If
    you do have to leave the property, we will help you make those arrangements,
    whether this is staying with relatives or checking in to alternative
    accommodation subject to the limits of your policy cover.
  </FaqP>
);
