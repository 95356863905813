import React from 'react';
import { getImages } from 'ageas-ui-themes';
import { CustomList } from 'ageas-ui-components';
import P from '../StandardTags/P';

import { receiptUploadErrorHome } from '../../helpers/errorMessages';
import { UploadModalHeader } from '../DocumentUploader/DocumentUploaderComponents';

const ImageStore = getImages('ageas');

const RECEIPT_UPLOAD_INSTRUCTIONS = (
  <>
    <P>We can accept the following:</P>
    <CustomList
      items={['Invoices', 'Estimates', 'Receipts']}
      bulletIcon={ImageStore.greenTick}
    />
  </>
);

const RECEIPT_UPLOAD_SUCCESS_INFO = (
  <>
    <P>Thank you for providing your proof.</P>
    <P>
      If you need to upload more than one document click &quot;Add
      another&quot;, otherwise click &quot;Close&quot; when you&apos;ve
      finished.
    </P>
  </>
);

/* eslint-disable import/prefer-default-export */
export const getReceiptUploaderText = (claimReference, perilDescription) => {
  return {
    header: 'Upload a document',
    instructionsTopSection:
      "Please click 'Add document' to upload a copy of the receipt/invoice showing the amount you paid for the repairs.",
    instructionsSideSection: RECEIPT_UPLOAD_INSTRUCTIONS,
    footer: (
      <P>
        We can accept original copies, photos or screenshots (as long as we can
        see the whole document).
      </P>
    ),
    uploadModalHeader: (
      <UploadModalHeader>
        This is where you can upload proof of your invoices, estimates or
        receipts.
      </UploadModalHeader>
    ),
    uploadedDocumentsHeader: 'Uploaded documents',
    uploadedDocumentsInfo: 'Below are the documents you have uploaded to us.',
    successfulUploadHeader:
      'Upload successful, we have received your documents',
    successfulUploadInfo: RECEIPT_UPLOAD_SUCCESS_INFO,
    uploadFailError: receiptUploadErrorHome(claimReference, perilDescription),
  };
};
