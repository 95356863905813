import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import media from '../MediaQuery/MediaQuery';

const ButtonBody = styled(Link)`
  border-radius: 40px;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  line-height: 15px;
  padding: 15px 25px;
  outline: 0;

  transition:
    background 0.2s linear,
    transform 0.2s linear;

  color: #000000;
  text-decoration: none;
  background-color: rgb(239, 239, 239);

  text-align: center;
  font-weight: 400;
  font-family: Arial;
  border-width: 2px;
  border-style: outset;
  border-left-color: rgb(133, 133, 133);
  border-top-color: rgb(133, 133, 133);
  border-right-color: rgb(118, 118, 118);
  border-bottom-color: rgb(118, 118, 118);

  ${media.phone`
    min-width: 100%;
    ${props =>
      props.theme &&
      css`
        margin-bottom: 1em;
      `}
  `}

  /* Variants */
  ${props =>
    props.formatting.primary &&
    !props.formatting.inverted &&
    css`
      background-color: ${props.theme.button.primary.background};
      color: ${props.theme.button.primary.text};
      border: 2px solid ${props.theme.button.primary.borderColor};

      &:hover {
        background-color: ${props.theme.button.primary.hover.background};
        border-color: ${props.theme.button.primary.hover.borderColor};
      }
    `}

  ${props =>
    props.formatting.primary &&
    props.formatting.inverted &&
    css`
      background-color: ${props.theme.button.primary.inverted.background};
      color: ${props.theme.button.primary.inverted.text};
      border: 2px solid ${props.theme.button.primary.borderColor};

      &:hover {
        background-color: ${props.theme.button.primary.inverted.hover
          .background};
        color: ${props.theme.button.primary.inverted.hover.text};
        border-color: ${props.theme.button.primary.inverted.hover.borderColor};
      }
    `}

  ${props =>
    props.formatting.secondary &&
    !props.formatting.inverted &&
    css`
      background-color: ${props.theme.button.secondary.background};
      color: ${props.theme.button.secondary.text};
      border: 2px solid ${props.theme.button.secondary.borderColor};

      &:focus {
        background-color: ${props.theme.button.secondary.hover.background};
        border-color: ${props.theme.button.secondary.hover.borderColor};
      }

      &:hover {
        background-color: ${props.theme.button.secondary.hover.background};
        border-color: ${props.theme.button.secondary.hover.borderColor};
      }
    `}

  ${props =>
    props.formatting.secondary &&
    props.formatting.inverted &&
    css`
      background-color: ${props.theme.button.secondary.inverted.background};
      color: ${props.theme.button.secondary.inverted.text};
      border: 2px solid ${props.theme.button.secondary.borderColor};

      &:hover {
        background-color: ${props.theme.button.secondary.inverted.hover
          .background};
        color: ${props.theme.button.secondary.inverted.hover.text};
        border-color: ${props.theme.button.secondary.inverted.hover
          .borderColor};
      }
    `}

  ${props =>
    props.formatting.tertiary &&
    !props.formatting.inverted &&
    css`
      background-color: ${props.theme.button.tertiary.background};
      color: ${props.theme.button.tertiary.text};
      border: 2px solid ${props.theme.button.tertiary.borderColor};

      &:hover {
        background-color: ${props.theme.button.tertiary.hover.background};
        border-color: ${props.theme.button.tertiary.hover.borderColor};
      }
    `}

  ${props =>
    props.formatting.tertiary &&
    props.formatting.inverted &&
    css`
      background-color: ${props.theme.button.tertiary.inverted.background};
      color: ${props.theme.button.tertiary.inverted.text};
      border: 2px solid ${props.theme.button.tertiary.borderColor};

      &:hover {
        background-color: ${props.theme.button.tertiary.inverted.hover
          .background};
        color: ${props.theme.button.tertiary.inverted.hover.text};
        border-color: ${props.theme.button.tertiary.inverted.hover.borderColor};
      }
    `}

  /* Removed for parity with ThemedButton (where plain does not work) */
  ${props =>
    props.formatting.plain &&
    css`
      //background-color: ${props.theme.button.primary.inverted.background};
      //color: ${props.theme.button.primary.inverted.text};
      //border: 1px solid ${props.theme.button.primary.border};
    `}



    /* Sizes */
  ${props =>
    props.formatting.xsmall &&
    css`
      min-width: 80px;
    `}
  ${props =>
    props.formatting.small &&
    css`
      min-width: 128px;
    `}
  ${props =>
    props.formatting.medium &&
    css`
      min-width: 176px;
    `};
  ${props =>
    props.formatting.large &&
    css`
      min-width: 256px;
    `};
  ${props =>
    props.formatting.xlarge &&
    css`
      min-width: 100%;
    `};

  /* Modifiers */
  ${props =>
    props.formatting.squared &&
    css`
      border-radius: 5px;
    `}

  ${props =>
    props.formatting.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.65;
    `}
`;

export default ButtonBody;
