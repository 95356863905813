import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getTheme } from 'ageas-ui-themes';
import { ThemeProvider } from 'styled-components';
import { brand } from '../../../data/whitelabel.config';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import {
  EOWReferAndInstructSupplierText,
  ExcessNoAcceptText,
  InstructSupplierText,
  ReferredText,
} from '../ThankYouEClaim/ThankYouEClaimHome';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import EOWDocumentUploader from '../../components/EscapeOfWater/EOWDocumentUploader';

const Theme = getTheme(brand);

const EscapeOfWaterThankYouPage = history => {
  const [escapeOfWaterResultData, setEscapeOfWaterResultData] = useState({});
  const [render, setRender] = useState(false);
  const historyHook = useHistory();

  useEffect(() => {
    const resultData = history?.history?.location?.state || {};
    if (!resultData?.isComplete && !resultData?.showUpload) {
      historyHook.push('/homeinsurance');
      return;
    }
    setRender(true);
    window.scrollTo(0, 0);
    setEscapeOfWaterResultData(resultData);
  }, []);

  const thankYouContent = () => {
    if (escapeOfWaterResultData?.isAccept === false) {
      return (
        <ExcessNoAcceptText
          claimReference={escapeOfWaterResultData?.isClaimRef}
        />
      );
    }

    if (
      escapeOfWaterResultData?.isAccept === true &&
      escapeOfWaterResultData?.isReferredData &&
      !escapeOfWaterResultData?.supplierName
    ) {
      return (
        <ReferredText
          claimReference={escapeOfWaterResultData?.isClaimRef}
          itemDescription="escape of water"
        />
      );
    }

    if (
      escapeOfWaterResultData?.isAccept === true &&
      escapeOfWaterResultData?.isReferredData &&
      !!escapeOfWaterResultData?.supplierName
    ) {
      return (
        <EOWReferAndInstructSupplierText
          claimReference={escapeOfWaterResultData?.isClaimRef}
          userContactNumber={escapeOfWaterResultData?.userContactNumber}
          supplierName={escapeOfWaterResultData?.supplierName}
          supplierNumber={escapeOfWaterResultData?.supplierNumber}
        />
      );
    }

    return (
      <InstructSupplierText
        claimReference={escapeOfWaterResultData?.isClaimRef}
        userContactNumber={escapeOfWaterResultData?.userContactNumber}
        supplierName={escapeOfWaterResultData?.supplierName}
        supplierNumber={escapeOfWaterResultData?.supplierNumber}
        perilDescription="escape of water"
      />
    );
  };

  const renderUpload = () => {
    return (
      <EOWDocumentUploader
        claimReference={escapeOfWaterResultData?.isClaimRef}
      />
    );
  };

  if (!render) {
    return null;
  }

  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <ClaimBreadcrumbStandard activeItem="thankYou" />
        <PageSectionBare>
          <Subsection>
            {escapeOfWaterResultData?.isComplete && thankYouContent()}
            {escapeOfWaterResultData?.showUpload && renderUpload()}
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default EscapeOfWaterThankYouPage;
