import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemedTitle } from 'ageas-ui-components';
import TelephoneLink from '../../components/TelephoneLink/TelephoneLink';
import ThemedAnchorButton from '../../components/ThemedAnchorButton/ThemedAnchorButton';
import media from '../../components/MediaQuery/MediaQueryMin';

// Add new components to the bottom. As changing the order changes snapshots

const ComponentTitle = styled.div`
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 5px;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 28px;
    padding-bottom: 5px;
    margin: 0 0 1rem;
    padding: 0;
    color: #2c2c2c;
    font-family:
      Arial,
      Helvetica Neue,
      Helvetica,
      sans-serif;
  }
`;

const StaticText = styled.div`
  padding-left: 10px;
  font-size: 15px;
  margin-bottom: 10px;
`;

const TextAlign = styled.div`
  text-align: -webkit-center;
  text-align: -moz-center;
`;
const FieldHeader = styled.div`
  font-weight: normal;
  font-size: 19px;
  margin-bottom: 10px;
  margin-top: 32px;
`;
const HeaderComponent = styled.div`
  padding-top: 25px;
  max-width: 875px;
  @media only screen and (max-width: 767px) {
    padding: 50px 0px;
    padding-left: 0px;
  }
`;
const ComponentText = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 25px;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 18px;
    padding-bottom: 25px;
    margin: 0 0 1rem;
    padding: 0;
    color: #2c2c2c;
    font-family:
      Arial,
      Helvetica Neue,
      Helvetica,
      sans-serif;
  }
`;

export const PageContainer = styled.div`
  font-size: 19px;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
`;

export const Subsection = styled.div`
  padding: 25px 0px;
`;

export const ButtonsBottomContainer = styled(Subsection)`
  text-align: right;
  width: 100%;
`;
export const TwoButtonsBottomContainer = styled(Subsection)`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'space-between'};
  width: 100%;
`;

const ComponentIcon = styled.div`
  padding: 0px 60px;
  max-width: 1300px;
  @media only screen and (max-width: 990px) {
    max-width: 540px;
    padding: 0px 12px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0px;
    margin-left: -10px;
    margin-right: 10px;
    max-width: 100%;
  }
`;

const DeskView = styled.div`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const MobView = styled.div`
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const PanelAlignment = styled.div`
  &:nth-child(1) {
    display: inline-flex;
    margin-right: 15px;
    margin-bottom: 15px;
    @media only screen and (max-width: 1346px) {
      display: grid;
      width: auto;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }
  &:nth-child(2) {
    display: inline-flex;
    margin-right: 15px;
    margin-bottom: 15px;
    @media only screen and (max-width: 1346px) {
      display: grid;
      width: auto;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }
  &:nth-child(3) {
    display: inline-flex;
    margin-right: 15px;
    margin-bottom: 15px;
    @media only screen and (max-width: 1346px) {
      display: grid;
      width: auto;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 1346px) {
    display: block;
  }
`;

const WholePanel = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-right: 0px;

  & > * {
    margin-right: 15px;
  }
  & > *:last-child {
    margin-right: 0px;
  }

  @media only screen and (max-width: 1346px) {
    //  display:block;
    //padding: 0px 100px;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    flex-direction: column;

    & > * {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media only screen and (max-width: 990px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

const PanelItemSide = styled.div`
  width: 318px;
  @media only screen and (max-width: 1346px) {
    width: 100%;
  }
`;
const PanelItemCentre = styled.div`
  width: 640px;
  @media only screen and (max-width: 1346px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const AnswerContent = styled.div`
  padding-left: 21px;
`;

const PhoneContent = styled.div`
  padding-left: 21px;
  font-size: 15px;
  font-weight: 900;
`;

const FaqContainer = styled.div`
  padding: 0px;
  padding-top: 15px;
`;

const NeedHelpInitial = styled.div`
  text-align: left;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  background-color: #ebebeb;
`;

export const PageSectionBare = styled.div`
  margin: 0px auto;
  padding: 0px;
  width: 100%;
  max-width: 1100px;
`;
export const PageSection = styled(PageSectionBare)`
  padding: 25px 50px;
`;

export const TopText = styled.div`
  background-color: #ebebeb;
  width: 100%;
`;
export const TopTextSection = styled(PageSection)`
  max-width: 730px;
  text-align: left;
  ${media.desktop`
    padding-top: 50px;
    padding-bottom: 50px;
  `};
  ${media.tablet`
    text-align: center;
  `};
`;
export const TopTextTitle = styled.div`
  background-color: #ebebeb;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0.25em;
  ${media.tablet`
    font-size: 36px;
  `};
`;

export const NavLink = styled(Link)`
  color: #208de9;
  text-decoration: none;
`;

export const NavLinkButtonIconRight = styled(ThemedAnchorButton)`
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  margin-bottom: 0px;
`;
export const NavLinkButtonIconContainer = styled.span`
  padding: 0px;
  font-size: 2em;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 6px;
`;

export const PageSectionColoured = styled(PageSection)`
  background-color: #fafafa;
  padding: 0px 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ClaimButtonStyle = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
  padding: 15px 10px;
`;

export const TitleText = styled.h2`
  font-size: 22px;
  ${props =>
    props.primary &&
    css`
      color: #8e419c;
    `}
  ${props =>
    props.white &&
    css`
      color: white;
    `}
  margin-bottom: 0.25em;
`;

export const PhoneNumbersSection = styled.div`
  margin-top: 20px;
  background-color: #fafafa;
  width: 100%;
`;
export const PhoneNumbersTextContainer = styled(PageSection)`
  padding-bottom: 0px;
`;
export const PhoneNumbersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 1100px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const PhoneNumberBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #ebebeb;
  margin: 10px 10px;
  width: 340px;
  height: 120px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
`;
export const PhoneNumber = styled(TelephoneLink)`
  font-size: 30px;
  color: black;
  &:not(:hover) {
    text-decoration: none;
  }
`;
const ThemedTitleSpaced = styled(ThemedTitle)`
  margin-bottom: 32px;
`;

const FieldHeaderNoTop = styled(FieldHeader)`
  margin-top: 0px;
`;

const FieldHeaderSpaced = styled(FieldHeader)`
  margin-bottom: 32px;
`;

export {
  ComponentTitle,
  TextAlign,
  FieldHeader,
  FieldHeaderNoTop,
  HeaderComponent,
  ComponentText,
  ComponentIcon,
  DeskView,
  MobView,
  PanelAlignment,
  WholePanel,
  AnswerContent,
  PhoneContent,
  FaqContainer,
  NeedHelpInitial,
  ContentWrapper,
  PanelItemCentre,
  PanelItemSide,
  StaticText,
  ThemedTitleSpaced,
  FieldHeaderSpaced,
};
