import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { LandingView } from '../views/Landing';
import { CarInsurance } from '../views/CarInsurance';
import { HomeInsurance } from '../views/HomeInsurance';
import { NotFoundErrorView } from '../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from './NotFoundRoute';
import AccordionDemo from '../components/NestedAccordion/AccordionDemo';
import QuestionsList from '../components/FAQs/HomeFAQ/_tests_/QuestionsListTestRender';
import IncidentDateTestRenderer from '../components/Forms/ClaimEntryFields/IncidentDate/IncidentDateTestRenderer';
import EclaimsRoute from './EclaimsRoute';
import HomeClaimsTemplate from '../views/HomeClaimsWrapper/HomeClaimsWrapper';
import MotorClaimRoutes from './MotorClaimRoutes/MotorClaimRoutes';
import HomeClaimRoutes from './HomeClaimRoutes/HomeClaimRoutes';
import ErrorMessagesRenderer from '../helpers/_test_/ErrorMessagesRenderer';
import WeatherResultSetRenderer from '../components/WeatherResult/__tests__/WeatherResultSetRenderer';
import HomeClaimsHubRoutes from './HomeClaimsHubRoutes/HomeClaimsHubRoutes';

import AuthContextTest1, {
  AuthContextTest2,
} from '../contexts/AuthContextDemo';
import UploaderExample from '../components/PersonalLossReceiptResult/UploaderExample';
import TPAssist from '../views/TPAssist/TPAssist';
import MotorClaimsHubRoutes from './MotorClaimsHubRoutes/MotorClaimsHubRoutes';
import MotorClaimsTpHubRoutes from './MotorClaimsTpHubRoutes/MotorClaimsTpHubRoutes';

/* istanbul ignore next */
const HomeClaimsTemplateDemo = () => {
  return (
    <HomeClaimsTemplate>
      <div>hi</div>
    </HomeClaimsTemplate>
  );
};

// istanbul ignore next */
const TestPages = () => {
  return (
    <Switch>
      <Route exact path="/TestPages/questionlist" component={QuestionsList} />
      <Route
        exact
        path="/TestPages/UploadDocuments"
        component={UploaderExample}
      />
      <Route
        exact
        path="/TestPages/UploadDocuments"
        component={UploaderExample}
      />
      <Route
        exact
        path="/TestPages/WeatherResultsDisplay"
        component={WeatherResultSetRenderer}
      />
      <Route
        exact
        path="/TestPages/ErrorMessagesRenderer"
        component={ErrorMessagesRenderer}
      />
      <Route
        exact
        path="/TestPages/HomeClaimsTemplateDemo"
        component={HomeClaimsTemplateDemo}
      />
      <Route
        exact
        path="/TestPages/AuthContextTest1"
        component={AuthContextTest1}
      />
      <Route
        exact
        path="/TestPages/AuthContextTest2"
        component={AuthContextTest2}
      />
      <Route exact path="/TestPages/accordion" component={AccordionDemo} />
      <Route
        exact
        path="/TestPages/IncidentDateTestRenderer"
        component={IncidentDateTestRenderer}
      />
      <NotFoundRoute component={NotFoundErrorView} />
    </Switch>
  );
};

const Routes = () => {
  return (
    <Switch>
      {/* istanbul ignore next */}
      <EclaimsRoute
        path="/TestPages"
        featureKey="FEATURE_TEST_PAGES"
        acceptableValue={['true']}
        component={TestPages}
      />
      <Route exact path="/" component={LandingView} />
      <EclaimsRoute
        path="/homeclaim"
        component={HomeClaimRoutes}
        featureKey="FEATURE_HOME_CLAIM"
        acceptableValue={['version1']}
      />
      <Route exact path="/carinsurance" component={CarInsurance} />
      <Route exact path="/homeinsurance" component={HomeInsurance} />
      <EclaimsRoute
        path="/homehub"
        component={HomeClaimsHubRoutes}
        featureKey="FEATURE_HOME_CLAIM_HUB"
        acceptableValue={['version1']}
      />
      <EclaimsRoute
        path="/motorhub"
        component={MotorClaimsHubRoutes}
        featureKey="FEATURE_MOTOR_CLAIM_HUB"
        acceptableValue={['version1']}
      />
      <EclaimsRoute
        path="/motortphub"
        component={MotorClaimsTpHubRoutes}
        featureKey="FEATURE_MOTOR_CLAIM_TP_HUB"
        acceptableValue={['version1']}
      />
      <Route exact path="/tpassist" component={TPAssist} />
      {/* Array of paths usually forces exact=true,
        added regex (.*) changes them to startsWith */}
      <Route
        path={['/portallanding(.*)', '/motorclaim(.*)']}
        component={MotorClaimRoutes}
      />
      <NotFoundRoute component={NotFoundErrorView} />
    </Switch>
  );
};

export default withRouter(Routes);
