import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  List,
  ListItem,
  ListItemAnchor,
  ListItemLink,
  ListItemSpan,
  Dot,
} from './Breadcrumbstyle';
import { sizes } from '../MediaQuery/MediaQuery';

const buildInnerItem = (item, index, active, formatProps) => {
  return (
    <Dot active={active} styling={formatProps}>
      {item.label}
    </Dot>
  );
};

const buildItemLink = (item, index, active, formatProps) => {
  const linkContainerProps = {
    ...item.linkContainerProps,
    ...(active
      ? item.linkContainerActiveProps
      : item.linkContainerInactiveProps),
  };
  if (active && item.href) {
    return (
      <ListItemAnchor href={item.href} {...linkContainerProps}>
        {buildInnerItem(item, index, active, formatProps)}
      </ListItemAnchor>
    );
  }
  if (active && item.to) {
    return (
      <ListItemLink to={item.to} {...linkContainerProps}>
        {buildInnerItem(item, index, active, formatProps)}
      </ListItemLink>
    );
  }
  return (
    <ListItemSpan {...linkContainerProps}>
      {buildInnerItem(item, index, active, formatProps)}
    </ListItemSpan>
  );
};

const buildItems = (items, currentItem, formatProps) => {
  return items.map((item, index) => {
    const active = currentItem !== undefined && index <= currentItem;
    const activeLine =
      active || (currentItem !== undefined && index - 1 === currentItem);
    return (
      <ListItem
        key={item.id || index}
        active={active}
        activeLine={activeLine}
        styling={formatProps}
      >
        {buildItemLink(item, index, active, formatProps)}
      </ListItem>
    );
  });
};

const Breadcrumb = forwardRef(
  (
    {
      items = [],
      currentItem,
      noLabels = false,
      mediaBreakpoint = `${sizes.tablet}px`,
      paddingBottomOffset = '0px',
      paddingSideOffset = '0px',
      alwaysNarrow = false,
      maxInnerWidth,
      maxNarrowInnerWidth,
      ...rest
    },
    ref,
  ) => {
    const formatProps = {
      noLabels,
      mediaBreakpoint,
      paddingBottomOffset,
      paddingSideOffset,
      alwaysNarrow,
      maxInnerWidth,
      maxNarrowInnerWidth,
    };

    return (
      <Container styling={formatProps} ref={ref} {...rest}>
        <List styling={formatProps}>
          {buildItems(items, currentItem, formatProps)}
        </List>
      </Container>
    );
  },
);

export default Breadcrumb;

// For some reason (maybe the forwardRef), defaultProps are not working
// So instead default are applied in the prop destructuring above.
Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
      ]),
      href: PropTypes.string,
      to: PropTypes.string,
      isBoth: PropTypes.bool,
      linkContainerProps: PropTypes.shape({}),
      linkContainerActiveProps: PropTypes.shape({}),
      linkContainerInactiveProps: PropTypes.shape({}),
    }),
  ),
  currentItem: PropTypes.number,
  noLabels: PropTypes.bool,
  mediaBreakpoint: PropTypes.string,
  paddingBottomOffset: PropTypes.string,
  paddingSideOffset: PropTypes.string,
  alwaysNarrow: PropTypes.bool,
  maxInnerWidth: PropTypes.string,
  maxNarrowInnerWidth: PropTypes.string,
};

Breadcrumb.defaultProps = {
  items: undefined,
  currentItem: undefined,
  noLabels: undefined,
  mediaBreakpoint: undefined,
  paddingBottomOffset: undefined,
  paddingSideOffset: undefined,
  alwaysNarrow: undefined,
  maxInnerWidth: undefined,
  maxNarrowInnerWidth: undefined,
};
