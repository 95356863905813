import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

/**
 * Return true if any of the errored fields are also touched
 * @param {*} errors RFF errors object
 * @param {*} touched RFF touched object
 * @returns true if a field exists in both errors and touched, else false
 */
const hasVisibleErrors = (errors, touched) =>
  !!Object.entries(errors).find(([field, error]) => error && touched[field]);

/**
 * Display children if parent RFF <Form> has:
 * * Error on a touched field
 * * And Form Submit has been attempted
 * @param {*} children
 * @returns children
 */
const IfVisibleFormError = ({ children }) => {
  const { invalid, errors, touched, submitFailed } = useFormState({
    subscription: {
      invalid: true,
      errors: true,
      touched: true,
      submitFailed: true,
    },
  });

  const visibleErrors = useMemo(
    () => invalid && hasVisibleErrors(errors, touched),
    [invalid, errors, touched],
  );

  if (!visibleErrors || !submitFailed) {
    return null;
  }

  return <>{children}</>;
};

IfVisibleFormError.propTypes = {
  children: PropTypes.node,
};

IfVisibleFormError.defaultProps = {
  children: undefined,
};

export default IfVisibleFormError;
