import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateConfirmUpdate from '../ConfirmUpdate/DateConfirmUpdate';
import OccupationConfirmUpdate from '../ConfirmUpdate/OccupationConfirmUpdate';
import { isoToDmy, isoToDmyAndHms } from '../../../helpers/dateTimeConverter';
import {
  FIELD_NAMES,
  LICENCE_TYPES,
  PreviousClaimsArrayModalContext,
  PreviousConvictionsArrayModalContext,
} from '../motorYourDetailsHelper';
import AutoCompleteConfirmUpdate from '../ConfirmUpdate/AutoCompleteConfirmUpdate';
import InputConfirmUpdate from '../ConfirmUpdate/InputConfirmUpdate';
import PreviousClaimsConfirmUpdate from '../ConfirmUpdate/PreviousClaimsConfirmUpdate';
import ConvictionsConfirmUpdate from '../ConfirmUpdate/ConvictionsConfirmUpdate';
import ScrollList from '../../../components/ScrollList/ScrollList';
import { getClaimTypeByCode } from '../../../helpers/motorClaimTypeDescriptions';
import uniqueKeyMapperFactory from '../../../helpers/uniqueKeyMakerFactory';

/**
 * Special validator for Occupation, as it returns an object, not a string
 * Validate both label and value components of that object as strings
 * @param {*} validateFields standard validation function
 * @param {*} fieldValue Standard validation function argument, RFF field value
 * ({label:'x',value:'y'})
 * @param {*} allValues Standard validation function argument, RFF form values
 * @param {*} meta Standard validation function argument, RFF field meta
 * @returns Standard validation function return, validation error string
 */
const validateAutoComplete = (
  validateFields,
  { label, value } = {},
  allValues,
  meta,
) => {
  return (
    validateFields &&
    (validateFields(label, allValues, meta) ||
      validateFields(value, allValues, meta))
  );
};

const ScrollListStyled = styled(ScrollList)`
  margin-bottom: 16px;
`;

const convictionsClaimsProps = {
  confirmUpdateProps: {
    renderChildrenOn: ['no'],
    allowUnknown: false,
    valueInLegend: false,
  },
};

const mapConvictions = convictions => {
  const { mapper } = uniqueKeyMapperFactory();
  const convictionsMapped = convictions?.map(({ description, date }) => {
    const value = `${isoToDmy(date)?.date} ${description}`;
    return {
      value,
      key: mapper(value),
    };
  });
  return convictionsMapped;
};

const getPreviousConvictionsProps = convictions => ({
  ...convictionsClaimsProps,
  value: convictions?.length ? (
    <ScrollListStyled items={mapConvictions(convictions)} />
  ) : null,
  title: convictions?.length
    ? 'Previous driving convictions in the past 5 years'
    : 'Previous driving convictions in the past 5 years: None',
});

const mapClaims = claims => {
  const { mapper } = uniqueKeyMapperFactory();
  const claimsMapped = claims?.map(({ type, date }) => {
    const value = `${isoToDmy(date)?.date} ${
      getClaimTypeByCode(type)?.description || type
    }`;
    return {
      value,
      key: mapper(value),
    };
  });
  return claimsMapped;
};

const PREVIOUS_CLAIMS_DESCRIPTION =
  'Previous motor insurance claims in the past 3 years (either with ageas or a previous insurer)';
const getPreviousClaimsProps = claims => ({
  ...convictionsClaimsProps,
  value: claims?.length ? <ScrollListStyled items={mapClaims(claims)} /> : null,
  title: claims?.length
    ? PREVIOUS_CLAIMS_DESCRIPTION
    : `${PREVIOUS_CLAIMS_DESCRIPTION}: None`,
});

/**
 * Component for displaying data relating to specific driver, for confirmation/
 * update
 */
const DriverConfirmDetails = ({ driverData, validateFields }) => {
  const previousConvictionsProps = useMemo(
    () => getPreviousConvictionsProps(driverData?.convictions),
    [driverData?.convictions],
  );
  const previousClaimsProps = useMemo(
    () => getPreviousClaimsProps(driverData?.claims),
    [driverData?.claims],
  );

  return (
    <>
      <DateConfirmUpdate
        title="Date of birth: "
        yesNoName={FIELD_NAMES.DATE_OF_BIRTH_IS_CORRECT}
        updateFieldName={FIELD_NAMES.DATE_OF_BIRTH}
        value={isoToDmyAndHms(driverData?.dateOfBirth)?.date}
        validate={validateFields}
        idField={FIELD_NAMES.DATE_OF_BIRTH}
      />
      <OccupationConfirmUpdate
        yesNoName={FIELD_NAMES.OCCUPATION_IS_CORRECT}
        value={driverData?.occupationDescription?.trim()}
        validate={validateFields}
        validateField={(...args) =>
          validateAutoComplete(validateFields, ...args)
        }
      />
      <AutoCompleteConfirmUpdate
        title="Licence type: "
        yesNoName={FIELD_NAMES.LICENCE_TYPE_IS_CORRECT}
        updateFieldName={FIELD_NAMES.LICENCE_TYPE}
        value={driverData?.licenceDescription?.trim()}
        validate={validateFields}
        validateField={(...args) =>
          validateAutoComplete(validateFields, ...args)
        }
        fieldProps={{
          suggestions: LICENCE_TYPES,
          minCharacters: 0,
          openOnZeroInput: true,
          noSuggestionsMsg:
            'No results found, please try typing another licence type',
          placeholder: 'Start typing',
        }}
      />
      <InputConfirmUpdate
        title="Years licence held: "
        yesNoName={FIELD_NAMES.LICENCE_YEARS_IS_CORRECT}
        updateFieldName={FIELD_NAMES.LICENCE_YEARS}
        value={driverData?.licenceYears?.toString()?.trim()}
        validate={validateFields}
        fieldProps={{
          maxLength: 2,
          xSmall: true,
          inputmode: 'numeric',
        }}
      />
      <ConvictionsConfirmUpdate
        {...previousConvictionsProps}
        yesNoName={FIELD_NAMES.DRIVER_CONVICTIONS_IS_CORRECT}
        updateFieldName={FIELD_NAMES.DRIVER_CONVICTIONS}
        validate={validateFields}
        Context={PreviousConvictionsArrayModalContext}
      />
      <PreviousClaimsConfirmUpdate
        {...previousClaimsProps}
        yesNoName={FIELD_NAMES.DRIVER_CLAIMS_IS_CORRECT}
        updateFieldName={FIELD_NAMES.DRIVER_CLAIMS}
        validate={validateFields}
        Context={PreviousClaimsArrayModalContext}
      />
    </>
  );
};

DriverConfirmDetails.propTypes = {
  driverData: PropTypes.instanceOf(Object),
  validateFields: PropTypes.func,
};

DriverConfirmDetails.defaultProps = {
  driverData: undefined,
  validateFields: undefined,
};

export default DriverConfirmDetails;
