import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import PassengerForm from './PassengerForm';
import Modal from '../../components/Modal/Modal';

const PageContainer = styled.div`
  width: 100%;
  max-width: 1100px;
`;
const InnerContainer = styled.div`
  background-color: white;
  padding: 23px 21px 16px 21px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

/*
Display PassengerForm in StandardPanel, in a modal popup

props:
* initialValues: Object to default RFF form state
* onSubmit: RFF OnSubmit callback
  * called with RFF form values object if form is valid
* onCancel: Call to instruct parent to close modal
* title: text title for StandardPanel

*/
const PassengerModalForm = ({ initialValues, onSubmit, onCancel, title }) => {
  return (
    <Modal onClose={onCancel}>
      <PageContainer>
        <StandardPanel
          primary
          title={title}
          shadowBorder={false}
          panelPadding="0px"
        >
          <InnerContainer>
            <PassengerForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </InnerContainer>
        </StandardPanel>
      </PageContainer>
    </Modal>
  );
};

export default PassengerModalForm;

PassengerModalForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

PassengerModalForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  onCancel: undefined,
  title: '',
};
