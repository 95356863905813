import React from 'react';
import FaqP from '../../FaqP';
import StandardUL from '../../../StandardTags/UL';

export const buildingsAccidentalDamageTitle =
  'When would my buildings accidental damage claim not be covered?';
export const buildingsAccidentalDamageContent = (
  <>
    <FaqP>
      One reason that your claim may not be covered is because you do not have
      the Accidental Damage extension cover on your Buildings policy.
    </FaqP>
    <FaqP>
      If you have chosen this cover, it will be shown on your policy
      documentation.
    </FaqP>
    <FaqP marginBottom={false}>
      Common exclusions applicable to Buildings accidental damage extension
      claims include (but are not limited to) the following:
    </FaqP>
    <StandardUL>
      <li>Damage caused by wear and tear or lack of maintenance</li>
      <li>Poor workmanship or bad design</li>
      <li>Damage caused by chewing, scratching, tearing or fouling by pets</li>
      <li>
        Damage caused by someone that you lend or rent all or part of your home
        to
      </li>
      <li>Damage if the property is unoccupied</li>
    </StandardUL>
    <FaqP>
      <strong>PLEASE NOTE:</strong>
      <br />
      There will be specific and general exclusions that apply to all sections
      of your policy cover. Please refer to your policy booklet for full details
      of these exclusions.
    </FaqP>
  </>
);
