import React from 'react';
import FaqP from '../../FaqP';

export const freezerFoodRuinedTitle =
  'Do I have to keep the ruined food or can I throw it away?';
export const freezerFoodRuinedContent = (
  <FaqP>
    You are not expected to keep ruined food, however it is recommended that you
    take photographs of all items before disposing of them, as we may ask you to
    send these before a cash settlement can be agreed.
  </FaqP>
);
