import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';
import PropTypes from 'prop-types';

// For documentation see:
// /docs/components/Modal/Modal.md

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 10000;
`;

const Modal = ({
  children,
  closeOnOutClick,
  disableBodyScroll,
  onClose,
  ...props
}) => {
  const modalContainerRef = useRef(null);

  useEffect(() => {
    if (disableBodyScroll) {
      document.body.style.overflow = 'hidden';
      return () => {
        if (document?.body?.style?.overflow) {
          document.body.style.overflow = '';
        }
      };
    }
    return undefined;
  }, []);

  const onClick = event => {
    event.stopPropagation();
    if (closeOnOutClick && event.target === modalContainerRef.current) {
      onClose();
    }
  };

  return (
    <FocusLock>
      <ModalContainer ref={modalContainerRef} onClick={onClick} {...props}>
        {children}
      </ModalContainer>
    </FocusLock>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node,
  disableBodyScroll: PropTypes.bool,
  closeOnOutClick: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  children: undefined,
  disableBodyScroll: true,
  closeOnOutClick: false,
  onClose: () => {},
};
