import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

import validEmail from '../extensions/ValidEmail';

const validKeys = ['email', 'emailAddress'];

/**
 * @description: Validate the field is a valid email address pattern
 * @param keys Fields to validate
 * @param message the message to display
 * @succeeds when the email field is a valid email address pattern
 * @fails when email field is not a valid email address pattern
 */
export const email = (keys = validKeys, message = messages.EMAIL) => {
  const JoiExt = Joi.extend(validEmail);
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExt.string()
      .validEmail()
      .email()
      .error(() => message),
  );
};

export default email;
