import React from 'react';
import PropTypes from 'prop-types';
import AddressSubform from '../Address/AddressSubform';
import StandardInput from '../StandardInput/StandardInput';
import StandardDropdown from '../StandardDropdown/StandardDropdown';
import titleCodes from '../../../helpers/titleCodes';
import SectionError from '../SectionError/SectionError';
import {
  personValidatorsPropType,
  personFieldNamesPropType,
} from './PersonPropTypes';

// For documentation see:
// /docs/components/Forms/Address/PersonSubform.md

const PersonSubform = ({ fieldNames, validate, validators, values, form }) => {
  return (
    <>
      <StandardDropdown
        name={fieldNames.title || 'title'}
        label="Title"
        aria-label="Title"
        validate={validators?.title || validate}
        options={titleCodes}
        blockContainerProps={{ small: true, minWidth: '228px' }}
        alwaysShowPlaceholder
      />

      <StandardInput
        name={fieldNames.firstName || 'firstName'}
        label="First name"
        validate={validators?.firstName || validate}
        small
        maxLength="20"
      />

      <StandardInput
        name={fieldNames.lastName || 'lastName'}
        label="Last name"
        validate={validators?.lastName || validate}
        small
        maxLength="40"
      />

      <AddressSubform
        fieldNames={fieldNames}
        validate={validate}
        validators={validators}
        values={values}
        form={form}
      />

      <StandardInput
        name={fieldNames.homeTelephone || 'homeTelephone'}
        label="Home telephone number"
        validate={validators?.homeTelephone || validate}
        xSmall
        maxLength="20"
      />

      <StandardInput
        name={fieldNames.mobileTelephone || 'mobileTelephone'}
        label="Mobile telephone number"
        validate={validators?.mobileTelephone || validate}
        xSmall
        maxLength="20"
      />

      <StandardInput
        name={fieldNames.emailAddress || 'emailAddress'}
        label="Email address"
        validate={validators?.emailAddress || validate}
        small
        maxLength="100"
      />

      <SectionError
        name={fieldNames.personSectionError || 'personSectionError'}
        validate={validators.personSectionError || validate}
      />
    </>
  );
};

PersonSubform.propTypes = {
  validate: PropTypes.func,
  validators: personValidatorsPropType,
  fieldNames: personFieldNamesPropType,
  values: PropTypes.instanceOf(Object).isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

PersonSubform.defaultProps = {
  fieldNames: {},
  validators: {},
  validate: () => {},
};

export default PersonSubform;
