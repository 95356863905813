import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { ThemedErrorMessage, ThemedLabel } from 'ageas-ui-components';
import { DAMAGE_LEVELS, damageLevelPropType } from './damageValues';
import { TitleTextSpaced } from '../../StandardPageWrapper/StandardPageWrapperStyle';

const ErrorDiv = styled.div`
  padding-bottom: 15px;
`;

const RadioGridDiv = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto;
  justify-items: start;
  align-items: start;
  gap: 5px;
  padding-bottom: 15px;
`;

const RadioInputContainer = styled.span`
  grid-row: 1 / 3;
  grid-column: 1 / 1;
  width: 20px;
`;

const RadioInput = styled.input`
  accent-color: #a962b6;
  height: 18px;
  width: 18px;
`;

const Radio = ({ name, damageLevel, validateFields }) => {
  const id = useRef();
  if (!id.current) {
    id.current = `VehicleDamageLevel-${name}-${
      damageLevel.name
    }-${_uniqueId()}`;
  }
  return (
    <Field
      name={name}
      value={damageLevel.name}
      type="radio"
      validate={validateFields}
    >
      {({ input }) => (
        <RadioGridDiv>
          <RadioInputContainer>
            <RadioInput
              id={id.current}
              type="radio"
              {...input}
              // Prevent touched from being set on focus/blur
              onFocus={undefined}
              onBlur={undefined}
            />
          </RadioInputContainer>
          <ThemedLabel fieldName={id.current} isMargin>
            {damageLevel.label}
          </ThemedLabel>
          {damageLevel.secondaryLabel && (
            <small>{damageLevel.secondaryLabel}</small>
          )}
        </RadioGridDiv>
      )}
    </Field>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  damageLevel: damageLevelPropType.isRequired,
  validateFields: PropTypes.func,
};
Radio.defaultProps = {
  validateFields: undefined,
};

const ErrorHandling = ({ name }) => (
  <Field name={name}>
    {({ meta }) =>
      meta.error &&
      meta.touched && (
        <ErrorDiv>
          <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
        </ErrorDiv>
      )
    }
  </Field>
);

ErrorHandling.propTypes = {
  name: PropTypes.string.isRequired,
};

const VehicleDamageRadios = ({ name, includeNone = true, validateFields }) => {
  const radioProps = {
    name,
    validateFields,
  };
  return (
    <>
      <TitleTextSpaced>
        Select the level of damage to this area of the vehicle.
      </TitleTextSpaced>
      {includeNone && (
        <Radio {...radioProps} damageLevel={DAMAGE_LEVELS.NONE} />
      )}
      <Radio {...radioProps} damageLevel={DAMAGE_LEVELS.SCRATCH} />
      <Radio {...radioProps} damageLevel={DAMAGE_LEVELS.DENT} />
      <Radio {...radioProps} damageLevel={DAMAGE_LEVELS.MEDIUM} />
      <Radio {...radioProps} damageLevel={DAMAGE_LEVELS.HEAVY} />
      <ErrorHandling {...radioProps} />
    </>
  );
};

VehicleDamageRadios.propTypes = {
  name: PropTypes.string.isRequired,
  includeNone: PropTypes.bool,
  validateFields: PropTypes.func,
};
VehicleDamageRadios.defaultProps = {
  includeNone: undefined,
  validateFields: undefined,
};

export default VehicleDamageRadios;
