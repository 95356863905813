import ComponentLibraryTheme from './AgeasComponentLibraryTheme';
import PortalLibraryTheme from './PortalLibraryTheme';
import ColorTheme from './AgeasColorTheme';
import MainThemeDimensions from './MainThemeDimensions';

const MainTheme = {
  ...MainThemeDimensions,
  ...PortalLibraryTheme,
  ...ComponentLibraryTheme,
  ...ColorTheme,
};

export default MainTheme;
