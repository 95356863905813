import React from 'react';
import PropTypes from 'prop-types';
import InvolvedPartyName, {
  getInvolvedPartyNameSchema,
} from './InvolvedPartyName';
import InvolvedPartyAddress, {
  getInvolvedPartyAddressSchema,
} from './InvolvedPartyAddress';
import InvolvedPartyContact, {
  getInvolvedPartyContactSchema,
} from './InvolvedPartyContact';
import { FieldHeader } from '../../../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';

const DEFAULT_FIELD_NAME = 'involvedParty';

export const getInvolvedPartySchema = fieldName => {
  return {
    ...getInvolvedPartyNameSchema(fieldName),
    ...getInvolvedPartyAddressSchema(fieldName),
    ...getInvolvedPartyContactSchema(fieldName),
  };
};

const InvolvedParty = ({
  name = DEFAULT_FIELD_NAME,
  header,
  headerProps,
  company,
  companyLabel,
  validate,
  hideName = false,
  hideAddress = false,
  hideContact = false,
}) => {
  return (
    <>
      {header && <FieldHeader {...headerProps}>{header}</FieldHeader>}
      {!hideName && (
        <InvolvedPartyName
          name={name}
          validate={validate}
          company={company}
          companyLabel={companyLabel}
        />
      )}
      {!hideAddress && <InvolvedPartyAddress name={name} validate={validate} />}
      {!hideContact && (
        <InvolvedPartyContact
          name={name}
          validate={validate}
          company={company}
        />
      )}
    </>
  );
};

InvolvedParty.propTypes = {
  name: PropTypes.string,
  header: PropTypes.string,
  headerProps: PropTypes.shape({}),
  company: PropTypes.bool,
  companyLabel: PropTypes.string,
  validate: PropTypes.func,
  hideName: PropTypes.bool,
  hideAddress: PropTypes.bool,
  hideContact: PropTypes.bool,
};
InvolvedParty.defaultProps = {
  name: undefined,
  header: undefined,
  headerProps: undefined,
  company: false,
  companyLabel: undefined,
  validate: undefined,
  hideName: undefined,
  hideAddress: undefined,
  hideContact: undefined,
};

export default InvolvedParty;
