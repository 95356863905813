import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const howDealtWithTitle = 'How will my complaint be dealt with?';
export const howDealtWithContent = (
  <>
    <FaqP marginBottom={false}>
      We&apos;ll try to resolve your complaint within three working days. If we
      can’t do this, we’ll write to you within five working days to either:
    </FaqP>
    <StandardUl>
      <li>Tell you what we’ve tried to do to resolve your complaint</li>
      <li>
        Let you know we’ve received your complaint and when you can expect a
        response from us
      </li>
      <li>Let you know who is dealing with your complaint</li>
    </StandardUl>
  </>
);
