export const STAGES = {
  INPUT: {
    name: 'input',
    path: '/motorclaim/newclaim/incidentdetails',
  },
  OUTCOME: {
    name: 'outcome',
    path: '/motorclaim/newclaim/claimoutcome',
  },
};

export const getStageNames = () =>
  Object.values(STAGES).map(({ name }) => name);

export const getStageByName = name =>
  Object.values(STAGES).find(stage => name === stage.name);

export default STAGES;
