import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import BlockNavigation from '../BlockNavigation/BlockNavigation';
import { MotorClaimContextNew } from '../../helpers/motorClaimHelperNew';
import { updateLocalPageSectionIntoContextUnvalidated } from '../../helpers/updateContinuableMotorClaimData';

const ContinuableMotorClaimFormNavActions = ({
  unsaved = true,
  values = {},
  enabled = true,
  pristine = false,
  section,
}) => {
  const { dataState, updateDataState } = useContext(MotorClaimContextNew);
  const noLongerPristineRef = useRef(false);
  if (!noLongerPristineRef.current && !pristine) {
    noLongerPristineRef.current = true;
  }

  const considerUnsavedData = enabled && unsaved && noLongerPristineRef.current;
  const saveToContext = () => {
    updateLocalPageSectionIntoContextUnvalidated(
      dataState,
      section,
      values,
      updateDataState,
    );
  };

  return (
    <BlockNavigation
      enabled={considerUnsavedData}
      beforePromptAction={saveToContext}
    />
  );
};

ContinuableMotorClaimFormNavActions.propTypes = {
  unsaved: PropTypes.bool,
  values: PropTypes.instanceOf(Object),
  enabled: PropTypes.bool,
  pristine: PropTypes.bool,
  section: PropTypes.string.isRequired,
};
ContinuableMotorClaimFormNavActions.defaultProps = {
  unsaved: undefined,
  values: undefined,
  enabled: undefined,
  pristine: undefined,
};

export default ContinuableMotorClaimFormNavActions;
