import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { relationship } from '../constants/LookupValues';

/* Need to return
Joi.object().keys({

        additionalInsuredDateOfBirth: Joi.date().when('additionalInsuredRelationshipToProposer', {
            is: 'PARENT',
            then: Joi.date().less(Joi.ref('proposerDateOfBirth')).required().error(()=> messages["PARENT"])
        }).concat(Joi.date().when('additionalInsuredRelationshipToProposer', {
            is: 'OFFSPRNG',
            then: Joi.date().greater(Joi.ref('proposerDateOfBirth')).required().error(()=> messages["OFFSPRING"])
        })).required(),
    })
    without hardcoding
*/

const ParentChildConfig = {
  RelationshipField: 'additionalInsuredRelationshipToProposer',
  additionalInsuredDOBField: 'additionalInsuredDateOfBirth',
  proposerDOBField: 'proposerDateOfBirth',
  parentValue: relationship.parent,
  offspringValue: relationship.offspring,
  parentMessage: 'PARENT',
  offspringMessage: 'OFFSPRING',
};

/**
 * @description: Uses a config object to validate consistency between fields. If the additional insured relationship
 * to proposer is parent then they should be older than the proposer,  the converse is also validated, If the additional insured relationship
 * to proposer is child then they should be younger than the propser.
 * @param config an object containing te relationship Field name, the additional insured dob fieldname, the proposer dob fieldname, the parent identifier, the offspring identifier,
 * the message for display for when both the parent and the offspring fail.
 * @succeeds when relationship is consistent with date of birth
 * @fails when relationship fields are not consistent with dob eg parent is younger than child.
 */
export const parentChild = (config = ParentChildConfig) => {
  return Joi.object().pattern(
    Joi.valid(config.additionalInsuredDOBField),
    Joi.date()
      .error(() => messages.EMPTY_DATE)
      .when(config.RelationshipField, {
        is: config.parentValue,
        then: Joi.date()
          .less(Joi.ref(config.proposerDOBField))
          .required()
          .error(() => messages[config.parentMessage]),
      })
      .concat(
        Joi.date().when(config.RelationshipField, {
          is: config.offspringValue,
          then: Joi.date()
            .greater(Joi.ref(config.proposerDOBField))
            .required()
            .error(() => messages[config.offspringMessage]),
        }),
      ),
  );
};

export default parentChild;
