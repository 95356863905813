import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import MotorContactDetail from '../../views/MotorContactDetail/MotorContactDetail';
import MotorIncidentDetail from '../../views/MotorIncidentDetail/MotorIncidentDetail';
import { InvolvedPartiesDetail } from '../../views/InvolvedPartiesDetail';
import Confirmation from '../../views/Confirmation/Confirmation';
import ThankYouEClaim from '../../views/ThankYouEClaim/ThankYouEClaim';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import {
  localStorageMotorPropertyName,
  MotorFormContext,
} from '../../helpers/motorFormStateHelper';
import config from '../../helpers/config';
import { PolicyContext } from '../../helpers/policyContextHelper';
import { BrokerContext } from '../../helpers/brokerContextHelper';
import { OTPVerificationMotor } from '../../views/NonDirectAuthentication/OTPVerification/OTPVerification';
import { BrokerLoginMotor } from '../../views/NonDirectAuthentication/BrokerLogin/BrokerLogin';
import { MobileEmailAuthenticationMotor } from '../../views/NonDirectAuthentication/MobileEmailAuthentication/MobileEmailAuthentication';

import PortalLandingPage from '../../views/PortalLandingPage/PortalLandingPage';
import EclaimsRoute from '../EclaimsRoute';

import { PortalIntegrationProvider } from '../../contexts/PortalIntegrationContext';
import MotorClaimEntryRoutesNew from './MotorClaimEntryRoutesNew';

const PORTAL_OTP_AUTH = ['portal', 'otp'];
const motorClaimsPrivateProps = {
  private: true,
  authTypes: PORTAL_OTP_AUTH,
  lobs: 'motor',
};

const MotorClaimBrokerLoginRoutes = () => {
  return (
    <DataContextProvider Context={BrokerContext}>
      <Switch>
        <Route
          exact
          path="/motorclaim/brokerlogin"
          component={BrokerLoginMotor}
        />
        <Route
          exact
          path="/motorclaim/brokerlogin/validatecontact"
          component={MobileEmailAuthenticationMotor}
        />
        <Route
          exact
          path="/motorclaim/brokerlogin/otpverify"
          component={OTPVerificationMotor}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

const MotorClaimEntryRoutes = () => {
  return (
    <DataContextProvider
      Context={MotorFormContext}
      localStoragePropertyName={localStorageMotorPropertyName}
      localStorageExpiryDays={config.client.localStorageExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <DataContextProvider Context={PolicyContext}>
        <Switch>
          <EclaimsRoute
            exact
            path="/motorclaim/contactdetails"
            component={MotorContactDetail}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/incidentdetails"
            component={MotorIncidentDetail}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/involvedpartiesdetail"
            component={InvolvedPartiesDetail}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/confirmation"
            component={Confirmation}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/thankyou"
            component={ThankYouEClaim}
            {...motorClaimsPrivateProps}
          />
          <NotFoundRoute component={NotFoundErrorView} />
        </Switch>
      </DataContextProvider>
    </DataContextProvider>
  );
};

const MotorClaimRoutes = () => {
  return (
    <Switch>
      <Route
        path="/motorclaim/brokerlogin"
        component={MotorClaimBrokerLoginRoutes}
      />

      <Route
        exact
        path={[
          '/motorclaim/contactdetails',
          '/motorclaim/incidentdetails',
          '/motorclaim/involvedpartiesdetail',
          '/motorclaim/confirmation',
          '/motorclaim/thankyou',
        ]}
        component={MotorClaimEntryRoutes}
      />
      <Route
        path={['/motorclaim/history', '/motorclaim/newclaim/']}
        component={MotorClaimEntryRoutesNew}
      />
      <NotFoundRoute component={NotFoundErrorView} />
    </Switch>
  );
};

const MotorClaimRouteSet = () => {
  return (
    <PortalIntegrationProvider>
      <Switch>
        <Route exact path="/portallanding" component={PortalLandingPage} />
        <Route path="/motorclaim" component={MotorClaimRoutes} />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </PortalIntegrationProvider>
  );
};

export default MotorClaimRouteSet;
