import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Image from '../ContactImage/ContactImage';

const BannerContainer = styled.div`
  text-align: left;
`;

const BannerGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${props =>
    props.vertical &&
    css`
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        div:last-child {
          margin-right: ${props.theme.buttonMarginRight};
        }
      }
    `}
`;

const BannerButton = styled.div`
  display: flex;
  // align-items: center;
  // justify-content: center;
  min-width: ${props =>
    props.srcImg ? props.theme.buttonIconMinWidth : props.theme.buttonMinWidth};
  overflow: hidden;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  margin-bottom: 10px;
  -webkit-tap-highlight-color: transparent;
  @media only screen and (max-width: 767px) {
    width: ${props => (props.srcImg ? '100%' : null)};
    margin-right: ${props =>
      props.srcImg ? '0px' : props.theme.buttonMarginRight};
  }
  margin: 0 10px 25px;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#fff')};
`;

const BannerLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 1em 0.75em;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  fill: ${props => props.theme.buttonPrimaryColor};
  transition: ${props => (props.srcImg ? null : 'all 0.2s ease 0s')};

  ${BannerButton}:hover & {
    border: 1px solid #8e419c;
  }
  border: 1px solid #ebebeb;
  padding: 33px;
  padding-right: ${props => (props.srcImg.id === 'motor' ? '25px' : '')};
  @media only screen and (max-width: 767px) {
    padding: ${props => (props.srcImg.id === 'motor' ? '25px' : '')};
    padding-bottom: ${props => (props.srcImg.id === 'motor' ? '50px' : '40px')};
  }
`;

const BannerImg = styled.img`
  position: absolute;
  left: 0;
  opacity: 0;
  &:checked + ${BannerLabel} svg {
    fill: ${props => props.theme.BannerInputCheckedFill};
  }
  &:checked + ${BannerLabel} {
    background: ${props => props.theme.buttonActiveColor};
    color: ${props => props.theme.BannerInputCheckedColor};
  }
`;

const IconLabel = styled.p`
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-size: 1.1rem;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#fff')};
  color: ${props => (props.lbcolor ? props.lbcolor : '#2c2c2c')};
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  line-height: 1.33333;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  width: min-content;
  padding: 0px;
  margin: 0px;
  @media only screen and (max-width: 767px) {
    margin-left: 95px;
    width: auto;
    text-align: -webkit-left;
    margin-top: -54px;
  }
`;

const BannerNormal = styled.div`
  display: block;
  padding-left: 4px;
  @media only screen and (max-width: 767px) {
    // position: absolute;
    // left: 36px;
  }
`;

const BannerIcon = styled.div`
  @media only screen and (max-width: 767px) {
    display: ${props => (props.icon ? 'none' : 'inline-block')};
  }
`;

const LinkBanner = styled.a`
  text-decoration: none;
  @media only screen and (max-width: 990px) {
    &:last-child {
      margin-left: 170px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: -webkit-fill-available;
    &:last-child {
      margin-left: 0px;
    }
  }
`;

const ContactBanner = ({
  fieldName,
  selectedValue,
  buttonSizes,
  srcImg = {},
  subLabel,
  className,
  vertical,
  checked,
}) => {
  return (
    <BannerContainer>
      <BannerGroupWrapper
        data-testid={`${fieldName}BannerGroupWrapper`}
        vertical={vertical}
      >
        {srcImg &&
          srcImg.map((srcImage, index) => {
            const isSelected = checked || selectedValue === srcImage.value;

            return (
              <LinkBanner
                href={srcImage.website}
                tabIndex={0}
                key={srcImage.id}
                onBlur={e => (e.target.style = '')}
                onMouseDown={e => (e.target.style = 'outline: 0')}
              >
                <BannerButton
                  srcImg={srcImage}
                  buttonSizes={buttonSizes}
                  className={className}
                  isSelected={isSelected}
                  data-testid={`${fieldName}BannerButton${index}`}
                  bgcolor={srcImage.bgcolor}
                >
                  <BannerImg
                    type="Banner"
                    name={fieldName}
                    value={srcImage.value}
                    key={`${fieldName}-${srcImage.value}`}
                    id={`${fieldName}-${srcImg.value}`}
                    checked={isSelected}
                  />
                  <BannerLabel
                    srcImg={srcImage}
                    htmlFor={`${fieldName}-${srcImage.value}`}
                    isSelected={isSelected}
                    data-testid={`${fieldName}BannerLabel${index}`}
                  >
                    {srcImg && srcImage.src && (
                      <BannerIcon icon={srcImage.icon}>
                        <BannerNormal>
                          <Image
                            size={65}
                            srcImg={srcImage.src}
                            src={srcImage.id}
                            mobileSelect={srcImage.imgcon}
                          />
                        </BannerNormal>
                      </BannerIcon>
                    )}

                    <IconLabel
                      bgcolor={srcImage.bgcolor}
                      lbcolor={srcImage.lbcolor}
                    >
                      {srcImage.label}
                    </IconLabel>
                    {subLabel && srcImage.subLabel && (
                      <span data-testid="subLabel">{srcImage.subLabel}</span>
                    )}
                  </BannerLabel>
                </BannerButton>
              </LinkBanner>
            );
          })}
      </BannerGroupWrapper>
    </BannerContainer>
  );
};

ContactBanner.propTypes = {
  fieldName: PropTypes.string.isRequired,
  subLabel: PropTypes.bool,
  selectedValue: PropTypes.string,
  buttonSizes: PropTypes.string,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  srcImg: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      srcImg: PropTypes.string,
    }),
  ),
  checked: PropTypes.bool,
};

ContactBanner.defaultProps = {
  selectedValue: '',
  subLabel: false,
  className: null,
  buttonSizes: '',
  srcImg: '',
  vertical: false,
  checked: false,
};

export default ContactBanner;
