import React from 'react';
import { ThemedHyperlink } from 'ageas-ui-components';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const makeAComplaintTitle = 'How do I make a complaint about my claim?';
export const makeAComplaintContent = (
  <>
    <FaqP>
      If you haven&apos;t told us about your complaint yet, please get in touch
      with us and we&apos;ll do our best to put things right.
    </FaqP>
    <FaqP>There are 3 ways to make a complaint.</FaqP>
    <FaqP>
      <strong>Online:</strong>
      <br />
      Please submit your complaint using our online form which may be accessed
      through our website{' '}
      <ThemedHyperlink
        primary
        href="https://www.ageas.co.uk/make-a-complaint/home-complaint/"
        text="www.ageas.co.uk/make-a-complaint/home-complaint"
      />
      .
    </FaqP>
    <FaqP>
      <strong>Write to us:</strong>
      <br />
      Customer Services Adviser
      <br />
      Ageas House
      <br />
      Hampshire Corporate Park
      <br />
      Templars Way
      <br />
      Eastleigh
      <br />
      SO53 3YA
    </FaqP>
    <FaqP marginBottom={false}>Please include the following:</FaqP>
    <StandardUl>
      <li>Your full name and address</li>
      <li>Your contact details, including any daytime phone numbers</li>
      <li>Your policy or claim number</li>
      <li>Details of your complaint and if you’ve had any losses</li>
      <li>
        If you’ve already spoken to someone about your complaint - let us know
        their name and the date you got in touch
      </li>
      <li>What you’d like us to do to resolve your complaint</li>
    </StandardUl>
    <FaqP>
      <strong>Telephone:</strong>
      <br />
      Please give us a call using the number shown in your claim’s
      documentation.
    </FaqP>
  </>
);
