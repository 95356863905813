import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { ThemedErrorMessage } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { range } from 'ageasvalidation/module/schemas/Range';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import styled from 'styled-components';
import P from '../../StandardTags/P';
import VehicleDamageSelectorGrid from '../../Forms/VehicleDamageSelector/VehicleDamageSelectorGrid';
import YesOrNo, {
  yesOrNoSchema,
} from '../../Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardInput from '../../Forms/StandardInput/StandardInput';
import StandardDropdown, {
  getStandardDropdownSchema,
} from '../../Forms/StandardDropdown/StandardDropdown';
import statementRequestPropType, {
  injuriesPropType,
  sanitiseText,
} from '../hubStatementBuilderHelper';
import IfVisibleFormError from '../../Forms/IfVisibleFormError/IfVisibleFormError';
import validateField from '../../../helpers/validationHelper';
import { getErrorMessage } from '../../../helpers/errorMessages';
import stringArrayBasicValidator from '../../../helpers/validation/stringArrayRequired';
import yesNoToBoolean, { booleanToYesNo } from '../../../helpers/yesNoBoolean';
import parseIntOrUndefined from '../../../helpers/parseIntOrUndefined';
import uniqueKeyMapperFactory from '../../../helpers/uniqueKeyMakerFactory';

const FIELD_NAMES = {
  YOUR_VEHICLE_MOVING: 'yourVehicleMoving',
  YOUR_VEHICLE_SPEED: 'yourVehicleSpeedMph',
  FORCE_OF_IMPACT: 'forceOfImpact',
  YOUR_VEHICLE_IMPACT: 'yourVehicleImpact',
  YOUR_VEHICLE_DAMAGE: 'yourVehicleDamage',
  THEIR_VEHICLE_IMPACT: 'theirVehicleImpact',
  THEIR_VEHICLE_DAMAGE: 'theirVehicleDamage',
  YOUR_SEATBELT_TIGHTENED: 'yourSeatbeltTightened',
  IMPACT_TO_YOUR_PERSON: 'impactToYourPerson',
  YOU_WERE_INJURED: 'youInjured',
  THEY_COMPLAINED_OF_INJURY: 'theyComplainedOfInjury',
  THEY_HAD_NO_VISIBLE_INJURY: 'theyHadNoVisibleInjury',
  TIME_AT_SCENE_WITH_THEM: 'timeAtSceneWithThemMinutes',
  YOU_ACCEPT_THEY_COULD_HAVE_WHIPLASH: 'youAcceptTheyCouldHaveWhiplash',
  YOU_ACCEPT_THEY_COULD_HAVE_OTHER_INJURY: 'youAcceptTheyCouldHaveOtherInjury',
};

const IMPACT_FORCE = [
  { value: 'trivial', label: 'Trivial' },
  { value: 'minor', label: 'Minor' },
  { value: 'moderate', label: 'Moderate' },
  { value: 'severe', label: 'Severe' },
];

const HintUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  margin-right: 0.2em;
`;
const HintLi = styled.li`
  margin-left: 15px;
`;

const IMPACT_FORCE_HINT = (
  <HintUl>
    <HintLi>Trivial - The impact was barely noticeable, if at all</HintLi>
    <HintLi>
      Minor - The impact was felt by those within your vehicle, but not enough
      to move them
    </HintLi>
    <HintLi>
      Moderate - The impact was severe enough to move the driver and/or
      passengers within your vehicle
    </HintLi>
    <HintLi>
      Severe - Your vehicle was moved away from its direction of travel by the
      impact
    </HintLi>
  </HintUl>
);

const OtherInjuryHint = ({ tpName, injuries }) => {
  const { mapper } = uniqueKeyMapperFactory();
  return (
    <>
      The alleged injuries for {tpName} are:
      <HintUl>
        {!injuries.length && <HintLi>Unknown</HintLi>}

        {injuries?.map(({ type, location }) => (
          <HintLi key={mapper(type + location)}>
            {sanitiseText(type)} - {sanitiseText(location)}
          </HintLi>
        ))}
      </HintUl>
    </>
  );
};

OtherInjuryHint.propTypes = {
  tpName: PropTypes.string.isRequired,
  injuries: injuriesPropType.isRequired,
};

const DAMAGE_LEVEL = [
  { value: 'noVisible', label: 'No visible damage' },
  { value: 'minor', label: 'Minor' },
  { value: 'moderate', label: 'Moderate' },
  { value: 'severe', label: 'Severe' },
];
const DAMAGE_LEVEL_HINT = (
  <HintUl>
    <HintLi>Minor - There is scratching to paintwork</HintLi>
    <HintLi>
      Moderate - There is denting to one or two areas (each no longer than 12
      inches/30 cm)
    </HintLi>
    <HintLi>
      Severe - There is significant damage to over 50% of the damaged area
    </HintLi>
  </HintUl>
);

const localYesOrNoSchema = fieldName =>
  yesOrNoSchema(fieldName, 'Please confirm');

const schema = {
  ...localYesOrNoSchema(FIELD_NAMES.YOUR_VEHICLE_MOVING),
  [FIELD_NAMES.YOUR_VEHICLE_SPEED]: stringRequired(
    [FIELD_NAMES.YOUR_VEHICLE_SPEED],
    'Please confirm',
  )
    .concat(
      range(
        1,
        100,
        [FIELD_NAMES.YOUR_VEHICLE_SPEED],
        'Please enter a valid value, from 1 to 100',
      ),
    )
    .concat(
      regularExpression(
        /^\d{1,3}$/,
        [FIELD_NAMES.YOUR_VEHICLE_SPEED],
        'Please enter a valid value, from 1 to 100',
      ),
    ),
  ...getStandardDropdownSchema(FIELD_NAMES.FORCE_OF_IMPACT, 'Please confirm'),
  [FIELD_NAMES.YOUR_VEHICLE_IMPACT]: stringArrayBasicValidator(
    FIELD_NAMES.YOUR_VEHICLE_IMPACT,
    'Please confirm',
  ),
  ...getStandardDropdownSchema(
    FIELD_NAMES.YOUR_VEHICLE_DAMAGE,
    'Please confirm',
  ),
  [FIELD_NAMES.THEIR_VEHICLE_IMPACT]: stringArrayBasicValidator(
    FIELD_NAMES.THEIR_VEHICLE_IMPACT,
    'Please confirm',
  ),
  ...getStandardDropdownSchema(
    FIELD_NAMES.THEIR_VEHICLE_DAMAGE,
    'Please confirm',
  ),
  ...localYesOrNoSchema(FIELD_NAMES.YOUR_SEATBELT_TIGHTENED),
  ...localYesOrNoSchema(FIELD_NAMES.IMPACT_TO_YOUR_PERSON),
  ...localYesOrNoSchema(FIELD_NAMES.YOU_WERE_INJURED),
  ...localYesOrNoSchema(FIELD_NAMES.THEY_COMPLAINED_OF_INJURY),
  ...localYesOrNoSchema(FIELD_NAMES.THEY_HAD_NO_VISIBLE_INJURY),
  [FIELD_NAMES.TIME_AT_SCENE_WITH_THEM]: stringRequired(
    [FIELD_NAMES.TIME_AT_SCENE_WITH_THEM],
    'Please confirm',
  )
    .concat(
      range(
        0,
        999,
        [FIELD_NAMES.TIME_AT_SCENE_WITH_THEM],
        'Please enter a valid value, from 0 to 999',
      ),
    )
    .concat(
      regularExpression(
        /^\d{1,3}$/,
        [FIELD_NAMES.TIME_AT_SCENE_WITH_THEM],
        'Please enter a valid value, from 0 to 999',
      ),
    ),
  ...localYesOrNoSchema(FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_WHIPLASH),
  ...localYesOrNoSchema(FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_OTHER_INJURY),
};

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};
export const payloadMapperOut = values => {
  const yourVehicleSpeedMph =
    values[FIELD_NAMES.YOUR_VEHICLE_MOVING] !== 'yes'
      ? undefined
      : parseIntOrUndefined(values[FIELD_NAMES.YOUR_VEHICLE_SPEED]);

  return {
    ...values,

    yourVehicleMoving: yesNoToBoolean(values[FIELD_NAMES.YOUR_VEHICLE_MOVING]),
    yourSeatbeltTightened: yesNoToBoolean(
      values[FIELD_NAMES.YOUR_SEATBELT_TIGHTENED],
    ),
    impactToYourPerson: yesNoToBoolean(
      values[FIELD_NAMES.IMPACT_TO_YOUR_PERSON],
    ),
    youInjured: yesNoToBoolean(values[FIELD_NAMES.YOU_WERE_INJURED]),
    theyComplainedOfInjury: yesNoToBoolean(
      values[FIELD_NAMES.THEY_COMPLAINED_OF_INJURY],
    ),
    theyHadNoVisibleInjury: yesNoToBoolean(
      values[FIELD_NAMES.THEY_HAD_NO_VISIBLE_INJURY],
    ),
    youAcceptTheyCouldHaveWhiplash: yesNoToBoolean(
      values[FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_WHIPLASH],
    ),
    youAcceptTheyCouldHaveOtherInjury: yesNoToBoolean(
      values[FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_OTHER_INJURY],
    ),

    yourVehicleSpeedMph,
    timeAtSceneWithThemMinutes: parseIntOrUndefined(
      values[FIELD_NAMES.TIME_AT_SCENE_WITH_THEM],
    ),
  };
};
export const payloadMapperIn = values => {
  if (!values) {
    return values;
  }
  return {
    ...values,

    [FIELD_NAMES.YOUR_VEHICLE_MOVING]: booleanToYesNo(values.yourVehicleMoving),
    [FIELD_NAMES.YOUR_VEHICLE_SPEED]:
      values.yourVehicleSpeedMph?.toString() || undefined,
    [FIELD_NAMES.YOUR_SEATBELT_TIGHTENED]: booleanToYesNo(
      values.yourSeatbeltTightened,
    ),
    [FIELD_NAMES.IMPACT_TO_YOUR_PERSON]: booleanToYesNo(
      values.impactToYourPerson,
    ),
    [FIELD_NAMES.YOU_WERE_INJURED]: booleanToYesNo(values.youInjured),
    [FIELD_NAMES.THEY_COMPLAINED_OF_INJURY]: booleanToYesNo(
      values.theyComplainedOfInjury,
    ),
    [FIELD_NAMES.THEY_HAD_NO_VISIBLE_INJURY]: booleanToYesNo(
      values.theyHadNoVisibleInjury,
    ),
    [FIELD_NAMES.TIME_AT_SCENE_WITH_THEM]:
      values.timeAtSceneWithThemMinutes?.toString() || undefined,
    [FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_WHIPLASH]: booleanToYesNo(
      values.youAcceptTheyCouldHaveWhiplash,
    ),
    [FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_OTHER_INJURY]: booleanToYesNo(
      values.youAcceptTheyCouldHaveOtherInjury,
    ),
  };
};

const Causation = ({ statementRequest }) => {
  const { values } = useFormState({ subscription: { values: true } });
  const { tpName = '-claimant-' } = statementRequest;

  const injuriesTooltip = useMemo(
    () => (
      <OtherInjuryHint
        tpName={tpName}
        injuries={statementRequest.injuries || []}
      />
    ),

    [tpName, statementRequest.injuries],
  );

  return (
    <>
      <YesOrNo
        name={FIELD_NAMES.YOUR_VEHICLE_MOVING}
        title="Was your vehicle moving when you came into contact with the other vehicle?"
        validate={validateFields}
      />
      {values[FIELD_NAMES.YOUR_VEHICLE_MOVING] === 'yes' && (
        <StandardInput
          name={FIELD_NAMES.YOUR_VEHICLE_SPEED}
          label="What speed were you travelling in miles per hour?"
          validate={validateFields}
          maxLength={3}
          inputmode="numeric"
          type="text"
        />
      )}

      <StandardDropdown
        name={FIELD_NAMES.FORCE_OF_IMPACT}
        label="Describe the force of the impact when the vehicles hit"
        aria-label="Describe the force of the impact when the vehicles hit"
        options={IMPACT_FORCE}
        validate={validateFields}
        hint={IMPACT_FORCE_HINT}
        hintProps={{ isHintList: true }}
      />

      <P>
        What area(s) of your vehicle hit their vehicle? Please choose the
        area(s) below
      </P>

      <VehicleDamageSelectorGrid
        name={FIELD_NAMES.YOUR_VEHICLE_IMPACT}
        validate={validateFields}
        containerLabel="Your vehicle impacted areas"
      />

      <StandardDropdown
        name={FIELD_NAMES.YOUR_VEHICLE_DAMAGE}
        label="Describe the damage to your vehicle"
        aria-label="Describe the damage to your vehicle"
        options={DAMAGE_LEVEL}
        validate={validateFields}
        hint={DAMAGE_LEVEL_HINT}
      />

      <P>
        What area(s) of their vehicle did you hit? Please choose the area(s)
        below
      </P>

      <VehicleDamageSelectorGrid
        name={FIELD_NAMES.THEIR_VEHICLE_IMPACT}
        validate={validateFields}
        containerLabel="Their vehicle impacted areas"
      />

      <StandardDropdown
        name={FIELD_NAMES.THEIR_VEHICLE_DAMAGE}
        label="Describe the damage to their vehicle"
        aria-label="Describe the damage to their vehicle"
        options={DAMAGE_LEVEL}
        validate={validateFields}
        hint={DAMAGE_LEVEL_HINT}
      />

      <YesOrNo
        name={FIELD_NAMES.YOUR_SEATBELT_TIGHTENED}
        title="Did you feel your seatbelt tighten?"
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.IMPACT_TO_YOUR_PERSON}
        title="Did your body hit any part of the interior of your vehicle?"
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.YOU_WERE_INJURED}
        title="Were you injured?"
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.THEY_COMPLAINED_OF_INJURY}
        title={`Did ${tpName} complain of injury at the scene of the accident?`}
        validate={validateFields}
      />

      <StandardInput
        name={FIELD_NAMES.TIME_AT_SCENE_WITH_THEM}
        label={`How many minutes would you estimate you were at the accident scene with ${tpName}?`}
        validate={validateFields}
        maxLength={3}
        inputmode="numeric"
        type="text"
      />

      <YesOrNo
        name={FIELD_NAMES.THEY_HAD_NO_VISIBLE_INJURY}
        title={`Did ${tpName} appear free of any injury?`}
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_WHIPLASH}
        title={`Do you accept ${tpName} could have sustained a whiplash injury to their neck, back or shoulder?`}
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.YOU_ACCEPT_THEY_COULD_HAVE_OTHER_INJURY}
        title={`Do you accept ${tpName} could have injured any of the other parts of their body they are claiming for?`}
        validate={validateFields}
        hint={injuriesTooltip}
      />

      <IfVisibleFormError>
        <ThemedErrorMessage hasIcon>
          Please ensure all fields are completed correctly
        </ThemedErrorMessage>
      </IfVisibleFormError>
    </>
  );
};

Causation.propTypes = {
  statementRequest: statementRequestPropType.isRequired,
};

export default Causation;
