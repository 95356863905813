import React from 'react';
import FaqP from '../../FaqP';

export const stormClaimStatusTitle = 'How long will my storm claim take?';
export const stormClaimStatusContent = (
  <>
    <FaqP>
      This will depend on the circumstances of your claim. On average, storm
      claims are being usually dealt with within 23 days.
    </FaqP>
    <FaqP>
      However, this is just an estimate. Your claim could take a shorter or
      longer period of time.
    </FaqP>
  </>
);
