export const POLICY_DATA_AXIOS_ERROR = {
  VALIDATION_CODES: 'validationCodes',
  OTHER: 'other',
};

export const getPolicyDataContextError = contextData => {
  if (
    contextData.status === 'loaded' &&
    contextData?.data?.status?.validations?.length
  ) {
    return POLICY_DATA_AXIOS_ERROR.VALIDATION_CODES;
  }

  if (contextData.status === 'error') {
    return POLICY_DATA_AXIOS_ERROR.OTHER;
  }

  return undefined;
};

export const policyDataValid = contextData => {
  return (
    contextData.status === 'loaded' &&
    !getPolicyDataContextError(contextData) &&
    contextData?.data?.policyHolders?.length
  );
};
