import React from 'react';
import './App.css';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { dom, config } from '@fortawesome/fontawesome-svg-core';
import MainTheme from './assets/MainTheme';
import Routes from './routes/routes';
import CookieBar from './components/CookieBar/CookieBar';
import { AuthProvider } from './contexts/AuthContext';
import Config from './helpers/config';
import { ChatBotContextProvider } from './contexts/ChatBotContext';
import 'ageas-ui-themes/lib/fonts.css';

// TODO webfonts are being rendered client side only.
//  need to fix to render server side as well
const webFont = typeof window !== 'undefined' ? require('webfontloader') : null;

if (webFont != null) {
  webFont.load({
    google: {
      families: ['Nunito', 'Nunito:bold', 'Roboto'],
    },
    custom: {
      families: ['Proxima Nova', 'Proxima Nova W01 Black'],
      urls: ['ageas-ui-themes/lib/fonts.css'],
    },
  });
}

config.autoAddCss = false;
const FontAwesomeStyles = createGlobalStyle`
    ${dom.css()}
`;

function App() {
  return (
    <>
      <FontAwesomeStyles />
      <ThemeProvider theme={MainTheme}>
        <AuthProvider>
          {Config.client.cookie_banner.active === 'false' ? <CookieBar /> : ''}
          <ChatBotContextProvider>
            <Routes />
          </ChatBotContextProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
