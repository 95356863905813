import React from 'react';
import { ThemedHyperlink } from 'ageas-ui-components';
import NestedAccordion from '../../NestedAccordion/NestedAccordion';
import nestedAccordionBuilder from '../../NestedAccordion/nestedAccordionBuilder';
import {
  FinancialOmbudsmanTelLink,
  FinancialOmbudsmanTelLink2,
  MotorClaimsAbroadTelLink,
  MotorClaimsTelLink,
} from '../../../helpers/ageasTelephoneNumbers';
import AccordionBodyPadded from '../../NestedAccordion/AccordionBodyPadded';
import FaqP from '../FaqP';
import StandardUL from '../../StandardTags/UL';
import { checkFeatureSwitch } from '../../FeatureSwitch/FeatureSwitch';

const SVA_ENABLED = checkFeatureSwitch('FEATURE_SVA_JOURNEY_ENABLED', [
  'version1',
]);

export const claimQuestions = [
  {
    title: "I've just had an accident, what should I do?",
    content: (
      <AccordionBodyPadded>
        <FaqP>
          <strong>Don&apos;t worry</strong>, we are here to help. Here&apos;s
          what you should do next:
        </FaqP>
        <StandardUL>
          <li>Make sure you and your passengers are safe</li>
          <li>Don&apos;t accept liability until you have spoken to Ageas</li>
          <li>
            Try to swap details with the other people involved in the incident
            for example names, contact details and vehicle/insurer details
          </li>
          <li>
            Take pictures if you can and it is safe to do so, particularly of
            the vehicle damage and the position of vehicles in the road
          </li>
          <li>
            Report your claim online or by calling us on <MotorClaimsTelLink />
          </li>
        </StandardUL>
      </AccordionBodyPadded>
    ),
  },
  {
    title: "I've had an accident abroad, what should I do?",
    content: (
      <AccordionBodyPadded>
        Please call <MotorClaimsAbroadTelLink />
      </AccordionBodyPadded>
    ),
  },
  {
    title: 'Paying your excess',
    content: (
      <AccordionBodyPadded>
        You will be required to pay your excess as part of your claim. If your
        vehicle is repaired by a garage you will pay your excess directly to
        them. If your vehicle is damaged beyond repair, then we will deduct the
        excess from the settlement value. Please note, if you choose to use your
        own repairer, you may have to pay an additional excess. Full details of
        the excesses that apply may be found in your Statement of Fact.
      </AccordionBodyPadded>
    ),
  },
  {
    title: 'Will making a claim affect the cost of my policy?',
    content: (
      <AccordionBodyPadded>
        Making a claim can sometimes lead to an increase in renewal price, but
        this often is based on the individual circumstances of the incident.
      </AccordionBodyPadded>
    ),
  },
  {
    title: 'Will making a claim affect my No Claims Discount?',
    content: (
      <AccordionBodyPadded>
        If you make a claim then your No Claims Discount may be affected. If the
        other party has accepted liability and/or you have a protected No Claims
        Discount, then you may not lose your discount. No Claims Discount
        protection does not protect the overall price of your insurance policy.
        The price of your insurance policy may increase following an accident
        even if you were not at fault.
      </AccordionBodyPadded>
    ),
  },
  {
    title: 'How will Ageas repair my vehicle?',
    content: (
      <AccordionBodyPadded>
        We have a network of Ageas repairers who will take care of your vehicle,
        getting you back on the road as soon as possible.{' '}
      </AccordionBodyPadded>
    ),
  },
  {
    title: 'Will I get a courtesy vehicle?',
    content: (
      <AccordionBodyPadded>
        If you choose to use one of our approved repairers they will supply you
        with a courtesy vehicle whilst yours is being repaired. If your vehicle
        is not repairable then Ageas will not supply you with a courtesy
        vehicle.
      </AccordionBodyPadded>
    ),
  },
  {
    title: 'What claims can I make online?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          Instead of calling the claims team to report your claim, you can now
          report some claims online using our digital claims facility. The
          claims you can report online are:
        </FaqP>
        <StandardUL>
          <li>Damage caused by a pothole</li>
          <li>Damage caused by hitting an animal</li>
          <li>Damage caused by hitting a building or structure</li>
          <li>Loss of control due to road surface</li>
          <li>Damage caused by flood</li>
        </StandardUL>
        <FaqP>This is a quick and easy way to make your claim.</FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'What do I need to report a claim online?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          To get your online claim journey started, all you need is your policy
          number. This will be shown on your policy schedule or statement of
          fact. Your policy number will look like:
        </FaqP>
        <StandardUL>
          <li>GDCCA04867463</li>
          <li>PTCTA37813215</li>
          <li>GDVCA01009108</li>
        </StandardUL>
        <FaqP>
          We will ask you for key details about your claim, such as what
          happened and for you to provide evidence to support your claim.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'What can I expect when I make an online claim?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          As your insurer, we&apos;re here to help make claims as easy as
          possible. We understand that making a claim can be a worrying and
          stressful time for you.
        </FaqP>
        <FaqP>
          When you make a claim online, you will receive a decision on the
          outcome of your claim. If, for any reason, we are unable to provide
          you with an immediate decision, we&apos;ll clearly tell you what will
          happen next and how long it will take.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'Not sure whether to make a claim?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          You can find out what your motor policy covers you for by checking
          your policy booklet. Your policy booklet will also tell you what
          excesses may be applicable.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'Information relating to the repair of your vehicle',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          <strong>Manufacturer&apos;s warranty</strong>
          <br />
          If your vehicle is still within the warranty period, any repairs
          carried out by our approved repairer will not affect this on the basis
          that repair methods will follow those specified by the manufacturer.
        </FaqP>
        <FaqP>
          <strong>Guarantee</strong>
          <br />
          Our repairer will provide a 3-year guarantee on repairs.
        </FaqP>
        <FaqP>
          <strong>Replacement locks</strong>
          <br />
          If your claim is for replacement locks, there may be a monetary limit
          applied. Full details can be found in your policy booklet.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'What do we do to combat fraud?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          We realise the majority of our customers are honest but are also aware
          a small number provide false information. It is important we identify
          them to protect the majority from rising costs. There are databases
          through which information is shared with other companies. We also
          share information with the police.
        </FaqP>
        <FaqP>
          Please refer to your policy booklet for more details on how your
          information is shared.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'How do I make a complaint about my claim?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          If you haven&apos;t told us about your complaint yet, please get in
          touch with us and we&apos;ll do our best to put things right.
        </FaqP>
        <FaqP>There are 3 ways to make a complaint.</FaqP>
        <FaqP>
          <strong>Online:</strong>
          <br />
          Please submit your complaint using our online form which may be
          accessed through our website{' '}
          <ThemedHyperlink
            primary
            href="https://www.ageas.co.uk/make-a-complaint/car-complaint/"
            text="www.ageas.co.uk/make-a-complaint/car-complaint"
          />
          .
        </FaqP>
        <FaqP>
          <strong>Write to us:</strong>
          <br />
          Customer Services Adviser
          <br />
          Ageas House
          <br />
          Hampshire Corporate Park
          <br />
          Templars Way
          <br />
          Eastleigh
          <br />
          SO53 3YA
        </FaqP>
        <FaqP marginBottom={false}>Please include the following:</FaqP>
        <StandardUL>
          <li>Your full name and address</li>
          <li>Your contact details, including any daytime phone numbers</li>
          <li>Your policy or claim number</li>
          <li>Details of your complaint and if you&apos;ve had any losses</li>
          <li>
            If you&apos;ve already spoken to someone about your complaint - let
            us know their name and the date you got in touch
          </li>
          <li>What you&apos;d like us to do to resolve your complaint</li>
        </StandardUL>
        <FaqP>
          <strong>Telephone:</strong>
          <br />
          Please give us a call using the number shown in your claim&apos;s
          documentation.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'How will my complaint be dealt with?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          We&apos;ll try to resolve your complaint within three working days. If
          we can&apos;t do this, we&apos;ll write to you within five working
          days to either:
        </FaqP>
        <StandardUL>
          <li>
            Tell you what we&apos;ve tried to do to resolve your complaint
          </li>
          <li>
            Let you know we&apos;ve received your complaint and when you can
            expect a response from us
          </li>
          <li>Let you know who is dealing with your complaint</li>
        </StandardUL>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title:
      'Who can I contact if I am not satisfied with the response to my complaint?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          If you&apos;re not happy with how we have handled your complaint, or
          we haven&apos;t resolved it within eight weeks, you can take it up
          with the Financial Ombudsman Service through the methods detailed
          below.
        </FaqP>
        <FaqP>
          Just so you know, this needs to be within six months from the date of
          our final response. Using this service won&apos;t affect your legal
          rights.
        </FaqP>
        <FaqP>
          As a free, independent body set up by the Government, it&apos;s the
          Financial Ombudsman&apos;s job to resolve disputes between customers
          and companies in the UK. You can find out more at{' '}
          <ThemedHyperlink
            primary
            href="https://www.financial-ombudsman.org.uk"
            text="www.financial-ombudsman.org.uk"
          />{' '}
          and fill out its online, Word or PDF form.
        </FaqP>
        <FaqP>
          <strong>By phone:</strong>
          <br />
          Call <FinancialOmbudsmanTelLink /> or <FinancialOmbudsmanTelLink2 />
        </FaqP>
        <FaqP>
          <strong>By email:</strong>
          <br />
          <ThemedHyperlink
            primary
            href="mailto: complaint.info@financial-ombudsman.org.uk"
            text="complaint.info@financial-ombudsman.org.uk"
          />
        </FaqP>
        <FaqP>
          <strong>In writing:</strong>
          <br />
          Financial Ombudsman Service
          <br />
          Exchange Tower
          <br />
          London
          <br />
          E14 9SR
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'Can I go straight to the financial ombudsman?',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          You&apos;re able to go straight to the Financial Ombudsman Service,
          however, please note they will advise that if you are dissatisfied
          with our service, that you give us the opportunity to resolve your
          concerns in the first instance.
        </FaqP>
        <FaqP>
          If, following this, you&apos;re still not happy with how we have
          handled your complaint or we haven&apos;t resolved it within eight
          weeks, the Financial Ombudsman will investigate your case.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'If the vehicle is confirmed a total loss',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          This is when the damage to your vehicle is uneconomical to repair. We
          have a dedicated team who will discuss settlement with you. The
          settlement figure will be the market value of the vehicle immediately
          before the incident and will reflect the cost of replacing it with one
          of a similar age, type and condition.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'If you receive correspondence from a third party',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          If you receive any correspondence from the other party involved or
          their representatives, you can forward this straight to us quoting
          your claim reference number. Please forward the correspondence to{' '}
          <ThemedHyperlink
            primary
            href="mailto: motorclaims@ageas.co.uk"
            text="motorclaims@ageas.co.uk"
          />
          .
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'Authentication code not received',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          We apologise that you are having trouble making a claim online. We
          take the protection of our customers&apos; information very seriously
          and always apply appropriate levels of security.
        </FaqP>
        <FaqP>
          In order for us to verify who you are, when you log in, we will send
          you an authentication code to either a mobile phone number or email
          address, which you provided when taking out your policy with us. If
          your details are not recognised, we unfortunately cannot send you a
          code to verify your identity.
        </FaqP>
        <FaqP>
          If you have not received an authentication code to complete security
          checks, you will need to contact our claims team to report your claim
          on <MotorClaimsTelLink />.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
  {
    title: 'Details not recognised e.g. policy number, date of birth',
    content: (
      <AccordionBodyPadded>
        <FaqP>
          If your details are not recognised, we unfortunately cannot send you
          the authentication code to complete necessary security checks. Below
          are common reasons why your details may not be recognised and advice
          on things to check.
        </FaqP>
        <FaqP>
          <strong>Invalid policy number</strong> - If the details you have
          provided do not match our records, please check you have entered all
          characters correctly and try again. If you think they are correct,
          please call us on <MotorClaimsTelLink />.
        </FaqP>
        <FaqP>
          <strong>Invalid date of birth</strong> - Please make sure you are
          entering the date of birth as shown on your policy schedule or
          statement of fact.
        </FaqP>
        <FaqP>
          If you are still having difficulty reporting your claim online, please
          contact our claims team on <MotorClaimsTelLink /> where one of our
          agents will be able to assist you.
        </FaqP>
      </AccordionBodyPadded>
    ),
    include: SVA_ENABLED,
  },
];

export const typeQuestions = [
  {
    title: 'I have a question about my claim',
    content: nestedAccordionBuilder(claimQuestions),
    contentHeader: 'My question is about',
    titlePaddingLeft: '44px',
    defaultOpen: false,
  },
];
export const typeQuestionsDefault = typeQuestions.find(
  q => q?.defaultOpen,
)?.title;

const MotorFAQAccordion = ({ ...props }) => {
  return (
    <NestedAccordion defaultSelection={typeQuestionsDefault} {...props}>
      {nestedAccordionBuilder(typeQuestions, undefined)}
    </NestedAccordion>
  );
};
export default MotorFAQAccordion;
