import React, { useEffect, useContext, useState, useRef } from 'react';
import { Form, useForm, useFormState } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ThemedButton, ThemedErrorMessage } from 'ageas-ui-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PageSectionV2 } from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import { MotorClaimContextNew } from '../../helpers/motorClaimHelperNew';
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import { genericErrorCallUsMotor } from '../../helpers/errorMessages';
import {
  ButtonsBottomContainer,
  ThemedTitleSpaced,
  TwoButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import SpyOnValues from '../../helpers/SpyOnValues/SpyOnValues';
import updateContinuableMotorClaimData from '../../helpers/updateContinuableMotorClaimData';
import MotorClaimBreadcrumbNew from '../../helpers/MotorClaimBreadcrumbNew';
import ContinuableMotorClaimFormNavActions from '../../components/ContinuableMotorClaimFormNavActions/ContinuableMotorClaimFormNavActions';
import ThemedLinkButton from '../../components/ThemedLinkButton/ThemedLinkButton';
import IfVisibleFormError from '../../components/Forms/IfVisibleFormError/IfVisibleFormError';
import YesNoPopup from './YesNoPopup';
import STAGES, { getStageNames } from './motorClaimNewHelpers';

const fieldUpdateMutator = ([fieldName, value], state, utils) => {
  utils.changeValue(state, fieldName, () => value);
};

const mutators = { ...arrayMutators, fieldUpdateMutator };

export const Card = ({ children, cardRef, ...rest }) => {
  return (
    <div ref={cardRef}>
      <StandardPanel padding="23px 21px" panelPadding="0" primary {...rest}>
        {children || <></>}
      </StandardPanel>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  cardRef: PropTypes.shape({}),
};

Card.defaultProps = {
  children: undefined,
  cardRef: undefined,
};

export const ExtractFormStateApi = ({ children }) => {
  const form = useForm();
  const formState = useFormState();

  return children({ form, ...formState });
};

ExtractFormStateApi.propTypes = {
  children: PropTypes.func.isRequired,
};

const ServerErrorComponent = () => {
  return (
    <ThemedErrorMessage hasIcon>{genericErrorCallUsMotor()}</ThemedErrorMessage>
  );
};
const ConcurrentUpdateErrorComponent = () => {
  return (
    <ThemedErrorMessage hasIcon>
      This claim has been updated on another device. Please click the Back
      button to return to the previous page
    </ThemedErrorMessage>
  );
};

const ValidationErrorComponent = () => (
  <IfVisibleFormError>
    <ThemedErrorMessage hasIcon>
      Please ensure all fields are completed correctly
    </ThemedErrorMessage>
  </IfVisibleFormError>
);

/**
 * Wrap useContext(MotorClaimContextNew), add new fields to
 * Extract current, unvalidated, and combined (unvalidated || current)
 * state for specified page section
 * @param {*} pageSectionName of page
 * @returns context
 */
export const useMotorClaimContextNew = pageSectionName => {
  const contextOut = useContext(MotorClaimContextNew);
  const { dataState } = contextOut;
  const pageStateValid = dataState?.claimEntryData?.[pageSectionName];
  const pageStateUnvalidated =
    dataState?.claimEntryData?.unvalidated?.[pageSectionName];
  const pageStateCombined = pageStateUnvalidated || pageStateValid;
  return {
    ...contextOut,
    pageStateValid,
    pageStateUnvalidated,
    pageStateCombined,
  };
};

const HISTORY_PAGE = '/motorclaim/history';

const GenericMotorFormPage = ({
  renderForm = true,
  spinner = true,
  showContinue = true,
  serverError = false,
  unsavedData = false,
  expectClaimReference = true,
  pageSectionName,
  breadcrumbId,
  pageTitle,
  onSubmitCallback = () => false,
  onFormValuesChange = () => {},
  nextPage,
  previousPage,
  children,
  confirmModalOnSubmit = false,
  submit = false,
  stage = STAGES.INPUT.name,
}) => {
  const [render, setRender] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const {
    pageStateCombined,
    pageStateUnvalidated,
    dataState,
    updateDataState,
  } = useMotorClaimContextNew(pageSectionName);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const initialFormDataRef = useRef(null);
  const [serverErrorInternal, setServerErrorInternal] = useState(false);
  const [concurrentUpdateError, setConcurrentUpdateError] = useState(false);
  const historyHook = useHistory(); // Use for navigation to next breadcrumb
  const [unsavedDataInternal, setUnsavedDataInternal] = useState(true);
  const [showConfirmPopup, setShowConfirmPopup] = useState(undefined);

  const { claimReference } = dataState?.claimDetails || {};
  const { meta } = dataState?.claimEntryData || {};

  // When renderForm becomes true (so parents have completed context setup)
  // Determine what to initialise form from, and initial state of
  // unsavedDataInternal (unsaved=true if initialised from unvalidated)
  if (renderForm && !initialFormDataRef.current) {
    initialFormDataRef.current = {
      pageState: { ...pageStateCombined },
      initialIsUnvalidated: !!pageStateUnvalidated,
    };
  }
  useEffect(() => {
    if (renderForm) {
      setUnsavedDataInternal(initialFormDataRef.current.initialIsUnvalidated);
      setInitialised(true);
    }
  }, [renderForm]);

  const createAxiosCancelToken = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosCancelToken.current;
  };

  const resetServerError = () => {
    setServerErrorInternal(false);
    setConcurrentUpdateError(false);
  };

  const onFormValuesChangeInternal = ([values]) => {
    onFormValuesChange(values);
    resetServerError();
    setUnsavedDataInternal(true);
  };

  const onActualSubmit = async values => {
    resetServerError();
    setShowConfirmPopup(undefined);
    setIsLoading(true);
    const cancelToken = createAxiosCancelToken().token;

    const response = await updateContinuableMotorClaimData(
      pageSectionName,
      values,
      dataState,
      updateDataState,
      cancelToken,
      submit,
    );
    if (!onSubmitCallback(response, values)) {
      if (response) {
        setIsLoading(false);
        if (response.success) {
          setUnsavedDataInternal(false);
          if (nextPage) {
            historyHook.push(nextPage);
          }
        } else if (response.errorType === 'concurrent') {
          setConcurrentUpdateError(true);
        } else if (response.error) {
          setServerErrorInternal(true);
        }
      }
    }
  };

  const onSubmit = values => {
    if (confirmModalOnSubmit) {
      setShowConfirmPopup({
        onConfirmYes: () => {
          onActualSubmit(values);
        },
      });
      return;
    }
    onActualSubmit(values);
  };

  // On page load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      !meta ||
      meta.stage !== stage ||
      (expectClaimReference && !claimReference)
    ) {
      historyHook.push('/motorclaim/history');
      return undefined;
    }
    setRender(true);

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  if (!render) {
    return null;
  }

  return (
    <>
      {!!(isLoading || spinner) && <LoadingSpinner />}
      <StandardPageWrapper lob="motor">
        {breadcrumbId !== undefined && (
          <MotorClaimBreadcrumbNew activeItem={breadcrumbId} />
        )}
        <PageSectionV2>
          <Card title={pageTitle}>
            {claimReference && (
              <ThemedTitleSpaced small>
                Claim number: <strong>{claimReference}</strong>
              </ThemedTitleSpaced>
            )}
            {renderForm && initialised && (
              <Form
                initialValues={initialFormDataRef.current.pageState}
                mutators={mutators}
                onSubmit={onSubmit}
                subscription={{ values: true, pristine: true }}
              >
                {({ handleSubmit, values, pristine }) => (
                  <form method="post" onSubmit={handleSubmit}>
                    <ContinuableMotorClaimFormNavActions
                      unsaved={unsavedDataInternal || unsavedData}
                      values={values}
                      section={pageSectionName}
                    />

                    {!!showConfirmPopup && (
                      <YesNoPopup
                        {...showConfirmPopup}
                        titleDescription="The details you have entered so far cannot be changed after this point. Are you sure you want to continue?"
                        onConfirmNo={() => {
                          setShowConfirmPopup(undefined);
                        }}
                      />
                    )}

                    <SpyOnValues
                      values={[values]}
                      enable={!pristine}
                      callback={onFormValuesChangeInternal}
                    />

                    {children}

                    <ValidationErrorComponent />
                    {(serverErrorInternal || serverError) && (
                      <ServerErrorComponent />
                    )}
                    {concurrentUpdateError && (
                      <>
                        <ConcurrentUpdateErrorComponent />
                        <ButtonsBottomContainer>
                          <ThemedLinkButton secondary to={HISTORY_PAGE}>
                            Back
                          </ThemedLinkButton>
                        </ButtonsBottomContainer>
                      </>
                    )}
                    {!concurrentUpdateError && (
                      <TwoButtonsBottomContainer alignRight={!previousPage}>
                        {!!previousPage && (
                          <ThemedLinkButton
                            secondary
                            inverted
                            to={previousPage}
                          >
                            Back
                          </ThemedLinkButton>
                        )}
                        {showContinue && (
                          <ThemedButton secondary type="submit">
                            Continue
                          </ThemedButton>
                        )}
                      </TwoButtonsBottomContainer>
                    )}
                  </form>
                )}
              </Form>
            )}
          </Card>
        </PageSectionV2>
      </StandardPageWrapper>
    </>
  );
};

export default GenericMotorFormPage;

GenericMotorFormPage.propTypes = {
  renderForm: PropTypes.bool,
  spinner: PropTypes.bool,
  showContinue: PropTypes.bool,
  serverError: PropTypes.bool,
  unsavedData: PropTypes.bool,
  expectClaimReference: PropTypes.bool,
  children: PropTypes.node,
  pageSectionName: PropTypes.string.isRequired,
  breadcrumbId: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  onSubmitCallback: PropTypes.func,
  onFormValuesChange: PropTypes.func,
  nextPage: PropTypes.string,
  previousPage: PropTypes.string,
  confirmModalOnSubmit: PropTypes.bool,
  submit: PropTypes.bool,
  stage: PropTypes.oneOf(getStageNames()),
};

GenericMotorFormPage.defaultProps = {
  renderForm: undefined,
  spinner: undefined,
  showContinue: undefined,
  serverError: undefined,
  unsavedData: undefined,
  expectClaimReference: undefined,
  children: undefined,
  breadcrumbId: undefined,
  onSubmitCallback: undefined,
  onFormValuesChange: undefined,
  nextPage: undefined,
  previousPage: undefined,
  confirmModalOnSubmit: undefined,
  submit: undefined,
  stage: undefined,
};
