import Joi from '@hapi/joi';

const validKeys = [];

export const regularExpressionOptional = (regex, keys = validKeys, message) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .optional()
      .regex(regex)
      .error(() => message),
  );
};

export default regularExpressionOptional;
