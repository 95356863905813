import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

/**
 * @description: Validate the field is a valid value and if matches the array required value,
 * the associated array is not empty
 * @param hasItemsRef Field reference that should have a value
 * @param itemsRef reference to array of items which should be populated if hasItemRef
 *                 value matches arrayRequiredValue
 * @param message to display if the hasItemsRef field is not one of the valid option
 * @param constraintMessage the message to display if the hasItems array is empty and hasItemRef
 *                          value matches arrayRequiredValue
 * @param validHasItemsValues array of valid values for hasItemsRef field
 * @param arrayRequiredValue value that means the array must not be empty
 * @succeeds when the hasItemsRef is populated with a valid option and either doesn't match
 *           the arrayRequiredValue or matches the arrayRequiredValue and the array is not empty
 * @fails when hasItemsRef is not a valid option or the array is empty when hasItemsRef
 *        is equal to the arrayRequiredValue
 */
export const dependentArray = (
  hasItemsRef,
  itemsRef,
  message = messages.SELECT_OPTION,
  constraintMessage = messages.ARRAY_SHOULD_HAVE_ENTRIES,
  validHasItemsValues = ['yes', 'no'],
  arrayRequiredValue = 'yes',
) => {
  return Joi.object().keys({
    [hasItemsRef]: Joi.any()
      .required()
      .valid(validHasItemsValues)
      .error(() => message),
    [itemsRef]: Joi.when(hasItemsRef, {
      is: arrayRequiredValue,
      then: Joi.array()
        .min(1)
        .required()
        .error(() => constraintMessage),
      otherwise: Joi.allow(),
    }),
  });
};

export default dependentArray;
