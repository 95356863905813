import { createContext } from 'react';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import arrayMinSize from '../../helpers/validation/arrayMinSize';
import { yesOrNoSchema } from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import isBooleanTrue from '../../helpers/validation/isBooleanTrue';
import { getStandardInputRequiredSchema } from '../../components/Forms/StandardInput/StandardInput';

export const FIELD_NAMES = {
  VEHICLE_IS_DAMAGED: 'vehicleIsDamaged',
  VEHICLE_AREA_DAMAGE_LEVELS: 'damageLevels',
  VEHICLE_UNDERSIDE_IS_DAMAGED: 'undersideIsDamaged',
  VEHICLE_UNDERSIDE_DAMAGE_LEVEL: 'undersideDamageLevel',
  HAS_MECHANICAL_DAMAGE: 'hasMechanicalDamage',
  MECHANICAL_DAMAGES: 'mechanicalDamages',
  HAS_PREVIOUS_DAMAGE: 'hasPreviousDamage',
  PREVIOUS_DAMAGE_DETAILS: 'previousDamageDetails',
  AIRBAGS_TRIGGERED: 'airbagsTriggered',
  WAS_DRIVEN_AWAY: 'wasDrivenAway',
  VEHICLE_IS_DRIVABLE: 'isDrivable',
  CONFIRMATION: 'confirmation',
};

export const MECHANICAL_DAMAGE_TYPES = [
  { value: 'sharps', label: 'There are protruding or sharp edges' },
  {
    value: 'exhaust',
    label: 'The exhaust is rattling, blowing or not present',
  },
  { value: 'wheels', label: 'Tyres/Wheels' },
  { value: 'lights', label: 'Lights not working' },
  { value: 'doorsWindows', label: 'Doors/Windows not working' },
  { value: 'glass', label: 'Glass is shattered' },
  {
    value: 'wingMirror',
    label: "Wing mirror (driver's side) damaged or not working correctly",
  },
  {
    value: 'bootBonnet',
    label: 'Boot/Bonnet not opening or not working correctly or insecure',
  },
  { value: 'steering', label: 'Steering/Power steering not working correctly' },
  {
    value: 'dashboardLights',
    label: 'Any dash lights illuminated/changed since the incident',
  },
  {
    value: 'hornWipers',
    label: 'Horn/Windscreen wipers/Seat belts not working',
  },
  { value: 'leakingFluids', label: 'Leaking fluids' },
  { value: 'waterIngress', label: 'Water ingress' },
  {
    value: 'noneApply',
    label: 'None of these apply',
    singleOption: true,
  },
];

const localYesOrNoSchema = fieldName =>
  yesOrNoSchema(fieldName, 'Please confirm');
const localRequiredSchema = fieldName => ({
  [fieldName]: stringRequired([fieldName], 'Please confirm'),
});

export const schema = {
  ...localYesOrNoSchema(FIELD_NAMES.VEHICLE_IS_DAMAGED),
  ...localYesOrNoSchema(FIELD_NAMES.VEHICLE_UNDERSIDE_IS_DAMAGED),
  ...localRequiredSchema(FIELD_NAMES.VEHICLE_UNDERSIDE_DAMAGE_LEVEL),
  ...localYesOrNoSchema(FIELD_NAMES.HAS_MECHANICAL_DAMAGE),

  [FIELD_NAMES.MECHANICAL_DAMAGES]: arrayMinSize(
    [FIELD_NAMES.MECHANICAL_DAMAGES],
    1,
    'Please confirm',
  ),

  ...localYesOrNoSchema(FIELD_NAMES.HAS_PREVIOUS_DAMAGE),
  ...getStandardInputRequiredSchema(
    FIELD_NAMES.PREVIOUS_DAMAGE_DETAILS,
    'Please enter details',
  ),
  ...localYesOrNoSchema(FIELD_NAMES.AIRBAGS_TRIGGERED),
  ...localYesOrNoSchema(FIELD_NAMES.WAS_DRIVEN_AWAY),
  ...localYesOrNoSchema(FIELD_NAMES.VEHICLE_IS_DRIVABLE),
  [FIELD_NAMES.CONFIRMATION]: isBooleanTrue(
    FIELD_NAMES.CONFIRMATION,
    'Please confirm',
  ),
};

export const VehicleDamageModalContext = createContext({});
