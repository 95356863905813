import React from 'react';
import FaqP from '../../FaqP';

export const buildingInspectionTitle =
  'Will someone come out to look at the damage?';
export const buildingInspectionContent = (
  <FaqP marginBottom={false}>
    When you report a claim to us, we will do all we can to establish the level
    of damage and determine the best option for your circumstances. We may
    instruct one of our approved contractors to contact you directly and make
    arrangements for the repairs or if the damage is substantial, we will send
    out an expert to manage the claim and talk you through the best course of
    action.
  </FaqP>
);
