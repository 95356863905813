import React, { useState } from 'react';
import {
  ThemedAdornedNumericInput,
  ThemedErrorMessage,
  ThemedLabel,
} from 'ageas-ui-components';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';

// For documentation see:
// /docs/components/Forms/StandardCurrencyInput/StandardCurrencyInput.md

const StandardCurrencyInput = ({
  id,
  name,
  label,
  secondaryLabel,
  validate,
  type,
  children,
  onChange,
  fieldProps,
  ...props
}) => {
  const [elementId] = useState(
    id || `StandardCurrencyInput-${name}-${_uniqueId()}`,
  );

  const onInputChange = (event, input) => {
    input.onChange(event);
    onChange(event);
  };

  return (
    <FieldStyled name={name} validate={validate} type={type} {...fieldProps}>
      {({ input, meta }) => (
        <>
          {label && (
            <ThemedLabel fieldName={elementId}>
              {label}
              {secondaryLabel && (
                <div>
                  <small>{secondaryLabel}</small>
                </div>
              )}
            </ThemedLabel>
          )}
          <FieldInnerBlockContainer marginTop="-6px">
            <ThemedAdornedNumericInput
              {...props}
              inputId={elementId}
              {...input}
              onChange={event => {
                onInputChange(event, input);
              }}
              validInput={{
                isValid: !(meta.error && meta.touched),
              }}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
              hasError={meta.error && meta.touched}
            />
          </FieldInnerBlockContainer>
          {meta.error && meta.touched && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardCurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  direction: PropTypes.string,
  adornText: PropTypes.string,
  validate: PropTypes.func,
  children: PropTypes.node,
  onChange: PropTypes.func,
  fieldProps: PropTypes.shape({}),
};

StandardCurrencyInput.defaultProps = {
  id: undefined,
  label: '',
  secondaryLabel: undefined,
  type: undefined,
  direction: 'left',
  adornText: '£',
  validate: () => {},
  children: undefined,
  onChange: () => {},
  fieldProps: {},
};

export default StandardCurrencyInput;
