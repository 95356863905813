import React from 'react';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import PropTypes from 'prop-types';
import StandardInput from '../Forms/StandardInput/StandardInput';

export const getTheftCrimeValidationSchema = theftFieldName => {
  return {
    [theftFieldName]: stringRequired(
      [theftFieldName],
      'Please enter a valid crime reference number',
    ).concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(
        /\S/,
        [theftFieldName],
        'Please enter a valid crime reference number',
      ),
    ),
  };
};

const TheftCrimeNumberField = props => {
  const { name, validate = () => {} } = props;
  const THEFT_CRIME_NUMBER_FIELDNAME = name;

  return (
    <StandardInput
      name={THEFT_CRIME_NUMBER_FIELDNAME}
      label="Please tell us the crime reference number"
      small
      maxLength="30"
      validate={validate}
      aria-label="theft crime number"
    />
  );
};

export default TheftCrimeNumberField;

TheftCrimeNumberField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
};

TheftCrimeNumberField.defaultProps = {
  name: undefined,
  validate: undefined,
};
