import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import { withContext } from '../../contexts/AuthContext';

import { brand } from '../../../data/whitelabel.config';
import config from '../../helpers/config';
import { PortalIntegrationContext } from '../../contexts/PortalIntegrationContext';
import { consoleError } from '../../helpers/consoleLog';

const Theme = getTheme(brand);
const PageContainer = styled.div`
  max-width: 1100px;
  padding-left: 25px;
  padding-right: 25px;
  margin: auto;
`;

const TARGET_MOTORCLAIMS_KNOCKOUT = 'motorClaimKnockout';
const TARGET_MOTORCLAIMS_ENTRY1 = 'makeMotorClaim';

const getRedirectPath = target => {
  switch (target) {
    case TARGET_MOTORCLAIMS_KNOCKOUT:
      return '/carinsurance';
    case TARGET_MOTORCLAIMS_ENTRY1:
      return '/motorclaim/history';
    default:
      return '/carinsurance';
  }
};

const getDataFromReceivedCookies = () => {
  const tokenData = {
    token: cookie.load('received_token'),
    referer: cookie.load('received_referer'),
    target: cookie.load('received_target') || TARGET_MOTORCLAIMS_KNOCKOUT,
  };
  cookie.remove('received_token', { path: '/' });
  cookie.remove('received_referer', { path: '/' });
  cookie.remove('received_target', { path: '/' });
  return tokenData;
};

const getAxiosHandlerWithToken = token => {
  // check that the token is valid;
  const http = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  /* No way to test this */
  /* istanbul ignore next */
  http.interceptors.request.use(
    axiosConfig => {
      const requestConfig = { ...axiosConfig };
      requestConfig.headers.Authorization = token;
      return requestConfig;
    },

    error => {
      return Promise.reject(error);
    },
  );
  return http;
};

const buildLoginLink = target => {
  let targetUrlComponent = '';
  if (target) {
    targetUrlComponent = `&target=${encodeURIComponent(target)}`;
  }
  return `${config.client.portalLoginUrl}${targetUrlComponent}`;
};

const PortalLandingPage = ({ isLoggedIn, setNewLogin = () => {} }) => {
  const [validity, setValidity] = useState();
  const [target, setTarget] = useState(null);
  const [cookieLoaded, setCookieLoaded] = useState(false);
  const portalIntegrationContext = useContext(PortalIntegrationContext);

  const onValidToken = (tokenData, userServiceResponse) => {
    setValidity(true);
    portalIntegrationContext.setReferer(tokenData.referer);
    setNewLogin(tokenData.token, 'portal');
    if (tokenData.target) {
      setTarget(tokenData.target);
    }
    if (userServiceResponse?.data?.userName) {
      portalIntegrationContext.setUserName(userServiceResponse.data.userName);
    }
    setCookieLoaded(true);
  };

  const onInvalidToken = tokenData => {
    setValidity(false);
    if (tokenData.target) {
      setTarget(tokenData.target);
    }
  };

  useEffect(() => {
    const tokenData = getDataFromReceivedCookies();
    const http = getAxiosHandlerWithToken(tokenData.token);

    // check if the token is valid, and save it as a token cookie if it is
    http
      .get(config.client.get_credentials_from_user_principles)
      .then(response => onValidToken(tokenData, response))
      .catch(e => {
        consoleError('axios error', e);
        onInvalidToken(tokenData);
      });
  }, []);

  const checkValidity = () => {
    if (
      typeof validity === 'undefined' ||
      (validity === true && (!target || !cookieLoaded))
    ) {
      return 'Validating login, please wait';
    }
    if (validity === true && isLoggedIn) {
      if (target) {
        return <Redirect to={getRedirectPath(target)} />;
      }
    }
    return (
      <>
        Sorry, there was an error processing your login. To try again please{' '}
        <a href={buildLoginLink(target)}>click&nbsp;here</a>.
      </>
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      <BrandHeader />
      <PageContainer>
        <div>{checkValidity()}</div>
      </PageContainer>
      <BrandFooter />
    </ThemeProvider>
  );
};

export default withContext(PortalLandingPage);

PortalLandingPage.propTypes = {
  // Provided by withContext
  isLoggedIn: PropTypes.bool,
  setNewLogin: PropTypes.func,
};

PortalLandingPage.defaultProps = {
  // Provided by withContext
  isLoggedIn: false,
  setNewLogin: undefined,
};
