import React from 'react';

export const localStorageAccidentalDamagePropertyName =
  'accidentalDamageClaimFormState';
export const localStorageAccidentalLossPropertyName =
  'accidentalLossClaimFormState';
export const localStorageTheftPropertyName = 'theftClaimFormState';

export const personalLossDataContext = {
  policyDetails: 'policyDetails',
  personalLossData: 'personalLossData',
  itemType: 'itemType',
  userContactNumber: 'userContactNumber',
  amount: 'amount',
  settlementAmount: 'settlementAmount',
  detailsCompleted: 'detailsCompleted',
  glazingEstimateInvoiceReceipt: 'glazingEstimateInvoiceReceipt',
  uploadedDocuments: 'uploadedDocuments',
};

export const AccidentalDamageContext = React.createContext({});
export const AccidentalLossContext = React.createContext({});
export const TheftContext = React.createContext({});
