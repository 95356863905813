import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const coverageEOWClaimQuestion =
  'What is covered by escape or leakage of water?';
const CoverageEOWClaimContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        Depending on the level of cover you have selected, which will be shown
        in your policy documentation e.g. Buildings, Contents or both, cover is
        provided for the loss or damage caused as a result of water leaking from
        things such as heating or drainage installation, washing machine or
        dishwasher.
      </FaqP>

      <FaqP>
        Your policy will also cover you for the cost of locating and gaining
        access to the leak if required. However it is possible that limits will
        apply. For more information, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Leaking or Escape of water in the home',
              "What is 'Trace & Access'? What does it cover?",
            ])
          }
        >
          &apos;What is &apos;Trace &amp; Access&apos;? What does it
          cover?&apos;
        </ButtonLink>
      </FaqP>
      <FaqP>
        For full details on what is covered for an escape or leakage of water,
        please refer to your policy booklet.
      </FaqP>
    </>
  );
};

export default CoverageEOWClaimContent;

CoverageEOWClaimContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
CoverageEOWClaimContent.defaultProps = {
  nestingControl: undefined,
};
