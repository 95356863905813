import React from 'react';
import { Input, ErrorMessage } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import {
  ukBankAccount,
  ukSortCode,
} from 'ageasvalidation/lib/schemas/UKBankAccount';
import ThemedLabel from 'ageas-ui-components/lib/components/ThemedLabel/ThemedLabel';
import PropTypes from 'prop-types';
import FieldStyled from '../FieldStyled/FieldStyled';

export const getBankDetailsValidationSchema = (
  accountNameFieldName,
  accountSortCodeFieldName,
  accountNumberFieldName,
) => {
  return {
    [accountNameFieldName]: stringRequired(
      [accountNameFieldName],
      'Please enter an account name',
    ).concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(
        /\S/,
        [accountNameFieldName],
        'Please enter an account name',
      ),
    ),
    [accountSortCodeFieldName]: ukSortCode(
      [accountSortCodeFieldName],
      'Please enter a valid sort code',
    ),
    [accountNumberFieldName]: ukBankAccount(
      [accountNumberFieldName],
      'Please enter a valid account number',
    ),
  };
};

const BankDetails = props => {
  const { name = [], validate = () => {} } = props;
  const [ACCOUNT_NAME, ACCOUNT_SORT_CODE, ACCOUNT_NUMBER] = name;

  const filterNonNumericKeys = event => {
    // Check whether the char is a numeric char
    if (event.charCode >= 48 && event.charCode <= 57) return true;
    event.preventDefault();
    // If not numeric, dismiss it
    const e = event;
    e.returnValue = false;
    return false;
  };

  return (
    <>
      <FieldStyled name={ACCOUNT_NAME} validate={validate}>
        {({ input, meta }) => (
          <>
            <ThemedLabel fieldName={input.name}>Account name</ThemedLabel>
            <Input
              size="small"
              maxLength="40"
              id={input.name}
              aria-label="account name"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
      <FieldStyled name={ACCOUNT_SORT_CODE} validate={validate}>
        {({ input, meta }) => (
          <>
            <ThemedLabel fieldName={input.name}>Sort code</ThemedLabel>
            <Input
              size="small"
              maxLength="6"
              id={input.name}
              aria-label="sort code"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
              onKeyPress={filterNonNumericKeys}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
      <FieldStyled name={ACCOUNT_NUMBER} validate={validate}>
        {({ input, meta }) => (
          <>
            <ThemedLabel fieldName={input.name}>Account number</ThemedLabel>
            <Input
              size="small"
              maxLength="8"
              id={input.name}
              aria-label="account number"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
              onKeyPress={filterNonNumericKeys}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
    </>
  );
};

BankDetails.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string),
  validate: PropTypes.func,
};

BankDetails.defaultProps = {
  name: undefined,
  validate: undefined,
};

export default BankDetails;
