import styled from 'styled-components';
import { ThemedIcon } from 'ageas-ui-components';
import media from '../../../components/MediaQuery/MediaQueryMin';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.benefitsBanner.background};
  padding: 0.5em;
  ${media.desktop`
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: space-evenly;
`}
`;

const BenefitsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
`;

const Text = styled.p`
  color: ${props => props.theme.benefitsBanner.text};
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  display: none;
  ${media.desktop`
    display: block;
`}
`;

const BoldText = styled(Text)`
  font-weight: ${props => props.theme.weight.bold};
  font-size: 1em;
  display: block;
`;

const Icon = styled(ThemedIcon).attrs(props => ({
  theme: props.theme,
  color: props.theme.benefitsBanner.iconColor,
}))`
  margin-right: 0.3em;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${media.desktop`
  flex-direction: row;
`}
`;

export { Container, Text, BoldText, BenefitsWrapper, Icon, InnerContainer };
