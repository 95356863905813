import { caseConverter } from './caseConverter';

const MOTOR_CLAIM_STATUSES_BASIC = {
  OPEN: {
    code: 'O',
    description: 'Open',
  },
  CLOSED: {
    code: 'C',
    description: 'Closed',
  },
  INCOMPLETE: {
    code: 'I',
    description: 'Incomplete',
  },
  CANCELLED: {
    code: 'X',
    description: 'Cancelled',
  },
  PROCESSING: {
    code: '',
    description: 'Processing',
  },
  NOT_SUBMITTED: {
    code: '',
    description: 'Not submitted',
  },
};

export const MOTOR_CLAIM_STATUS_LONG_CODES = Object.keys(
  MOTOR_CLAIM_STATUSES_BASIC,
);

export const MOTOR_CLAIM_STATUS_CODES = MOTOR_CLAIM_STATUS_LONG_CODES.map(
  status => MOTOR_CLAIM_STATUSES_BASIC[status].code,
);

// Add other descriptions
// lower:             lower case
// upper:             UPPER CASE
// capitaliseFirst:   Capitalise first word
// capitalise:        Capitalise All Words
MOTOR_CLAIM_STATUS_LONG_CODES.forEach(statusCode => {
  const status = MOTOR_CLAIM_STATUSES_BASIC[statusCode];
  status.lower = caseConverter(status.description, 'lower');
  status.upper = caseConverter(status.description, 'upper');
  status.capitaliseFirst = caseConverter(status.description, 'capitaliseFirst');
  status.capitalise = caseConverter(status.description, 'capitalise');
});

export const MOTOR_CLAIM_STATUSES = MOTOR_CLAIM_STATUSES_BASIC;

export const MOTOR_CLAIM_STATUSES_LIST = Object.keys(MOTOR_CLAIM_STATUSES).map(
  statusCode => MOTOR_CLAIM_STATUSES_BASIC[statusCode],
);

export const getMotorClaimStatusByCode = code =>
  MOTOR_CLAIM_STATUSES_LIST.find(status => status.code === code);

export const getStatusDesc = status => {
  return (
    getMotorClaimStatusByCode(status)?.capitalise ||
    MOTOR_CLAIM_STATUSES.OPEN.capitalise
  );
};
