import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import { PageContainer } from './StandardPageWrapperStyle';

const Theme = getTheme(brand);

const StandardPageWrapper = ({
  lob,
  children,
  headerLogoutButton = true,
  headerClaimButton = false,
  thirdParty = false,
}) => {
  return (
    <PageContainer>
      <ThemeProvider theme={Theme}>
        <BrandHeader
          open
          isMenuBar
          fixedWidthMode
          logoutPosition
          maxOuterWidth="1122px"
          lob={lob}
          logoutButton={headerLogoutButton}
          claimButton={headerClaimButton}
        />
        <main>{children}</main>
        <BrandFooter thirdParty={thirdParty} />
      </ThemeProvider>
    </PageContainer>
  );
};

export default StandardPageWrapper;

StandardPageWrapper.propTypes = {
  children: PropTypes.node,
  lob: PropTypes.string,
  headerLogoutButton: PropTypes.bool,
  headerClaimButton: PropTypes.bool,
  thirdParty: PropTypes.bool,
};

StandardPageWrapper.defaultProps = {
  children: undefined,
  lob: undefined,
  headerLogoutButton: undefined,
  headerClaimButton: undefined,
  thirdParty: undefined,
};
