import React from 'react';
import P from '../../components/StandardTags/P';

export const EOW_CAUSE_LIST = [
  {
    value: 'appliance',
    label: 'Appliance',
  },
  {
    value: 'frozenPipe',
    label: 'Burst Pipe - Frozen',
  },
  {
    value: 'pipeLeak',
    label: 'Pipe Leak',
  },
  {
    value: 'waterTank',
    label: 'Water Tank',
  },
  {
    value: 'unknownCause',
    label: 'Unknown Cause',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const EOW_SECTION_CODE_LIST = [
  {
    value: 'buildings',
    label: 'Buildings',
  },
  {
    value: 'contents',
    label: 'Contents',
  },
  {
    value: 'both',
    label: 'Both',
  },
];

export const EOW_DISCOVERED_BY_LIST = [
  {
    value: 'policyholder',
    label: 'You',
  },
  {
    value: 'neighbour',
    label: 'Neighbour',
  },
  {
    value: 'relative',
    label: 'Relative',
  },
  {
    value: 'friend',
    label: 'Friend',
  },
  {
    value: 'waterBoard',
    label: 'Water Board',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const EOW_LEAKING_FOR_LIST = [
  {
    value: '1Day',
    label: '1 day',
  },
  {
    value: 'lessThan3Days',
    label: 'Less than 3 days',
  },
  {
    value: 'lessThan1Week',
    label: 'Less than 1 week',
  },
  {
    value: 'moreThan1Week',
    label: 'More than 1 week',
  },
];

export const EOW_WATER_TYPE_LIST = [
  {
    value: 'clean',
    label: 'Clean',
  },
  {
    value: 'dirty',
    label: 'Dirty',
  },
];

export const EOW_DAMAGED_AREAS_LIST = [
  {
    value: 'bathroom',
    label: 'Bathroom',
  },
  {
    value: 'bedroom',
    label: 'Bedrooms',
  },
  {
    value: 'loft',
    label: 'Loft',
  },
  {
    value: 'kitchen',
    label: 'Kitchen',
  },
  {
    value: 'livingRoom',
    label: 'Lounge/Living Room',
  },
  {
    value: 'study',
    label: 'Study',
  },
  {
    value: 'hall',
    label: 'Hallway',
  },
  {
    value: 'garage',
    label: 'Garage',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const EOW_OWNERSHIP_LIST = [
  {
    value: 'own',
    label: 'Own',
  },
  {
    value: 'rent',
    label: 'Rent',
  },
];

export const EOW_HELP_TEXT = {
  sectionCode: (
    <>
      <P>
        Buildings - the main structure of your home, including any fixtures and
        fittings, garden walls, gates, decking, paths, drives, patios, permanent
        swimming pools built of brick, stone or concrete, permanently fixed hot
        tubs, solar panels, fully enclosed outbuildings and hard tennis courts.
      </P>
      <P>
        Contents - items and valuables within your home such as your furniture,
        carpets, kitchen appliances, jewellery and entertainment systems.
      </P>
    </>
  ),
  stillLeaking: (
    <P>
      PLEASE NOTE repairs to the pipework, or other parts of the water or
      heating system are rarely covered. To check your level of cover please
      refer to your policy schedule.
    </P>
  ),
  thirdPartyFault: (
    <P>
      A third party is a person or group other than you or anyone who
      permanently resides at the address.
    </P>
  ),
  ownership: (
    <P>
      PLEASE NOTE if rent is selected, we will require a copy of your signed
      &apos;Tenancy agreement&apos;. You will have the opportunity to select and
      upload this later.
    </P>
  ),
};
