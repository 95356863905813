import React from 'react';
import PropTypes from 'prop-types';
import JewelleryItemForm from './JewelleryItemForm';
import StandardPopup from '../StandardPopup/StandardPopup';
import { PageContainer } from '../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';

const JewelleryModalForm = ({
  initialValues,
  onSubmit,
  onCancel,
  specifiedItems = [],
  allSpecifiedItems = [],
}) => {
  return (
    <StandardPopup>
      <PageContainer>
        <JewelleryItemForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          specifiedItems={specifiedItems}
          allSpecifiedItems={allSpecifiedItems}
        />
      </PageContainer>
    </StandardPopup>
  );
};

export default JewelleryModalForm;

JewelleryModalForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  specifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  allSpecifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
};

JewelleryModalForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  onCancel: undefined,
  specifiedItems: undefined,
  allSpecifiedItems: undefined,
};
