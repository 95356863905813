import { mapToValueLabel } from './documentUploadTypes';

// The order here is the dropdown order
export const documentUploadTypesListAll = [
  {
    code: 'MOTOR_TP_ESTIMATE',
    description: 'Estimate',
    bulletDescription: 'Estimates',
    category: 'motorTPEstimate',
    notificationCode: 'ME',
    correspondenceType: '2G',
    motorTPHubUploadable: true,
  },
  {
    code: 'MOTOR_TP_IMAGES',
    description: 'Photos',
    category: 'motorTPImages',
    notificationCode: 'MP',
    correspondenceType: '2I',
    motorTPHubUploadable: true,
  },
  {
    code: 'MOTOR_TP_V5',
    description: 'V5 document',
    category: 'motorTPV5',
    notificationCode: 'MV',
    correspondenceType: '25',
    motorTPHubUploadable: true,
  },
  {
    code: 'MOTOR_TP_LICENSE',
    description: 'Licence',
    category: 'motorTPLicense',
    notificationCode: 'ML',
    correspondenceType: '2L',
    motorTPHubUploadable: true,
  },
  {
    code: 'MOTOR_TP_RECEIPT_INVOICE',
    description: 'Receipt or invoice',
    category: 'motorTPReceiptInvoice',
    bulletDescription: 'Receipts',
    sentenceDescription: 'Receipt',
    notificationCode: 'MR',
    correspondenceType: '2V',
    motorTPHubUploadable: true,
  },
];

const buildDocumentUploadTypesObject = (types = documentUploadTypesListAll) => {
  const out = {};
  types.forEach(type => (out[type.code] = type));
  return out;
};

export const MOTOR_TP_HUB_UPLOADABLE_TYPES = documentUploadTypesListAll.filter(
  data => data.motorTPHubUploadable,
);
export const MOTOR_TP_HUB_UPLOADABLE_TYPES_LIST =
  MOTOR_TP_HUB_UPLOADABLE_TYPES.map(mapToValueLabel);
const MOTOR_TP_HUB_UPLOADABLE_TYPES_OBJECT = buildDocumentUploadTypesObject(
  MOTOR_TP_HUB_UPLOADABLE_TYPES,
);

export default MOTOR_TP_HUB_UPLOADABLE_TYPES_OBJECT;

export const buildCorrespondenceTypeMapping = types => {
  const out = {};
  types.forEach(data => {
    if (data.correspondenceType) {
      out[data.correspondenceType] = data;
    }
  });
  return out;
};
export const MOTOR_TP_HUB_DOCUMENT_TYPES_BY_CORRESPONDENCE_TYPE =
  buildCorrespondenceTypeMapping(MOTOR_TP_HUB_UPLOADABLE_TYPES);
