/* istanbul ignore file */
import React, { useContext } from 'react';
import { withContext, AuthContext } from './AuthContext';

const AuthContextTest1 = ({ ...props }) => {
  // eslint-disable-next-line no-console
  console.log('AuthContextTest1 props', props);
  return (
    <div>
      <div>hi</div>
      <div>{JSON.stringify(props)}</div>
    </div>
  );
};

export default withContext(AuthContextTest1);

export const AuthContextTest2 = () => {
  const authData = useContext(AuthContext);
  // eslint-disable-next-line no-console
  console.log('AuthContextTest2 useContext', authData);
  return (
    <div>
      <div>hi</div>
      <div>{JSON.stringify(authData)}</div>
    </div>
  );
};
