import React from 'react';
import FaqP from '../../FaqP';

export const financialOmbudsmanTitle =
  'Can I go straight to the Financial Ombudsman?';
export const financialOmbudsmanContent = (
  <>
    <FaqP>
      You are able to go straight to the Financial Ombudsman Service, however,
      please note they will advise that if you are dissatisfied with our
      service, that you give us the opportunity to resolve your concerns in the
      first instance.
    </FaqP>
    <FaqP>
      If, following this, you&apos;re still not happy with how we have handled
      your complaint or we haven&apos;t resolved it within eight weeks, the
      Financial Ombudsman will investigate your case.
    </FaqP>
  </>
);
