import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

const validKeys = ['webReference'];

/**
 * @description: validates a web reference (00000-00000-00000 or 000000-0000-000000)
 * @param keys: fieldnames to validate
 * @param message: message to display when validation fails.
 */
export const webReference = (
  keys = validKeys,
  message = messages.INVALID_WEBREFERENCE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex.webReference)
      .required()
      .error(() => message),
  );
};

export default webReference;
