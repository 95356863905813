import React from 'react';
import FaqP from '../../FaqP';
import {
  SupplierCCG,
  SupplierVirtus,
} from '../../../../helpers/ageasTelephoneNumbers';

export const stormProgressClaimTitle =
  'How do I get an update on the progress of my claim?';
export const stormProgressClaimContent = (
  <FaqP>
    To make your claims experience as easy as possible, our specialist suppliers
    have full authority to manage claims without the need to refer back to us
    for approval. If we&apos;ve appointed one to manage your claim, and you
    would like an update, please call them directly in the first instance and
    they will be able to assist you. For CCG, call <SupplierCCG />; for Virtus,
    call <SupplierVirtus />.
  </FaqP>
);
