import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const freezerFoodClaimNotCoveredTitle =
  'Why would my damage to fridge/freezer food claim not be covered?';
export const freezerFoodClaimNotCoveredContent = (
  <>
    <FaqP marginBottom={false}>
      Reasons why your claim might not be covered include:
    </FaqP>
    <StandardUl>
      <li>
        Damage caused by your power supply being cut off by the supplier (as a
        result of no payment of bills, for example)
      </li>
      <li>Damage caused deliberately by you or your family</li>
      <li>Any amount over the limit shown in your policy documentation</li>
    </StandardUl>
    <FaqP>
      Please check your policy booklet for specific policy exclusions.
    </FaqP>
  </>
);
