/* istanbul ignore file */
import React, { useState } from 'react';
import { getTheme } from 'ageas-ui-themes';
import { ThemeProvider } from 'styled-components';
import { UploadDocuments } from 'ageas-ui-components';
import { hashFile } from 'ageas-ui-components/lib/components/UploadDocuments/helpers';
import { brand } from '../../../data/whitelabel.config';
import HomeClaimsWrapper from '../../views/HomeClaimsWrapper/HomeClaimsWrapper';
import { PageSectionBare } from '../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';
import { getReceiptUploaderText } from './ReceiptUploaderConfig';

const Theme = getTheme(brand);

const UploaderExample = () => {
  const [postResult, setPostResult] = useState(true);

  const axiosPost = (url, payload) => {
    /* eslint-disable no-console */
    console.log('axios posting with', url, payload.document.data, payload);
    if (
      postResult &&
      !payload.document.filename.toUpperCase().includes('TEST_ERROR')
    ) {
      const buffer = Buffer.from(payload.document.data, 'base64');
      const fileHash = hashFile(buffer);
      return Promise.resolve({
        data: {
          filename: payload.document.filename,
          fileHash,
        },
      });
    }
    return Promise.reject(new Error('errtst'));
  };

  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <button
          type="button"
          onClick={() => {
            setPostResult(!postResult);
          }}
        >
          Axios returns: {postResult ? 'ok' : 'error'}
        </button>
        <PageSectionBare>
          <UploadDocuments
            axiosPost={axiosPost}
            axiosPostURL="http://fake.fake.fake"
            axiosFinally={() => {
              /* eslint-disable no-console */
              console.log('I am finally func');
            }}
            onSuccessfulUpload={(file, files) => {
              /* eslint-disable no-console */
              console.log('I am onSuccessfulUpload func', file, files);
            }}
            payloadKeys={{ claimReference: 'abcd1234' }}
            {...getReceiptUploaderText('claimReference', 'peril description')}
            fileSizeLimit={10}
            brand="ageas"
          />
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};
export default UploaderExample;
