import styles, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import TelephoneLink from '../../components/TelephoneLink/TelephoneLink';
import ThemedAnchorButton from '../../components/ThemedAnchorButton/ThemedAnchorButton';
import media from '../../components/MediaQuery/MediaQueryMin';

const ComponentTitle = styles.div`
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 5px;
  font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 28px;
    padding-bottom: 5px;
    margin: 0 0 1rem;
    padding: 0;
    color: #2c2c2c;
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
  }
`;

const StaticText = styles.div`
  padding-left: 10px;
  font-size: 15px;
  margin-bottom:10px;
`;

const TextAlign = styles.div`
  text-align: -webkit-center;
  text-align: -moz-center;
`;
const HeaderComponent = styles.div`
  padding-top: 25px;
  max-width: 875px;
  @media only screen and (max-width: 767px) {
    padding: 50px 0px;
    padding-left: 0px;
  }
`;
const ComponentText = styles.div`
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 25px;
  font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 18px;
    padding-bottom: 25px;
    margin: 0 0 1rem;
    padding: 0;
    color: #2c2c2c;
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
  }
`;

export const PageContainer = styles.div`
  font-size: 19px;
  font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
`;

const ComponentIcon = styles.div`
  padding: 0px 60px;
  max-width: 1300px;
  @media only screen and (max-width: 990px) {
    max-width: 540px;
    padding: 0px 12px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0px;
    margin-left: -10px;
    margin-right: 10px;
    max-width: 100%;
  }
`;

const DeskView = styles.div`
  @media only screen and (max-width: 767px) {
    display:none;
  }
`;

const MobView = styles.div`
  display:none;
  @media only screen and (max-width: 767px) {
    display:block;
  }
`;

const PanelAlignment = styles.div`
  &:nth-child(1) {
    display:inline-flex;
    margin-right: 15px;
    margin-bottom:15px;
    @media only screen and (max-width: 1346px) {
      display:grid;
      width: auto;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }
  &:nth-child(2) {
  display:inline-flex;
  margin-right: 15px;
  margin-bottom:15px;
  @media only screen and (max-width: 1346px) {
    display:grid;
    width: auto;
    margin-bottom: 15px;
    margin-left: 15px;
    }
  }
  &:nth-child(3) {
    display:inline-flex;
    margin-right: 15px;
    margin-bottom:15px;
    @media only screen and (max-width: 1346px) {
      display:grid;
      width: auto;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 1346px) {
    display:block;
  }
`;

const WholePanelWrapper = styles.div`
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
`;

const WholePanel = styles.div`
padding-top: 16px;
display:flex;
justify-content: left;
flex-direction: row;
margin-right: 0px;
flex-wrap: wrap;

& > * {
  margin-right: 15px;
}
& > *:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 1346px) {
//  display:block;
  //padding: 0px 100px;
  padding-left: 100px;
  padding-right: 100px;
  align-items: center;
  flex-direction: column;

  & > * {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media only screen and (max-width: 990px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const BannerPanel = styles.div`
width: 100%;
flex-shrink: 0;
margin: 0px 0px 14px 8px;
`;

const PanelItemSide = styles.div`
  width:318px;
  @media only screen and (max-width: 1346px) {
    width: 100%;
  }
`;
const PanelItemCentre = styles.div`
  width:640px;
  @media only screen and (max-width: 1346px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const AnswerContent = styles.div`
  padding-left: 21px;
`;

const PhoneContent = styles.div`
  padding-left: 21px;
  font-size: 15px;
  font-weight: 900;
`;

const FaqContainer = styles.div`
  padding: 0px;
  padding-top: 15px;
`;

const NeedHelpInitial = styles.div`
 text-align:left
`;

const ContentWrapper = styles.div`
padding: 20px;
background-color: #EBEBEB;
`;

export const PageSectionBare = styles.div`
  margin: 0px auto;
  padding: 0px;
  width: 100%;
  max-width: 1100px;
`;
export const PageSection = styles(PageSectionBare)`
  padding: 25px 50px;
`;

export const TopText = styles.div`
  background-color: #ebebeb;
  width: 100%;
`;
export const TopTextSection = styles(PageSection)`
  max-width: 730px;
  text-align: left;
  ${media.desktop`
    padding-top: 50px;
    padding-bottom: 50px;
  `};
  ${media.tablet`
    text-align: center;
  `};
`;
export const TopTextTitle = styles.div`
  background-color: #ebebeb;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0.25em;
  ${media.tablet`
    font-size: 36px;
  `};
`;

export const NavLink = styles(Link)`
  color:#208DE9;
  text-decoration: none;
`;

export const NavLinkButtonIconRight = styles(ThemedAnchorButton)`
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  margin-bottom: 0px;
`;
export const NavLinkButtonIconContainer = styles.span`
  padding: 0px;
  font-size: 2em;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 6px;
`;

export const ReferralText = styles(PageSection)`
  background-color: #fafafa;
  padding: 10px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const TextContainer = styles(PageSection)`
  padding: 0px 40px;
  display: flex;
`;

export const ClaimButtonStyle = styles.div`
  width:100%;
  padding: 15px 10px;
`;

export const ContentHolder = styles.div`
  padding: 15px 10px;
  width: 100%;
`;

export const ExcessContentHolder = styles(ContentHolder)`
  font-size: 17px;
  font-weight: bold;
`;

export const IconContainer = styles.div`
  padding: 3px;
  padding-right: 2px;
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
`;

export const StormIcon = styles(IconContainer)`
  margin-right: 12px;
`;

export const PurpleBox = styles(PageSection)`
  margin-top: 20px;
  background-color: #8E419C;
  background: linear-gradient(#8E419C, #5A1466);
  color: white;
`;

export const TitleText = styles.h2`
  font-size: 22px;
  ${props =>
    props.primary &&
    css`
      color: #8e419c;
    `}
  ${props =>
    props.white &&
    css`
      color: white;
    `}
  margin-bottom: 0.25em;
`;

export const PhoneNumbersSection = styles.div`
  margin-top: 20px;
  background-color: #fafafa;
  width: 100%;
`;
export const PhoneNumbersTextContainer = styles(PageSection)`
  padding-bottom: 0px;
`;
export const PhoneNumbersContainer = styles.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  margin: auto;
  width: 100%;
  max-width: 1100px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px
`;
export const PhoneNumberBoxStyle = styles.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #EBEBEB;
  width: 340px;
  height: 200px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
`;
export const PhoneNumber = styles(TelephoneLink)`
  font-size: 30px;
  color: black;
  &:not(:hover) {
    text-decoration: none;
  }
`;

export {
  ComponentTitle,
  TextAlign,
  HeaderComponent,
  ComponentText,
  ComponentIcon,
  DeskView,
  MobView,
  PanelAlignment,
  WholePanelWrapper,
  WholePanel,
  BannerPanel,
  AnswerContent,
  PhoneContent,
  FaqContainer,
  NeedHelpInitial,
  ContentWrapper,
  PanelItemCentre,
  PanelItemSide,
  StaticText,
};
