import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import config from '../../helpers/config';
import { PolicyContext } from '../../helpers/policyContextHelper';
import EclaimsRoute from '../EclaimsRoute';

import ClaimsHistoryMotor from '../../views/ClaimsHistory/ClaimsHistoryMotor';
import {
  MotorClaimContextNew,
  localStoragePropertyName,
} from '../../helpers/motorClaimHelperNew';
import ContinueMotorClaim from '../../views/MotorClaimNew/ContinueMotorClaim';
import NewMotorClaimInitialise from '../../views/MotorClaimNew/NewMotorClaimInitialise';
import MotorIncidentDetails from '../../views/MotorClaimNew/MotorIncidentDetails';
import MotorContactDetails from '../../views/MotorClaimNew/MotorContactDetails';
import MotorYourDetails from '../../views/MotorClaimNew/MotorYourDetails';
import MotorVehicleDamageDetails from '../../views/MotorClaimNew/MotorVehicleDamageDetails';
import MotorClaimOutcome from '../../views/MotorClaimNew/MotorClaimOutcome';

const PORTAL_OTP_AUTH = ['portal', 'otp'];
const motorClaimsPrivateProps = {
  private: true,
  authTypes: PORTAL_OTP_AUTH,
  lobs: 'motor',
};

const MotorClaimEntryRoutesNew = () => {
  return (
    <DataContextProvider
      Context={MotorClaimContextNew}
      localStoragePropertyName={localStoragePropertyName}
      localStorageExpiryDays={config.client.localStorageExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <DataContextProvider Context={PolicyContext}>
        <Switch>
          <EclaimsRoute
            exact
            path="/motorclaim/history"
            component={ClaimsHistoryMotor}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/initialise"
            component={NewMotorClaimInitialise}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/continue"
            component={ContinueMotorClaim}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/incidentdetails"
            component={MotorIncidentDetails}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/contactdetails"
            component={MotorContactDetails}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/yourdetails"
            component={MotorYourDetails}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/vehicledamage"
            component={MotorVehicleDamageDetails}
            {...motorClaimsPrivateProps}
          />
          <EclaimsRoute
            exact
            path="/motorclaim/newclaim/claimoutcome"
            component={MotorClaimOutcome}
            {...motorClaimsPrivateProps}
          />
          <NotFoundRoute component={NotFoundErrorView} />
        </Switch>
      </DataContextProvider>
    </DataContextProvider>
  );
};

export default MotorClaimEntryRoutesNew;
