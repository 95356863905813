import styled, { css } from 'styled-components';
import { ThemedTextAreaInput, ThemedButton } from 'ageas-ui-components';
import ageasLogo from '../../../data/assets/brandLogo/ageas_nest_RGB.svg';
import media from '../MediaQuery/MediaQuery';
/*
error text and border: #EE5623

try and do the border

Button itself as clickable retry

error messgae is:
	Failed to send, click message to retry
entire error message is link, underline on hover and focus
*/

const LEFT_COLOUR = 'black';
const LEFT_BACKGROUND_COLOUR = 'rgb(235, 235, 235)';
const LEFT_BADGE_BACKGROUND_COLOUR = 'white';
export const RIGHT_COLOUR = 'white';
const RIGHT_BACKGROUND_COLOUR = 'rgb(142, 65, 156)';
const ERROR_COLOUR = '#EE5623';

const BODY_SIZES = {
  small: '400px',
  medium: '550px',
  large: '700px',
};
// Controls when MessagesInner applies width 95%
// Prevents long unbroken messages from pushing past the boundary of the
// messages pane
const INNER_SIZES_BREAK = {
  small: '500px',
  medium: '650px',
  large: '800px',
};

export const LoadMoreButton = styled(ThemedButton)`
  min-width: 130px;
  ${media.phone`
    min-width: 100%;
  `}
`;
export const SendButton = styled(ThemedButton)`
  margin: 0;
  min-width: ${({ minWidth }) => minWidth || '120px'};
  ${media.phone`
    min-width: 100%;
  `}
`;

export const MessengerContainer = styled.div`
  gap: 10px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const SendMessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-end;
`;
export const MessageTextArea = styled(ThemedTextAreaInput)`
  background-color: white;
  font-family: inherit;
  flex-grow: 1;
  margin: 0;
  width: auto;
  resize: none;
`;

export const MessagesContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 50px;
  background-color: white;
  border-bottom: 1px solid black;
  ${({ borderType }) =>
    borderType === 'box' &&
    css`
      border: 1px solid #b3b3b3;
    `}

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: auto;

  /* relative required for children offsetTop to be relative to this */
  position: relative;

  font-size: 16px;
`;

export const MessageContainer = styled.div`
  position: relative;
  min-height: 1em;
  margin-top: 0px;
  margin-bottom: 44px; /* 44 provides extra offset for badge */
  text-align: left;
  flex-shrink: 0;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 90%;
  ${({ left }) =>
    left &&
    css`
      align-self: flex-start;
      margin-left: 44px;
      margin-right: 14px;
    `}
  ${({ left }) =>
    !left &&
    css`
      margin-left: 14px;
      margin-right: 44px;
    `}
`;

export const StatusContainer = styled.div`
  text-align: right;
  font-weight: bold;
`;

export const MessageInner = styled.div`
  color: ${RIGHT_COLOUR};
  ${({ status }) =>
    status === 'sendError' &&
    css`
      cursor: pointer;
    `}
  ${({ size }) =>
    INNER_SIZES_BREAK[size] &&
    css`
      @media (max-width: ${INNER_SIZES_BREAK[size]}) {
        width: 90%;
      }
    `}
`;

export const MessageBody = styled.div`
  position: relative;
  min-width: 100px;
  overflow-wrap: break-word;
  ${({ size }) =>
    BODY_SIZES[size] &&
    css`
      max-width: ${BODY_SIZES[size]};
    `}

  background-color: ${RIGHT_BACKGROUND_COLOUR};
  border-radius: 1em;
  padding: 20px;

  ${({ left }) =>
    left &&
    css`
      color: ${LEFT_COLOUR};
      background-color: ${LEFT_BACKGROUND_COLOUR};
      padding-left: 25px;
    `}
`;

export const SubText = styled.div`
  font-size: 18px;
  width: 100%;
`;
export const SubSubText = styled.div`
  font-size: 16px;
  width: 100%;
`;
export const SubHeader = styled.h2`
  font-size: 18px;
  width: 100%;
`;

export const MiscContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const ErrorContainer = styled(MiscContainer)`
  color: ${ERROR_COLOUR};
  font-weight: bold;
  padding: 3px;
`;

export const InfoContainer = styled(MiscContainer)`
  color: black;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

export const MoreButtonContainer = styled(MiscContainer)`
  padding: 3px;
`;

export const Badge = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  bottom: -30px;
  border-radius: 50%;
  text-align: center;
  background-color: ${RIGHT_BACKGROUND_COLOUR};
  right: -30px;
  ${({ left }) =>
    left &&
    css`
      color: transparent;
      background-color: ${LEFT_BADGE_BACKGROUND_COLOUR};
      background-image: url(${ageasLogo});
      background-position: center;
      background-repeat: no-repeat;
      right: auto;
      left: -30px;
    `}
`;
