import PropTypes from 'prop-types';
import React from 'react';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardDropdown from '../../../components/Forms/StandardDropdown/StandardDropdown';
import {
  ANIMAL_TYPE_LIST,
  incidentDetailsFields,
} from '../motorIncidentDetailsHelper';

const IncidentDamageAnimalFields = ({ values, validateFields }) => {
  return (
    <>
      <StandardDropdown
        name={incidentDetailsFields.ANIMAL_TYPE}
        label="Please tell us the type of animal"
        aria-label="Please tell us the type of animal"
        options={ANIMAL_TYPE_LIST}
        small
        validate={validateFields}
      />
      {values[incidentDetailsFields.ANIMAL_TYPE] === 'dogHorseFarmAnimal' && (
        <>
          <YesOrNo
            name={incidentDetailsFields.ANIMAL_OWNER_KNOWN}
            title="Do you know who the animal owner is?"
            validate={validateFields}
          />
          {values[incidentDetailsFields.ANIMAL_OWNER_KNOWN] === 'yes' && (
            <>
              <YesOrNo
                name={incidentDetailsFields.ANIMAL_OWNER_RESPONSIBLE}
                title="Was the animal owner the responsible party?"
                hint="Responsible party is a person or group that is responsible for the incident"
                validate={validateFields}
              />
              {values[incidentDetailsFields.ANIMAL_OWNER_RESPONSIBLE] ===
                'yes' && (
                <InvolvedParty
                  name={incidentDetailsFields.ANIMAL_OWNER_DETAILS}
                  header="Please confirm the name, address and contact details of the animal owner"
                  values={values}
                  validate={validateFields}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IncidentDamageAnimalFields;

IncidentDamageAnimalFields.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  validateFields: PropTypes.func,
};

IncidentDamageAnimalFields.defaultProps = {
  validateFields: undefined,
};
