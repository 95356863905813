import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AccordionSection, FieldDisplay } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { MotorFormContext } from '../../helpers/motorFormStateHelper';
import ThemedLinkButton from '../ThemedLinkButton/ThemedLinkButton';
import { buildFullName } from '../../helpers/buildFullName';
import { hideThirdParty } from '../../helpers/SingleDriveCircumstanceHelper';
import {
  EditWrapper,
  SummaryContent,
  FieldFlexColumn,
  EditFlexColumn,
} from './Summary.style';
import SummaryIncompleteError from './SummaryIncompleteError';

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -9px;
`;
const IndentStyling = styled.div`
  margin-left: 17px;
`;
const InvolvedPartiesDetailsSummary = ({ title, theming }) => {
  const { dataState } = useContext(MotorFormContext);
  const [pageFormState, setPageFormState] = useState({});
  const [displayBody, setDisplayBody] = useState(true);
  const [incidentDetailPageFormState, setIncidentDetailPageFormState] =
    useState({});

  const toggleBody = () => {
    setDisplayBody(!displayBody);
  };
  useEffect(() => {
    setPageFormState({ ...dataState?.involvedPartiesDetail });
    setIncidentDetailPageFormState({
      ...dataState?.motorIncidentDetail,
    });
  }, []);

  const renderDriverName = tpDriverFields => {
    if (!tpDriverFields?.length) {
      return 'Unknown';
    }
    return <span>{buildFullName(tpDriverFields[0])}</span>;
  };

  const renderFields = () => {
    return (
      <>
        <SubTitle>Your vehicle</SubTitle>
        <IndentStyling>
          <FieldDisplay title="Number of passengers">
            {pageFormState.phHasPassengers || 'Unknown'}
          </FieldDisplay>
        </IndentStyling>
        <br />
        {!hideThirdParty(incidentDetailPageFormState.circumstanceCode) && (
          <>
            <SubTitle>Their vehicle</SubTitle>
            <IndentStyling>
              <FieldDisplay title="Registration">
                {pageFormState.tpVehicleKnown === 'no'
                  ? 'N/A'
                  : pageFormState.tpVehicleVRN || 'Unknown'}
              </FieldDisplay>

              <FieldDisplay title="Driver name">
                {pageFormState.tpVehicleKnown === 'no'
                  ? 'N/A'
                  : renderDriverName(pageFormState.tpDriverFields)}
              </FieldDisplay>

              <FieldDisplay title="Number of passengers">
                {pageFormState.tpHasPassengers || 'N/A'}
              </FieldDisplay>
            </IndentStyling>
          </>
        )}
        <SummaryIncompleteError isValid={pageFormState.formIsValid} />
      </>
    );
  };
  return (
    <AccordionSection
      title={title}
      theming={theming}
      color="blue"
      displayBody={displayBody}
      toggleBody={toggleBody}
      key="InvolvedParties"
    >
      <SummaryContent>
        <FieldFlexColumn>{renderFields()}</FieldFlexColumn>
        <EditFlexColumn>
          <EditWrapper>
            <ThemedLinkButton
              primary
              inverted
              xsmall
              to="/motorclaim/involvedpartiesdetail"
            >
              Edit
            </ThemedLinkButton>
          </EditWrapper>
        </EditFlexColumn>
      </SummaryContent>
    </AccordionSection>
  );
};
export default InvolvedPartiesDetailsSummary;
InvolvedPartiesDetailsSummary.propTypes = {
  title: PropTypes.string,
  theming: PropTypes.string,
};
InvolvedPartiesDetailsSummary.defaultProps = {
  title: 'Involved parties details',
  theming: 'secondary',
};
