import React, { useRef, useState, useContext, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import {
  ThemedLabel,
  ThemedButton,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  stringRequired,
  numberRequired,
} from 'ageasvalidation/lib/schemas/Required';
import { isEmpty } from 'lodash';
import { Form } from 'react-final-form';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { ukPostcode } from 'ageasvalidation/lib/schemas/UKPostcode';
import { min } from 'ageasvalidation/module/schemas/Min';
import { max } from 'ageasvalidation/module/schemas/Max';
import config from '../../../helpers/config';
import ReCAPTCHAHelper from '../../../helpers/ReCAPTCHAHelper';

import PostcodeField from '../../../components/Forms/Address/PostcodeField';
import StandardPanel from '../../../components/StandardPanel/StandardPanel';
import { brand } from '../../../../data/whitelabel.config';
import BrandFooter from '../../../assets/BrandFooter';
import BrandHeader from '../../../assets/BrandHeader';
import NavigateTo from '../../../components/Navigation/NavigateTo/NavigateTo';
import { BrokerContext } from '../../../helpers/brokerContextHelper';
import BrokerLoginBreadcrumb from '../../../helpers/BrokerLoginBreadcrumb';
import LoadingSpinner from '../../../components/UI/LoadingSpinner/LoadingSpinner';
import { validateField } from '../../../helpers/validationHelper';
import OTPAxios from '../../../helpers/axiosFunctions/OTPAxios';
import ButtonLink from '../../../components/ThemedButtonLink/ThemedButtonLink';
import {
  getOTPSuccessDestinationByLob,
  getRootLoginPathByLob,
} from '../../../helpers/getLineOfBusinessSubfolder';
import { AuthContext } from '../../../contexts/AuthContext';
import getPublicPayloadFromJWTToken from '../../../helpers/getPublicPayloadFromJWTToken';
import useQuery from '../../../hooks/useQuery/useQuery';
import {
  doesNotMatchOTP,
  noAuthCodeOTP,
  problemInRequestOTP,
  noPolicyOTP,
  noRecordsMatchedOTP,
} from '../../../helpers/errorMessages';
import { consoleError } from '../../../helpers/consoleLog';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';
import BrokerLoginHomePolicyDetailsBreadcrumb from '../../../helpers/BrokerLoginHomePolicyDetailsBreadcrumb';

const Theme = getTheme(brand);
const QuestionStyle = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 32px;
`;

const PanelContent = styled.div`
  text-align: left;
  max-width: 1100px;
  margin: auto;
`;

const BottomButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const StandardInputStyle = styled(StandardInput)`
  width: 200px;
  @media only screen and (max-width: 360px) {
    width: 100%;
  }
`;

const OTPVerification = ({ landingPage, authType, thirdParty = false }) => {
  const { isLoggedIn, setNewLogin, clearLogin } = useContext(AuthContext);
  const recaptchaRef = useRef(null);
  const recaptchaExecuted = useRef(false);
  const [navigatePath, setNavigatePath] = useState(null);
  const { dataState: brokerDataContext } = useContext(BrokerContext);
  const brokerContactContext = { ...brokerDataContext };
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [verifyOtpFailed, setVerifyOtpFailed] = useState(false);
  const [verifyOtpExpired, setVerifyOtpExpired] = useState(false);
  const [otpResentFailure, setOtpResentFailure] = useState(false);
  const [authCount, setAuthCount] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [isErrorNotMatch, setIsErrorNotMatch] = useState(false);
  const axiosCancelToken = useRef(null);
  const axiosResendCancelToken = useRef(null);
  const [loginComplete, setLoginComplete] = useState(false);
  const queryParams = useQuery();

  const otpRegex = /^\d{6}$/;
  const surnameRegex = /^[A-Za-z\-' .]+$/;

  const addTargetToPath = path => {
    const target = queryParams.get('target');
    if (target) {
      return `${path}?target=${encodeURIComponent(target)}`;
    }
    return path;
  };

  const onloadInitializeValue = () => {
    if (isEmpty(brokerDataContext)) {
      let path = `${getRootLoginPathByLob(landingPage)}`;
      path = addTargetToPath(path);
      setNavigatePath(path);
    }
  };

  useEffect(() => {
    clearLogin();
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
      if (axiosResendCancelToken?.current?.cancel) {
        axiosResendCancelToken.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    onloadInitializeValue();
  }, [brokerDataContext]);

  const schema = {
    otpField: stringRequired(
      ['otpField'],
      'Please enter the Ageas authentication code',
    ).concat(
      regularExpression(
        otpRegex,
        ['otpField'],
        'Please enter a valid Ageas authentication code',
      ),
    ),
    policyholderSurname: stringRequired(
      ['policyholderSurname'],
      "Please enter the policyholder's last name",
    )
      .concat(
        regularExpression(
          surnameRegex,
          ['policyholderSurname'],
          'Please enter a valid last name',
        ),
      )
      .concat(
        // Non-blank (at least one non-whitespace char)
        regularExpression(
          /\S/,
          ['policyholderSurname'],
          'Please enter a valid last name',
        ),
      ),
    riskAddressPostcode: stringRequired(
      ['riskAddressPostcode'],
      'Please enter the postcode',
    ).concat(
      ukPostcode(['riskAddressPostcode'], 'Please enter a valid UK postcode'),
    ),
    numberOfBedrooms: numberRequired(
      ['numberOfBedrooms'],
      'Please enter the number of bedrooms',
    )
      .concat(min(1, ['numberOfBedrooms'], true, 'Please enter a valid value'))
      .concat(
        max(10, ['numberOfBedrooms'], true, 'Please enter a valid value'),
      ),
  };

  const resetResultStatuses = () => {
    setVerifyOtpFailed(false);
    setVerifyOtpExpired(false);
    setOtpResentFailure(false);
    setIsErrorNotMatch(false);
    setServerError(false);
  };

  const navigateToSuccessDestination = () => {
    const target = queryParams.get('target');
    if (target) {
      setNavigatePath(target);
      return;
    }
    setNavigatePath(
      getOTPSuccessDestinationByLob(
        brokerDataContext.lineOfBusiness || landingPage,
      ),
    );
  };

  const renderContactMessage = () => {
    return brokerContactContext?.preferredContact === 'emailOTPSent'
      ? 'You will shortly receive an email from us containing a 6 digit code.'
      : 'You will shortly receive a text message from us containing a 6 digit code.';
  };
  const validateInputField = (value, _allValues, meta) => {
    let error;
    // Standard validation - mandatory fields
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return error;
      }
    }
    return undefined;
  };

  // Navigate to destination when login complete
  useEffect(() => {
    if (isLoggedIn && loginComplete) {
      navigateToSuccessDestination();
    }
  }, [isLoggedIn, loginComplete]);

  const completeLogin = response => {
    try {
      setIsLoading(false);
      getPublicPayloadFromJWTToken(response.token);
    } catch (e) {
      setServerError(true);
      return;
    }

    setNewLogin(response.token);
    setLoginComplete(true);
  };

  const axiosCount = count => {
    setAuthCount(prevCount => prevCount + 1);
    if (count >= 2) {
      setIsDisable(true);
      resetResultStatuses();
    }
  };

  const onSubmit = async values => {
    resetResultStatuses();
    let dataToValidate;
    if (authType === 'basic') {
      dataToValidate = {
        policyDetailsSent: {
          policyholderSurname: values.policyholderSurname,
          riskAddressPostcode: values.riskAddressPostcode,
          numberOfBedrooms: +values.numberOfBedrooms,
        },
      };
    } else {
      dataToValidate = { otpSent: values.otpField };
    }

    let recaptchaToken = '';
    // Double current because we are passing ref back using callback
    // rather than forwardRef
    if (!config.client.recaptchaDisabled) {
      try {
        // If we have already submitted a recaptcha, reset it
        if (recaptchaExecuted.current) {
          await recaptchaRef.current.current.reset();
        } else {
          recaptchaExecuted.current = true;
        }
        recaptchaToken = await recaptchaRef.current.current.executeAsync();
      } catch (recaptchaError) {
        setIsLoading(false);
        consoleError('unexpected recaptcha error', recaptchaError);
        setServerError(true);
        return;
      }
    }

    setIsLoading(true);

    axiosCancelToken.current = axios.CancelToken.source();
    const { data, error } = await OTPAxios(
      'validateCode',
      brokerContactContext,
      dataToValidate,
      axiosCancelToken.current.token,
      { 'google-recaptcha-token': recaptchaToken },
    );
    // Successful login - process, do not remove spinner yet
    if (data?.token) {
      completeLogin(data);
      return;
    }
    // Unsuccessful login - remove spinner and handle
    if (error?.isCancel) {
      return; // Nothing to do if Axios request cancelled
    }
    setIsLoading(false);
    if (data) {
      if (authType === 'basic') {
        setIsErrorNotMatch(true);
        axiosCount(authCount);
      } else if (data?.otpStatus === 'otpExpired') {
        setVerifyOtpExpired(true);
      } else {
        consoleError('unexpected otpStatus', data?.otpStatus);
        setVerifyOtpFailed(true);
      }
    } else {
      consoleError('unexpected error', error?.status);
      setServerError(true);
    }
  };

  const resendOTP = async () => {
    resetResultStatuses();
    let contactData;
    if (brokerContactContext?.preferredContact === 'mobileOTPSent') {
      contactData = {
        mobileTelephoneSent:
          brokerContactContext.contactData?.mobileTelephoneSent,
      };
    } else if (brokerContactContext?.preferredContact === 'emailOTPSent') {
      contactData = {
        emailAddressSent: brokerContactContext.contactData?.emailAddressSent,
      };
    }
    setIsLoading(true);
    axiosResendCancelToken.current = axios.CancelToken.source();
    const { data, error } = await OTPAxios(
      'requestCode',
      brokerContactContext,
      contactData,
      axiosResendCancelToken.current.token,
      undefined,
    );
    if (error?.isCancel) {
      return;
    }
    setIsLoading(false);
    if (data) {
      if (
        data?.otpStatus === 'emailOTPSent' ||
        data?.otpStatus === 'mobileOTPSent'
      ) {
        // empty because
        // no need to display successMessage;
      } else {
        consoleError('unexpected otpStatus', data?.otpStatus);
        setOtpResentFailure(true);
      }
    } else if (error?.status === 400 || error?.status === 500) {
      setOtpResentFailure(true);
    } else {
      consoleError('unexpected response', error?.status);
      setOtpResentFailure(true);
    }
  };

  const renderResend = () => {
    return (
      <ButtonLink primary onClick={resendOTP}>
        click here
      </ButtonLink>
    );
  };

  const renderFormEnding = () => {
    return (
      <BottomButtonsContainer>
        <ThemedButton secondary small type="submit" disabled={isDisable}>
          Continue
        </ThemedButton>
      </BottomButtonsContainer>
    );
  };

  const renderOTPQuestions = () => {
    return (
      <>
        <Heading>{renderContactMessage()}</Heading>
        <Heading>
          Please enter those 6 digits here to confirm your identity.
        </Heading>
        <StandardInputStyle
          name="otpField"
          aria-label="OTP Number"
          label="Ageas authentication code"
          maxLength="6"
          validate={validateInputField}
          onChange={() => {
            resetResultStatuses();
          }}
        />
        {!verifyOtpExpired && (
          <>
            <ThemedLabel>
              If you did not receive your Ageas authentication code or you
              require another one to be sent, please {renderResend()}.
            </ThemedLabel>
            <br />
          </>
        )}
        {verifyOtpExpired && (
          <ThemedErrorMessage hasIcon>
            Your Ageas authentication code has expired. Please {renderResend()}{' '}
            to receive another one
          </ThemedErrorMessage>
        )}
        {otpResentFailure && (
          <ThemedErrorMessage hasIcon>
            {noAuthCodeOTP(landingPage)}
          </ThemedErrorMessage>
        )}
        {serverError && (
          <ThemedErrorMessage hasIcon>
            {problemInRequestOTP(landingPage)}
          </ThemedErrorMessage>
        )}
        {verifyOtpFailed && (
          <ThemedErrorMessage hasIcon>{doesNotMatchOTP()}</ThemedErrorMessage>
        )}
        {renderFormEnding()}
        <ReCAPTCHAHelper
          sitekey={config.client.recaptchaSiteKey}
          size="invisible"
          badge="inline"
          apiCallback={apiRef => (recaptchaRef.current = apiRef)}
        />
      </>
    );
  };

  const renderPolicyDetailsQuestions = () => {
    return (
      <>
        <Heading>
          Please provide us with the following information to confirm your
          identity.
        </Heading>
        <StandardInput
          name="policyholderSurname"
          label="Policyholder's last name"
          xSmall
          maxLength="40"
          validate={validateInputField}
          aria-label="Policyholder surname"
        />
        <PostcodeField
          name="riskAddressPostcode"
          label="Postcode of the property insured on the policy"
          aria-label="Postcode"
          xSmall
          validate={validateInputField}
          format
        />
        <StandardInput
          name="numberOfBedrooms"
          label="Number of bedrooms in your property - between 1 & 10"
          xSmall
          maxLength="2"
          validate={validateInputField}
          aria-label="Number of bedrooms"
        />
        {isErrorNotMatch && (
          <ThemedErrorMessage hasIcon>
            {noPolicyOTP(landingPage)}
          </ThemedErrorMessage>
        )}
        {isDisable && (
          <ThemedErrorMessage hasIcon>
            {noRecordsMatchedOTP(landingPage)}
          </ThemedErrorMessage>
        )}
        {serverError && (
          <ThemedErrorMessage hasIcon>
            {problemInRequestOTP(landingPage)}
          </ThemedErrorMessage>
        )}
        {renderFormEnding()}
        <ReCAPTCHAHelper
          sitekey={config.client.recaptchaSiteKey}
          size="invisible"
          badge="inline"
          apiCallback={apiRef => (recaptchaRef.current = apiRef)}
        />
      </>
    );
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      <ThemeProvider theme={Theme}>
        <BrandHeader />
        {authType === 'basic' ? (
          <BrokerLoginHomePolicyDetailsBreadcrumb activeItem="verifyPolicyDetails" />
        ) : (
          <BrokerLoginBreadcrumb activeItem="verifyOTP" />
        )}
        <main>
          <NavigateTo path={navigatePath} />
          <QuestionStyle>
            <PanelContent>
              <StandardPanel primary title="Authentication" padding="23px 21px">
                <Form onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      {authType === 'basic'
                        ? renderPolicyDetailsQuestions()
                        : renderOTPQuestions()}
                    </form>
                  )}
                </Form>
              </StandardPanel>
            </PanelContent>
          </QuestionStyle>
        </main>
        <BrandFooter thirdParty={thirdParty} />
      </ThemeProvider>
    </>
  );
};

export default OTPVerification;

OTPVerification.propTypes = {
  landingPage: PropTypes.string,
  authType: PropTypes.string,
  thirdParty: PropTypes.bool,
};
OTPVerification.defaultProps = {
  landingPage: undefined,
  authType: 'otp',
  thirdParty: undefined,
};

export const OTPVerificationMotor = props => {
  return <OTPVerification {...props} landingPage="motor" />;
};
export const OTPVerificationHome = props => {
  return <OTPVerification {...props} landingPage="home" />;
};
export const PolicyDetailsVerificationHome = props => {
  return <OTPVerification {...props} landingPage="home" authType="basic" />;
};
export const OTPVerificationMotorTPA = props => {
  return <OTPVerification {...props} landingPage="motorTPA" thirdParty />;
};
