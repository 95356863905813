// Based on ageas-validation package telephone number validation
//   (node_modules\ageasvalidation\lib\constants\RegularExpressions.js)
// But with the follwoing changes to remove patterns that are not valid UK
// mobile numbers:
// * 08 combinations removed
//   * e.g. 08001111 not allowed in this regex
// * Short combinations removed (mobile numbers always full length)
//   * e.g. "0123 456 789" not allowed in this regex
// * extension removed
//   * e.g. "0123 456 7890 ext 1234" not allowed in this regex

const mobileTelephoneValidationRegex =
  /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)7(?:\d{1}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{2}\)?[\s-]?\d{3}[\s-]?\d{4}|\d{3}\)?[\s-]?(?:\d{3}[\s-]?\d{3})|\d{4}\)?[\s-]?\d{5})$/;

export default mobileTelephoneValidationRegex;
