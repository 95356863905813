import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = ({ srcImg, color, size, src, className, mobileSelect }) => {
  const ImangeContainer = styled.img`
    margin-left: ${mobileSelect === 'T' ? '-13px' : ''};
    margin-top: ${mobileSelect === 'T' ? '-11px' : ''};
  `;
  return (
    <div data-testid={`icon-${src}`}>
      <ImangeContainer
        className={className}
        src={srcImg}
        alt={srcImg}
        color={color}
        size={size}
      />
    </div>
  );
};

Image.propTypes = {
  srcImg: PropTypes.string,
  color: PropTypes.string,
  src: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  mobileSelect: PropTypes.string,
};

Image.defaultProps = {
  srcImg: '',
  color: '',
  size: '',
  className: '',
  mobileSelect: '',
};

export default Image;
