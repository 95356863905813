import PropTypes from 'prop-types';

export const nestingControlPropTypes = PropTypes.shape({
  isNested: PropTypes.bool,
  nestLevel: PropTypes.number,
  cascadeClose: PropTypes.bool,
  forceClose: PropTypes.bool,
  path: PropTypes.arrayOf(PropTypes.string),
  forcePath: PropTypes.arrayOf(PropTypes.string),
  setForcePath: PropTypes.func,
  autoPositionOffsetTop: PropTypes.func,
});

export const nestingControlDefault = {
  isNested: false,
  nestLevel: 1,
  cascadeClose: true,
  forceClose: false,
  path: [],
  forcePath: undefined,
  setForcePath: undefined,
  autoPositionOffsetTop: () => 0,
};
