import React from 'react';
import FaqP from '../../FaqP';

export const myPolicyExcessTitle =
  'What is my excess, when will I have to pay it and how will you collect it?';
export const myPolicyExcessContent = (
  <>
    <FaqP>Your excess is shown within your policy documentation.</FaqP>
    <FaqP>
      If your claim is settled via a cash payment, the excess will be deducted
      from the total amount before it is paid to you. Alternatively, if one of
      our repair networks carry out restoration, reinstatement or repair work,
      they will collect your excess from you on our behalf.
    </FaqP>
  </>
);
