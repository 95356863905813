import React from 'react';
import FaqP from '../../FaqP';

export const traceAccessCoverQuestion =
  "What is 'Trace & Access'? What does it cover?";
export const traceAccessCoverContent = (
  <>
    <FaqP>
      Trace &amp; Access applies when you are unable to find the source of a
      leak, for example from hidden pipework.
    </FaqP>
    <FaqP>
      Providing your building has been damaged by the water leakage, we will pay
      the reasonable costs you have to pay to find where the leak is coming
      from, including the cost to repair walls, floors or ceilings, up to the
      limit shown in your policy documentation.
    </FaqP>
    <FaqP>
      You must get our agreement before work starts in order to receive this
      benefit. For more information please refer to your policy documentation.
    </FaqP>
  </>
);
