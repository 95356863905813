import React from 'react';
import { ThemedHyperlink } from 'ageas-ui-components';
import FaqP from '../../FaqP';
import StandardUL from '../../../StandardTags/UL';

export const title = 'The claims hub';
export const content = (
  <>
    <FaqP>
      You can track your claim online by logging into the Ageas claims hub. To
      log in, all you need is your policy or claim reference number which you
      would have received via a text message and email.
    </FaqP>
    <FaqP marginBottom={false}>
      The hub enables you to view key information about your claim. Here you
      can:
    </FaqP>
    <StandardUL>
      <li>Check your claim status</li>
      <li>
        Find contact details of approved suppliers we&apos;ve appointed to
        assist with your claim
      </li>
      <li>Upload documents directly to the claim</li>
      <li>Send and receive messages to/from the claims team</li>
    </StandardUL>
    <FaqP>
      The format of your claim reference will be 7 digits and one letter e.g.
      6234567R
    </FaqP>
    <FaqP>
      To log into the Ageas claims hub, please follow this link
      <br />
      <ThemedHyperlink
        primary
        href="https://www.ageas.co.uk/claims/home-insurance"
        text="www.ageas.co.uk/claims/home-insurance"
      />
    </FaqP>
  </>
);

export const pair = { title, content };
