import React from 'react';
import PropTypes from 'prop-types';
import { ThemedParagraph } from 'ageas-ui-components';
import {
  Footer,
  FooterContainer,
  FooterLinks,
  FooterText,
  VersionInformation,
} from './Footer.style';
// Imports config for Version Details
import Config from '../../../helpers/config';
import legalText from '../../../helpers/legalText';

const year = new Date().getFullYear();

export const footerLinks = [
  {
    id: 1,
    label: 'Accessibility',
    link: 'https://www.ageas.co.uk/accessibility/',
    target: '_top',
  },
  {
    id: 2,
    label: 'Cookies',
    link: 'https://www.ageas.co.uk/cookies/',
    target: '_top',
  },
  {
    id: 3,
    label: 'Modern slavery statement',
    link: 'https://www.ageas.co.uk/important-information/modern-slavery-statement/',
    target: '_top',
  },
  {
    id: 4,
    label: 'Privacy notices',
    link: 'https://www.ageas.co.uk/privacy-notice/',
    target: '_top',
    thirdParty: false,
  },
  {
    id: 401,
    label: 'Privacy notices',
    link: 'https://www.ageas.co.uk/privacy-notice/non-customers-privacy-policy/',
    target: '_top',
    thirdParty: true,
  },
  {
    id: 5,
    label: 'Supporting all customers',
    link: 'https://www.ageas.co.uk/need-help/supporting-all-customers/',
    target: '_top',
    thirdParty: false,
  },
  {
    id: 6,
    label: 'Terms and conditions',
    link: 'https://www.ageas.co.uk/terms-and-conditions/',
    target: '_top',
  },
];

const getFooterLinks = thirdParty => {
  return footerLinks.filter(link =>
    [undefined, thirdParty].includes(link.thirdParty),
  );
};

const BrandFooter = ({ thirdParty = false }) => {
  // Initiates version details
  const { releaseVersion, siEnviroment, siVersion } =
    Config.client.version || {};

  return (
    <Footer>
      <FooterContainer>
        <FooterLinks>
          <p>&copy; ageas {year}</p>
          <ul>
            {getFooterLinks(thirdParty).map(({ id, label, link, target }) => (
              <li key={id}>
                <a href={link} target={target}>
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </FooterLinks>
        <FooterText>
          <ThemedParagraph textAlign="center" fontSize="14px">
            {legalText}
          </ThemedParagraph>
          {/* Adds SI version details */}
          {releaseVersion && siVersion && siEnviroment && (
            <VersionInformation>{`${releaseVersion} | ${siEnviroment} | ${siVersion}`}</VersionInformation>
          )}
        </FooterText>
      </FooterContainer>
    </Footer>
  );
};

export default BrandFooter;

BrandFooter.propTypes = {
  thirdParty: PropTypes.bool,
};
BrandFooter.defaultProps = {
  thirdParty: undefined,
};
