import {
  dmyToIsoOrUndefined,
  involvedPartyType,
  isYes,
  mapClaimsToServer,
  mapConvictionsToServer,
  mapInvolvedPartyToServer,
  yesNoToBoolean,
} from '../helpers';
import { OTHER_UNKNOWN_DRIVER } from '../../../views/MotorClaimNew/motorYourDetailsHelper';

const mapActualDriverDetails = (driver, yourDetails = {}) => {
  if (driver === undefined || driver === OTHER_UNKNOWN_DRIVER) {
    return undefined;
  }

  const {
    dobIsCorrect,
    dob,
    occupationIsCorrect,
    occupation,
    occupationChangedDate,
    occupationChangedVehicleUsed,
    occupationChangedCommuting,
    licenceTypeIsCorrect,
    licenceType,
    licenceYearsIsCorrect,
    licenceYears,
    driverConvictionsIsCorrect,
    driverConvictions,
    driverClaimsIsCorrect,
    driverClaims,
  } = yourDetails;

  const dateOfBirthIsCorrectServer = yesNoToBoolean(dobIsCorrect);
  let dateOfBirthServer;
  if (dateOfBirthIsCorrectServer === false) {
    dateOfBirthServer = dmyToIsoOrUndefined(dob);
  }

  const licenceTypeIsCorrectServer = yesNoToBoolean(licenceTypeIsCorrect);
  let licenceTypeServer;
  if (licenceTypeIsCorrectServer === false) {
    licenceTypeServer = licenceType;
  }

  const licenceYearsIsCorrectServer = yesNoToBoolean(licenceYearsIsCorrect);
  let licenceYearsServer;
  if (licenceYearsIsCorrectServer === false) {
    licenceYearsServer = parseInt(licenceYears, 10) || undefined;
  }

  const occupationIsCorrectServer = yesNoToBoolean(occupationIsCorrect);
  let occupationDetails;
  if (occupationIsCorrectServer === false) {
    occupationDetails = {
      ...occupationDetails,
      occupation,
      changedDate: dmyToIsoOrUndefined(occupationChangedDate),
      changedVehicleUsed: yesNoToBoolean(occupationChangedVehicleUsed),
      changedCommuting: yesNoToBoolean(occupationChangedCommuting),
    };
  }

  const isPreviousConvictionsCorrect = yesNoToBoolean(
    driverConvictionsIsCorrect,
  );
  let previousConvictions;
  if (!isPreviousConvictionsCorrect && driverConvictions?.length) {
    previousConvictions = mapConvictionsToServer(driverConvictions);
  }

  const isPreviousClaimsCorrect = yesNoToBoolean(driverClaimsIsCorrect);
  let previousClaims;
  if (!isPreviousClaimsCorrect && driverClaims?.length) {
    previousClaims = mapClaimsToServer(driverClaims);
  }

  return {
    isDateOfBirthCorrect: dateOfBirthIsCorrectServer,
    dateOfBirth: dateOfBirthServer,
    isLicenceTypeCorrect: licenceTypeIsCorrectServer,
    licenceType: licenceTypeServer,
    isLicenceYearsCorrect: licenceYearsIsCorrectServer,
    licenceYears: licenceYearsServer,
    isOccupationCorrect: occupationIsCorrectServer,
    occupationDetails,
    isPreviousConvictionsCorrect,
    previousConvictions,
    isPreviousClaimsCorrect,
    previousClaims,
  };
};

const mapOtherDriverDetails = (driver, yourDetails = {}) => {
  if (driver !== OTHER_UNKNOWN_DRIVER) {
    return undefined;
  }

  const {
    dob,
    otherDriverOwnPolicy,
    licenceType,
    licenceYears,
    otherDriverHasConvictions,
    otherDriverConvictions,
    otherDriverHasClaims,
    otherDriverClaims,
  } = yourDetails;

  let previousConvictions;
  if (isYes(otherDriverHasConvictions) && otherDriverConvictions?.length) {
    previousConvictions = mapConvictionsToServer(otherDriverConvictions);
  }

  let previousClaims;
  if (isYes(otherDriverHasClaims) && otherDriverClaims?.length) {
    previousClaims = mapClaimsToServer(otherDriverClaims);
  }

  return {
    driverDetails: mapInvolvedPartyToServer(
      yourDetails,
      'otherDriverDetails',
      involvedPartyType.INDIVIDUAL,
    ),
    dateOfBirth: dmyToIsoOrUndefined(dob),
    hasOwnPolicy: yesNoToBoolean(otherDriverOwnPolicy),
    licenceType,
    licenceYears: parseInt(licenceYears, 10) || undefined,
    hasPreviousConvictions: otherDriverHasConvictions,
    previousConvictions,
    hasPreviousClaims: otherDriverHasClaims,
    previousClaims,
  };
};

const mapPolicyDetails = (yourDetails = {}) => {
  const { phAddressIsCorrect, phAddressChangedDate, vehicleKept } = yourDetails;

  let out = {
    isAddressCorrect: yesNoToBoolean(phAddressIsCorrect),
  };
  if (out.isAddressCorrect === false) {
    out = {
      ...out,
      address: mapInvolvedPartyToServer(yourDetails, 'phAddress'),
      addressChangedDate: dmyToIsoOrUndefined(phAddressChangedDate),
      vehicleKept,
    };
  }

  return out;
};

const mapOwnerKeeper = (yourDetails = {}, values = {}) => {
  const {
    changedDate,
    namePrefix,
    name,
    dateOfBirth,
    status,
    relationshipToPh,
  } = values;

  const out = {
    changedDate: dmyToIsoOrUndefined(changedDate),
    name: name
      ? mapInvolvedPartyToServer(
          yourDetails,
          namePrefix,
          involvedPartyType.INDIVIDUAL,
        )
      : undefined,
    dateOfBirth: dmyToIsoOrUndefined(dateOfBirth),
    status,
    relationshipToPh: relationshipToPh?.trim(),
  };

  // DO not return object if all of its contents are undefined
  if (Object.values(out).every(el => el === undefined)) {
    return undefined;
  }

  return out;
};

const mapVehicleOwner = (yourDetails = {}) => {
  const {
    vehicleOwnerChangedDate,
    vehicleOwnerName,
    vehicleOwnerDob,
    vehicleOwnerStatus,
    vehicleOwnerRelationshipToPh,
  } = yourDetails;

  const values = {
    changedDate: vehicleOwnerChangedDate,
    namePrefix: 'vehicleOwnerName',
    name: vehicleOwnerName,
    dateOfBirth: vehicleOwnerDob,
    status: vehicleOwnerStatus,
    relationshipToPh: vehicleOwnerRelationshipToPh,
  };

  return mapOwnerKeeper(yourDetails, values);
};

const mapVehicleKeeper = (yourDetails = {}) => {
  const {
    vehicleKeeperChangedDate,
    vehicleKeeperName,
    vehicleKeeperDob,
    vehicleKeeperRelationshipToPh,
  } = yourDetails;

  const values = {
    changedDate: vehicleKeeperChangedDate,
    namePrefix: 'vehicleKeeperName',
    name: vehicleKeeperName,
    dateOfBirth: vehicleKeeperDob,
    relationshipToPh: vehicleKeeperRelationshipToPh,
  };

  return mapOwnerKeeper(yourDetails, values);
};

const mapVehicleDetails = (yourDetails = {}) => {
  const {
    vehicleOwnerIsCorrect,
    vehicleKeeperIsCorrect,
    vehicleModificationsToAdd,
    vehicleModificationsDetails,
    vehicleModificationsDate,
    mainUser,
    vehicleUsage,
    vehicleUsageDetails,
  } = yourDetails;

  let modifications = {
    modificationsToAdd: yesNoToBoolean(vehicleModificationsToAdd),
  };
  if (modifications.modificationsToAdd === true) {
    modifications = {
      ...modifications,
      modificationsDetails: vehicleModificationsDetails?.trim(),
      modificationsDate: dmyToIsoOrUndefined(vehicleModificationsDate),
    };
  }

  const vehicleUsageSection = {
    vehicleUsage: vehicleUsage?.trim(),
  };
  if (vehicleUsageSection.vehicleUsage === 'other') {
    vehicleUsageSection.vehicleUsageDetails = vehicleUsageDetails?.trim();
  }

  const isOwnerCorrect = yesNoToBoolean(vehicleOwnerIsCorrect);
  let vehicleOwner;
  if (isOwnerCorrect === false) {
    vehicleOwner = mapVehicleOwner(yourDetails);
  }
  const isKeeperCorrect = yesNoToBoolean(vehicleKeeperIsCorrect);
  let vehicleKeeper;
  if (isKeeperCorrect === false) {
    vehicleKeeper = mapVehicleKeeper(yourDetails);
  }

  let mainUserDetails;
  if (!isOwnerCorrect || !isKeeperCorrect) {
    const mainUserServer = mainUser?.trim();
    let mainUserName;
    if (mainUserServer === 'other') {
      mainUserName = mapInvolvedPartyToServer(
        yourDetails,
        'mainUserName',
        involvedPartyType.INDIVIDUAL,
      );
    }
    mainUserDetails = {
      mainUser: mainUserServer,
      mainUserName,
    };
  }

  return {
    isOwnerCorrect,
    isKeeperCorrect,
    ...modifications,
    ...vehicleUsageSection,
    vehicleOwner,
    vehicleKeeper,
    ...mainUserDetails,
  };
};

/**
 * Map yourDetails context/pageForms data to JSON Blob
 * underwriterQuestions section
 * @param {*} data UnderwriterQuestions in MotorClaimContextNew
 * claimEntryData format
 * @returns data in JSON Blob underwriterQuestions section format, or undefined
 * if section not present
 */
const mapLocalToUnderwriterQuestionsSection = ({ yourDetails } = {}) => {
  if (!yourDetails) {
    return undefined;
  }
  const { driver } = yourDetails || {};

  // Return mapped object
  return {
    actualDriverDetails: mapActualDriverDetails(driver, yourDetails),
    otherDriverDetails: mapOtherDriverDetails(driver, yourDetails),
    policyDetails: mapPolicyDetails(yourDetails),
    vehicleDetails: mapVehicleDetails(yourDetails),
  };
};

export default mapLocalToUnderwriterQuestionsSection;
