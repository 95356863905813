import React from 'react';
import PropTypes from 'prop-types';

import {
  brand,
  brandLogo,
  headerInfo,
} from '../../../../data/whitelabel.config';
import capitalizeWord from '../../../helpers/capitalizeWord';

import BenefitsBanner from '../benefitsBanner/BenefitsBanner';
import useModalOnExit from '../useModalOnExit/useModalOnExit';
import MenuBar from './MenuBar';

import { Header, Logo, Container, Link, LinkContainer } from './Header.style';

const BrandHeader = props => {
  const {
    fixedWidthMode,
    isMenuBar,
    maxWidth = '1170px',
    maxOuterWidth,
    lob,
    logoutButton = true,
    claimButton = true,
    suppressWarningOnClick,
  } = props;
  const { homeLink, logoText } = headerInfo;
  const { toggleModal, Modal } = useModalOnExit();

  const toggleModalIntercept = event => {
    if (!isMenuBar && !suppressWarningOnClick) {
      event.preventDefault(); // Prevent link navigation if displaying modal
      event.stopPropagation();
      toggleModal(event);
    }
  };

  return (
    <Header id="topLayer" maxOuterWidth={maxOuterWidth}>
      <Container
        data-testid="headerContainer"
        fixedWidthMode={fixedWidthMode}
        maxWidth={maxWidth}
      >
        <LinkContainer>
          <Link
            href={homeLink}
            onClick={toggleModalIntercept}
            aria-label={`${capitalizeWord(brand)} home`}
          >
            <Logo id="logoProperty">
              <img alt={`${capitalizeWord(brand)} logo`} src={brandLogo} />
              <figcaption>{logoText}</figcaption>
            </Logo>
          </Link>
        </LinkContainer>
        {isMenuBar && (
          <MenuBar
            logoutButton={logoutButton}
            claimButton={claimButton}
            lob={lob}
          />
        )}
      </Container>
      <BenefitsBanner />
      {/* eslint-disable-next-line */}
      {!suppressWarningOnClick && <Modal />}
    </Header>
  );
};

BrandHeader.propTypes = {
  suppressWarningOnClick: PropTypes.bool,
  fixedWidthMode: PropTypes.bool,
  isMenuBar: PropTypes.bool,
  maxWidth: PropTypes.string,
  maxOuterWidth: PropTypes.string,
  lob: PropTypes.string,
  logoutButton: PropTypes.bool,
  claimButton: PropTypes.bool,
};

BrandHeader.defaultProps = {
  suppressWarningOnClick: false,
  fixedWidthMode: false,
  isMenuBar: false,
  maxWidth: undefined,
  maxOuterWidth: undefined,
  lob: undefined,
  logoutButton: undefined,
  claimButton: undefined,
};

export default BrandHeader;
