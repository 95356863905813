import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusText,
  TitleTextSpaced,
  LabelSet,
  BasicSection,
  SubText,
} from '../StandardPageWrapper/StandardPageWrapperStyle';
import LabelSetEntry from '../StandardPageWrapper/LabelSetEntry';
import { getStatusDesc } from '../../helpers/motorClaimStatusDescriptions';
import { reverseDate } from '../../helpers/dateTimeConverter';
import TelephoneLink from '../TelephoneLink/TelephoneLink';
import StandardUL from '../StandardTags/UL';
import { phoneNumbers } from '../../helpers/ageasTelephoneNumbers';

const servicesPropType = PropTypes.shape({
  solutionCentre: PropTypes.bool,
  thirdPartyGarage: PropTypes.bool,
  hireCar: PropTypes.bool,
  courtesyCar: PropTypes.bool,
  approvedRepairerName: PropTypes.string,
  approvedRepairerTel: PropTypes.string,
  hireBranchName: PropTypes.string,
  hireBranchTel: PropTypes.string,
});

const claimDetailsPropType = PropTypes.shape({
  claimReference: PropTypes.string,
  claimStatus: PropTypes.string,
  incidentDate: PropTypes.string,
  name: PropTypes.string,
  vrn: PropTypes.string,
  services: servicesPropType,
});

const Contact = ({ label, name, tel, isPresent }) => {
  if (isPresent && (name || tel)) {
    return (
      <LabelSetEntry label={label} column>
        {name} {tel && <TelephoneLink number={tel} />}
      </LabelSetEntry>
    );
  }
  return null;
};
Contact.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  tel: PropTypes.string,
  isPresent: PropTypes.bool.isRequired,
};
Contact.defaultProps = {
  name: undefined,
  tel: undefined,
};

const Contacts = ({ services = {} }) => {
  const {
    solutionCentre = false,
    hireCar = false,
    approvedRepairerName,
    approvedRepairerTel,
    hireBranchName,
    hireBranchTel,
  } = services || {};

  return (
    <BasicSection>
      <strong>Contact details:</strong>
      <SubText>
        <LabelSet>
          <Contact
            label="Approved repairer:"
            name={approvedRepairerName}
            tel={approvedRepairerTel}
            isPresent={solutionCentre}
          />

          <Contact
            label="Hire car provider:"
            name={hireBranchName}
            tel={hireBranchTel}
            isPresent={hireCar}
          />

          <Contact
            label="Ageas:"
            name="Third Party Assistance team"
            tel={phoneNumbers.tpAssist}
            isPresent
          />
        </LabelSet>
      </SubText>
    </BasicSection>
  );
};
Contacts.propTypes = {
  services: servicesPropType,
};
Contacts.defaultProps = {
  services: undefined,
};

const MotorTpHubClaimDetails = ({ claimDetails = {} }) => {
  const {
    claimReference,
    claimStatus,
    incidentDate,
    claimant,
    vrn,
    services = {},
  } = claimDetails || {};
  const {
    solutionCentre = false,
    thirdPartyGarage = false,
    hireCar = false,
    courtesyCar = false,
  } = services;

  const hasServices =
    solutionCentre || thirdPartyGarage || hireCar || courtesyCar;

  if (!claimReference) {
    return null;
  }

  return (
    <>
      <BasicSection>
        <TitleTextSpaced black>
          Claim reference {claimReference} -{' '}
          <StatusText>{getStatusDesc(claimStatus)}</StatusText>
        </TitleTextSpaced>

        <LabelSet>
          <LabelSetEntry label="Name:">
            {claimant?.name || 'Unspecified'}
          </LabelSetEntry>

          <LabelSetEntry label="Vehicle reg:">
            {vrn || 'Unspecified'}
          </LabelSetEntry>

          <LabelSetEntry label="Incident date:">
            {incidentDate ? reverseDate(incidentDate) : 'Unspecified'}
          </LabelSetEntry>
        </LabelSet>
      </BasicSection>

      <BasicSection>
        <strong>Services you have accepted from Ageas:</strong>

        <SubText>
          <StandardUL>
            {solutionCentre && <li>Approved repairer</li>}
            {thirdPartyGarage && <li>Third party garage</li>}
            {hireCar && <li>Hire car</li>}
            {courtesyCar && <li>Courtesy car</li>}
            {!hasServices && <li>None</li>}
          </StandardUL>
        </SubText>
      </BasicSection>

      <Contacts services={services} />
    </>
  );
};

MotorTpHubClaimDetails.propTypes = {
  claimDetails: claimDetailsPropType,
};
MotorTpHubClaimDetails.defaultProps = {
  claimDetails: undefined,
};

export default MotorTpHubClaimDetails;
