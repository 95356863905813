import React from 'react';
import PropTypes from 'prop-types';
import { AccordionBodyPaddedBase } from './AccordionSection.style';
import {
  nestingControlPropTypes,
  nestingControlDefault,
} from './accordionNestingProp';

const cloneChild = (child, nestingControl, index) => {
  // Child is not a component that can receive this prop
  // return as-is
  if (!React.isValidElement(child) || typeof child?.type !== 'function') {
    return child;
  }
  // Add new props
  const newProps = {
    key: index,
    nestingControl,
  };

  return React.cloneElement(child, newProps);
};

const processChildren = (children, nestingControl) => {
  // Do not bother cloning if no cloneable props to pass
  if (!nestingControl?.setForcePath) {
    return children;
  }

  // Multiple children
  if (Array.isArray(children)) {
    return children.map((child, index) => {
      return cloneChild(child, nestingControl, index);
    });
  }
  // Single child, or no child
  return cloneChild(children, nestingControl);
};

const AccordionBodyPadded = ({
  children,
  nestingControl,
  padForIconLeft,
  padForIconRight,
}) => {
  const nestingControlFull = {
    ...nestingControlDefault,
    ...nestingControl,
  };
  return (
    <AccordionBodyPaddedBase
      padForIconLeft={padForIconLeft}
      padForIconRight={padForIconRight}
      {...nestingControlFull}
    >
      {processChildren(children, nestingControlFull)}
    </AccordionBodyPaddedBase>
  );
};

AccordionBodyPadded.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nestingControl: nestingControlPropTypes,
  padForIconLeft: PropTypes.bool,
  padForIconRight: PropTypes.bool,
};

AccordionBodyPadded.defaultProps = {
  children: [],
  nestingControl: undefined,
  padForIconLeft: true,
  padForIconRight: true,
};

export default AccordionBodyPadded;
