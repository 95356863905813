import React from 'react';
import { validateField } from '../../../helpers/validationHelper';
import { getErrorMessage } from '../../../helpers/errorMessages';
import PreviousMotorClaim from './PreviousMotorClaim';
import { getStandardDateRequiredPastSchema } from '../StandardDate/StandardDate';
import { getStandardDropdownSchema } from '../StandardDropdown/StandardDropdown';
import ModalForm from '../ModalForm/ModalForm';

const schema = {
  ...getStandardDropdownSchema('claimType'),
  ...getStandardDateRequiredPastSchema(
    'claimDate',
    'Please select the claim date',
  ),
};

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const PreviousMotorClaimModalForm = ({ ...rest }) => {
  return (
    <ModalForm {...rest}>
      <PreviousMotorClaim validateFields={validateFields} />
    </ModalForm>
  );
};

export default PreviousMotorClaimModalForm;

PreviousMotorClaimModalForm.propTypes = {
  ...ModalForm.propTypes,
};

PreviousMotorClaimModalForm.defaultProps = {
  ...ModalForm.defaultProps,
};
