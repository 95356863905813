import React from 'react';
import FaqP from '../../FaqP';

export const perishedFoodAffectClaimTitle =
  'I have already replaced the perished food, how will this affect the claim?';
export const perishedFoodAffectClaimContent = (
  <FaqP>
    We would ask that you contact us in the first instance, where this is
    possible. However, if you do replace the items, please keep hold of
    receipts, as you may be asked to send these before a cash settlement can be
    agreed.
  </FaqP>
);
