import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageSection } from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import {
  MotorClaimContextNew,
  motorClaimDataContext,
} from '../../helpers/motorClaimHelperNew';
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import STAGES from './motorClaimNewHelpers';

const NewMotorClaimInitialise = () => {
  const historyHook = useHistory();
  const { updateDataState } = useContext(MotorClaimContextNew);

  // Onload actions - clear context, so that incidentDetails page is loaded
  // for a new claim
  useEffect(() => {
    updateDataState(motorClaimDataContext.claimEntryData, {
      // Must always exist, so when refreshing a page after localStorage
      // expiry, page knows to return to history because meta is absent
      meta: { stage: STAGES.INPUT.name },
    });
    updateDataState(motorClaimDataContext.claimDetails, {});
    historyHook.replace('/motorclaim/newclaim/incidentdetails');
  }, []);

  return (
    <>
      <LoadingSpinner />
      <StandardPageWrapper lob="motor">
        <PageSection />
      </StandardPageWrapper>
    </>
  );
};

export default NewMotorClaimInitialise;
