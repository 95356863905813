import React from 'react';
import PropTypes from 'prop-types';
import { checkBusinessHours } from '../../helpers/checkBusinessHours';

const RenderMessageOrOOH = ({
  children,
  lob,
  oohMessage,
  getStandardOOH = () => null,
  forceNoOOH = false,
}) => {
  if (forceNoOOH || checkBusinessHours(lob)) {
    return <>{children}</>;
  }
  if (oohMessage) {
    return oohMessage;
  }
  return getStandardOOH(lob);
};
RenderMessageOrOOH.propTypes = {
  children: PropTypes.node,
  lob: PropTypes.string,
  oohMessage: PropTypes.node,
  getStandardOOH: PropTypes.func,
  forceNoOOH: PropTypes.bool,
};
RenderMessageOrOOH.defaultProps = {
  children: undefined,
  lob: undefined,
  oohMessage: undefined,
  getStandardOOH: undefined,
  forceNoOOH: undefined,
};

export default RenderMessageOrOOH;
