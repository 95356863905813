import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

const validKeys = [];

/**
 * @description: Validate the field against a regular expression.
 * @param regex A regular expression to validate against
 * @param keys Fields to validate
 * @parm message A message to display when the validation fails
 * @succeeds when field matches the provided regular expression
 * @fails when field is not a string or does not match the regular expression or is empty
 */
export const regularExpression = (
  regex,
  keys = validKeys,
  message = messages.INVALID_REGULAR_EXPRESSION,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex)
      .required()
      .error(() => message),
  );
};

export default regularExpression;
