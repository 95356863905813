import React from 'react';
import { ThemedHyperlink } from 'ageas-ui-components';
import NestedAccordion from '../NestedAccordion/NestedAccordion';
import nestedAccordionBuilder from '../NestedAccordion/nestedAccordionBuilder';
import FaqP from './FaqP';
import { TPAssistTelLink } from '../../helpers/ageasTelephoneNumbers';
import config from '../../helpers/config';
import { businessHoursMotorTPADescriptive } from '../../helpers/businessHoursData';

export const TPAssistQuestions = [
  {
    title: 'Will I get a cash settlement?',
    content: (
      <FaqP>
        Each claim is assessed on an individual basis. We will do our very best
        to repair your vehicle but in some cases this may not be possible, in
        which case we may offer a cash settlement as an alternative.
      </FaqP>
    ),
  },
  {
    title: 'Will I be kept mobile?',
    content: (
      <>
        <FaqP>
          If you use one of our approved repairers, they will be able to provide
          you with a free courtesy car for the duration of the repairs. You will
          be insured as you would in your own vehicle. If this does not meet
          your mobility needs, we can provide you with a replacement vehicle
          through one of our hand-picked hire providers.
        </FaqP>
        <FaqP>
          A courtesy vehicle will be provided for as long as it is reasonably
          required while the repairs to your vehicle are undertaken.
        </FaqP>
        <FaqP>
          In some cases it may be more appropriate for us to consider a cash
          payment instead of providing a replacement vehicle. This will be based
          on individual needs and requirements.
        </FaqP>
        <FaqP>
          You may have been offered a vehicle through your insurers or another
          company. For further information, please{' '}
          <ThemedHyperlink
            primary
            href="https://www.ageas.co.uk/globalassets/documents/credit-hire-explanation.pdf"
            text="download the pdf"
          />{' '}
          relating to this.
        </FaqP>
      </>
    ),
  },
  {
    title: 'What happens if my car is not repairable?',
    content: (
      <>
        <FaqP>
          When the cost of the repairs is greater than the current market value
          of the vehicle, the vehicle is considered to be beyond economical
          repair, also known as a total loss or a write-off.
        </FaqP>
        <FaqP>
          If your vehicle is considered beyond economical repair, we will
          calculate the vehicle&apos;s value and agree this with you. Once a
          payment has been made, we can then arrange to dispose of your vehicle
          for you. Alternatively, if you would like to retain your damaged
          vehicle and it is safe to do so, we will deduct the salvage value from
          any payment made. Industry databases, such as the national Motor
          Insurance Database, will be updated to reflect that the vehicle has
          been the subject of a write off.
        </FaqP>
      </>
    ),
  },
  {
    title: 'Why should I deal directly with Ageas?',
    content: (
      <>
        <FaqP>
          <strong>Claiming through Ageas has a number of benefits:</strong>
        </FaqP>
        <FaqP>
          <strong>A dedicated team:</strong> our dedicated third party team are
          on hand to help get things back to normal fast. You can contact us on{' '}
          <strong>
            <TPAssistTelLink />
          </strong>
          {'; '}
          {businessHoursMotorTPADescriptive}.
        </FaqP>
        <FaqP>
          <strong>Protecting your no claims:</strong> claiming directly through
          Ageas will not affect your no claims discount and you will not incur
          any insurance excess charges.
        </FaqP>
        <FaqP>
          <strong>A choice of repairers:</strong> if your car is repairable, you
          will have access to our network of approved repairers. Alternatively,
          you can use a garage of your choice to undertake the work.
        </FaqP>
        <FaqP>
          <strong>Prompt payment:</strong> if your vehicle is damaged beyond
          economical repair, we will ensure that you receive prompt payment for
          the value of your vehicle.
        </FaqP>
        <FaqP>
          <strong>Stay mobile:</strong> we will ensure that you remain mobile
          through providing a complimentary courtesy or hire vehicle.
        </FaqP>
        <FaqP>
          <strong>Don&apos;t just take our word for it...</strong>
        </FaqP>
        <FaqP>
          “Dealing directly with the other side&apos;s insurer [
          <em>in this case Ageas</em>] has a number of benefits. The claim is
          managed with as little inconvenience to you as possible, and at no
          cost to you. People who deal directly with the other side&apos;s
          insurer may receive compensation faster when compared with those who
          choose to use legal representation. If an accident was not your fault
          and you deal directly with the other side&apos;s insurer, the insurer
          will also never take a portion of the compensation you receive.
          Moreover, reducing unnecessary legal costs will help keep
          everyone&apos;s insurance premiums down.
        </FaqP>
        <FaqP>
          <em>
            - Third Party Claimant Guide, Association of British Insurers. (June
            2022)
          </em>
        </FaqP>
      </>
    ),
  },
  {
    title: 'How we handle your data',
    content: (
      <>
        <FaqP>
          Ageas takes the protection of people’s data very seriously. We ensure
          that the data we process for both customers and non-customers has
          appropriate security measures around them.
        </FaqP>
        <FaqP>
          As part of a claim, we may collect different pieces of information
          about you. This could be from a variety of sources including directly
          from you or from our customers where you have given them your
          information after an accident, as well as publicly available sources
          and industry registers.
        </FaqP>
        <FaqP>
          The information could include details such as your name, address,
          contact information and even special category data, such as medical
          data.
        </FaqP>
        <FaqP>
          We will use this information for the purposes of settling an insurance
          claim and may contact you to offer our services where you have been
          involved in a non-fault accident with one of our customers. If you
          advise that you do not want our services, then we will no longer
          contact you for these purposes, and will only process your data as
          necessary to settle a claim.
        </FaqP>
        <FaqP>
          For further information on how Ageas processes your data, you can
          access the Privacy Policy for Non-Ageas customers{' '}
          <ThemedHyperlink
            primary
            href={config.client.nonCustomerPrivacyPolicyUrl}
            text="here"
          />
          .
        </FaqP>
      </>
    ),
  },
];

export const typeQuestions = [
  {
    title: 'Frequently asked questions',
    content: nestedAccordionBuilder(TPAssistQuestions, true),
    contentHeader: 'My question is about',
  },
];

const TPAssistFAQAccordion = ({ ...props }) => {
  return (
    <NestedAccordion {...props}>
      {nestedAccordionBuilder(typeQuestions)}
    </NestedAccordion>
  );
};
export default TPAssistFAQAccordion;
