import { titleCodeToText } from './titleCodes';

// Assemble name as :
//  Title (if set and valid, converted from fieldValues.title)
//  First name (if set in fieldValues.firstName)
//  Last name (if set in fieldValues.lastName)
// All separated by spaces
// If first and last names are not set, substitute:
//   If index provided (not undefined):
//     Unknown #<index>
//   Else:
//     Unknown
export const buildFullName = ({ title, firstName, lastName }, index) => {
  const nameComponents = [];
  if (title) {
    const titleText = titleCodeToText(title);
    if (titleText) {
      nameComponents.push(titleText);
    }
  }
  if (firstName?.trim()) {
    nameComponents.push(firstName.trim());
  }
  if (lastName?.trim()) {
    nameComponents.push(lastName.trim());
  }
  if (!firstName?.trim() && !lastName?.trim()) {
    if (typeof index !== 'undefined') {
      nameComponents.push(`Unknown #${index}`);
    } else {
      nameComponents.push(`Unknown`);
    }
  }
  return nameComponents.join(' ');
};

export default buildFullName;
