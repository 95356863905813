import React from 'react';
import styled, { css } from 'styled-components';
import { ProgressiveQuestionItemPropTypes } from './ProgressiveQuestionsPropTypes';
import Spacer from '../Forms/FieldSpacer/FieldSpacer';
import FieldInnerBlockContainer from '../Forms/FieldInnerBlockContainer/FieldInnerBlockContainer';
import DropdownRadio from '../Forms/DropdownRadio/DropdownRadio';

// For documentation see:
// /docs/components/ProgressiveQuestions/ProgressiveQuestionItem.md

const Container = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 16px;
  color: #646464;
  text-align: left;
  @media only screen and (max-width: 768px) {
    margin-left: 5px;
    margin-right: 5px;
  }
  ${({ itemContainerProps }) =>
    itemContainerProps?.marginLeft &&
    css`
      margin-left: ${itemContainerProps.marginLeft};
    `}
  ${({ itemContainerProps }) =>
    itemContainerProps?.color &&
    css`
      color: ${itemContainerProps.color};
    `}
  ${({ itemContainerProps }) =>
    itemContainerProps?.fontSize &&
    css`
      font-size: ${itemContainerProps.fontSize};
    `}
`;
const RadioContainer = styled.fieldset`
  border: 0px;
`;

const RadioButtons = styled(DropdownRadio)`
  ${({ localProps }) =>
    localProps?.minWidth &&
    css`
      min-width: ${localProps?.minWidth};
    `}
`;

const ProgressiveQuestionItem = ({
  itemQuestion,
  answerId,
  onButtonClick,
  radioProps = {},
  dropdownProps = {},
  localProps = {},
  itemContainerProps,
  maxButtons,
}) => {
  const renderQuestionText = () => {
    // Only bother if this question has an answer
    if (answerId) {
      // Find this answer's object
      /* eslint react/prop-types: 0 */
      const currentAnswerItem = itemQuestion.answers.find(
        answer => answer.id === answerId,
      );

      // If found and it has content, render that content
      if (currentAnswerItem && currentAnswerItem.content) {
        return <div>{currentAnswerItem.content}</div>;
      }
    }

    return null;
  };

  const renderButtons = () => {
    const radioOptionsForText = itemQuestion.answers
      .filter(id => !id.switchOff === true)
      .map(answer => ({
        value: answer.id,
        label: answer.description,
      }));
    const fieldName = `question${itemQuestion.id}`;
    return (
      <Spacer>
        <RadioContainer>
          <legend>{itemQuestion.title}</legend>
          <FieldInnerBlockContainer>
            <RadioButtons
              fieldName={fieldName}
              errorMsg=""
              options={radioOptionsForText}
              selectedOption={answerId}
              onChange={value => {
                onButtonClick(itemQuestion, value);
              }}
              radioProps={radioProps}
              dropdownProps={dropdownProps}
              localProps={localProps}
              maxButtons={maxButtons}
            />
          </FieldInnerBlockContainer>
        </RadioContainer>
      </Spacer>
    );
  };

  return (
    <Container itemContainerProps={itemContainerProps}>
      <div>
        {renderButtons()}
        {renderQuestionText()}
      </div>
    </Container>
  );
};

ProgressiveQuestionItem.propTypes = ProgressiveQuestionItemPropTypes;

export default ProgressiveQuestionItem;
