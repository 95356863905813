import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage } from 'ageas-ui-components';
import axiosHelper from '../../../helpers/axios';
import statementRequestPropType, {
  statementTextPropType,
} from '../hubStatementBuilderHelper';
import uniqueKeyMapperFactory from '../../../helpers/uniqueKeyMakerFactory';
import P from '../../StandardTags/P';
import StandardInput, {
  getStandardInputRequiredSchema,
} from '../../Forms/StandardInput/StandardInput';
import TelephoneLink from '../../TelephoneLink/TelephoneLink';
import validateField from '../../../helpers/validationHelper';
import {
  getErrorMessage,
  serverErrorCustomTelNoOOH,
} from '../../../helpers/errorMessages';
import ModalForm from '../../Forms/ModalForm/ModalForm';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import useAxiosCancelToken from '../../../hooks/useAxiosCancelToken/useAxiosCancelToken';
import config from '../../../helpers/config';
import { consoleError } from '../../../helpers/consoleLog';

const Container = styled.div`
  margin-bottom: 24px;
`;

const StatementContainer = styled.div`
  margin: 24px 0;
`;

const StatementP = styled.p`
  margin: revert;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  min-height: 0.5em;
`;

const PNoTop = styled(P)`
  margin-top: 0;
`;

const FIELD_NAMES = {
  SIGNATURE: 'signature',
};

const schema = {
  ...getStandardInputRequiredSchema(FIELD_NAMES.SIGNATURE, 'Please confirm'),
};

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const MapStatementText = ({ statementText }) => {
  const { mapper } = uniqueKeyMapperFactory();

  const text = statementText?.map(line => (
    <StatementP key={mapper(line)}>{line}</StatementP>
  ));

  return text?.length ? text : '---No statement found---';
};

MapStatementText.propTypes = {
  statementText: statementTextPropType.isRequired,
};

const axiosCall = (payload, cancelToken) => {
  return axiosHelper.post(
    config.client.signMotorHubStatement_endpoint,
    payload,
    {
      cancelToken,
    },
  );
};

const Declaration = ({
  claimReference,
  statementRequest,
  onSave,
  onCancel,
  onEdit,
  errorTelephone,
}) => {
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createToken] = useAxiosCancelToken();

  const onSubmit = values => {
    if (!isLoading) {
      setServerError(false);
      setIsLoading(true);
      const payload = {
        claimReference,
        sequenceNumber: statementRequest.sequenceNumber,
        signature: { signature: values.signature },
      };
      axiosCall(payload, createToken().token)
        .then(() => {
          onSave();
          // No need to unset isLoading, successful save will close modal
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            consoleError('axios error', error);
            setServerError(true);
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <ModalForm
        title="Statement of Truth"
        onSubmit={onSubmit}
        onCancel={onCancel}
        buttonsMid={[{ text: 'Amend statement details', onClick: onEdit }]}
        fullWidthButtons
        saveButtonText="Confirm statement"
        focusLockProps={{ autoFocus: false }}
      >
        <Container>
          <PNoTop>
            <strong>Your accident summary</strong>
          </PNoTop>
          <hr />
          <StatementContainer>
            <MapStatementText statementText={statementRequest.statementText} />
          </StatementContainer>
          <hr />
          <P>
            Please check the statement that has been generated. If you agree
            this is correct, please carefully read the declaration above and
            digitally sign the statement using your full name. If you don&apos;t
            agree this is correct, please click &apos;Amend statement
            details&apos; to change your answers or call the claim team to
            discuss on <TelephoneLink number={errorTelephone} suffix="." />
          </P>
          <StandardInput
            name={FIELD_NAMES.SIGNATURE}
            label="Please sign by typing your name here"
            validate={validateFields}
            maxLength={100}
          />

          {serverError && (
            <ThemedErrorMessage hasIcon>
              {serverErrorCustomTelNoOOH(
                'motor',
                errorTelephone,
                true,
                'motorClaims',
              )}
            </ThemedErrorMessage>
          )}
        </Container>
      </ModalForm>
    </>
  );
};

Declaration.propTypes = {
  claimReference: PropTypes.string.isRequired,
  statementRequest: statementRequestPropType.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  errorTelephone: PropTypes.string.isRequired,
};

export default Declaration;
