import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const noCoverageWaterClaimQuestion =
  'When would my claim not be covered?';
export const noCoverageWaterClaimContent = (
  <>
    <FaqP marginBottom={false}>
      Common exclusions applicable to an escape or leakage of water claim
      include (but are not limited to) the following:
    </FaqP>
    <StandardUl>
      <li>
        General repairs to the water or heating system (e.g. the cost of a
        plumber to fix the pipework or where the leak is coming from)
      </li>
      <li>
        Loss or damage that has been happening gradually over a period of time
        (e.g. damp, mould, failed sealant around a bath or shower tray)
      </li>
    </StandardUl>
    <FaqP>
      You may also not be covered for loss or damage caused by water overflowing
      from wash basins, sinks, bidets, showers and baths as a results of taps
      being left on. To check, please refer to your policy booklet.
    </FaqP>

    <FaqP>
      <strong>PLEASE NOTE:</strong>
      <br />
      There will be general exclusions that apply to all sections of your policy
      cover. Please refer to your policy booklet for full details of these
      exclusions.
    </FaqP>
  </>
);
