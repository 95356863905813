import Joi from '@hapi/joi';

const isBooleanTrue = (fieldName, message) => {
  // return undefined;
  return Joi.object().pattern(
    Joi.valid(fieldName),
    Joi.boolean()
      .valid(true)
      .error(function buildErrorMessage() {
        return message || `${fieldName} is not boolean true`;
      }),
  );
};

export default isBooleanTrue;
