import React from 'react';

export const brokerDetail = {
  contactList: 'contactList',
  displayError: 'displayError',
  brokerPolicyDetail: 'brokerPolicyDetail',
  displayServerDownError: 'displayServerDownError',
  lineOfBusiness: 'lineOfBusiness',
  application: 'application',
  preferredContact: 'preferredContact',
  contactData: 'contactData',
};

export const BrokerContext = React.createContext({});
