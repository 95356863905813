import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';
import { FieldHeader } from '../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';

export const numberOfPeopleSchema = householdFieldName => {
  return {
    [householdFieldName]: stringRequired(
      [householdFieldName],
      'Please select the number of people in your household',
    ),
  };
};

const NUMBER_LIST = [
  {
    value: 'one',
    label: '1',
  },
  {
    value: 'two',
    label: '2',
  },
  {
    value: 'three',
    label: '3',
  },
  {
    value: 'four',
    label: '4',
  },
  {
    value: 'fivePlus',
    label: '5+',
  },
];

const HouseHoldDetails = ({ name, ...rest }) => {
  const HOUSEHOLD_FIELD_NAME = name;

  return (
    <>
      <FieldHeader>Please confirm the following:</FieldHeader>
      <StandardRadio
        name={HOUSEHOLD_FIELD_NAME}
        title="How many people currently live in your household?"
        options={NUMBER_LIST}
        medium
        vertical
        {...rest}
      />
    </>
  );
};

HouseHoldDetails.propTypes = {
  name: PropTypes.string,
};
HouseHoldDetails.defaultProps = {
  name: undefined,
};

export default HouseHoldDetails;
