const jewelleryItemTypes = [
  { value: 'watch', label: 'Watch' },
  { value: 'earrings', label: 'Earrings' },
  { value: 'ring', label: 'Ring' },
  { value: 'necklace', label: 'Necklace' },
  { value: 'bracelet', label: 'Bracelet' },
  { value: 'brooch', label: 'Brooch' },
  { value: 'chain', label: 'Chain' },
  { value: 'other', label: 'Other' },
];

export const jewelleryItemTypeToObject = jewelleryItemType => {
  return jewelleryItemTypes.find(title => title.value === jewelleryItemType);
};
export const jewelleryItemTypeToText = jewelleryItemType => {
  return jewelleryItemTypeToObject(jewelleryItemType)?.label;
};

export default jewelleryItemTypes;
