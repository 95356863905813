import React from 'react';
import FaqP from '../../FaqP';

export const startedRepairsTitle =
  'I have already started repairs, how will this affect the claim?';
export const startedRepairsContent = (
  <FaqP>
    We would ask that you contact us before you undertake any repairs or clean
    up unless it is essential for protecting the property from further loss or
    damage.
  </FaqP>
);
