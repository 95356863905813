import React from 'react';
import { Input, ErrorMessage } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import ThemedLabel from 'ageas-ui-components/lib/components/ThemedLabel/ThemedLabel';
import FieldStyled from '../Forms/FieldStyled/FieldStyled';

export const carpetProviderDetailsSchema = carpetProviderFieldName => {
  return {
    [carpetProviderFieldName]: stringRequired(
      [carpetProviderFieldName],
      'Please enter details of the store or provider of the carpet',
    ).concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(
        /\S/,
        [carpetProviderFieldName],
        'Please enter details of the store or provider of the carpet',
      ),
    ),
  };
};

const CarpetProviderDetails = props => {
  const { name = 'carpetProvider', validate = () => {} } = props;

  return (
    <FieldStyled name={name} validate={validate}>
      {({ input, meta }) => (
        <>
          <ThemedLabel fieldName={input.name}>
            Please tell us where you bought the carpet from originally
            <div>
              <small>
                If you can&apos;t remember, please enter &quot;unsure&quot;.
              </small>
            </div>
          </ThemedLabel>
          <Input
            size="small"
            maxLength="100"
            id={input.name}
            aria-label="carpet provider details"
            {...input}
            invalidInput={{
              isInvalid: meta.error && meta.touched,
            }}
          />
          {meta.error && meta.touched && (
            <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
          )}
        </>
      )}
    </FieldStyled>
  );
};

CarpetProviderDetails.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
};
CarpetProviderDetails.defaultProps = {
  name: undefined,
  validate: undefined,
};

export default CarpetProviderDetails;
