import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ageas-ui-components';
import {
  OfferValue,
  OfferValuesContainer,
  PZeroTop,
  PanelInnerGrey,
  LabelSetLabel,
  SubText,
  TitleTextSpacedFlex,
} from '../StandardPageWrapper/StandardPageWrapperStyle';
import P from '../StandardTags/P';
import formatAmount from '../../helpers/formatAmount';
import { brand } from '../../../data/whitelabel.config';
import propTypes from './propTypes';
import MotorHubAcceptOfferForm from './MotorHubAcceptOfferForm';

const buildValue = (label, amount, isSummary) => {
  return (
    <OfferValue isSummary={isSummary}>
      <LabelSetLabel>{label}</LabelSetLabel>
      <div>£{formatAmount(amount)}</div>
    </OfferValue>
  );
};

const ASSESSMENT_TEXT = (
  <>
    Following assessment of your claim and based on the information available to
    us, we will be settling this claim on a total loss or write-off basis.{' '}
  </>
);

const getTermsCalc1Text = isTP =>
  !isTP ? (
    <>
      These terms mean that a vehicle has either been stolen and not recovered
      or cannot be repaired to a roadworthy standard because the damage is too
      severe or the cost to repair it would be more than the market value. Your
      policy covers you for the market value of the vehicle, which we calculate{' '}
    </>
  ) : (
    <>
      These terms mean that a vehicle cannot be repaired to a roadworthy
      standard because the damage is too severe or the cost to repair it would
      be more than the market value. We calculate the market value{' '}
    </>
  );

const CALC_2_TEXT = (
  <>
    based upon the condition and mileage of the vehicle at the time of the
    claim, as well as using industry recognised guides such as Glass&apos;s
    Guide and CAP HPI, to assess a fair price.{' '}
  </>
);

const getOfferText = amount => (
  <>
    Taking this information into consideration, we are pleased to be able to
    offer you £{formatAmount(amount)} in settlement of your claim.
  </>
);

const getOfferTextFull = (isTP, amount) => (
  <>
    {ASSESSMENT_TEXT}
    {getTermsCalc1Text(isTP)}
    {CALC_2_TEXT}
    {getOfferText(amount)}
  </>
);

const MotorHubSettlements = ({
  settlementData,
  isLoading,
  tluHandlerTelephone = '-NotFound-',
  isTP = false,
  acceptOfferUrl,
  Context,
}) => {
  if (isLoading) {
    return <></>;
  }

  if (!settlementData?.paymentOffers?.length) {
    return (
      <SubText>
        You currently have no outstanding claim settlement offers.
      </SubText>
    );
  }

  const { amount, excess } = settlementData.paymentOffers[0];
  const { marketValue } = settlementData;

  return (
    <SubText>
      <TitleTextSpacedFlex black>
        {/* green tick */}
        <Icon
          className="icon-warning"
          brandIconSet={brand}
          icon="tick-roundel"
          color="green"
          size={28}
        />
        <span>Settlement offer</span>
      </TitleTextSpacedFlex>

      <P>{getOfferTextFull(isTP, amount)}</P>

      <PanelInnerGrey>
        <PZeroTop>This is how we have calculated your settlement:</PZeroTop>
        {/* amounts list */}
        <OfferValuesContainer>
          {buildValue('Current market value', marketValue || 0)}
          {!isTP && buildValue('Policy excess', excess)}
          {buildValue('Total offer', amount, true)}
        </OfferValuesContainer>
        <MotorHubAcceptOfferForm
          settlementData={settlementData}
          tluHandlerTelephone={tluHandlerTelephone}
          isTP={isTP}
          acceptOfferUrl={acceptOfferUrl}
          Context={Context}
        />
      </PanelInnerGrey>
    </SubText>
  );
};

MotorHubSettlements.propTypes = {
  settlementData: propTypes.settlementData,
  isLoading: PropTypes.bool,
  tluHandlerTelephone: PropTypes.string,
  isTP: PropTypes.bool,
  acceptOfferUrl: PropTypes.string,
  Context: PropTypes.shape({}),
};

MotorHubSettlements.defaultProps = {
  settlementData: undefined,
  isLoading: undefined,
  tluHandlerTelephone: undefined,
  isTP: undefined,
  acceptOfferUrl: undefined,
  Context: undefined,
};

export default MotorHubSettlements;
