import React, { useState, useRef, useEffect, useContext } from 'react';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import media from '../MediaQuery/MediaQuery';
import ThemedIconButton from '../ThemedIconButton/ThemedIconButton';
import { HomeClaimsFNOL } from '../../helpers/ageasTelephoneNumbers';
import P from '../StandardTags/P';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import { StormContext } from '../../helpers/stormContextHelper';
import { serverErrorHome } from '../../helpers/errorMessages';
import LoadingSpinner from '../UI/LoadingSpinner/LoadingSpinner';
import { brand } from '../../../data/whitelabel.config';
import declineCode from '../../helpers/homeDeclineCodes';
import { businessHoursDescriptive } from '../../helpers/businessHoursData';
import NavigateTo from '../Navigation/NavigateTo/NavigateTo';

const CompleteButtonsContainer = styled.div`
  margin-bottom: 1em;
  & > * {
    margin-right: 6px;
    ${media.phone`
      margin-right: 0;
    `}
  }
`;

const Theme = getTheme(brand);

const HailSnowCriteriaMet = ({
  isEndorsementRefer,
  isWeatherRefer,
  isWeatherDecline,
  applicableExcess,
  claimReference,
  weatherConditionMet,
}) => {
  const [serverError, setServerError] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCompleteButton, setShowCompleteButton] = useState(true);
  const [navigatePath, setNavigatePath] = useState(null);
  const [stormReference, setStormReference] = useState({});
  const { dataState: weatherDataContext, clearAllDataState } =
    useContext(StormContext);

  const stormRejectHomeClaim = {
    claimReference,
    reason: declineCode.UNECONOMIC,
  };

  const stormAcceptHomeClaim = {
    claimReference,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const claimReferenceSection = () => {
    return (
      <P>
        Your claim reference for this incident is{' '}
        <strong>{claimReference}</strong>.
      </P>
    );
  };

  const footerSection = () => {
    return (
      <P>
        One of our claims handlers will be in touch by the end of the next
        working day.
      </P>
    );
  };

  const renderStormThanksPage = (accept, supplier) => {
    const stormData = {
      supplierName: supplier ? supplier.supplierName : '',
      supplierNumber: supplier ? supplier.supplierTelephone : '',
      userContactNumber: weatherDataContext?.userContactNumber,
      isAccept: accept,
      isReferredData: isWeatherRefer,
      isComplete: true,
      isClaimRef: claimReference,
    };

    setStormReference(stormData);
    setIsLoading(false);
    setNavigatePath('/homeclaim/storm/thankyoustormclaim');
    clearAllDataState();
  };

  const postRequest = (endpoint, payload, accept) => {
    setServerError(false);
    setShowCompleteButton(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(endpoint, payload, {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(data => {
        setIsLoading(false);
        setServerError(false);
        renderStormThanksPage(accept, data?.data?.supplierDetails);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
          setShowCompleteButton(true);
        }
      });
  };
  const onClickAccept = () => {
    postRequest(
      config.client.acceptHomeClaim_endpoint,
      stormAcceptHomeClaim,
      true,
    );
  };

  const onClickDecline = () => {
    postRequest(
      config.client.rejectHomeClaim_endpoint,
      stormRejectHomeClaim,
      false,
    );
  };

  const renderButtonComponent = () => {
    return (
      showCompleteButton && (
        <CompleteButtonsContainer>
          <ThemedButton
            type="button"
            primary
            inverted
            onClick={() => onClickDecline()}
          >
            No
          </ThemedButton>
          <ThemedIconButton
            type="button"
            secondary
            onClick={() => onClickAccept()}
            iconRight={faChevronCircleRight}
          >
            Yes - proceed
          </ThemedIconButton>
        </CompleteButtonsContainer>
      )
    );
  };

  const renderServerErrorComponent = () => {
    return (
      serverError && (
        <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
      )
    );
  };

  const renderPleaseNoteComponent = () => {
    return (
      <P>
        Please note that your policy has an excess of{' '}
        <strong>£{applicableExcess}</strong>. This is the amount you{' '}
        {isWeatherRefer ? 'will ' : ''}
        have to pay towards the cost of your claim. Would you like to proceed
        with {isWeatherRefer ? 'the referral of your' : 'this'} claim?
      </P>
    );
  };

  const renderButtonOptionFooterSection = () => {
    return (
      <>
        {renderPleaseNoteComponent()}
        <br />
        {renderButtonComponent()}
        {renderServerErrorComponent()}
        {isLoading && <LoadingSpinner />}
      </>
    );
  };

  // TODO - Further refactor maybe required - by components for ease of testing
  const textSectionsToRender = Object.freeze({
    condition1: (
      <>
        <P>
          The required weather conditions have been met, however, due to
          endorsements that you have on your policy, your claim will need to be
          referred.
        </P>
        {claimReferenceSection()}
        {footerSection()}
      </>
    ),
    condition2: (
      <>
        <P>
          Due to the weather conditions reported and endorsements that you have
          on your policy, your claim will need to be referred for review.
        </P>
        {claimReferenceSection()}
        {footerSection()}
      </>
    ),
    condition3: (
      <>
        <P>
          Unfortunately, we are unable to consider a claim for the damage to
          your property as the weather conditions have not met the required
          criteria detailed in your policy.
        </P>
        <P>
          Your claim reference for this incident is{' '}
          <strong>{claimReference}</strong>. Should you wish to discuss the
          matter further, please call the claims team on <HomeClaimsFNOL />{' '}
          between the hours of {businessHoursDescriptive}.
        </P>
      </>
    ),
    condition4: (
      <>
        <P>
          Due to the weather conditions reported, your claim will need to be
          referred for review.
        </P>
        {renderButtonOptionFooterSection()}
      </>
    ),
    condition5: (
      <>
        <P>
          As the required weather conditions have been met, we are able to
          consider a claim for the damage to your property.
        </P>
        {renderButtonOptionFooterSection()}
      </>
    ),
  });

  return (
    <ThemeProvider theme={Theme}>
      <NavigateTo path={navigatePath} data={stormReference} />

      {weatherConditionMet &&
        !isWeatherRefer &&
        isEndorsementRefer &&
        textSectionsToRender.condition1}

      {isWeatherRefer && isEndorsementRefer && textSectionsToRender.condition2}

      {isWeatherDecline && textSectionsToRender.condition3}

      {!isEndorsementRefer &&
        weatherConditionMet &&
        isWeatherRefer &&
        textSectionsToRender.condition4}

      {!isEndorsementRefer &&
        weatherConditionMet &&
        !isWeatherRefer &&
        textSectionsToRender.condition5}
    </ThemeProvider>
  );
};

HailSnowCriteriaMet.propTypes = {
  isEndorsementRefer: PropTypes.bool,
  isWeatherRefer: PropTypes.bool,
  isWeatherDecline: PropTypes.bool,
  applicableExcess: PropTypes.number,
  claimReference: PropTypes.string,
  weatherConditionMet: PropTypes.bool,
};

HailSnowCriteriaMet.defaultProps = {
  isEndorsementRefer: false,
  isWeatherRefer: false,
  isWeatherDecline: false,
  applicableExcess: 100,
  claimReference: undefined,
  weatherConditionMet: false,
};

export default HailSnowCriteriaMet;
