/* istanbul ignore file */
// Externalise these so they can be mocked in jest without affecting jest
// itself
// (console logs slow tests down)

/* eslint-disable no-console */
export const consoleError = (...x) => console.error(...x);
/* eslint-disable no-console */
export const consoleLog = (...x) => console.log(...x);
/* eslint-disable no-console */
export const consoleWarn = (...x) => console.warn(...x);
