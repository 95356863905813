import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import {
  relationship,
  maritalStatus as maritalStatusLookup,
} from '../constants/LookupValues';

/* need to return 
    Joi.object().pattern(Joi.valid(fieldNames.maritalStatusKeys), Joi.string().required().
        concat(Joi.string().when(RelationshipField, {
            is: 'SPOUSEREL',
            then: Joi.string().equal('M').required().error(() => messages["SPOUSE"])
        })).concat(Joi.string().when(RelationshipField, {
            is: 'CivPrtnr',
            then: Joi.string().equal('V').required().error(() => messages["CIVIL_PARTNER"])
        })
        ).required()
    );   
    but using config file to prevent hardcoding responses
*/

const MaritalSetup = {
  RelationshipField: 'additionalInsuredRelationshipToProposer',
  MaritalStatusWhen: [
    {
      is: relationship.spouse,
      then: maritalStatusLookup.married,
      message: 'SPOUSE',
    },
    {
      is: relationship.civilPartner,
      then: maritalStatusLookup.civilPartnered,
      message: 'CIVIL_PARTNER',
    },
  ],
  MaritalStatusFields: [
    'proposerMaritalStatus',
    'additionalInsuredMaritalStatus',
  ],
};

const MaritalSetupAddDriver = {
  RelationshipField: 'customerRelation',
  MaritalStatusWhen: [
    {
      is: relationship.spouse,
      then: maritalStatusLookup.married,
      message: 'MUST_BE_MARRIED',
    },
    {
      is: relationship.civilPartner,
      then: maritalStatusLookup.civilPartnered,
      message: 'SINGLE_AND_CIVIL_PARTNER',
    },
  ],
  MaritalStatusFields: ['newDriverMaritalStatus'],
};

function getSubSchemas(config) {
  return config.MaritalStatusWhen.map(item => {
    return Joi.string().when(config.RelationshipField, {
      is: item.is,
      then: Joi.string()
        .equal(item.then)
        .required()
        .error(() => messages[item.message]),
    });
  });
}

const mergeSchemas = schemas => {
  let mergedSchema = '';
  schemas.forEach(schema => {
    if (mergedSchema === '') {
      mergedSchema = schema;
    } else {
      mergedSchema = mergedSchema.concat(schema);
    }
  });
  return mergedSchema;
};

/**
 * @description: Uses a config object to validate consistency between fields. If the additional insured relationship
 * to proposer is spouse then they should have marital status to reflect this the same is true for the propser marital status
 * @param config an object containing th marital status fields and lookup values used. Readme.md contains details of configuration object
 * @succeeds when relationship is consistent with marital status
 * @fails when relationship fields are not consistent with marital status. e.g when relationship to proposer is 'Married' but marital status is 'Single'
 */
export const maritalStatus = (config = MaritalSetup) => {
  return Joi.object()
    .pattern(
      Joi.valid(config.MaritalStatusFields),
      mergeSchemas(getSubSchemas(config)),
    )
    .required();
};

export const maritalStatusAddDriver = (config = MaritalSetupAddDriver) => {
  return Joi.object()
    .pattern(
      Joi.valid(config.MaritalStatusFields),
      mergeSchemas(getSubSchemas(config)),
    )
    .required();
};

export default maritalStatus;
