import React from 'react';
import { Switch } from 'react-router-dom';
import NotFoundRoute from '../NotFoundRoute';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import HubClaimsHistoryHome from '../../views/ClaimsHistory/HubClaimsHistoryHome';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  HubContext,
  localStorageHubPropertyName,
} from '../../helpers/hubContextHelper';
import ChatBot from '../../components/ChatBot/ChatBot';
import HomeHubClaimDetails from '../../views/HomeHubClaimDetails/HomeHubClaimDetails';
import EclaimsRoute from '../EclaimsRoute';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import config from '../../helpers/config';

const hubClaimsPrivateProps = {
  private: true,
  authTypes: ['otp'],
  lobs: 'home',
};

const HomeClaimsHubRoutes = () => {
  return (
    <DataContextProvider
      Context={HubContext}
      localStoragePropertyName={localStorageHubPropertyName}
      localStorageExpiryDays={config.client.localStorageHubExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path="/homehub"
          component={HubClaimsHistoryHome}
          {...hubClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path="/homehub/claimdetails"
          component={HomeHubClaimDetails}
          {...hubClaimsPrivateProps}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
      <ChatBot />
    </DataContextProvider>
  );
};

export default HomeClaimsHubRoutes;
