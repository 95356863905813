/* THIS SHOULD MATCH THE FILE IN THE PORTAL WHITELABEL REPO */

/* Ageas Brand Guideline colours */

/* ** Do not access these colours directly from Components! ** */

const ColorTheme = {
  /* Basic Colours */
  black: '#000',
  white: '#FFF',
  link: '#0000EE',
  warning: '#facb32',
  error: '#EE5623',

  /* Ageas Primary/Purple */
  primary: '#8E419C',
  primaryLight: '#A962B6',
  primaryLighter: '#FAE9FD',
  primaryDark: '#712D7D',
  primaryDarker: '#591F64',

  /* Ageas Secondary/Green */
  secondary: '#90BC0D',
  secondaryLight: '#CDE6A0',
  secondaryDark: '#6FA313',

  /* Ageas Grey */
  grey: '#C7CBDB',
  greyLight: '#EBEBEB',
  greyLighter: '#F5F5F5', // NEWLY ADDED
  greyLightest: '#FAFAFA',
  greyDark: '#B3B3B3',
  greyDarker: '#646464',
  greyDarkest: '#2C2C2C',

  /* Ageas Pink */
  pink: '#D30773',
  pinkLight: '#FAE9FD',

  /* Ageas Blue */
  blue: '#33D9EC',
  blueLight: '#DDF1F3',
  blueDark: '#BCE4E9',
};

export default ColorTheme;
