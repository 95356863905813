import crypto from 'crypto-browserify';
import { Buffer } from 'buffer/';
import Config from './config';

const internalKey = Config.client.clientEncryptionKey;
const algorithm = 'aes-256-cbc';

const getIVFromKey = keyIn => {
  let key = keyIn;
  if (key.length < 16) {
    key = key.concat(internalKey);
  }
  return key.slice(0, 16);
};

const getKey = key => {
  return key.concat(internalKey).slice(0, 32);
};

const encrypt = (data, key) => {
  try {
    const cipher = crypto.createCipheriv(
      algorithm,
      getKey(key),
      getIVFromKey(key),
    );
    return Buffer.concat([
      Buffer.from(cipher.update(data)),
      Buffer.from(cipher.final()),
    ]).toString('hex');
  } catch (e) {
    return false;
  }
};

const decrypt = (data, key) => {
  try {
    const decipher = crypto.createDecipheriv(
      algorithm,
      getKey(key),
      getIVFromKey(key),
    );
    return Buffer.concat([
      Buffer.from(decipher.update(Buffer.from(data, 'hex'))),
      Buffer.from(decipher.final()),
    ]).toString();
  } catch (e) {
    return false;
  }
};

export { encrypt, decrypt };
