export const updateNotifications = (
  hubContext,
  hubDataContext,
  newNotifications,
) => {
  hubContext.updateDataState(hubDataContext.notifications, newNotifications);
};

export const updateNotificationStatus = (
  hubContext,
  hubDataContext,
  sequence,
  newStatus,
) => {
  const newNotifications = [...hubContext.dataState.notifications];
  const newNotification = newNotifications.find(
    notification =>
      notification.sequence === sequence && notification.status !== newStatus,
  );
  if (newNotification) {
    newNotification.status = newStatus;
    updateNotifications(hubContext, hubDataContext, newNotifications);
  }
};

export const findNotifications = (hubContext, searchCode, searchStatus) =>
  hubContext.dataState.notifications.filter(
    ({ code, status }) => code === searchCode && status === searchStatus,
  );

export const closeNotificationsByCode = (
  hubContext,
  hubDataContext,
  searchCode,
) =>
  findNotifications(hubContext, searchCode, 'open').forEach(({ sequence }) => {
    updateNotificationStatus(hubContext, hubDataContext, sequence, 'closed');
  });
