import { getStandardInputRequiredSchema } from '../../components/Forms/StandardInput/StandardInput';
import { getStandardDropdownSchema } from '../../components/Forms/StandardDropdown/StandardDropdown';
import { yesOrNoSchema } from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';

import { getInvolvedPartySchema } from '../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import { getIncidentDateValidationSchema } from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';

export const COUNTRIES_LIST = [
  {
    value: 'england',
    label: 'England',
  },
  {
    value: 'scotland',
    label: 'Scotland',
  },
  {
    value: 'wales',
    label: 'Wales',
  },
  {
    value: 'northernIreland',
    label: 'Northern Ireland',
  },
  {
    value: 'channelIsland',
    label: 'Channel Islands',
  },
];

export const DAMAGE_OCCURRED_LIST = [
  { value: 'animal', label: 'Contact with an animal' },
  { value: 'property', label: 'Impact with other property' },
  { value: 'roadSurface', label: 'Due to the road surface' },
  { value: 'floodWater', label: 'Caused by flood water' },
  { value: 'other', label: 'Other' },
];

export const ANIMAL_TYPE_LIST = [
  { value: 'dogHorseFarmAnimal', label: 'Dog/Horse/Farm animal' },
  { value: 'other', label: 'Other' },
];

export const PROPERTY_TYPE_DAMAGED_LIST = [
  { value: 'building', label: 'Building' },
  { value: 'crashBarrier', label: 'Crash Barrier' },
  { value: 'fence', label: 'Fence' },
  { value: 'streetFurniture', label: 'Street Furniture' },
  { value: 'wall', label: 'Wall' },
  { value: 'other', label: 'Other' },
];

export const ROAD_SURFACE_ISSUE_TYPE_LIST = [
  { value: 'spillageMud', label: 'Spillage/Mud' },
  { value: 'pothole', label: 'Pothole' },
  { value: 'ungrittedRoad', label: 'Ungritted road' },
];

export const getInvolvedPartyTypeList = companyLabel => {
  return [
    { value: 'individual', label: 'Private Individual' },
    { value: 'company', label: companyLabel || 'Company' },
  ];
};
export const incidentDetailsFields = {
  INCIDENT_DATE: 'incidentDate',
  INCIDENT_TIME: 'incidentTime',
  VEHICLE_REGISTRATION: 'vehicleRegistration',
  INCIDENT_STREET: 'incidentStreet',
  INCIDENT_TOWN: 'incidentTown',
  INCIDENT_COUNTRY: 'incidentCountry',
  HOW_DAMAGE_HAPPENED: 'howDamageHappened',
  INCIDENT_DETAILS: 'incidentDetails',
  ANIMAL_TYPE: 'animaltype',
  ANIMAL_OWNER_KNOWN: 'animalOwnerKnown',
  ANIMAL_OWNER_RESPONSIBLE: 'animalOwnerResponsible',
  ANIMAL_OWNER_DETAILS: 'animalOwnerDetails',
  PROPERTY_TYPE_DAMAGED: 'propertyTypeDamaged',
  PROPERTY_TYPE_DAMAGED_OTHER_DETAILS: 'propertyTypeDamagedOtherDetails',
  PROPERTY_OWNER_KNOWN: 'propertyOwnerKnown',
  PROPERTY_OWNER_TYPE: 'propertyOwnerType',
  PROPERTY_OWNER_DETAILS: 'propertyOwnerDetails',
  ROAD_SURFACE_ISSUE_TYPE: 'roadSurfaceIssueType',
  SPILLAGE_MUD_WARNING_SIGNS: 'spillageMudWarningSigns',
  SPILLAGE_MUD_WHO_CAUSED_KNOWN: 'spillageMudWhoCausedKnown',
  SPILLAGE_MUD_WHO_CAUSED_TYPE: 'spillageMudWhoCausedType',
  SPILLAGE_MUD_WHO_CAUSED_DETAILS: 'spillageMudWhoCausedDetails',
  SPILLAGE_MUD_WHEN_APPEARED_KNOWN: 'spillageMudWhenAppearedKnown',
  SPILLAGE_MUD_WHEN_APPEARED: 'spillageMudWhenAppeared',
  POTHOLE_COUNCIL_DETAILS: 'potholeCouncilDetails',
  UNGRITTED_ROAD_MAIN_ROAD: 'ungrittedRoadMainRoad',
  UNGRITTED_ROAD_ACCESS_ROAD: 'ungrittedRoadAccessRoad',
  UNGRITTED_ROAD_PUBLIC_TRANSPORT: 'ungrittedRoadPublicTransport',
  UNGRITTED_ROAD_OWNER_KNOWN: 'ungrittedRoadOwnerKnown',
  UNGRITTED_ROAD_OWNER_TYPE: 'ungrittedRoadOwnerType',
  UNGRITTED_ROAD_OWNER_DETAILS: 'ungrittedRoadOwnerDetails',
  HAS_CCTV: 'hasCCTV',
};

export const schema = {
  ...getIncidentDateValidationSchema(incidentDetailsFields.INCIDENT_DATE),
  ...getStandardInputRequiredSchema(incidentDetailsFields.INCIDENT_STREET),
  ...getStandardInputRequiredSchema(incidentDetailsFields.INCIDENT_TOWN),
  ...getStandardDropdownSchema(incidentDetailsFields.INCIDENT_COUNTRY),
  ...getStandardDropdownSchema(incidentDetailsFields.HOW_DAMAGE_HAPPENED),
  ...getStandardInputRequiredSchema(
    incidentDetailsFields.INCIDENT_DETAILS,
    'Please enter details',
  ),
  ...getStandardDropdownSchema(incidentDetailsFields.ANIMAL_TYPE),
  ...yesOrNoSchema(incidentDetailsFields.ANIMAL_OWNER_KNOWN),
  ...yesOrNoSchema(incidentDetailsFields.ANIMAL_OWNER_RESPONSIBLE),
  ...getInvolvedPartySchema(incidentDetailsFields.ANIMAL_OWNER_DETAILS),
  ...getStandardDropdownSchema(incidentDetailsFields.PROPERTY_TYPE_DAMAGED),
  ...getStandardInputRequiredSchema(
    incidentDetailsFields.PROPERTY_TYPE_DAMAGED_OTHER_DETAILS,
    'Please enter details',
  ),
  ...yesOrNoSchema(incidentDetailsFields.PROPERTY_OWNER_KNOWN),
  ...yesOrNoSchema(incidentDetailsFields.PROPERTY_OWNER_TYPE),
  ...getInvolvedPartySchema(incidentDetailsFields.PROPERTY_OWNER_DETAILS),
  ...getStandardDropdownSchema(incidentDetailsFields.ROAD_SURFACE_ISSUE_TYPE),
  ...yesOrNoSchema(incidentDetailsFields.SPILLAGE_MUD_WARNING_SIGNS),
  ...yesOrNoSchema(incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_KNOWN),
  ...yesOrNoSchema(incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_TYPE),
  ...getInvolvedPartySchema(
    incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_DETAILS,
  ),
  ...yesOrNoSchema(incidentDetailsFields.SPILLAGE_MUD_WHEN_APPEARED_KNOWN),
  ...getStandardInputRequiredSchema(
    incidentDetailsFields.SPILLAGE_MUD_WHEN_APPEARED,
    'Please enter details',
  ),
  ...getInvolvedPartySchema(incidentDetailsFields.POTHOLE_COUNCIL_DETAILS),
  ...yesOrNoSchema(incidentDetailsFields.UNGRITTED_ROAD_MAIN_ROAD),
  ...yesOrNoSchema(incidentDetailsFields.UNGRITTED_ROAD_ACCESS_ROAD),
  ...yesOrNoSchema(incidentDetailsFields.UNGRITTED_ROAD_PUBLIC_TRANSPORT),
  ...yesOrNoSchema(incidentDetailsFields.UNGRITTED_ROAD_OWNER_KNOWN),
  ...yesOrNoSchema(incidentDetailsFields.UNGRITTED_ROAD_OWNER_TYPE),
  ...getInvolvedPartySchema(incidentDetailsFields.UNGRITTED_ROAD_OWNER_DETAILS),
  ...yesOrNoSchema(incidentDetailsFields.HAS_CCTV),
};
