import React from 'react';
import PropTypes from 'prop-types';
import StandardInput, {
  getStandardInputRequiredSchema,
} from '../../StandardInput/StandardInput';
import StandardDropdown, {
  getStandardDropdownSchema,
} from '../../StandardDropdown/StandardDropdown';
import titleCodes from '../../../../helpers/titleCodes';

const DEFAULT_FIELD_NAME = 'involvedParty';

export const getInvolvedPartyNameSchema = fieldName => {
  return {
    ...getStandardInputRequiredSchema(
      `${fieldName}.companyName`,
      'Please enter a name',
    ),
    ...getStandardDropdownSchema(`${fieldName}.title`, 'Please select a title'),
    ...getStandardInputRequiredSchema(
      `${fieldName}.firstName`,
      'Please enter a first name',
    ),
    ...getStandardInputRequiredSchema(
      `${fieldName}.lastName`,
      'Please enter a last name',
    ),
  };
};

const InvolvedPartyName = ({
  name = DEFAULT_FIELD_NAME,
  validate,
  company,
  companyLabel,
}) => {
  return (
    <>
      {company ? (
        <StandardInput
          name={`${name}.companyName`}
          label={companyLabel || 'Company name'}
          aria-label={companyLabel || 'Company name'}
          validate={validate}
          medium
          maxLength="100"
        />
      ) : (
        <>
          <StandardDropdown
            name={`${name}.title`}
            label="Title"
            aria-label="Title"
            validate={validate}
            options={titleCodes}
            blockContainerProps={{ small: true, minWidth: '228px' }}
            alwaysShowPlaceholder
            large
          />
          <StandardInput
            name={`${name}.firstName`}
            label="First name"
            aria-label="First name"
            validate={validate}
            small
            maxLength="20"
          />
          <StandardInput
            name={`${name}.lastName`}
            label="Last name"
            aria-label="Last name"
            validate={validate}
            small
            maxLength="40"
          />
        </>
      )}
    </>
  );
};

InvolvedPartyName.propTypes = {
  name: PropTypes.string,
  headerProps: PropTypes.shape({}),
  labelling: PropTypes.shape({
    commonLabel: PropTypes.string,
    'aria-label': PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
  }),
  company: PropTypes.bool,
  companyLabel: PropTypes.string,
  validate: PropTypes.func,
};
InvolvedPartyName.defaultProps = {
  name: undefined,
  headerProps: undefined,
  labelling: undefined,
  company: false,
  companyLabel: undefined,
  validate: undefined,
};

export default InvolvedPartyName;
