import PropTypes from 'prop-types';
import React from 'react';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';
import StandardRadio from '../../../components/Forms/StandardRadio/StandardRadio';
import {
  getInvolvedPartyTypeList,
  incidentDetailsFields,
} from '../motorIncidentDetailsHelper';

const IncidentDamageRoadSurfaceMudFields = ({ values, validateFields }) => {
  return (
    <>
      <YesOrNo
        name={incidentDetailsFields.SPILLAGE_MUD_WARNING_SIGNS}
        title="Were there any warning signs of this?"
        validate={validateFields}
      />
      <YesOrNo
        name={incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_KNOWN}
        title="Do you know who caused the spillage?"
        validate={validateFields}
      />
      {values[incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_KNOWN] ===
        'yes' && (
        <>
          <StandardRadio
            name={incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_TYPE}
            title="Was the spillage caused by a private individual or a company/local authority?"
            options={getInvolvedPartyTypeList('Company/Local Authority')}
            medium
            vertical
            validate={validateFields}
          />
          {!!values[incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_TYPE] && (
            <>
              <InvolvedParty
                name={incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_DETAILS}
                header="Please confirm the name, address and contact details of the person who caused the spillage"
                values={values}
                company={
                  values[incidentDetailsFields.SPILLAGE_MUD_WHO_CAUSED_TYPE] ===
                  'company'
                }
                companyLabel="Company or local authority name"
                validate={validateFields}
              />
              <YesOrNo
                name={incidentDetailsFields.SPILLAGE_MUD_WHEN_APPEARED_KNOWN}
                title="Do you know when the spillage appeared?"
                validate={validateFields}
              />
              {values[
                incidentDetailsFields.SPILLAGE_MUD_WHEN_APPEARED_KNOWN
              ] === 'yes' && (
                <StandardInput
                  name={incidentDetailsFields.SPILLAGE_MUD_WHEN_APPEARED}
                  label="Please tell us when the spillage appeared"
                  // aria-label="Please tell us when the spillage appeared"
                  small
                  maxLength="30"
                  validate={validateFields}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IncidentDamageRoadSurfaceMudFields;

IncidentDamageRoadSurfaceMudFields.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  validateFields: PropTypes.func,
};

IncidentDamageRoadSurfaceMudFields.defaultProps = {
  validateFields: undefined,
};
