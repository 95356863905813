/**
 * Map JSON Blob returned from server to context/pageForm format
 * required for Contact Details page
 * @param {*} claimData property of JSON Blob
 * @returns data in Contact Details page form format, or undefined if section
 * not present
 */
export const mapServerToContactDetailsPage = ({ contactDetails } = {}) => {
  if (!contactDetails) {
    return undefined;
  }
  const { mobileTelephone, email } = contactDetails;
  // Return mapped object
  return {
    mobileTelephone: mobileTelephone?.trim(),
    emailAddress: email?.trim(),
  };
};

export default mapServerToContactDetailsPage;
