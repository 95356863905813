const ROOT = '/motortphub';
const LOGIN = `${ROOT}/login`;
const VALIDATE_CONTACT = `${LOGIN}/validatecontact`;
const OTP_VERIFY = `${LOGIN}/otpverify`;
const PATHS = {
  ROOT,
  LOGIN,
  VALIDATE_CONTACT,
  OTP_VERIFY,
  POLICY_DETAILS_VERIFY: undefined,
};

export const getTPAPaths = () => PATHS;

export default getTPAPaths;
