import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  TitleText,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import { brand } from '../../../data/whitelabel.config';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import P from '../../components/StandardTags/P';
import {
  ExcessNoAcceptText,
  ReferredText,
} from '../ThankYouEClaim/ThankYouEClaimHome';
import config from '../../helpers/config';

const Theme = getTheme(brand);

const ThankYouStormClaim = history => {
  const [stormResultData, setStormResultData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setStormResultData(history?.history?.location?.state);
  }, []);

  const renderResult = () => {
    // Not accepted.
    if (stormResultData?.isAccept === false) {
      return (
        <ExcessNoAcceptText claimReference={stormResultData?.isClaimRef} />
      );
    }

    // Accepted and referred
    if (stormResultData?.isReferredData) {
      return (
        <ReferredText
          claimReference={stormResultData?.isClaimRef}
          itemDescription="storm"
        />
      );
    }

    // Accepted and not referred
    return (
      <>
        <TitleText>Thanks. Your storm claim has been submitted.</TitleText>
        <strong>
          <P>What happens next?</P>
        </strong>
        <P>
          Your claim reference is <strong>{stormResultData?.isClaimRef}</strong>
          .
        </P>
        <P>
          We have instructed <strong>{stormResultData?.supplierName}</strong>,
          who are storm specialist surveyors, to inspect the damage to your
          property and confirm whether it has been caused by the weather
          conditions.
        </P>
        <P>
          They’ll phone you on{' '}
          <strong>{stormResultData?.userContactNumber}</strong> within{' '}
          {config.client.text.stormSurveyorSla} from now to make an appointment
          that is convenient for you.
        </P>
        <P>
          Their contact details, should you need them, are{' '}
          <strong>{stormResultData?.supplierNumber}.</strong> A text containing
          this information will be sent to you shortly.
        </P>
        <P>
          We will also send details of your personal claims handler by text and
          email to the number and address you provided earlier.
        </P>
        <P>Thank you for making your claim.</P>
      </>
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <ClaimBreadcrumbStandard activeItem="thankYou" />
        <PageSectionBare>
          <Subsection>
            {stormResultData?.isComplete && renderResult()}
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default ThankYouStormClaim;
