import axios from 'axios';
import { motorClaimDataContext } from './motorClaimHelperNew';
import {
  updateLocalPageSection,
  mapLocalToServer,
  updateLocalMeta,
  updateLocalPageSectionUnvalidated,
} from './motorClaimNewDataMapper/motorClaimNewDataMapper';
import axiosHelper from './axios';
import config from './config';
import { consoleError } from './consoleLog';

const buildUpdateClaimRequest = (dataState, claimEntryData) => {
  return {
    claimReference: dataState.claimDetails.claimReference,
    policyReference: dataState.claimDetails.policyReference,
    ...mapLocalToServer(claimEntryData),
  };
};

export const updateLocalPageSectionIntoContext = (
  dataState,
  section,
  values,
  updateDataState,
  clearUnvalidated = false,
) => {
  const claimEntryData = updateLocalPageSection(
    dataState.claimEntryData,
    section,
    values,
  );
  if (clearUnvalidated && claimEntryData?.unvalidated?.[section]) {
    delete claimEntryData.unvalidated[section];
  }
  updateDataState(motorClaimDataContext.claimEntryData, claimEntryData);
  return claimEntryData;
};

export const updateLocalPageSectionIntoContextUnvalidated = (
  dataState,
  section,
  values,
  updateDataState,
) => {
  const claimEntryData = updateLocalPageSectionUnvalidated(
    dataState.claimEntryData,
    section,
    values,
  );
  updateDataState(motorClaimDataContext.claimEntryData, claimEntryData);
  return claimEntryData;
};

const updateContinuableMotorClaimData = async (
  section,
  values,
  dataState,
  updateDataState,
  cancelToken,
  submit,
) => {
  // Data should be validated by now, so save to actual and remove unvalidated
  let claimEntryData = updateLocalPageSectionIntoContext(
    dataState,
    section,
    values,
    updateDataState,
    true,
  );

  const updateClaimRequest = buildUpdateClaimRequest(dataState, claimEntryData);
  if (submit) {
    updateClaimRequest.submit = true;
  }

  try {
    const result = await axiosHelper.post(
      config.client.updateContinuableMotorClaimData_endpoint,
      updateClaimRequest,
      {
        cancelToken,
      },
    );
    const { uid, stage } = result.data;
    claimEntryData = updateLocalMeta(claimEntryData, { uid, stage });
    updateDataState(motorClaimDataContext.claimEntryData, claimEntryData);
    return { success: true, result, claimEntryData };
  } catch (error) {
    if (!axios.isCancel(error)) {
      const errorCode = error.response?.data?.data?.errorCode;
      const errorResponse = { error, claimEntryData };
      if (error.response?.status === 422 && errorCode === 'CONCURRENT_UPDATE') {
        errorResponse.errorType = 'concurrent';
      } else {
        // Generic error
        consoleError('axios error', error);
        errorResponse.errorType = 'generic';
      }
      return errorResponse;
    }
    return undefined;
  }
};

export default updateContinuableMotorClaimData;
