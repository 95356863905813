import React from 'react';
import FaqP from '../../FaqP';

export const excessPayCollectTitle =
  'What is my excess, when will I have to pay it and how will you collect it?';
export const excessPayCollectContent = (
  <>
    <FaqP>Your excess can be found on your policy documentation.</FaqP>
    <FaqP>
      You will either pay the repair network directly or we will deduct the
      excess from the total to be paid, when we settle the claim with cash.
    </FaqP>
  </>
);
