import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import config from '../../helpers/config';
import { encrypt, decrypt } from '../../helpers/encryptionHelper';

// Provides a data context, and a provider with state and a per-data-set
// update function
// context structure is:
//   {
//     updateDataState: function,
//     dataState: {
//       data1:{...},
//       data2:{...},
//       data3:{...},
//     },
//   }
// updateDataState has parameters:
//   pageDataName: property of dataState e.g. data1
//   pageDataState: data state object.
// So this:
//   updateDataState("data1",{abcd})
// will do this:
//   dataState["data1"] = {abcd}
// and then update the state context with the updated data.

export const getLocalStorageState = (
  localStoragePropertyName,
  localStorageGetEncryptUserKey,
) => {
  // Get value from localStorage
  // localStorage only accessible if Window object present
  // (i.e. not server side rendering)
  // If no window object, return empty object
  if (typeof window === 'undefined') {
    return {};
  }

  let data;
  try {
    data = JSON.parse(localStorage.getItem(localStoragePropertyName));
  } catch (e) {
    // Use original undefined value for data
  }
  if (!data) {
    return {};
  }

  // Decrypting payload? If yes, replace with parsed decrypted
  if (
    config.client.clientEncryptLocalStorage &&
    localStorageGetEncryptUserKey &&
    data.payload &&
    typeof data.payload === 'string'
  ) {
    let payload;
    try {
      payload = JSON.parse(
        decrypt(data.payload, localStorageGetEncryptUserKey()) || data.payload,
      );
    } catch (e) {
      // Do nothing
    }
    data.payload = payload;
  }

  return data;
};

export const setLocalStorageState = (
  dataIn,
  localStoragePropertyName,
  localStorageGetEncryptUserKey,
) => {
  // Set value in localStorage
  // localStorage only accessible if Window object present
  // (i.e. not server side rendering)
  // If no window object, return undefined
  if (typeof window !== 'undefined') {
    const data = dataIn;
    // Encrypting payload? If yes, replace with stringified encrypted
    if (
      config.client.clientEncryptLocalStorage &&
      localStorageGetEncryptUserKey &&
      data.payload
    ) {
      const encryptionKey = localStorageGetEncryptUserKey();
      // Do not save if key is a falsey value
      if (!encryptionKey) {
        return;
      }
      data.payload = encrypt(JSON.stringify(data.payload), encryptionKey) || '';
    }

    localStorage.setItem(localStoragePropertyName, JSON.stringify(data));
  }
};

export const validateExpireLocalStorage = (
  localStoragePropertyName,
  localStorageExpiryDays,
  localStorageGetMatchUID,
  localStorageGetEncryptUserKey,
) => {
  // Retrieve localStorage
  // Separate meta portion from the rest of the object.
  const { meta, payload } = getLocalStorageState(
    localStoragePropertyName,
    localStorageGetEncryptUserKey,
  );
  const { meta: dataMeta, ...data } = payload || {};

  // Validate user - clear localStorage and return {} if logged in to a
  // different user
  if (localStorageGetMatchUID && dataMeta?.uid) {
    const uid = localStorageGetMatchUID();
    if (uid && uid !== dataMeta.uid) {
      localStorage.removeItem(localStoragePropertyName);
      return {};
    }
  }

  // Validate expiry - clear localStorage and return {} if data is too old
  if (localStorageExpiryDays && meta?.timestamp) {
    // Get age of localStorage data in days
    let diff;
    try {
      diff = moment().diff(moment(meta.timestamp), 'days');
    } catch (e) {
      // Use original undefined value for diff
    }
    if (!Number.isNaN(diff) && diff >= localStorageExpiryDays) {
      localStorage.removeItem(localStoragePropertyName);
      return {};
    }
  }

  // Return localStorage object payload excluding meta
  return data;
};

const getDefaultValue = (
  value,
  localStoragePropertyName,
  localStorageExpiryDays,
  localStorageGetMatchUID,
  localStorageGetEncryptUserKey,
) => {
  // If value object provided, default to it
  if (typeof value === 'object') {
    return value;
  }

  let defaultValue;

  // If Local Storage property name provided, default to localStorage data
  // Empty object if not defined
  if (localStoragePropertyName) {
    defaultValue = validateExpireLocalStorage(
      localStoragePropertyName,
      localStorageExpiryDays,
      localStorageGetMatchUID,
      localStorageGetEncryptUserKey,
    );
  }

  // Default to empty object
  return defaultValue || {};
};

const DataContextProvider = ({
  Context,
  value,
  localStoragePropertyName,
  localStorageExpiryDays,
  localStorageGetMatchUID,
  localStorageGetEncryptUserKey,
  children,
}) => {
  // We need to initialise context on first render, without waiting for
  // useEffect didMount. For some reason if we initialise it at didMount, even
  // if we delay child rendering until initialised, it breaks some tests by
  // changing how child screens render in test only, don't know why
  const dataStateIsSet = useRef(false);
  const initialiseDataSet = () => {
    if (!dataStateIsSet.current) {
      dataStateIsSet.current = true;
      return getDefaultValue(
        value,
        localStoragePropertyName,
        localStorageExpiryDays,
        localStorageGetMatchUID,
        localStorageGetEncryptUserKey,
      );
    }
    return undefined;
  };
  const [dataState, setDataState] = useState(initialiseDataSet());
  const [localStorageTimeoutId, setLocalStorageTimeoutId] = useState(null);

  // Special Immediate variables for storing state. They always mirror the
  // corresponding state variables (when updated via the set<var>Full() setter)
  // This allows state variable changes to be immediately available, instead of
  // waiting for the next render
  // This allows updateDataState() to work when called consecutively (dataState
  // and localStorageTimeoutId show their correct current values)
  let dataStateImmediate = dataState;
  let localStorageTimeoutIdImmediate = localStorageTimeoutId;

  // State updaters - update state var and temp state var
  const setDataStateFull = state => {
    setDataState(state);
    dataStateImmediate = state;
  };
  const setLocalStorageTimeoutIdFull = id => {
    setLocalStorageTimeoutId(id);
    localStorageTimeoutIdImmediate = id;
  };

  // Update specific page's state in data state
  const updateDataState = (dataStateName, pageDataState, clearAll = false) => {
    // Check for disallowed names
    if (dataStateName.toLowerCase() === 'meta') {
      throw new Error(`DataContext: name ${dataStateName} not allowed`);
    }

    // Combine with context state (via immediate mode var) if not clearing all
    const newDataState = clearAll ? {} : { ...dataStateImmediate };
    newDataState[dataStateName] = pageDataState;

    // Update into context
    setDataStateFull(newDataState);

    // localStorage only accessible if Window object present
    // (i.e. not server side rendering)
    if (localStoragePropertyName && typeof window !== 'undefined') {
      // Run async via setTimeout to not disturb user experience

      // Stop existing timer if one exists (in case of consecutive calls)
      if (localStorageTimeoutIdImmediate) {
        window.clearTimeout(localStorageTimeoutId);
        setLocalStorageTimeoutIdFull(null);
      }

      // Define localStorage object
      const localStorageDataState = {
        payload: {
          ...newDataState,
          meta: {},
        },
        meta: {},
      };

      // Add meta entries if needed
      if (localStorageExpiryDays) {
        localStorageDataState.meta.timestamp = new Date().toISOString();
      }
      if (localStorageGetMatchUID) {
        const uid = localStorageGetMatchUID();
        if (uid) {
          localStorageDataState.payload.meta.uid = uid;
        }
      }

      // Start and log new timer for update
      setLocalStorageTimeoutIdFull(
        window.setTimeout(() => {
          // Async update into localStorage
          setLocalStorageState(
            localStorageDataState,
            localStoragePropertyName,
            localStorageGetEncryptUserKey,
          );
          setLocalStorageTimeoutId(null);
        }, 0),
      );
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(localStoragePropertyName);
  };

  const clearAllDataState = localStorageSynchronousDelete => {
    // Clear from context
    setDataStateFull({});

    // localStorage only accessible if Window object present
    // (i.e. not server side rendering)
    if (localStoragePropertyName && typeof window !== 'undefined') {
      if (localStorageSynchronousDelete) {
        clearLocalStorage();
      } else {
        window.setTimeout(() => {
          // Async clear from localStorage
          clearLocalStorage();
        }, 0);
      }
    }
  };

  // TODO useMemo-ise in the future (is complicated as funcs need to be
  // useCallback-ised)
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValue = { dataState, updateDataState, clearAllDataState };

  // Return provider with children only included once context initialised
  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

DataContextProvider.propTypes = {
  Context: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
  localStoragePropertyName: PropTypes.string,
  localStorageExpiryDays: PropTypes.number,
  value: PropTypes.shape({}),
  localStorageGetMatchUID: PropTypes.func,
  localStorageGetEncryptUserKey: PropTypes.func,
};

DataContextProvider.defaultProps = {
  children: undefined,
  localStoragePropertyName: undefined,
  localStorageExpiryDays: undefined,
  localStorageGetMatchUID: undefined,
  localStorageGetEncryptUserKey: undefined,
  value: undefined,
};

export default DataContextProvider;
