import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Dropdown, Label, ErrorMessage } from 'ageas-ui-components';

const moment = require('moment');

const InputContainer = styled.fieldset`
  border: none;
  display: block;
  margin: 24px 0;
  text-align: left;
`;

const LabelText = styled.div`
  padding-right: 30px;
`;

const DropdownWrapper = styled.div`
  width: 560px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

class DateDropdownInput extends Component {
  state = {
    day: '',
    month: '',
    year: '',
  };

  componentDidMount() {
    this.updateDefaultDate();
  }

  componentDidUpdate() {
    this.updateCallback(false);
    this.updateDefaultDate();
  }

  parseDefaultDay = defaultDay => {
    const parsedDay = parseInt(defaultDay, 10);
    if (typeof parsedDay === 'number' && parsedDay >= 1 && parsedDay <= 31)
      return parsedDay;
    return '';
  };

  parseDefaultMonth = defaultMonth => {
    const parsedMonth = parseInt(defaultMonth, 10);
    if (
      typeof parsedMonth === 'number' &&
      parsedMonth >= 1 &&
      parsedMonth <= 12
    )
      return parsedMonth;
    return '';
  };

  parseDefaultYear = defaultYear => {
    const { maxYears } = this.props;
    const thisYear = moment().year();
    const parsedYear = parseInt(defaultYear, 10);
    if (
      typeof parsedYear === 'number' &&
      parsedYear > thisYear - maxYears &&
      parsedYear <= thisYear
    )
      return parsedYear;
    return '';
  };

  updateDefaultDate = () => {
    const { dateDefaultValue, monthDefaultValue, yearDefaultValue } =
      this.props;
    const { day, month, year } = this.state;

    // Update initial year state if specified and valid
    const defaultYear = this.parseDefaultYear(yearDefaultValue);
    if (year === '' && defaultYear !== year) {
      this.setState({ year: defaultYear.toString() });
      this.forceUpdate();
    }

    // Update initial month state if specified and valid
    const defaultMonth = this.parseDefaultMonth(monthDefaultValue);
    if (month === '' && defaultMonth !== month) {
      this.setState({ month: defaultMonth.toString() });
      this.forceUpdate();
    }

    // Update initial date state if specified and valid
    const defaultDay = this.parseDefaultDay(dateDefaultValue);
    if (day === '' && defaultDay !== day) {
      this.setState({ day: defaultDay.toString() });
      this.forceUpdate();
    }
  };

  updateCallback = clearDate => {
    const { day, month, year } = this.state;
    const { handleDate, inclDay } = this.props;

    if (month !== '' && year !== '') {
      if (day !== '' || !inclDay) {
        handleDate(
          moment
            .utc({ year, month: month - 1, day: inclDay ? day : 1 })
            .valueOf(),
        );
        return;
      }
    }
    if (clearDate) {
      handleDate(null);
    }
  };

  handleSelect = (event, selectType) => {
    this.setState({
      [selectType]: event.target.value,
    });

    this.updateCallback(true);
  };

  renderNumberOption = (value, leadingZero) => {
    let formatValue = value.toString();
    if (leadingZero && value >= -9 && value <= 9) {
      formatValue = `0${formatValue}`;
    }
    return (
      <option key={value} value={value}>
        {formatValue}
      </option>
    );
  };

  render() {
    const {
      title,
      isRequired,
      inclDay,
      errorMsg,
      dropdownIcon,
      dropdownId,
      hint,
      size,
      placeholderDay,
      placeholderMonth,
      placeholderYear,
      formInput,
      maxYears,
      disabled,
      // dateDefaultValue,
      // monthDefaultValue,
      // yearDefaultValue,
    } = this.props;
    const { day, month, year } = this.state;

    return (
      <InputContainer>
        {title && (
          <legend>
            <Label hint={hint} size={size} fieldName={dropdownId}>
              <LabelText>
                {title} {isRequired && '*'}
              </LabelText>
            </Label>
          </legend>
        )}
        <DropdownWrapper>
          {inclDay && (
            <Dropdown
              aria-label="Select Day"
              name={`${formInput.name}Day`}
              id={`${dropdownId}Day`}
              onChange={event => this.handleSelect(event, 'day')}
              data-testid={`${dropdownId}DayPicker`}
              hasError={
                errorMsg !== '' && errorMsg.toLowerCase().includes('day')
              }
              dropdownIcon={dropdownIcon}
              size="x-small"
              disabled={disabled}
              value={day}
            >
              <option value="" key="">
                {placeholderDay}
              </option>
              {_.range(1, 32, 1).map(value => {
                return this.renderNumberOption(value, false);
              })}
            </Dropdown>
          )}
          <Dropdown
            aria-label="Select Month"
            name={`${formInput.name}Month`}
            id={`${dropdownId}Month`}
            onChange={event => this.handleSelect(event, 'month')}
            data-testid={`${dropdownId}MonthPicker`}
            hasError={
              errorMsg !== '' && errorMsg.toLowerCase().includes('month')
            }
            dropdownIcon={dropdownIcon}
            size="x-small"
            disabled={disabled}
            value={month}
          >
            <option value="" key="">
              {placeholderMonth}
            </option>
            {_.range(1, 13, 1).map(value => {
              return this.renderNumberOption(value, false);
            })}
          </Dropdown>
          <Dropdown
            aria-label="Select Year"
            name={`${formInput.name}Year`}
            id={`${dropdownId}Year`}
            onChange={event => this.handleSelect(event, 'year')}
            data-testid={`${dropdownId}YearPicker`}
            hasError={
              errorMsg !== '' && errorMsg.toLowerCase().includes('year')
            }
            dropdownIcon={dropdownIcon}
            size="x-small"
            disabled={disabled}
            value={year}
          >
            <option value="" key="">
              {placeholderYear}
            </option>
            {_.range(moment().year(), moment().year() - maxYears, -1).map(
              value => {
                return this.renderNumberOption(value, true);
              },
            )}
          </Dropdown>
        </DropdownWrapper>
        {errorMsg && (
          <div
            aria-live="assertive"
            aria-relevant="additions removals"
            id={`${dropdownId}-error-area-selector`}
            data-testid={`${dropdownId}-error-area-selector`}
          >
            <ErrorMessage hasIcon>{errorMsg}</ErrorMessage>
          </div>
        )}
      </InputContainer>
    );
  }
}

DateDropdownInput.propTypes = {
  title: PropTypes.string,
  inclDay: PropTypes.bool,
  isRequired: PropTypes.bool,
  errorMsg: PropTypes.string,
  dropdownIcon: PropTypes.string,
  handleDate: PropTypes.func,
  dropdownId: PropTypes.string.isRequired,
  hint: PropTypes.node,
  size: PropTypes.string,
  placeholderDay: PropTypes.string,
  placeholderMonth: PropTypes.string,
  placeholderYear: PropTypes.string,
  formInput: PropTypes.shape({ name: PropTypes.string }),
  maxYears: PropTypes.number,
  disabled: PropTypes.bool,
  dateDefaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  monthDefaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yearDefaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DateDropdownInput.defaultProps = {
  title: '',
  inclDay: false,
  isRequired: false,
  errorMsg: '',
  dropdownIcon: '',
  handleDate: () => {},
  hint: undefined,
  size: '',
  placeholderDay: '-day-',
  placeholderMonth: '-month-',
  formInput: {},
  placeholderYear: '-year-',
  maxYears: 111,
  disabled: false,
  dateDefaultValue: '',
  monthDefaultValue: '',
  yearDefaultValue: '',
};

export default DateDropdownInput;
