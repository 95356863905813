import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const escapeOfWaterQuestion =
  'What is meant by leaking or escape of water?';
const EscapeOfWaterContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        &apos;Escape of water&apos; is a term used in home insurance to describe
        a water leak that can come from multiple sources in the home.
      </FaqP>
      <FaqP marginBottom={false}>For example:</FaqP>
      <StandardUl>
        <li>
          Leaking pipes (boiler, water tank, radiators or heating system etc.)
        </li>
        <li>Burst pipes (caused by water freezing within them)</li>
        <li>Appliances (i.e. washing machine, dishwasher etc.)</li>
      </StandardUl>
      <FaqP marginBottom={false}>
        What is not considered an &apos;Escape or leakage of water&apos;
        includes (but is not limited to):
      </FaqP>
      <StandardUl>
        <li>
          If you experience water escaping through the failure, wear and tear or
          lack of grouting or sealant around baths, showers etc.
        </li>
        <li>Overflow of water from blocked guttering</li>
      </StandardUl>
      <FaqP>
        If you experience water entering your property due to weather events
        like a storm, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Storm damage and claims',
            ])
          }
        >
          &apos;Storm damage and claims&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

export default EscapeOfWaterContent;

EscapeOfWaterContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
EscapeOfWaterContent.defaultProps = {
  nestingControl: undefined,
};
