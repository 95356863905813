export const JEWELLERY_FIELDS = {
  JEWELLERY_ITEMS: 'jewelleryItems',
  LOST_PROPERTY_NUMBER: 'lossJewelleryPropertyNumber',
  THEFT_CRIME_NUMBER: 'theftJewelleryCrimeNumber',
  INCIDENT_LOCATION: 'incidentLocation',
};
export const JEWELLERY_ITEM_FIELDS = {
  SPECIFIED_ITEM_KEY: 'specifiedItemName',
  TYPE: 'itemName',
  DESCRIPTION: 'itemDescription',
  PURCHASED_FROM: 'itemPurchased',
  HAS_RECEIPT: 'itemReceipt',
  VALUE: 'itemCost',
};

const toUpperCase = text => text.toUpperCase();

export const renderCreateField = (fieldName, perilCode) => {
  if (fieldName === JEWELLERY_FIELDS.LOST_PROPERTY_NUMBER) {
    return perilCode === 'AL';
  }
  if (fieldName === JEWELLERY_FIELDS.THEFT_CRIME_NUMBER) {
    return perilCode === 'TF';
  }

  return Object.values(JEWELLERY_FIELDS).includes(fieldName);
};
const getPayloadGlobalFieldValue = (
  fieldName,
  values,
  perilCode,
  translator = x => x,
) => {
  if (renderCreateField(fieldName, perilCode)) {
    return translator(values[fieldName]);
  }
  return undefined;
};

const specifiedItemKeyIsSet = values => {
  return (
    values[JEWELLERY_ITEM_FIELDS.SPECIFIED_ITEM_KEY] &&
    values[JEWELLERY_ITEM_FIELDS.SPECIFIED_ITEM_KEY] !== 'other'
  );
};

export const renderCreateItemField = (fieldName, values) => {
  if (fieldName === JEWELLERY_ITEM_FIELDS.SPECIFIED_ITEM_KEY) {
    return specifiedItemKeyIsSet(values);
  }
  if (fieldName === JEWELLERY_ITEM_FIELDS.DESCRIPTION) {
    return !specifiedItemKeyIsSet(values);
  }
  if (fieldName === JEWELLERY_ITEM_FIELDS.VALUE) {
    return (
      values[JEWELLERY_ITEM_FIELDS.HAS_RECEIPT] === 'yes' &&
      !specifiedItemKeyIsSet(values)
    );
  }

  return Object.values(JEWELLERY_ITEM_FIELDS).includes(fieldName);
};

const getPayloadItemFieldValue = (fieldName, values, translator = x => x) => {
  if (renderCreateItemField(fieldName, values)) {
    return translator(values[fieldName]);
  }
  return undefined;
};

export const buildJewelleryGlobalFieldsPayload = (values, perilCode) => {
  return {
    incidentLocation: getPayloadGlobalFieldValue(
      JEWELLERY_FIELDS.INCIDENT_LOCATION,
      values,
      perilCode,
    ),
    lossReference: getPayloadGlobalFieldValue(
      JEWELLERY_FIELDS.LOST_PROPERTY_NUMBER,
      values,
      perilCode,
      toUpperCase,
    ),
    crimeReference: getPayloadGlobalFieldValue(
      JEWELLERY_FIELDS.THEFT_CRIME_NUMBER,
      values,
      perilCode,
    ),
  };
};

export const buildJewelleryItemsListPayload = values => {
  return values[JEWELLERY_FIELDS.JEWELLERY_ITEMS].map(itemValues => ({
    itemCategory: 'jewellery',
    specifiedItemKey: getPayloadItemFieldValue(
      JEWELLERY_ITEM_FIELDS.SPECIFIED_ITEM_KEY,
      itemValues,
    ),
    jewelleryDetails: {
      jewelleryType: getPayloadItemFieldValue(
        JEWELLERY_ITEM_FIELDS.TYPE,
        itemValues,
      ),
      itemDescription: getPayloadItemFieldValue(
        JEWELLERY_ITEM_FIELDS.DESCRIPTION,
        itemValues,
      ),
      itemPurchasedLocation: itemValues[JEWELLERY_ITEM_FIELDS.PURCHASED_FROM],
      itemReceipt: itemValues[JEWELLERY_ITEM_FIELDS.HAS_RECEIPT] === 'yes',
    },
    itemValue: getPayloadItemFieldValue(
      JEWELLERY_ITEM_FIELDS.VALUE,
      itemValues,
      parseFloat,
    ),
  }));
};
