import React from 'react';
import PropTypes from 'prop-types';
import StandardPageWrapper from '../../StandardPageWrapper/StandardPageWrapper';
import HubClaimStatus from '../HubClaimStatus/HubClaimStatus';

const HubClaimPageWrapper = ({ children }) => {
  return (
    <StandardPageWrapper headerClaimButton>
      <HubClaimStatus />

      {children}
    </StandardPageWrapper>
  );
};

export default HubClaimPageWrapper;

HubClaimPageWrapper.propTypes = {
  children: PropTypes.node,
};

HubClaimPageWrapper.defaultProps = {
  children: undefined,
};
