import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { ThemedCollapsiblePanel } from 'ageas-ui-components';
import { getTheme } from 'ageas-ui-themes';
import { brand } from '../../../data/whitelabel.config';
import HubMessenger from './HubMessenger';
import config from '../../helpers/config';
import media from '../MediaQuery/MediaQuery';
import MessagesSvg from '../../assets/icons/messages/MessagesSVG';

const Theme = getTheme(brand);

const Floater = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0px 10px;
  max-height: 100vh;
  width: 375px;
  display: flex;
  z-index: 5000;

  ${media.phone`
    width: 100%;
    margin: 0px auto;
    padding: 0px 5px;
    right: auto;
  `}
`;

const Panel = styled(ThemedCollapsiblePanel)`
  width: ${props => props?.width || '100%'};
  margin: 0;
  background-color: white;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 300px;
    max-height: 600px;
  }
`;

const FloatingHubMessenger = ({
  externalOpenState,
  messengerRef,
  unread,
  ...props
}) => {
  const isOpen = externalOpenState[0];
  return (
    <ThemeProvider theme={Theme}>
      <Floater ref={messengerRef}>
        <Panel
          primary
          title="Message Centre"
          externalOpenState={externalOpenState}
          padding="23px 21px"
          id="motorTpMessengerPanel"
          titleIconWidth="44px"
          titleIconLeft={<MessagesSvg unread={unread} />}
        >
          <MessagesContainer>
            <HubMessenger
              enableFirstFetch={isOpen}
              sendButtonProps={{ secondary: true, minWidth: '100%' }}
              messagesContainerProps={{ borderType: 'box' }}
              displayCharacterCount
              {...props}
            />
          </MessagesContainer>
        </Panel>
      </Floater>
    </ThemeProvider>
  );
};

export const MotorTpHubMessenger = ({ ...props }) => {
  return (
    <FloatingHubMessenger
      getUrl={config.client.getMotorTpHubMessages_endpoint}
      sendUrl={config.client.createMotorTpHubMessage_endpoint}
      {...props}
    />
  );
};

export default FloatingHubMessenger;

FloatingHubMessenger.propTypes = {
  externalOpenState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  ),
  messengerRef: PropTypes.shape({}),
  unread: PropTypes.bool,
};

FloatingHubMessenger.defaultProps = {
  externalOpenState: undefined,
  messengerRef: undefined,
  unread: undefined,
};
