export const firstLetterOfEachWordRegex = /\b[a-z]/g;

export const capitaliseFirstLetterOfEachWord = text =>
  text.replace(firstLetterOfEachWordRegex, match => match.toUpperCase());

export const caseConverter = (text, mode) => {
  if (text) {
    if (mode === 'upper') {
      return text.toUpperCase();
    }
    if (mode === 'lower') {
      return text.toLowerCase();
    }
    if (mode === 'capitaliseFirst') {
      return text.substring(0, 1).toUpperCase() + text.substring(1);
    }
    if (mode === 'capitalise') {
      return capitaliseFirstLetterOfEachWord(text);
    }
  }

  return text;
};
