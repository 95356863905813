import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  HOME_HUB_UPLOADABLE_TYPES,
  HOME_HUB_UPLOADABLE_TYPES_LIST,
  mapToValueLabel,
} from '../../helpers/documentUploadTypes';
import StandardDropdown from '../Forms/StandardDropdown/StandardDropdown';

const Wrapper = styled.div`
  margin-top: 0.75em;
`;

const UploaderCategoryCustomField = ({
  uploadableTypes = HOME_HUB_UPLOADABLE_TYPES,
  uploadableTypesList = HOME_HUB_UPLOADABLE_TYPES_LIST,
  documentTypesList,
  notificationCodes = [],
}) => {
  let documentTypes;
  // Pre-filtered & option/value converted list?
  if (documentTypesList) {
    documentTypes = documentTypesList;
  }
  // Else filter HUB uploadable types by provided notification codes?
  else if (notificationCodes.length) {
    documentTypes = uploadableTypes
      .filter(entry => notificationCodes.includes(entry.notificationCode))
      .map(mapToValueLabel);
  }
  // Else all HUB uploadable types
  else {
    documentTypes = uploadableTypesList;
  }

  // Default and don't show placeholder if only one option
  const defaultValue =
    documentTypes.length === 1 ? documentTypes[0].value : undefined;
  const alwaysShowPlaceholder = !defaultValue;

  const validate = category => {
    if (!category) {
      return 'Please select a document type';
    }
    return undefined;
  };

  return (
    <Wrapper>
      <StandardDropdown
        label="Type of document"
        aria-label="Type of document"
        name="category"
        options={documentTypes}
        fieldProps={{ defaultValue }}
        validate={validate}
        alwaysShowPlaceholder={alwaysShowPlaceholder}
      />
    </Wrapper>
  );
};

export default UploaderCategoryCustomField;

UploaderCategoryCustomField.propTypes = {
  uploadableTypes: PropTypes.arrayOf(PropTypes.shape({})),
  uploadableTypesList: PropTypes.arrayOf(PropTypes.shape({})),
  documentTypesList: PropTypes.arrayOf(PropTypes.shape({})),
  notificationCodes: PropTypes.arrayOf(PropTypes.string),
};

UploaderCategoryCustomField.defaultProps = {
  uploadableTypes: undefined,
  uploadableTypesList: undefined,
  documentTypesList: undefined,
  notificationCodes: undefined,
};
