import { messages } from '../constants/ErrorMessages';

export default function validEmail(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      validEmail: messages.EMAIL,
    },
    rules: [
      {
        name: 'validEmail',
        validate(params, value, state, options) {
          const regex = /[”]/g;
          if (value.match(regex) != null) {
            return this.createError(
              'string.validEmail',
              { value },
              state,
              options,
            );
          }
          return value;
        },
      },
    ],
  };
}
