import styled from 'styled-components';

// App.css applies a default margin of 0 to all elements
// This is to restore default formatting to <p> elements
const StandardP = styled.p`
  /* old browsers */
  margin-bottom: 16px;
  margin-top: 16px;
  /* modern browesrs */
  margin: revert;
`;

export default StandardP;
