import React, { useState, useEffect } from 'react';
import { getTheme } from 'ageas-ui-themes';
import { ThemeProvider } from 'styled-components';
import { brand } from '../../../data/whitelabel.config';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import {
  ExcessNoAcceptText,
  InstructSupplierText,
  PaymentSettlementText,
  ReferredText,
} from '../ThankYouEClaim/ThankYouEClaimHome';
import ClaimBreadcrumbPL from '../../helpers/ClaimBreadcrumbPL';

const Theme = getTheme(brand);

const PersonalLossThankYouPage = history => {
  const [personalLossResultData, setPersonalLossResultData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setPersonalLossResultData(history?.history?.location?.state);
  }, []);

  const thankYouContent = () => {
    if (personalLossResultData?.isAccept === false) {
      return (
        <ExcessNoAcceptText
          claimReference={personalLossResultData?.isClaimRef}
        />
      );
    }

    if (
      personalLossResultData?.isAccept === true &&
      (personalLossResultData?.isReferredData ||
        personalLossResultData?.evidenceUploaded === false)
    ) {
      return (
        <ReferredText
          claimReference={personalLossResultData?.isClaimRef}
          itemDescription={personalLossResultData?.description}
        />
      );
    }

    if (
      personalLossResultData?.isAccept === true &&
      personalLossResultData?.settled
    ) {
      return (
        <PaymentSettlementText
          claimReference={personalLossResultData?.isClaimRef}
          itemDescription={personalLossResultData?.description}
          settlementAmount={personalLossResultData?.isSettlementAmount}
        />
      );
    }

    return (
      <InstructSupplierText
        claimReference={personalLossResultData?.isClaimRef}
        userContactNumber={personalLossResultData?.userContactNumber}
        supplierName={personalLossResultData?.supplierName}
        supplierNumber={personalLossResultData?.supplierNumber}
        perilDescription={personalLossResultData?.description}
      />
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <ClaimBreadcrumbPL
          activeItem="thankYou"
          itemType={personalLossResultData?.itemType}
        />
        <PageSectionBare>
          <Subsection>
            {personalLossResultData?.isComplete && thankYouContent()}
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default PersonalLossThankYouPage;
