import React from 'react';
import FaqP from '../../FaqP';

export const inhabitableDamageTitle =
  'What if my property is not habitable due to the extent of damage?';
export const inhabitableDamageContent = (
  <FaqP>
    We understand that some customers like to stay in their own homes where
    possible, so we can provide facilities like temporary kitchens and bathrooms
    to enable you to do so. If you do have to leave the property, we will help
    you make those arrangements, whether this is staying with relatives or
    checking in to suitable alternative accommodation.
  </FaqP>
);
