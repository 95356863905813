import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ThemedCheckbox, ThemedButton } from 'ageas-ui-components';
import Accordion from '../../components/FAQs/MotorFAQ/Accordion';
import Ul from '../../components/StandardTags/UL';
import BrandHeader from '../../assets/BrandHeader';
import BrandFooter from '../../assets/BrandFooter';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import ProgressiveQuestionsPanel from '../../components/ProgressiveQuestions/ProgressiveQuestionPanel';
import ProgressiveQuestionMotorHelper from '../../helpers/ProgressiveQuestionMotorHelper';

import {
  PageContainer,
  TitleText,
  TextContainer,
  PageSection,
  PageSectionBare,
  TopText,
  TopTextSection,
  TopTextTitle,
  PhoneNumbersSection,
  NavLink,
  PurpleBox,
  ContentHolder,
} from '../../assets/styles/GenericInsurance.style';
import makingClaimsEasyText from '../../helpers/makingClaimsEasyText';
import StandardPopup, {
  ModalButtonsContainer,
} from '../../components/StandardPopup/StandardPopup';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';
import { brand } from '../../../data/whitelabel.config';
import { MotorClaimsTelLink } from '../../helpers/ageasTelephoneNumbers';

const Theme = getTheme(brand);

const CarInsurance = () => {
  const [disclaimer, setDisclaimer] = useState(false);
  const [claimUrl, setClaimUrl] = useState(false);
  const [checked, setChecked] = useState(false);
  const [navigatePath, setNavigatePath] = useState(null);

  const onConfirm = () => {
    setDisclaimer(false);
    setNavigatePath(claimUrl);
  };

  const onConfirmClose = () => {
    setDisclaimer(false);
    setChecked(false);
  };
  const onConfirmationCheckboxChange = event => {
    setChecked(event.target.checked);
  };
  const renderDisclaimer = url => {
    setDisclaimer(true);
    setClaimUrl(url);
  };

  return (
    <ThemeProvider theme={Theme}>
      <PageContainer>
        <NavigateTo path={navigatePath} />
        <BrandHeader suppressWarningOnClick />
        <div id="wholewindow">
          <TopText>
            <TopTextSection>
              <TopTextTitle>{makingClaimsEasyText.titleJSX}</TopTextTitle>
              {makingClaimsEasyText.textJSX}
            </TopTextSection>
          </TopText>

          <PageSection>
            <TitleText primary>Motor claims</TitleText>
            <NavLink to="/">
              <FontAwesomeIcon icon={faChevronLeft} /> back to claims
            </NavLink>
          </PageSection>
          {disclaimer && (
            <StandardPopup>
              <Spacer marginBottom="10px">
                <ThemedCheckbox
                  fieldName="Checkbox"
                  name="fraudDisclaimerBox"
                  checked={checked}
                  onChange={onConfirmationCheckboxChange}
                  text="Please confirm that you will take care to answer all questions honestly and to the best of your knowledge. Failure to do so may affect your claim."
                  boldLabel={false}
                />
              </Spacer>
              <ModalButtonsContainer>
                <ThemedButton
                  primary
                  small
                  disabled={!checked}
                  type="button"
                  onClick={() => onConfirm()}
                >
                  Yes
                </ThemedButton>
                <ThemedButton
                  inverted
                  primary
                  small
                  type="button"
                  onClick={onConfirmClose}
                >
                  Close
                </ThemedButton>
              </ModalButtonsContainer>
            </StandardPopup>
          )}

          <TextContainer>
            <ContentHolder>
              <TitleText>
                Please tell us what type of new claim you&apos;d like to make
              </TitleText>
              <ProgressiveQuestionsPanel
                noBorder
                itemContainerProps={{
                  color: 'black',
                  fontSize: '19px',
                  marginLeft: '0px',
                }}
                primary
                padding="25px"
                questions={ProgressiveQuestionMotorHelper(renderDisclaimer)}
                dropdownProps={{ containerProps: { large: true } }}
                localProps={{ minWidth: '140px' }}
                maxButtons={3}
              />
            </ContentHolder>
          </TextContainer>

          <PurpleBox>
            <TitleText>Do you have any questions?</TitleText>
            We&apos;ve put together some answers to the most frequently asked
            questions and made it easy for you to find the information you need.
          </PurpleBox>

          <PageSectionBare>
            <Accordion />
          </PageSectionBare>
          <PhoneNumbersSection>
            <PageSection>
              <TitleText>Do you need urgent support?</TitleText>
              <Ul>
                <li>
                  If you are at the roadside and need urgent assistance, please
                  call <MotorClaimsTelLink />
                </li>
              </Ul>
            </PageSection>
          </PhoneNumbersSection>
          <BrandFooter />
        </div>
      </PageContainer>
    </ThemeProvider>
  );
};

export default CarInsurance;
