import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

const validKeys = [];

/**
 * @description: Validate the field is a number that falls between the range provided.
 * @param rangeMin minimum the field value can be
 * @param rangeMax maximum the field value can be
 * @param keys Fields to validate
 * @succeeds when field value falls inside the range
 * @fails when field value falls outside the range
 */
export const range = (
  rangeMin,
  rangeMax,
  keys = validKeys,
  message = messages.OUTSIDE_RANGE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.number()
      .min(rangeMin)
      .concat(Joi.number().max(rangeMax))
      .error(() => message),
  );
};

export default range;
