import React from 'react';
import Breadcrumb from '../components/BreadcrumbClaims/BreadcrumbClaims';

export const items = [
  {
    id: 'brokerLogin',
    label: '1',
  },
  {
    id: 'validateContact',
    label: '2',
  },
  {
    id: 'verifyOTP',
    label: '3',
  },
];

const BrokerLoginBreadcrumb = ({ ...props }) => {
  return <Breadcrumb items={items} {...props} />;
};

export default BrokerLoginBreadcrumb;
