import React from 'react';
import PropTypes from 'prop-types';
import { email } from 'ageasvalidation/lib/schemas/Email';
import { ukTelephoneNumber } from 'ageasvalidation/lib/schemas/Telephone';
import StandardInput from '../../StandardInput/StandardInput';
import mobileTelephoneValidationRegex from '../../../../helpers/mobileTelephoneValidationRegex';
import regularExpressionOptional from '../../../../helpers/validation/regularExpressionOptional';

const DEFAULT_FIELD_NAME = 'involvedParty';

export const getInvolvedPartyContactSchema = fieldName => {
  return {
    [`${fieldName}.homeTelephone`]: ukTelephoneNumber(
      [`${fieldName}.homeTelephone`],
      'Please enter a valid home telephone number',
    ),
    [`${fieldName}.workTelephone`]: ukTelephoneNumber(
      [`${fieldName}.workTelephone`],
      'Please enter a valid work telephone number',
    ),
    [`${fieldName}.mobileTelephone`]: regularExpressionOptional(
      mobileTelephoneValidationRegex,
      [`${fieldName}.mobileTelephone`],
      'Please enter a valid mobile telephone number',
    ),
    [`${fieldName}.emailAddress`]: email(
      [`${fieldName}.emailAddress`],
      'Please enter a valid email address',
    ),
  };
};

const InvolvedPartyContact = ({
  name = DEFAULT_FIELD_NAME,
  validate,
  company,
}) => {
  return (
    <>
      {company ? (
        <StandardInput
          name={`${name}.workTelephone`}
          label="Work telephone number"
          aria-label="Work telephone number"
          validate={validate}
          xSmall
          maxLength="20"
        />
      ) : (
        <StandardInput
          name={`${name}.homeTelephone`}
          label="Home telephone number"
          aria-label="Home telephone number"
          validate={validate}
          xSmall
          maxLength="20"
        />
      )}
      <StandardInput
        name={`${name}.mobileTelephone`}
        label="Mobile telephone number"
        aria-label="Mobile telephone number"
        validate={validate}
        xSmall
        maxLength="20"
      />
      <StandardInput
        name={`${name}.emailAddress`}
        label="Email address"
        aria-label="Email address"
        validate={validate}
        small
        maxLength="100"
      />
    </>
  );
};

InvolvedPartyContact.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  header: PropTypes.string,
  headerProps: PropTypes.shape({}),
  labelling: PropTypes.shape({
    commonLabel: PropTypes.string,
    'aria-label': PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
  }),
  company: PropTypes.bool,
  validate: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  disabled: PropTypes.bool,
};
InvolvedPartyContact.defaultProps = {
  name: undefined,
  header: undefined,
  headerProps: undefined,
  labelling: undefined,
  company: false,
  validate: undefined,
  disabled: false,
};

export default InvolvedPartyContact;
