import React, { useContext, useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import { getTheme } from 'ageas-ui-themes';
import { ThemeProvider } from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import { Form } from 'react-final-form';
import { brand } from '../../../data/whitelabel.config';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
  ButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../../components/StandardTags/P';
import BankDetails, {
  getBankDetailsValidationSchema,
} from '../../components/Forms/BankDetails/BankDetails';
import { validateField } from '../../helpers/validationHelper';
import {
  getErrorMessage,
  checkBankDetails as checkBankDetailsError,
  InvalidBankDetailsHome,
  serverErrorHome,
} from '../../helpers/errorMessages';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import YesNoButton from '../../components/YesNoButton/YesNoButton';
import {
  freezerFoodDamageDataContext,
  FreezerFoodContext,
} from '../../helpers/freezerFoodContextHelper';
import { WithinXSExcessText } from '../ThankYouEClaim/ThankYouEClaimHome';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import declineCode from '../../helpers/homeDeclineCodes';

const Theme = getTheme(brand);

const ACCOUNT_NAME = 'accountName';
const ACCOUNT_SORT_CODE = 'sortCode';
const ACCOUNT_NUMBER = 'accountNumber';

const schema = {
  ...getBankDetailsValidationSchema(
    ACCOUNT_NAME,
    ACCOUNT_SORT_CODE,
    ACCOUNT_NUMBER,
  ),
};

const validateFields = (value, allFields, meta) => {
  let error;
  if (schema[meta.name]) {
    error = validateField(value, meta, schema);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const FreezerFoodClaimResult = () => {
  const [navigatePath, setNavigatePath] = useState(null);
  const [renderPage, setRenderPage] = useState(false);
  const [serverError, setServerError] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [displayBankDetails, setDisplayBankDetails] = useState(false);
  const [checkBankDetails, setCheckBankDetails] = useState(false);
  const [invalidBankDetails, setInvalidBankDetails] = useState(false);
  const [freezerFoodReference, setFreezerFoodReference] = useState({});
  const [freezerFoodState, setFreezerFoodState] = useState(null);
  const {
    dataState: freezerFoodContext,
    updateDataState,
    clearAllDataState,
  } = useContext(FreezerFoodContext);

  // Use context data if present, else state if present
  let freezerFoodDataFull = freezerFoodState;
  if (
    !isEmpty({
      ...freezerFoodContext?.freezerFoodDamageData,
    })
  ) {
    freezerFoodDataFull = freezerFoodContext;
  }

  const freezerFoodDataListContext = {
    ...freezerFoodDataFull?.freezerFoodDamageData,
  };

  const {
    claimReference,
    status = {},
    relevantPolicyData = {},
  } = freezerFoodDataListContext;
  const { applicableExcess } = relevantPolicyData;

  const { referrals = [], declines = [] } = status;

  const amountClaimed = freezerFoodDataFull?.amount;
  const settlementAmount = amountClaimed - applicableExcess;
  const isFreezerFoodRefer = referrals.includes('FreezerFoodRefer');
  const isWithinXS = declines.includes('ExcessTooHigh');

  const isEndOfJourney = () => {
    return isWithinXS;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!navigatePath) {
      if (isEmpty(freezerFoodDataListContext)) {
        setNavigatePath('/homeinsurance');
      } else {
        // If context set but not state, Move context to state
        if (!freezerFoodState) {
          setFreezerFoodState(freezerFoodDataFull);
        }
        // If decline condition (so no further progress allowed), delete
        // context
        if (isEndOfJourney()) {
          clearAllDataState();
        }
        setRenderPage(true);
      }
    }
  }, [freezerFoodDataFull]);

  useEffect(() => {
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const renderFreezerThanksPage = accept => {
    updateDataState(
      freezerFoodDamageDataContext.settlementAmount,
      settlementAmount,
    );
    const freezerFoodDamageData = {
      isAccept: accept,
      isReferredData: isFreezerFoodRefer,
      isComplete: true,
      isClaimRef: claimReference,
      isSettlementAmount: settlementAmount,
    };

    setFreezerFoodReference(freezerFoodDamageData);
    setIsLoading(false);
    setNavigatePath('/homeclaim/freezerfood/thankyoupage');
    clearAllDataState();
  };

  const freezerRejectHomeClaim = {
    claimReference,
    reason: declineCode.UNECONOMIC,
  };

  const freezerAcceptHomeClaim = {
    claimReference,
  };

  const showBankDetails = () => {
    setDisplayBankDetails(true);
    setServerError(false);
  };

  const postRequest = (endpoint, payload, accept, payment) => {
    setInvalidBankDetails(false);
    setCheckBankDetails(false);
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(endpoint, payload, {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(data => {
        setIsLoading(false);
        if (accept && payment) {
          const { paymentSuccess = false, errorCode = '' } = data?.data || {};
          if (paymentSuccess && !errorCode) {
            renderFreezerThanksPage(accept);
          } else if (errorCode === 'TooManyAttempts') {
            setInvalidBankDetails(true);
          } else if (errorCode === 'InvalidDetails') {
            setCheckBankDetails(true);
          } else {
            setServerError(true);
          }
        } else {
          renderFreezerThanksPage(accept);
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  };

  const onSubmit = values => {
    postRequest(
      config.client.acceptHomeClaim_endpoint,
      {
        ...freezerAcceptHomeClaim,
        bankDetails: {
          accountName: values.accountName,
          sortCode: +values.sortCode,
          accountNumber: +values.accountNumber,
        },
      },
      true,
      true,
    );
  };

  const acceptExcess = () => {
    postRequest(
      config.client.acceptHomeClaim_endpoint,
      freezerAcceptHomeClaim,
      true,
    );
  };

  const declineExcess = () => {
    setDisplayBankDetails(false);
    postRequest(
      config.client.rejectHomeClaim_endpoint,
      freezerRejectHomeClaim,
      false,
    );
  };

  const renderSettlementExcessSection = () => {
    return (
      <>
        <P>
          Please note that your policy has an excess of{' '}
          <strong>£{applicableExcess}</strong> which is the amount you have to
          pay towards the cost of your claim. We are able to pay you{' '}
          <strong>£{settlementAmount}</strong> today in settlement of your
          claim, would you like to proceed?
        </P>
        <YesNoButton
          yesButtonLabel="Yes - proceed"
          declineOnClick={declineExcess}
          acceptOnClick={showBankDetails}
        />
      </>
    );
  };

  const renderReferralExcessSection = () => {
    return (
      <>
        <P>
          Due to the value of perished freezer food, your claim will need to be
          referred for review.
        </P>
        <P>
          Please note that your policy has an excess of{' '}
          <strong>£{applicableExcess}</strong>. This is the amount you will have
          to pay towards the cost of your claim. Would you like to proceed with
          the referral of your claim?
        </P>
        <YesNoButton
          yesButtonLabel="Yes - proceed"
          declineOnClick={declineExcess}
          acceptOnClick={acceptExcess}
        />
      </>
    );
  };

  const renderWithinXSExcessSection = () => {
    return (
      <WithinXSExcessText
        claimReference={claimReference}
        applicableExcess={applicableExcess}
      />
    );
  };

  const renderServerErrorComponent = () => {
    return (
      serverError && (
        <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
      )
    );
  };

  const renderCheckBankDetailsError = () => {
    return (
      checkBankDetails && (
        <ThemedErrorMessage hasIcon>
          {checkBankDetailsError()}
        </ThemedErrorMessage>
      )
    );
  };

  const renderInvalidBankDetailsError = () => {
    return (
      invalidBankDetails && (
        <ThemedErrorMessage hasIcon>
          {InvalidBankDetailsHome()}
        </ThemedErrorMessage>
      )
    );
  };

  const renderConfirmButton = () => {
    return (
      !invalidBankDetails && (
        <ButtonsBottomContainer>
          <ThemedButton secondary type="submit">
            Confirm
          </ThemedButton>
        </ButtonsBottomContainer>
      )
    );
  };

  const renderBankDetailsSection = () => {
    return (
      displayBankDetails && (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form method="post" onSubmit={handleSubmit}>
              <P>
                We’ll pay this money directly into your bank account. Please
                enter the following details:
              </P>
              <BankDetails
                name={[ACCOUNT_NAME, ACCOUNT_SORT_CODE, ACCOUNT_NUMBER]}
                validate={validateFields}
              />
              {renderCheckBankDetailsError()}
              {renderInvalidBankDetailsError()}
              {renderServerErrorComponent()}
              {renderConfirmButton()}
            </form>
          )}
        </Form>
      )
    );
  };

  if (navigatePath) {
    return <NavigateTo path={navigatePath} data={freezerFoodReference} />;
  }

  if (!renderPage) {
    return null;
  }

  const renderExcessSection = () => {
    if (isWithinXS) {
      return renderWithinXSExcessSection();
    }
    if (isFreezerFoodRefer) {
      return renderReferralExcessSection();
    }
    return renderSettlementExcessSection();
  };

  return (
    <ThemeProvider theme={Theme}>
      {isLoading && <LoadingSpinner />}
      <HomeClaimsWrapper>
        <ClaimBreadcrumbStandard activeItem="results" />
        <PageSectionBare>
          <Subsection>
            {renderExcessSection()}
            {!displayBankDetails && renderServerErrorComponent()}
          </Subsection>
          <Subsection>{renderBankDetailsSection()}</Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default FreezerFoodClaimResult;
