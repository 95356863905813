export const motorCircumstancesNoneOption = {
  value: '9999',
  label: 'None of the above',
};

// Commented out items are backoffice-only items,here for reference
export const motorCircumstanceCodes = [
  { value: '0001', label: 'Your vehicle was hit in the rear' },
  {
    value: '0002',
    label: 'A third party vehicle pulled out into the path of your vehicle',
  },
  { value: '0003', label: 'Your vehicle was hit whilst parked' },
  {
    value: '0004',
    label:
      'A third party vehicle attempted to overtake your vehicle whilst turning',
  },
  {
    value: '0005',
    label: 'A third party vehicle turned into the path of your vehicle',
  },
  {
    value: '0006',
    label: 'An Incident involving only your vehicle',
    singleVehicle: true,
  },
  // { value: '0007', label: 'Vandalism/Malicious Damage' },
  // { value: '0008', label: 'Collision with a pedestrian' },
  // { value: '0009', label: 'Collision with a pedal cyclist' },
  // { value: '0010', label: 'Collision with a motor cyclist' },
  { value: '0011', label: 'A third party vehicle reversed into yours' },
  // { value: '0012', label: 'PH or TP head-on collision' },
  // { value: '0013', label: 'PH or TP vehicle collision on roundabout' },
  {
    value: '0014',
    label: 'The wrong fuel has been used in your vehicle',
    singleVehicle: true,
  },
  motorCircumstancesNoneOption,
];

// This is the backoffice wording for all items
// export const motorCircumstanceCodes = [
//   { value: '0001', label: 'PH or TP vehicle hit in rear' },
//   { value: '0002', label: 'PH or TP vehicle pulled out when unsafe' },
//   { value: '0003', label: 'PH or TP vehicle hit whilst parked' },
//   { value: '0004',
//     label: 'PH or TP vehicle turned whilst the other was overtaking' },
//   { value: '0005',
//     label: 'PH or TP vehicle turned across the path of the other' },
//   { value: '0006', label: 'Only insured vehicle involved' },
//   { value: '0007', label: 'Vandalism/Malicious Damage' },
//   { value: '0008', label: 'Collision with a pedestrian' },
//   { value: '0009', label: 'Collision with a pedal cyclist' },
//   { value: '0010', label: 'Collision with a motor cyclist' },
//   { value: '0011', label: 'PH or TP vehicle reversed' },
//   { value: '0012', label: 'PH or TP head-on collision' },
//   { value: '0013', label: 'PH or TP vehicle collision on roundabout' },
//   { value: '0014', label: 'Wrong fuel used' },
//   { value: '9999', label: 'None of the above' }
//   ];
