import mapLocalToIncidentDetailsSection from './toServer/mapLocalToIncidentDetailsSection';
import mapLocalToLocationDetailsSection from './toServer/mapLocalToLocationDetailsSection';
import mapServerToIncidentDetailsPage from './toLocal/mapServerToIncidentDetailsPage';
import mapLocalToContactDetailsSection from './toServer/mapLocalToContactDetailsSection';
import mapServerToContactDetailsPage from './toLocal/mapServerToContactDetailsPage';
import mapServerToYourDetailsPage from './toLocal/mapServerToYourDetailsPage';
import mapLocalToUnderwriterQuestionsSection from './toServer/mapLocalToUwQuestionsSection';
import mapLocalToVehicleDamageSection from './toServer/mapLocalToVehicleDamageSection';
import mapServerToVehicleDamageDetailsPage from './toLocal/mapServerToVehicleDamageDetailsPage';

/**
 * Map JSON Blob returned from server to context/pageForms format
 * @param {*} data in server JSON Blob format
 * @returns data in MotorClaimContextNew, claimEntryData format
 * (one object per page, in page form format)
 */
export const mapServerToLocal = (data = {}) => {
  const { meta = {}, claimData = {} } = data;
  const local = {
    meta: { ...meta },
    incidentDetails: mapServerToIncidentDetailsPage(claimData),
    contactDetails: mapServerToContactDetailsPage(claimData),
    yourDetails: mapServerToYourDetailsPage(claimData),
    vehicleDamageDetails: mapServerToVehicleDamageDetailsPage(claimData),
  };
  return local;
};

/**
 * Map context/pageForms data to JSON Blob format for send to server
 * @param {*} data in MotorClaimContextNew, claimEntryData format (all pages)
 * @returns data in server JSON Blob format
 * (one object per page, in page form format)
 */
export const mapLocalToServer = (data = {}) => {
  const { meta = {} } = data;
  const server = {
    meta: { ...meta },
    claimData: {
      incidentDetails: mapLocalToIncidentDetailsSection(data),
      locationDetails: mapLocalToLocationDetailsSection(data),
      contactDetails: mapLocalToContactDetailsSection(data),
      underwriterQuestions: mapLocalToUnderwriterQuestionsSection(data),
      vehicleDamage: mapLocalToVehicleDamageSection(data),
    },
  };
  return server;
};

/**
 * Update meta section in provided MotorClaimContextNew, claimEntryData, with
 * provided new section
 * Is a merge - values in newMeta replace corresponding values, unspecified
 * values left alone.
 * @param {*} data MotorClaimContextNew, claimEntryData
 * @param {*} newMeta New meta object to merge
 * @returns new copy of data (does not update original, but shares some
 * children with original)
 */
export const updateLocalMeta = (data = {}, newMeta = {}) => {
  const newData = { ...data };
  newData.meta = {
    ...newData.meta,
    ...newMeta,
  };
  return newData;
};

/**
 * Replace specified page section in provided MotorClaimContextNew,
 * claimEntryData with provided new section
 * @param {*} data MotorClaimContextNew, claimEntryData
 * @param {*} newMeta New page object to add/replace
 * @returns new copy of data (does not update original, but shares some
 * children with original)
 */
export const updateLocalPageSection = (
  data = {},
  section = undefined,
  newPageData = {},
) => {
  const newData = { ...data };
  newData[section] = newPageData;
  return newData;
};

/**
 * Replace specified page section in provided MotorClaimContextNew,
 * claimEntryData.unvalidated with provided new section
 * @param {*} data MotorClaimContextNew, claimEntryData
 * @param {*} newMeta New page object to add/replace
 * @returns new copy of data (does not update original, but shares some
 * children with original)
 */
export const updateLocalPageSectionUnvalidated = (
  data = {},
  section = undefined,
  newPageData = {},
) => {
  const newData = { ...data };
  newData.unvalidated = updateLocalPageSection(
    data.unvalidated,
    section,
    newPageData,
  );
  return newData;
};
