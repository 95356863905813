import React from 'react';
import FaqP from '../../FaqP';

export const accidentalDamageInfoTitle = 'What is accidental damage?';
export const accidentalDamageInfoContent = (
  <FaqP>
    &apos;Accidental damage&apos; is a term used in home insurance to define
    unexpected and unintended damage, by something sudden and which is not
    deliberate caused by an external force to your property or contents.
    Examples of accidental damage include spilling a drink and staining the
    carpet or accidentally drilling through a pipe.
  </FaqP>
);
