import styled, { css } from 'styled-components';
import RemoveIcon from '../../assets/ui_icon_remove.svg';
import AddIcon from '../../assets/ui_icon_add.svg';
import FullTickIcon from '../../assets/ui_icon_added.svg';
import PartialTickIcon from '../../assets/ui_icon_indeterminate.svg';
import media from '../MediaQuery/MediaQuery';

export const DesktopBuilding = styled.div`
  flex-direction: row;
  align-items: flex-end;
  margin-left: 10px;
  width: 100%;
  ${media.phone`
    display:none;
  `}
`;

export const ImageButtonWrapper = styled.div`
  max-width: 791px;
  position: relative;
  margin-right: 30px;
  margin-bottom: 15px;
`;

export const HouseDesktopImage = styled.img`
  position: relative;
  max-width: 100%;
  ${media.phone`
    display:none;
  `}
`;

export const ButtonTextContainer = styled.span`
  display: none;
  ${media.phone`
    display:none;
  `}
`;

export const IconTemplate = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 27px;
  height: 27px;
  border-radius: 13.5px;
  border: 2px solid #70a316;
  background-color: #70a316;
  box-sizing: border-box;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${AddIcon});
  background-size: 15px 15px;
  ${media.phone`
    display:none;
  `}
`;

export const ButtonIcon = styled(IconTemplate)`
  ${media.phone`
    display:none;
  `}
`;

export const HoverButton = styled.button`
  padding: 5px;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  min-width: auto;
  margin-bottom: 0px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 18.5px;
  background-color: transparent;
  // z-index: 1;
  &:hover {
    background-color: #fff;
    cursor: pointer;
    border-color: ${props => (props.isChecked ? '#db2828' : '#086708')};
  }
  ${props =>
    props.isChecked &&
    css`
      & > ${ButtonIcon} {
        border-color: #db2828;
        background-color: #db2828;
        background-image: url(${RemoveIcon});
      }
    `}
  & > ${ButtonTextContainer} {
    display: initial;
    margin-left: 3px;
    margin-right: 3px;
  }
  background-color: transparent;
  padding: 0px;
  border: none;
  ${media.phone`
        display:none;
    `}
`;

export const HoverButtonContentContainer = styled.div`
  display: none;
  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
  ${media.phone`
    display:none;
  `}
`;

export const ContainerIcon = styled(IconTemplate)`
  ${media.phone`
    display:none;
  `}
`;

export const HoverButtonContainer = styled.div`
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  margin-bottom: 0px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 18.5px;
  background-color: transparent;
  z-index: ${props => props.zIndex || '1'};
  ${props =>
    props.open &&
    css`
      background-color: #fff;
      cursor: pointer;
      border-color ${props.checkedType === 'full' ? '#db2828' : '#086708'};
    `};

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top};
      left: ${props.left};
    `};

  ${props =>
    props.checkedType === 'full' &&
    css`
      & > ${ContainerIcon} {
        background-color: white;
        background-image: url(${FullTickIcon});
      }
    `}

  ${props =>
    props.checkedType === 'partial' &&
    css`
      & > ${ContainerIcon} {
        background-color: white;
        background-image: url(${PartialTickIcon});
      }
    `}
    ${props =>
    props.open &&
    css`
      & ${ContainerIcon} {
        display: none;
      }
      & ${HoverButtonContentContainer} {
        display: flex;
        flex-direction: column;
      }
    `}
    ${media.phone`
      display:none;
`}
`;
