const config =
  typeof window !== 'undefined'
    ? // client rendered config
      {
        server: {},
        client: window.env,
      }
    : // server rendered config
      {
        server: {
          // server config
          assets:
            process.env.SERVER_ASSETS_MANIFEST ||
            process.env.RAZZLE_ASSETS_MANIFEST,
          public_dir:
            process.env.SERVER_PUBLIC_DIR || process.env.RAZZLE_PUBLIC_DIR,
          port: process.env.SERVER_PORT || process.env.PORT,
          privateKey: process.env.SERVER_PRIVATE_KEY.replace(/\\n/g, '\n'),
          domain: process.env.SERVER_DOMAIN,
          productId: process.env.SERVER_PRODUCT_ID,
          portalLandingAllow: process.env.SERVER_PORTAL_LANDING_ALLOW,
          login_bypass_page: process.env.SERVER_ENABLE_LOGIN_BYPASS_PAGE,
        },
        client: {
          landingPageRedirectURL: process.env.CLIENT_LANDING_PAGE_REDIRECT,
          FEATURE_TEST_PAGES: process.env.FEATURE_TEST_PAGES,
          FEATURE_MOTOR_OTP_ALLOW_CLAIM_REF_LOGIN:
            process.env.FEATURE_MOTOR_OTP_ALLOW_CLAIM_REF_LOGIN === 'true',
          FEATURE_HOME_CLAIM: process.env.FEATURE_HOME_CLAIM,
          FEATURE_HOME_CLAIM_STORM: process.env.FEATURE_HOME_CLAIM_STORM,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE,
          FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS,
          FEATURE_HOME_CLAIM_THEFT: process.env.FEATURE_HOME_CLAIM_THEFT,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_CARPETS:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_CARPETS,
          FEATURE_HOME_CLAIM_FREEZERFOOD_DAMAGE:
            process.env.FEATURE_HOME_CLAIM_FREEZERFOOD_DAMAGE,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_JEWELLERY:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_JEWELLERY,
          FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS_JEWELLERY:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS_JEWELLERY,
          FEATURE_HOME_CLAIM_THEFT_JEWELLERY:
            process.env.FEATURE_HOME_CLAIM_THEFT_JEWELLERY,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_ELECTRONIC_DEVICES:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_ELECTRONIC_DEVICES,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_TV:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_TV,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_MOBILE:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_MOBILE,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_LAPTOP:
            process.env.FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_LAPTOP,
          FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING_UPLOAD:
            process.env
              .FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING_UPLOAD,
          FEATURE_HOME_CLAIM_ESCAPE_OF_WATER:
            process.env.FEATURE_HOME_CLAIM_ESCAPE_OF_WATER,
          FEATURE_HOMEPAGE_REFERRAL_LINK:
            process.env.FEATURE_HOMEPAGE_REFERRAL_LINK,
          FEATURE_HOMEPAGE_REFERRAL_LINK_ALWAYS_ON:
            process.env.FEATURE_HOMEPAGE_REFERRAL_LINK_ALWAYS_ON,
          FEATURE_HXSP_WARNING_TEXT: process.env.FEATURE_HXSP_WARNING_TEXT,
          FEATURE_CHATBOT_ENABLED:
            process.env.FEATURE_CHATBOT_ENABLED === 'true',
          FEATURE_POLICY_NUMBER_HELP_MESSAGE:
            process.env.FEATURE_POLICY_NUMBER_HELP_MESSAGE === 'true',
          FEATURE_HOME_CLAIM_HUB: process.env.FEATURE_HOME_CLAIM_HUB,
          FEATURE_HOME_CLAIM_HUB_SETTLEMENT:
            process.env.FEATURE_HOME_CLAIM_HUB_SETTLEMENT,
          FEATURE_MOTOR_CLAIM_HUB: process.env.FEATURE_MOTOR_CLAIM_HUB,
          FEATURE_MOTOR_CLAIM_TP_HUB: process.env.FEATURE_MOTOR_CLAIM_TP_HUB,
          FEATURE_SVA_JOURNEY_ENABLED: process.env.FEATURE_SVA_JOURNEY_ENABLED,
          portalLoginUrl: process.env.CLIENT_PORTAL_LOGIN_URL,
          sbsUrl: process.env.CLIENT_SBS_URL,
          nonCustomerPrivacyPolicyUrl:
            process.env.CLIENT_NON_CUSTOMER_PRIVACY_POLICY,
          homeHubUrl: process.env.CLIENT_HOME_HUB_URL,
          sample_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_SAMPLE_ENDPOINT,
          cookieSecure: process.env.CLIENT_COOKIE_SECURE || false,
          cookieExpiryMinutes: process.env.CLIENT_COOKIE_EXPIRY_MINUTES
            ? parseInt(process.env.CLIENT_COOKIE_EXPIRY_MINUTES, 10)
            : 30,
          lastLogonTypeCookieExpiryMinutes: process.env
            .CLIENT_LAST_LOGON_TYPE_COOKIE_EXPIRY_MINUTES
            ? parseInt(
                process.env.CLIENT_LAST_LOGON_TYPE_COOKIE_EXPIRY_MINUTES,
                10,
              )
            : 1440,
          localStorageExpiryDays: process.env.CLIENT_LOCALSTORAGE_EXPIRY_DAYS
            ? parseInt(process.env.CLIENT_LOCALSTORAGE_EXPIRY_DAYS, 10)
            : 10,
          localStorageHubExpiryDays: process.env
            .CLIENT_LOCALSTORAGE_HUB_EXPIRY_DAYS
            ? parseInt(process.env.CLIENT_LOCALSTORAGE_HUB_EXPIRY_DAYS, 10)
            : 1,
          localStorageStormExpiryDays: process.env
            .CLIENT_LOCALSTORAGE_STORM_EXPIRY_DAYS
            ? parseInt(process.env.CLIENT_LOCALSTORAGE_STORM_EXPIRY_DAYS, 10)
            : 1,
          localStorageAccidentalDamageExpiryDays: process.env
            .CLIENT_LOCALSTORAGE_ACCIDENTAL_DAMAGE_EXPIRY_DAYS
            ? parseInt(
                process.env.CLIENT_LOCALSTORAGE_ACCIDENTAL_DAMAGE_EXPIRY_DAYS,
                10,
              )
            : 1,
          localStorageFreezerFoodExpiryDays: process.env
            .CLIENT_LOCALSTORAGE_FREEZER_FOOD_EXPIRY_DAYS
            ? parseInt(
                process.env.CLIENT_LOCALSTORAGE_FREEZER_FOOD_EXPIRY_DAYS,
                10,
              )
            : 1,
          localStorageEscapeOfWaterExpiryDays: process.env
            .CLIENT_LOCALSTORAGE_EOW_EXPIRY_DAYS
            ? parseInt(process.env.CLIENT_LOCALSTORAGE_EOW_EXPIRY_DAYS, 10)
            : 1,
          helloworld_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_HELLOWORLD_ENDPOINT,
          isUp_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_SERVER_IS_UP_ENDPOINT,
          userpolicy_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_USERPOLICY_ENDPOINT,
          userpolicydriverlist_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_USERPOLICY_DRIVERSLIST_ENDPOINT,
          policyvehicles_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_POLICY_VEHICLES_ENDPOINT,
          claimnumber_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_CLAIM_NUMBER_ENDPOINT,
          createBasicMotorClaim_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_CREATE_BASIC_MOTORCLAIM_ENDPOINT,
          homeclaimnumber_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_HOMECLAIM_ENDPOINT,
          brokercontact_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_BROKER_CONTACT_ENDPOINT,
          brokercontact_validate_otp_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_BROKER_CONTACT_VALIDATE_OTP_ENDPOINT,
          get_credentials_from_user_principles:
            process.env.CLIENT_BACK_END +
            process.env
              .CLIENT_USER_SERVICE_GET_CREDENTIALS_FROM_USER_PRINCIPLES,
          getContinuableMotorClaimData_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_CONTINUABLE_MOTOR_CLAIM_DATA,
          updateContinuableMotorClaimData_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_UPDATE_CONTINUABLE_MOTOR_CLAIM_DATA,
          gethomeclaimhistory_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_HOME_CLAIM_HISTORY,
          gethomepolicyholder_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_HOME_POLICYHOLDER_ENDPOINT,
          rejectHomeClaim_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_REJECT_HOME_CLAIM,
          acceptHomeClaim_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_ACCEPT_HOME_CLAIM,
          uploadDocument_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_UPLOAD_DOCUMENT,
          motorTPHubUploadDocument_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_MOTOR_TP_UPLOAD_DOCUMENT,
          getHomeHubMessages_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_HOME_HUB_MESSAGES,
          createHomeHubMessage_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_CREATE_HOME_HUB_MESSAGE,
          getMotorTpHubMessages_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_MOTOR_HUB_MESSAGES,
          createMotorTpHubMessage_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_CREATE_MOTOR_HUB_MESSAGE,
          getHomeHubClaimDetails_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_HOME_HUB_CLAIM_DETAILS,
          getMotorHubClaimDetails_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_MOTOR_HUB_CLAIM_DETAILS,
          getMotorHubStatementBuilderRequest_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_MOTOR_HUB_STATEMENT_BUILDER_REQUEST,
          updateMotorHubStatementAnswers_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_UPDATE_MOTOR_HUB_STATEMENT_ANSWERS,
          signMotorHubStatement_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_SIGN_MOTOR_HUB_STATEMENT,
          getMotorTpHubClaimDetails_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_MOTOR_TP_HUB_CLAIM_DETAILS,
          getHomeHubSettlementData_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_HOME_HUB_SETTLEMENT_DATA,
          acceptAndPayOffer_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_HOME_HUB_ACCEPT_PAY_OFFER,
          motorAcceptAndPayOffer_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_MOTOR_HUB_ACCEPT_PAY_OFFER,
          motorTpAcceptAndPayOffer_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_MOTOR_TP_HUB_ACCEPT_PAY_OFFER,
          tpAssistValidateUUID_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_TPASSIST_VALIDATE_UUID,
          tpAssistAccept_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_TPASSIST_ACCEPT,
          getmotorclaimhistory_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_MOTOR_CLAIM_HISTORY,
          getMotorPolicyDetails_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_MOTOR_POLICY_DETAILS,
          getOccupationsList_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_OCCUPATIONS_LIST,
          getConvictionsList_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_CONVICTIONS_LIST,
          getOwnerKeeperStatusList_endpoint:
            process.env.CLIENT_BACK_END +
            process.env.CLIENT_GET_OWNER_KEEPER_STATUS_LIST,

          event_endpoint:
            process.env.CLIENT_BACK_END + process.env.CLIENT_EVENT,
          cookie_banner: {
            url: process.env.CLIENT_COOKIE_BANNER_URL,
            domain_script: process.env.CLIENT_COOKIE_BANNER_DATA_DOMAIN_SCRIPT,
            active: process.env.CLIENT_ONETRUST_COOKIE_BANNER_ACTIVE,
          },
          clientEncryptionKey: process.env.CLIENT_ENCRYPTION_KEY,
          clientHashUID:
            !process.env.CLIENT_HASH_UID ||
            process.env.CLIENT_HASH_UID !== 'false',
          clientEncryptLocalStorage:
            process.env.CLIENT_ENCRYPTION_KEY &&
            (!process.env.CLIENT_ENCRYPT_LOCALSTORAGE ||
              process.env.CLIENT_ENCRYPT_LOCALSTORAGE !== 'false'),
          gtm_id: process.env.CLIENT_GTM_ID,
          featureLanding: process.env.FEATURE_LOCAL_LANDING,
          version: {
            releaseVersion: process.env.RELEASE_VERSION,
            siVersion: process.env.SI_VERSION,
            siEnviroment: process.env.SI_ENVIRONMENT,
          },
          recaptchaDisabled:
            process.env.CLIENT_RECAPTCHA_DISABLED === true ||
            process.env.CLIENT_RECAPTCHA_DISABLED === 'true',
          recaptchaSiteKey: process.env.CLIENT_RECAPTCHA_SITE_KEY,
          claimHistoryNoXDaysCheck:
            process.env.CLIENT_CLAIM_HISTORY_NO_XDAYS_CHECK === 'true',
          testBusinessHoursOverride: {
            weekdayStart:
              process.env.CLIENT_TEST_BUSINESS_HOURS_OVERRIDE_WEEKDAY_START,
            weekdayEnd:
              process.env.CLIENT_TEST_BUSINESS_HOURS_OVERRIDE_WEEKDAY_END,
            weekendStart:
              process.env.CLIENT_TEST_BUSINESS_HOURS_OVERRIDE_WEEKEND_START,
            weekendEnd:
              process.env.CLIENT_TEST_BUSINESS_HOURS_OVERRIDE_WEEKEND_END,
            text: {
              stormSurveyorSla: process.env.TEXT_STORM_SURVEYOR_SLA,
            },
          },
          text: {
            stormSurveyorSla: process.env.TEXT_STORM_SURVEYOR_SLA,
          },
          email: {
            householdclaims: process.env.EMAIL_HOUSEHOLD_CLAIMS,
            motorclaims: process.env.EMAIL_MOTOR_CLAIMS,
          },
        },
      };

export default config;
