import moment from 'moment';
import { messages } from '../constants/ErrorMessages';

export function validDate(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      validDate: 'must be a valid calendar date',
    },
    rules: [
      {
        name: 'validDate',
        validate(params, value, state, options) {
          if (!moment(value, 'DD/MM/YYYY').isValid()) {
            return this.createError(
              'string.validDate',
              { value },
              state,
              options,
            );
          }
          return value;
        },
      },
    ],
  };
}

export function lessThanEqual(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      invalidDateComparer: messages.INVALID_DATE_COMPARER,
      invalidDateOrig: messages.INVALID_DATE_ORIG,
      lessThanEqual: messages.INVALID_DATE_LTE,
    },
    rules: [
      {
        name: 'lessThanEqual',
        params: {
          compareDate: Joi.alternatives([
            Joi.string().required(),
            Joi.func().ref(),
          ]),
        },
        validate(params, value, state, options) {
          let comparer = moment(params.compareDate, 'DD/MM/YYYY');
          const originalDate = moment(value, 'DD/MM/YYYY');
          if (!originalDate.isValid()) {
            return this.createError(
              'string.invalidDateOrig',
              { value, compareDate: params.compareDate },
              state,
              options,
            );
          }
          if (!comparer.isValid() && !Joi.isRef(params.compareDate)) {
            return this.createError(
              'string.invalidDateComparer',
              { value, compareDate: params.compareDate },
              state,
              options,
            );
          }

          let { compareDate } = params;
          if (Joi.isRef(params.compareDate)) {
            compareDate = params.compareDate(
              state.reference || state.parent,
              options,
            );
            compareDate = moment(compareDate).format('DD/MM/YYYY');
            comparer = moment(compareDate, 'DD/MM/YYYY');
          }
          if (
            originalDate.isBefore(comparer) ||
            originalDate.isSame(comparer)
          ) {
            return value;
          }
          return this.createError(
            'string.lessThanEqual',
            { value, compareDate: params.compareDate },
            state,
            options,
          );
        },
      },
    ],
  };
}

export function greaterThanEqual(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      invalidDateComparer: messages.INVALID_DATE_COMPARER,
      invalidDateOrig: messages.INVALID_DATE_ORIG,
      greaterThanEqual: messages.INVALID_DATE_GTE,
    },
    rules: [
      {
        name: 'greaterThanEqual',
        params: {
          compareDate: Joi.alternatives([
            Joi.string().required(),
            Joi.func().ref(),
          ]),
        },
        validate(params, value, state, options) {
          let comparer = moment(params.compareDate, 'DD/MM/YYYY');
          const originalDate = moment(value, 'DD/MM/YYYY');
          if (!originalDate.isValid()) {
            return this.createError(
              'string.invalidDateOrig',
              { value, compareDate: params.compareDate },
              state,
              options,
            );
          }
          if (!comparer.isValid() && !Joi.isRef(params.compareDate)) {
            return this.createError(
              'string.invalidDateComparer',
              { value, compareDate: params.compareDate },
              state,
              options,
            );
          }
          let { compareDate } = params;
          if (Joi.isRef(params.compareDate)) {
            compareDate = params.compareDate(
              state.reference || state.parent,
              options,
            );
            compareDate = moment(compareDate).format('DD/MM/YYYY');
            comparer = moment(compareDate, 'DD/MM/YYYY');
          }
          if (originalDate.isAfter(comparer) || originalDate.isSame(comparer)) {
            return value;
          }
          return this.createError(
            'string.greaterThanEqual',
            { value, compareDate: params.compareDate },
            state,
            options,
          );
        },
      },
    ],
  };
}

export function greaterThanEqualToCompare(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      invalidDateComparer: messages.INVALID_DATE_COMPARER,
      invalidDateOrig: messages.INVALID_DATE_ORIG,
      greaterThanEqualToCompare: messages.INVALID_DATE_GTE,
    },
    rules: [
      {
        name: 'greaterThanEqualToCompare',
        params: {
          dateOfBirth: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const comparer = moment(
            state.parent[params.dateOfBirth],
            'DD/MM/YYYY',
          );
          const originalDate = moment(value, 'DD/MM/YYYY');
          if (!originalDate.isValid()) {
            return this.createError(
              'string.invalidDateOrig',
              { value, dateOfBirth: state.parent[params.dateOfBirth] },
              state,
              options,
            );
          }
          if (!comparer.isValid()) {
            return this.createError(
              'string.invalidDateComparer',
              { value, dateOfBirth: state.parent[params.dateOfBirth] },
              state,
              options,
            );
          }
          if (originalDate.isAfter(comparer) || originalDate.isSame(comparer)) {
            return value;
          }
          return this.createError(
            'string.greaterThanEqualToCompare',
            { value, dateOfBirth: state.parent[params.dateOfBirth] },
            state,
            options,
          );
        },
      },
    ],
  };
}

export function inceptionBeforeMax(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      inceptionBeforeMax: messages.INCEPTION_BEFOREMAXAGE,
      invalidMaxAge: messages.INVALID_INTEGER,
      invalidDate: messages.INVALID_DATE,
    },
    rules: [
      {
        name: 'inceptionBeforeMax',
        params: {
          maximumAge: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          dateOfBirth: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const dob = moment(params.dateOfBirth, 'DD/MM/YYYY');
          const inception = moment(value, 'DD/MM/YYYY');

          if (
            params.maximumAge == null ||
            Number.isNaN(params.maximumAge) == null
          ) {
            return this.createError(
              'string.invalidMaxAge',
              { value, maximumAge: params.maximumAge },
              state,
              options,
            );
          }

          if (dob.isValid() && inception.isValid()) {
            const personsAgeOnInception = moment(value, 'DD/MM/YYYY').diff(
              moment(params.dateOfBirth, 'DD/MM/YYYY'),
              'years',
            );

            if (personsAgeOnInception >= params.maximumAge) {
              return this.createError(
                'string.inceptionBeforeMax',
                { value, maximumAge: params.maximumAge },
                state,
                options,
              );
            }
            return value;
          }
          return this.createError(
            'string.invalidDate',
            { value, maximumAge: params.maximumAge },
            state,
            options,
          );
        },
      },
    ],
  };
}

export function inceptionAfterMin(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      inceptionAfterMin: messages.INCEPTION_AFTERMINAGE,
      invalidMinAge: messages.INVALID_INTEGER,
      invalidDate: messages.INVALID_DATE,
    },
    rules: [
      {
        name: 'inceptionAfterMin',
        params: {
          minimumAge: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
          dateOfBirth: Joi.alternatives([
            Joi.string(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const dob = moment(params.dateOfBirth, 'DD/MM/YYYY');
          const inception = moment(value, 'DD/MM/YYYY');
          if (
            params.minimumAge == null ||
            Number.isNaN(params.minimumAge) == null
          ) {
            return this.createError(
              'string.invalidMinAge',
              { value, minimumAge: params.minimumAge },
              state,
              options,
            );
          }

          if (dob.isValid() && inception.isValid()) {
            const personsAgeOnInception = moment(value, 'DD/MM/YYYY').diff(
              moment(params.dateOfBirth, 'DD/MM/YYYY'),
              'years',
            );

            if (personsAgeOnInception < params.minimumAge) {
              return this.createError(
                'string.inceptionAfterMin',
                { value, minimumAge: params.minimumAge },
                state,
                options,
              );
            }
            return value;
          }
          return this.createError(
            'string.invalidDate',
            { value, minimumAge: params.minimumAge },
            state,
            options,
          );
        },
      },
    ],
  };
}

/* Purchase date validation */

export function purchaseDateManufactureYearCompare(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      purchaseDateManufactureYearCompare: messages.INVALID_PURCHASE_DATE,
    },
    rules: [
      {
        name: 'purchaseDateManufactureYearCompare',
        params: {
          yearOfManufacture: Joi.alternatives([
            Joi.string().required(),
            Joi.func().ref(),
          ]),
        },
        validate(params, value, state, options) {
          if (value && params.yearOfManufacture) {
            let { yearOfManufacture } = params;
            if (Joi.isRef(params.yearOfManufacture)) {
              yearOfManufacture = params.yearOfManufacture(
                state.reference || state.parent,
                options,
              );
            }
            const purchaseYear = moment(value, 'DD/MM/YYYY').year();
            if (Number(purchaseYear) < Number(yearOfManufacture)) {
              return this.createError(
                'string.purchaseDateManufactureYearCompare',
                { value, yearOfManufacture: params.yearOfManufacture },
                state,
                options,
              );
            }
            return value;
          }
          return value;
        },
      },
    ],
  };
}
