import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import ConfirmUpdate from '../ConfirmUpdate/ConfirmUpdate';
import {
  FIELD_NAMES,
  MECHANICAL_DAMAGE_TYPES,
  VehicleDamageModalContext,
} from '../motorVehicleDamageDetailsHelper';
import P from '../../../components/StandardTags/P';
import VehicleDamageSelectorGrid from '../../../components/Forms/VehicleDamageSelector/VehicleDamageSelectorGrid';
import { TitleTextSpaced } from '../../../components/StandardPageWrapper/StandardPageWrapperStyle';
import DAMAGE_AREAS from '../../../components/Forms/VehicleDamageSelector/damageValues';
import VehicleDamageSelectorSingle from '../../../components/Forms/VehicleDamageSelector/VehicleDamageSelectorSingle';
import useInitialisedValue from '../../../hooks/useInitialisedValue/useInitialisedValue';
import StandardTextarea from '../../../components/Forms/StandardTextarea/StandardTextarea';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardCheckbox from '../../../components/Forms/StandardCheckbox/StandardCheckbox';
import StandardCheckboxGroup from '../../../components/Forms/StandardCheckboxGroup/StandardCheckboxGroup';

// Separated out so as to isolate scope of rerendering (useFormState causes
// component to rerender whenever any value changes)
const UndersideDamageSelector = ({ validateFields }) => {
  const { values } = useFormState({ subscription: { values: true } });

  const undersideDamagedChangedToYes = useInitialisedValue(
    values[FIELD_NAMES.VEHICLE_UNDERSIDE_IS_DAMAGED] === 'yes',
    false,
  );

  return (
    <ConfirmUpdate
      title="Was there any damage underneath the vehicle?"
      secondaryTitle="If yes, please select the damage to the best of your ability."
      value={null}
      valueInLegend={false}
      yesNoName={FIELD_NAMES.VEHICLE_UNDERSIDE_IS_DAMAGED}
      validate={validateFields}
      renderChildrenOn={['yes']}
      hasButtonsTitle={false}
    >
      <VehicleDamageSelectorSingle
        name={FIELD_NAMES.VEHICLE_UNDERSIDE_DAMAGE_LEVEL}
        damageArea={DAMAGE_AREAS.UNDERSIDE}
        Context={VehicleDamageModalContext}
        validate={validateFields}
        autoLaunch={undersideDamagedChangedToYes}
      />
    </ConfirmUpdate>
  );
};

UndersideDamageSelector.propTypes = {
  validateFields: PropTypes.func,
};

UndersideDamageSelector.defaultProps = {
  validateFields: undefined,
};

const VehicleDamageDetails = ({ validateFields }) => {
  return (
    <ConfirmUpdate
      title="Was your vehicle damaged?"
      value={null}
      yesNoName={FIELD_NAMES.VEHICLE_IS_DAMAGED}
      validate={validateFields}
      renderChildrenOn={['yes']}
      hasButtonsTitle={false}
    >
      <TitleTextSpaced>Damaged area(s) review:</TitleTextSpaced>
      <P>
        Please select where your vehicle is damaged on the car diagram below,
        and to the best of your ability, select the level of damage.
      </P>
      <VehicleDamageSelectorGrid
        name={FIELD_NAMES.VEHICLE_AREA_DAMAGE_LEVELS}
        Context={VehicleDamageModalContext}
        validate={validateFields}
      />

      <UndersideDamageSelector validateFields={validateFields} />

      <ConfirmUpdate
        title="Was there any mechanical damage to the vehicle?"
        value={null}
        yesNoName={FIELD_NAMES.HAS_MECHANICAL_DAMAGE}
        validate={validateFields}
        renderChildrenOn={['yes']}
        hasButtonsTitle={false}
      >
        <StandardCheckboxGroup
          name={FIELD_NAMES.MECHANICAL_DAMAGES}
          title="Which of the descriptions below apply?"
          options={MECHANICAL_DAMAGE_TYPES}
          validate={validateFields}
          enforceSingleOptions
          oneColumnTablet
          columnWidth="16em"
        />
      </ConfirmUpdate>

      <ConfirmUpdate
        title="Was there any damage to the vehicle before the incident?"
        value={null}
        yesNoName={FIELD_NAMES.HAS_PREVIOUS_DAMAGE}
        validate={validateFields}
        renderChildrenOn={['yes']}
        hasButtonsTitle={false}
        allowUnknown="I don't know"
      >
        <StandardTextarea
          name={FIELD_NAMES.PREVIOUS_DAMAGE_DETAILS}
          label="Please provide extra details about the damage sustained to the vehicle"
          maxLength={500}
          validate={validateFields}
          blockProps={{ marginTop: '0' }}
          resize="none"
        />
      </ConfirmUpdate>

      <YesOrNo
        name={FIELD_NAMES.AIRBAGS_TRIGGERED}
        title="Did the airbags activate?"
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.WAS_DRIVEN_AWAY}
        title="Was the vehicle driven away from the incident?"
        validate={validateFields}
      />

      <YesOrNo
        name={FIELD_NAMES.VEHICLE_IS_DRIVABLE}
        title="Are you still able to drive the vehicle?"
        validate={validateFields}
      />

      <StandardCheckbox
        name={FIELD_NAMES.CONFIRMATION}
        label="I confirm that all information I have provided is accurate to the best of my knowledge."
        value="yes"
        validate={validateFields}
        boldLabel={false}
      />
    </ConfirmUpdate>
  );
};

VehicleDamageDetails.propTypes = {
  validateFields: PropTypes.func,
};

VehicleDamageDetails.defaultProps = {
  validateFields: undefined,
};

export default VehicleDamageDetails;
