import styled from 'styled-components';
import { ThemedIcon } from 'ageas-ui-components';
import media from '../../../components/MediaQuery/MediaQueryMin';

const Footer = styled.footer`
  width: 100%;
  background: ${props => props.theme.greyDarker};
  margin-top: 50px;
  padding: 48px 0px;
`;

const FooterContainer = styled.div`
  max-width: 1170px;
  margin: 0 15px;
  ${media.desktop`
    margin: 0 auto;
  `};
`;

const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
  `};
  ul {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 24px 0;
    padding: 32px 15px 0 15px;
    li {
      display: block;
      text-align: left;
      padding-bottom: 8px;
      ${media.desktop`
        display: inline-block;
        width: 33.33%;
      `};
      a {
        color: ${props => props.theme.white};
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
`;

const FooterLinksHeader = styled.div`
  font-weight: bold;
  margin: 0 auto 10px;
  padding: 0 15px;
  color: ${props => props.theme.white};
`;

const FooterText = styled.div`
  padding: 24px 15px;
  border-top-width: 4px;
  border-top-style: dotted;
  border-top-color: ${props => props.theme.black};
  ${media.desktop`
    padding: 0 15px 0 48px;
    margin: 0;
    width: 40%;
    border-top: none;
    border-left-width: 4px;
    border-left-style: dotted;
    border-left-color: ${props => props.theme.black};
  `};
`;

const ChevronIcon = styled(ThemedIcon).attrs(props => ({
  theme: props.theme,
  color: props.theme.white,
}))`
  margin-right: 8px;
`;

const IconWrapper = styled.span`
  display: inline-block;
`;

export {
  Footer,
  FooterLinksContainer,
  FooterLinksHeader,
  FooterText,
  FooterContainer,
  ChevronIcon,
  IconWrapper,
};
