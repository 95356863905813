import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

const validKeys = [];

/**
 * @description: Validate the field is at no more than the maximum specified
 * @param maxVal the maximum the field should be
 * @param canBeEqual if true (default) then the validation will also succeed if the value is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when value of field is less than or equal to the max parameter
 * @fails when value of the field is greater than max parameter
 */
export const max = (
  maxVal,
  keys = validKeys,
  canBeEqual = false,
  message = messages.MAX,
) => {
  if (canBeEqual) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.number()
        .max(maxVal)
        .required()
        .error(() => message),
    );
  }
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.number()
      .less(maxVal)
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the length of the field value is at less than or equal to the length specified
 * @param length the maximum length the field should be
 * @param keys Fields to validate
 * @succeeds when length of field is less than or equal to the length parameter
 * @fails when length of the field is greater than length parameter
 */
export const maxLength = (
  length,
  keys = validKeys,
  message = messages.MAX_LENGTH,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .max(length)
      .error(() => message),
  );
};

/**
 * @description: Validate the field is at no more than the maximum specified
 * @param age the maximum the field should be
 * @param canBeEqual if true (default) then the validation will also succeed if the value is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when value of field is less than or equal to maxAge max parameter
 * @fails when value of the field is greater than maxAge parameter
 */
export const maxAge = (
  age,
  keys = validKeys,
  canBeEqual = false,
  message = messages.MAX_AGE,
) => max(age, keys, canBeEqual, message);

/**
 * @description: Validate the field is at no more than the maximum specified
 * @param maxDays the maximum the field should be
 * @param canBeEqual if true (default) then the validation will also succeed if the value is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when value of field is less than or equal to the maxDays parameter
 * @fails when value of the field is greater than maxDays parameter
 */
export const maxAgeInDays = (
  maxDays,
  keys = validKeys,
  canBeEqual = false,
  message = messages.MAX_AGEINDAYS,
) => max(maxDays, keys, canBeEqual, message);

/**
 * @description: Validate the field is at no more than the maximum specified
 * @param maxMonths the maximum the field should be
 * @param canBeEqual if true (default) then the validation will also succeed if the value is equal to given parameter
 * @param keys Fields to validate
 * @succeeds when value of field is less than or equal to the maxMonths parameter
 * @fails when value of the field is greater than maxMonths parameter
 */
export const maxAgeInMonths = (
  maxMonths,
  keys = validKeys,
  canBeEqual = false,
  message = messages.MAX_AGEINMONTHS,
) => max(maxMonths, keys, canBeEqual, message);
