import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { validPassword } from '../extensions/ValidRelationship';

const validKeys = ['myString'];
const defaultMessage = messages.INVALID_STRING_LENGTH;

/**
 * @description: Validate string's length
 * @param length The max length the string can be
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds When length of string is less than or equal to length
 * @fails When length of string is greater than length
 */
export const stringLength = (
  length,
  keys = validKeys,
  message = defaultMessage,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .max(length)
      .error(() => message),
  );
};

/**
 * @description: Validate string's legnth is within a certain range
 * @param minLength The min length the string can be
 * @param maxLength The max length the string can be
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds When length of string is between the minLength and maxLength values
 * @fails When length of string is smaller than minLength or greater than maxLength
 */

export const stringLengthBetween = (
  minLength,
  maxLength,
  keys = validKeys,
  message,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .min(minLength)
      .max(maxLength)
      .error(
        () =>
          message ||
          `Length of ${keys} can be minimum ${minLength} & maximum ${maxLength} characters`,
      ),
  );
};

/**
 * @description: Validate string is not equal to another string
 * @param compare The other string to compare to
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds When value is not equal to compare string
 * @fails When value is equal to compare string
 */
export const stringNotEqualTo = (
  compare,
  keys = validKeys,
  message = messages.STRING_NOT_EQUAL,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .invalid(compare)
      .error(() => message),
  );
};

/**
 * @description: Validate string is equal to another string
 * @param compare The other string to compare to
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds When value is equal to compare string
 * @fails When value is not equal to compare string
 */
export const stringEqualTo = (
  compare,
  keys = validKeys,
  message = messages.STRING_EQUAL,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .valid(compare)
      .error(() => message),
  );
};

/**
 * @description: Validate current key value is equal to another key value
 * @param compareKey The other string Value to compare
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds When value is equal to compare key value
 * @fails When value is not equal to compare key value
 */

export const passwordEqualityCheck = (compareKey, keys, message) => {
  const JoiExt = Joi.extend(validPassword);
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExt.string()
      .validPassword(compareKey)
      .error(() => message),
  );
};
