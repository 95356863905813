import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import { ThemedErrorMessage } from 'ageas-ui-components';
import { payloadMapperIn as payloadMapperInCausation } from './ModalContents/Causation';
import LoadingSpinner from '../UI/LoadingSpinner/LoadingSpinner';
import { MODAL_MODE, hasDeclaration } from './hubStatementBuilderHelper';
import {
  concurrentErrorCustomTel,
  serverErrorCustomTelNoOOH,
} from '../../helpers/errorMessages';
import config from '../../helpers/config';
import axiosHelper from '../../helpers/axios';
import {
  EDITABLE_STATUSES,
  getStatusByValue,
} from '../../helpers/statementBuilder';
import { consoleError } from '../../helpers/consoleLog';
import Declaration from './ModalContents/Declaration';
import Completion from './ModalContents/Completion';
import Questions from './ModalContents/Questions';
import StandardPopup from '../StandardPopup/StandardPopup';

const Container = styled.div`
  margin-bottom: 24px;
`;

const HubStatementBuilderModal = ({
  claimReference,
  sequenceNumber,
  onSave,
  closeModal,
  piHandlerTelephone,
}) => {
  const [statementRequest, setStatementRequest] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [errorType, setErrorType] = useState(undefined);
  const [modalMode, setModalMode] = useState(undefined);
  const axiosCancelToken = useRef(null);

  const axiosCall = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosHelper.get(
      `${
        config.client.getMotorHubStatementBuilderRequest_endpoint
      }?claimReference=${encodeURIComponent(
        claimReference,
      )}&sequenceNumber=${encodeURIComponent(sequenceNumber)}`,
      {
        cancelToken: axiosCancelToken.current.token,
      },
    );
  };

  useEffect(() => {
    // (re) fetch statement request
    axiosCall()
      .then(({ data }) => {
        const statusObject = getStatusByValue(data?.status);
        // Fail if not New/Opened status any more
        if (!EDITABLE_STATUSES.includes(statusObject)) {
          setErrorType('concurrent');
          setModalMode(MODAL_MODE.LOAD_ERROR);
        } else {
          const mappedData = { ...data };
          mappedData.causation = payloadMapperInCausation(data.causation);
          setStatementRequest(mappedData);
          // Default to showing declaration if it has questions
          if (hasDeclaration(mappedData)) {
            setModalMode(MODAL_MODE.DECLARATION);
          } else {
            setModalMode(MODAL_MODE.QUESTIONS);
          }
        }
        // Must be last due to un-batched state update in react < 18
        setIsLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          consoleError(error);
          setErrorType('generic');
          // Must be last due to un-batched state update in react < 18
          setIsLoading(false);
        }
      });
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const onQuestionsSave = (values, { statementText } = {}) => {
    // Saved questions, expect text back
    // Update text and questions into statement request
    const newStatement = {
      ...statementRequest,
      statementText,
      [statementRequest.type]: values,
    };
    setStatementRequest(newStatement);
    setModalMode(MODAL_MODE.DECLARATION);
  };

  const onDeclarationSave = () => {
    // Saved signature
    // Update card state to indicate this entry is complete
    onSave(sequenceNumber);
    // Switch to completion modal
    setModalMode(MODAL_MODE.COMPLETION);
  };

  const onEdit = () => {
    setModalMode(MODAL_MODE.QUESTIONS);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (modalMode === MODAL_MODE.QUESTIONS) {
    return (
      <Questions
        claimReference={claimReference}
        statementRequest={statementRequest}
        onSave={onQuestionsSave}
        onCancel={closeModal}
        errorTelephone={piHandlerTelephone}
      />
    );
  }

  if (modalMode === MODAL_MODE.DECLARATION) {
    return (
      <Declaration
        claimReference={claimReference}
        statementRequest={statementRequest}
        onSave={onDeclarationSave}
        onCancel={closeModal}
        onEdit={onEdit}
        errorTelephone={piHandlerTelephone}
      />
    );
  }

  if (modalMode === MODAL_MODE.COMPLETION) {
    return <Completion onClose={closeModal} />;
  }

  // Error
  return (
    <StandardPopup
      modalProps={{
        withHeader: true,
        headerText: 'Error',
        withClose: true,
        onCloseClick: closeModal,
      }}
      buttons={[{ onClick: closeModal }]}
    >
      <Container>
        {errorType === 'concurrent' && (
          <ThemedErrorMessage hasIcon>
            {concurrentErrorCustomTel(piHandlerTelephone)}
          </ThemedErrorMessage>
        )}
        {errorType === 'generic' && (
          <ThemedErrorMessage hasIcon>
            {serverErrorCustomTelNoOOH(
              'motor',
              piHandlerTelephone,
              true,
              'motorClaims',
            )}
          </ThemedErrorMessage>
        )}
      </Container>
    </StandardPopup>
  );
};

export default HubStatementBuilderModal;

HubStatementBuilderModal.propTypes = {
  claimReference: PropTypes.string.isRequired,
  sequenceNumber: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  piHandlerTelephone: PropTypes.string.isRequired,
};
