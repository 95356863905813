import React, { useContext, useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import { getTheme } from 'ageas-ui-themes';
import { ThemeProvider } from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import { brand } from '../../../data/whitelabel.config';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../../components/StandardTags/P';
import { EscapeOfWaterContext } from '../../helpers/escapeOfWaterContextHelper';
import YesNoButton from '../../components/YesNoButton/YesNoButton';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import { serverErrorHome } from '../../helpers/errorMessages';
import declineCode from '../../helpers/homeDeclineCodes';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';
import StandardPopup, {
  ModalButtonsContainer,
} from '../../components/StandardPopup/StandardPopup';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import { EndorsementReferText } from '../ThankYouEClaim/ThankYouEClaimHome';
import EOWDocumentUploader from '../../components/EscapeOfWater/EOWDocumentUploader';

const Theme = getTheme(brand);

export const renderPopup = (titleDescription, onConfirmYes, onConfirmNo) => {
  return (
    <StandardPopup>
      <Spacer marginBottom="10px">
        <P>{titleDescription}</P>
      </Spacer>
      <ModalButtonsContainer>
        <ThemedButton primary small type="button" onClick={onConfirmYes}>
          Yes
        </ThemedButton>
        <ThemedButton
          inverted
          primary
          small
          type="button"
          onClick={onConfirmNo}
        >
          Close
        </ThemedButton>
      </ModalButtonsContainer>
    </StandardPopup>
  );
};

const showClaimReferral = referrals => {
  // Check if referrals array contains at least one referral that should be
  // shown to the user
  return referrals.some(
    refer =>
      ![
        'EowRecoveryRefer',
        // 'AnotherReferThatDoesNotShowToUser',
      ].includes(refer),
  );
};

const EscapeOfWaterResult = () => {
  const [navigatePath, setNavigatePath] = useState(null);
  const [renderPage, setRenderPage] = useState(false);
  const [navigateToData, setNavigateToData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isYesNoButton, setIsYesNoButton] = useState(true);
  const [isAcceptPopup, setIsAcceptPopup] = useState(false);
  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const [escapeOfWaterState, setEscapeOfWaterState] = useState(null);

  const PERIL_CODE = 'EW';

  const { dataState: escapeOfWaterContext, clearAllDataState } =
    useContext(EscapeOfWaterContext);

  // Use context data if present, else state if present
  let escapeOfWaterDataFull = escapeOfWaterState;
  if (
    !isEmpty({
      ...escapeOfWaterContext?.escapeOfWaterData,
    })
  ) {
    escapeOfWaterDataFull = escapeOfWaterContext;
  }

  const escapeOfWaterDataListContext = {
    ...escapeOfWaterDataFull?.escapeOfWaterData,
  };

  const {
    claimReference,
    status = {},
    relevantPolicyData = {},
  } = escapeOfWaterDataListContext;
  const { applicableExcess } = relevantPolicyData;
  const { referrals = [] } = status;

  const isEndorsementRefer = referrals.includes('ExcessEndorsementRefer');
  const isReferredData = showClaimReferral(referrals);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!navigatePath) {
      if (isEmpty(escapeOfWaterDataListContext)) {
        setNavigatePath('/homeinsurance');
      } else {
        // If context set but not state, Move context to state
        if (!escapeOfWaterState) {
          setEscapeOfWaterState(escapeOfWaterDataFull);
        }
        setRenderPage(true);
      }
    }
  }, [escapeOfWaterDataFull]);

  useEffect(() => {
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const renderThankYouPage = (accept, supplier) => {
    const showUpload = accept && isReferredData;

    const thankYouData = {
      supplierName: supplier ? supplier.supplierName : '',
      supplierNumber: supplier ? supplier.supplierTelephone : '',
      isAccept: accept,
      isComplete: true,
      isClaimRef: claimReference,
      userContactNumber: escapeOfWaterDataFull?.userContactNumber,
      isReferredData,
      showUpload,
    };

    setNavigateToData(thankYouData);
    setIsLoading(false);
    setNavigatePath(perilRoutes[PERIL_CODE].thankYou);
    clearAllDataState();
  };

  const rejectHomeClaimWithExcessDeclineCode = {
    claimReference,
    reason: declineCode.UNECONOMIC,
  };

  const acceptHomeClaimData = {
    claimReference,
  };

  const postRequest = (endpoint, payload, accept) => {
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(endpoint, payload, {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(data => {
        setIsLoading(false);
        setServerError(false);
        renderThankYouPage(accept, data?.data?.supplierDetails);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  };

  const renderServerErrorComponent = () => {
    return (
      serverError && (
        <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
      )
    );
  };

  const renderDeclineExcessSection = () => {
    setServerError(false);
    setIsYesNoButton(false);
    setIsRejectPopup(true);
  };

  const acceptExcess = () => {
    setServerError(false);
    postRequest(
      config.client.acceptHomeClaim_endpoint,
      acceptHomeClaimData,
      true,
    );
  };

  const onConfirmAccept = () => {
    setIsAcceptPopup(false);
    return acceptExcess();
  };

  const onConfirmReject = () => {
    setIsRejectPopup(false);
    postRequest(
      config.client.rejectHomeClaim_endpoint,
      rejectHomeClaimWithExcessDeclineCode,
      false,
    );
  };

  const onConfirmAcceptClose = () => {
    setIsAcceptPopup(false);
    setIsYesNoButton(true);
  };

  const onConfirmRejectClose = () => {
    setIsRejectPopup(false);
    setIsYesNoButton(true);
  };

  const renderAcceptExcessResult = () => {
    setIsYesNoButton(false);
    setIsAcceptPopup(true);
  };

  const renderExcessSection = () => {
    return (
      <>
        <P>
          Please note that your policy has a total excess of{' '}
          <strong>£{applicableExcess}</strong> for this claim. This is the
          amount you have to pay towards the cost of your claim. Would you like
          to proceed with this claim?
        </P>
        {isYesNoButton && (
          <YesNoButton
            yesButtonLabel="Yes - proceed"
            declineOnClick={renderDeclineExcessSection}
            acceptOnClick={renderAcceptExcessResult}
          />
        )}
        {isAcceptPopup &&
          renderPopup(
            'Please confirm you wish to proceed with your claim.',
            onConfirmAccept,
            onConfirmAcceptClose,
          )}
        {isRejectPopup &&
          renderPopup(
            'Please confirm you no longer wish to proceed with your claim.',
            onConfirmReject,
            onConfirmRejectClose,
          )}
      </>
    );
  };

  const renderMainSection = () => {
    if (isEndorsementRefer) {
      return (
        <>
          <EndorsementReferText
            claimReference={claimReference}
            perilDescription="escape of water"
          />
          <EOWDocumentUploader claimReference={claimReference} />
        </>
      );
    }
    return (
      <>
        {renderExcessSection()}
        {renderServerErrorComponent()}
      </>
    );
  };

  if (navigatePath) {
    return <NavigateTo path={navigatePath} data={navigateToData} />;
  }
  if (!renderPage) {
    return null;
  }

  return (
    <ThemeProvider theme={Theme}>
      {isLoading && <LoadingSpinner />}
      <HomeClaimsWrapper>
        <ClaimBreadcrumbStandard activeItem="results" />
        <PageSectionBare>
          <Subsection>{renderMainSection()}</Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

renderPopup.defaultProps = {
  onConfirmYes: () => {},
  onConfirmNo: () => {},
};

export default EscapeOfWaterResult;
