import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { reverseDate } from '../../helpers/dateTimeConverter';
import { HomeClaimsFNOL } from '../../helpers/ageasTelephoneNumbers';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  TitleText,
  PageSectionBare,
  Subsection,
  ButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import StandardP from '../../components/StandardTags/P';
import { serverErrorHome } from '../../helpers/errorMessages';
import { getBusinessHoursDescriptive } from '../../helpers/businessHoursData';
import { StormContext } from '../../helpers/stormContextHelper';
import {
  AccidentalDamageContext,
  AccidentalLossContext,
  TheftContext,
} from '../../helpers/personalLossContextHelper';
import { FreezerFoodContext } from '../../helpers/freezerFoodContextHelper';
import { EscapeOfWaterContext } from '../../helpers/escapeOfWaterContextHelper';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';
import { consoleError } from '../../helpers/consoleLog';

const HistoryTable = styled.table`
  text-align: center;
  width: 550px;
  & tr {
    background-color: #f5f5f5;
  }
  & tr:nth-child(even) {
    background-color: #ebebeb;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const HistoryTableHeader = styled.th`
  font-size: 20px;
  color: #8e419c;
  padding: 12px;
  background-color: #ebebeb;
  width: 12em;
`;

const HistoryTableCell = styled.td`
  font-size: 16px;
  padding: 15px;
`;

const buildTableRows = data => {
  return data.map(claim => (
    <tr key={claim.claimNumber}>
      <HistoryTableCell>{claim.claimNumber}</HistoryTableCell>
      <HistoryTableCell>{reverseDate(claim.adviceDate)}</HistoryTableCell>
    </tr>
  ));
};

const HomeClaimsHistory = ({
  perilCode,
  description,
  nextScreen,
  xDaysCheck,
  xDaysDescriptor,
  perilContext,
  isCompleteRedirectPath,
  forwardQueryParams,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [disableContinue, setDisableContinue] = useState(true);
  const [serverError, setServerError] = useState(false);
  const axiosCancelToken = useRef(null);
  const [navigatePath, setNavigatePath] = useState(null);
  const [pageRender, setPageRender] = useState(false);
  const [xDaysCheckFail, setXDaysCheckFail] = useState(false);
  const currentLocation = useLocation();

  let perilContextState;
  if (perilContext) {
    perilContextState = useContext(perilContext).dataState;
  }

  const navigateToNextScreen = () => {
    // Copy forwardQueryParams query params from current URL to target URL
    const currentQueryParams = new URLSearchParams(currentLocation.search);
    const newQueryParams = new URLSearchParams();
    forwardQueryParams.forEach(key => {
      if (currentQueryParams.has(key)) {
        newQueryParams.set(key, currentQueryParams.get(key));
      }
    });
    const queryString = newQueryParams.toString();
    let separator = '';
    if (queryString) {
      separator = nextScreen.includes('?') ? '&' : '?';
    }
    // Navigate tio target URL + query params
    setNavigatePath(nextScreen + separator + queryString);
  };

  const formatPerilDescription = () => {
    return description ? `${description} ` : '';
  };

  const withinXDaysCheck = (historyData, xDays) => {
    if (xDays && !config.client.claimHistoryNoXDaysCheck) {
      // Calculate the date x days ago, as a yyyy-mm-dd string
      const dateXDaysAgo = DateTime.now()
        .minus({ days: xDays })
        .toFormat('yyyy-LL-dd');
      // Check fails (claim within x days? set state var to true, else false)
      setXDaysCheckFail(
        historyData.some(entry => entry.adviceDate >= dateXDaysAgo),
      );
    }
  };

  const getClaimsHistory = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .get(
        `${
          config.client.gethomeclaimhistory_endpoint
        }?peril=${encodeURIComponent(perilCode)}`,
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(({ data }) => {
        if (!data?.homeClaimHistory?.length) {
          navigateToNextScreen();
          return;
        }
        setIsLoading(false);
        setDisableContinue(false);
        setTableData(data.homeClaimHistory);
        withinXDaysCheck(data.homeClaimHistory, xDaysCheck);
      })
      .catch(error => {
        consoleError('axios error', error);
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          setDisableContinue(true);
          setServerError(true);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClaimsHistory();
    if (perilContextState?.detailsCompleted && isCompleteRedirectPath) {
      setNavigatePath(isCompleteRedirectPath);
    } else {
      setPageRender(true);
    }
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const onContinueClick = () => {
    navigateToNextScreen();
  };

  if (navigatePath) {
    return <NavigateTo path={navigatePath} />;
  }
  // server side render will not have localStorage. Suppress page content load
  // if context (form localStorage) is unavailable, so initial server side
  // page render does not display incorrect info in the split second before
  // client side updates from localStorage
  if (!pageRender) {
    return null;
  }

  const onClickClose = () => {
    setNavigatePath('/homeinsurance');
  };

  const renderClose = () => {
    return (
      <ButtonsBottomContainer>
        <ThemedButton
          primary
          inverted
          small
          type="button"
          onClick={onClickClose}
        >
          Close
        </ThemedButton>
      </ButtonsBottomContainer>
    );
  };

  const buildXDaysDescriptor = (days, custom) => {
    return custom || `${days} days`;
  };

  return (
    <HomeClaimsWrapper>
      {isLoading ? <LoadingSpinner /> : ''}
      <PageSectionBare>
        <Subsection>
          <TitleText>Existing claims</TitleText>
          <StandardP>
            You have made the following claims for {formatPerilDescription()} in
            the last 12 months:
          </StandardP>
        </Subsection>
        <Subsection>
          {tableData && (
            <HistoryTable>
              <thead>
                <tr>
                  <HistoryTableHeader>Claim number</HistoryTableHeader>
                  <HistoryTableHeader>Advice date</HistoryTableHeader>
                </tr>
              </thead>
              <tbody>{buildTableRows(tableData)}</tbody>
            </HistoryTable>
          )}
        </Subsection>
        {tableData && !xDaysCheckFail && (
          <>
            <Subsection>
              <StandardP>
                If you would like to talk to us about the above claim(s), please
                call the Claims Team on <HomeClaimsFNOL /> between the hours of{' '}
                {getBusinessHoursDescriptive('home')}.
              </StandardP>
              <StandardP>
                If you would like to make a new {formatPerilDescription()}{' '}
                claim, please click Continue.
              </StandardP>
            </Subsection>
            <ButtonsBottomContainer>
              <ThemedButton
                type="button"
                secondary
                disabled={disableContinue}
                onClick={onContinueClick}
              >
                Continue
              </ThemedButton>
            </ButtonsBottomContainer>
          </>
        )}
        {tableData && xDaysCheckFail && (
          <>
            <StandardP>
              Unfortunately, as you have made a claim for{' '}
              {formatPerilDescription()} in the last{' '}
              {buildXDaysDescriptor(xDaysCheck, xDaysDescriptor)}, we are unable
              to process your claim online at this time. Please call the Claims
              Team on <HomeClaimsFNOL /> between the hours of{' '}
              {getBusinessHoursDescriptive('home')}.
            </StandardP>
            {renderClose()}
          </>
        )}
        {serverError && (
          <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
        )}
      </PageSectionBare>
    </HomeClaimsWrapper>
  );
};

HomeClaimsHistory.propTypes = {
  perilCode: PropTypes.string,
  description: PropTypes.string,
  nextScreen: PropTypes.string,
  xDaysCheck: PropTypes.number,
  xDaysDescriptor: PropTypes.string,
  perilContext: PropTypes.shape({}),
  isCompleteRedirectPath: PropTypes.string,
  forwardQueryParams: PropTypes.arrayOf(PropTypes.string),
};
HomeClaimsHistory.defaultProps = {
  perilCode: undefined,
  description: undefined,
  nextScreen: '/homeinsurance',
  xDaysCheck: undefined,
  xDaysDescriptor: undefined,
  perilContext: undefined,
  isCompleteRedirectPath: undefined,
  forwardQueryParams: [],
};

export default HomeClaimsHistory;

export const HomeClaimsHistoryStorm = ({ ...props }) => {
  return (
    <HomeClaimsHistory
      {...props}
      perilCode="ST"
      description="storm damage"
      nextScreen={perilRoutes.ST.incidentDetails}
      perilContext={StormContext}
      isCompleteRedirectPath={perilRoutes.ST.results}
    />
  );
};

export const HomeClaimsHistoryAccidentalDamage = ({ ...props }) => {
  return (
    <HomeClaimsHistory
      {...props}
      perilCode="AD"
      description="accidental damage"
      nextScreen={perilRoutes.AD.incidentDetails}
      xDaysCheck={30}
      perilContext={AccidentalDamageContext}
      isCompleteRedirectPath={perilRoutes.AD.results}
      forwardQueryParams={['itemType']}
    />
  );
};

export const HomeClaimsHistoryAccidentalLoss = ({ ...props }) => {
  return (
    <HomeClaimsHistory
      {...props}
      perilCode="AL"
      description="accidental loss"
      nextScreen={perilRoutes.AL.incidentDetails}
      xDaysCheck={30}
      perilContext={AccidentalLossContext}
      isCompleteRedirectPath={perilRoutes.AL.results}
      forwardQueryParams={['itemType']}
    />
  );
};

export const HomeClaimsHistoryTheft = ({ ...props }) => {
  return (
    <HomeClaimsHistory
      {...props}
      perilCode="TF"
      description="theft"
      nextScreen={perilRoutes.TF.incidentDetails}
      perilContext={TheftContext}
      isCompleteRedirectPath={perilRoutes.TF.results}
      forwardQueryParams={['itemType']}
    />
  );
};

export const HomeClaimsHistoryFreezerFood = ({ ...props }) => {
  return (
    <HomeClaimsHistory
      {...props}
      perilCode="FF"
      description="freezer food"
      nextScreen={perilRoutes.FF.incidentDetails}
      xDaysCheck={365}
      xDaysDescriptor="12 months"
      perilContext={FreezerFoodContext}
      isCompleteRedirectPath={perilRoutes.FF.results}
    />
  );
};

export const HomeClaimsHistoryEscapeOfWater = ({ ...props }) => {
  return (
    <HomeClaimsHistory
      {...props}
      perilCode="EW"
      description="escape of water"
      nextScreen={perilRoutes.EW.incidentDetails}
      xDaysCheck={365}
      xDaysDescriptor="12 months"
      perilContext={EscapeOfWaterContext}
      isCompleteRedirectPath={perilRoutes.EW.results}
    />
  );
};
