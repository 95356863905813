export const messages = {
  CIVIL_PARTNER: 'Marital status must match the relationship to proposer',
  ONE_SPOUSE:
    'There can only be one additional policyholder with a spouse relationship to proposer',
  CANNOT_BE_SINGLE:
    "You have stated this person is your Spouse and therefore this person's marital status cannot be single.",
  SPOUSE: 'Marital status must match the relationship to proposer',
  PARENT: "The parent's date of birth should come before a childs",
  OFFSPRING: "A child's date of birth should come after a parents",
  EMAIL: 'Please enter a valid email address',
  EMPTY_STRING: 'Please fill in a value',
  EMPTY_OPTION: 'Please pick an option',
  EMPTY_DATE: 'Please fill in a valid date',
  EMPTY_NUMBER: 'Please fill in a valid number',
  INVALID_WEBREFERENCE:
    'Please fill in a valid webreference (0000-000000-0000) or (00000-00000-00000)',
  INVALID_ACCOUNT_NUMBER: 'Please fill in a valid account number (00000000)',
  INVALID_SORT_CODE: 'Please enter a valid sort code (000000)',
  CHANNEL_ISLANDS_NOT_ALLOWED:
    'We cannot provide a quote for addresses in the Channel Islands',
  INVALID_REGISTRATION: 'Please enter a valid registration number',
  INVALID_POSTCODE: 'Please enter a valid postcode',
  INVALID_STRING_LENGTH:
    'The number of characters entered is more than allowed',
  INVALID_TELEPHONE_NUMBER: 'Please enter a valid telephone number',
  INVALID_REQUIRED_IF_TRUE: 'Please provide a value',
  INVALID_REQUIRED_IF_FALSE: 'Please provide a value',
  INVALID_REQUIRED_IF_EMPTY: 'Please provide a value',
  INVALID_REGULAR_EXPRESSION: 'Regular expression does not match',
  OUTSIDE_RANGE: 'The number falls outside the permissible range',
  MUST_BE_TRUE: 'Value must be true',
  MUST_BE_FALSE: 'Value must be false',
  MUST_BE_FALSE_OR_UNDEFINED: 'Value must be false',
  MIN: 'Value should be greater than specified minimum',
  MIN_LENGTH:
    'The number of characters entered is less than the number required',
  MIN_AGEINMONTHS: 'The age in months is less than minimum',
  MIN_AGEINDAYS: 'The age in days is less than minimum',
  MIN_AGE: 'The age is less than the minimum',
  MAX: 'Value should be less than specified minimum',
  MAX_LENGTH:
    'The number of characters entered is greater than the number required',
  MAX_AGEINMONTHS: 'The age in months is greater than minimum',
  MAX_AGEINDAYS: 'The age in days is greater than minimum',
  MAX_AGE: 'The age is greater than the minimum',
  INVALID_EMPLOYMENT_STATUS: 'Not Employed',
  INVALID_OCCUPATION:
    '#EmpStatus# status must not have #field# as the occupation',
  INVALID_INTEGER: 'Value should be an integer',
  INVALID_DATE: 'Value should be a valid date',
  INVALID_DATE_OF_BIRTH: 'Please enter a valid date of birth',
  DATE_LESSTHANOREQUALTO: 'date should be less than or equal to #field#',
  INVALID_DATE_COMPARER: 'invalid date passed {{compareDate}}',
  INVALID_DATE_ORIG: 'invalid date passed {{value}}',
  INVALID_DATE_LTE: '{{value}} is not less than or equal to {{compareDate}}',
  INVALID_DATE_GTE: '{{value}} is not greater than or equal to {{compareDate}}',
  GREATERTHANOREQUALTO_YEAR: 'The year should be less than or equal',
  STRING_NOT_EQUAL: 'The strings provided should not be the same',
  STRING_EQUAL: 'The strings provided should be the same',
  INVALID_GENDER_TITLE: 'The gender and title do not match',
  INVALID_LICENCE_DATE:
    'The date that the licence was obtained must be on or after the 17th birthday',
  INVALID_LICENCE_HELD:
    'The number of years a licence is held must be less than or equal to the years since the 17th birthday',
  INVALID_ADDRESS_EVENT: 'Please click Find address',
  INVALID_ADDRESS_SELECT_EVENT: 'Please select the correspondence address',
  RESIDENCY_BEFORETODAY: 'Value should be before or equal to today',
  RESIDENCY_BEFOREINCEPTION:
    'Value should be before or equal to policy inception date',
  RESIDENCY_AFTERDOB: 'Value should be after or equal to date of birth',
  INCEPTION_BEFOREMAXAGE:
    'Age on inception date should be less than {{maximumAge}}',
  INCEPTION_AFTERMINAGE:
    'Age on inception date should be greater than {{minimumAge}}',
  ONLY_ONE_SPOUSE:
    'There can only be one additional policyholder with a spouse relationship to proposer.',
  SINGLE_AND_SPOUSE:
    "You have stated this person is your spouse and therefore this person's marital status cannot be single.",
  SINGLE_AND_CIVIL_PARTNER:
    "You have stated this person is your civil partner and therefore this person's marital status must be civil partnered.",
  MUST_BE_CIVIL_PARTNER:
    "You have stated this person's relationship to you is civil partner therefore your marital status must be civil partnered.",
  MUST_BE_MARRIED:
    "You have stated this person's relationship to you is spouse therefore their marital status must be married.",
  ONLY_ONE_CIVIL_PARTNER:
    'There can only be one additional policyholder with a civil partner relationship to proposer.',
  MUST_BE_SAME_GENDER: 'Genders must be the same',
  MUST_NOT_BE_SAME_GENDER: 'Genders must not be the same',
  CAR_VALUE: 'Please enter a value',
  DROPDOWN_VALUE: 'Please select a valid value from the dropdown list',
  CAR_MILEAGE: 'Please tell us how many miles you drive in a year',
  CAR_BUSINESS_MILEAGE:
    'Please tell us how many business miles you drive in a year',
  CAR_BUSINESS_MILEAGE_LESSTHANOREQUALTO:
    'Business mileage must be less than total mileage',
  TRACKING_DEVICE_NOT_ENTERED:
    'Please select if the car is fitted with a tracking device',
  NAME_INVALID:
    "Invalid name format! Only alphabets, apostrophe ('), hyphen (-) and spaces are allowed",
  FIRST_NAME_INCORRECT_LENGTH:
    'Length of first name can be minimum 2 & maximum 50 characters',
  SURNAME_INCORRECT_LENGTH:
    'Length of surname can be minimum 2 & maximum 50 characters',
  SURNAME_MISSING: 'Please tell us your surname',
  FIRST_NAME_NOT_ENTERED: 'Type first name',
  SURNAME_NOT_ENTERED: 'Type surname',
  POLICY_NUMBER_MISSING: 'Please enter the policy number',
  POLICY_NUMBER_INVALID:
    "We're sorry, we don't recognise that policy number. Please try again",
  DATE_OF_BIRTH_MISSING: 'Please enter the date of birth',
  DATE_OF_BIRTH_UNDER_18: 'You must be 18 or older to register',
  DATE_OF_BIRTH_UNDER_17: 'The driver must be 17 or over',
  POSTCODE_MISSING: 'Please enter the post code',
  PASSWORD_LENGTH: 'Password should be between 8 and 16 characters',
  REGISTRATION_PASSWORD_LENGTH:
    'Password should be between 8 and 16 characters, including at least 1 letter and 1 number',
  REGISTRATION_PASSWORD_EMPTY: 'Please tell us your password.',
  PASSWORD_CHARS_LENGTH:
    'Password should contain a number, a letter and must be within 8 to 16 characters',
  TITLE_MISSED: 'Please pick a title',
  GENDER_MISSED: 'Please pick a gender',
  INDUSTRY_TYPE: 'Please choose the business area',
  SELECT_OPTION: 'Please pick Yes or No',
  ARRAY_SHOULD_HAVE_ENTRIES: 'Please add an entry',
  PROVIDE_DATE: 'Please provide a date',
  PAYMENT_OPTION_NOT_SELECTED:
    'Please confirm above how you would like to pay for your change',
  LICENCE_TYPE_MISSING: 'What type of licence do they hold?',
  LICENCE_DURATION_MISSING: 'Please pick an option',
  MULTIPLE_CAR_INFO_MISSING: 'Please pick an option',
  LICENCE_ACQUIRED_IN_THE_FUTURE: 'Enter a date before today',
  CUSTOM_LICENCE_DATE_MORE_THAN_ONE_YEAR:
    'Please select a date that was less than a year ago',
  HAS_CONVICTIONS:
    "We're really sorry but we're unable to give you a quote at this time.",
  HAS_MEDICAL_CONDITION:
    "We're really sorry but we need to discuss any restrictions with you before we can give you quote. The quickest way to do this is to click on 'Live Chat', which you will find on the side bar of this page.",
  HAS_POLICY_REFUSED:
    "We're really sorry but we need to discuss any restrictions with you before we can give you quote. The quickest way to do this is to click on 'Live Chat', which you will find on the side bar of this page.",
  DRINK_LEVEL_MAX_250: 'Please enter a value less than or equal to 250.',
  INVALID_ADDRESS_LINE: 'Please enter a valid address line',
  SELECT_KEPT_OVERNIGHT:
    'Please select if the car is parked overnight at your home address',
  DASHCAM_MISSING: 'Please select if the car is fitted with a dashcam',
  WHERE_KEPT_DUQS_NOT_ENTERED:
    'Please select where the car is normally kept overnight',
  MILES_PER_WEEK: 'This is equal to around {{milesPerWeek}} miles a week.',
  CONDITIONAL_VALIDATION: 'Please select correct options',
  YOUNGER_THAN_PROPOSER:
    'This person cannot be your parent as they are younger than you',
  OLDER_THAN_PROPOSER:
    'This person cannot be your parent as they are younger than you',
  MOTOR_CLAIMS_TYPE: 'Please pick the type of claim you made',
  SUB_MOTOR_CLAIMS_TYPE: 'Please pick the type of sub-claim',
  CONTTHEFT_CLAIMS_TYPE: "Please pick the type of 'Theft-Contents Only' claim",

  FIRE_CLAIMS_TYPE: "Please pick the type of 'Fire' claim",
  MULTVEH_CLAIMS_TYPE: "Please pick the type of 'Multiple Vehicle' claim",
  SINGVEH_CLAIMS_TYPE: "Please pick the type of 'Single Vehicle' claim",
  STORM_CLAIMS_TYPE: "Please pick the type of 'Storm' claim",
  THEFTCTGRY_CLAIMS_TYPE:
    "Please pick the type of 'Theft-Vehicle Recovered' claim",
  TOTALTHFTL_CLAIMS_TYPE:
    "Please pick the type of 'Theft-Vehicle not Recovered' claim",
  VANDAL_CLAIMS_TYPE:
    "Please pick the type of 'Vandalism/Malicious Damage' claim",
  WINDSCREEN_CLAIMS_TYPE: "Please pick the type of 'Windscreen' claim",
  CAR_MODIFIED:
    "We need to know about any changes that have been made to your car that aren't in the manufacturer's specification. The quickest way to do this is to click on 'Violet the Ageas assistant', which you'll find at the bottom of this page.",
  INVALID_PURCHASE_DATE:
    'Purchase date cannot be prior to the vehicle registration date',
  INVALID_REGISTRATION_PASSWORD:
    'Password should be between 8 and 16 characters, including at least 1 letter and 1 number and we only accept the following special characters !$%^&*#@',
};

export default messages;
