import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { ThemedErrorMessage } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { FIELD_NAME } from './VehicleDamageModalForm';
import VehicleDamageResultsDisplay from './VehicleDamageResultsDisplay';
import ButtonLink from '../../ThemedButtonLink/ThemedButtonLink';

export const getStandardRequiredSchema = (
  fieldName,
  message = 'Please select level of damage',
) => {
  return {
    [fieldName]: stringRequired([fieldName], message),
  };
};

const VehicleDamageSelectorSingleInner = ({
  input,
  meta,
  Context,
  damageArea,
  autoLaunch = false,
}) => {
  const { onAdd, hasBeenTouched } = useContext(Context);
  const refs = useRef();
  refs.current = { input, meta, damageArea };

  const values = useMemo(() => {
    return input.value ? { [damageArea.name]: input.value } : {};
  }, [input.value]);

  const onAreaDamageChange = useCallback(damage => {
    refs.current.input.onChange(damage || undefined);
  }, []);

  const openModal = useCallback(() => {
    const update = newValues => onAreaDamageChange(newValues.damage);
    const initialValues = {
      [FIELD_NAME]: refs.current.input?.value,
    };
    onAdd(update, `Damage to ${refs.current.damageArea.label}`, initialValues, {
      includeNone: false,
    });
  }, []);

  // Automatically open the modal when autoLaunch transitions to true if no
  // damage level selected yet
  useEffect(() => {
    if (autoLaunch && !refs.current.input.value) {
      openModal();
    }
  }, [autoLaunch]);

  return (
    <>
      <VehicleDamageResultsDisplay values={values} isSingle>
        <ButtonLink primary underline onClick={openModal}>
          Edit level of damage
        </ButtonLink>
      </VehicleDamageResultsDisplay>

      {meta.error && (meta.touched || hasBeenTouched) && (
        <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
      )}
    </>
  );
};

VehicleDamageSelectorSingleInner.propTypes = {
  damageArea: PropTypes.instanceOf(Object).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  Context: PropTypes.instanceOf(Object).isRequired,
  autoLaunch: PropTypes.bool,
};
VehicleDamageSelectorSingleInner.defaultProps = {
  autoLaunch: undefined,
};

const VehicleDamageSelectorSingle = ({ name, Context, validate, ...rest }) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <VehicleDamageSelectorSingleInner
          input={input}
          meta={meta}
          Context={Context}
          {...rest}
        />
      )}
    </Field>
  );
};

export default VehicleDamageSelectorSingle;

VehicleDamageSelectorSingle.propTypes = {
  damageArea: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  Context: PropTypes.instanceOf(Object).isRequired,
  validate: PropTypes.func,
  autoLaunch: PropTypes.bool,
};
VehicleDamageSelectorSingle.defaultProps = {
  validate: undefined,
  autoLaunch: undefined,
};
