import { dmyToIso, isoToDmy } from '../dateTimeConverter';
import postcodeSplitter from '../postcodeSplitter';

/**
 * Depreciated, use version in src/helpers/yesNoToBoolean
 * @param {*} value
 * @param {*} ifOther
 * @returns
 * @depreciated
 */
export const yesNoToBoolean = (value, ifOther = undefined) => {
  const valueSanitised = value?.trim()?.toLowerCase();
  if (valueSanitised === 'yes') {
    return true;
  }
  if (valueSanitised === 'no') {
    return false;
  }
  return ifOther;
};

/**
 * Depreciated, use version in src/helpers/yesNoToBoolean
 * @param {*} value
 * @returns
 * @depreciated
 */
export const isYes = value => value === 'yes';

/**
 * Depreciated, use version in src/helpers/yesNoToBoolean
 * @param {*} value
 * @param {*} ifOther
 * @returns
 * @depreciated
 */
export const booleanToYesNo = (value, ifOther = undefined) => {
  if (value === true) {
    return 'yes';
  }
  if (value === false) {
    return 'no';
  }
  return ifOther;
};

/**
 * Depreciated, use version in src/helpers/yesNoToBoolean
 * @param {*} value
 * @returns
 * @depreciated
 */
export const isOther = value => value?.trim() === 'other';

export const dmyToIsoOrUndefined = dateString =>
  dateString ? dmyToIso(dateString) || undefined : undefined;

export const isoToDmyOrUndefined = dateString =>
  dateString ? isoToDmy(dateString) || undefined : undefined;

export const involvedPartyType = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
};

export const mapInvolvedPartyToServer = (data, fieldPrefix, type) => {
  const involvedParty = data[fieldPrefix];
  const postcode = postcodeSplitter(involvedParty?.postcode?.trim());
  const isCompany = type?.trim() === involvedPartyType.COMPANY;
  const isIndividual = type?.trim() === involvedPartyType.INDIVIDUAL;
  return {
    companyName: isCompany ? involvedParty?.companyName?.trim() : undefined,
    title: isIndividual ? involvedParty?.title?.trim() : undefined,
    firstName: isIndividual ? involvedParty?.firstName?.trim() : undefined,
    lastName: isIndividual ? involvedParty?.lastName?.trim() : undefined,
    houseNumber: involvedParty?.houseNumberName?.trim(),
    addressLine1: involvedParty?.streetLine1?.trim(),
    addressLine2: involvedParty?.streetLine2?.trim(),
    district: involvedParty?.district?.trim(),
    townCity: involvedParty?.town?.trim(),
    county: involvedParty?.county?.trim(),
    postCode1: postcode.outward,
    postCode2: postcode.inward,
    workTelephone: involvedParty?.workTelephone?.trim(),
    homeTelephone: involvedParty?.homeTelephone?.trim(),
    telephoneNumber: involvedParty?.mobileTelephone?.trim(),
    emailAddress: involvedParty?.emailAddress?.trim(),
  };
};

export const mapInvolvedPartyToLocal = (data, fieldPrefix) => {
  return {
    [fieldPrefix]: {
      companyName: data?.companyName?.trim(),
      title: data?.title?.trim(),
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      houseNumberName: data?.houseNumber?.trim(),
      streetLine1: data?.addressLine1?.trim(),
      streetLine2: data?.addressLine2?.trim(),
      district: data?.district?.trim(),
      town: data?.townCity?.trim(),
      county: data?.county?.trim(),
      postcode:
        [data?.postCode1?.trim(), data?.postCode2?.trim()]
          .filter(x => !!x)
          .join('') || undefined,
      // data?.postCode1?.trim() + data?.postCode2?.trim(),
      workTelephone: data?.workTelephone?.trim(),
      homeTelephone: data?.homeTelephone?.trim(),
      mobileTelephone: data?.telephoneNumber?.trim(),
      emailAddress: data?.emailAddress?.trim(),
    },
  };
};

export const mapConvictionsToServer = convictions =>
  convictions?.map(({ convictionType, convictionDate }) => ({
    convictionType,
    convictionDate: dmyToIsoOrUndefined(convictionDate),
  }));

export const mapClaimsToServer = claims =>
  claims?.map(({ claimType, claimDate }) => ({
    claimType,
    claimDate: dmyToIsoOrUndefined(claimDate),
  }));

export const mapConvictionsToLocal = convictions =>
  convictions?.map(({ convictionType, convictionDate }) => ({
    convictionType,
    convictionDate: isoToDmyOrUndefined(convictionDate)?.date,
  }));

export const mapClaimsToLocal = claims =>
  claims?.map(({ claimType, claimDate }) => ({
    claimType,
    claimDate: isoToDmyOrUndefined(claimDate)?.date,
  }));
