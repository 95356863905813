import React, { useRef, useState, useEffect, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Form } from 'react-final-form';
import { getTheme } from 'ageas-ui-themes';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { numberRequired } from 'ageasvalidation/lib/schemas/Required';
import { min } from 'ageasvalidation/module/schemas/Min';
import { max } from 'ageasvalidation/module/schemas/Max';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import { dateISOConverter } from '../../helpers/dateTimeConverter';
import { brand } from '../../../data/whitelabel.config';
import HomeStandardClaimDetails, {
  getStandardClaimDetailsValidationSchema,
} from '../HomeStandardClaimDetails/HomeStandardClaimDetails';
import {
  freezerFoodDamageDataContext,
  FreezerFoodContext,
} from '../../helpers/freezerFoodContextHelper';
import { buildStateFromCookies } from '../../contexts/AuthContext';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import {
  getIncidentDateValidationSchema,
  run3YearDateValidation,
} from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import { validateField } from '../../helpers/validationHelper';
import {
  serverErrorHome,
  getErrorMessage,
  validPolicyErrorHome,
} from '../../helpers/errorMessages';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  TitleText,
  PageSectionBare,
  Subsection,
  ButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../../components/StandardTags/P';
import HouseHoldDetails, {
  numberOfPeopleSchema,
} from '../../components/FreezerFoodQuestions/HouseholdDetails';
import PeriodicallyShopping, {
  regularShoppingSchema,
} from '../../components/FreezerFoodQuestions/PeriodicallyShopping';
import FreezerType, {
  freezerTypeSchema,
} from '../../components/FreezerFoodQuestions/FreezerType';
import FreezerTimeline, {
  freezerTimelineSchema,
} from '../../components/FreezerFoodQuestions/FreezerTimeline';
import ElectricitySupplierDetails, {
  electricitySupplierDetailsSchema,
} from '../../components/FreezerFoodQuestions/ElectricitySupplierDetails';
import StandardCurrencyInput from '../../components/Forms/StandardCurrencyInput/StandardCurrencyInput';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import YesOrNo, {
  yesOrNoSchema,
} from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import { policyDataValid } from '../../helpers/policyDataContextHelper';

const Theme = getTheme(brand);

const CurrencyInput = styled(StandardCurrencyInput)`
  width: 100px;
`;

const INCIDENT_DATE_FIELD_NAME = 'damagedDate';
const POLICYHOLDER_NAME = 'policyHolderNumber';
const MOBILE_TELEPHONE_NAME = 'homeMobileTelephone';
const EMAIL_ADDRESS_NAME = 'homeEmail';
const POWERCUT = 'powercut';
const NUMBER_OF_PEOPLE = 'numberOfPeople';
const REGULAR_SHOPPING = 'regularShopping';
const FREEZER_TYPE = 'freezerType';
const FREEZER_TIMELINE = 'freezerTimeline';
const ELECTRICITY_SUPPLIER = 'electricitySupplier';
const AMOUNT_CLAIMED = 'amountClaimed';

const schema = {
  ...getIncidentDateValidationSchema(INCIDENT_DATE_FIELD_NAME),
  ...getStandardClaimDetailsValidationSchema(
    POLICYHOLDER_NAME,
    MOBILE_TELEPHONE_NAME,
    EMAIL_ADDRESS_NAME,
  ),
  ...yesOrNoSchema(POWERCUT),
  ...numberOfPeopleSchema(NUMBER_OF_PEOPLE),
  ...regularShoppingSchema(REGULAR_SHOPPING),
  ...freezerTypeSchema(FREEZER_TYPE),
  ...freezerTimelineSchema(FREEZER_TIMELINE),
  ...electricitySupplierDetailsSchema(ELECTRICITY_SUPPLIER),
  [AMOUNT_CLAIMED]: numberRequired([AMOUNT_CLAIMED], 'Please enter a value')
    .concat(min(1, [AMOUNT_CLAIMED], true, 'Please enter a value'))
    .concat(max(999, [AMOUNT_CLAIMED], true, 'Please enter a value')),
};

const FreezerFoodClaim = () => {
  const [navigatePath, setNavigatePath] = useState(null);
  const { dataState: freezerFoodContext, updateDataState } =
    useContext(FreezerFoodContext);
  const policyDetailsContext = {
    ...freezerFoodContext?.policyDetails,
  };

  const [serverError, setServerError] = useState(false);
  const [notInForcePolicy, isNotInForcePolicy] = useState(false);
  const [noCover, isNotCovered] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderedPage, setRenderedPage] = useState(false);

  const PERIL_CODE = 'FF';

  const resetStateErrors = () => {
    setServerError(false);
    isNotInForcePolicy(false);
    isNotCovered(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isEmpty(freezerFoodContext) && freezerFoodContext?.detailsCompleted) {
      setNavigatePath('/homeclaim/freezerfood/results');
    } else {
      setRenderedPage(true);
    }
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (
      policyDetailsContext?.status === 'nokey' &&
      !buildStateFromCookies().isLoggedIn
    ) {
      setNavigatePath(
        `/homeclaim/brokerlogin?target=${encodeURIComponent(
          window.location.pathname,
        )}`,
      );
    }
  }, [policyDetailsContext?.status]);

  if (navigatePath) {
    return <NavigateTo path={navigatePath} />;
  }

  if (!renderedPage) {
    return null;
  }

  const validateFields = (value, allFields, meta) => {
    let error;
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return getErrorMessage(error);
      }
    }

    // Custom validation - incident happened 3yrs ago
    if (meta.name === INCIDENT_DATE_FIELD_NAME) {
      if (run3YearDateValidation(allFields[INCIDENT_DATE_FIELD_NAME], 'home')) {
        return serverErrorHome();
      }
      // If inputs changed and policy not valid error displayed, reset it
      if (notInForcePolicy) {
        isNotInForcePolicy(false);
      }
      // If inputs changed and no cover error displayed, reset it
      if (noCover) {
        isNotCovered(false);
      }
    }
    return undefined;
  };

  const incidentDateIsValid = (values, errors) => {
    return !!(!errors.damagedDate && values.damagedDate);
  };

  const yesNoToBoolean = value => {
    return value === 'yes';
  };

  const showElectricitySupplierInput = values => {
    return yesNoToBoolean(values[POWERCUT]);
  };

  const buildRequestPayload = values => {
    return {
      homeIncidentDetails: {
        perilCode: PERIL_CODE,
        incidentDate: dateISOConverter(values.damagedDate, true),
        policyholderId: values.policyHolderNumber,
        emailAddress: values.homeEmail,
        mobileTelephoneNumber: values.homeMobileTelephone,
        freezerFoodDetails: {
          powerCut: yesNoToBoolean(values[POWERCUT]),
          ...(showElectricitySupplierInput(values) && {
            electricitySupplier: values.electricitySupplier,
          }),
          occupantCount: values.numberOfPeople,
          shoppingInterval: values.regularShopping,
          freezerType: values.freezerType,
          freezerAge: values.freezerTimeline,
          amount: parseInt(values.amountClaimed, 10),
        },
      },
    };
  };

  const onCreateClaimFail = () => {
    updateDataState(freezerFoodDamageDataContext.freezerFoodDamageData, {});
    updateDataState(freezerFoodDamageDataContext.detailsCompleted, false);
    setServerError(true);
    setIsLoading(false);
  };

  const onCreateClaimSuccess = (data, values) => {
    const { claimReference = '' } = data?.data || {};
    const { declines = [] } = data?.data?.status || {};

    setIsLoading(false);
    updateDataState(
      freezerFoodDamageDataContext.freezerFoodDamageData,
      data?.data,
    );
    updateDataState(freezerFoodDamageDataContext.amount, values.amountClaimed);

    if (declines.includes('PolicyNotOnCover')) {
      isNotInForcePolicy(true);
    } else if (declines.includes('PolicyCoverNotValid')) {
      isNotCovered(true);
    } else if (!isEmpty(claimReference)) {
      updateDataState(freezerFoodDamageDataContext.detailsCompleted, true);
      setNavigatePath('/homeclaim/freezerfood/results');
    }
  };

  const createFreezerFoodClaim = values => {
    setIsLoading(true);
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .post(
        config.client.homeclaimnumber_endpoint,
        buildRequestPayload(values),
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(data => {
        onCreateClaimSuccess(data, values);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          onCreateClaimFail();
        }
      });
  };

  const beforeSubmit = () => {
    resetStateErrors();
  };

  const onSubmit = values => {
    createFreezerFoodClaim(values);
  };

  const showContinue = values => {
    return (
      !(values.policyHolderNumber === '000') &&
      (policyDetailsContext.status !== 'loading' ||
        policyDataValid(policyDetailsContext))
    );
  };

  const dateLabelDescription =
    'To enable us to validate your claim, please tell us the date you became aware of the problem with your freezer.';

  const renderFreezerQuestions = () => {
    return (
      <>
        <HouseHoldDetails name={NUMBER_OF_PEOPLE} validate={validateFields} />

        <PeriodicallyShopping
          name={REGULAR_SHOPPING}
          validate={validateFields}
        />

        <FreezerType name={FREEZER_TYPE} validate={validateFields} />
        <FreezerTimeline name={FREEZER_TIMELINE} validate={validateFields} />
        <CurrencyInput
          primary
          name={AMOUNT_CLAIMED}
          id={AMOUNT_CLAIMED}
          label="Please tell us the approximate value to replace the food that has perished"
          maxLength="3"
          size="3"
          aria-label="amount claimed"
          validate={validateFields}
        />
      </>
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      {isLoading ? <LoadingSpinner /> : ''}
      <HomeClaimsWrapper backToClaimsLink>
        <ClaimBreadcrumbStandard activeItem="claim" />
        <PageSectionBare>
          <Subsection>
            <TitleText>Freezer food claim</TitleText>
            <P>
              Please note, we will be recording details of this incident in our
              claims system.
            </P>
          </Subsection>

          <Subsection last>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, values, form, errors }) => (
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  onSubmitCapture={event => beforeSubmit(event, values)}
                >
                  <HomeStandardClaimDetails
                    name={[
                      INCIDENT_DATE_FIELD_NAME,
                      POLICYHOLDER_NAME,
                      MOBILE_TELEPHONE_NAME,
                      EMAIL_ADDRESS_NAME,
                    ]}
                    dateSecondaryLabel={dateLabelDescription}
                    validate={validateFields}
                    dateValue={values.damagedDate}
                    perilCode={PERIL_CODE}
                    dateChecker={incidentDateIsValid(values, errors)}
                    contextName={FreezerFoodContext}
                    policyHolderNameList={
                      freezerFoodDamageDataContext?.policyDetails
                    }
                  />
                  <YesOrNo
                    name={POWERCUT}
                    title="Is your claim a result of an electrical power cut?"
                    validate={validateFields}
                  />

                  {showElectricitySupplierInput(values) && (
                    <ElectricitySupplierDetails
                      name={ELECTRICITY_SUPPLIER}
                      validate={validateFields}
                      aria-label="supplier details"
                    />
                  )}
                  {values.powercut && renderFreezerQuestions()}

                  {(notInForcePolicy || noCover) && (
                    <ThemedErrorMessage hasIcon>
                      {validPolicyErrorHome()}
                    </ThemedErrorMessage>
                  )}

                  {serverError && (
                    <ThemedErrorMessage hasIcon>
                      {serverErrorHome()}
                    </ThemedErrorMessage>
                  )}

                  {showContinue(values) && (
                    <ButtonsBottomContainer>
                      <ThemedButton secondary type="submit">
                        Continue
                      </ThemedButton>
                    </ButtonsBottomContainer>
                  )}
                </form>
              )}
            </Form>
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default FreezerFoodClaim;
