import React, { useContext } from 'react';

import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { email } from 'ageasvalidation/lib/schemas/Email';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';

import GenericMotorFormPageWithPolicyFetch from './GenericMotorFormPageWithPolicyFetch';
import { useMotorClaimContextNew } from './GenericMotorFormPage';
import { FieldHeaderSpaced } from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';

import StandardInput from '../../components/Forms/StandardInput/StandardInput';

import { PolicyContext } from '../../helpers/policyContextHelper';
import mobileTelephoneValidationRegex from '../../helpers/mobileTelephoneValidationRegex';
import { getErrorMessage } from '../../helpers/errorMessages';
import validateField from '../../helpers/validationHelper';
import { updateLocalPageSectionIntoContextUnvalidated } from '../../helpers/updateContinuableMotorClaimData';

const FIELD_NAMES = {
  MOBILE_TELEPHONE: 'mobileTelephone',
  EMAIL_ADDRESS: 'emailAddress',
};

const schema = {
  [FIELD_NAMES.MOBILE_TELEPHONE]: stringRequired(
    [FIELD_NAMES.MOBILE_TELEPHONE],
    'Please enter a value',
  ).concat(
    regularExpression(
      mobileTelephoneValidationRegex,
      [FIELD_NAMES.MOBILE_TELEPHONE],
      'Please enter a valid mobile telephone number',
    ),
  ),
  [FIELD_NAMES.EMAIL_ADDRESS]: stringRequired(
    [FIELD_NAMES.EMAIL_ADDRESS],
    'Please enter a value',
  ).concat(
    email([FIELD_NAMES.EMAIL_ADDRESS], 'Please enter a valid email address'),
  ),
};

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);

    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const PAGE_SECTION_NAME = 'contactDetails';

const MotorContactDetails = () => {
  const { pageStateCombined, dataState, updateDataState } =
    useMotorClaimContextNew(PAGE_SECTION_NAME);
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyDetails = { ...policyDataContext.policyDetails };

  // Update page context with default field values from policyDetails
  // Returns false if policyDetails is not valid for use
  // Updates page context from policyDetails (if required) and returns true
  // if it is valid for use
  const setFieldDefaultsFromPolicyContext = (
    newPolicyDetails = policyDetails,
  ) => {
    if (!newPolicyDetails?.data?.contactDetails) {
      return false;
    }
    if (newPolicyDetails && !pageStateCombined) {
      const { mobileTelephone, email: emailAddress } =
        newPolicyDetails.data.contactDetails;
      const values = {};
      let updated = false;

      if (mobileTelephone) {
        updated = true;
        values[FIELD_NAMES.MOBILE_TELEPHONE] = mobileTelephone;
      }
      if (emailAddress) {
        updated = true;
        values[FIELD_NAMES.EMAIL_ADDRESS] = emailAddress;
      }

      if (updated) {
        updateLocalPageSectionIntoContextUnvalidated(
          dataState,
          PAGE_SECTION_NAME,
          values,
          updateDataState,
        );
      }
    }
    return true;
  };

  return (
    <GenericMotorFormPageWithPolicyFetch
      pageSectionName={PAGE_SECTION_NAME}
      setFieldDefaultsFromPolicyContext={setFieldDefaultsFromPolicyContext}
      breadcrumbId="contactDetails"
      pageTitle="Contact Details"
      nextPage="/motorclaim/newclaim/yourdetails"
      previousPage="/motorclaim/newclaim/incidentdetails"
    >
      <FieldHeaderSpaced>
        Please confirm the contact details you want to use throughout the claim.
      </FieldHeaderSpaced>

      <StandardInput
        name={FIELD_NAMES.MOBILE_TELEPHONE}
        label="Mobile telephone number"
        small
        maxLength="16"
        validate={validateFields}
      />

      <StandardInput
        name={FIELD_NAMES.EMAIL_ADDRESS}
        label="Email address"
        small
        maxLength="100"
        validate={validateFields}
      />
    </GenericMotorFormPageWithPolicyFetch>
  );
};

export default MotorContactDetails;
