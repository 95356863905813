/* eslint-disable react/prop-types */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloud,
  faCloudShowersHeavy,
  faHouseDamage,
  faDoorOpen,
} from '@fortawesome/free-solid-svg-icons';
import NestedAccordion from '../NestedAccordion';
import AccordionSection from '../AccordionSection';
import nestedAccordionBuilder, {
  nestedAccordionSectionBuilder,
} from '../nestedAccordionBuilder';
import { nestingControlPropTypes } from '../accordionNestingProp';
import ButtonLink from '../../ThemedButtonLink/ThemedButtonLink';
import IconImage from '../../IconImage/IconImage';
import errorIcon from '../../../assets/images/success-icon.svg';

export const stormText1 = [
  'Some text Storm 1',
  'Some text Storm 2',
  'Some text Storm 3',
  'Some text Storm 4',
  'Some text Storm 5',
  'Some text Storm 6',
  'Some text Storm 7',
];
export const roofText1 = [
  'Some text Roof 1',
  'Some text Roof 2',
  'Some text Roof 3',
  'Some text Roof 4',
  'Some text Roof 5',
  'Some text Roof 6',
  'Some text Roof 7',
];
export const interiorText1 = [
  'Some text Interior 1',
  'Some text Interior 2',
  'Some text Interior 3',
  'Some text Interior 4',
  'Some text Interior 5',
  'Some text Interior 6',
  'Some text Interior 7',
];
export const policyText1 = [
  'Some text Policy 1',
  'Some text Policy 2',
  'Some text Policy 3',
];

const Storm2Component = ({ nestingControl }) => {
  return (
    <>
      <p>{stormText1[1]}</p>
      <p>Some more text</p>
      this is{' '}
      <ButtonLink
        primary
        onClick={() =>
          nestingControl?.setForcePath(['What if policy?', 'What if premium?'])
        }
      >
        nav test to policy/premium
      </ButtonLink>{' '}
      link
    </>
  );
};
Storm2Component.propTypes = {
  nestingControl: nestingControlPropTypes,
};
Storm2Component.defaultProps = {
  nestingControl: undefined,
};

export const stormQuestions = [
  {
    title: 'What if storm 1?',
    content: (
      <>
        <p>{stormText1[0]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if storm 2?',
    content: <Storm2Component />,
    icon: undefined,
  },
  {
    title: 'What if storm 3?',
    content: (
      <>
        <p>{stormText1[2]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if storm 4?',
    content: (
      <>
        <p>{stormText1[3]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if storm 5?',
    content: (
      <>
        <p>{stormText1[4]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if storm 6?',
    content: (
      <>
        <p>{stormText1[5]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if storm 7?',
    content: (
      <>
        <p>{stormText1[6]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
];
export const roofQuestions = [
  {
    title: 'What if roof 1?',
    content: (
      <>
        <p>{roofText1[0]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if roof 2?',
    content: (
      <>
        <p>{roofText1[1]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if roof 3?',
    content: (
      <>
        <p>{roofText1[2]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if roof 4?',
    content: (
      <>
        <p>{roofText1[3]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if roof 5?',
    content: (
      <>
        <p>{roofText1[4]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if roof 6?',
    content: (
      <>
        <p>{roofText1[5]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if roof 7?',
    content: (
      <>
        <p>{roofText1[6]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
];
export const interiorQuestions = [
  {
    title: 'What if interior 1?',
    content: (
      <>
        <p>{interiorText1[0]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if interior 2?',
    content: (
      <>
        <p>{interiorText1[1]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if interior 3?',
    content: (
      <>
        <p>{interiorText1[2]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if interior 4?',
    content: (
      <>
        <p>{interiorText1[3]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if interior 5?',
    content: (
      <>
        <p>{interiorText1[4]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if interior 6?',
    content: (
      <>
        <p>{interiorText1[5]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if interior 7?',
    content: (
      <>
        <p>{interiorText1[6]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
];
export const policyQuestions = [
  {
    title: 'What if coverage?',
    content: `${policyText1[0]}, text node`,
    icon: undefined,
  },
  {
    title: 'What if premium?',
    content: [
      <p key="line1">{policyText1[1]}</p>,
      <p key="line2">Some more text</p>,
    ],
    icon: undefined,
  },
  {
    title: 'What if renewal?',
    content: (
      <>
        <p>{policyText1[2]}</p>
        <p>Some more text</p>
      </>
    ),
    icon: undefined,
  },
  {
    title: 'What if undefined?',
    content: undefined,
    icon: undefined,
  },
  {
    title: 'What if null?',
    content: null,
    icon: undefined,
  },
];

const errorIconImg = <IconImage src={errorIcon} />;

export const claimQuestions = [
  {
    title: 'What if storm?',
    content: nestedAccordionSectionBuilder(stormQuestions, true),
    icon: <FontAwesomeIcon icon={faCloud} />,
    activeIcon: <FontAwesomeIcon icon={faCloudShowersHeavy} />,
  },
  {
    title: 'What if roof?',
    content: nestedAccordionSectionBuilder(roofQuestions, true),
    icon: <FontAwesomeIcon icon={faHouseDamage} />,
    activeIcon: errorIconImg,
  },
  {
    title: 'What if interior?',
    content: nestedAccordionSectionBuilder(interiorQuestions, true),
    icon: <FontAwesomeIcon icon={faDoorOpen} />,
  },
];

// <h2 key="subheader1">{typeQuestionHeader}</h2>
export const typeQuestionHeader = 'My question is about';
export const typeQuestions = [
  {
    title: 'What if claim?',
    content: nestedAccordionBuilder(claimQuestions),
    contentHeader: 'My question is about',
    icon: undefined,
  },
  {
    title: 'What if policy?',
    content: nestedAccordionBuilder(policyQuestions, true),
    icon: undefined,
  },
];

export const TypeAccordion = ({ questionSet = typeQuestions, ...props }) => {
  return (
    <NestedAccordion {...props}>
      {nestedAccordionBuilder(questionSet)}
    </NestedAccordion>
  );
};

export const EdgeCasesAccordion = () => {
  return (
    <div data-testid="testAccordionTestCases">
      <div data-testid="testAccordionTextNodeChild">
        <NestedAccordion>accordion text node child</NestedAccordion>
      </div>
      <div data-testid="testAccordionArrayChild">
        <NestedAccordion>
          {['accordion text node array 1', 'accordion text node array 2']}
        </NestedAccordion>
      </div>
      <div data-testid="testAccordionArrayChildElements">
        <NestedAccordion>
          {[
            <p key="1">accordion element array 1</p>,
            <p key="2">accordion element array 2</p>,
          ]}
        </NestedAccordion>
      </div>
      <div data-testid="testAccordionNoChild">
        <NestedAccordion />
      </div>

      <div data-testid="testAccordionSectionTextNodeChild">
        <NestedAccordion>
          <AccordionSection title="section text node child title">
            accordion section text node child
          </AccordionSection>
        </NestedAccordion>
      </div>
      <div data-testid="testAccordionSectionArrayChild">
        <NestedAccordion>
          <AccordionSection title="section array child title">
            {[
              'accordion section text node array 1',
              'accordion section text node array 2',
            ]}
          </AccordionSection>
        </NestedAccordion>
      </div>
      <div data-testid="testAccordionSectionArrayChildElements">
        <NestedAccordion>
          <AccordionSection title="section array child elements title">
            {[
              <p key="1">accordion section element array 1</p>,
              <p key="2">accordion section element array 2</p>,
            ]}
          </AccordionSection>
        </NestedAccordion>
      </div>
      <div data-testid="testAccordionSectionNoChild">
        <NestedAccordion>
          <AccordionSection title="section no child title" />
        </NestedAccordion>
      </div>
    </div>
  );
};

export default TypeAccordion;
