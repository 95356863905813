import PropTypes from 'prop-types';
import React from 'react';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import { incidentDetailsFields } from '../motorIncidentDetailsHelper';

const IncidentDamageRoadSurfacePotholeFields = ({ validateFields }) => {
  return (
    <InvolvedParty
      name={incidentDetailsFields.POTHOLE_COUNCIL_DETAILS}
      header="Please confirm the name, address and contact details of the local council"
      company
      companyLabel="Council name"
      validate={validateFields}
    />
  );
};

export default IncidentDamageRoadSurfacePotholeFields;

IncidentDamageRoadSurfacePotholeFields.propTypes = {
  validateFields: PropTypes.func,
};

IncidentDamageRoadSurfacePotholeFields.defaultProps = {
  validateFields: undefined,
};
