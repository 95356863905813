import config from './config';

const { weekendStart, weekendEnd, weekdayStart, weekdayEnd } =
  config?.client?.testBusinessHoursOverride || {};

// Start/End times are in 24h format with a period separator (<hour>.<minute>)
// E.g. 5:30pm would be "17.30"
export const businessHoursMotor = {
  Sunday: {
    start: weekendStart || '09.00',
    stop: weekendEnd || '17.00',
  },
  Monday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '21.00',
  },
  Tuesday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '21.00',
  },
  Wednesday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '21.00',
  },
  Thursday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '21.00',
  },
  Friday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '21.00',
  },
  Saturday: {
    start: weekendStart || '09.00',
    stop: weekendEnd || '17.00',
  },
};
export const businessHoursMotorTPA = {
  Sunday: {
    start: weekendStart || '11.00',
    stop: weekendEnd || '15.00',
  },
  Monday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '20.00',
  },
  Tuesday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '20.00',
  },
  Wednesday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '20.00',
  },
  Thursday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '20.00',
  },
  Friday: {
    start: weekdayStart || '08.00',
    stop: weekdayEnd || '20.00',
  },
  Saturday: {
    start: weekendStart || '09.00',
    stop: weekendEnd || '17.00',
  },
};
export const businessHours = {
  Sunday: {
    start: weekendStart || '00.00',
    stop: weekendEnd || '24.00',
  },
  Monday: {
    start: weekdayStart || '00.00',
    stop: weekdayEnd || '24.00',
  },
  Tuesday: {
    start: weekdayStart || '00.00',
    stop: weekdayEnd || '24.00',
  },
  Wednesday: {
    start: weekdayStart || '00.00',
    stop: weekdayEnd || '24.00',
  },
  Thursday: {
    start: weekdayStart || '00.00',
    stop: weekdayEnd || '24.00',
  },
  Friday: {
    start: weekdayStart || '00.00',
    stop: weekdayEnd || '24.00',
  },
  Saturday: {
    start: weekendStart || '00.00',
    stop: weekendEnd || '24.00',
  },
};

export const getBusinessHours = (lob, day) => {
  if (lob === 'motor') {
    return businessHoursMotor[day];
  }
  if (lob === 'motorTPA') {
    return businessHoursMotorTPA[day];
  }
  return businessHours[day];
};

export const businessHoursDescriptive = '8am-8pm Mon-Fri and 9am-5pm Sat';

export const businessHoursMotorDescriptive =
  '8am-9pm Mon-Fri and 9am-5pm Sat & Sun';

export const businessHoursMotorTPADescriptive =
  'Monday-Friday 8am-7pm and Saturday 9am-5pm';

export const businessHoursMidweekDescriptive = '9am-5pm Mon-Fri';

export const getBusinessHoursDescriptive = lob => {
  if (lob === 'motor') {
    return businessHoursMotorDescriptive;
  }
  if (lob === 'motorTPA') {
    return businessHoursMotorTPADescriptive;
  }
  if (lob === 'motorClaims') {
    return businessHoursMidweekDescriptive;
  }
  return businessHoursDescriptive;
};

export const motorReferLink =
  'https://www.ageas.co.uk/make-a-claim/car-insurance/';

export const homeReferLink =
  'https://www.ageas.co.uk/make-a-claim/home-insurance/';
