import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemedTitle, ThemedErrorMessage } from 'ageas-ui-components';
import axios from 'axios';
import PropTypes from 'prop-types';
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import {
  PageSectionBare,
  SubTextCenter,
  ErrorTextContainer,
} from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import { Status, HistoryTitleHolder } from './ClaimsHistory.style';
import { reverseDate } from '../../helpers/dateTimeConverter';
import { dataLoadFailingError } from '../../helpers/errorMessages';
import axiosHelper from '../../helpers/axios';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import { consoleError } from '../../helpers/consoleLog';
import {
  DataLineContainer,
  DataLineItem,
} from '../../components/ActionList/ActionList.style';
import ActionListEntry from '../../components/ActionList/ActionListEntry';

const buildHistoryEntry = (claim, onClick, buttonText) => {
  const onEntryButtonClick = !claim.isClickable
    ? undefined
    : () => {
        onClick(claim);
      };

  return (
    <ActionListEntry
      key={claim.claimNumber}
      onClick={onEntryButtonClick}
      buttonText={buttonText}
      icon={claim.icon}
      iconAlt={claim.iconAlt}
    >
      <DataLineContainer wrap="wrap">
        <DataLineItem primary shrink="1">
          Claim Reference
        </DataLineItem>
        <DataLineItem primary narrowMode="notNarrow">
          {claim.claimNumber}
        </DataLineItem>
        <DataLineItem>
          {' '}
          - <Status open={claim.isOpen}>{claim.claimStatusDesc}</Status>
        </DataLineItem>
      </DataLineContainer>

      <DataLineContainer narrowMode="narrow" primary>
        <DataLineItem>{claim.claimNumber}</DataLineItem>
      </DataLineContainer>

      <DataLineContainer wrap="wrap">
        {claim.claimType?.trim() && (
          <DataLineItem shrink="1">{claim.claimType}, </DataLineItem>
        )}
        {claim.vrn?.trim() && (
          <DataLineItem shrink="1">{claim.vrn}, </DataLineItem>
        )}
        <DataLineItem shrink="1">
          Incident Date {reverseDate(claim.lossDate)}
        </DataLineItem>
      </DataLineContainer>
    </ActionListEntry>
  );
};

const ClaimsHistory = ({
  lob,
  buttonLink,
  buttonText,
  Context,
  dataContext,
  entryMapper,
  dataMapper,
  axiosUrl,
  titleText,
  subText,
  showTableText,
  headerClaimButton = false,
  redirectPathOnNoClaims,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [dataLoadFail, setDataLoadFail] = useState(false);
  const axiosCancelToken = useRef(null);
  const { updateDataState } = useContext(Context);
  const [navigatePath, setNavigatePath] = useState(null);

  const getClaimsHistory = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .get(axiosUrl, {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(({ data }) => {
        setIsLoading(false);
        const mappedData = dataMapper(data);
        if (mappedData.length < 1 && redirectPathOnNoClaims) {
          setNavigatePath(redirectPathOnNoClaims);
        }
        setTableData(mappedData);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          consoleError('axios error', error);
          setIsLoading(false);
          setDataLoadFail(true);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClaimsHistory();
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const onClick = claimData => {
    updateDataState(
      dataContext.claimDetails,
      {
        claimReference: claimData.claimNumber,
        ...claimData.originalData,
      },
      true, // Remove existing claim from context if present
    );
    setNavigatePath(buttonLink);
  };

  const renderSubText = () => {
    return (
      <SubTextCenter>
        {typeof subText === 'function' ? subText(tableData) : subText}
      </SubTextCenter>
    );
  };

  return (
    <>
      {navigatePath && <NavigateTo path={navigatePath} />}
      <StandardPageWrapper lob={lob} headerClaimButton={headerClaimButton}>
        {isLoading ? <LoadingSpinner /> : ''}
        <PageSectionBare>
          {titleText && (
            <HistoryTitleHolder>
              <ThemedTitle primary large center>
                {titleText}
              </ThemedTitle>
            </HistoryTitleHolder>
          )}
          {renderSubText()}
          <StandardPanel
            title="My Claims"
            primary
            shadowBorder
            panelPadding="0 0 0 0"
          >
            <SubTextCenter>
              {showTableText && tableData && (
                <>
                  {tableData.length
                    ? 'Please click on the claim you would like to view.'
                    : 'You have no existing claims.'}
                </>
              )}
            </SubTextCenter>
            {tableData &&
              tableData.map(claim => {
                return buildHistoryEntry(
                  entryMapper(lob, claim),
                  onClick,
                  buttonText,
                );
              })}
            {dataLoadFail && (
              <ErrorTextContainer>
                <ThemedErrorMessage hasIcon>
                  {dataLoadFailingError(lob)}
                </ThemedErrorMessage>
              </ErrorTextContainer>
            )}
          </StandardPanel>
        </PageSectionBare>
      </StandardPageWrapper>
    </>
  );
};

export default ClaimsHistory;

ClaimsHistory.propTypes = {
  lob: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  Context: PropTypes.shape({}).isRequired,
  dataContext: PropTypes.shape({
    claimDetails: PropTypes.string.isRequired,
  }).isRequired,
  entryMapper: PropTypes.func.isRequired,
  dataMapper: PropTypes.func.isRequired,
  axiosUrl: PropTypes.string.isRequired,
  titleText: PropTypes.string,
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  showTableText: PropTypes.bool.isRequired,
  redirectPathOnNoClaims: PropTypes.string,
  headerClaimButton: PropTypes.bool,
};

ClaimsHistory.defaultProps = {
  titleText: undefined,
  redirectPathOnNoClaims: undefined,
  headerClaimButton: undefined,
};
