import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// For documentation see:
// /docs/components/Navigation/NavigateTo/NavigateTo.md

const NavigateTo = ({ history, path, data }) => {
  // Console error if history.push is done inside render method, so has
  // to be in a useEffect instead
  useEffect(() => {
    if (path) {
      // state is undefined even when the data is not passed as param.
      // Other than Thankyou page the state will be undefined.
      history.push(path, data);
    }
  }, [path]);

  return null;
};

NavigateTo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  path: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};
NavigateTo.defaultProps = {
  path: undefined,
  data: undefined,
};

export default withRouter(NavigateTo);
