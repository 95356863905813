import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { lessThanEqual, mileageCalculation } from '../extensions/ValidMileage';

const JoiExtended = Joi.extend(lessThanEqual);
const JoiExt = Joi.extend(mileageCalculation);

/**
 * @description: Validate the field is a valid mileage
 * @param keys Fields to validate
 * @param message the message to display
 * @succeeds when the mileage field is a valid mileage pattern
 * @fails when mileage field is not a valid mileage pattern
 */
export const mileage = (
  keys,
  message = messages.CAR_MILEAGE,
  constraintMessage = messages.CAR_BUSINESS_MILEAGE_LESSTHANOREQUALTO,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExt.number()
      .required()
      .mileageCalculation()
      .error(err => {
        // based on error type will return the error message
        if (err && err[0] && err[0].type === 'number.base') {
          return message;
        }
        return constraintMessage;
      }),
  );
};

/**
 * @description: Validate the field is a valid business mileage
 * @param keys Fields to validate
 * @param mileageRef reference to mileage field to do extra constraint check
 * @param message the message to display if business mileage is empty
 *                or not a number
 * @param constraintMessage the message to display if business mileage is
 *                          less than or equal to mileage reffered in
 *                          mileageRef
 * @succeeds when the business mileage field is a valid mileage pattern
 * @fails when business mileage field is not a valid mileage pattern
 */
export const businessMileage = (
  keys,
  mileageRef = '',
  message = messages.CAR_BUSINESS_MILEAGE,
  constraintMessage = messages.CAR_BUSINESS_MILEAGE_LESSTHANOREQUALTO,
) => {
  if (mileageRef) {
    return Joi.object().pattern(
      Joi.valid(keys),
      JoiExtended.number()
        .required()
        .lessThanEqual()
        .error(err => {
          // based on error type will return the error message
          if (err && err[0] && err[0].type === 'number.base') {
            return message;
          }
          return constraintMessage;
        }),
    );
  }
  // If does not have mileage ref then simple validation should be done
  return mileage(keys, message);
};

export default mileage;
