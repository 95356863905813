import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardHint from '../../../components/StandardHint/StandardHint';

const Container = styled.fieldset`
  border: 0px;
`;
const Legend = styled.legend`
  margin-bottom: 16px;
`;
const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const UNKNOWN = '-unknown-';

const mapValue = (value, i) => <span key={i}>{value}</span>;

const mapValues = values => (
  <ValuesContainer>
    {!values.length ? mapValue(UNKNOWN) : values?.map(mapValue)}
  </ValuesContainer>
);

const buildValueDisplay = value => {
  if (!value && value !== null) {
    return UNKNOWN;
  }
  return Array.isArray(value) ? mapValues(value) : value;
};

/**
 * Generic "confirm/update field" component.
 * Consists of a fieldset with legend = "title: value"
 * (e.g. "Occupation: Cook")
 * Containing a Yes/No radio button with label = "Is this correct?"
 * If "No" is selected, the children (a field to input the new value) is
 * rendered.
 */
const ConfirmUpdate = ({
  title,
  value,
  valueInLegend = true,
  secondaryTitle,
  yesNoName,
  validate,
  children,
  disabled,
  hasButtonsTitle = true,
  renderChildrenOn = ['no'],
  allowUnknown,
  hint,
}) => {
  const { values } = useFormState();

  const displayValue = buildValueDisplay(value);

  return (
    <Container>
      <Legend>
        {title}
        {secondaryTitle && (
          <>
            <br />
            <small>{secondaryTitle}</small>
          </>
        )}
        {valueInLegend && displayValue}
        {hint && <StandardHint field={yesNoName} hint={hint} />}
      </Legend>
      {!valueInLegend && displayValue}

      <YesOrNo
        name={yesNoName}
        title={hasButtonsTitle && 'Is this correct?'}
        validate={validate}
        disabled={disabled}
        blockProps={hasButtonsTitle ? {} : { marginTop: '0px' }}
        allowUnknown={allowUnknown}
      />
      {renderChildrenOn?.includes(values[yesNoName]) && children}
    </Container>
  );
};

export default ConfirmUpdate;

ConfirmUpdate.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  valueInLegend: PropTypes.bool,
  yesNoName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hasButtonsTitle: PropTypes.bool,
  renderChildrenOn: PropTypes.arrayOf(PropTypes.string),
  allowUnknown: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hint: PropTypes.node,
};

ConfirmUpdate.defaultProps = {
  title: undefined,
  secondaryTitle: undefined,
  value: undefined,
  valueInLegend: undefined,
  validate: undefined,
  children: undefined,
  disabled: undefined,
  hasButtonsTitle: undefined,
  renderChildrenOn: undefined,
  allowUnknown: undefined,
  hint: undefined,
};
