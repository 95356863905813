import React from 'react';
import FaqP from '../../FaqP';

export const contentsClaimEventTitle =
  'What will I need to provide in the event of a contents claim?';
export const contentsClaimEventContent = (
  <>
    <FaqP>
      In the event that you need to make a claim for contents, there are a
      couple of things that the claims department might request in order to
      proceed with your claim.
    </FaqP>
    <FaqP>
      These can be things such as pictures of the damaged item(s), quotes to
      repair or replace the item(s) and the reason why they need replacement, as
      well as receipts, valuations and/or proof of purchase may be required in
      some cases or replacement costs from websites for any irreparable items.
    </FaqP>
    <FaqP>
      If a supplier is appointed to deal with your damaged contents, you might
      not be required to provide the above as they will carry out those checks
      for you.
    </FaqP>
  </>
);
