import React from 'react';
import FaqP from '../../FaqP';

export const affectClaimTitle =
  'I have already started repairs, how will this affect the claim?';
export const affectClaimContent = (
  <>
    <FaqP>
      We may decide to inspect your damaged item(s) before we are able to accept
      your claim. Therefore, we would ask that you contact us before you make
      any repairs or replacements.
    </FaqP>
    <FaqP>
      Please do not dispose of the damaged item(s) before making a claim, as not
      having the item(s) available for inspection may affect the outcome of the
      claim.
    </FaqP>
  </>
);
