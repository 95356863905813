import React, { useRef, useState, useEffect, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Form } from 'react-final-form';
import { getTheme } from 'ageas-ui-themes';
import {
  ThemedErrorMessage,
  ThemedButton,
  ThemedHint,
} from 'ageas-ui-components';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import { dateISOConverter } from '../../helpers/dateTimeConverter';
import { brand } from '../../../data/whitelabel.config';
import HomeStandardClaimDetails, {
  getStandardClaimDetailsValidationSchema,
} from '../HomeStandardClaimDetails/HomeStandardClaimDetails';
import { buildStateFromCookies } from '../../contexts/AuthContext';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import {
  getIncidentDateValidationSchema,
  run3YearDateValidation,
} from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import { validateField } from '../../helpers/validationHelper';
import {
  serverErrorHome,
  getErrorMessage,
  validPolicyErrorHome,
  IncorrectHomeClaimCover,
  UnableToProceedWithClaimHome,
} from '../../helpers/errorMessages';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  TitleText,
  PageSectionBare,
  Subsection,
  ButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../../components/StandardTags/P';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import { policyDataValid } from '../../helpers/policyDataContextHelper';
import {
  EscapeOfWaterContext,
  escapeOfWaterDataContext,
} from '../../helpers/escapeOfWaterContextHelper';
import {
  EOW_CAUSE_LIST,
  EOW_DAMAGED_AREAS_LIST,
  EOW_DISCOVERED_BY_LIST,
  EOW_HELP_TEXT,
  EOW_LEAKING_FOR_LIST,
  EOW_OWNERSHIP_LIST,
  EOW_SECTION_CODE_LIST,
  EOW_WATER_TYPE_LIST,
} from './escapeOfWaterHelper';
import stringArrayRequired from '../../helpers/validation/stringArrayRequired';
import StandardRadio from '../../components/Forms/StandardRadio/StandardRadio';
import StandardDropdown from '../../components/Forms/StandardDropdown/StandardDropdown';
import StandardTextarea from '../../components/Forms/StandardTextarea/StandardTextarea';
import YesOrNo, {
  yesOrNoSchema,
} from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardCheckboxGroup from '../../components/Forms/StandardCheckboxGroup/StandardCheckboxGroup';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';

const Theme = getTheme(brand);

const INCIDENT_DATE_FIELD_NAME = 'damagedDate';
const POLICYHOLDER_NAME = 'policyHolderNumber';
const MOBILE_TELEPHONE_NAME = 'homeMobileTelephone';
const EMAIL_ADDRESS_NAME = 'homeEmail';
const SECTION_CODE = 'sectionCode';
const CAUSE_CODE = 'causeCode';
const CAUSE_DESCRIPTION = 'causeDescription';
const LEAK_LOCATION = 'leakLocation';
const DISCOVERED_BY = 'discoveredBy';
const DISCOVERED_BY_DESCRIPTION = 'discoveredByDescription';
const STILL_LEAKING = 'stillLeaking';
const TRADESMAN_DETAILS = 'tradesmanDetails';
const LEAKING_FOR = 'leakingFor';
const THIRD_PARTY_FAULT = 'thirdPartyFault';
const THIRD_PARTY_FAULT_DETAILS = 'thirdPartyFaultDetails';
const WATER_TYPE = 'waterType';
const DAMAGED_AREAS = 'damagedAreas';
const DAMAGED_AREAS_DESCRIPTION = 'damagedAreasDescription';
const ANYONE_LIVING_AT_PROPERTY = 'anyoneLivingAtProperty';
const OWNERSHIP = 'ownership';

const getSelectARadioOptionSchema = fieldName => {
  return {
    [fieldName]: stringRequired([fieldName], 'Please select an option'),
  };
};

const getSelectACheckboxOptionSchema = fieldName => {
  return {
    [fieldName]: stringArrayRequired([fieldName], 'Please select an option'),
  };
};

const getEnterDetailsSchema = fieldName => {
  return {
    [fieldName]: stringRequired([fieldName], 'Please enter details').concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(/\S/, [fieldName], 'Please enter details'),
    ),
  };
};

const schema = {
  ...getIncidentDateValidationSchema(INCIDENT_DATE_FIELD_NAME),
  ...getStandardClaimDetailsValidationSchema(
    POLICYHOLDER_NAME,
    MOBILE_TELEPHONE_NAME,
    EMAIL_ADDRESS_NAME,
  ),
  ...getSelectARadioOptionSchema(SECTION_CODE),
  ...getSelectARadioOptionSchema(CAUSE_CODE),
  ...getEnterDetailsSchema(CAUSE_DESCRIPTION),
  ...getEnterDetailsSchema(LEAK_LOCATION),
  ...getSelectARadioOptionSchema(DISCOVERED_BY),
  ...getEnterDetailsSchema(DISCOVERED_BY_DESCRIPTION),
  ...yesOrNoSchema(STILL_LEAKING),
  ...getSelectARadioOptionSchema(LEAKING_FOR),
  ...yesOrNoSchema(THIRD_PARTY_FAULT),
  ...getEnterDetailsSchema(THIRD_PARTY_FAULT_DETAILS),
  ...getSelectARadioOptionSchema(WATER_TYPE),
  ...getSelectACheckboxOptionSchema(DAMAGED_AREAS),
  ...getEnterDetailsSchema(DAMAGED_AREAS_DESCRIPTION),
  ...yesOrNoSchema(ANYONE_LIVING_AT_PROPERTY),
  ...getSelectARadioOptionSchema(OWNERSHIP),
};

const ThemedHintWrapper = styled.span`
  margin-left: 0.5em;
`;
const ThemedHintContainer = styled.div`
  display: flex;
`;

const renderTitleWithHelpText = (title, field, hint) => {
  return (
    <ThemedHintContainer>
      <span>{title}</span>
      <ThemedHintWrapper>
        <ThemedHint primary small hover noclick field={field}>
          {hint}
        </ThemedHint>
      </ThemedHintWrapper>
    </ThemedHintContainer>
  );
};

const EscapeOfWaterClaim = () => {
  const [navigatePath, setNavigatePath] = useState(null);
  const { dataState: escapeOfWaterContext, updateDataState } =
    useContext(EscapeOfWaterContext);
  const policyDetailsContext = {
    ...escapeOfWaterContext?.policyDetails,
  };

  const [serverError, setServerError] = useState(false);
  const [notInForcePolicy, isNotInForcePolicy] = useState(false);
  const [noCover, isNotCovered] = useState(false);
  const [unableToProceed, isUnableToProceed] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderedPage, setRenderedPage] = useState(false);

  const PERIL_CODE = 'EW';

  const resetStateErrors = () => {
    setServerError(false);
    isNotInForcePolicy(false);
    isNotCovered(false);
    isUnableToProceed(false);
  };

  const yesNoToBoolean = value => {
    return value === 'yes';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      !isEmpty(escapeOfWaterContext) &&
      escapeOfWaterContext?.detailsCompleted
    ) {
      setNavigatePath('/homeclaim/escapeofwater/results');
    } else {
      setRenderedPage(true);
    }
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (
      policyDetailsContext?.status === 'nokey' &&
      !buildStateFromCookies().isLoggedIn
    ) {
      setNavigatePath(
        `/homeclaim/brokerlogin?target=${encodeURIComponent(
          window.location.pathname,
        )}`,
      );
    }
  }, [policyDetailsContext?.status]);

  if (navigatePath) {
    return <NavigateTo path={navigatePath} />;
  }

  if (!renderedPage) {
    return null;
  }

  const validateFields = (value, allFields, meta) => {
    let error;
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return getErrorMessage(error);
      }
    }

    // Custom validation - incident happened 3yrs ago
    if (meta.name === INCIDENT_DATE_FIELD_NAME) {
      if (run3YearDateValidation(allFields[INCIDENT_DATE_FIELD_NAME], 'home')) {
        return serverErrorHome();
      }
      // If inputs changed and policy not valid error displayed, reset it
      if (notInForcePolicy) {
        isNotInForcePolicy(false);
      }
      // If inputs changed and unable to proceed error displayed, reset it
      if (unableToProceed) {
        isUnableToProceed(false);
      }
      if (serverError) {
        setServerError(false);
      }
    }
    return undefined;
  };

  const incidentDateIsValid = (values, errors) => {
    return !!(!errors.damagedDate && values.damagedDate);
  };

  const sectionCodeToArray = value => {
    return ['buildings', 'contents'].filter(
      item => value === 'both' || value === item,
    );
  };

  const showProvideDetailsInput = value => {
    return ['other', 'yes'].includes(value) || value?.includes('other');
  };

  const buildRequestPayload = values => {
    return {
      homeIncidentDetails: {
        perilCode: PERIL_CODE,
        incidentDate: dateISOConverter(values.damagedDate, true),
        policyholderId: values.policyHolderNumber,
        emailAddress: values.homeEmail,
        mobileTelephoneNumber: values.homeMobileTelephone,
        escapeOfWaterDetails: {
          sectionCodes: sectionCodeToArray(values[SECTION_CODE]),
          cause: values[CAUSE_CODE],
          ...(showProvideDetailsInput(values[CAUSE_CODE]) && {
            causeDescription: values[CAUSE_DESCRIPTION],
          }),
          location: values[LEAK_LOCATION],
          discoveredBy: values[DISCOVERED_BY],
          ...(showProvideDetailsInput(values[DISCOVERED_BY]) && {
            discoveredByDescription: values[DISCOVERED_BY_DESCRIPTION],
          }),
          stillLeaking: yesNoToBoolean(values[STILL_LEAKING]),
          ...(values[STILL_LEAKING] === 'no' &&
            !!values[TRADESMAN_DETAILS] && {
              tradesmanDetails: values[TRADESMAN_DETAILS],
            }),
          leakingFor: values[LEAKING_FOR],
          thirdPartyFault: yesNoToBoolean(values[THIRD_PARTY_FAULT]),
          ...(yesNoToBoolean(values[THIRD_PARTY_FAULT]) && {
            thirdPartyDetails: values[THIRD_PARTY_FAULT_DETAILS],
          }),
          waterType: values[WATER_TYPE],
          damagedAreas: values[DAMAGED_AREAS],
          ...(showProvideDetailsInput(values[DAMAGED_AREAS]) && {
            damagedAreasDescription: values[DAMAGED_AREAS_DESCRIPTION],
          }),
          anyoneLivingAtProperty: yesNoToBoolean(
            values[ANYONE_LIVING_AT_PROPERTY],
          ),
          ownership: values[OWNERSHIP],
        },
      },
    };
  };

  const onCreateClaimFail = () => {
    updateDataState(escapeOfWaterDataContext.escapeOfWaterData, {});
    updateDataState(escapeOfWaterDataContext.detailsCompleted, false);
    setServerError(true);
    setIsLoading(false);
  };

  const onCreateClaimSuccess = (data, values) => {
    const { claimReference = '' } = data?.data || {};
    const { declines = [] } = data?.data?.status || {};

    setIsLoading(false);
    updateDataState(escapeOfWaterDataContext.escapeOfWaterData, data?.data);
    updateDataState(
      escapeOfWaterDataContext.userContactNumber,
      values.homeMobileTelephone,
    );

    if (declines.includes('PolicyNotOnCover')) {
      isNotInForcePolicy(true);
    } else if (declines.includes('PolicyCoverNotPreviouslyValid')) {
      isUnableToProceed(true);
    } else if (declines.includes('PolicyCoverNotValid')) {
      isNotCovered(true);
    } else if (!isEmpty(claimReference)) {
      updateDataState(escapeOfWaterDataContext.detailsCompleted, true);
      setNavigatePath(perilRoutes[PERIL_CODE].results);
    }
  };

  const createEscapeOfWaterClaim = values => {
    setIsLoading(true);
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .post(
        config.client.homeclaimnumber_endpoint,
        buildRequestPayload(values),
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(data => {
        onCreateClaimSuccess(data, values);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          onCreateClaimFail();
        }
      });
  };

  const beforeSubmit = () => {
    resetStateErrors();
  };

  const onSubmit = values => {
    createEscapeOfWaterClaim(values);
  };

  const showContinue = values => {
    return (
      !(values.policyHolderNumber === '000' || noCover || unableToProceed) &&
      (policyDetailsContext.status !== 'loading' ||
        policyDataValid(policyDetailsContext))
    );
  };

  const dateLabelDescription =
    'To enable us to validate your claim, please tell us the date on which the incident occurred.';

  return (
    <ThemeProvider theme={Theme}>
      {isLoading ? <LoadingSpinner /> : ''}
      <HomeClaimsWrapper backToClaimsLink>
        <ClaimBreadcrumbStandard activeItem="claim" />
        <PageSectionBare>
          <Subsection>
            <TitleText>Escape of water claim</TitleText>
            <P>
              Please note, we will be recording details of this incident in our
              claims system.
            </P>
          </Subsection>

          <Subsection last>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, values, form, errors }) => (
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  onSubmitCapture={event => beforeSubmit(event, values)}
                >
                  <HomeStandardClaimDetails
                    name={[
                      INCIDENT_DATE_FIELD_NAME,
                      POLICYHOLDER_NAME,
                      MOBILE_TELEPHONE_NAME,
                      EMAIL_ADDRESS_NAME,
                    ]}
                    dateSecondaryLabel={dateLabelDescription}
                    validate={validateFields}
                    dateValue={values.damagedDate}
                    perilCode={PERIL_CODE}
                    dateChecker={incidentDateIsValid(values, errors)}
                    contextName={EscapeOfWaterContext}
                    policyHolderNameList={
                      escapeOfWaterDataContext?.policyDetails
                    }
                  />
                  <StandardRadio
                    name={SECTION_CODE}
                    title={renderTitleWithHelpText(
                      'Is your claim for damage to:',
                      SECTION_CODE,
                      EOW_HELP_TEXT[SECTION_CODE],
                    )}
                    options={EOW_SECTION_CODE_LIST}
                    medium
                    vertical
                    validate={validateFields}
                  />
                  <StandardDropdown
                    name={CAUSE_CODE}
                    label="Please select the cause of the leak"
                    aria-label="Please select the cause of the leak"
                    options={EOW_CAUSE_LIST}
                    small
                    validate={validateFields}
                  />
                  {showProvideDetailsInput(values[CAUSE_CODE]) && (
                    <StandardTextarea
                      name={CAUSE_DESCRIPTION}
                      label="Please provide more information"
                      maxLength={1000}
                      validate={validateFields}
                      blockProps={{ marginTop: '0' }}
                    />
                  )}
                  <StandardTextarea
                    name={LEAK_LOCATION}
                    label="Please confirm the location of the leak?"
                    maxLength={1000}
                    validate={validateFields}
                    blockProps={{ marginTop: '0' }}
                  />
                  <StandardDropdown
                    name={DISCOVERED_BY}
                    label="Who discovered the leak?"
                    aria-label="Who discovered the leak?"
                    options={EOW_DISCOVERED_BY_LIST}
                    small
                    validate={validateFields}
                  />
                  {showProvideDetailsInput(values[DISCOVERED_BY]) && (
                    <StandardTextarea
                      name={DISCOVERED_BY_DESCRIPTION}
                      label="Please provide more information"
                      maxLength={200}
                      validate={validateFields}
                      blockProps={{ marginTop: '0' }}
                    />
                  )}
                  <YesOrNo
                    name={STILL_LEAKING}
                    title={renderTitleWithHelpText(
                      'Is the water still leaking?',
                      STILL_LEAKING,
                      EOW_HELP_TEXT[STILL_LEAKING],
                    )}
                    validate={validateFields}
                  />
                  {values[STILL_LEAKING] === 'no' && (
                    <StandardTextarea
                      name={TRADESMAN_DETAILS}
                      label="If the leak was repaired by a tradesman, please provide details below"
                      maxLength={1000}
                      blockProps={{ marginTop: '0' }}
                    />
                  )}
                  <StandardDropdown
                    name={LEAKING_FOR}
                    label="How long has the leak been going on for?"
                    aria-label="How long has the leak been going on for?"
                    secondaryLabel="If you're unsure, please select your best guess."
                    options={EOW_LEAKING_FOR_LIST}
                    small
                    validate={validateFields}
                  />
                  <YesOrNo
                    name={THIRD_PARTY_FAULT}
                    title={renderTitleWithHelpText(
                      'Could a third party be at fault for the leak?',
                      THIRD_PARTY_FAULT,
                      EOW_HELP_TEXT[THIRD_PARTY_FAULT],
                    )}
                    validate={validateFields}
                  />
                  {showProvideDetailsInput(values[THIRD_PARTY_FAULT]) && (
                    <StandardTextarea
                      name={THIRD_PARTY_FAULT_DETAILS}
                      label="Please provide more information e.g. person/company name, contact information, insurance details (if known) and a description of why you believe them to be at fault"
                      maxLength={1000}
                      validate={validateFields}
                      blockProps={{ marginTop: '0' }}
                    />
                  )}
                  <StandardRadio
                    name={WATER_TYPE}
                    title="Is the water clean or dirty?"
                    options={EOW_WATER_TYPE_LIST}
                    medium
                    vertical
                    validate={validateFields}
                  />
                  <StandardCheckboxGroup
                    name={DAMAGED_AREAS}
                    title="Please select the area(s) of your home that have been damaged"
                    options={EOW_DAMAGED_AREAS_LIST}
                    medium
                    vertical
                    validate={validateFields}
                    blockProps={{ marginTop: '0' }}
                  />
                  {showProvideDetailsInput(values[DAMAGED_AREAS]) && (
                    <StandardTextarea
                      name={DAMAGED_AREAS_DESCRIPTION}
                      label="Please provide more information"
                      maxLength={500}
                      validate={validateFields}
                      blockProps={{ marginTop: '0' }}
                    />
                  )}
                  <YesOrNo
                    name={ANYONE_LIVING_AT_PROPERTY}
                    title="Do you or does anyone currently live at the property?"
                    validate={validateFields}
                  />
                  <StandardRadio
                    name={OWNERSHIP}
                    title={renderTitleWithHelpText(
                      'Do you own the property or rent?',
                      OWNERSHIP,
                      EOW_HELP_TEXT[OWNERSHIP],
                    )}
                    options={EOW_OWNERSHIP_LIST}
                    medium
                    vertical
                    validate={validateFields}
                  />

                  {unableToProceed && (
                    <ThemedErrorMessage hasIcon>
                      {UnableToProceedWithClaimHome()}
                    </ThemedErrorMessage>
                  )}

                  {notInForcePolicy && (
                    <ThemedErrorMessage hasIcon>
                      {validPolicyErrorHome()}
                    </ThemedErrorMessage>
                  )}

                  {noCover && (
                    <>
                      {IncorrectHomeClaimCover(
                        escapeOfWaterContext?.escapeOfWaterData?.claimReference,
                      )}
                    </>
                  )}

                  {serverError && (
                    <ThemedErrorMessage hasIcon>
                      {serverErrorHome()}
                    </ThemedErrorMessage>
                  )}

                  {showContinue(values) && (
                    <ButtonsBottomContainer>
                      <ThemedButton secondary type="submit">
                        Continue
                      </ThemedButton>
                    </ButtonsBottomContainer>
                  )}
                </form>
              )}
            </Form>
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default EscapeOfWaterClaim;
