import Joi from '@hapi/joi';

const stringArrayBasicValidator = (fieldName, message) => {
  // return undefined;
  return Joi.object().pattern(
    Joi.valid(fieldName),
    Joi.array()
      .min(1)
      .items(Joi.string().required())
      .error(function buildErrorMessage() {
        return message || `${fieldName} array is invalid`;
      }),
  );
};

export default stringArrayBasicValidator;
