import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { brand } from '../../../data/whitelabel.config';

import GenericMotorFormPageWithPolicyFetch from './GenericMotorFormPageWithPolicyFetch';
import {
  VehicleDamageModalContext,
  schema,
} from './motorVehicleDamageDetailsHelper';

import { PolicyContext } from '../../helpers/policyContextHelper';
import { getErrorMessage } from '../../helpers/errorMessages';
import validateField from '../../helpers/validationHelper';
import StandardFormModalContext from '../../contexts/StandardFormModalContext/StandardFormModalContext';
import VehicleDamageDetails from './MotorVehicleDamageFieldSets/VehicleDamageDetails';
import VehicleDamageModalForm from '../../components/Forms/VehicleDamageSelector/VehicleDamageModalForm';
import DisplayVehicleInfo from './MotorVehicleDamageFieldSets/DisplayVehicleInfo';
import { FieldHeaderSpaced } from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';

const Theme = getTheme(brand);

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const ContextWrappers = ({ children }) => {
  return (
    <StandardFormModalContext Context={VehicleDamageModalContext}>
      {children}
    </StandardFormModalContext>
  );
};
ContextWrappers.propTypes = {
  children: PropTypes.node,
};
ContextWrappers.defaultProps = {
  children: undefined,
};

const Modals = () => {
  const {
    modalState: vehicleDamageModalState,
    closeModal: vehicleDamageModalClose,
  } = useContext(VehicleDamageModalContext);

  return (
    <ThemeProvider theme={Theme}>
      {vehicleDamageModalState && (
        <VehicleDamageModalForm
          onCancel={vehicleDamageModalClose}
          {...vehicleDamageModalState}
        />
      )}
    </ThemeProvider>
  );
};

const PAGE_SECTION_NAME = 'vehicleDamageDetails';

const MotorVehicleDamageDetails = () => {
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyDetails = { ...policyDataContext.policyDetails };

  // Check if policyDetails context contains required data
  const setFieldDefaultsFromPolicyContext = (
    newPolicyDetails = policyDetails,
  ) => {
    return !!newPolicyDetails?.data?.vehicles?.length;
  };

  return (
    <ContextWrappers>
      <Modals />
      <GenericMotorFormPageWithPolicyFetch
        pageSectionName={PAGE_SECTION_NAME}
        setFieldDefaultsFromPolicyContext={setFieldDefaultsFromPolicyContext}
        breadcrumbId="vehicleDamage"
        pageTitle="Vehicle Damage Details"
        nextPage="/motorclaim/newclaim/claimoutcome"
        previousPage="/motorclaim/newclaim/yourdetails"
        confirmModalOnSubmit
        submit
      >
        <DisplayVehicleInfo />
        <FieldHeaderSpaced>
          So that we can process your claim as quickly and easily as possible,
          please answer the following questions.
        </FieldHeaderSpaced>
        <VehicleDamageDetails validateFields={validateFields} />
      </GenericMotorFormPageWithPolicyFetch>
    </ContextWrappers>
  );
};

export default MotorVehicleDamageDetails;
