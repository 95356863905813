import { buildingDamageTitle, buildingDamageContent } from './BuildingDamage';
import {
  buildingExcessPaymentTitle,
  buildingExcessPaymentContent,
} from './BuildingExcessPayment';
import {
  inhabitableDamageContent,
  inhabitableDamageTitle,
} from './InhabitableDamage';
import {
  temporaryRepairsTitle,
  temporaryRepairsContent,
} from './TemporaryRepairs';
import { startedRepairsTitle, startedRepairsContent } from './StartedRepairs';
import {
  claimRequirementTitle,
  claimRequirementContent,
} from './ClaimRequirement';
import {
  buildingInspectionTitle,
  buildingInspectionContent,
} from './BuildingInspection';
import {
  buildingInsuranceCoverTitle,
  buildingInsuranceCoverContent,
} from './BuildingInsuranceCoverage';
import { buildingClaimTitle, buildingClaimContent } from './BuildingClaim';

const buildingDamageQuestions = [
  {
    title: buildingDamageTitle,
    content: buildingDamageContent,
  },
  {
    title: buildingInsuranceCoverTitle,
    content: buildingInsuranceCoverContent,
  },
  {
    title: buildingClaimTitle,
    content: buildingClaimContent,
  },
  {
    title: buildingInspectionTitle,
    content: buildingInspectionContent,
  },
  {
    title: buildingExcessPaymentTitle,
    content: buildingExcessPaymentContent,
  },
  {
    title: claimRequirementTitle,
    content: claimRequirementContent,
  },
  {
    title: inhabitableDamageTitle,
    content: inhabitableDamageContent,
  },
  {
    title: temporaryRepairsTitle,
    content: temporaryRepairsContent,
  },
  {
    title: startedRepairsTitle,
    content: startedRepairsContent,
  },
];
export default buildingDamageQuestions;
