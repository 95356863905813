import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const dotSize = 30 / 14; // em, approx 0.86 (so is 12px for a font size of 14px)
const halfDotSize = dotSize / 2;
const dottedLineSize = 5; // px;

/*
  breadcrumb container.
  Supplies the gray background with curved border
*/
export const Container = styled.nav`
  font-size: 32px;
  font-family: Nunito, sans-serif;
  padding-top: 0.5em;
  padding-left: calc(85px + ${({ styling }) => styling.paddingSideOffset});
  padding-right: calc(85px + ${({ styling }) => styling.paddingSideOffset});
  margin-bottom: 0.5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/*
List item container
  Applies flex to children li's to ensure even sizing and spacing of li elements
*/

export const List = styled.ul`
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0 auto;
  ${({ styling }) =>
    styling.maxInnerWidth &&
    css`
      max-width: ${styling.maxInnerWidth};
      flex-grow: 1;
      flex-shrink: 1;
    `}
`;

/*
List item 
  Applies flex to children:
  * content (link containing dot and label text), fixed width
  * ::before (dotted line), stretch to fit container
*/
export const ListItem = styled.li`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  color: #b3b3b3;
  /* No dotted line before #1, so no flex grow */
  &:first-child {
    flex-grow: 0;
  }
  /* All but first, use ::before to create a dotted line that grows to fit flex container */
  &:not(:first-child)::before {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    border-bottom: ${dottedLineSize}px dotted #b3b3b3;
    content: '';
    margin: 12px 4px 0px 4px;
  }
  /* Active list item colour */
  ${({ active }) =>
    active &&
    css`
      color: #8e419c;
    `}

  /* active list item dotted line colour */
  ${({ activeLine }) =>
    activeLine &&
    css`
      &:not(:first-child)::before {
        border-color: #8e419c;
      }
    `}
`;

/*
  List item content container. is Anchor/Link (active) or span (inactive)
  Flex to ensure children display side by side
*/
const listItemContainer = css`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-start;
  color: inherit;
  text-decoration: none;
  outline: none;
`;

export const ListItemAnchor = styled.a`
  ${listItemContainer}
`;
export const ListItemLink = styled(Link)`
  ${listItemContainer}
`;
export const ListItemSpan = styled.span`
  ${listItemContainer}
`;

/*
  List item dot
*/
export const Dot = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${dotSize}em;
  height: ${dotSize}em;
  border-radius: ${halfDotSize}em;
  border: 4px solid #b3b3b3;
  background-color: white;
  content: '';
  padding: 0px;
  /* Active list item dot colour */
  ${({ active }) =>
    active &&
    css`
      background-color: white;
      border: 4px solid #8e419c;
    `}
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    font-size: 22px;
    width: ${halfDotSize}em;
    height: ${halfDotSize}em;
    border: 3px solid #b3b3b3;
    padding: 14px;
    background-color: #b3b3b3;
    color: white;
    /* Active list item dot colour */
    ${({ active }) =>
      active &&
      css`
        font-size: revert;
        width: ${dotSize}em;
        height: ${dotSize}em;
        border-radius: ${halfDotSize}em;
        color: #8e419c;
        background-color: white;
        border: 4px solid #8e419c;
      `}
  }

  /* Focussed list item dot colour */
  ${ListItemAnchor}:focus-visible &,
  ${ListItemLink}:focus-visible &,
  ${ListItemAnchor}:hover &,
  ${ListItemLink}:hover & {
    background-color: #8d409c;
    color: white;
  }
`;

/*
  Label text container
*/
export const Label = styled.div``;

/*
  Wide mode label
  Do not show on narow screen
*/
export const LabelWide = styled(Label)`
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    display: none;
  }
`;
/*
  Narrow mode label
  Only show on narow screen
*/
export const LabelNarrow = styled(Label)`
  display: none;
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    display: block;
  }
`;
