import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const makeEscapeOfWaterClaimQuestion =
  'What should I expect when making a claim for escape or leakage of water?';
const MakeEscapeOfWaterClaimContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        Based on the information you provide, we will check your policy to
        ensure you are covered and let you know how we can help.
      </FaqP>

      <FaqP>
        Please be aware that your policy does not cover general repairs to the
        pipework, water or heating system of your home. You will need to check
        your policy documentation to see if you have purchased the Home
        Emergency optional cover. If you do have this cover, your policy
        documentation will also provide details on how to contact the relevant
        team. If you do not have this extended cover, please contact a plumber
        directly to repair and stop the leak.
      </FaqP>

      <FaqP>
        Once the leak has been stopped, we will get to work on fixing the damage
        caused.
      </FaqP>

      <FaqP>
        We may be able to offer you a cash settlement for the water damage
        repairs and raise a cash payment for you to use in getting the repairs
        completed.
      </FaqP>

      <FaqP>
        If there is more work to be done, we may instruct one of our approved
        contractors to contact you directly and make arrangements for the
        repairs.
      </FaqP>

      <FaqP>
        If the damage is substantial, we will send out an expert to manage the
        claim and talk you through the best course of action.
      </FaqP>

      <FaqP>
        Some of your items may need replacing or restoring. Depending on your
        cover, we can let you know if we can help with those too.
      </FaqP>

      <FaqP>
        Depending on the time of year and the extent of the damage, we do advise
        that you turn on the heating and open the windows to dry out the
        property as much as possible. In some cases, where the damage isn&apos;t
        extensive, this may be enough to resolve the issues.
      </FaqP>

      <FaqP>
        For information on when your claim would not be covered, please refer to
        the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Leaking or Escape of water in the home',
              'When would my claim not be covered?',
            ])
          }
        >
          &apos;When would my claim not be covered?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};
export default MakeEscapeOfWaterClaimContent;

MakeEscapeOfWaterClaimContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
MakeEscapeOfWaterClaimContent.defaultProps = {
  nestingControl: undefined,
};
