import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ThemedCheckbox, ThemedErrorMessage } from 'ageas-ui-components';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';
import media from '../../MediaQuery/MediaQuery';

export const optionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    singleOption: PropTypes.bool,
  }),
);

// For documentation see:
// /docs/components/Forms/StandardCheckboxGroup/StandardCheckboxGroup.md

const CheckboxGroupWrapper = styled.fieldset`
  border: 0px;
`;

const CheckboxGroupContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 15px;
`;

const CheckboxContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;

  ${({ columnWidth }) =>
    columnWidth &&
    css`
      flex-basis: ${columnWidth};
    `}

  ${({ oneColumnTablet }) =>
    oneColumnTablet &&
    media.tablet`
      flex-basis: 100%;
    `}
`;

const isSingle = (value, singleOptions) => singleOptions.includes(value);

const getNonSingles = (values, singleOptions) =>
  values.filter(value => !isSingle(value, singleOptions));
const hasSingles = (values, singleOptions) =>
  values.some(value => isSingle(value, singleOptions));
const hasNonSingles = (values, singleOptions) =>
  values.some(value => !isSingle(value, singleOptions));
const getLastSingle = (values, singleOptions) =>
  values.find(value => isSingle(value, singleOptions));

const getSingleOptions = options =>
  options.filter(({ singleOption }) => singleOption).map(({ value }) => value);

/**
 * If enabled, prevents invalid combinations of options being set, e.g.
 * * Multiple singleOptions
 * * singleOption and non-SingleOption
 * Works by updating field to untick invalid options
 *
 * If enabled, does not render children (checkboxes) until the cleanup has run
 * once (maybe formState is already invalid). Tried doing this in parallel,
 * but onMount cleanup did not work properly (formState correct but checkboxes
 * not unchecked). Possible race condition, caused by cleanup updates and
 * checkbox rendering occurring at the same time. So now checkboxes don't
 * render until after the cleanup updates are done.
 *
 * If not enabled, simply renders children
 *
 * @param {*} props
 * @returns children
 */
const SingleOptionsEnforcer = ({
  values,
  onChange,
  options,
  enabled,
  children,
}) => {
  // Auto initialise if enforcer disabled, or <= 1 value
  const [initialised, setInitialised] = useState(
    !enabled || values.length <= 1,
  );
  const previousRef = useRef([]);
  const propRefs = useRef();
  propRefs.current = {
    onChange,
    singleOptions: useMemo(() => getSingleOptions(options), [options]),
  };

  // Fires whenever radio group value changes
  useEffect(() => {
    // Nothing to do if enforce not enabled
    if (!enabled) {
      return;
    }
    setInitialised(true);
    const { singleOptions } = propRefs.current;

    // Nothing to do if there are no single options
    if (!singleOptions.length) {
      return;
    }

    const previous = previousRef.current;
    previousRef.current = values;

    // Nothing to change if does not contain multiple entries, and at least one
    // single
    // (If this breaks, causes state update infinite recursion)
    if (values.length <= 1 || !hasSingles(values, singleOptions)) {
      return;
    }

    // Contains at least one single and at least one non-single

    // Get the values that have been added, if any
    // Includes all values at mount
    const added = values.filter(value => !previous.includes(value));

    // Was a non-single value added? If yes, keep only non-single values
    // Handles user adding non-single, and onMount auto cleanup (remove invalid
    // combo - singles and non-singles, prefer keeping non-singles)
    if (hasNonSingles(added, singleOptions)) {
      const nonSingles = getNonSingles(values, singleOptions);
      propRefs.current.onChange([...nonSingles]);
      return;
    }

    // Contains multiple values including a single, keep only the last
    // added single
    // Handles onMount auto cleanup (remove invalid combo - multiple singles,
    // keep last single)
    const lastSingle = getLastSingle(added, singleOptions);
    if (lastSingle) {
      propRefs.current.onChange([lastSingle]);
      // return;
    }
  }, [values, enabled]);

  if (!initialised) {
    return null;
  }

  return <>{children}</>;
};

SingleOptionsEnforcer.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  options: optionsPropType,
  enabled: PropTypes.bool,
  children: PropTypes.node,
};

SingleOptionsEnforcer.defaultProps = {
  values: [],
  options: [],
  enabled: false,
  children: undefined,
};

const StandardCheckboxGroup = ({
  name,
  title,
  secondaryTitle,
  validate,
  options,
  children,
  fieldProps,
  blockProps,
  boldLabel,
  enforceSingleOptions,
  oneColumnTablet,
  columnWidth,
  ...props
}) => {
  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input: inputOuter, meta }) => (
        <SingleOptionsEnforcer
          values={inputOuter.value || []}
          onChange={inputOuter.onChange}
          options={options}
          enabled={enforceSingleOptions}
        >
          <CheckboxGroupWrapper>
            {title && (
              <legend>
                {title}
                {secondaryTitle && (
                  <div>
                    <small>{secondaryTitle}</small>
                  </div>
                )}
              </legend>
            )}
            <FieldInnerBlockContainer {...blockProps}>
              <CheckboxGroupContainer>
                {options.map(({ value, label }) => (
                  <CheckboxContainer
                    key={value}
                    oneColumnTablet={oneColumnTablet}
                    columnWidth={columnWidth}
                  >
                    <FieldStyled
                      name={name}
                      value={value}
                      type="checkbox"
                      marginBottom="0"
                      {...props}
                    >
                      {({ input }) => (
                        <ThemedCheckbox
                          id={value}
                          fieldName={value}
                          text={label}
                          boldLabel={boldLabel}
                          {...input}
                        />
                      )}
                    </FieldStyled>
                  </CheckboxContainer>
                ))}
              </CheckboxGroupContainer>
            </FieldInnerBlockContainer>
          </CheckboxGroupWrapper>
          {meta.error && meta.touched && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </SingleOptionsEnforcer>
      )}
    </FieldStyled>
  );
};

StandardCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  validate: PropTypes.func,
  options: optionsPropType,
  children: PropTypes.node,
  fieldProps: PropTypes.shape({}),
  blockProps: PropTypes.shape({}),
  small: PropTypes.bool,
  boldLabel: PropTypes.bool,
  enforceSingleOptions: PropTypes.bool,
  oneColumnTablet: PropTypes.bool,
  columnWidth: PropTypes.string,
};

StandardCheckboxGroup.defaultProps = {
  title: '',
  secondaryTitle: undefined,
  validate: () => {},
  options: [],
  children: undefined,
  fieldProps: {},
  blockProps: {},
  small: true,
  boldLabel: false,
  enforceSingleOptions: false,
  oneColumnTablet: false,
  columnWidth: '12em',
};

export default StandardCheckboxGroup;
