import React from 'react';
import TelephoneLink from '../components/TelephoneLink/TelephoneLink';

export const phoneNumbers = {
  motorClaims: '0345 165 5796',
  motorClaimsAbroad: '+44 1242 715 929',
  fleetClaims: '0345 125 8833',
  legalCover: '0344 701 1119',
  homeClaims: '0345 122 3019',
  existingClaims: '0345 125 2450',
  keyProtectionClaims: '0345 165 0571',
  ageasGlassClaims: '0345 165 0581',
  homeClaimsFNOL: '0345 165 5795',
  homeClaimsLandingFNOL: '0345 165 5753',
  financialOmbudsman: '0800 023 4567',
  financialOmbudsman2: '0300 123 9123',
  legalExpenses: '0345 166 6359',
  homeEmergency: '0345 166 6358',
  homeComplaintFNOL3: '0345 165 5795',
  supplierCCG: '0330 124 2254',
  supplierVirtus: '0330 024 8948',
  tpAssist: '01452 626649',
  axaAssistance: '0345 168 5126',
  tluTeamHuntGroup: '0345 168 5000',
  customerServices: '0345 165 0570',
  motorClaimsTeam: '0345 122 3018',
};

export const MotorClaimsTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.motorClaims} {...props} />
);
export const MotorClaimsAbroadTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.motorClaimsAbroad} {...props} />
);
export const FleetClaimsTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.fleetClaims} {...props} />
);
export const LegalCoverTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.legalCover} {...props} />
);
export const HomeClaimsTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.homeClaims} {...props} />
);
export const ExistingClaimsTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.existingClaims} {...props} />
);
export const KeyProtectionTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.keyProtectionClaims} {...props} />
);
export const AgeasGlassTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.ageasGlassClaims} {...props} />
);
export const HomeClaimsFNOL = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.homeClaimsFNOL} {...props} />
);
export const HomeClaimsLandingFNOL = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.homeClaimsLandingFNOL} {...props} />
);
export const FinancialOmbudsmanTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.financialOmbudsman} {...props} />
);
export const FinancialOmbudsmanTelLink2 = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.financialOmbudsman2} {...props} />
);
export const HomeComplaintFNOL3 = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.homeComplaintFNOL3} {...props} />
);
export const SupplierCCG = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.supplierCCG} {...props} />
);
export const SupplierVirtus = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.supplierVirtus} {...props} />
);
export const TPAssistTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.tpAssist} {...props} />
);
export const AxaAssistanceTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.axaAssistance} {...props} />
);
export const MotorClaimsTeamTelLink = ({ ...props }) => (
  <TelephoneLink number={phoneNumbers.motorClaimsTeam} {...props} />
);
