import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import weatherFunction from '../../../helpers/weatherResultDataHelper';
import HomeClaimsWrapper from '../../../views/HomeClaimsWrapper/HomeClaimsWrapper';
import WeatherResultSet from '../WeatherResultSet';
import { brand } from '../../../../data/whitelabel.config';

const Theme = getTheme(brand);

const defaultTestData = {
  createClaimStatus: 'created',
  weatherNetDecision: {
    conditionMet: true,
    result: {
      wind: {
        conditionMet: false,
        worstRecordedDate: '2021-06-22',
        worstRecorded: 24.0,
      },
      rain: {
        conditionMet: true,
        worstRecordedDate: '2021-06-18',
        worstRecorded: 64.2,
      },
      hail: {
        conditionMet: true,
        worstRecordedDate: '2021-06-24',
      },
      snow: {
        conditionMet: false,
        worstRecordedDate: '2021-06-24',
      },
    },
  },
  relevantPolicyData: {
    riskPostcode: 'SO53 3YA',
    weatherLimits: {
      wind: 55.0,
      rain: 25.0,
    },
  },
};

const WeatherResultSetRenderer = ({
  data = weatherFunction(defaultTestData),
}) => {
  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <WeatherResultSet data={data} />
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default WeatherResultSetRenderer;

WeatherResultSetRenderer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

WeatherResultSetRenderer.defaultProps = {
  data: undefined,
};
