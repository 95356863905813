export const maritalStatus = {
  civilPartnered: 'V',
  married: 'M',
  single: 'S',
};

export const relationship = {
  civilPartner: 'CivPrtnr',
  offspring: 'OFFSPRNG',
  parent: 'PARENT',
  spouse: 'SPOUSEREL',
  none: 'BLANK',
  sibling: 'SIBLING',
  commonLawPartner: 'COMSPOUSE',
};

export const occupationFields = {
  employmentStatus: 'employmentStatus',
  occupation: 'occupation',
  employer: 'employer',
};

export const employStatus = {
  Club: 'Club',
  Director: 'Director',
  Employee: 'Employee',
  Student: 'FullTmEdu',
  Government: 'Gvnment',
  HousePerson: 'HsePerson',
  LimitedCompany: 'LtdComp',
  Other: 'OtherEuro',
  Partner: 'Prop/Prtn',
  Retired: 'Retired',
  SelfEmployed: 'SelfEmploy',
  Unemployed: 'UnEmploy',
};
// Used for A 'Student' status cannot have occupation Blah error message
export const employStatusErrorField = {
  Student: "'Student'",
  Unemployed: "'Unemployed'",
  Employed: "'Employed'",
  Retired: "'Retired'",
  HousePerson: "'HousePerson'",
};

export const employmentStatuses = {
  employed: [
    'Employee',
    'SelfEmploy',
    'Prop/Prtn',
    'Director',
    'Gvnment',
    'Club',
  ],
  unemployed: ['UnEmploy', 'OtherEuro'],
  student: ['FullTmEdu'],
  housePerson: ['HsePerson'],
  retired: ['Retired'],
};

export const employmentCodes = {
  unemployed: ['U03', '220', '43D'],
  housePerson: ['427', '163', 'H09', 'A97'],
  retired: ['R09'],
  student: [
    'S34',
    'S44',
    '%01',
    'S45',
    '331',
    'S46',
    'S47',
    '631',
    'S48',
    'S49',
    '558',
    'S50',
    'S51',
    'N04',
    'S52',
    'S53',
    '18D',
    '19D',
    '48D',
    '49D',
    '74C',
  ],
};

export const employerCodes = {
  unemployed: ['747', '186', '949'],
  housePerson: ['747', '186', '948'],
  retired: ['747', '186', '947'],
  student: ['747', '186', '950'],
};

export const genders = {
  male: ['Mr', 'Lord', 'Sir'],
  female: ['Mrs', 'Miss', 'Ms', 'Lady'],
  maleSpecifier: 'Male',
  femaleSpecifier: 'Female',
};
