import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StandardDropdown from '../Forms/StandardDropdown/StandardDropdown';
import StandardInput from '../Forms/StandardInput/StandardInput';
import YesOrNo from '../Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardCurrencyDecimalInput from '../Forms/StandardCurrencyDecimalInput/StandardCurrencyDecimalInput';
import jewelleryItemTypes from '../../helpers/jewelleryItemTypes';
import {
  jewelleryValidatorsPropType,
  jewelleryFieldNamePropType,
} from './JewelleryItemPropTypes';
import { HomeClaimsFNOL } from '../../helpers/ageasTelephoneNumbers';
import { businessHoursDescriptive } from '../../helpers/businessHoursData';
import { buildSpecifiedItemDescription } from '../../helpers/buildJewelleryItemName';
import P from '../StandardTags/P';

const SubLabel = styled.div`
  margin-top: 0.5em;
`;

export const DEFAULT_FIELD_NAMES = {
  specifiedItemName: 'specifiedItemName',
  itemName: 'itemName',
  itemDescription: 'itemDescription',
  itemPurchased: 'itemPurchased',
  itemReceipt: 'itemReceipt',
  itemCost: 'itemCost',
};

export const hasSpecifiedItems = (specifiedItems = []) => {
  return !!specifiedItems.length;
};
export const anySpecifiedItems = (allSpecifiedItems = []) => {
  return !!allSpecifiedItems.length;
};

export const notSpecifiedItem = (values, specifiedItems = []) => {
  return (
    !hasSpecifiedItems(specifiedItems) || values.specifiedItemName === 'other'
  );
};

export const showItemCost = (values, specifiedItems = []) => {
  if (
    values.itemReceipt === 'yes' &&
    notSpecifiedItem(values, specifiedItems)
  ) {
    return true;
  }
  return undefined;
};

export const showDescription = (values, specifiedItems = []) => {
  if (notSpecifiedItem(values, specifiedItems)) {
    return true;
  }
  return undefined;
};

const OTHER_ITEM = {
  value: 'other',
  label: 'Item not specified',
};

// Sanitise form values object to remove values for undisplayed fields
export const sanitiseValues = (
  values,
  specifiedItems = [],
  fieldNames = {},
) => {
  const fieldNamesFull = { ...DEFAULT_FIELD_NAMES, ...fieldNames };
  const sanitised = { ...values };

  if (
    sanitised[fieldNamesFull.itemDescription] &&
    !showDescription(values, specifiedItems)
  ) {
    delete sanitised[fieldNamesFull.itemDescription];
  }
  if (
    sanitised[fieldNamesFull.itemCost] &&
    !showItemCost(values, specifiedItems)
  ) {
    delete sanitised[fieldNamesFull.itemCost];
  }

  // Always set a specified item type - default to other if unspecified
  if (!sanitised[fieldNamesFull.specifiedItemName]) {
    sanitised[fieldNamesFull.specifiedItemName] = OTHER_ITEM.value;
  }

  return sanitised;
};

const JewelleryItemSubForm = ({
  fieldNames,
  validate,
  validators,
  values,
  specifiedItems = [],
  allSpecifiedItems = [],
  beforeAnyBlur,
}) => {
  const fieldNamesFull = { ...DEFAULT_FIELD_NAMES, ...fieldNames };

  const SUB_LABEL = (
    <SubLabel>
      If you can&apos;t see the specified item you want to claim for in the
      list, please call the claims team on{' '}
      <strong>
        <HomeClaimsFNOL />
      </strong>{' '}
      between the hours of {businessHoursDescriptive}.
    </SubLabel>
  );

  const formatSpecifiedItems = useCallback(() => {
    const options = specifiedItems.map(item => ({
      value: item.itemKey,
      label: buildSpecifiedItemDescription(item),
    }));
    options.push(OTHER_ITEM);
    return options;
  }, [specifiedItems]);

  return (
    <>
      {hasSpecifiedItems(specifiedItems) && (
        <StandardDropdown
          name={fieldNamesFull.specifiedItemName}
          label="Please confirm the item you're claiming for"
          secondaryLabel={SUB_LABEL}
          validate={validators?.specifiedItemName || validate}
          options={formatSpecifiedItems()}
          blockContainerProps={{ small: true, minWidth: '228px' }}
          alwaysShowPlaceholder
          aria-label="specified jewellery item type"
          beforeBlur={beforeAnyBlur}
        />
      )}
      {!anySpecifiedItems(allSpecifiedItems) && (
        <P>
          If you have a specified item on your policy that you would like to
          claim for, please call the claims team on{' '}
          <strong>
            <HomeClaimsFNOL />
          </strong>{' '}
          between the hours of {businessHoursDescriptive}.
        </P>
      )}

      <StandardDropdown
        name={fieldNamesFull.itemName}
        label="Please select an item of jewellery you are claiming for"
        validate={validators?.itemName || validate}
        options={jewelleryItemTypes}
        blockContainerProps={{ small: true, minWidth: '228px' }}
        alwaysShowPlaceholder
        aria-label="jewellery item type"
        beforeBlur={beforeAnyBlur}
      />

      {showDescription(values, specifiedItems) && (
        <StandardInput
          name={fieldNamesFull.itemDescription}
          label="Please provide a description of the item of jewellery"
          small
          maxLength="78"
          validate={validators?.itemDescription || validate}
          aria-label="jewellery item description"
        />
      )}

      <StandardInput
        name={fieldNamesFull.itemPurchased}
        label="Please tell us where the item of jewellery was purchased"
        secondaryLabel='If you can&apos;t remember, please enter "unsure".'
        small
        maxLength="78"
        validate={validators?.itemPurchased || validate}
        aria-label="jewellery purchased from"
        beforeBlur={beforeAnyBlur}
      />

      <YesOrNo
        name={fieldNamesFull.itemReceipt}
        title="Do you have a receipt or valuation for the item of jewellery?"
        validate={validators?.itemReceipt || validate}
        aria-label="jewellery receipt"
      />

      {showItemCost(values, specifiedItems) && (
        <StandardCurrencyDecimalInput
          name={fieldNamesFull.itemCost}
          primary
          maxLength="7"
          title="Please tell us the approximate value of the jewellery"
          direction="left"
          adornText="£"
          isRequired
          aria-label="jewellery cost"
          validate={validators?.itemCost || validate}
          beforeBlur={beforeAnyBlur}
        />
      )}
    </>
  );
};

JewelleryItemSubForm.propTypes = {
  validate: PropTypes.func,
  validators: jewelleryValidatorsPropType,
  fieldNames: jewelleryFieldNamePropType,
  values: PropTypes.shape({
    itemReceipt: PropTypes.string,
    specifiedItemName: PropTypes.string,
  }),
  specifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  allSpecifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  beforeAnyBlur: PropTypes.func,
};

JewelleryItemSubForm.defaultProps = {
  fieldNames: {},
  validators: {},
  validate: () => {},
  values: {},
  specifiedItems: undefined,
  allSpecifiedItems: undefined,
  beforeAnyBlur: undefined,
};

export default JewelleryItemSubForm;
