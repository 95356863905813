import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import EclaimsRoute from '../EclaimsRoute';
import { HomeClaimsHistoryFreezerFood } from '../../views/HomeClaimsHistory/HomeClaimsHistory';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  FreezerFoodContext,
  localStorageFreezerFoodPropertyName,
} from '../../helpers/freezerFoodContextHelper';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import FreezerFoodClaim from '../../views/FreezerFoodClaim/FreezerFoodClaim';
import FreezerFoodClaimResult from '../../views/FreezerFoodClaimResult/FreezerFoodClaimResult';
import ThankYouFreezerFood from '../../views/ThankYouFreezerFood/ThankYouFreezerFood';

const homeClaimsPrivateProps = {
  private: true,
  authTypes: ['otp', 'basic'],
  lobs: 'home',
};
const FreezerFoodClaimRoutes = () => {
  return (
    <DataContextProvider
      Context={FreezerFoodContext}
      localStoragePropertyName={localStorageFreezerFoodPropertyName}
      localStorageExpiryDays={config.client.localStorageFreezerFoodExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path="/homeclaim/freezerfood/history"
          component={HomeClaimsHistoryFreezerFood}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path="/homeclaim/freezerfood/incidentdetails"
          component={FreezerFoodClaim}
          {...homeClaimsPrivateProps}
        />

        <EclaimsRoute
          exact
          path="/homeclaim/freezerfood/results"
          component={FreezerFoodClaimResult}
          {...homeClaimsPrivateProps}
        />

        <EclaimsRoute
          exact
          path="/homeclaim/freezerfood/thankyoupage"
          component={ThankYouFreezerFood}
          {...homeClaimsPrivateProps}
        />

        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default FreezerFoodClaimRoutes;
