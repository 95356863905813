import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import {
  ThemedRadioGroupInput,
  ThemedDropdownV2,
  ThemedErrorMessage,
} from 'ageas-ui-components';

const ThemedRadioGroupInputStyled = styled(ThemedRadioGroupInput)`
  border-radius: 4px;
  ${props =>
    props.RoundBorder &&
    css`
      border-radius: 21px;
    `}
`;

// Display either:
//   a set of buttons (instance of RadioGroupInput),
//   or a select dropdown (instance of ThemedDropdownV2),
// depending on number of options vs maxButtons
//   maxButtons = 0 or < options.length: Select
//   else: (Radio buttons)
//   Default is null (buttons)
//   No field
const DropdownRadio = ({
  options,
  id,
  fieldName,
  selectedOption,
  onChange,
  errorMsg,
  hasError,
  maxButtons,
  noFieldOnZero,
  placeholder,
  radioProps,
  dropdownProps,
  RoundBorder,
  ...rest
}) => {
  const [randomId, setRandomId] = useState(undefined);
  const stateRef = useRef();
  stateRef.current = {
    onChange,
  };

  useEffect(() => {
    setRandomId(`DropdownRadio-${fieldName}-${_uniqueId()}`);
  }, []);

  // Convert event-based return value callback used by RadioGroupInput
  // and ThemedDropdownV2, to value-based callback
  const onChangeEvent = event => {
    onChange(event.target.value);
  };

  const deriveSelectedValue = (triggerOnChange = false) => {
    // If current value not provided but there is only one value to
    // display, default to that value and inform parent via callback.
    if (!selectedOption && options?.length === 1) {
      if (triggerOnChange) {
        stateRef.current.onChange(options[0].value); // Notify parent
      }
      return options[0].value; // Set field value
    }

    // If current value not in list, override to undefined
    // and inform parent via callback
    // Safety in case list changes but selected option already specified in RFF
    if (
      selectedOption &&
      options &&
      !options?.find(option => option.value === selectedOption)
    ) {
      if (triggerOnChange) {
        stateRef.current.onChange(undefined); // Notify parent
      }
      return undefined; // Set field value
    }

    // Else use the provided value
    return selectedOption;
  };

  // Automatic processing of selectedOption
  useEffect(() => {
    deriveSelectedValue(true);
  }, [options, selectedOption]);

  // Render no content until Id is definitely set
  if (!id && !randomId) {
    return null;
  }

  // Render RadioGroup or Dropdown, or nothing, depending on number of options
  // vs max buttons
  if (!options?.length && noFieldOnZero) {
    return null;
  }

  const renderDropdown = maxButtons === 0 || options?.length > maxButtons;

  return (
    <>
      {renderDropdown && (
        <ThemedDropdownV2
          noTextBehindIcon
          placeholder={placeholder}
          options={options || []}
          name={fieldName}
          selected={deriveSelectedValue()}
          onChange={onChangeEvent}
          id={id || randomId}
          data-testid={id || randomId}
          hasError={!!errorMsg || hasError}
          {...dropdownProps}
          {...rest}
        />
      )}
      {!renderDropdown && (
        <ThemedRadioGroupInputStyled
          fieldName={fieldName}
          hasError={!!errorMsg || hasError}
          id={id || randomId}
          brandIconSet="ageas"
          options={options || []}
          onChange={onChangeEvent}
          selectedValue={deriveSelectedValue()}
          medium
          vertical
          RoundBorder={RoundBorder}
          {...radioProps}
          {...rest}
        />
      )}
      {errorMsg && (
        <div aria-live="assertive" aria-relevant="additions removals">
          <ThemedErrorMessage hasIcon>{errorMsg}</ThemedErrorMessage>
        </div>
      )}
    </>
  );
};

DropdownRadio.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  fieldName: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
  RoundBorder: PropTypes.bool,
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  hasError: PropTypes.bool,
  maxButtons: PropTypes.number,
  noFieldOnZero: PropTypes.bool,
  placeholder: PropTypes.string,
  radioProps: PropTypes.instanceOf(Object),
  dropdownProps: PropTypes.instanceOf(Object),
};

DropdownRadio.defaultProps = {
  options: undefined,
  id: undefined,
  selectedOption: '',
  onChange: () => {},
  errorMsg: '',
  hasError: false,
  maxButtons: null,
  noFieldOnZero: true,
  placeholder: 'Please select an option...',
  radioProps: {},
  dropdownProps: {},
  RoundBorder: false,
};

export default DropdownRadio;
