import React from 'react';
import BreadcrumbClaims from '../components/BreadcrumbClaims/BreadcrumbClaims';

export const items = [
  {
    id: 'claim',
    label: '1',
  },
  {
    id: 'results',
    label: '2',
  },
  {
    id: 'thankYou',
    label: '3',
  },
];

const ClaimBreadcrumbStandard = ({ ...props }) => {
  return <BreadcrumbClaims items={items} {...props} />;
};

export default ClaimBreadcrumbStandard;
