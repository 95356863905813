import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { stringNotEqualTo } from 'ageasvalidation/lib/schemas/String';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';

export const glazingDamagedItemSchema = glazingDamagedItemFieldName => {
  return {
    [glazingDamagedItemFieldName]: stringRequired(
      [glazingDamagedItemFieldName],
      'Please select an item',
    ).concat(
      stringNotEqualTo(
        'other',
        [glazingDamagedItemFieldName],
        '_SomethingElseSelectedHome',
      ),
    ),
  };
};

const GLAZING_ITEM_LIST = [
  {
    value: 'window',
    label: 'Glazed window',
  },
  {
    value: 'door',
    label: 'Glazed door',
  },
  {
    value: 'other',
    label: 'Something else',
  },
];

const GlazingDamagedItem = ({ name, ...rest }) => {
  const GLAZING_DAMAGED_ITEM_FIELD_NAME = name;

  return (
    <StandardRadio
      name={GLAZING_DAMAGED_ITEM_FIELD_NAME}
      title="Please tell us what was damaged"
      options={GLAZING_ITEM_LIST}
      medium
      vertical
      {...rest}
    />
  );
};

GlazingDamagedItem.propTypes = {
  name: PropTypes.string,
};
GlazingDamagedItem.defaultProps = {
  name: undefined,
};

export default GlazingDamagedItem;
