import React from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { PageContainer } from '../../assets/styles/GenericInsurance.style';
import Banner from '../ContactBanner/MiddleLayer';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import config from '../../helpers/config';
import { brand } from '../../../data/whitelabel.config';

const Theme = getTheme(brand);

const LandingView = () => {
  // TODO move this into a useEffect, should not be in render method
  if (config.client.featureLanding !== 'true') {
    if (typeof window !== 'undefined') {
      window.location.replace(config.client.landingPageRedirectURL);
    }
    return null;
  }

  return (
    <ThemeProvider theme={Theme}>
      <PageContainer>
        <BrandHeader suppressWarningOnClick />
        <div id="wholewindow">
          <Banner />
          <BrandFooter />
        </div>
      </PageContainer>
    </ThemeProvider>
  );
};

export default LandingView;
