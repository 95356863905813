import PropTypes from 'prop-types';
import React from 'react';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardDropdown from '../../../components/Forms/StandardDropdown/StandardDropdown';
import StandardRadio from '../../../components/Forms/StandardRadio/StandardRadio';
import {
  PROPERTY_TYPE_DAMAGED_LIST,
  getInvolvedPartyTypeList,
  incidentDetailsFields,
} from '../motorIncidentDetailsHelper';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';

const IncidentDamagePropertyFields = ({ values, validateFields }) => {
  return (
    <>
      <StandardDropdown
        name={incidentDetailsFields.PROPERTY_TYPE_DAMAGED}
        label="What is the type of property damage?"
        aria-label="What is the type of property damage?"
        options={PROPERTY_TYPE_DAMAGED_LIST}
        small
        validate={validateFields}
      />
      {!!values[incidentDetailsFields.PROPERTY_TYPE_DAMAGED] && (
        <>
          {values[incidentDetailsFields.PROPERTY_TYPE_DAMAGED] === 'other' && (
            <StandardInput
              name={incidentDetailsFields.PROPERTY_TYPE_DAMAGED_OTHER_DETAILS}
              label="Please confirm the type of property"
              aria-label="Please confirm the type of property"
              small
              maxLength="100"
              validate={validateFields}
            />
          )}
          <YesOrNo
            name={incidentDetailsFields.PROPERTY_OWNER_KNOWN}
            title="Do you know who the property owner is?"
            validate={validateFields}
          />
          {values[incidentDetailsFields.PROPERTY_OWNER_KNOWN] === 'yes' && (
            <>
              <StandardRadio
                name={incidentDetailsFields.PROPERTY_OWNER_TYPE}
                title="Is the owner a private individual or a company/local authority?"
                options={getInvolvedPartyTypeList('Company/Local Authority')}
                medium
                vertical
                validate={validateFields}
              />
              {!!values[incidentDetailsFields.PROPERTY_OWNER_TYPE] && (
                <InvolvedParty
                  name={incidentDetailsFields.PROPERTY_OWNER_DETAILS}
                  header="Please confirm the name, address and contact details of the owner"
                  values={values}
                  company={
                    values[incidentDetailsFields.PROPERTY_OWNER_TYPE] ===
                    'company'
                  }
                  companyLabel="Company or local authority name"
                  validate={validateFields}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IncidentDamagePropertyFields;

IncidentDamagePropertyFields.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  validateFields: PropTypes.func,
};

IncidentDamagePropertyFields.defaultProps = {
  validateFields: undefined,
};
