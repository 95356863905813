import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemedCheckbox, ThemedErrorMessage } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import _uniqueId from 'lodash/uniqueId';
import FieldStyled from '../FieldStyled/FieldStyled';

export const getStandardCheckboxRequiredSchema = (
  fieldName,
  message = 'Please confirm',
) => {
  return {
    [fieldName]: stringRequired([fieldName], message),
  };
};

const StandardCheckbox = ({
  name,
  id,
  label,
  validate,
  value,
  children,
  ...props
}) => {
  const elementId = useRef(id);
  if (!elementId.current) {
    elementId.current = `StandardDropdown-${name}-${_uniqueId()}`;
  }
  return (
    <FieldStyled name={name} validate={validate} type="checkbox">
      {({ input, meta }) => (
        <>
          <ThemedCheckbox
            text={label}
            fieldName={elementId.current}
            {...input}
            {...props}
          />

          {meta.error && meta.touched && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func,
  value: PropTypes.string.isRequired,
  children: PropTypes.node,
};

StandardCheckbox.defaultProps = {
  id: undefined,
  validate: () => {},
  children: undefined,
};

export default StandardCheckbox;
