import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const NotFoundRoute = props => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      render={({ staticContext }) => {
        /* istanbul ignore next */
        if (staticContext) {
          // `missed` is used in `server.js` to denote a 404
          staticContext.missed = true;

          // `statusCode` is officially defined in the `StaticContext` docs
          staticContext.statusCode = 404;

          // `serverStatusCode` is used in `server.js` if `missed` is falsey,
          // so it's been set here just in case!
          staticContext.serverStatusCode = 404;
        }

        return <Component {...rest} />;
      }}
    />
  );
};

NotFoundRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default NotFoundRoute;
