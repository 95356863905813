import React from 'react';
import NestedAccordion from './NestedAccordion';
import AccordionSection from './AccordionSection';
import AccordionBodyPadded from './AccordionBodyPadded';

// Could not inline this as it broke accordion nesting logic
const contentBuilder = (content, padContent) => {
  if (!padContent) {
    return content;
  }
  return <AccordionBodyPadded>{content}</AccordionBodyPadded>;
};

const accordionBuilder = (entryData, padContent) => {
  return (
    <NestedAccordion
      key={entryData.title}
      title={entryData.title}
      icon={entryData.icon}
      activeIcon={entryData.activeIcon}
      contentHeader={entryData.contentHeader}
      titlePaddingLeft={entryData.titlePaddingLeft}
    >
      {contentBuilder(entryData.content, padContent)}
    </NestedAccordion>
  );
};

const sectionBuilder = (entryData, padContent) => {
  return (
    <AccordionSection
      key={entryData.title}
      title={entryData.title}
      icon={entryData.icon}
      activeIcon={entryData.activeIcon}
      contentHeader={entryData.contentHeader}
      titlePaddingLeft={entryData.titlePaddingLeft}
    >
      {contentBuilder(entryData.content, padContent)}
    </AccordionSection>
  );
};

// Must return an array to be compatible as child of NestedAccordion or
// AccordionSection. Else nesting props do not get passed
const nestedAccordionBuilder = (data, padContent) => {
  return data
    .filter(({ include }) => include === undefined || include)
    .map(entry => {
      return accordionBuilder(entry, padContent);
    });
};

// Must return an array to be compatible as child of NestedAccordion or
// AccordionSection. Else nesting props do not get passed
export const nestedAccordionSectionBuilder = (data, padContent) => {
  return data.map(entry => {
    return sectionBuilder(entry, padContent);
  });
};

export default nestedAccordionBuilder;
