import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { brand } from '../../../../data/whitelabel.config';
import {
  PageSection,
  TitleText,
  SubText,
  StatusText,
} from '../../StandardPageWrapper/StandardPageWrapperStyle';
import { HubContext } from '../../../helpers/hubContextHelper';
import { getPerilByCode } from '../../../helpers/perilCodesDescriptions';
import {
  CLAIM_STATUSES,
  getStatusByCode,
} from '../../../helpers/claimStatusDescriptions';
import { reverseDate } from '../../../helpers/dateTimeConverter';
import BackButtonSection from '../../StandardPageWrapper/BackButtonSection';

const Theme = getTheme(brand);

const getPerilDesc = perilCode => {
  return getPerilByCode(perilCode)?.capitalise || perilCode;
};

const getStatusDesc = status => {
  return getStatusByCode(status)?.capitalise || CLAIM_STATUSES.OPEN.capitalise;
};

const HubClaimStatus = () => {
  const { dataState = {} } = useContext(HubContext);
  const { claimReference, statusCode, perilCode, lossDate, declineCode } =
    dataState.claimDetails || {};

  return (
    <ThemeProvider theme={Theme}>
      <PageSection>
        <BackButtonSection to="/homehub" />
        <TitleText primary>
          Claim reference {claimReference} -{' '}
          <StatusText>{getStatusDesc(statusCode)}</StatusText>
        </TitleText>
        {perilCode?.trim() && <SubText>{getPerilDesc(perilCode)}</SubText>}
        <SubText>Incident date {reverseDate(lossDate)}</SubText>
        {declineCode === 'autoClosed' && (
          <SubText>
            This claim has been closed due to inactivity. If you wish to
            continue with this claim, please send us a message via the
            &apos;Message Centre&apos;.
          </SubText>
        )}
      </PageSection>
    </ThemeProvider>
  );
};

export default HubClaimStatus;
