import React from 'react';
import { ThemedHyperlink } from 'ageas-ui-components';
import FaqP from '../../FaqP';
import {
  FinancialOmbudsmanTelLink,
  FinancialOmbudsmanTelLink2,
} from '../../../../helpers/ageasTelephoneNumbers';

export const notSatisfiedTitle =
  'Who can I contact if I am not satisfied with the response to my complaint?';
export const notSatisfiedContent = (
  <>
    <FaqP>
      If you&apos;re not happy with how we have handled your complaint, or we
      haven’t resolved it within eight weeks, you can take it up with the
      Financial Ombudsman Service through the methods detailed below.
    </FaqP>
    <FaqP>
      Just so you know, this needs to be within six months from the date of our
      final response. Using this service won&apos;t affect your legal rights.
    </FaqP>
    <FaqP>
      As a free, independent body set up by the Government, it&apos;s the
      Financial Ombudsman&apos;s job to resolve disputes between customers and
      companies in the UK. You can find out more at{' '}
      <ThemedHyperlink
        primary
        href="https://www.financial-ombudsman.org.uk/"
        text="www.financial-ombudsman.org.uk"
      />{' '}
      and fill out its online, Word or PDF form.
    </FaqP>
    <FaqP>
      <strong>By phone:</strong>
      <br />
      Call <FinancialOmbudsmanTelLink /> or <FinancialOmbudsmanTelLink2 />
    </FaqP>
    <FaqP>
      <strong>By email:</strong>
      <br />
      <ThemedHyperlink
        primary
        href="mailto: complaint.info@financial-ombudsman.org.uk"
        text="complaint.info@financial-ombudsman.org.uk"
      />
    </FaqP>
    <FaqP>
      <strong>In writing:</strong>
      <br />
      Financial Ombudsman Service
      <br />
      Exchange Tower
      <br />
      London
      <br />
      E14 9SR
      <br />
    </FaqP>
  </>
);
