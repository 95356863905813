/* Portal Component Color links for Ageas Theme */

/* Component color links to be added here for each new
   Portal Component created */

import color from './AgeasColorTheme';

const PortalLibraryTheme = {
  /* TO BE UPDATED */
  loadingSpinnerBackgroundColor: 'rgba(255, 255, 255, 0.81)',
  orangePassword: '#fc9e00',
  vrnPlateCountryBackground: '#3082c8',

  /* ------------------------------------------- */

  vrnPlateTextColor: color.greyDarker,
  vrnPlateBackground: color.white,
  vrnPlateCountryTextColor: color.white,

  passwordStrengthBackground: color.greyLightest,
  passwordStrengthText: color.greyDark,

  primaryColorHover: color.primaryLight,

  redPassword: color.error,
  greenPassword: color.secondaryDark,

  statusOrange: color.error,
  statusGreen: color.secondaryDark,

  primaryColor: color.primary,

  cookieBackgroundColor: color.greyDarkest,

  headerArea: color.greyLighter,

  modalButtonBar: color.greyLighter,
  backButtonText: color.greyDarker,

  notificationsBackground: color.greyLight,

  documentTextGrey: color.greyDarkest,

  errorColor: color.error,

  logoutButtonContainer: color.greyLight,

  footerBackground: color.greyDarker,

  milesInputText: color.greyDarker,

  navItemWrapper: color.greyDarker,

  greyThinBar: color.greyDarker,

  uploadListHeading: color.greyDarker,

  passwordStrengthBorder: color.greyLight,

  profileName: color.greyDarker,

  listHeading: color.greyDarker,

  navItemFill: color.greyDarker,
  navItemBorder: color.greyDarker,
  navLink: color.greyDarker,
  navLinkInactive: color.greyDarker,
  navImageInactive: color.greyDarker,
  mobileNavWrapperBackground: color.greyLight,

  breadCrumbActive: color.primary,
  breadCrumbInactive: color.greyDark,

  renewalLink: color.primary,
  welcomeMessage: color.greyDarker,

  renewalIcon: color.primary,
  accordionSectionDivider: color.greyDarkest,
  loadingSpinner: color.greyDarker,

  renewalSummaryBackground: color.greyLight,
  renewalText: color.greyDarkest,
  renewalPrice: color.secondary,
  modalHeader: color.primary,
  successTick: color.secondaryDark,

  claimsHeaderText: color.primary,
  claimDetailsDivider: color.greyLight,

  convictionHeaderText: color.primary,
  convictionDetailsDivider: color.greyLight,

  modalActionDivider: color.greyLight,

  paymentSummaryAmount: color.primary,

  changeDriverBorder: color.greyLight,
  themedAgreementText: color.primary,
  driverDetailsModalButtonBackground: color.white,
  driverDetailsModalButtonText: color.secondaryDark,
  driverDetailsModalBackButtonText: color.greyDarker,
  driverDetailsModalBorder: color.secondaryDark,

  modificationDeleteModalButtonBackground: color.white,
  modificationDeleteModalButtonText: color.secondaryDark,
  modificationDeleteModalText: color.error,
  modificationDeleteModalBorder: color.secondaryDark,

  optionalExtrasTickIcon: color.secondary,

  editIcon: color.greyDarker,
  binIcon: color.greyDarker,
};

export default PortalLibraryTheme;
