import axios from 'axios';
import cookie from 'react-cookies';

// Note: This is the axios instance for calls to Ageas APIs
// If adding calls to other services, a separate/new axios instance
// will need to be added.

const http = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  config => {
    const token = cookie.load('token');
    const requestConfig = { ...config };

    if (token) {
      requestConfig.headers.Authorization = token;
    }

    return requestConfig;
  },

  error => {
    return Promise.reject(error);
  },
);

export default http;
