import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * If RFF selected value does not match any of the options,
 * use callback to inform RFF to clear the value
 */
const ClearOnInvalid = ({ value, options, onChange }) => {
  const refs = useRef({});
  refs.current = {
    onChange,
  };

  useEffect(() => {
    if (
      value !== undefined &&
      !options?.find(option => value === option.value)
    ) {
      refs.current.onChange();
    }
  }, [value, options]);

  return null;
};
ClearOnInvalid.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};
ClearOnInvalid.defaultProps = {
  value: undefined,
  options: undefined,
};

export default ClearOnInvalid;
