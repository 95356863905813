import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';

const BlockNavigation = ({
  enabled = false,
  beforePromptAction,
  message = 'Leave page? Changes you made may not be saved.',
}) => {
  const propsRef = useRef({});
  propsRef.current = { beforePromptAction };

  // When enabled toggles processing
  useEffect(() => {
    // Blocking is enabled?
    if (enabled) {
      // Add unload handler to block conventional navigation
      const beforeUnloadHandler = e => {
        const event = e;
        propsRef.current?.beforePromptAction?.();
        event.preventDefault();
        event.returnValue = true;
      };
      window.addEventListener('beforeunload', beforeUnloadHandler);
      // Remove next time this useEffect fires
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
      };
    }
    // Blocking is turned off, do nothing
    return undefined;
  }, [enabled]);

  // Add React Router Prompt to block SPA navigation
  return (
    <Prompt
      when={enabled}
      message={() => {
        propsRef.current?.beforePromptAction?.();
        return message;
      }}
    />
  );
};

BlockNavigation.propTypes = {
  enabled: PropTypes.bool,
  beforePromptAction: PropTypes.func,
  message: PropTypes.string,
};
BlockNavigation.defaultProps = {
  enabled: undefined,
  beforePromptAction: undefined,
  message: undefined,
};

export default BlockNavigation;
