import React from 'react';
import { ThemedHyperlink } from 'ageas-ui-components';
import FaqP from '../../FaqP';

export const title = 'What can I expect when I make an online claim?';
export const content = (
  <>
    <FaqP>
      As your insurer, we&apos;re here to help make claims as easy as possible.
      We understand that making a claim can be a worrying and stressful time for
      you.
    </FaqP>
    <FaqP>
      When you make a claim online, you will receive a decision on the outcome
      of your claim. If, for any reason, we are unable to provide you with an
      immediate decision, we&apos;ll clearly tell you what will happen next and
      how long it will take.
    </FaqP>
    <FaqP>
      Once your claim has been registered, we&apos;ll send you a link so that
      you can easily track your claim 24/7 by logging into the claims hub. All
      you need is your policy or claim reference number sent to you after you
      completed your claim notification.
    </FaqP>
    <FaqP>
      To log into the claims hub, please follow this link
      <br />
      <ThemedHyperlink
        primary
        href="https://www.ageas.co.uk/claims/home-insurance"
        text="www.ageas.co.uk/claims/home-insurance"
      />
    </FaqP>
  </>
);

export const pair = { title, content };
