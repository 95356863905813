import styled, { css } from 'styled-components';
import { ThemedButton } from 'ageas-ui-components';
import media from '../MediaQuery/MediaQuery';

export const TitleText = styled.h2`
  font-size: 18px;
  margin-left: 10px;
  margin-top: 28px;
`;

export const ButtonHolder = styled.div`
  ${media.phone`
    margin-bottom: 36px;
  `}
`;

export const DisplayArea = styled.div`
  border: 0.5px solid #cccccc;
  display: flex;
  max-width: 791px;
  min-height: 160px;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  margin: 8px 0px;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ErrorContainer = styled.div`
  margin-bottom: 20px;
`;

export const ButtonStyle = styled.button`
  background-color: #ebebeb;
  border: 0.25px solid #cccccc;
  font-size: 16px;
  color: #2c2c2c;
  padding: 10px;
  margin: 10px 0px 0px 10px;
`;

export const ThemedButtonIconRight = styled(ThemedButton)`
  display: none;
  ${media.phone`
  padding: 6px 35px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  margin-bottom: 18px;
  margin-left: 6%;
  margin-right:5px;
  color: #000000;
`}
`;

export const Container = styled.div`
  display: none;
  ${media.phone`
  align-content: center;
  display: flex;
  overflow-x: hidden;
  position: relative;
  `}
`;

export const House = styled.img`
  display: none;
  ${media.phone`
  max-width: 100%;
  flex-shrink: 0;
  display: flex;
  margin-left:15px;
  `}
`;

export const ThemedButtonIconContainer = styled.span`
  display: none;
  ${media.phone`
  padding: 7px 10px 7px 10px;
  ${props =>
    props.large &&
    css`
      padding: 6px 11px 6px 11px;
    `}
  font-size: 18px;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  font-weight: 700;
  color: #f8f8f8;
  background: #6fa313;
  margin-right: 15px;
  display: flex;
  `}
`;

export const HomeIconContainer = styled.span`
  display: none;
  ${media.phone`
  padding: 8px;
  font-size: 1em;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  font-weight: 500;
  color: #f8f8f8;
  background: #6fa313;
  margin-right: 15px;
  position: absolute;
  top: 3%;
  left: 38%;
  display: flex;
  `}
`;

export const HomeIconContainerOne = styled.div`
  display: none;
  ${media.phone`
  top: 9%;
  left: 30%;
  display: flex;
  position: absolute;
  `}
`;

export const HomeIconContainerTwo = styled.div`
  display: none;
  ${media.phone`
  top: 62%;
  left: 64%;
  display: flex;
  position: absolute;
  `}
`;

export const IconContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`;

export const BackgroundIcon = styled.img`
  width: 25px;
  position: absolute;
`;

export const RemoveIcon = styled.img`
  width: 11px;
  height: 10px;
  margin-left: 7px;
  position: relative;
`;

export const ModalButtonsContainer = styled.div`
  text-align: right;
  width: 100%;
`;
