import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { validateField } from '../../../helpers/validationHelper';
import { getErrorMessage } from '../../../helpers/errorMessages';
import ModalForm from '../ModalForm/ModalForm';
import VehicleDamageRadios from './VehicleDamageRadios';

export const FIELD_NAME = 'damage';

const schema = {
  [FIELD_NAME]: stringRequired([FIELD_NAME], 'Please confirm'),
};

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const VehicleDamageModalForm = ({
  name = FIELD_NAME,
  includeNone,
  ...rest
}) => {
  return (
    <ModalForm {...rest}>
      <VehicleDamageRadios
        name={name}
        includeNone={includeNone}
        validateFields={validateFields}
      />
    </ModalForm>
  );
};

export default VehicleDamageModalForm;

VehicleDamageModalForm.propTypes = {
  name: PropTypes.string,
  includeNone: PropTypes.bool,
};

VehicleDamageModalForm.defaultProps = {
  name: undefined,
  includeNone: undefined,
};
