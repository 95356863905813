import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

const validKeys = [];

/**
 * @description: Validate the field is true.
 * @param keys Fields to validate
 * @succeeds when field is a boolean true
 * @fails when field is not a boolean or is boolean false
 */
export const mustBeTrue = (
  keys = validKeys,
  message = messages.MUST_BE_TRUE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.boolean()
      .valid(true)
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field is false.
 * @param keys Fields to validate
 * @succeeds when field is a boolean false
 * @fails when field is not a boolean or is boolean true
 */
export const mustBeFalse = (
  keys = validKeys,
  message = messages.MUST_BE_FALSE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.boolean()
      .valid(false)
      .required()
      .error(() => message),
  );
};

export const mustBeNo = (keys, message) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .valid('No', 'no')
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field is false alone.
 * @param keys Fields to validate
 * @succeeds when field is a boolean false and undefined
 * @fails when field is only boolean true
 */
export const mustBeFalseUndefined = (
  keys = validKeys,
  message = messages.MUST_BE_FALSE_OR_UNDEFINED,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.boolean()
      .allow('')
      .optional()
      .invalid(true)
      .error(() => message),
  );
};
