import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { getTheme } from 'ageas-ui-themes';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { numberRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { min } from 'ageasvalidation/module/schemas/Min';
import { max } from 'ageasvalidation/module/schemas/Max';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import { dateISOConverter } from '../../helpers/dateTimeConverter';
import { brand } from '../../../data/whitelabel.config';
import HomeStandardClaimDetails, {
  getStandardClaimDetailsValidationSchema,
} from '../HomeStandardClaimDetails/HomeStandardClaimDetails';
import {
  personalLossDataContext,
  AccidentalDamageContext,
  AccidentalLossContext,
  TheftContext,
} from '../../helpers/personalLossContextHelper';
import { buildStateFromCookies } from '../../contexts/AuthContext';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import {
  getIncidentDateValidationSchema,
  run3YearDateValidation,
} from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import { validateField } from '../../helpers/validationHelper';
import {
  serverErrorHome,
  getErrorMessage,
  UnableToProceedWithClaimHome,
  validPolicyErrorHome,
  IncorrectHomeClaimCover,
} from '../../helpers/errorMessages';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import IncidentDetails, {
  getIncidentDetailsSchema,
} from '../../components/Forms/ClaimEntryFields/IncidentDetails/IncidentDetails';
import {
  TitleText,
  PageSectionBare,
  Subsection,
  ButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';

import HomeAwayLocation, {
  getHomeAwayLocationSchema,
} from '../../components/Forms/ClaimEntryFields/HomeAwayLocation/HomeAwayLocation';
import {
  isValidItemTypeForPeril,
  getItemByType,
} from '../../helpers/itemListAD';
import HomeMakeModelDetails, {
  getHomeMakeModelValidationSchema,
} from '../../components/Forms/ClaimEntryFields/HomeMakeModelDetails/HomeMakeModelDetails';
import P from '../../components/StandardTags/P';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import CarpetDamagedRoom, {
  carpetDamagedSchema,
} from '../../components/CarpetQuestions/CarpetDamagedRoom';
import CarpetTimeline, {
  carpetTimelineSchema,
} from '../../components/CarpetQuestions/CarpetTimeline';
import CarpetProviderDetails, {
  carpetProviderDetailsSchema,
} from '../../components/CarpetQuestions/CarpetProviderDetails';
import StandardCurrencyInput from '../../components/Forms/StandardCurrencyInput/StandardCurrencyInput';
import StandardCurrencyDecimalInput from '../../components/Forms/StandardCurrencyDecimalInput/StandardCurrencyDecimalInput';
import GlazingDamagedItem, {
  glazingDamagedItemSchema,
} from '../../components/GlazingDamagedItem/GlazingDamagedItem';
import YesOrNo, {
  yesOrNoSchema,
} from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';
import JewelleryModalForm from '../../components/JewelleryItems/JewelleryModalPopup';
import {
  buildJewelleryGlobalFieldsPayload,
  buildJewelleryItemsListPayload,
} from '../../helpers/buildPayloadPersonalLossJewellery';
import JewelleryItemFields from '../../components/JewelleryItemFields/JewelleryItemFields';
import ClaimBreadcrumbPL from '../../helpers/ClaimBreadcrumbPL';
import { policyDataValid } from '../../helpers/policyDataContextHelper';
import StandardPopup from '../../components/StandardPopup/StandardPopup';

const Theme = getTheme(brand);

const CurrencyInput = styled(StandardCurrencyInput)`
  width: 100px;
`;

const perilType = {
  AD: 'damage',
  AL: 'loss',
  TF: 'theft',
};

const InvalidItemRemovedModal = ({ onClose }) => (
  <StandardPopup buttons={[{ text: 'OK', onClick: onClose }]}>
    One or more of your jewellery items was not valid on this incident date and
    has been removed. Please review your items.
  </StandardPopup>
);
InvalidItemRemovedModal.propTypes = {
  onClose: PropTypes.func,
};

InvalidItemRemovedModal.defaultProps = {
  onClose: () => {},
};

const PersonalLossClaim = ({ perilCode, description, perilContext }) => {
  const [navigatePath, setNavigatePath] = useState(null);
  const {
    dataState: baseContext,
    updateDataState,
    clearAllDataState,
  } = useContext(perilContext);

  const policyDetailsContext = {
    ...baseContext?.policyDetails,
  };

  const [serverError, setServerError] = useState(false);
  const [notInForcePolicy, isNotInForcePolicy] = useState(false);
  const [noCover, isNotCovered] = useState(false);
  const [unableToProceed, isUnableToProceed] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderedPage, setRenderedPage] = useState(false);
  const currentLocation = useLocation();
  const [itemTypeDescription, setItemTypeDescription] = useState(undefined);

  const [jewelleryItemModal, setJewelleryItemModal] = useState(null);
  const [showInvalidItemRemovedModal, setShowInvalidItemRemovedModal] =
    useState(false);

  const onInvalidItemRemove = () => {
    setShowInvalidItemRemovedModal(true);
  };
  const onInvalidItemRemovedModalClose = () => {
    setShowInvalidItemRemovedModal(false);
  };

  const closeJewelleryItemModal = () => {
    setJewelleryItemModal(null);
  };

  const openJewelleryItemModal = values => {
    setJewelleryItemModal(values);
  };

  const onJewelleryItemAdd = (push, title, specifiedItems) => {
    const onAddSubmit = values => {
      push(values);
      closeJewelleryItemModal();
    };
    openJewelleryItemModal({
      onSubmit: onAddSubmit,
      initialValues: {},
      title,
      specifiedItems,
      allSpecifiedItems: policyDetailsContext.data?.policySpecifiedItems,
    });
  };

  const onJewelleryItemEdit = (
    index,
    initialValues,
    update,
    title,
    specifiedItems,
  ) => {
    const onEditSubmit = values => {
      update(index, values);
      closeJewelleryItemModal();
    };
    openJewelleryItemModal({
      index,
      onSubmit: onEditSubmit,
      initialValues,
      title,
      specifiedItems,
      allSpecifiedItems: policyDetailsContext.data?.policySpecifiedItems,
    });
  };

  const resetStateErrors = () => {
    setServerError(false);
    isNotInForcePolicy(false);
    isNotCovered(false);
    isUnableToProceed(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Get initial item type from context/URL
    const queryParams = new URLSearchParams(currentLocation.search);
    const initialItemType =
      queryParams.get('itemType') || baseContext?.itemType || undefined;

    // if context valid for /homeclaim/<peril>/results then go to it
    if (!isEmpty(baseContext) && baseContext?.detailsCompleted) {
      setNavigatePath(perilRoutes[perilCode].results);
    }

    // Item type invalid or not allowed, return to homepage
    else if (
      !initialItemType ||
      !isValidItemTypeForPeril(initialItemType, perilCode)
    ) {
      setNavigatePath('/homeinsurance');
    }

    // Load this page
    else {
      // If context item type differs from chosen, clear context and set
      // context item type
      if (!baseContext?.itemType || baseContext?.itemType !== initialItemType) {
        clearAllDataState();
        updateDataState(personalLossDataContext.itemType, initialItemType);
      }
      setItemTypeDescription(getItemByType(initialItemType).label);
      setRenderedPage(true);
    }

    return () => {
      closeJewelleryItemModal();
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (
      policyDetailsContext?.status === 'nokey' &&
      !buildStateFromCookies().isLoggedIn
    ) {
      setNavigatePath(
        `/homeclaim/brokerlogin?target=${encodeURIComponent(
          window.location.pathname,
        )}`,
      );
    }
  }, [policyDetailsContext?.status]);

  if (navigatePath) {
    return <NavigateTo path={navigatePath} />;
  }

  if (!renderedPage) {
    return null;
  }

  const INCIDENT_DATE_FIELD_NAME = 'damagedDate';
  const POLICYHOLDER_NAME = 'policyHolderNumber';
  const MOBILE_TELEPHONE_NAME = 'homeMobileTelephone';
  const EMAIL_ADDRESS_NAME = 'homeEmail';
  const INCIDENT_DETAILS_NAME = 'incidentDetails';
  const LOCATION_NAME = 'incidentLocation';
  const ITEM_MAKE_NAME = 'itemMake';
  const ITEM_MODEL_NAME = 'itemModel';
  const CARPET_DAMAGED_ROOM = 'carpetDamagedRoom';
  const CARPET_TIMELINE = 'carpetTimeline';
  const CARPET_PROVIDER_DETAILS = 'carpetProviderDetails';
  const CARPET_COST = 'carpetCost';
  const FIXEDGLAZING_COST = 'fixedGlazingCost';
  const GLAZING_DAMAGED_ITEM = 'glazingDamagedItem';
  const GLAZING_REPAIRS_CARRIED_OUT = 'glazingRepairsCarriedOut';
  const GLAZING_ESTIMATE_INVOICE_RECEIPT = 'glazingEstimateInvoiceReceipt';

  const schema = {
    ...getIncidentDateValidationSchema(INCIDENT_DATE_FIELD_NAME),
    ...getStandardClaimDetailsValidationSchema(
      POLICYHOLDER_NAME,
      MOBILE_TELEPHONE_NAME,
      EMAIL_ADDRESS_NAME,
    ),
    ...getIncidentDetailsSchema(INCIDENT_DETAILS_NAME),
    ...getHomeAwayLocationSchema(LOCATION_NAME),
    ...getHomeMakeModelValidationSchema(ITEM_MAKE_NAME, ITEM_MODEL_NAME),
    ...carpetDamagedSchema(CARPET_DAMAGED_ROOM),
    ...carpetTimelineSchema(CARPET_TIMELINE),
    ...carpetProviderDetailsSchema(CARPET_PROVIDER_DETAILS),
    [CARPET_COST]: numberRequired([CARPET_COST], 'Please enter a value')
      .concat(min(0, [CARPET_COST], true, 'Please enter a value'))
      .concat(max(9999, [CARPET_COST], true, 'Please enter a value')),
    [FIXEDGLAZING_COST]: numberRequired(
      [FIXEDGLAZING_COST],
      'Please enter a valid value',
    )
      .concat(min(1, [FIXEDGLAZING_COST], true, 'Please enter a valid value'))
      .concat(
        max(9999.99, [FIXEDGLAZING_COST], true, 'Please enter a valid value'),
      )
      .concat(
        regularExpression(
          /^[0-9]{1,4}(\.[0-9]{2})?$/,
          [FIXEDGLAZING_COST],
          'Please enter a valid value',
        ),
      ),
    ...glazingDamagedItemSchema(GLAZING_DAMAGED_ITEM),
    ...yesOrNoSchema(GLAZING_REPAIRS_CARRIED_OUT),
    ...yesOrNoSchema(GLAZING_ESTIMATE_INVOICE_RECEIPT),
  };

  const validateFields = (value, allValues, meta) => {
    let error;
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return getErrorMessage(error);
      }
    }

    // Custom validation - incident happened 3yrs ago
    if (meta.name === INCIDENT_DATE_FIELD_NAME) {
      if (run3YearDateValidation(allValues[INCIDENT_DATE_FIELD_NAME], 'home')) {
        return serverErrorHome();
      }
      // If inputs changed and policy not valid error displayed, reset it
      if (notInForcePolicy) {
        isNotInForcePolicy(false);
      }
      // If inputs changed and unable to proceed error displayed, reset it
      if (unableToProceed) {
        isUnableToProceed(false);
      }
      if (serverError) {
        setServerError(false);
      }
    }
    return undefined;
  };

  const incidentDateIsValid = (values, errors) => {
    return !!(!errors.damagedDate && values.damagedDate);
  };

  const showLocationRadio = () => {
    return (
      baseContext.itemType === 'mobile' ||
      baseContext.itemType === 'laptop' ||
      baseContext.itemType === 'jewellery'
    );
  };

  const showMakeModelFields = () => {
    return (
      baseContext.itemType === 'tv' ||
      baseContext.itemType === 'mobile' ||
      baseContext.itemType === 'laptop'
    );
  };

  const showIncidentDetailsField = () => {
    return (
      baseContext.itemType === 'tv' ||
      baseContext.itemType === 'mobile' ||
      baseContext.itemType === 'laptop' ||
      baseContext.itemType === 'carpets' ||
      baseContext.itemType === 'fixedGlazing' ||
      baseContext.itemType === 'jewellery'
    );
  };

  const showCarpetQuestions = () => {
    return baseContext.itemType === 'carpets';
  };

  const showGlazingQuestions = () => {
    return baseContext.itemType === 'fixedGlazing';
  };

  const showGlazingCostField = values => {
    return values[GLAZING_ESTIMATE_INVOICE_RECEIPT] === 'yes';
  };

  const showJewelleryQuestions = () => {
    return baseContext.itemType === 'jewellery';
  };

  const buildItemDetailPayload = values => {
    if (showJewelleryQuestions()) {
      return buildJewelleryItemsListPayload(values);
    }
    if (showCarpetQuestions()) {
      return [
        {
          itemCategory: 'furnishing',
          furnishingDetails: {
            itemType: baseContext.itemType,
            furnishingLocation: values.carpetDamagedRoom,
            furnishingAge: values.carpetTimeline,
            furnishingSupplier: values.carpetProviderDetails,
            claimedAmount: +values.carpetCost,
          },
        },
      ];
    }
    if (showGlazingQuestions()) {
      return [
        {
          itemCategory: 'fixedGlazing',
          fixedGlazingDetails: {
            fixedGlazingDamagedItem: values.glazingDamagedItem,
            fixedGlazingRepairsCarriedOut:
              values.glazingRepairsCarriedOut === 'yes',
            fixedGlazingEstimateInvoiceReceipt:
              values.glazingEstimateInvoiceReceipt === 'yes',
            ...(showGlazingCostField(values) && {
              fixedGlazingCost: +values.fixedGlazingCost,
            }),
          },
        },
      ];
    }
    return [
      {
        itemCategory: 'electronicDevice',
        electronicDeviceDetails: {
          itemType: baseContext.itemType,
          itemMake: values.itemMake,
          itemModel: values.itemModel,
        },
      },
    ];
  };

  const buildRequestPayload = values => {
    const PERIL_SECTION_NAME = {
      AD: 'accidentalDamageDetails',
      AL: 'accidentalLossDetails',
      TF: 'theftDetails',
    };
    return {
      homeIncidentDetails: {
        perilCode,
        incidentDate: dateISOConverter(values.damagedDate, true),
        policyholderId: values.policyHolderNumber,
        emailAddress: values.homeEmail,
        mobileTelephoneNumber: values.homeMobileTelephone,
        [PERIL_SECTION_NAME[perilCode] || 'details']: {
          ...(showIncidentDetailsField(values) && {
            incidentDetails: values.incidentDetails,
          }),
          ...(showLocationRadio(values) && {
            incidentLocation: values.incidentLocation,
          }),
          ...(showJewelleryQuestions() &&
            buildJewelleryGlobalFieldsPayload(values, perilCode)),
          lossItems: buildItemDetailPayload(values),
        },
      },
    };
  };

  const onCreateClaimFail = () => {
    updateDataState(personalLossDataContext.personalLossData, {});
    updateDataState(personalLossDataContext.detailsCompleted, false);
    setServerError(true);
    setIsLoading(false);
  };

  const onCreateClaimSuccess = (data, values) => {
    const { claimReference = '' } = data?.data || {};
    const { declines = [] } = data?.data?.status || {};

    setIsLoading(false);
    updateDataState(personalLossDataContext.personalLossData, data?.data);
    updateDataState(
      personalLossDataContext.userContactNumber,
      values.homeMobileTelephone,
    );
    updateDataState(personalLossDataContext.amount, values.fixedGlazingCost);

    if (baseContext.itemType === 'fixedGlazing') {
      updateDataState(
        personalLossDataContext.glazingEstimateInvoiceReceipt,
        values.glazingEstimateInvoiceReceipt,
      );
    }

    if (declines.includes('PolicyNotOnCover')) {
      isNotInForcePolicy(true);
    } else if (declines.includes('PolicyCoverNotPreviouslyValid')) {
      isUnableToProceed(true);
    } else if (declines.includes('PolicyCoverNotValid')) {
      isNotCovered(true);
    } else if (!isEmpty(claimReference)) {
      updateDataState(personalLossDataContext.detailsCompleted, true);
      setNavigatePath(perilRoutes[perilCode].results);
    }
  };

  const createPersonalLossClaim = values => {
    setIsLoading(true);
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .post(
        config.client.homeclaimnumber_endpoint,
        buildRequestPayload(values),
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(data => {
        onCreateClaimSuccess(data, values);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          onCreateClaimFail();
        }
      });
  };

  const beforeSubmit = () => {
    resetStateErrors();
  };

  const onSubmit = values => {
    createPersonalLossClaim(values);
  };

  const showContinue = values => {
    return (
      !(
        values.policyHolderNumber === '000' ||
        noCover ||
        (showGlazingQuestions() && values.glazingDamagedItem === 'other')
      ) &&
      (policyDetailsContext.status !== 'loading' ||
        policyDataValid(policyDetailsContext))
    );
  };

  const dateLabelDescription =
    'To enable us to validate your claim, please tell us the date on which the incident occurred.';

  const renderJewelleryModal = () => {
    if (!showJewelleryQuestions() || jewelleryItemModal === null) {
      return null;
    }
    return (
      <JewelleryModalForm
        {...jewelleryItemModal}
        onCancel={() => closeJewelleryItemModal()}
      />
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      {isLoading && <LoadingSpinner />}
      {showInvalidItemRemovedModal && (
        <InvalidItemRemovedModal onClose={onInvalidItemRemovedModalClose} />
      )}
      <HomeClaimsWrapper backToClaimsLink>
        {renderJewelleryModal()}
        <ClaimBreadcrumbPL activeItem="claim" itemType={baseContext.itemType} />
        <PageSectionBare>
          <Subsection>
            <TitleText>
              {description} claim
              {itemTypeDescription && ` - ${itemTypeDescription}`}
            </TitleText>
            <P>
              Please note, we will be recording details of the circumstances in
              our claims system.
            </P>
          </Subsection>

          <Subsection last>
            <Form mutators={{ ...arrayMutators }} onSubmit={onSubmit}>
              {({ handleSubmit, values, form, errors }) => (
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  onSubmitCapture={event => beforeSubmit(event, values)}
                >
                  <HomeStandardClaimDetails
                    name={[
                      INCIDENT_DATE_FIELD_NAME,
                      POLICYHOLDER_NAME,
                      MOBILE_TELEPHONE_NAME,
                      EMAIL_ADDRESS_NAME,
                    ]}
                    dateSecondaryLabel={dateLabelDescription}
                    validate={validateFields}
                    dateValue={values.damagedDate}
                    perilCode={perilCode}
                    itemType={baseContext.itemType}
                    dateChecker={incidentDateIsValid(values, errors)}
                    contextName={perilContext}
                    policyHolderNameList={
                      personalLossDataContext?.policyDetails
                    }
                  />

                  {showMakeModelFields(values) && (
                    <HomeMakeModelDetails
                      name={[ITEM_MAKE_NAME, ITEM_MODEL_NAME]}
                      validate={validateFields}
                    />
                  )}

                  {showIncidentDetailsField(values) && (
                    <IncidentDetails
                      name={INCIDENT_DETAILS_NAME}
                      validate={validateFields}
                      aria-label="incident details"
                    />
                  )}

                  {showLocationRadio(values) && (
                    <HomeAwayLocation
                      name={LOCATION_NAME}
                      validate={validateFields}
                    />
                  )}

                  {showCarpetQuestions() && (
                    <>
                      <CarpetDamagedRoom
                        name={CARPET_DAMAGED_ROOM}
                        validate={validateFields}
                      />

                      <CarpetTimeline
                        name={CARPET_TIMELINE}
                        validate={validateFields}
                      />

                      <CarpetProviderDetails
                        name={CARPET_PROVIDER_DETAILS}
                        validate={validateFields}
                      />

                      <CurrencyInput
                        primary
                        name={CARPET_COST}
                        id={CARPET_COST}
                        label="Please tell us the approximate value of your carpet"
                        secondaryLabel='If you are unsure, please enter "0".'
                        maxLength="4"
                        size="4"
                        aria-label="carpet cost"
                        validate={validateFields}
                      />
                    </>
                  )}

                  {showGlazingQuestions() && (
                    <>
                      <GlazingDamagedItem
                        name={GLAZING_DAMAGED_ITEM}
                        validate={validateFields}
                        alwaysShowError={!!values.glazingDamagedItem}
                      />

                      <YesOrNo
                        name={GLAZING_REPAIRS_CARRIED_OUT}
                        title="Have the repairs already been carried out?"
                        validate={validateFields}
                      />

                      <YesOrNo
                        name={GLAZING_ESTIMATE_INVOICE_RECEIPT}
                        title="Do you have an estimate, invoice or receipt for the repairs?"
                        validate={validateFields}
                      />

                      {showGlazingCostField(values) && (
                        <StandardCurrencyDecimalInput
                          name={FIXEDGLAZING_COST}
                          primary
                          maxLength="7"
                          title="Please tell us the value of the fixed glazing you are claiming for"
                          direction="left"
                          adornText="£"
                          isRequired
                          aria-label="fixed glazing cost"
                          validate={validateFields}
                        />
                      )}
                    </>
                  )}

                  {showJewelleryQuestions() && (
                    <JewelleryItemFields
                      specifiedItems={
                        policyDetailsContext.data?.policySpecifiedItems
                      }
                      specifiedItemsStatus={policyDetailsContext.status}
                      perilCode={perilCode}
                      onItemAdd={onJewelleryItemAdd}
                      onItemEdit={onJewelleryItemEdit}
                      onInvalidItemRemove={onInvalidItemRemove}
                    />
                  )}

                  {unableToProceed && (
                    <ThemedErrorMessage hasIcon>
                      {UnableToProceedWithClaimHome()}
                    </ThemedErrorMessage>
                  )}

                  {notInForcePolicy && (
                    <ThemedErrorMessage hasIcon>
                      {validPolicyErrorHome(perilType[perilCode])}
                    </ThemedErrorMessage>
                  )}

                  {noCover && (
                    <>
                      {IncorrectHomeClaimCover(
                        baseContext?.personalLossData?.claimReference,
                      )}
                    </>
                  )}

                  {serverError && (
                    <ThemedErrorMessage hasIcon>
                      {serverErrorHome()}
                    </ThemedErrorMessage>
                  )}

                  {showContinue(values) && (
                    <ButtonsBottomContainer>
                      <ThemedButton secondary type="submit">
                        Continue
                      </ThemedButton>
                    </ButtonsBottomContainer>
                  )}
                </form>
              )}
            </Form>
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

PersonalLossClaim.propTypes = {
  perilCode: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  perilContext: PropTypes.shape({}).isRequired,
};

export default PersonalLossClaim;

export const PersonalLossClaimAccidentalDamage = ({ ...props }) => {
  return (
    <PersonalLossClaim
      {...props}
      perilCode="AD"
      description="Accidental damage"
      perilContext={AccidentalDamageContext}
    />
  );
};

export const PersonalLossClaimAccidentalLoss = ({ ...props }) => {
  return (
    <PersonalLossClaim
      {...props}
      perilCode="AL"
      description="Accidental loss"
      perilContext={AccidentalLossContext}
    />
  );
};

export const PersonalLossClaimTheft = ({ ...props }) => {
  return (
    <PersonalLossClaim
      {...props}
      perilCode="TF"
      description="Theft"
      perilContext={TheftContext}
    />
  );
};
