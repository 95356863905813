import CarImage from '../../assets/images/car_insurance.png';
import CarInvertedImage from '../../assets/images/car_insurance_inverted.png';
import HomeImage from '../../assets/images/home_insurance.png';
import HomeInvertedImage from '../../assets/images/home_insurance_inverted.png';
import PetImage from '../../assets/images/ageas_icons_master_v2_pet_line_option1.png';
import BusinessImage from '../../assets/images/business_insurance.png';
import TravelImage from '../../assets/images/travel_icon_70x70_purple.png';
import MotorImage from '../../assets/images/bike_insurance.png';
import OtherImage from '../../assets/images/ageas_icons_master_v2_security_insurance_line.png';
import CloseImage from '../../assets/images/close.png';

const BannerIcon = [
  {
    id: 'car',
    value: 'Carinsurance',
    label: 'Car insurance',
    src: CarImage,
    website: '/carinsurance',
  },
  {
    id: 'home',
    value: 'Homeinsurance',
    label: 'Home insurance',
    src: HomeImage,
    website: '/homeinsurance',
  },
  {
    id: 'pet',
    value: 'PetInsurance',
    label: 'Pet insurance',
    src: PetImage,
    website: 'https://www.ageas.co.uk/make-a-claim/pet-insurance/',
  },
  {
    id: 'business',
    value: 'Businessinsurance',
    label: 'Business insurance',
    src: BusinessImage,
    website: 'https://www.ageas.co.uk/make-a-claim/business-insurance/',
  },
  {
    id: 'travel',
    value: 'Travelinsurance',
    label: 'Travel insurance',
    src: TravelImage,
    website: 'https://www.ageas.co.uk/make-a-claim/travel-insurance/',
  },
  {
    id: 'motor',
    value: 'Motorcycleinsurance',
    label: 'Motorcycle insurance',
    src: MotorImage,
    website: 'https://www.ageas.co.uk/make-a-claim/motorcycle-insurance/',
  },
  {
    id: 'other',
    value: 'Otherproducts',
    label: 'Other products',
    src: OtherImage,
    website: 'https://www.ageas.co.uk/make-a-claim/other-products/',
  },
];

const CarIcon = [
  {
    id: 'car',
    value: 'Carinsurance',
    label: 'Car insurance',
    src: CarInvertedImage,
    lbcolor: '#fff',
    bgcolor: '#8E419C',
  },
  {
    id: 'home',
    value: 'Homeinsurance',
    label: 'Home insurance',
    src: HomeImage,
    website: '/homeinsurance',
  },
  {
    id: 'pet',
    value: 'PetInsurance',
    label: 'Pet insurance',
    src: PetImage,
    website: 'https://www.ageas.co.uk/make-a-claim/pet-insurance/',
  },
  {
    id: 'business',
    value: 'Businessinsurance',
    label: 'Business insurance',
    src: BusinessImage,
    website: 'https://www.ageas.co.uk/make-a-claim/business-insurance/',
  },
  {
    id: 'travel',
    value: 'Travelinsurance',
    label: 'Travel insurance',
    src: TravelImage,
    website: 'https://www.ageas.co.uk/make-a-claim/travel-insurance/',
  },
  {
    id: 'motor',
    value: 'Motorcycleinsurance',
    label: 'Motorcycle insurance',
    src: MotorImage,
    website: 'https://www.ageas.co.uk/make-a-claim/motorcycle-insurance/',
  },
  {
    id: 'other',
    value: 'Otherproducts',
    label: 'Other products',
    src: OtherImage,
    website: 'https://www.ageas.co.uk/make-a-claim/other-products/',
  },
];

const HomeIcon = [
  {
    id: 'car',
    value: 'Carinsurance',
    label: 'Car insurance',
    src: CarImage,
    website: '/carinsurance',
  },
  {
    id: 'home',
    value: 'Homeinsurance',
    label: 'Home insurance',
    src: HomeInvertedImage,
    lbcolor: '#fff',
    bgcolor: '#8E419C',
  },
  {
    id: 'pet',
    value: 'PetInsurance',
    label: 'Pet insurance',
    src: PetImage,
    website: 'https://www.ageas.co.uk/make-a-claim/pet-insurance/',
  },
  {
    id: 'business',
    value: 'Businessinsurance',
    label: 'Business insurance',
    src: BusinessImage,
    website: 'https://www.ageas.co.uk/make-a-claim/business-insurance/',
  },
  {
    id: 'travel',
    value: 'Travelinsurance',
    label: 'Travel insurance',
    src: TravelImage,
    website: 'https://www.ageas.co.uk/make-a-claim/travel-insurance/',
  },
  {
    id: 'motor',
    value: 'Motorcycleinsurance',
    label: 'Motorcycle insurance',
    src: MotorImage,
    website: 'https://www.ageas.co.uk/make-a-claim/motorcycle-insurance/',
  },
  {
    id: 'other',
    value: 'Otherproducts',
    label: 'Other products',
    src: OtherImage,
    website: 'https://www.ageas.co.uk/make-a-claim/other-products/',
  },
];

const CarMobile = [
  {
    id: 'car',
    value: 'Carinsurance',
    label: 'Car insurance',
    src: CloseImage,
    lbcolor: '#fff',
    bgcolor: '#8E419C',
    website: '/',
    imgcon: 'T',
  },
];
const HomeMobile = [
  {
    id: 'Home',
    value: 'Homeinsurance',
    label: 'Home insurance',
    src: CloseImage,
    lbcolor: '#fff',
    bgcolor: '#8E419C',
    website: '/',
    imgcon: 'T',
  },
];

export { BannerIcon, CarIcon, HomeIcon, CarMobile, HomeMobile };
