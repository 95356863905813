import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { Icon, ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import { Form } from 'react-final-form';
import {
  SubText,
  BasicSection,
  TextColor,
  SummaryContainer,
  ButtonsBottomContainer,
  SettlementPaymentSeparator,
} from '../../StandardPageWrapper/StandardPageWrapperStyle';
import config from '../../../helpers/config';
import axiosHelper from '../../../helpers/axios';
import { checkFeatureSwitch } from '../../FeatureSwitch/FeatureSwitch';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import {
  dataLoadFailingError,
  getErrorMessage,
  checkBankDetails as checkBankDetailsError,
  InvalidBankDetailsHome,
  serverErrorHome,
} from '../../../helpers/errorMessages';

import BankDetails, {
  getBankDetailsValidationSchema,
} from '../../Forms/BankDetails/BankDetails';
import validateField from '../../../helpers/validationHelper';
import formatAmount from '../../../helpers/formatAmount';
import { HubContext, hubDataContext } from '../../../helpers/hubContextHelper';
import { closeNotificationsByCode } from '../../../helpers/hubNotificationsHelper';
import HubPayments from '../../HubPayments/HubPayments';

const ACCOUNT_NAME = 'accountName';
const ACCOUNT_SORT_CODE = 'sortCode';
const ACCOUNT_NUMBER = 'accountNumber';

const schema = {
  ...getBankDetailsValidationSchema(
    ACCOUNT_NAME,
    ACCOUNT_SORT_CODE,
    ACCOUNT_NUMBER,
  ),
};

const validateFields = (value, _allFields, meta) => {
  let error;
  if (schema[meta.name]) {
    error = validateField(value, meta, schema);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const Settlement = ({ claimReference }) => {
  const [dataLoadFail, setDataLoadFail] = useState(false);
  const axiosCancelToken = useRef(null);
  const [settlementData, setSettlementData] = useState(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkBankDetails, setCheckBankDetails] = useState(false);
  const [invalidBankDetails, setInvalidBankDetails] = useState(false);
  const [serverError, setServerError] = useState(false);
  const hubContext = useContext(HubContext);

  if (!checkFeatureSwitch('FEATURE_HOME_CLAIM_HUB_SETTLEMENT', ['version1'])) {
    return <>Coming soon...</>;
  }

  const displaySettlementAndPaymentDetails = () => {
    const displaySettlementOffers = () => {
      return (
        <>
          <SummaryContainer>
            <Icon
              className="icon-warning"
              brandIconSet="ageas"
              icon="tick-roundel"
              color="green"
              size={34}
            />
            <SubText>
              <strong>
                Settlement offer: <TextColor>{claimReference}</TextColor>
              </strong>
            </SubText>
          </SummaryContainer>
          <BasicSection>
            <SubText>
              We are pleased to be able to offer you a cash settlement of{' '}
              <strong>
                £{formatAmount(settlementData.paymentOffers[0].amount)}
              </strong>
              , which includes your excess deduction of{' '}
              <strong>
                £{formatAmount(settlementData.paymentOffers[0].excess)}
              </strong>
              .
            </SubText>
            <SubText>
              If you are happy with that, please enter your bank details below
              and click the &apos;Accept &amp; Submit&apos; button so that we
              can make a payment into your account. If you do not accept, please
              contact your claims handler via the secure &apos;Message
              Centre&apos;.
            </SubText>
          </BasicSection>
        </>
      );
    };

    const renderCheckBankDetailsError = () => {
      return (
        checkBankDetails && (
          <ThemedErrorMessage hasIcon>
            {checkBankDetailsError()}
          </ThemedErrorMessage>
        )
      );
    };

    const renderInvalidBankDetailsError = () => {
      return (
        invalidBankDetails && (
          <ThemedErrorMessage hasIcon>
            {InvalidBankDetailsHome()}
          </ThemedErrorMessage>
        )
      );
    };

    const renderServerErrorComponent = () => {
      return (
        serverError && (
          <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
        )
      );
    };

    const renderAcceptSubmitButton = () => {
      return (
        !invalidBankDetails && (
          <ButtonsBottomContainer>
            <ThemedButton secondary type="submit">
              Accept &amp; Submit
            </ThemedButton>
          </ButtonsBottomContainer>
        )
      );
    };

    const displaySuccessfulPaymentText = () => {
      return (
        <BasicSection>
          <SubText>Thanks!</SubText>
          <SubText>
            A payment of{' '}
            <strong>
              £{formatAmount(settlementData.paymentOffers[0].amount)}
            </strong>{' '}
            will be credited to your bank account within 3 working days.
          </SubText>
        </BasicSection>
      );
    };

    const buildRequestPayload = values => {
      return {
        claimReference,
        offerSequence: settlementData.paymentOffers[0].sequence,
        accountName: values.accountName,
        accountNumber: +values.accountNumber,
        sortCode: +values.sortCode,
      };
    };

    const acceptAndPayOffer = (values, payment) => {
      setInvalidBankDetails(false);
      setCheckBankDetails(false);
      setServerError(false);
      axiosCancelToken.current = axios.CancelToken.source();
      setIsLoading(true);

      axiosHelper
        .post(
          config.client.acceptAndPayOffer_endpoint,
          buildRequestPayload(values),
          {
            cancelToken: axiosCancelToken.current.token,
          },
        )
        .then(data => {
          setIsLoading(false);
          if (payment) {
            const { paymentSuccess = false, errorCode = '' } = data?.data || {};
            if (paymentSuccess && !errorCode) {
              setIsPaymentSuccess(true);
              closeNotificationsByCode(hubContext, hubDataContext, 'OF');
            } else if (errorCode === 'TooManyAttempts') {
              setInvalidBankDetails(true);
            } else if (errorCode === 'InvalidDetails') {
              setCheckBankDetails(true);
            } else {
              setServerError(true);
            }
          }
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            setServerError(true);
            setIsLoading(false);
          }
        });
    };

    const onSubmit = (values, payment) => {
      acceptAndPayOffer(values, payment);
    };

    const displayNoOfferPaymentText = () => {
      return (
        <SubText>
          You currently have no outstanding claim settlement offers or payments.
        </SubText>
      );
    };

    return (
      <Form onSubmit={values => onSubmit(values, true)}>
        {({ handleSubmit }) => (
          <form method="post" onSubmit={handleSubmit}>
            {!isEmpty(settlementData.paymentOffers) && !isPaymentSuccess && (
              <>
                {displaySettlementOffers()}
                <BankDetails
                  name={[ACCOUNT_NAME, ACCOUNT_SORT_CODE, ACCOUNT_NUMBER]}
                  validate={validateFields}
                  headerInfo=""
                />
                {renderCheckBankDetailsError()}
                {renderInvalidBankDetailsError()}
                {renderServerErrorComponent()}
                {renderAcceptSubmitButton()}
              </>
            )}
            {isPaymentSuccess && displaySuccessfulPaymentText()}
            {!isEmpty(settlementData.paymentOffers) &&
              !isEmpty(settlementData.payments) && (
                <SettlementPaymentSeparator />
              )}
            {!isEmpty(settlementData.payments) && (
              <HubPayments payments={settlementData.payments} />
            )}
            {isEmpty(settlementData.paymentOffers) &&
              isEmpty(settlementData.payments) &&
              displayNoOfferPaymentText()}
          </form>
        )}
      </Form>
    );
  };

  const getSettlementDetails = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .get(
        `${
          config.client.getHomeHubSettlementData_endpoint
        }?claimReference=${encodeURIComponent(claimReference)}`,
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(({ data }) => {
        setIsLoading(false);
        setSettlementData(data);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setDataLoadFail(true);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSettlementDetails();
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  if (dataLoadFail) {
    return (
      <ThemedErrorMessage hasIcon>{dataLoadFailingError()}</ThemedErrorMessage>
    );
  }

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      {settlementData && displaySettlementAndPaymentDetails()}
    </>
  );
};

export default Settlement;

Settlement.propTypes = {
  claimReference: PropTypes.string,
};
Settlement.defaultProps = {
  claimReference: undefined,
};
