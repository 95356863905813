import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';

export const carpetDamagedSchema = damagedCarpetFieldName => {
  return {
    [damagedCarpetFieldName]: stringRequired(
      [damagedCarpetFieldName],
      'Please select a room',
    ),
  };
};

const ROOM_LIST = [
  {
    value: 'hall',
    label: 'Hall',
  },
  {
    value: 'stairs',
    label: 'Stairs',
  },
  {
    value: 'landing',
    label: 'Landing',
  },
  {
    value: 'livingRoom',
    label: 'Living room',
  },
  {
    value: 'diningRoom',
    label: 'Dining room',
  },
  {
    value: 'kitchen',
    label: 'Kitchen',
  },
  {
    value: 'bedroom',
    label: 'Bedroom',
  },
  {
    value: 'bathroom',
    label: 'Bathroom',
  },
  {
    value: 'conservatory',
    label: 'Conservatory',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const CarpetDamagedRoom = ({ name, ...rest }) => {
  const DAMAGED_CARPET_FIELD_NAME = name;

  return (
    <StandardRadio
      name={DAMAGED_CARPET_FIELD_NAME}
      title="Please tell us which room the damaged carpet is in"
      secondaryTitle="If more than one room is damaged, please tell us the room with the largest carpet."
      options={ROOM_LIST}
      medium
      vertical
      {...rest}
    />
  );
};

CarpetDamagedRoom.propTypes = {
  name: PropTypes.string,
};
CarpetDamagedRoom.defaultProps = {
  name: undefined,
};

export default CarpetDamagedRoom;
