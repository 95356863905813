import React from 'react';
import FaqP from '../../FaqP';

export const extentDamageTitle = 'What if my property is not safe to live in?';
export const extentDamageContent = (
  <>
    <FaqP>
      We understand that some customers like to stay in their own homes where
      possible, so we can provide facilities like temporary kitchens and
      bathrooms to enable you to do so. If you do have to leave the property, we
      will help you make those arrangements, whether this is staying with
      relatives or checking into suitable alternative accommodation.
    </FaqP>
    <FaqP>
      For more information on alternative accommodation cover please refer to
      your policy documentation.
    </FaqP>
  </>
);
