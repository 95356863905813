import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemedHint } from 'ageas-ui-components';

const HintWrapper = styled.div`
  position: relative;
  display: inline;
`;

const StandardHint = ({ field, hint, hintProps }) => {
  const newHintProps = useMemo(
    () => ({
      isHintList: true,
      ...hintProps,
    }),
    [hintProps],
  );
  return (
    <HintWrapper>
      <ThemedHint
        field={field}
        primary
        separateIcon
        noFloat
        trueButtons
        iconMargin="0 0 0 4px"
        {...newHintProps}
      >
        {hint}
      </ThemedHint>
    </HintWrapper>
  );
};

StandardHint.propTypes = {
  field: PropTypes.string.isRequired,
  hint: PropTypes.node.isRequired,
  hintProps: PropTypes.shape({}),
};

StandardHint.defaultProps = {
  hintProps: {},
};

export default StandardHint;
