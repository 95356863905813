import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemedErrorMessage, ThemedParagraph } from 'ageas-ui-components';
import MinusIcon from '../../assets/icons/claimTypes/ui-icon-remove.svg';
import BackgroundImageIcon from '../../assets/icons/claimTypes/ui-Icon-bg-remove.svg';
import stringArrayRequired from '../../helpers/validation/stringArrayRequired';
import buildingDamageItem from '../../helpers/buildingDamagedItem';
import BuildingGardenMobile from './BuildingGardenMobile';
import {
  DisplayArea,
  ButtonContainer,
  ButtonStyle,
  RemoveIcon,
  BackgroundIcon,
  IconContainer,
  ErrorContainer,
} from './BuildingGardenStyle';
import ButtonLink from '../ThemedButtonLink/ThemedButtonLink';
import BuildingGardenDesktop from '../BuildingGardenDesktop/BuildingGardenDesktop';
import StandardPopup from '../StandardPopup/StandardPopup';

const DEFAULT_FIELD_NAME = 'damagedAreas';

export const getBuildingGardenValidationSchema = (
  fieldName = DEFAULT_FIELD_NAME,
) => {
  return {
    [fieldName]: stringArrayRequired(
      fieldName,
      'Please select at least one area of damage',
    ),
  };
};

const BuildingGarden = ({ input = {}, meta = {} }) => {
  const [selectedItems, setSelectedItems] = useState(input.value || []);
  const [modal, setModal] = useState(false);

  const setSelectedItemsFull = newValue => {
    setSelectedItems([...newValue]);
    if (input.onChange) {
      input.onChange([...newValue]);
    }
  };

  const toggleChecked = id => {
    const selectedItemIndex = selectedItems.findIndex(item => item === id);
    if (selectedItemIndex >= 0) {
      selectedItems.splice(selectedItemIndex, 1);
    } else {
      const newItem = buildingDamageItem.find(item => item.id === id);
      if (newItem) {
        selectedItems.push(newItem.id);
      }
    }
    setSelectedItemsFull([...selectedItems]);
  };

  useEffect(() => {
    let newValue = [];
    if (input.value?.length) {
      newValue = buildingDamageItem
        .filter(item => input.value.some(id => id === item.id))
        .map(item => item.id);
    }
    setSelectedItems(newValue);
  }, [input.value]);

  const removeStormItems = id => {
    const newList = [...selectedItems.filter(item => item !== id)];
    setSelectedItemsFull(newList);
  };
  const renderSelectedValue = () => {
    return selectedItems.map(id => {
      const data = buildingDamageItem.find(item => item.id === id);
      return (
        <ButtonContainer key={id}>
          <ButtonStyle
            type="button"
            aria-label={`Remove ${data.name}`}
            onClick={() => removeStormItems(id)}
          >
            {data.name}
            <IconContainer>
              <BackgroundIcon src={BackgroundImageIcon} />
              <RemoveIcon src={MinusIcon} />
            </IconContainer>
          </ButtonStyle>
        </ButtonContainer>
      );
    });
  };

  const onNotCoveredClick = event => {
    setModal(true);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <div>
        <strong>Storm damage location(s)</strong>
      </div>
      <div>
        Please confirm the area(s) of your building that have been damaged on
        the image below. Make sure you confirm all areas of damage.
      </div>
      <div>
        <ButtonLink primary onClick={onNotCoveredClick}>
          Areas that are not covered
        </ButtonLink>
      </div>
      {modal && (
        <StandardPopup
          buttons={[
            {
              onClick: () => setModal(false),
            },
          ]}
        >
          <ThemedParagraph>
            Areas such as fences, gates, swimming pools and hot tub covers are
            not covered under the Storm peril, please check your policy wording.
          </ThemedParagraph>
        </StandardPopup>
      )}
      <BuildingGardenDesktop
        damageItem={buildingDamageItem}
        toggleChecked={toggleChecked}
        selectedItems={selectedItems}
      />
      <BuildingGardenMobile
        buildingDamageItem={buildingDamageItem}
        selectedItemCall={homeData => setSelectedItemsFull([...homeData])}
        selectedItems={selectedItems}
      />
      {meta?.error && meta?.touched && (
        <ErrorContainer>
          <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
        </ErrorContainer>
      )}
      <div role="group" aria-label="Remove damage item(s)">
        <div>Location(s) of storm damage</div>
        <DisplayArea>{renderSelectedValue()}</DisplayArea>
      </div>
    </>
  );
};

export default BuildingGarden;

BuildingGarden.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({}),
};

BuildingGarden.defaultProps = {
  input: undefined,
  meta: undefined,
};
