import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { ThemedTitle, ThemedButton } from 'ageas-ui-components';
import {
  stringRequired,
  objectRequired,
} from 'ageasvalidation/lib/schemas/Required';
import { registrationNumber } from 'ageasvalidation/lib/schemas/RegistrationNumber';
import StandardDropdown from '../../components/Forms/StandardDropdown/StandardDropdown';
import PersonFieldArray from '../../components/Forms/Person/PersonFieldArray';
import { validateField } from '../../helpers/validationHelper';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';
import StandardInput from '../../components/Forms/StandardInput/StandardInput';
import StandardRadio from '../../components/Forms/StandardRadio/StandardRadio';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import ThemedLinkButton from '../../components/ThemedLinkButton/ThemedLinkButton';
import {
  MotorFormContext,
  formNames,
} from '../../helpers/motorFormStateHelper';

import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import PassengerModalForm from './PassengerModalForm';
import BreadCrumb from '../../helpers/MotorClaimBreadcrumb';
import { hideThirdParty } from '../../helpers/SingleDriveCircumstanceHelper';
import { MotorClaimsTelLink } from '../../helpers/ageasTelephoneNumbers';
import { motorReferLink } from '../../helpers/businessHoursData';
import { checkBusinessHours } from '../../helpers/checkBusinessHours';

const Theme = getTheme(brand);

const PageContainer = styled.div`
  max-width: 1100px;
  margin: auto;
`;
const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const yesNoRadioOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];
const yesNoUnknownRadioOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'unknown',
    label: 'Unknown',
  },
];

const dropDownOptions = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

const tooManyPassengersMessage = type => {
  const businessHours = checkBusinessHours('motor');
  return (
    <>
      If there were more than 5 passengers in {type} vehicle, please{' '}
      {businessHours && (
        <>
          call: <MotorClaimsTelLink />
        </>
      )}
      {!businessHours && (
        <>
          contact us using this form:{' '}
          <a href={motorReferLink}>Claim referral form</a>
        </>
      )}
    </>
  );
};

// Invalid schema error usually means a field is being validated with
// no schema in here

const passengersSchema = {
  phHasPassengers: objectRequired(
    ['phHasPassengers'],
    'You cannot reduce the number of passengers to be less than the number of passengers you have entered details for. If you wish to reduce the number of passengers, please delete the appropriate passenger details.',
  ),
  tpHasPassengers: objectRequired(
    ['tpHasPassengers'],
    'You cannot reduce the number of passengers to be less than the number of passengers you have entered details for. If you wish to reduce the number of passengers, please delete the appropriate passenger details.',
  ),
};

const schema = {
  tpVehicleKnown: stringRequired(
    ['tpVehicleKnown'],
    'Please select one of the options',
  ),
  tpVehicleDriverKnown: stringRequired(
    ['tpVehicleDriverKnown'],
    'Please select one of the options',
  ),
  // tpVehicleVRN: stringRequired(
  //   ['tpVehicleVRN'],
  //   'Please enter the vehicle registration number',
  // ),
  tpVehicleIsForeign: stringRequired(
    ['tpVehicleIsForeign'],
    'Please select one of the options',
  ),
  tpVehicleIsTaxi: stringRequired(
    ['tpVehicleIsTaxi'],
    'Please select one of the options',
  ),
  tpHasPassengers: stringRequired(
    ['tpHasPassengers'],
    'Please select one of the options',
  ),
  phHasPassengers: stringRequired(
    ['phHasPassengers'],
    'Please select one of the options',
  ),
};

const vrnUkRegSchema = {
  tpVehicleVRN: registrationNumber(
    ['tpVehicleVRN'],
    'Please enter a valid UK registration number',
  ),
};

const validateFieldSchema = (value, allValues, meta) => {
  let result;

  if (schema[meta.name]) {
    result = validateField(value?.trim(), meta, schema);
    if (result) {
      return result;
    }
  }

  const phCount = allValues.phPassengers?.length || 0;
  if (meta.name === 'phHasPassengers') {
    if (
      value &&
      allValues.phHasPassengers !== phCount &&
      allValues.phHasPassengers < phCount &&
      passengersSchema[meta.name]
    ) {
      return (result = validateField(value, meta, passengersSchema));
    }
  }
  const tpCount = allValues.tpPassengers?.length || 0;
  if (meta.name === 'tpHasPassengers') {
    if (
      value &&
      allValues.tpHasPassengers !== tpCount &&
      allValues.tpHasPassengers < tpCount &&
      passengersSchema[meta.name]
    ) {
      return (result = validateField(value, meta, passengersSchema));
    }
  }

  // VRN specific validation
  if (meta.name === 'tpVehicleVRN') {
    // Not a foreign vehicle? Registration must be a UK reg
    // (ignoring spaces)
    if (
      value &&
      allValues.tpVehicleIsForeign === 'no' &&
      vrnUkRegSchema[meta.name]
    ) {
      result = validateField(
        value?.trim().replace(' ', ''),
        meta,
        vrnUkRegSchema,
      );
      if (result) {
        return result;
      }
    }
  }

  return null;
};

const InvolvedPartiesDetail = () => {
  const { dataState, updateDataState } = useContext(MotorFormContext);
  const [pageFormState, setPageFormState] = useState({});
  const [navigatePath, setNavigatePath] = useState(null);
  const [passengerModal, setPassengerModal] = useState(null);
  const [incidentDetailPageFormState, setIncidentDetailPageFormState] =
    useState({});

  // This contains all logic for controling field display
  // (and TP vehicle section display)
  const getRenderedFieldsList = values => {
    const renderedFields = {
      phHasPassengers: true,
    };

    // ph questions
    const phPassengers = values.phPassengers?.length || 0;
    const phHasPassengers = parseInt(values.phHasPassengers, 10) || 0;

    if (phPassengers > 0 || phHasPassengers > 0) {
      renderedFields.phPassengers = true;
    }

    // tp questions
    if (
      values.phHasPassengers !== undefined &&
      !hideThirdParty(incidentDetailPageFormState.circumstanceCode)
    ) {
      renderedFields.renderTPFields = true; // Section, not field
      renderedFields.tpVehicleKnown = true;

      // tp vehicle questions
      if (
        values.tpVehicleKnown === 'yes' ||
        values.tpVehicleKnown === 'yesNoVRN'
      ) {
        if (values?.tpVehicleKnown === 'yes') {
          renderedFields.tpVehicleVRN = true;
        }
        renderedFields.tpVehicleIsForeign = true;
        renderedFields.tpVehicleIsTaxi = true;
        renderedFields.tpDriverFields = true;

        // tp passenger questions
        const tpPassengers = values.tpPassengers?.length || 0;
        const tpHasPassengers = parseInt(values.tpHasPassengers, 10) || 0;
        renderedFields.tpHasPassengers = true;
        if (tpPassengers > 0 || tpHasPassengers > 0) {
          renderedFields.tpPassengers = true;
        }
      }
    }

    return renderedFields;
  };

  // This takes a form values object, and removes any fields that are not
  // rendered according to getRenderedFieldsList
  const sanitiseFormObject = (values = {}) => {
    const renderedFields = getRenderedFieldsList(values);
    const sanitisedValues = { ...values };

    // For every field in values object. If not a rendered field, remove it
    Object.keys(values).forEach(key => {
      if (!renderedFields[key]) {
        delete sanitisedValues[key];
      }
    });

    return sanitisedValues;
  };

  const closePassengerModal = () => {
    setPassengerModal(null);
  };

  useEffect(() => {
    // We need RFF Form initial values to not change, so cannot populate direct
    // from context. So clone to local state variable on mount and default
    // using that
    setPageFormState({ ...dataState?.involvedPartiesDetail });
    setIncidentDetailPageFormState({
      ...dataState?.motorIncidentDetail,
    });
    window.scrollTo(0, 0); // Scroll to top of page on load

    // Close modal on unmount (restore document body style)
    return () => {
      closePassengerModal();
    };
  }, []);

  const onSubmit = values => {
    // Form is valid, save context and navigate to next page
    // sanitiseFormObject() removes values for non-rendered fields
    const stateValues = { ...sanitiseFormObject(values), formIsValid: true };
    updateDataState(formNames.involvedPartiesDetail, stateValues);
    setNavigatePath('/motorclaim/confirmation');
  };

  const beforeSubmit = (event, values) => {
    const stateValues = { ...sanitiseFormObject(values) };
    delete stateValues.formIsValid;
    updateDataState(formNames.involvedPartiesDetail, stateValues);
  };

  const openPassengerModal = values => {
    setPassengerModal(values);
  };

  const onAdd = (push, title) => {
    const onAddSubmit = values => {
      push(values);
      closePassengerModal();
    };
    openPassengerModal({
      onSubmit: onAddSubmit,
      initialValues: {},
      title,
    });
  };
  const onEdit = (index, initialValues, update, title) => {
    const onEditSubmit = values => {
      update(index, values);
      closePassengerModal();
    };
    openPassengerModal({
      index,
      onSubmit: onEditSubmit,
      initialValues,
      title,
    });
  };

  const renderPassengerModal = () => {
    if (passengerModal === null) {
      return null;
    }
    return (
      <PassengerModalForm
        {...passengerModal}
        onCancel={() => closePassengerModal()}
      />
    );
  };

  // Do not direct call. Call via
  //    form.mutators.fieldUpdateMutator(fieldName,value)
  const fieldUpdateMutator = ([fieldName, value], state, utils) => {
    utils.changeValue(state, fieldName, () => value);
  };

  const renderTPPassengerFields = (renderedFields, values) => {
    const hasPassengers = parseInt(values.tpHasPassengers, 10) || 0;
    const passengerCountSecondaryLabel = tooManyPassengersMessage('their');
    return (
      <>
        {renderedFields.tpHasPassengers && (
          <StandardDropdown
            name="tpHasPassengers"
            label="How many passengers were in the other vehicle?"
            aria-label="How many passengers were in the other vehicle?"
            secondaryLabel={passengerCountSecondaryLabel}
            options={dropDownOptions}
            validate={validateFieldSchema}
            alwaysShowError={values.tpHasPassengers !== undefined}
            blockContainerProps={{ small: true, minWidth: '228px' }}
          />
        )}
        {renderedFields.tpPassengers > 0 && (
          <PersonFieldArray
            name="tpPassengers"
            label="Please enter the details of their passengers where known"
            onAdd={onAdd}
            onEdit={onEdit}
            addLabel="Add passenger (their vehicle)"
            editLabel="Edit passenger (their vehicle)"
            showAddButton={false}
            defaultRows={hasPassengers}
          />
        )}
      </>
    );
  };

  const renderPHPassengerFields = (renderedFields, values) => {
    const hasPassengers = parseInt(values.phHasPassengers, 10) || 0;
    const passengerCountSecondaryLabel = tooManyPassengersMessage('your');
    return (
      <>
        {renderedFields.phHasPassengers && (
          <StandardDropdown
            name="phHasPassengers"
            label="How many passengers were in your vehicle?"
            aria-label="How many passengers were in your vehicle?"
            secondaryLabel={passengerCountSecondaryLabel}
            options={dropDownOptions}
            validate={validateFieldSchema}
            alwaysShowError={values.phPassengers !== undefined}
            blockContainerProps={{ small: true, minWidth: '228px' }}
          />
        )}
        {renderedFields.phPassengers && (
          <PersonFieldArray
            name="phPassengers"
            label="Please enter the details of your passengers where known"
            onAdd={onAdd}
            onEdit={onEdit}
            addLabel="Add passenger (your vehicle)"
            editLabel="Edit passenger (your vehicle)"
            showAddButton={false}
            defaultRows={hasPassengers}
          />
        )}
      </>
    );
  };

  const renderTPDriverFields = renderedFields => {
    return (
      renderedFields.tpDriverFields && (
        <PersonFieldArray
          name="tpDriverFields"
          label="Please enter the details of the driver where known"
          onAdd={onAdd}
          onEdit={onEdit}
          addLabel="Driver details (their vehicle)"
          editLabel="Edit driver (their vehicle)"
          showAddButton={false}
          defaultRows={1}
        />
      )
    );
  };
  const renderTPVehicleFields = (renderedFields, values, form) => {
    // Cannot use react final form parse and format props to do this, as they
    // are currently bugged and cause controlled-uncontrolled console errors
    const onVRNChange = event => {
      // If field has a value, convert it to upper case
      if (event.target.value) {
        form.mutators.fieldUpdateMutator(
          'tpVehicleVRN',
          event.target.value.toUpperCase(),
        );
      }
    };

    return (
      <>
        {renderedFields.tpVehicleVRN && (
          <StandardInput
            name="tpVehicleVRN"
            label="If known, what is the other vehicle's registration?"
            xSmall
            maxLength="10"
            onChange={onVRNChange}
            validate={validateFieldSchema}
            alwaysShowError={values.tpVehicleVRN !== undefined}
          />
        )}

        {renderedFields.tpVehicleIsForeign && (
          <StandardRadio
            vertical
            name="tpVehicleIsForeign"
            validate={validateFieldSchema}
            title="Is the vehicle foreign (registered outside of the UK)?"
            options={yesNoUnknownRadioOptions}
          />
        )}

        {renderedFields.tpVehicleIsTaxi && (
          <StandardRadio
            vertical
            name="tpVehicleIsTaxi"
            validate={validateFieldSchema}
            title="Is the vehicle a taxi?"
            options={yesNoUnknownRadioOptions}
          />
        )}

        {renderTPDriverFields(renderedFields)}
        {renderTPPassengerFields(renderedFields, values)}
      </>
    );
  };

  const renderTPFields = (renderedFields, values, form) => {
    return (
      renderedFields.renderTPFields && (
        <>
          <hr />
          <Spacer />
          <ThemedTitle medium>Their vehicle</ThemedTitle>
          <Spacer />
          {renderedFields.tpVehicleKnown && (
            <StandardRadio
              vertical
              name="tpVehicleKnown"
              validate={validateFieldSchema}
              title="Was there another vehicle involved?"
              options={yesNoRadioOptions}
            />
          )}

          {renderTPVehicleFields(renderedFields, values, form)}
        </>
      )
    );
  };

  return (
    <ThemeProvider theme={Theme}>
      {renderPassengerModal()}
      <BrandHeader />
      <BreadCrumb activeItem="Involved parties" />
      <main>
        <NavigateTo path={navigatePath} />
        <PageContainer>
          <StandardPanel
            primary
            title="Involved parties details"
            padding="23px 21px 0px 21px"
            panelPadding="0px 0px 16px 0px"
          >
            <Form
              onSubmit={onSubmit}
              mutators={{
                fieldUpdateMutator,
                ...arrayMutators,
              }}
              initialValues={pageFormState}
            >
              {({ handleSubmit, values, form }) => {
                const renderedFields = getRenderedFieldsList(values);
                return (
                  <form
                    method="post"
                    onSubmit={handleSubmit}
                    onSubmitCapture={event => {
                      beforeSubmit(event, values);
                    }}
                  >
                    <ThemedTitle medium>Your vehicle</ThemedTitle>
                    <Spacer />
                    <Spacer>
                      The vehicle on this policy is:{' '}
                      <strong>
                        {incidentDetailPageFormState?.vehicleRegistration ||
                          '--'}
                      </strong>
                    </Spacer>

                    {renderPHPassengerFields(renderedFields, values)}
                    {renderTPFields(renderedFields, values, form)}

                    <BottomButtonsContainer>
                      <ThemedLinkButton
                        small
                        to="/motorclaim/contactdetails"
                        secondary
                        inverted
                        type="button"
                      >
                        Back
                      </ThemedLinkButton>
                      <ThemedButton small secondary type="submit">
                        Continue
                      </ThemedButton>
                    </BottomButtonsContainer>
                  </form>
                );
              }}
            </Form>
          </StandardPanel>
        </PageContainer>
      </main>
      <BrandFooter />
    </ThemeProvider>
  );
};

export default InvolvedPartiesDetail;
