import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemedErrorMessage } from 'ageas-ui-components';
import FieldSpinner from '../Forms/FieldSpinner/FieldSpinner';
import DropdownRadio from '../Forms/DropdownRadio/DropdownRadio';
import {
  NoActivePolicyErrorHome,
  otherPolicyHolder,
} from '../../helpers/errorMessages';

// We now have the onchange item only firing when one of the params changes
// SO can we simply always do a request in this instance, and use abort
// to cancel the existing if present?
// No, we need to check keys because of context.

export const OTHER_UNKNOWN_POLICYHOLDER = '000';

const Heading = styled.div`
  font-weight: normal;
  font-size: 19px;
  margin-bottom: 10px;
  margin-top: 32px;
`;

const dropdownProps = {
  containerProps: {
    medium: true,
  },
};

const PolicyHolderField = ({
  dataContextObject,
  dataContextName,
  selectedOption,
  title,
  ...props
}) => {
  const { dataState: policyDataContext } = useContext(dataContextObject);
  const policyDetailsContext = { ...policyDataContext[dataContextName] };

  const buildOptionsList = () => {
    const optionsList =
      policyDetailsContext?.data?.policyHolders?.map(val => {
        return {
          label: val.name,
          value: val.clientNumber,
        };
      }) || [];
    optionsList.push({
      label: 'Other',
      value: OTHER_UNKNOWN_POLICYHOLDER,
    });

    return optionsList;
  };

  const renderField = () => {
    return (
      <>
        <Heading>{title}</Heading>
        <DropdownRadio
          RoundBorder
          dropdownProps={dropdownProps}
          {...props}
          selectedOption={selectedOption}
          title={title}
          options={buildOptionsList()}
        />
      </>
    );
  };

  // No status yet or no key provided, return nothing
  if (!policyDetailsContext.status || policyDetailsContext.status === 'nokey') {
    return null;
  }

  // Is loading, display spinner - no title
  if (policyDetailsContext.status === 'loading') {
    return <FieldSpinner />;
  }

  // Render nothing for error (calling component must handle error message)
  if (policyDetailsContext.status === 'error') {
    return null;
  }

  // Render not found error - no header, just error
  if (
    policyDetailsContext.status === 'loaded' &&
    !policyDetailsContext?.data?.policyHolders?.length
  ) {
    return (
      <ThemedErrorMessage hasIcon>
        {NoActivePolicyErrorHome()}
      </ThemedErrorMessage>
    );
  }

  // Render field
  return (
    <>
      {renderField()}
      {selectedOption === OTHER_UNKNOWN_POLICYHOLDER && (
        <ThemedErrorMessage hasIcon>{otherPolicyHolder()}</ThemedErrorMessage>
      )}
    </>
  );
};

PolicyHolderField.defaultProps = {
  title: '',
  selectedOption: undefined,
};

PolicyHolderField.propTypes = {
  title: PropTypes.string,
  selectedOption: PropTypes.string,
  dataContextObject: PropTypes.instanceOf(Object).isRequired,
  dataContextName: PropTypes.string.isRequired,
};

export default PolicyHolderField;
