import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ThemedErrorMessage } from 'ageas-ui-components';
import axiosHelper from '../../helpers/axios';
import {
  ErrorTextContainer,
  PageSection,
} from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import {
  MotorClaimContextNew,
  motorClaimDataContext,
} from '../../helpers/motorClaimHelperNew';
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import { genericErrorCallUsMotor } from '../../helpers/errorMessages';
import { consoleError } from '../../helpers/consoleLog';
import config from '../../helpers/config';
import ThemedLinkButton from '../../components/ThemedLinkButton/ThemedLinkButton';
import { mapServerToLocal } from '../../helpers/motorClaimNewDataMapper/motorClaimNewDataMapper';
import { getStageByName } from './motorClaimNewHelpers';

const cancelAxios = axiosCancelToken => {
  if (axiosCancelToken?.current?.cancel) {
    axiosCancelToken.current.cancel();
  }
};

const getClaimData = (axiosCancelToken, claimReference, policyReference) => {
  cancelAxios(axiosCancelToken);
  /* eslint-disable-next-line no-param-reassign */
  axiosCancelToken.current = axios.CancelToken.source();
  const params = new URLSearchParams([
    ['claimReference', claimReference],
    ['policyReference', policyReference],
  ]);
  return axiosHelper.get(
    `${
      config.client.getContinuableMotorClaimData_endpoint
    }?${params.toString()}`,
    {
      cancelToken: axiosCancelToken.current.token,
    },
  );
};

const ContinueMotorClaim = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoadFail, setDataLoadFail] = useState(false);
  const historyHook = useHistory();
  const { dataState, updateDataState } = useContext(MotorClaimContextNew);
  const axiosCancelToken = useRef(null);

  // Onload actions
  useEffect(() => {
    const { claimReference, policyReference } = dataState?.claimDetails || {};

    // No claim ref? Redirect to history
    if (!claimReference || !policyReference) {
      historyHook.replace('/motorclaim/history');
      return undefined;
    }

    // Request claim entry data from database

    getClaimData(axiosCancelToken, claimReference, policyReference)
      .then(({ data }) => {
        updateDataState(
          motorClaimDataContext.claimEntryData,
          mapServerToLocal(data),
        );
        const url =
          getStageByName(data.meta.stage)?.path || '/motorclaim/history';
        historyHook.replace(url);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          consoleError(error);
          setDataLoadFail(true);
          setIsLoading(false);
        }
      });

    return () => {
      cancelAxios(axiosCancelToken);
    };
  }, []);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <StandardPageWrapper lob="motor">
        <PageSection>
          {dataLoadFail && (
            <div>
              <ErrorTextContainer>
                <ThemedErrorMessage hasIcon>
                  {genericErrorCallUsMotor('motor')}
                </ThemedErrorMessage>
              </ErrorTextContainer>
              <ThemedLinkButton
                small
                to="/motorclaim/history"
                secondary
                inverted
                type="button"
              >
                Back
              </ThemedLinkButton>
            </div>
          )}
        </PageSection>
      </StandardPageWrapper>
    </>
  );
};

export default ContinueMotorClaim;
