import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';

export const freezerTimelineSchema = freezerTimelineFieldName => {
  return {
    [freezerTimelineFieldName]: stringRequired(
      [freezerTimelineFieldName],
      'Please select an approximate age of your freezer',
    ),
  };
};

const FREEZER_TIMELINE_LIST = [
  {
    value: 'lessThanAYear',
    label: 'Less than a year',
  },
  {
    value: 'oneYear',
    label: '1 year',
  },
  {
    value: 'twoYears',
    label: '2 years',
  },
  {
    value: 'threeYears',
    label: '3 years',
  },
  {
    value: 'fourYears',
    label: '4 years',
  },
  {
    value: 'fivePlus',
    label: '5+ years',
  },
];

const FreezerTimeline = ({ name, ...rest }) => {
  const FREEZER_FIELD_NAME = name;
  return (
    <StandardRadio
      name={FREEZER_FIELD_NAME}
      title="Approximately how old is your freezer?"
      options={FREEZER_TIMELINE_LIST}
      medium
      vertical
      {...rest}
    />
  );
};

FreezerTimeline.propTypes = {
  name: PropTypes.string,
};
FreezerTimeline.defaultProps = {
  name: undefined,
};

export default FreezerTimeline;
