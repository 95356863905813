import React, { useRef } from 'react';
import { ThemedLabel } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';
import DropdownRadio from '../DropdownRadio/DropdownRadio';

// For documentation see:
// /docs/components/Forms/StandardDropdown/StandardDropdown.md

export const getStandardDropdownSchema = (fieldName, error) => {
  return {
    [fieldName]: stringRequired(
      [fieldName],
      error || 'Please select an option',
    ),
  };
};

const StandardDropdownRadio = ({
  id,
  name,
  label,
  secondaryLabel,
  validate,
  alwaysShowError,
  options,
  children,
  onChange,
  beforeBlur,
  fieldProps,
  small,
  medium,
  large,
  blockContainerProps,
  dropdownProps,
  ...props
}) => {
  const elementId = useRef(id);
  if (!elementId.current) {
    elementId.current = `StandardDropdownRadio-${name}-${_uniqueId()}`;
  }

  const onSelectChange = (event, input) => {
    input.onChange(event);
    onChange(event);
  };

  const onSelectBlur = (event, input) => {
    if (!beforeBlur || beforeBlur(event) !== false) input.onBlur(event);
  };

  const containerProps = {
    small,
    medium,
    large,
  };

  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input, meta }) => (
        <>
          <ThemedLabel fieldName={elementId.current}>
            {label}
            {secondaryLabel && (
              <>
                <br />
                <small>{secondaryLabel}</small>
              </>
            )}
          </ThemedLabel>
          <FieldInnerBlockContainer marginTop="0px" {...blockContainerProps}>
            <DropdownRadio
              {...props}
              options={options}
              id={elementId.current}
              {...input}
              fieldName={input.name}
              onBlur={event => {
                onSelectBlur(event, input);
              }}
              selectedOption={input.value}
              onChange={event => {
                onSelectChange(event, input);
              }}
              errorMsg={
                meta.error && (meta.touched || alwaysShowError)
                  ? meta.error
                  : undefined
              }
              containerProps={containerProps}
              dropdownProps={{
                'aria-label': label,
                ...dropdownProps,
              }}
            />
          </FieldInnerBlockContainer>
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardDropdownRadio.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validate: PropTypes.func,
  alwaysShowError: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  onChange: PropTypes.func,
  beforeBlur: PropTypes.func,
  fieldProps: PropTypes.shape({}),
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  blockContainerProps: PropTypes.shape({}),
  dropdownProps: PropTypes.shape({}),
};

StandardDropdownRadio.defaultProps = {
  id: undefined,
  label: '',
  secondaryLabel: undefined,
  validate: () => {},
  alwaysShowError: false,
  options: [],
  children: undefined,
  onChange: () => {},
  beforeBlur: undefined,
  fieldProps: {},
  small: undefined,
  medium: undefined,
  large: undefined,
  blockContainerProps: undefined,
  dropdownProps: undefined,
};

export default StandardDropdownRadio;
