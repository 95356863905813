import React from 'react';
import { motorClaimContextData } from '../../helpers/motorClaimHelperNew';
import carImage from '../../assets/images/car_insurance.png';
import { getClaimTypeByCode } from '../../helpers/motorClaimTypeDescriptions';
import {
  MOTOR_CLAIM_STATUSES,
  getMotorClaimStatusByCode,
} from '../../helpers/motorClaimStatusDescriptions';
import config from '../../helpers/config';
import { MotorClaimsTelLink } from '../../helpers/ageasTelephoneNumbers';
import ClaimsHistory from './ClaimsHistory';
import StandardP from '../../components/StandardTags/P';
import ThemedLinkButton from '../../components/ThemedLinkButton/ThemedLinkButton';

const getStatusDescMotor = (status, claim) => {
  if (
    claim.isConfirmed === false &&
    status === MOTOR_CLAIM_STATUSES.OPEN.code
  ) {
    return MOTOR_CLAIM_STATUSES.PROCESSING.capitalise;
  }
  if (status === MOTOR_CLAIM_STATUSES.INCOMPLETE.code) {
    return MOTOR_CLAIM_STATUSES.NOT_SUBMITTED.capitaliseFirst;
  }
  return (
    getMotorClaimStatusByCode(status)?.capitalise ||
    MOTOR_CLAIM_STATUSES.OPEN.capitalise
  );
};

const entryMapper = (lob, claim) => {
  return {
    originalData: claim,
    ...claim,
    lossDate: claim.incidentDate,
    lob,
    icon: carImage,
    iconAlt: 'Car',
    claimType:
      getClaimTypeByCode(claim.claimType)?.capitalise || claim.claimType,
    claimStatusDesc: getStatusDescMotor(claim.statusCode, claim),
    isOpen:
      claim.statusCode === MOTOR_CLAIM_STATUSES.OPEN.code && claim.isConfirmed,
    isClickable: claim.continuable,
    vrn: claim.vrn,
  };
};

const renderSubText = claims => {
  return (
    <>
      You have made the following motor claims which are still in progress or
      have been closed within the last 12 months. If you would like to talk to
      us about any of them, please call the Claims Team on{' '}
      <MotorClaimsTelLink suffix="." />
      {Array.isArray(claims) && !claims.some(claim => claim.continuable) && (
        <>
          <StandardP>
            If you&apos;d like to make a new claim, please click &apos;Make a
            new claim&apos;.
          </StandardP>
          <ThemedLinkButton
            primary
            type="button"
            to="/motorclaim/newclaim/initialise"
          >
            Make a new claim
          </ThemedLinkButton>
        </>
      )}
      {Array.isArray(claims) && claims.some(claim => claim.continuable) && (
        <StandardP>
          If you haven&apos;t finished submitting your claim, please click
          &apos;Continue claim&apos;.
        </StandardP>
      )}
    </>
  );
};

const ClaimsHistoryMotor = () => {
  return (
    <ClaimsHistory
      lob="motor"
      buttonLink="/motorclaim/newclaim/continue"
      buttonText="Continue claim"
      Context={motorClaimContextData.MotorClaimContextNew}
      dataContext={motorClaimContextData.motorClaimDataContext}
      entryMapper={entryMapper}
      dataMapper={data => data.motorClaimHistory}
      axiosUrl={config.client.getmotorclaimhistory_endpoint}
      subText={renderSubText}
      showTableText={false}
      redirectPathOnNoClaims="/motorclaim/newclaim/initialise"
    />
  );
};

export default ClaimsHistoryMotor;
