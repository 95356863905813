import JoiBase from '@hapi/joi';
import JoiDate from '@hapi/joi-date';
import { messages } from '../constants/ErrorMessages';

const Joi = JoiBase.extend(JoiDate);

const validKeys = [];

/**
 * @description: Validate the field is a date and is not empty.
 * @param keys Fields to validate
 * @succeeds when field is a string and not empty
 * @fails when field is not a string or is empty
 */
export const dateRequired = (
  keys = validKeys,
  message = messages.EMPTY_DATE,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.date()
      .format('DD/MM/YYYY')
      .raw()
      .error(() => message),
  );
};

/**
 * @description: Validate the field stores a boolean and is not empty.
 * @param keys Fields to validate
 * @succeeds when field is a string and not empty
 * @fails when field is not a string or is empty
 */

export const booleanRequired = (
  keys = validKeys,
  message = messages.INVALID_REQUIRED_IF_EMPTY,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.boolean()
      .strict()
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field stores an object and is not empty.
 * @param keys Fields to validate
 * @succeeds when field is a string and not empty
 * @fails when field is not a string or is empty
 */

export const objectRequired = (
  keys = validKeys,
  message = messages.INVALID_REQUIRED_IF_EMPTY,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.object()
      .strict()
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field is a string and is not empty.
 * @param keys Fields to validate
 * @succeeds when field is a string and not empty
 * @fails when field is not a string or is empty
 */
export const stringRequired = (
  keys = validKeys,
  message = messages.EMPTY_STRING,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field is a number and is not empty.
 * @param keys Fields to validate
 * @succeeds when field is a number and not empty
 * @fails when field is not a number or is empty
 */
export const numberRequired = (
  keys = validKeys,
  message = messages.EMPTY_NUMBER,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.number()
      .required()
      .error(() => message),
  );
};

/**
 * @description: Validate the field is not empty.
 * @param keys Fields to validate
 * @succeeds when field is not empty
 * @fails when field is empty
 */
export const required = (keys = validKeys) => {
  return Joi.object().pattern(Joi.valid(keys), [
    Joi.date()
      .required()
      .error(() => messages.EMPTY_DATE),
    Joi.string()
      .required()
      .error(() => messages.EMPTY_STRING),
    Joi.number()
      .required()
      .error(() => messages.EMPTY_NUMBER),
  ]);
};
