import React from 'react';

import { brand } from '../../../../data/whitelabel.config';
import capitalizeWord from '../../../helpers/capitalizeWord';

import { Header, Logo, Container } from './Header.style';

const BrandHeader = () => {
  return (
    <Header>
      <Container>
        {/* TODO: Add link to homepage and modal prompt in a separate ticket */}
        <a href="/">
          <Logo>
            <img
              alt={`${capitalizeWord(brand)} logo`}
              src="https://via.placeholder.com/150"
            />
            <figcaption>Car Insurance</figcaption>
          </Logo>
        </a>
      </Container>
    </Header>
  );
};

export default BrandHeader;
