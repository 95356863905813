import styled from 'styled-components';

// For documentation see:
// /docs/components/Forms/FieldSpacer/FieldSpacer.md

const Spacer = styled.div`
  margin-bottom: ${props => props.marginBottom || '24px'};
`;

export default Spacer;
