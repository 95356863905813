import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const dotSize = 12 / 14; // em, approx 0.86 (so is 12px for a font size of 14px)
const halfDotSize = dotSize / 2;
const dottedLineSize = 2; // px;
const halfDottedLineSize = dottedLineSize / 2;

/*
  breadcrumb container.
  Supplies the gray background with curved border
*/
const narrowModeContainerCSS = css`
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-bottom: 1em;
  /* padding side offset should not apply in mobile mode */
  padding-left: ${({ styling }) => styling.labelWidth / 2}px;
  padding-right: ${({ styling }) => styling.labelWidth / 2}px;
`;
export const Container = styled.nav`
  font-size: 14px;
  background: rgb(214, 214, 214);
  font-family: Nunito, sans-serif;
  padding-top: 2em;
  padding-bottom: calc(59px + ${({ styling }) => styling.paddingBottomOffset});
  padding-left: calc(
    ${({ styling }) => styling.labelWidth / 2}px +
      ${({ styling }) => styling.paddingSideOffset}
  );
  padding-right: calc(
    ${({ styling }) => styling.labelWidth / 2}px +
      ${({ styling }) => styling.paddingSideOffset}
  );
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  box-sizing: border-box;
  width: 100%;

  /* narrow view remove fancy curved bottom border */
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    ${narrowModeContainerCSS}

    /* Different default label width for breakpoint narrow mode */
    padding-left: ${({ styling }) => styling.narrowLabelWidth / 2}px;
    padding-right: ${({ styling }) => styling.narrowLabelWidth / 2}px;
  }

  /* Always narrow mode - breakpoint narrow label width does not apply as it will be rendering normal labels */
  ${({ styling }) => styling.alwaysNarrow && narrowModeContainerCSS}
`;

/*
List item container
  Applies flex to children li's to ensure even sizing and spacing of li elements
*/

export const List = styled.ul`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  margin: 0 auto;
  ${({ styling }) =>
    styling.maxInnerWidth &&
    css`
      max-width: ${styling.maxInnerWidth};
    `}
  ${({ styling }) =>
    styling.maxNarrowInnerWidth &&
    css`
      @media (max-width: ${styling.mediaBreakpoint}) {
        max-width: ${styling.maxNarrowInnerWidth};
      }
    `}
`;

/*
List item 
  Applies flex to children:
  * content (link containing dot and label text), fixed width
  * ::before (dotted line), stretch to fit container
*/
export const ListItem = styled.li`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: flex-start;
  color: #b3b3b3;
  /* No dotted line before #1, so no flex grow */
  &:first-child {
    flex-grow: 0;
  }
  /* All but first, use ::before to create a dotted line that grows to fit flex container */
  &:not(:first-child)::before {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    border-bottom: ${dottedLineSize}px dotted #b3b3b3;
    content: '';
    /* top margin half dot height - half border */
    margin: calc(${halfDotSize}em - ${halfDottedLineSize}px) 4px;
  }
  /* Active list item colours */
  ${({ active }) =>
    active &&
    css`
      color: black;
      &:not(:first-child)::before {
        border-color: black;
      }
    `}
`;

/*
  List item content container. is Anchor/Link (active) or span (inactive)
  Flex to ensure children display side by side
*/
const listItemContainer = css`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-start;
  color: inherit;
  text-decoration: none;
  outline: none;
`;
const listItemContainerClickable = css`
  &:hover {
    text-decoration: underline;
    color: #8d409c;
  }
`;

export const ListItemAnchor = styled.a`
  ${listItemContainer}
  ${listItemContainerClickable}
`;
export const ListItemLink = styled(Link)`
  ${listItemContainer}
  ${listItemContainerClickable}
`;
export const ListItemSpan = styled.span`
  ${listItemContainer}
`;

/*
  List item dot
*/
export const Dot = styled.div`
  width: ${dotSize}em;
  height: ${dotSize}em;
  border-radius: ${halfDotSize}em;
  background-color: #b3b3b3;
  content: '';
  padding: 0px;
  /* Active list item dot colour */
  ${({ active }) =>
    active &&
    css`
      background-color: black;
    `}

  /* Focussed list item dot colour */
  ${ListItemAnchor}:focus-visible &,
  ${ListItemLink}:focus-visible &,
  ${ListItemAnchor}:hover &,
  ${ListItemLink}:hover & {
    background-color: #8d409c;
  }
`;

/*
  0 width container for Label
  This lets the label take up vertical space (so container is automatically
  big enough) but not horizontal space (so the ListItem dotted lins work)
*/
export const Offsetter = styled.div`
  width: 0px;
`;

/*
  Label text container
*/
export const Label = styled.div`
  font-weight: bold;
  width: ${({ styling }) => styling.labelWidth}px;
  position: relative;

  top: 2em;
  padding: 0px 2px 2px 2px;
  margin-bottom: calc(2em - 2px);
  left: calc(-${({ styling }) => styling.labelWidth / 2}px - ${halfDotSize}em);
  text-align: center;

  /* Different default label width for narrow mode */
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    width: ${({ styling }) => styling.narrowLabelWidth}px;
    left: calc(
      -${({ styling }) => styling.narrowLabelWidth / 2}px - ${halfDotSize}em
    );
  }

  /* Empty label outline is a single line instead */
  outline: 2px none #8d409c;
  &:empty {
    padding: 0px;
    outline-width: 1.5px;
  }

  /* Focussed list item label colour/outline */
  ${ListItemAnchor}:focus-visible &,
  ${ListItemLink}:focus-visible &,
  ${ListItemAnchor}:hover &,
  ${ListItemLink}:hover & {
    color: #8d409c;
  }
  ${ListItemAnchor}:focus-visible &,
  ${ListItemLink}:focus-visible & {
    outline-style: solid;
  }
`;

/*
  Wide mode label
  Do not show on narow screen
*/
export const LabelWide = styled(Label)`
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    display: none;
  }
`;
/*
  Narrow mode label
  Only show on narow screen
*/
export const LabelNarrow = styled(Label)`
  display: none;
  @media (max-width: ${({ styling }) => styling.mediaBreakpoint}) {
    display: block;
  }
`;
