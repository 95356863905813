import { brand } from '../../data/whitelabel.config';
import headers from './headers';

/*  Set header according to brand
    Check if corresponding brand object exists in headers
    revert to defaultHeader if brand is not set or doesn't exist */

const header = headers[brand] ? brand : 'defaultHeader';

export default headers[header];
