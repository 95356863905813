import React from 'react';
import { homeHubContextData } from '../../helpers/hubContextHelper';
import homeImage from '../../assets/images/home_insurance.png';
import config from '../../helpers/config';
import ClaimsHistory from './ClaimsHistory';
import { getPerilByCode } from '../../helpers/perilCodesDescriptions';
import {
  CLAIM_STATUSES,
  getStatusByCode,
} from '../../helpers/claimStatusDescriptions';

const getPerilDesc = perilCode => {
  return getPerilByCode(perilCode)?.capitalise || perilCode;
};

const getStatusDescHome = status => {
  if (status === CLAIM_STATUSES.INCOMPLETE.code) {
    return CLAIM_STATUSES.PROCESSING.capitalise;
  }
  return getStatusByCode(status)?.capitalise || CLAIM_STATUSES.OPEN.capitalise;
};

const entryMapper = (lob, claim) => {
  return {
    originalData: claim,
    ...claim,
    lob,
    icon: homeImage,
    iconAlt: 'home',
    claimType: getPerilDesc(claim.perilCode),
    claimStatusDesc: getStatusDescHome(claim.statusCode),
    isOpen: claim.statusCode === CLAIM_STATUSES.OPEN.code,
    isClickable:
      claim.statusCode !== CLAIM_STATUSES.PROCESSING.code &&
      claim.statusCode !== CLAIM_STATUSES.INCOMPLETE.code,
  };
};

const HubClaimsHistoryHome = () => {
  return (
    <ClaimsHistory
      lob="home"
      buttonLink="/homehub/claimdetails"
      buttonText="View"
      Context={homeHubContextData.HubContext}
      dataContext={homeHubContextData.hubDataContext}
      entryMapper={entryMapper}
      dataMapper={data => data.homeClaimHistory}
      headerClaimButton
      axiosUrl={config.client.gethomeclaimhistory_endpoint}
      titleText="Hello! Welcome to your claims hub"
      subText="Here you can manage and monitor your claims, upload documents, send messages and stay in touch with how your claim is progressing."
      showTableText
    />
  );
};

export default HubClaimsHistoryHome;
