// Convert array of string words to readable comma/or separated string.
// Words are separated by ', ' except the last pair, which are separated
// by ' or ' (or custom)
// Empty array or non-array returns empty string ''

// E.g.
// * readableJoin(['ant', 'bison', 'camel', 'elephant'])
//   * -> 'ant, bison, camel or elephant'
// * readableJoin(['ant', 'bison', 'elephant'])
//   * -> 'ant, bison or elephant'
// * readableJoin(['ant', 'elephant'])
//   * -> 'ant or elephant'
// * readableJoin(['elephant'])
//   * -> 'elephant'

const readableJoin = (words, lastJoiner = ' or ') => {
  if (!Array.isArray(words) || words.length === 0) {
    return '';
  }
  if (words.length === 1) {
    return words[0];
  }
  return (
    words.slice(0, words.length - 1).join(', ') +
    lastJoiner +
    words[words.length - 1]
  );
};

export default readableJoin;
