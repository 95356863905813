import { dmyAndHmsToIso } from '../../dateTimeConverter';
import {
  isYes,
  mapInvolvedPartyToServer,
  yesNoToBoolean,
  isOther,
  involvedPartyType,
} from '../helpers';

/**
 * Map Animal Damage data in context/pageForms format to JSON Blob format
 * @param {*} incidentDetails in MotorClaimContextNew, claimEntryData format
 * (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsAnimalDamageSection = incidentDetails => {
  const {
    howDamageHappened,
    animaltype,
    animalOwnerKnown,
    animalOwnerResponsible,
  } = incidentDetails || {};

  if (howDamageHappened !== 'animal') {
    return {};
  }

  return {
    animalDamage: {
      animalType: animaltype?.trim(),
      ownerKnown: isOther(animaltype)
        ? undefined
        : yesNoToBoolean(animalOwnerKnown),
      ownerResponsible:
        isOther(animaltype) || !isYes(animalOwnerKnown)
          ? undefined
          : yesNoToBoolean(animalOwnerResponsible),
      ownerDetails:
        isOther(animaltype) ||
        !isYes(animalOwnerKnown) ||
        !isYes(animalOwnerResponsible)
          ? undefined
          : mapInvolvedPartyToServer(
              incidentDetails,
              'animalOwnerDetails',
              involvedPartyType.INDIVIDUAL,
            ),
    },
  };
};

/**
 * Map Property Damage data in context/pageForms format to JSON Blob format
 * @param {*} incidentDetails in MotorClaimContextNew, claimEntryData format
 * (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsPropertyDamageSection =
  incidentDetails => {
    const {
      howDamageHappened,
      propertyTypeDamaged,
      propertyTypeDamagedOtherDetails,
      propertyOwnerKnown,
      propertyOwnerType,
    } = incidentDetails || {};

    if (howDamageHappened !== 'property') {
      return {};
    }

    return {
      propertyDamage: {
        propertyType: propertyTypeDamaged?.trim(),
        propertyTypeOtherDetails: isOther(propertyTypeDamaged)
          ? propertyTypeDamagedOtherDetails?.trim()
          : undefined,
        ownerKnown: yesNoToBoolean(propertyOwnerKnown),
        ownerType: !isYes(propertyOwnerKnown)
          ? undefined
          : propertyOwnerType?.trim(),
        ownerDetails: !isYes(propertyOwnerKnown)
          ? undefined
          : mapInvolvedPartyToServer(
              incidentDetails,
              'propertyOwnerDetails',
              propertyOwnerType,
            ),
      },
    };
  };

/**
 * Map Road Surface Mud Spillage data in context/pageForms format to JSON Blob
 * format
 * @param {*} incidentDetails in MotorClaimContextNew, claimEntryData format
 * (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsRoadSurfaceSpillageMudSection =
  incidentDetails => {
    const {
      roadSurfaceIssueType,
      spillageMudWarningSigns,
      spillageMudWhoCausedKnown,
      spillageMudWhoCausedType,
      spillageMudWhenAppearedKnown,
      spillageMudWhenAppeared,
    } = incidentDetails || {};

    if (roadSurfaceIssueType !== 'spillageMud') {
      return {};
    }

    return {
      spillageMud: {
        warningSigns: yesNoToBoolean(spillageMudWarningSigns),
        whoCausedKnown: yesNoToBoolean(spillageMudWhoCausedKnown),
        whoCausedType: !isYes(spillageMudWhoCausedKnown)
          ? undefined
          : spillageMudWhoCausedType?.trim(),
        whoCausedDetails: !isYes(spillageMudWhoCausedKnown)
          ? undefined
          : mapInvolvedPartyToServer(
              incidentDetails,
              'spillageMudWhoCausedDetails',
              spillageMudWhoCausedType,
            ),
        whenAppearedKnown: !isYes(spillageMudWhoCausedKnown)
          ? undefined
          : yesNoToBoolean(spillageMudWhenAppearedKnown),
        whenAppeared:
          !isYes(spillageMudWhoCausedKnown) ||
          !isYes(spillageMudWhenAppearedKnown)
            ? undefined
            : spillageMudWhenAppeared?.trim(),
      },
    };
  };

/**
 * Map Road Surface Ungritted data in context/pageForms format to JSON Blob
 * format
 * @param {*} incidentDetails in MotorClaimContextNew, claimEntryData format
 * (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsRoadSurfaceUngrittedSection =
  incidentDetails => {
    const {
      roadSurfaceIssueType,
      ungrittedRoadMainRoad,
      ungrittedRoadAccessRoad,
      ungrittedRoadPublicTransport,
      ungrittedRoadOwnerKnown,
      ungrittedRoadOwnerType,
    } = incidentDetails || {};

    if (roadSurfaceIssueType !== 'ungrittedRoad') {
      return {};
    }

    const needUngrittedRoadOwnerDetails =
      isYes(ungrittedRoadMainRoad) ||
      isYes(ungrittedRoadAccessRoad) ||
      isYes(ungrittedRoadPublicTransport);

    return {
      ungrittedRoad: {
        mainRoad: yesNoToBoolean(ungrittedRoadMainRoad),
        accessRoad: yesNoToBoolean(ungrittedRoadAccessRoad),
        publicTransport: yesNoToBoolean(ungrittedRoadPublicTransport),
        ownerKnown: !needUngrittedRoadOwnerDetails
          ? undefined
          : yesNoToBoolean(ungrittedRoadOwnerKnown),
        ownerType:
          !needUngrittedRoadOwnerDetails || !isYes(ungrittedRoadOwnerKnown)
            ? undefined
            : ungrittedRoadOwnerType?.trim(),
        ownerDetails:
          !needUngrittedRoadOwnerDetails || !isYes(ungrittedRoadOwnerKnown)
            ? undefined
            : mapInvolvedPartyToServer(
                incidentDetails,
                'ungrittedRoadOwnerDetails',
                ungrittedRoadOwnerType,
              ),
      },
    };
  };

/**
 * Map Road Surface Mud Spillage data in context/pageForms format to JSON Blob
 * format
 * @param {*} incidentDetails in MotorClaimContextNew, claimEntryData format
 * (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsRoadSurfacePotholeSection =
  incidentDetails => {
    const { roadSurfaceIssueType } = incidentDetails || {};

    if (roadSurfaceIssueType !== 'pothole') {
      return {};
    }

    return {
      pothole: {
        councilDetails: mapInvolvedPartyToServer(
          incidentDetails,
          'potholeCouncilDetails',
          involvedPartyType.COMPANY,
        ),
      },
    };
  };

/**
 * Map Road Surface data in context/pageForms format to JSON Blob format
 * @param {*} incidentDetails in MotorClaimContextNew, claimEntryData format
 * (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsRoadSurfaceSection = incidentDetails => {
  const { howDamageHappened, roadSurfaceIssueType } = incidentDetails || {};

  if (howDamageHappened !== 'roadSurface') {
    return {};
  }

  return {
    roadSurface: {
      roadSurfaceIssueType: roadSurfaceIssueType?.trim(),
      ...mapLocalToIncidentDetailsRoadSurfaceSpillageMudSection(
        incidentDetails,
      ),
      ...mapLocalToIncidentDetailsRoadSurfaceUngrittedSection(incidentDetails),
      ...mapLocalToIncidentDetailsRoadSurfacePotholeSection(incidentDetails),
    },
  };
};

/**
 * Map context/pageForms data to JSON Blob incidentDetails section
 * @param {*} data in MotorClaimContextNew, claimEntryData format (all pages)
 * @returns data in JSON Blob incidentDetails section format
 */
export const mapLocalToIncidentDetailsSection = ({
  incidentDetails,
  yourDetails,
} = {}) => {
  const {
    incidentDate,
    incidentTime,
    vehicleRegistration,
    howDamageHappened,
    incidentDetails: incidentDetailsField,
    hasCCTV,
  } = incidentDetails || {};
  const { driver } = yourDetails || {};

  // Conversions
  const incidentDateTime =
    incidentDate && incidentTime
      ? dmyAndHmsToIso(incidentDate, incidentTime)
      : undefined;

  let circumstances = {};
  if (howDamageHappened) {
    circumstances = {
      circumstances: {
        howDamageHappened: howDamageHappened?.trim(),
        ...mapLocalToIncidentDetailsAnimalDamageSection(incidentDetails),
        ...mapLocalToIncidentDetailsPropertyDamageSection(incidentDetails),
        ...mapLocalToIncidentDetailsRoadSurfaceSection(incidentDetails),
      },
    };
  }

  // Return mapped object
  const output = {
    incidentDate: incidentDateTime,
    vrn: vehicleRegistration?.trim(),
    incidentDescription: incidentDetailsField?.trim(),
    hasCCTV: yesNoToBoolean(hasCCTV),
    driver: driver && parseInt(driver, 10),
    ...circumstances,
  };

  return output;
};

export default mapLocalToIncidentDetailsSection;
