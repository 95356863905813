import { DateTime } from 'luxon';
import { getBusinessHours } from './businessHoursData';

// Note: Parameter is a Luxon DateTime object, not a JavaScript Date object
// eslint-disable-next-line import/prefer-default-export
export const checkBusinessHours = (lob, date = DateTime.now()) => {
  // toFormat returns "<dayName>,<hour>.<minute>"
  // Split by comma to get ["<dayName>","<hour>.<minute>"]
  const [day, hour] = date
    .setZone('Europe/London')
    .setLocale('en-GB')
    .toFormat('cccc,HH.mm')
    .split(',');

  const { start, stop } = getBusinessHours(lob, day);
  return start <= hour && hour < stop;
};

// export default checkBusinessHours;
