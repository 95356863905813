import React, { useState } from 'react';
import PropTypes from 'prop-types';
import houseIcon from '../../assets/icons/claimTypes/damage-selector_mobile.svg';
import Mobilemenu from './Menumobile';
import {
  ThemedButtonIconRight,
  Container,
  House,
  ThemedButtonIconContainer,
  HomeIconContainerOne,
  HomeIconContainerTwo,
  ButtonHolder,
} from './BuildingGardenStyle';

const BuildingGardenMobile = ({
  buildingDamageItem,
  selectedItemCall,
  toggleSelected,
  selectedItems,
}) => {
  const [building, setBuilding] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const renderBackdrop = () => {
    setBuilding(true);
    setOpenMenu(true);
  };

  const renderBackdropgarden = () => {
    setBuilding(false);
    setOpenMenu(true);
  };

  return (
    <>
      <Container>
        <House src={houseIcon} alt="House icon" />
        <HomeIconContainerOne>
          <ThemedButtonIconContainer large>1</ThemedButtonIconContainer>
        </HomeIconContainerOne>
        <HomeIconContainerTwo>
          <ThemedButtonIconContainer large>2</ThemedButtonIconContainer>
        </HomeIconContainerTwo>
      </Container>
      <ButtonHolder>
        <ThemedButtonIconRight secondary inverted onClick={renderBackdrop}>
          <ThemedButtonIconContainer>1</ThemedButtonIconContainer>
          Building storm damage
        </ThemedButtonIconRight>
        <ThemedButtonIconRight
          secondary
          inverted
          onClick={renderBackdropgarden}
        >
          <ThemedButtonIconContainer>2</ThemedButtonIconContainer>
          Garden storm damage
        </ThemedButtonIconRight>
      </ButtonHolder>
      <Mobilemenu
        openItem={openMenu}
        stormItems={buildingDamageItem}
        selectedItems={selectedItems}
        stormItemsCall={selectedItemCall}
        toggleSelected={toggleSelected}
        buttonSelection={building}
        openMenuItem={data => setOpenMenu(data)}
      />
    </>
  );
};

export default BuildingGardenMobile;

BuildingGardenMobile.propTypes = {
  buildingDamageItem: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      isChecked: PropTypes.bool,
    }),
  ),
  selectedItemCall: PropTypes.func,
  toggleSelected: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

BuildingGardenMobile.defaultProps = {
  buildingDamageItem: undefined,
  selectedItemCall: undefined,
  toggleSelected: undefined,
  selectedItems: undefined,
};
