import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Entry,
  DataContainer,
  ButtonContainer,
  IconContainer,
  IconImg,
} from './ActionList.style';

const ActionListEntry = ({
  children,
  onClick,
  buttonText,
  buttonLabel,
  sideMargin,
  icon,
  iconAlt,
}) => {
  return (
    <Entry sideMargin={sideMargin}>
      {icon && (
        <IconContainer>
          <IconImg src={icon} alt={iconAlt} />
        </IconContainer>
      )}
      <DataContainer>{children}</DataContainer>
      {!!onClick && (
        <ButtonContainer>
          <ActionButton
            primary
            inverted
            focusHighlight
            type="button"
            onClick={onClick}
            aria-label={buttonLabel}
          >
            {buttonText}
          </ActionButton>
        </ButtonContainer>
      )}
    </Entry>
  );
};

ActionListEntry.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  buttonText: PropTypes.node,
  buttonLabel: PropTypes.string,
  sideMargin: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
};
ActionListEntry.defaultProps = {
  children: undefined,
  onClick: undefined,
  buttonText: undefined,
  buttonLabel: undefined,
  sideMargin: undefined,
  icon: undefined,
  iconAlt: undefined,
};

export default ActionListEntry;
