import React from 'react';

export const localStorageMotorPropertyName = 'motorClaimFormState';

export const formNames = {
  motorContactDetail: 'motorContactDetail',
  motorIncidentDetail: 'motorIncidentDetail',
  involvedPartiesDetail: 'involvedPartiesDetail',
};

export const MotorFormContext = React.createContext({});
