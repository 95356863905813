import React from 'react';
import { motorHubContextData } from '../../helpers/hubContextHelper';
import carImage from '../../assets/images/car_insurance.png';
import { getClaimTypeByCode } from '../../helpers/motorClaimTypeDescriptions';
import {
  MOTOR_CLAIM_STATUSES,
  getMotorClaimStatusByCode,
} from '../../helpers/motorClaimStatusDescriptions';
import config from '../../helpers/config';
import ClaimsHistory from './ClaimsHistory';

const getStatusDescMotor = (status, claim) => {
  if (
    (claim.isConfirmed === false &&
      status === MOTOR_CLAIM_STATUSES.OPEN.code) ||
    status === MOTOR_CLAIM_STATUSES.INCOMPLETE.code
  ) {
    return MOTOR_CLAIM_STATUSES.PROCESSING.capitalise;
  }
  return (
    getMotorClaimStatusByCode(status)?.capitalise ||
    MOTOR_CLAIM_STATUSES.OPEN.capitalise
  );
};

const entryMapper = (lob, claim) => {
  return {
    originalData: claim,
    ...claim,
    lossDate: claim.incidentDate,
    lob,
    icon: carImage,
    iconAlt: 'Car',
    claimType:
      getClaimTypeByCode(claim.claimType)?.capitalise || claim.claimType,
    claimStatusDesc: getStatusDescMotor(claim.statusCode, claim),
    isOpen:
      claim.statusCode === MOTOR_CLAIM_STATUSES.OPEN.code && claim.isConfirmed,
    isClickable:
      claim.statusCode !== MOTOR_CLAIM_STATUSES.PROCESSING.code &&
      claim.statusCode !== MOTOR_CLAIM_STATUSES.INCOMPLETE.code &&
      claim.isConfirmed,
    vrn: claim.vrn,
  };
};

const HubClaimsHistoryMotor = () => {
  return (
    <ClaimsHistory
      lob="motor"
      buttonLink="/motorhub/claimdetails"
      buttonText="View"
      Context={motorHubContextData.HubContext}
      dataContext={motorHubContextData.hubDataContext}
      entryMapper={entryMapper}
      dataMapper={data => data.motorClaimHistory}
      axiosUrl={config.client.getmotorclaimhistory_endpoint}
      titleText="Hello! Welcome to your claims hub"
      subText="Here you can monitor your claims."
      showTableText
    />
  );
};

export default HubClaimsHistoryMotor;
