import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getTokenAndType } from '../../contexts/AuthContext';
import { consoleError } from '../../helpers/consoleLog';

// We now have the onchange item only firing when one of the parms changes
// SO can we simply always do a request in this instance, and use abort
// to cancel the existing if present?
// No, we need to check keys because of context.

const ContextAxios = ({
  keyList,
  enableUpdate,
  axiosFunction,
  dataContextObject,
  dataContextName,
}) => {
  const [mounted, setMounted] = useState(false);
  const axiosCancelToken = useRef(null);
  const { dataState, updateDataState } = useContext(dataContextObject);

  // Realtime state, so it is accurate if updated several times per render.
  // Read this var, update this var at the same time as state
  const listState = { ...dataState[dataContextName] };

  const setListStateData = newData => {
    listState.data = newData;
    updateDataState(dataContextName, { ...listState });
  };
  const setListStateKeys = newKeyState => {
    listState.keyState = newKeyState;
    updateDataState(dataContextName, { ...listState });
  };
  const setListStateStatus = newStatus => {
    listState.status = newStatus;
    updateDataState(dataContextName, { ...listState });
  };
  const setListStateError = newError => {
    listState.error = newError;
    updateDataState(dataContextName, { ...listState });
  };

  // Abort in-progress axios request if one exists
  const abortAxios = message => {
    if (axiosCancelToken?.current?.cancel) {
      axiosCancelToken.current.cancel(message);
    }
  };

  // Create axios cancel token
  const setAxiosCancelToken = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosCancelToken.current;
  };

  // Have axios lookup keys changed from previous request?
  const keysHaveChanged = keySet => {
    return (
      !listState?.keyState ||
      keySet.length !== listState.keyState.length ||
      !keySet.every((key, i) => key === listState.keyState[i])
    );
  };

  // Submit axios request
  // Returns true if request submitted, else false
  const requestData = keySet => {
    const cancelToken = setAxiosCancelToken().token;

    // Call provided axios function, which returns promise
    const axiosSubmit = axiosFunction(keySet, cancelToken);
    // Not sent, assume noKey
    if (!axiosSubmit) {
      setListStateStatus('nokey');
      return false;
    }

    // Returned a string, assume custom failed status
    if (typeof axiosSubmit === 'string') {
      setListStateStatus(axiosSubmit);
      return false;
    }

    // We are loading, clear existing data, update keys, process result
    setListStateData(undefined);
    setListStateKeys(keySet);
    setListStateStatus('loading');
    axiosSubmit
      .then(data => {
        setListStateData(data);
        setListStateStatus('loaded');
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          consoleError('axios error', error);
          setListStateStatus('error');
          setListStateError(error);
        }
      });
    return true;
  };

  // Mount/unmount flag controller
  // So on change useEffects can not run twice on mount
  useEffect(() => {
    setMounted(true);
    return () => {
      abortAxios();
    };
  }, []);

  // When props change, check if data needs to be re-requested
  // Only once mounted (useEffect runs twice on mount)
  // If request submitted, return function to abort axios on cleanup
  // (cancel request for new request or unmount)
  useEffect(() => {
    if (enableUpdate && mounted && keyList) {
      // Add auth state (type and token) to key list
      const keySet = [...keyList, ...getTokenAndType()];
      // Check if keys still match and data is valid
      // (could be re-mount, context data still valid)
      if (!keysHaveChanged(keySet) && listState?.data) {
        setListStateStatus('loaded');
      }
      // Attempt (Re)request.
      // If axios requested, return function to abort on cleanup
      else if (requestData(keySet)) {
        return () => {
          // If component is unmounting, remove loading status for neatness
          if (listState.status === 'loading') {
            setListStateStatus(undefined);
            setListStateError(undefined);
          }
          abortAxios();
        };
      }
    }
    return undefined;
  }, [...keyList, mounted, enableUpdate]);

  return <></>;
};

ContextAxios.defaultProps = {
  keyList: [],
  enableUpdate: true,
};

ContextAxios.propTypes = {
  keyList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  enableUpdate: PropTypes.bool,
  axiosFunction: PropTypes.func.isRequired,
  dataContextObject: PropTypes.instanceOf(Object).isRequired,
  dataContextName: PropTypes.string.isRequired,
};

export default ContextAxios;
