import React, { useEffect, useRef, useState, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { Form, Field } from 'react-final-form';
import { getTheme } from 'ageas-ui-themes';
import { ThemedErrorMessage, ThemedParagraph } from 'ageas-ui-components';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { brand } from '../../../data/whitelabel.config';
import {
  getIncidentDateValidationSchema,
  run3YearDateValidation,
} from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import { validateField } from '../../helpers/validationHelper';
import { HomeClaimsFNOL } from '../../helpers/ageasTelephoneNumbers';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  TitleText,
  PageSectionBare,
  Subsection,
  ButtonsBottomContainer,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';

import axiosHelper from '../../helpers/axios';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import { dateISOConverter } from '../../helpers/dateTimeConverter';
import config from '../../helpers/config';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import HomeGarage, {
  getBuildingGardenValidationSchema,
} from '../../components/BuildingGarden/BuildingGarden';
import ThemedIconButton from '../../components/ThemedIconButton/ThemedIconButton';
import {
  serverErrorHome,
  validPolicyErrorHome,
} from '../../helpers/errorMessages';
import { homeReferLink } from '../../helpers/businessHoursData';
import { checkBusinessHours } from '../../helpers/checkBusinessHours';
import {
  stormDamageDataContext,
  StormContext,
} from '../../helpers/stormContextHelper';
import StandardPopup from '../../components/StandardPopup/StandardPopup';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';
import HomeStandardClaimDetails, {
  getStandardClaimDetailsValidationSchema,
} from '../HomeStandardClaimDetails/HomeStandardClaimDetails';
import { policyDataValid } from '../../helpers/policyDataContextHelper';

const Theme = getTheme(brand);

const INCIDENT_DATE_FIELD_NAME = 'damagedDate';
const DAMAGED_AREAS_FIELD_NAME = 'damagedAreas';
const MOBILE_TELEPHONE_NAME = 'homeMobileTelephone';
const EMAIL_ADDRESS_NAME = 'homeEmail';
const POLICYHOLDER_NAME = 'policyHolderNumber';

const StormDamagedClaim = () => {
  const { dataState: stormDataContext, updateDataState } =
    useContext(StormContext);
  const policyDetailsContext = { ...stormDataContext?.policyDetails };

  const [serverError, setServerError] = useState(false);
  const [notInForcePolicy, isNotInForcePolicy] = useState(false);
  const axiosCancelToken = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [navigatePath, setNavigatePath] = useState(null);
  const [createClaimModalAction, setCreateClaimModalAction] =
    useState(undefined);
  const [renderedPage, setRenderedPage] = useState(false);

  const resetStateErrors = () => {
    setServerError(false);
    isNotInForcePolicy(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isEmpty(stormDataContext) && stormDataContext?.detailsCompleted) {
      setNavigatePath('/homeclaim/storm/results');
    } else {
      setRenderedPage(true);
    }
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const onCreateClaimFail = error => {
    if (!axios.isCancel(error)) {
      updateDataState(stormDamageDataContext.weatherData, {});
      updateDataState(stormDamageDataContext.detailsCompleted, false);
      setServerError(true);
      setIsLoading(false);
    }
  };

  const onCreateClaimSuccess = (data, values) => {
    const { claimReference = {} } = data?.data || {};
    const { declines = [] } = data?.data?.status || {};

    setIsLoading(false);
    updateDataState(stormDamageDataContext.weatherData, data?.data);
    updateDataState(
      stormDamageDataContext.weatherIncidentDate,
      values.damagedDate,
    );
    updateDataState(
      stormDamageDataContext.userContactNumber,
      values.homeMobileTelephone,
    );
    if (!isEmpty(claimReference)) {
      updateDataState(stormDamageDataContext.detailsCompleted, true);
      setNavigatePath('/homeclaim/storm/results');
    } else if (declines.includes('PolicyNotOnCover')) {
      isNotInForcePolicy(true);
    }
  };

  const buildRequestPayload = values => {
    return {
      homeIncidentDetails: {
        perilCode: 'ST',
        incidentDate: dateISOConverter(values.damagedDate, true),
        policyholderId: values.policyHolderNumber,
        emailAddress: values.homeEmail,
        mobileTelephoneNumber: values.homeMobileTelephone,
        stormDetails: {
          damagedAreas: values.damagedAreas,
        },
      },
    };
  };

  const createStormClaim = values => {
    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .post(
        config.client.homeclaimnumber_endpoint,
        buildRequestPayload(values),
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(data => {
        onCreateClaimSuccess(data, values);
      })
      .catch(error => {
        onCreateClaimFail(error);
      });
  };
  const schema = {
    ...getIncidentDateValidationSchema(INCIDENT_DATE_FIELD_NAME),
    ...getBuildingGardenValidationSchema(DAMAGED_AREAS_FIELD_NAME),
    ...getStandardClaimDetailsValidationSchema(
      POLICYHOLDER_NAME,
      MOBILE_TELEPHONE_NAME,
      EMAIL_ADDRESS_NAME,
    ),
  };

  const validateFields = (value, allFields, meta) => {
    let error;
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return error;
      }
    }

    // Custom validation - incident happened 3yrs ago
    if (meta.name === INCIDENT_DATE_FIELD_NAME) {
      if (run3YearDateValidation(allFields[INCIDENT_DATE_FIELD_NAME], 'home')) {
        return serverErrorHome();
      }
      // If inputs changed and policy not valid error displayed, reset it
      if (notInForcePolicy) {
        isNotInForcePolicy(false);
      }
    }

    return undefined;
  };

  const closeCreateClaimModal = () => {
    setCreateClaimModalAction(undefined);
  };

  const onSubmitConfirm = values => {
    closeCreateClaimModal();
    setIsLoading(true);
    // createStormClaim(values);
    createStormClaim(values);
  };

  const onSubmit = values => {
    setCreateClaimModalAction(values);
  };

  const beforeSubmit = () => {
    resetStateErrors();
  };

  const Ischeckvalues = (values, errors) => {
    return !!(!errors.damagedDate && values.damagedDate);
  };

  const showContinue = values => {
    return (
      !(values.policyHolderNumber === '000') &&
      (policyDetailsContext.status !== 'loading' ||
        policyDataValid(policyDetailsContext))
    );
  };

  const titleDescription = () => {
    const businessHours = checkBusinessHours('home');
    return (
      <>
        You can now report your claim for storm damage to your buildings online.
        This only applies to external damage so if you have suffered damage
        inside your property or to your contents, please{' '}
        {businessHours && (
          <>
            call the claims team on <HomeClaimsFNOL />.
          </>
        )}
        {!businessHours && (
          <>
            contact us using this form{' '}
            <a href={homeReferLink}>Claim referral form</a>.
          </>
        )}
      </>
    );
  };
  const renderFormEnding = values => {
    return (
      showContinue(values) && (
        <ButtonsBottomContainer>
          <ThemedIconButton
            secondary
            type="submit"
            iconRight={faChevronCircleRight}
          >
            Submit your claim
          </ThemedIconButton>
        </ButtonsBottomContainer>
      )
    );
  };

  if (navigatePath) {
    return <NavigateTo path={navigatePath} />;
  }
  // server side render will not have localStorage. Suppress page content load
  // if context (form localStorage) is unavailable, so initial server side
  // page render does not display incorrect info in the split second before
  // client side updates from localStorage
  if (!renderedPage) {
    return null;
  }

  return (
    <ThemeProvider theme={Theme}>
      {isLoading ? <LoadingSpinner /> : ''}
      <HomeClaimsWrapper backToClaimsLink>
        <ClaimBreadcrumbStandard activeItem="claim" />
        {createClaimModalAction && (
          <StandardPopup
            buttons={[
              {
                onClick: () => onSubmitConfirm(createClaimModalAction),
                small: true,
              },
              {
                onClick: closeCreateClaimModal,
                text: 'Close',
                small: true,
                inverted: true,
              },
            ]}
          >
            <ThemedParagraph>
              By submitting your claim, we will be recording details of this
              incident in our claims system.
            </ThemedParagraph>
          </StandardPopup>
        )}
        <PageSectionBare>
          <Form onSubmit={onSubmit} initialValues={{}}>
            {({ handleSubmit, values, form, errors }) => (
              <form
                method="post"
                onSubmit={handleSubmit}
                onSubmitCapture={event => beforeSubmit(event, values)}
              >
                <Subsection>
                  <TitleText>Storm damage claim</TitleText>
                  {titleDescription()}
                </Subsection>

                <HomeStandardClaimDetails
                  name={[
                    INCIDENT_DATE_FIELD_NAME,
                    POLICYHOLDER_NAME,
                    MOBILE_TELEPHONE_NAME,
                    EMAIL_ADDRESS_NAME,
                  ]}
                  dateSecondaryLabel="To enable us to validate your claim, we need to check the weather records where you live in order to confirm the conditions have met the criteria detailed in your policy. Please tell us when the damage occurred. If you don’t know the exact date, please tell us when you noticed the damage."
                  validate={validateFields}
                  dateValue={values.damagedDate}
                  perilCode="ST"
                  dateChecker={Ischeckvalues(values, errors)}
                  contextName={StormContext}
                  policyHolderNameList={stormDamageDataContext?.policyDetails}
                />

                <Field
                  name={DAMAGED_AREAS_FIELD_NAME}
                  validate={validateFields}
                >
                  {({ input, meta }) => (
                    <HomeGarage input={input} meta={meta} />
                  )}
                </Field>

                {notInForcePolicy && (
                  <ThemedErrorMessage hasIcon>
                    {validPolicyErrorHome()}
                  </ThemedErrorMessage>
                )}
                {serverError && (
                  <ThemedErrorMessage hasIcon>
                    {serverErrorHome()}
                  </ThemedErrorMessage>
                )}
                {renderFormEnding(values, errors)}
              </form>
            )}
          </Form>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};
export default StormDamagedClaim;
