import React from 'react';
import { ThemedParagraph } from 'ageas-ui-components';
import {
  Footer,
  FooterContainer,
  FooterLinksContainer,
  FooterLinksHeader,
  FooterText,
  ChevronIcon,
  IconWrapper,
} from './Footer.style';

const BrandFooter = () => {
  return (
    <Footer>
      <FooterContainer>
        <FooterLinksHeader>Documents And Information</FooterLinksHeader>
        <FooterLinksContainer>
          <ul>
            <li>
              <a
                href="https://www.rias.co.uk/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <ChevronIcon icon="chevron-left" size={18} />
                </IconWrapper>
                Privacy and cookies policy
              </a>
            </li>
            <li>
              <a
                href="https://www.rias.co.uk/terms-of-business/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <ChevronIcon icon="chevron-left" size={18} />
                </IconWrapper>
                Terms of Business
              </a>
            </li>
            <li>
              <a
                href="https://www.rias.co.uk/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <ChevronIcon icon="chevron-left" size={18} />
                </IconWrapper>
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
                href="https://www.rias.co.uk/existing-customers/policy-documents/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <ChevronIcon icon="chevron-left" size={18} />
                </IconWrapper>
                Policy Documents
              </a>
            </li>

            <li>
              <a
                href="https://www.rias.co.uk/home-insurance/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <ChevronIcon icon="chevron-left" size={18} />
                </IconWrapper>
                FAQs
              </a>
            </li>
          </ul>
          <FooterText>
            <ThemedParagraph
              white
              textAlign="left"
              fontSize="12px"
              lineHeight="1.4"
            >
              RIAS is a trading name of Ageas Retail Limited. Registered office:
              Ageas House, Hampshire Corporate Park, Templars Way, Eastleigh,
              Hampshire, SO53 3YA. Registered in England and Wales 1324965.
              Ageas Retail Limited is authorised and regulated by the Financial
              Conduct Authority. FCA registered number: 312468. Insurance
              provided by Ageas Retail Limited is not available in the Channel
              Islands. Ageas Retail Limited is a sister company of Ageas
              Insurance Limited.
            </ThemedParagraph>
          </FooterText>
        </FooterLinksContainer>
      </FooterContainer>
    </Footer>
  );
};

export default BrandFooter;
