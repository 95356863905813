import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../components/Breadcrumb2/Breadcrumb';

export const items = [
  {
    id: 'Incident',
    label: 'Incident',
    to: '/motorclaim/incidentdetails',
  },
  {
    id: 'Contact',
    label: 'Contact',
    to: '/motorclaim/contactdetails',
  },
  {
    id: 'Involved parties',
    label: 'Involved parties',
    to: '/motorclaim/involvedpartiesdetail',
  },
  {
    id: 'Summary',
    label: 'Summary',
    to: '/motorclaim/confirmation',
  },
];

const MotorClaimBreadcrumb = ({ activeItem }) => {
  let currentItem;
  if (activeItem) {
    currentItem = items.findIndex(item => item.id === activeItem);
    if (currentItem < 0) {
      currentItem = undefined;
    }
  }

  return (
    <Breadcrumb
      items={items}
      currentItem={currentItem}
      maxInnerWidth="700px"
      maxNarrowInnerWidth="280px"
    />
  );
};

export default MotorClaimBreadcrumb;

MotorClaimBreadcrumb.defaultProps = {
  activeItem: undefined,
};

MotorClaimBreadcrumb.propTypes = {
  activeItem: PropTypes.string,
};
