import Joi from '@hapi/joi';

import { validDate } from '../extensions/ValidDate';
import { messages } from '../constants/ErrorMessages';

const JoiExtended = Joi.extend(validDate);

const validKeys = [];

/**
 * @description: validates an integer
 * @param keys: fieldnames to validate
 * @param message: message to display when not a valid integer.
 * @succeeds when value is an integer.
 * @fails when value is not an integer
 */
export const integer = (
  keys = validKeys,
  message = messages.INVALID_INTEGER,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.number()
      .integer()
      .error(() => message),
  );
};

/**
 * @description: validates an date
 * @param keys: fieldnames to validate
 * @param message: message to display when not a valid date.
 * @succeeds when value is a valid date
 * @fails when value is not a date or is an invalid date
 */
export const date = (keys = validKeys, message = messages.INVALID_DATE) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExtended.string()
      .validDate()
      .error(() => message),
  );
};
