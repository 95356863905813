import React, { Component } from 'react';
import styled from 'styled-components';
import cookie from 'react-cookies';
import { Button } from 'ageas-ui-components';
import media from '../MediaQuery/MediaQuery';

export const CookieButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${props => props.theme.white};
  color: ${props => props.theme.white};
  margin-left: 20px;

  ${media.tablet`align-self: flex-start;`}
`;

const CookiePolicy = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  background: ${props => props.theme.cookieBackgroundColor};
  top: 0;
  height: auto;
  left: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 20px 3em 20px 3em;
  z-index: 12000;

  ${media.phone`
  flex-flow: column wrap;
  width: auto;
  padding: 20px;
  `}
`;

const CookieText = styled.div`
  max-width: 800px;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  color: white;

  a {
    text-decoration: underline;
    padding: 4px;
  }
`;

const Link = styled.a`
  color: ${props => props.theme.white};
  text-decoration: none;
  padding: 0px 10px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

class CookieBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: !cookie.load('Cookiebanner'),
    };
  }

  state = {
    isShown: true,
  };

  hasCookies() {
    this.setState({ isShown: !cookie.load('Cookiebanner') });
  }

  acceptCookies(event) {
    const { isShown } = this.state;

    event.preventDefault();
    this.setState({ isShown: !isShown });
    const date = new Date();
    date.setDate(date.getDate() + 30);
    cookie.save('Cookiebanner', 'true', { expires: date });
  }

  render() {
    const { isShown } = this.state;

    return (
      isShown && (
        <CookiePolicy>
          <CookieText>
            <span>
              We use cookies on our website for a number of reasons, including
              to enhance performance, analyse traffic and personalise
              advertising. To find out more and see what your options are, read
              our
            </span>
            <Link
              title="Cookie Policy"
              href="https://www.ageas.co.uk/cookies/"
              target="_blank"
            >
              cookie policy.
            </Link>
            <span>
              By continuing to use this website you accept our use of cookies.
            </span>
          </CookieText>
          <CookieButton
            theming="inverted"
            buttonTestId="cookieButtonID"
            onClick={event => this.acceptCookies(event)}
          >
            Accept and close
          </CookieButton>
        </CookiePolicy>
      )
    );
  }
}

export default CookieBar;
