import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getTheme } from 'ageas-ui-themes';
import styled, { ThemeProvider } from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import { brand } from '../../../data/whitelabel.config';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../../components/StandardTags/P';
import {
  AccidentalDamageContext,
  AccidentalLossContext,
  TheftContext,
} from '../../helpers/personalLossContextHelper';
import YesNoButton from '../../components/YesNoButton/YesNoButton';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import { serverErrorHome } from '../../helpers/errorMessages';
import declineCode from '../../helpers/homeDeclineCodes';
import { HomeClaimsFNOL } from '../../helpers/ageasTelephoneNumbers';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';
import { businessHoursDescriptive } from '../../helpers/businessHoursData';
import ClaimBreadcrumbPL from '../../helpers/ClaimBreadcrumbPL';
import PersonalLossReceiptResult from '../../components/PersonalLossReceiptResult/PersonalLossReceiptResult';
import { checkFeatureSwitch } from '../../components/FeatureSwitch/FeatureSwitch';
import StandardPopup, {
  ModalButtonsContainer,
} from '../../components/StandardPopup/StandardPopup';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';

const Theme = getTheme(brand);

const SBSContainer = styled.div`
  padding-top: 6px;
`;

export const renderPopup = (titleDescription, onConfirmYes, onConfirmNo) => {
  return (
    <StandardPopup>
      <Spacer marginBottom="10px">
        <P>{titleDescription}</P>
      </Spacer>
      <ModalButtonsContainer>
        <ThemedButton primary small type="button" onClick={onConfirmYes}>
          Yes
        </ThemedButton>
        <ThemedButton
          inverted
          primary
          small
          type="button"
          onClick={onConfirmNo}
        >
          Close
        </ThemedButton>
      </ModalButtonsContainer>
    </StandardPopup>
  );
};

const PersonalLossResult = ({ perilCode, description, perilContext }) => {
  const [finalContent, setFinalContent] = useState(false);
  const [navigatePath, setNavigatePath] = useState(null);
  const [renderPage, setRenderPage] = useState(false);
  const [navigateToData, setNavigateToData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const axiosCancelToken = useRef(null);
  const [personalLossState, setPersonalLossState] = useState(null);
  const [upload, setUpload] = useState(false);
  const [isYesNoButton, setIsYesNoButton] = useState(true);
  const [isAcceptPopup, setIsAcceptPopup] = useState(false);
  const [isRejectPopup, setIsRejectPopup] = useState(false);
  const [isAcceptSbsPopup, setIsAcceptSbsPopup] = useState(false);
  const [isDeclineOtherPopup, setIsDeclineOtherPopup] = useState(false);
  const [isSbsDeclinePopup, setIsSbsDeclinePopup] = useState(false);

  const { dataState: personalLossContext, clearAllDataState } =
    useContext(perilContext);

  // Use context data if present, else state if present
  let personalLossDataFull = personalLossState;
  if (
    !isEmpty({
      ...personalLossContext?.personalLossData,
    })
  ) {
    personalLossDataFull = personalLossContext;
  }

  const personalLossDataListContext = {
    ...personalLossDataFull?.personalLossData,
  };

  const {
    claimReference,
    status = {},
    relevantPolicyData = {},
  } = personalLossDataListContext;
  const { applicableExcess } = relevantPolicyData;

  const { referrals = [], declines = [] } = status;
  const isFixedGlazingRefer = referrals.includes(
    'GlazingEstimateOverThreshold',
  );
  const isWithinXS = declines.includes('ExcessTooHigh');
  const itemTypeSelected = personalLossDataFull?.itemType;
  const isElectronicDevice = ['tv', 'mobile', 'laptop'].includes(
    itemTypeSelected,
  );
  const isFixedGlazing = itemTypeSelected === 'fixedGlazing';
  const glazingEstimateInvoiceReceipt =
    personalLossDataFull?.glazingEstimateInvoiceReceipt === 'yes';

  const isEndOfJourney = () => {
    return isWithinXS;
  };

  useEffect(() => {
    if (!redirected) {
      window.scrollTo(0, 0);
      if (!navigatePath) {
        if (isEmpty(personalLossDataListContext)) {
          setNavigatePath('/homeinsurance');
        } else {
          // If context set but not state, Move context to state
          if (!personalLossState) {
            setPersonalLossState(personalLossDataFull);
          }
          // If decline condition (so no further progress allowed), delete
          // context
          if (isEndOfJourney()) {
            clearAllDataState();
          }
          setRenderPage(true);
        }
      }
    }
  }, [personalLossDataFull]);

  useEffect(() => {
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const renderThankYouPage = (accept, supplier) => {
    const thankYouData = {
      supplierName: supplier ? supplier.supplierName : '',
      supplierNumber: supplier ? supplier.supplierTelephone : '',
      isAccept: accept,
      isComplete: true,
      isClaimRef: claimReference,
      userContactNumber: personalLossDataFull?.userContactNumber,
      itemType: itemTypeSelected,
      isReferredData: isFixedGlazingRefer,
      isReceipt: glazingEstimateInvoiceReceipt,
      perilCode,
      description,
    };

    setNavigateToData(thankYouData);
    setIsLoading(false);
    setNavigatePath(perilRoutes[perilCode].thankYou);
    clearAllDataState();
  };

  const rejectHomeClaimWithExcessDeclineCode = {
    claimReference,
    reason: declineCode.UNECONOMIC,
  };

  const rejectHomeClaimWithOtherDeclineCode = {
    claimReference,
    reason: declineCode.OTHER,
  };

  const acceptHomeClaimData = {
    claimReference,
  };

  const postRequest = (endpoint, payload, accept) => {
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(endpoint, payload, {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(data => {
        setIsLoading(false);
        setServerError(false);
        renderThankYouPage(accept, data?.data?.supplierDetails);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  };

  const sbsRedirect = () => {
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(
        config.client.acceptHomeClaim_endpoint,
        {
          claimReference,
        },
        { cancelToken: axiosCancelToken.current.token },
      )
      .then(({ data }) => {
        setRedirected(true);
        clearAllDataState(true);
        window.location.assign(
          config.client.sbsUrl +
            encodeURIComponent(data.supplierDetails.supplierRedirectUrl),
        );
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  };

  const renderServerErrorComponent = () => {
    return (
      serverError && (
        <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
      )
    );
  };

  const renderFinalSection = () => {
    setServerError(false);
    setFinalContent(true);
  };

  const renderSbsDeclineExcessSection = () => {
    setServerError(false);
    setFinalContent(false);
    setIsYesNoButton(false);
    setIsSbsDeclinePopup(true);
  };

  const renderDeclineExcessSection = () => {
    setServerError(false);
    setFinalContent(false);
    setIsYesNoButton(false);
    setIsRejectPopup(true);
  };

  const renderDeclineOtherSection = () => {
    setServerError(false);
    setIsYesNoButton(false);
    setIsDeclineOtherPopup(true);
  };

  const acceptExcess = () => {
    setServerError(false);
    postRequest(
      config.client.acceptHomeClaim_endpoint,
      acceptHomeClaimData,
      true,
    );
  };

  const renderUploadWithBankSection = () => {
    setUpload(true);
  };

  const onConfirmAccept = () => {
    setIsAcceptPopup(false);
    if (
      isFixedGlazing &&
      glazingEstimateInvoiceReceipt &&
      checkFeatureSwitch(
        'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING_UPLOAD',
        ['version1'],
      )
    ) {
      return renderUploadWithBankSection();
    }
    return acceptExcess();
  };

  const onConfirmReject = () => {
    setIsRejectPopup(false);
    postRequest(
      config.client.rejectHomeClaim_endpoint,
      rejectHomeClaimWithExcessDeclineCode,
      false,
    );
  };

  const onConfirmDeclineOther = () => {
    setIsDeclineOtherPopup(false);
    postRequest(
      config.client.rejectHomeClaim_endpoint,
      rejectHomeClaimWithOtherDeclineCode,
      false,
    );
  };

  const onConfirmSbsDecline = () => {
    setIsSbsDeclinePopup(false);
    postRequest(
      config.client.rejectHomeClaim_endpoint,
      rejectHomeClaimWithExcessDeclineCode,
      false,
    );
  };

  const onConfirmSbsDeclineClose = () => {
    setIsSbsDeclinePopup(false);
    setIsYesNoButton(true);
  };

  const onConfirmAcceptClose = () => {
    setIsAcceptPopup(false);
    setIsYesNoButton(true);
  };

  const onConfirmRejectClose = () => {
    setIsRejectPopup(false);
    setIsYesNoButton(true);
  };

  const onConfirmDeclineOtherClose = () => {
    setIsDeclineOtherPopup(false);
    setIsYesNoButton(true);
  };

  const onConfirmAcceptToSbs = () => {
    setIsAcceptSbsPopup(false);
    sbsRedirect();
  };

  const onConfirmAcceptToSbsClose = () => {
    setIsAcceptSbsPopup(false);
    setIsYesNoButton(true);
  };

  const redirectToSbs = () => {
    setIsYesNoButton(false);
    setIsAcceptSbsPopup(true);
  };

  const renderAcceptExcessResult = () => {
    setIsYesNoButton(false);
    setIsAcceptPopup(true);
  };

  const yesConfirmationText =
    'Please confirm you wish to proceed with your claim.';
  const noConfirmationText =
    'Please confirm you no longer wish to proceed with your claim.';

  const renderSBSExcessSection = () => {
    return (
      <>
        <P>
          Your policy has an excess of <strong>£{applicableExcess}</strong>.
          This is the amount you have to pay towards the cost of your claim.
          Would you like to proceed with this claim?
        </P>
        {isYesNoButton && (
          <YesNoButton
            yesButtonLabel="Yes - proceed"
            declineOnClick={renderSbsDeclineExcessSection}
            acceptOnClick={renderFinalSection}
          />
        )}
        {isSbsDeclinePopup &&
          renderPopup(
            noConfirmationText,
            onConfirmSbsDecline,
            onConfirmSbsDeclineClose,
          )}
      </>
    );
  };

  const renderSBSAcceptQuestionText = () => {
    return (
      <>
        <P>
          You will now be transferred to our preferred supplier, SBS Insurance
          Services, to complete the final steps of your online claim. Would you
          like to continue with this claim?
        </P>
        {isYesNoButton && (
          <YesNoButton
            yesButtonLabel="Yes - continue"
            declineOnClick={renderDeclineOtherSection}
            acceptOnClick={redirectToSbs}
          />
        )}
        {isAcceptSbsPopup &&
          renderPopup(
            yesConfirmationText,
            onConfirmAcceptToSbs,
            onConfirmAcceptToSbsClose,
          )}
        {isDeclineOtherPopup &&
          renderPopup(
            noConfirmationText,
            onConfirmDeclineOther,
            onConfirmDeclineOtherClose,
          )}
      </>
    );
  };

  const renderExcessAcceptQuestionText = () => {
    return (
      <>
        <P>
          Please note that your policy has an excess of{' '}
          <strong>£{applicableExcess}</strong>. This is the amount you will have
          to pay towards the cost of your claim. Would you like to proceed with
          your claim?
        </P>
        {isYesNoButton && (
          <YesNoButton
            yesButtonLabel="Yes - proceed"
            declineOnClick={renderDeclineExcessSection}
            acceptOnClick={renderAcceptExcessResult}
          />
        )}
        {isAcceptPopup &&
          renderPopup(
            yesConfirmationText,
            onConfirmAccept,
            onConfirmAcceptClose,
          )}
        {isRejectPopup &&
          renderPopup(
            noConfirmationText,
            onConfirmReject,
            onConfirmRejectClose,
          )}
      </>
    );
  };

  const renderInstructSupplierSection = () => {
    const SUPPLIER_TYPE = {
      carpets: 'flooring',
      fixedGlazing: 'glazing',
      jewellery: 'jewellery',
    };
    return (
      <>
        <P>
          We will be appointing our specialist{' '}
          {SUPPLIER_TYPE[itemTypeSelected] || ''} supplier to assist you further
          with your claim.
        </P>
        {renderExcessAcceptQuestionText()}
      </>
    );
  };

  const renderWithinExcessSection = () => {
    const ITEM_WORDING = {
      carpets: 'your carpet',
      fixedGlazing: 'the fixed glazing',
      jewellery: 'your jewellery',
    };
    return (
      <>
        <P>
          As your policy has an excess of <strong>£{applicableExcess}</strong>,
          which is the amount you have to pay towards the cost of your claim, we
          are unable to process your claim as the value you have specified to
          replace {ITEM_WORDING[itemTypeSelected] || 'your item'} is within your
          excess amount.
        </P>
        <P>
          A reference of <strong>{claimReference}</strong> has been allocated to
          this incident.
        </P>
        <P>
          Please call the claims team on <HomeClaimsFNOL /> between the hours of{' '}
          {businessHoursDescriptive} if you wish to discuss the matter further.
        </P>
        <P>We are sorry we cannot be of any assistance on this occasion.</P>
      </>
    );
  };

  const renderNonSBSExcessSection = () => {
    if (isWithinXS) {
      return renderWithinExcessSection();
    }
    if (isFixedGlazing && glazingEstimateInvoiceReceipt) {
      return renderExcessAcceptQuestionText();
    }
    return renderInstructSupplierSection();
  };

  const renderExcessSection = () => {
    return (
      <>
        {isElectronicDevice && (
          <>
            {renderSBSExcessSection()}
            {finalContent && (
              <SBSContainer>{renderSBSAcceptQuestionText()}</SBSContainer>
            )}
          </>
        )}
        {!isElectronicDevice && renderNonSBSExcessSection()}
      </>
    );
  };

  if (navigatePath) {
    return <NavigateTo path={navigatePath} data={navigateToData} />;
  }
  if (!renderPage) {
    return null;
  }

  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <ClaimBreadcrumbPL activeItem="results" itemType={itemTypeSelected} />
        <PageSectionBare>
          <Subsection>
            {renderExcessSection()}
            {upload && (
              <PersonalLossReceiptResult
                perilCode={perilCode}
                perilDescription={description}
                perilContext={perilContext}
                itemType={itemTypeSelected}
              />
            )}
            {renderServerErrorComponent()}
            {isLoading && <LoadingSpinner />}
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

renderPopup.propTypes = {
  titleDescription: PropTypes.string.isRequired,
  onConfirmYes: PropTypes.func,
  onConfirmNo: PropTypes.func,
};

renderPopup.defaultProps = {
  onConfirmYes: () => {},
  onConfirmNo: () => {},
};

PersonalLossResult.propTypes = {
  perilCode: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  perilContext: PropTypes.shape({}).isRequired,
};

export default PersonalLossResult;

export const PersonalLossResultAccidentalDamage = ({ ...props }) => {
  return (
    <PersonalLossResult
      {...props}
      perilCode="AD"
      description="accidental damage"
      perilContext={AccidentalDamageContext}
    />
  );
};

export const PersonalLossResultAccidentalLoss = ({ ...props }) => {
  return (
    <PersonalLossResult
      {...props}
      perilCode="AL"
      description="accidental loss"
      perilContext={AccidentalLossContext}
    />
  );
};

export const PersonalLossResultTheft = ({ ...props }) => {
  return (
    <PersonalLossResult
      {...props}
      perilCode="TF"
      description="theft"
      perilContext={TheftContext}
    />
  );
};
