import React from 'react';
import FaqP from '../../FaqP';

export const freezerFoodClaimTitle =
  'What can I expect when making a claim for damage to fridge/freezer food?';
export const freezerFoodClaimContent = (
  <>
    <FaqP>
      Based on the information you provide, we will check your policy to ensure
      you are covered and let you know how we can help.
    </FaqP>
    <FaqP>
      We want to make claiming as easy as possible, so we look to settle all
      damage to fridge/freezer food claims on the first call.
    </FaqP>
    <FaqP>
      Settlement of these types of claims will always be a cash payment.
    </FaqP>
    <FaqP>
      Please note that during 2020, the average amount we paid per claim to our
      customers in respect of this peril was £122.00.
    </FaqP>
  </>
);
