import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Tab as ImportedTab,
  TabList,
  TabPanel as ImportedTabPanel,
  Tabs,
} from 'ageas-ui-components';
import HubClaimPageWrapper from '../../components/HomeHubClaims/HubClaimPageWrapper/HubClaimPageWrapper';
import { PageSectionBare } from '../../components/StandardPageWrapper/StandardPageWrapperStyle';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import { HubContext } from '../../helpers/hubContextHelper';
import HubMessenger from '../../components/HubMessenger/HubMessenger';
import media from '../../components/MediaQuery/MediaQuery';
import ClaimDetails from '../../components/HomeHubClaims/ClaimDetails/ClaimDetails';
import RenderOnFirstActive from '../../components/RenderOnFirstActive/RenderOnFirstActive';
import Settlement from '../../components/HomeHubClaims/Settlement/Settlement';

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 300px;
    max-height: 600px;
  }
`;
const Tab = styled(ImportedTab)`
  font-size: 16px;
`;
const TabPanel = styled(ImportedTabPanel)`
  margin-left: 10px;
  margin-right: 10px;
  ${media.phone`
    margin-left: 0;
    margin-right: 0;
  `};
`;

const ClaimDetailsRenderer = ({ isActiveTab, activeTab, dataState }) => {
  return (
    <RenderOnFirstActive active={isActiveTab}>
      <ClaimDetails
        claimReference={dataState.claimDetails?.claimReference}
        perilCode={dataState.claimDetails?.perilCode}
        activeTab={activeTab}
      />
    </RenderOnFirstActive>
  );
};

ClaimDetailsRenderer.propTypes = {
  dataState: PropTypes.shape({
    claimDetails: PropTypes.shape({
      claimReference: PropTypes.string.isRequired,
      perilCode: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isActiveTab: PropTypes.bool,
  activeTab: PropTypes.string,
};
ClaimDetailsRenderer.defaultProps = {
  isActiveTab: undefined,
  activeTab: undefined,
};

const RenderingSettlement = ({ isActiveTab, activeTab, dataState }) => {
  return (
    <RenderOnFirstActive active={isActiveTab}>
      <Settlement
        claimReference={dataState.claimDetails?.claimReference}
        activeTab={activeTab}
      />
    </RenderOnFirstActive>
  );
};

RenderingSettlement.propTypes = {
  dataState: PropTypes.shape({
    claimDetails: PropTypes.shape({
      claimReference: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isActiveTab: PropTypes.bool,
  activeTab: PropTypes.string,
};
RenderingSettlement.defaultProps = {
  isActiveTab: undefined,
  activeTab: undefined,
};

const MessageCentre = ({ isActiveTab, dataState }) => {
  return (
    <RenderOnFirstActive active={isActiveTab}>
      <MessagesContainer>
        <HubMessenger
          claimReference={dataState.claimDetails?.claimReference}
          autoRefreshOnActive={isActiveTab}
        />
      </MessagesContainer>
    </RenderOnFirstActive>
  );
};
MessageCentre.propTypes = {
  dataState: PropTypes.shape({
    claimDetails: PropTypes.shape({
      claimReference: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isActiveTab: PropTypes.bool,
};
MessageCentre.defaultProps = {
  isActiveTab: undefined,
};

// Tab list
const TAB_LIST = [
  {
    id: 'details',
    tabHeader: 'Claim Details',
    TabContent: ClaimDetailsRenderer,
    tabPanelProps: { forceRender: true },
  },
  {
    id: 'settlement',
    tabHeader: 'Settlements',
    TabContent: RenderingSettlement,
  },
  {
    id: 'messages',
    tabHeader: 'Message Centre',
    TabContent: MessageCentre,
    tabPanelProps: { forceRender: true },
  },
];

const findTabIndex = id => TAB_LIST.findIndex(tab => tab.id === id);

const HomeHubClaimDetails = () => {
  const [currentTab, setCurrentTab] = useState(-1);
  const [render, setRender] = useState(false);
  const { dataState } = useContext(HubContext);
  const [navigatePath, setNavigatePath] = useState(null);
  const location = useLocation();
  const history = useHistory();

  // On page load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!dataState.claimDetails?.claimReference) {
      setNavigatePath('/homehub');
    } else {
      setRender(true);
    }
  }, []);

  // If location search changes, navigate to specified tab
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    let tabIndex = findTabIndex(tabParam);
    if (tabIndex < 0) {
      tabIndex = 0;
    }
    setCurrentTab(tabIndex);
  }, [location.search]);

  // Tab change? Navigate through URL query param
  const onTabSelect = tabIndex => {
    const newTabPath = `${location.pathname}?tab=${encodeURIComponent(
      TAB_LIST[tabIndex].id,
    )}`;
    history.push(newTabPath);
  };

  return (
    <>
      {navigatePath && <NavigateTo path={navigatePath} />}
      {render && currentTab >= 0 && (
        <HubClaimPageWrapper>
          <PageSectionBare>
            <Tabs selectedIndex={currentTab} onSelect={onTabSelect}>
              <TabList>
                {TAB_LIST.map(({ id, tabHeader }) => (
                  <Tab key={id}>{tabHeader}</Tab>
                ))}
              </TabList>
              {TAB_LIST.map(({ id, TabContent, tabPanelProps = {} }, index) => (
                <TabPanel key={id} {...tabPanelProps}>
                  <TabContent
                    index={index}
                    currentTab={currentTab}
                    dataState={dataState}
                    activeTab={TAB_LIST[currentTab]?.id}
                    isActiveTab={currentTab === index}
                  />
                </TabPanel>
              ))}
            </Tabs>
          </PageSectionBare>
        </HubClaimPageWrapper>
      )}
    </>
  );
};

export default HomeHubClaimDetails;
