import React from 'react';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StandardInput from '../Forms/StandardInput/StandardInput';

export const getLostPropertyValidationSchema = lossFieldName => {
  return {
    [lossFieldName]: stringRequired(
      [lossFieldName],
      'Please enter a valid lost property reference number',
    ).concat(
      // Note is case insensitive, must be upper cased in payload
      regularExpression(
        /^LR[A-Z0-9]{12}-[A-Z0-9]{12}$/i,
        [lossFieldName],
        'Please enter a valid lost property reference number',
      ),
    ),
  };
};

const SubLabel = styled.div`
  margin-top: 0.5em;
`;

const SUB_LABEL = (
  <SubLabel>
    You must report the loss to the appropriate authority. If you haven&apos;t
    done this already, please follow the link{' '}
    <a href="https://www.reportmyloss.com/uk" target="_blank" rel="noreferrer">
      https://www.reportmyloss.com/uk
    </a>{' '}
    and obtain a lost property reference number.
  </SubLabel>
);

const LostPropertyNumberField = ({ name, validate = () => {}, ...rest }) => {
  const LOST_PROPERTY_NUMBER_FIELDNAME = name;

  return (
    <StandardInput
      name={LOST_PROPERTY_NUMBER_FIELDNAME}
      label="Please tell us the lost property reference number"
      secondaryLabel={SUB_LABEL}
      small
      maxLength="27"
      validate={validate}
      aria-label="lost property number"
      placeholder="LRxxxxxxxxxxxx-xxxxxxxxxxxx"
      {...rest}
    />
  );
};

export default LostPropertyNumberField;

LostPropertyNumberField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
};

LostPropertyNumberField.defaultProps = {
  name: undefined,
  validate: undefined,
};
