import React from 'react';
import { ThemedButton } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import StandardPopup, {
  ModalButtonsContainer,
} from '../../components/StandardPopup/StandardPopup';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';
import P from '../../components/StandardTags/P';

const YesNoPopup = ({ titleDescription, onConfirmYes, onConfirmNo }) => {
  return (
    <StandardPopup>
      <Spacer marginBottom="10px">
        <P>{titleDescription}</P>
      </Spacer>
      <ModalButtonsContainer>
        <ThemedButton primary small type="button" onClick={onConfirmYes}>
          Yes
        </ThemedButton>
        <ThemedButton
          inverted
          primary
          small
          type="button"
          onClick={onConfirmNo}
        >
          No
        </ThemedButton>
      </ModalButtonsContainer>
    </StandardPopup>
  );
};
YesNoPopup.propTypes = {
  titleDescription: PropTypes.string.isRequired,
  onConfirmYes: PropTypes.func,
  onConfirmNo: PropTypes.func,
};

YesNoPopup.defaultProps = {
  onConfirmYes: () => {},
  onConfirmNo: () => {},
};

export default YesNoPopup;
