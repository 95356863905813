import { DateTime } from 'luxon';
import moment from 'moment';

//= ====================
// New Luxon converters.

const HMS_PATTERN_TEST = /^\d{2}:\d{2}:\d{2}$/;
const DMY_PATTERN = 'dd/MM/yyyy';
const HM_PATTERN = 'HH:mm';
const HMS_PATTERN = `${HM_PATTERN}:ss`;

/**
 * Determine if a time string is HH:MM or HH:MM:SS, and return the
 * corresponding Luxon pattern
 * @param {*} timeString
 * @returns
 */
const deriveTimePattern = timeString => {
  if (HMS_PATTERN_TEST.test(timeString)) {
    return HMS_PATTERN;
  }
  return HM_PATTERN;
};

/**
 * Wrapper for DateTime.fromISO (forces utc)
 * Converts ISO date and dateTime strings to DateTime object
 * E.g.
 *   yyyy-mm-dd
 *   yyyy-mm-ddThh:mm:ss
 * @param {*} dateString date/dateTime string
 * @returns DateTime object
 */
const isoToLuxon = dateString => {
  return DateTime.fromISO(dateString, { zone: 'utc' });
};

/**
 * Convert DD/MM/YYYY date string to DateTime object (forces utc)
 * @param {*} dateString in DD/MM/YYYY format e.g. 31/12/2023
 * @returns DateTime object
 */
const dmyToLuxon = dateString => {
  return DateTime.fromFormat(dateString, DMY_PATTERN, { zone: 'utc' });
};

/**
 * Convert separate DD/MM/YYYY date and HH:MM / HH:MM:SS time strings
 * to single DateTime object (forces utc)
 * @param {*} dateString in DD/MM/YYYY format e.g. 31/12/2023
 * @param {*} timeString in HH:MM or HH:MM:SS pattern e.g. 23:10 or 23:10:51
 * @returns DateTime object
 */
const dmyAndHmsToLuxon = (dateString, timeString) => {
  const format = `${DMY_PATTERN} ${deriveTimePattern(timeString)}`;
  return DateTime.fromFormat(`${dateString} ${timeString}`, format, {
    zone: 'utc',
  });
};

/**
 * Convert DateTime object to DD/MM/YYYY date string
 * @param {*} dateObj DateTime object
 * @returns DD/MM/YYYY string e.g. 31/12/2023
 */
const luxonToDmy = dateObj => {
  return dateObj.toFormat(DMY_PATTERN);
};

/**
 * Convert DateTime object to HH:MM string
 * @param {*} dateObj DateTime object
 * @returns HH:MM string e.g. 23:10
 */
const luxonToHm = dateObj => {
  return dateObj.toFormat(HM_PATTERN);
};
/**
 * Convert DateTime object to HH:MM:SS string
 * @param {*} dateObj DateTime object
 * @returns HH:MM:SS string e.g. 23:10:50
 */
const luxonToHms = dateObj => {
  return dateObj.toFormat(HMS_PATTERN);
};

/**
 * Convert ISO dateTime string yyyy-mm-ddThh:mm:ss to DD/MM/YYYY date
 * string and HH:MM / HH:MM:SS time string
 * Returns object containing:
 *   isValid: true if dateTime string is valid, else false
 *   date: 'DD/MM/YYYY', // Date string in DD/MM/YYYY format, or undefined if
 *                          dateTime invalid
 *   time: 'HH:MM(:SS)', // Time string in HH:MM or HH:MM:SS format, or
 *                          undefined if dateTime invalid
 * @param {*} dateString dateTime string in ISO format e.g. 2023-12-31T23:10:50
 * @param {*} seconds if true result time will be HH:MM:SS, else only HH:MM
 * @returns object containing date and time e.g.
 * {
 *   isValid: true,
 *   date: '31/12/2023',
 *   time: '23:10:50'
 * }
 */
const isoToDmyAndHms = (dateString, seconds = false) => {
  const dateObj = isoToLuxon(dateString);
  const date = dateObj.isValid ? luxonToDmy(dateObj) : undefined;
  let time;
  if (dateObj.isValid) {
    time = seconds ? luxonToHms(dateObj) : luxonToHm(dateObj);
  }
  return {
    isValid: dateObj.isValid,
    date,
    time,
  };
};

/**
 * Convert separate DD/MM/YYYY date and HH:MM / HH:MM:SS time strings to
 * ISO DateTime string yyyy-mm-ddThh:mm:ss
 * @param {*} dateString in DD/MM/YYYY format e.g. 31/12/2023
 * @param {*} timeString in HH:MM or HH:MM:SS pattern e.g. 23:10 or 23:10:51
 * @returns dateTime string in ISO format e.g. 2023-12-31T23:10:50, or null
 *          if date or time are invalid
 */
const dmyAndHmsToIso = (dateString, timeString) => {
  const dateObj = dmyAndHmsToLuxon(dateString, timeString);
  return dateObj.toISO({ includeOffset: false, suppressMilliseconds: true });
};

/**
 * Convert ISO date string yyyy-mm-dd to DD/MM/YYYY date string
 * Returns object containing:
 *   isValid: true if dateTime string is valid, else false
 *   date: 'DD/MM/YYYY', // Date string in DD/MM/YYYY format, or undefined if
 *                          dateTime invalid
 * @param {*} dateString date string in ISO format e.g. 2023-12-31
 * @returns object containing date and time e.g.
 * {
 *   isValid: true,
 *   date: '31/12/2023',
 * }
 */
const isoToDmy = dateString => {
  const dateObj = isoToLuxon(dateString);
  const date = dateObj.isValid ? luxonToDmy(dateObj) : undefined;
  return {
    isValid: dateObj.isValid,
    date,
  };
};

/**
 * Convert DD/MM/YYYY date strings to ISO Date string yyyy-mm-dd
 * @param {*} dateString in DD/MM/YYYY format e.g. 31/12/2023
 * @returns date string in ISO format e.g. 2023-12-31, or null
 *          if date is invalid
 */
const dmyToIso = dateString => {
  const dateObj = dmyToLuxon(dateString);
  return dateObj.toISODate();
};

//= ====================
// Legacy moment converters. Depreciated, use above

/**
 * Convert yyyy-mm-dd date and hh:mm time to moment object
 * @param {*} date
 * @param {*} time
 * @param {*} strict
 * @returns moment object
 * @deprecated
 */
const dateTimeConverter = (date, time, strict = true) => {
  return moment.utc(`${date} ${time}`, 'DD/MM/YYYY HH:mm', strict);
};

/**
 * Convert yyyy-mm-dd date time to moment object
 * @param {*} date
 * @param {*} strict
 * @returns moment object
 * @deprecated
 */
const dateISOConverter = (date, strict = true) => {
  return moment.utc(date, 'DD/MM/YYYY', strict).format('YYYY-MM-DD');
};

/**
 * Convert ISO date/dateTime to dd/mm/YYYY
 * @param {*} date
 * @returns dd/mm/YYYY
 * @deprecated
 */
const reverseDate = date => {
  return moment.utc(date).format('DD/MM/YYYY');
};

/**
 * Convert ISO dateTime to HH:MM
 * @param {*} date
 * @returns HH:MM
 * @deprecated
 */
const formatTime = time => {
  return moment.utc(time).format('HH:mm');
};

/**
 * Convert ISO time HH:MM:SS to HH:MM
 * @param {*} date
 * @returns HH:MM
 * @deprecated
 */
const formatISOTime = time => {
  return moment.utc(time, 'HH:mm:ss').format('HH:mm');
};

export {
  isoToLuxon,
  dmyToLuxon,
  dmyAndHmsToLuxon,
  luxonToDmy,
  luxonToHm,
  luxonToHms,
  isoToDmyAndHms,
  dmyAndHmsToIso,
  isoToDmy,
  dmyToIso,
  dateTimeConverter,
  dateISOConverter,
  reverseDate,
  formatTime,
  formatISOTime,
};
