import React from 'react';
import {
  ThemedLabel,
  ThemedErrorMessage,
  ThemedInputWithAutocomplete,
} from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';

export const getStandardAutoCompleteRequiredSchema = (
  fieldName,
  customRequiredError,
) => {
  return {
    [fieldName]: stringRequired(
      [fieldName],
      customRequiredError || 'Please enter a value',
    ),
  };
};

const outerPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  secondaryLabel: PropTypes.node,
  validate: PropTypes.func,
  children: PropTypes.node,
  alwaysShowError: PropTypes.bool,
  fieldProps: PropTypes.shape({}),
  labelProps: PropTypes.shape({}),
  blockProps: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
};
const outerDefaultProps = {
  id: undefined,
  label: '',
  secondaryLabel: undefined,
  validate: () => {},
  children: undefined,
  alwaysShowError: false,
  onChange: () => {},
  fieldProps: {},
  labelProps: {},
  blockProps: {},
  options: [],
  disabled: undefined,
};

const StandardAutoCompleteInner = ({
  name,
  label,
  secondaryLabel,
  validate,
  children,
  alwaysShowError,
  fieldProps,
  labelProps,
  blockProps,
  options,
  disabled,
  input,
  meta,
  ...props
}) => {
  const isInvalid = !!(meta.error && (meta.touched || alwaysShowError));

  const handleSelected = selected => {
    input.onChange(
      selected ? { value: selected.value, label: selected.label } : undefined,
    );
  };

  return (
    <>
      <ThemedLabel fieldName={name} {...labelProps}>
        {label}
        {secondaryLabel && (
          <>
            <br />
            <small>{secondaryLabel}</small>
          </>
        )}
      </ThemedLabel>
      <FieldInnerBlockContainer marginTop="-6px" {...blockProps}>
        <ThemedInputWithAutocomplete
          autoComplete="off"
          fieldName={name}
          isValid={!isInvalid}
          hasError={isInvalid}
          defaultValue={input.value?.label}
          handleSelected={handleSelected}
          suggestions={options}
          enableAbortHanding
          formInput={{ disabled, autoComplete: 'off' }}
          autoUnselectOnInputChange
          autoSelectOnExactMatch
          {...props}
        />
      </FieldInnerBlockContainer>
      {isInvalid && (
        <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
      )}
      {children}
    </>
  );
};

StandardAutoCompleteInner.propTypes = {
  ...outerPropTypes,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

const StandardAutoComplete = props => {
  const { name, validate, fieldProps } = props;

  return (
    <FieldStyled name={name} validate={validate} type="text" {...fieldProps}>
      {({ input, meta }) => {
        return (
          <StandardAutoCompleteInner input={input} meta={meta} {...props} />
        );
      }}
    </FieldStyled>
  );
};

StandardAutoComplete.propTypes = {
  ...outerPropTypes,
};

StandardAutoComplete.defaultProps = {
  ...outerDefaultProps,
};

export default StandardAutoComplete;
