import React from 'react';
import PropTypes from 'prop-types';
import { ukPostcode } from 'ageasvalidation/lib/schemas/UKPostcode';
import StandardInput, {
  getStandardInputOptionalSchema,
  getStandardInputRequiredSchema,
} from '../../StandardInput/StandardInput';
import PostcodeField from '../../Address/PostcodeField';

const DEFAULT_FIELD_NAME = 'involvedParty';

export const getInvolvedPartyAddressSchema = fieldName => {
  return {
    ...getStandardInputRequiredSchema(
      `${fieldName}.houseNumberName`,
      'Please enter a house number or name',
    ),
    ...getStandardInputRequiredSchema(
      `${fieldName}.streetLine1`,
      'Please enter a street or road name',
    ),
    ...getStandardInputOptionalSchema(`${fieldName}.streetLine2`),
    ...getStandardInputOptionalSchema(`${fieldName}.district`),
    ...getStandardInputRequiredSchema(
      `${fieldName}.town`,
      'Please enter a town or city name',
    ),
    ...getStandardInputOptionalSchema(`${fieldName}.county`),
    [`${fieldName}.postcode`]: ukPostcode(
      [`${fieldName}.postcode`],
      'Please enter a valid UK postcode',
    ),
  };
};

// For aria-label, label, and placeholder:
// * If boolean true, use commonLabel
// * If truthy, use self
// * If falsey, use undefined
const DEFAULT_LABELLING = {
  houseNumberName: {
    commonLabel: 'House number or name',
    'aria-label': true,
    label: true,
    placeholder: undefined,
  },
  streetLine1: {
    commonLabel: 'Street 1',
    'aria-label': 'Street or road line 1',
    label: true,
    placeholder: undefined,
  },
  streetLine2: {
    commonLabel: 'Street 2',
    'aria-label': 'Street or road line 2',
    label: true,
    placeholder: undefined,
  },
  district: {
    commonLabel: 'District',
    'aria-label': true,
    label: true,
    placeholder: undefined,
  },
  town: {
    commonLabel: 'Town or city',
    'aria-label': true,
    label: true,
    placeholder: undefined,
  },
  county: {
    commonLabel: 'County',
    'aria-label': true,
    label: true,
    placeholder: undefined,
  },
  postcode: {
    commonLabel: 'Postcode',
    'aria-label': true,
    label: true,
    placeholder: undefined,
  },
};

const getLabel = (type, labels) => {
  if (labels[type] === true) {
    return labels.commonLabel;
  }
  return labels[type] || undefined;
};

const computeLabels = (subName, labelling) => {
  const labels = {
    ...(DEFAULT_LABELLING?.[subName] || {}),
    ...(labelling?.[subName] || {}),
  };

  return {
    placeholder: getLabel('placeholder', labels),
    label: getLabel('label', labels),
    'aria-label': getLabel('aria-label', labels),
  };
};

const computeNameAndLabels = (name, subName, labelling) => {
  const fullName = `${name}.${subName}`;
  return {
    name: fullName,
    ...computeLabels(subName, labelling),
  };
};

const InvolvedPartyAddress = ({
  name = DEFAULT_FIELD_NAME,
  validate,
  labelling,
  disabled,
}) => {
  return (
    <>
      <StandardInput
        {...computeNameAndLabels(name, 'houseNumberName', labelling)}
        validate={validate}
        small
        maxLength="50"
        disabled={disabled}
      />
      <StandardInput
        {...computeNameAndLabels(name, 'streetLine1', labelling)}
        validate={validate}
        small
        maxLength="40"
        disabled={disabled}
      />
      <StandardInput
        {...computeNameAndLabels(name, 'streetLine2', labelling)}
        validate={validate}
        small
        maxLength="40"
        disabled={disabled}
      />
      <StandardInput
        {...computeNameAndLabels(name, 'district', labelling)}
        validate={validate}
        small
        maxLength="30"
        disabled={disabled}
      />
      <StandardInput
        {...computeNameAndLabels(name, 'town', labelling)}
        validate={validate}
        small
        maxLength="30"
        disabled={disabled}
      />
      <StandardInput
        {...computeNameAndLabels(name, 'county', labelling)}
        validate={validate}
        small
        maxLength="30"
        disabled={disabled}
      />
      <PostcodeField
        {...computeNameAndLabels(name, 'postcode', labelling)}
        validate={validate}
        xSmall
        format
        disabled={disabled}
      />
    </>
  );
};

InvolvedPartyAddress.propTypes = {
  name: PropTypes.string,
  header: PropTypes.string,
  headerProps: PropTypes.shape({}),
  labelling: PropTypes.shape({
    commonLabel: PropTypes.string,
    'aria-label': PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
  }),
  company: PropTypes.bool,
  companyLabel: PropTypes.string,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
};
InvolvedPartyAddress.defaultProps = {
  name: undefined,
  header: undefined,
  headerProps: undefined,
  labelling: undefined,
  company: false,
  companyLabel: undefined,
  validate: undefined,
  disabled: false,
};

export default InvolvedPartyAddress;
