import styled from 'styled-components';

const NewUpdatesStyle = styled.span`
  background-color: ${({ theme }) => theme.button.secondary.hover.background};
  border-color: ${({ theme }) => theme.button.secondary.hover.background};
  color: ${({ theme }) => theme.button.primary.text};
  margin-left: 1em;
  padding: 0.3em 0.5em;
  border-radius: 1em;
  font-size: 0.8rem;
`;

export default NewUpdatesStyle;
