import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UploadDocuments, CustomList } from 'ageas-ui-components';
import { getImages } from 'ageas-ui-themes';

import styled from 'styled-components';
import UploaderCategoryCustomField from './UploaderCategoryCustomField';
import P from '../StandardTags/P';

import { hubUploadErrorHome } from '../../helpers/errorMessages';
import config from '../../helpers/config';
import { brand } from '../../../data/whitelabel.config';
import documentUploadTypes, {
  FILE_TYPES,
} from '../../helpers/documentUploadTypes';
import axiosHelper from '../../helpers/axios';
import { getPerilByCode } from '../../helpers/perilCodesDescriptions';
import { UploadModalHeader } from './DocumentUploaderComponents';

const ImageStore = getImages(brand);

const PNoBottom = styled(P)`
  margin-bottom: 0px;
`;

const UPLOAD_INSTRUCTIONS = (
  <>
    <P>We can accept the following:</P>
    <CustomList
      items={['Estimates', 'Receipts', 'Legal documents', 'Photos of damage']}
      bulletIcon={ImageStore.greenTick}
    />
  </>
);

const UPLOAD_SUCCESS_INFO = (
  <>
    <P>Thank you for providing your document.</P>
    <P>
      If you need to upload more than one document click &quot;Add
      another&quot;, otherwise click &quot;Close&quot; when you&apos;ve
      finished.
    </P>
  </>
);

const buildAxiosErrorMessage = (claimReference, perilCode, _payload, data) =>
  hubUploadErrorHome(
    claimReference,
    getPerilByCode(perilCode)?.lower || perilCode,
    documentUploadTypes[data.category]?.correspondenceType,
  );

/* eslint-disable import/prefer-default-export */
const getUploaderProps = (claimReference, perilCode) => {
  return {
    axiosPost: axiosHelper.post,
    axiosPostURL: config.client.uploadDocument_endpoint,
    header: 'Upload a document',
    instructionsTopSection:
      'This is where you can provide us with documentation in support of your claim.',
    instructionsSideSection: UPLOAD_INSTRUCTIONS,
    footer: (
      <PNoBottom>
        We can accept original copies, photos or screenshots (as long as we can
        see the whole document).
      </PNoBottom>
    ),
    uploadModalHeader: (
      <UploadModalHeader>
        This is where you can upload proof of your legal document, estimate,
        receipt or photos of damage.
      </UploadModalHeader>
    ),
    successfulUploadHeader:
      'Upload successful, we have received your documents',
    successfulUploadInfo: UPLOAD_SUCCESS_INFO,
    uploadFailError: (...props) =>
      buildAxiosErrorMessage(claimReference, perilCode, ...props),
    acceptFileTypesList: FILE_TYPES,
    fileSizeLimit: 7,
    brand,
    customUploadFieldsDataFormatter: ({ category }) => {
      return { category: documentUploadTypes[category].category };
    },
    showUploadedDocumentsSection: false,
    CustomUploadFields: UploaderCategoryCustomField,
    modalWrapperProps: {
      upload: {
        role: 'dialog',
        'aria-label': 'Upload a document',
      },
      another: {
        role: 'dialog',
        'aria-label': 'Upload another document',
      },
      close: {
        role: 'dialog',
        'aria-label': 'Confirm close document upload',
      },
    },
  };
};

const HomeHubUploader = ({
  claimReference,
  perilCode,
  notificationCodes,
  notificationSequence,
  externalModalState,
  onSuccessfulUpload,
}) => {
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const uploadedDocumentsState = [uploadedDocuments, setUploadedDocuments];

  const NotificationSpecificUploaderFields = () => (
    <UploaderCategoryCustomField notificationCodes={notificationCodes} />
  );

  return (
    <>
      {/* Instance that renders its own add button */}
      <UploadDocuments
        payloadKeys={{ claimReference }}
        onSuccessfulUpload={onSuccessfulUpload}
        uploadedDocumentsState={uploadedDocumentsState}
        {...getUploaderProps(claimReference, perilCode)}
      />
      {/* instance that renders modals only, with external add button */}
      <UploadDocuments
        payloadKeys={{ claimReference, notificationSequence }}
        onSuccessfulUpload={onSuccessfulUpload}
        uploadedDocumentsState={uploadedDocumentsState}
        {...getUploaderProps(claimReference, perilCode)}
        uploaderModalsOnly
        modalState={externalModalState}
        CustomUploadFields={NotificationSpecificUploaderFields}
      />
    </>
  );
};

export default HomeHubUploader;

HomeHubUploader.propTypes = {
  claimReference: PropTypes.string,
  perilCode: PropTypes.string,
  notificationCodes: PropTypes.arrayOf(PropTypes.string),
  notificationSequence: PropTypes.number,
  externalModalState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ),
  onSuccessfulUpload: PropTypes.func,
};

HomeHubUploader.defaultProps = {
  claimReference: undefined,
  perilCode: undefined,
  notificationCodes: undefined,
  notificationSequence: undefined,
  externalModalState: undefined,
  onSuccessfulUpload: undefined,
};
