import { useRef } from 'react';

/**
 * Return initial, until the first time that value changes from its original
 * value.
 * From then on, return value
 * @param {*} value to return once it changes
 * @param {*} initial to return until value changes, default is undefined
 * @returns initial until value changes then value
 */
const useInitialisedValue = (value, initial = undefined) => {
  const refs = useRef({ initialised: false, initialValue: value });

  // If not initialised and value changes, initialise
  if (!refs.current.initialised && value !== refs.current.initialValue) {
    refs.current.initialised = true;
  }

  // Return value if initialised, else return initial
  return refs.current.initialised ? value : initial;
};

export default useInitialisedValue;
