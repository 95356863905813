import React, { useContext, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import axios from 'axios';
import { Icon, ThemedButton, ThemedErrorMessage } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import P from '../StandardTags/P';
import {
  BoldBlack,
  ButtonsBottomContainer,
  ClosedNotification,
  PanelInner,
  TitleTextSpaced,
} from '../StandardPageWrapper/StandardPageWrapperStyle';
import {
  getErrorMessage,
  checkBankDetails as checkBankDetailsError,
  invalidBankDetailsMotor,
  serverErrorCustomTel,
} from '../../helpers/errorMessages';
import BankDetails, {
  getBankDetailsValidationSchema,
} from '../Forms/BankDetails/BankDetails';
import StandardInput from '../Forms/StandardInput/StandardInput';
import config from '../../helpers/config';
import {
  HubContextMotor,
  hubDataContextMotor,
} from '../../helpers/hubContextHelper';
import settlementPropTypes from './propTypes';
import LoadingSpinner from '../UI/LoadingSpinner/LoadingSpinner';
import validateField from '../../helpers/validationHelper';
import TelephoneLink from '../TelephoneLink/TelephoneLink';
import axiosHelper from '../../helpers/axios';
import { brand } from '../../../data/whitelabel.config';
import formatAmount from '../../helpers/formatAmount';
import { closeNotificationsByCode } from '../../helpers/hubNotificationsHelper';

const ACCOUNT_NAME = 'accountName';
const ACCOUNT_SORT_CODE = 'sortCode';
const ACCOUNT_NUMBER = 'accountNumber';
const V5C_NUMBER = 'v5cNumber';

const schema = {
  ...getBankDetailsValidationSchema(
    ACCOUNT_NAME,
    ACCOUNT_SORT_CODE,
    ACCOUNT_NUMBER,
  ),
  [V5C_NUMBER]: stringRequired(
    [V5C_NUMBER],
    'Please enter a valid reference, using numbers only',
  ).concat(
    // Must be exactly 11 numeric characters)
    regularExpression(
      /^[0-9]{11}$/,
      [V5C_NUMBER],
      'Please enter a valid reference, using numbers only',
    ),
  ),
};

const validateFields = (value, _allFields, meta) => {
  let error;
  if (schema[meta.name]) {
    error = validateField(value, meta, schema);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const renderCheckBankDetailsError = show =>
  show && (
    <ThemedErrorMessage hasIcon>{checkBankDetailsError()}</ThemedErrorMessage>
  );

const renderInvalidBankDetailsError = (show, telephone) =>
  show && (
    <ThemedErrorMessage hasIcon>
      {invalidBankDetailsMotor(<TelephoneLink number={telephone} />)}
    </ThemedErrorMessage>
  );

const renderServerErrorComponent = (show, telephone) =>
  show && (
    <ThemedErrorMessage hasIcon>
      {serverErrorCustomTel('motor', <TelephoneLink number={telephone} />)}
    </ThemedErrorMessage>
  );

const renderAcceptSubmitButton = show =>
  show && (
    <ButtonsBottomContainer>
      <ThemedButton secondary type="submit">
        Accept &amp; Submit
      </ThemedButton>
    </ButtonsBottomContainer>
  );

const renderSuccessMessage = amount => (
  <>
    <ClosedNotification
      status="closed"
      leftContent={
        <Icon
          className="icon-warning"
          brandIconSet={brand}
          icon="tick-roundel"
          color="green"
          size={34}
        />
      }
    >
      A payment of £{formatAmount(amount)} will be credited to your bank account
      within 3 working days.
    </ClosedNotification>
    <P>
      If they have not already done so, our salvage agents will be in contact
      with you to arrange collection and the vehicle will then be sold on by our
      agents.
    </P>
  </>
);

const filterNonNumericKeys = event => {
  // Check whether the char is a numeric char
  if (event.charCode >= 48 && event.charCode <= 57) return true;
  event.preventDefault();
  // If not numeric, dismiss it
  const e = event;
  e.returnValue = false;
  return false;
};

const MotorHubAcceptOfferForm = ({
  settlementData,
  tluHandlerTelephone,
  isTP = false,
  acceptOfferUrl = config.client.motorAcceptAndPayOffer_endpoint,
  Context = HubContextMotor,
}) => {
  const axiosCancelToken = useRef(null);
  const topAnchorRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [checkBankDetails, setCheckBankDetails] = useState(false);
  const [invalidBankDetails, setInvalidBankDetails] = useState(false);
  const [serverError, setServerError] = useState(false);

  const hubContextMotor = useContext(Context);
  const { dataState = {} } = hubContextMotor;
  const { claimReference } = dataState.claimDetails || {};

  const { amount, sequence } = settlementData.paymentOffers[0];

  const { leasedVehicle = false } = settlementData;

  // On page load
  useEffect(() => {
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const buildRequestPayload = values => {
    return {
      claimReference,
      offerSequence: sequence,
      bankDetails: {
        accountName: values[ACCOUNT_NAME].trim(),
        accountNumber: +values[ACCOUNT_NUMBER],
        sortCode: +values[ACCOUNT_SORT_CODE],
      },
      v5c: values[V5C_NUMBER]?.trimEnd(),
    };
  };

  // On success, scroll to success message
  useEffect(() => {
    if (isPaymentSuccess) {
      topAnchorRef.current.scrollIntoView();
    }
  }, [isPaymentSuccess]);

  const acceptAndPayOffer = (values, payment) => {
    setInvalidBankDetails(false);
    setCheckBankDetails(false);
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(acceptOfferUrl, buildRequestPayload(values), {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(data => {
        setIsLoading(false);
        if (payment) {
          const { paymentSuccess = false, errorCode = '' } = data?.data || {};
          if (paymentSuccess && !errorCode) {
            setIsPaymentSuccess(true);
            closeNotificationsByCode(
              hubContextMotor,
              hubDataContextMotor,
              'OF',
            );
          } else if (errorCode === 'TooManyAttempts') {
            setInvalidBankDetails(true);
          } else if (errorCode === 'InvalidDetails') {
            setCheckBankDetails(true);
          } else {
            setServerError(true);
          }
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  };

  const onSubmit = (values, payment) => {
    acceptAndPayOffer(values, payment);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <>
        <div ref={topAnchorRef} />
        {isPaymentSuccess && renderSuccessMessage(amount)}
        {!isPaymentSuccess && (
          <>
            <P>
              If you are happy to accept this offer, please enter your
              {!leasedVehicle && ' V5C reference number,'} bank details and
              click the &apos;Accept &amp; Submit&apos; button. This will allow
              us to make a payment to you today.{' '}
              <strong>
                Please note that, by accepting this settlement, ownership of the
                vehicle will transfer to Ageas and you will not be able to keep
                the vehicle.
              </strong>
            </P>
            {!isTP && (
              <P>
                If you pay for your policy monthly, you must contact your
                insurance provider to arrange payment of any outstanding
                premium.
              </P>
            )}
            <P>
              If you do not want to accept this settlement offer, please call us
              on <TelephoneLink number={tluHandlerTelephone} />
            </P>
            <PanelInner>
              {!leasedVehicle && (
                <>
                  <TitleTextSpaced black>Your V5C Number</TitleTextSpaced>

                  {isTP && (
                    <P>
                      <BoldBlack>Note:</BoldBlack>
                      <br />
                      If you have a private (personalised) number plate and you
                      wish to take the private number off (retention), transfer
                      it or sell it, then you will need to notify the DVLA. If
                      you haven&apos;t done this already, you can do so by
                      following the link{' '}
                      <a
                        href="https://www.gov.uk/personalised-vehicle-registration-numbers"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Private (personalised) number plates: Overview - GOV.UK
                        (www.gov.uk)
                      </a>
                    </P>
                  )}

                  <P>
                    <BoldBlack>V5 document reference number:</BoldBlack>
                    <br />
                    This will be an 11-digit reference number. We will need this
                    reference number to notify the DVLA that you no longer own
                    the vehicle. By providing this number, you are giving us
                    permission to update the DVLA and you&apos;ll get an email
                    confirmation once this has been done.
                  </P>
                </>
              )}

              <Form onSubmit={values => onSubmit(values, true)}>
                {({ handleSubmit }) => (
                  <form method="post" onSubmit={handleSubmit}>
                    {!leasedVehicle && (
                      <StandardInput
                        name={V5C_NUMBER}
                        label="V5C Number"
                        placeholder="Enter your V5C Document Reference Number"
                        validate={validateFields}
                        maxLength="11"
                        size="small"
                        onKeyPress={filterNonNumericKeys}
                      />
                    )}
                    <TitleTextSpaced black>Your Bank Details</TitleTextSpaced>

                    <BankDetails
                      name={[ACCOUNT_NAME, ACCOUNT_SORT_CODE, ACCOUNT_NUMBER]}
                      validate={validateFields}
                      headerInfo=""
                    />
                    {renderCheckBankDetailsError(checkBankDetails)}
                    {renderInvalidBankDetailsError(
                      invalidBankDetails,
                      tluHandlerTelephone,
                    )}

                    {renderServerErrorComponent(
                      serverError,
                      tluHandlerTelephone,
                    )}
                    {renderAcceptSubmitButton(
                      !invalidBankDetails && !isPaymentSuccess,
                    )}
                  </form>
                )}
              </Form>
            </PanelInner>
          </>
        )}
      </>
    </>
  );
};

MotorHubAcceptOfferForm.propTypes = {
  settlementData: settlementPropTypes.settlementData.isRequired,
  tluHandlerTelephone: PropTypes.string.isRequired,
  isTP: PropTypes.bool,
  acceptOfferUrl: PropTypes.string,
  Context: PropTypes.shape({}),
};

MotorHubAcceptOfferForm.defaultProps = {
  isTP: undefined,
  acceptOfferUrl: undefined,
  Context: undefined,
};

export default MotorHubAcceptOfferForm;
