import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ConfirmUpdate from './ConfirmUpdate';
import FieldArrayDisplay from '../../../components/Forms/FieldArrayDisplay/FieldArrayDisplay';

const previousConvictionDisplayMapper = props => {
  const { convictionDate, convictionType } = props;
  return `${convictionDate} ${convictionType?.label || ''}`.trim() || 'Unknown';
};

const ConvictionsConfirmUpdate = ({
  title,
  yesNoName,
  updateFieldName,
  value,
  validateYesNo,
  validateField,
  validate,
  disabled,
  fieldProps = {},
  confirmUpdateProps = {},
  Context,
}) => {
  const { onAdd, onEdit, hasBeenTouched } = useContext(Context);

  return (
    <ConfirmUpdate
      title={title}
      value={value}
      yesNoName={yesNoName}
      validate={validateYesNo || validate}
      disabled={disabled}
      renderChildrenOn={['yes']}
      allowUnknown
      hint="If the driver has had any driving convictions in the past 5 years, please provide the date of the conviction and the conviction code"
      {...confirmUpdateProps}
    >
      <FieldArrayDisplay
        name={updateFieldName}
        label="Please enter the details of the conviction(s)"
        onAdd={onAdd}
        onEdit={onEdit}
        addLabel="Add conviction"
        addButtonLabel="Add conviction"
        editLabel="Edit conviction"
        maxRows={10}
        displayMapper={previousConvictionDisplayMapper}
        validate={validateField || validate}
        displayArrayErrors={hasBeenTouched ? 'always' : true}
        disabled={disabled}
        {...fieldProps}
      />
    </ConfirmUpdate>
  );
};

export default ConvictionsConfirmUpdate;

ConvictionsConfirmUpdate.propTypes = {
  title: PropTypes.string,
  yesNoName: PropTypes.string.isRequired,
  updateFieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  validateYesNo: PropTypes.func,
  validateField: PropTypes.func,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.instanceOf(Object),
  confirmUpdateProps: PropTypes.instanceOf(Object),
  Context: PropTypes.instanceOf(Object),
};
ConvictionsConfirmUpdate.defaultProps = {
  title: undefined,
  value: undefined,
  validateYesNo: undefined,
  validateField: undefined,
  validate: undefined,
  disabled: undefined,
  fieldProps: undefined,
  confirmUpdateProps: undefined,
  Context: undefined,
};
