import React, { Component } from 'react';
import crypto from 'crypto-browserify';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import moment from 'moment';
import axios from '../helpers/axios';
import config from '../helpers/config';
import getPublicPayloadFromJWTToken from '../helpers/getPublicPayloadFromJWTToken';

const AuthContext = React.createContext({});

const AUTHENTICATION_TYPES = {
  ageas_direct: 'portal',
  rias_direct: 'portal',
  ageas_eclaims_otp: 'otp',
  ageas_eclaims_motor_tp_otp: 'motortpotp',
  ageas_eclaims_basic: 'basic',
};

export const setAuthCookie = (name, value) => {
  cookie.save(name, value, {
    path: '/',
    expires: moment().add(config.client.cookieExpiryMinutes, 'minute').toDate(),

    secure: config.client.cookieSecure === 'true',
  });
};

export const removeAuthCookie = name => {
  cookie.remove(name, {
    path: '/',
  });
};

export const setLastLoginMethodCookie = value => {
  cookie.save('last_login_method', value, {
    path: '/',
    expires: moment()
      .add(config.client.lastLogonTypeCookieExpiryMinutes, 'minute')
      .toDate(),

    secure: config.client.cookieSecure === 'true',
  });
};

export const buildUid = (
  type = '',
  lineOfBusiness = '',
  idRef = '',
  idRef2 = '',
) => {
  return `${type}|${lineOfBusiness}|${idRef}|${idRef2}|`;
};

export const decodeUid = (uid = '') => {
  const components = uid.split('|');
  const decoded = {};
  const KEY_NAMES = ['type', 'lineOfBusiness', 'idRef', 'idRef2'];
  for (let i = 0; i < components.length && i < KEY_NAMES.length; i += 1) {
    if (components[i]) {
      decoded[KEY_NAMES[i]] = components[i];
    }
  }
  return decoded;
};

const getAuthTypeFromToken = token => {
  const tokenPayload = getPublicPayloadFromJWTToken(token);
  return [AUTHENTICATION_TYPES[tokenPayload?.aud], tokenPayload];
};

const getTokenCookie = () => {
  return cookie.load('token');
};

const buildUidFromToken = token => {
  let type;
  let tokenPayload;
  if (token) {
    [type, tokenPayload] = getAuthTypeFromToken(token);
    if (!type) {
      return '';
    }
  } else {
    return '';
  }

  if (type === 'portal') {
    return buildUid('portal', 'motor', tokenPayload.strataUsername, '');
  }

  if (tokenPayload.lineOfBusiness === 'motorTPA') {
    return buildUid(
      type,
      tokenPayload.lineOfBusiness,
      tokenPayload.claimReference,
      tokenPayload.claimantReference,
    );
  }

  return buildUid(
    type,
    tokenPayload.lineOfBusiness,
    tokenPayload.policyReference,
    tokenPayload.schemeId,
  );
};

export const buildStateFromCookies = () => {
  const token = getTokenCookie() || '';
  const uid = buildUidFromToken(token);
  const isLoggedIn = !!token;
  return {
    token,
    isLoggedIn,
    uid,
  };
};

export const getUidFromCookies = () => {
  return buildStateFromCookies().uid;
};

export const getLastLoginMethodCookie = () => {
  return cookie.load('last_login_method');
};

export const getHashedUidFromCookies = () => {
  const uid = getUidFromCookies();
  if (!uid) {
    return undefined;
  }
  if (config.client.clientHashUID) {
    return crypto.createHash('sha1').update(uid, 'utf8').digest('base64');
  }
  return uid;
};

export const getTokenAndType = () => {
  const { token, isLoggedIn, uid } = buildStateFromCookies();
  if (isLoggedIn) {
    const decodedUid = decodeUid(uid);
    return [decodedUid.type, token];
  }
  return [undefined, undefined];
};

class AuthProvider extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...buildStateFromCookies(),
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setStateFromToken();
    axios.interceptors.response.use(
      // Any status code that lies within the range of 2xx causes this
      // first function to trigger
      response => response,

      // Any status codes that falls outside the range of 2xx causes this
      // second function to trigger
      error => {
        if (error?.response?.status === 401) {
          this.clearLogin();
        }
        return Promise.reject(error);
      },
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setStateIfMounted = (...x) => {
    if (this.mounted) {
      this.setState(...x);
    }
  };

  setStateFromToken = () => {
    this.setStateIfMounted(buildStateFromCookies());
  };

  // axios.create will run only once in it's helper file
  // The jwt will change during login thus Auth must be imperatively set.
  // We are also not able to subscribe the headers to change.
  // Cookie change is not an event in older browsers.
  // Thus this is the simplest solution.
  setTokenCookie = token => {
    this.clearTokenCookies();
    setAuthCookie('token', token);
  };

  clearTokenCookies = () => {
    removeAuthCookie('token');
  };

  checkLogin = (types, linesOfBusiness) => {
    const { isLoggedIn, uid } = this.state;
    if (!isLoggedIn) {
      return false;
    }

    if (!getTokenCookie()) {
      return false;
    }

    const decodedUid = decodeUid(uid);

    if (types?.length) {
      if (!types.some(x => x === decodedUid.type)) {
        return false;
      }
    }

    if (linesOfBusiness?.length) {
      if (
        !decodedUid.lineOfBusiness ||
        !linesOfBusiness.some(x => x === decodedUid.lineOfBusiness)
      ) {
        return false;
      }
    }

    return true;
  };

  setNewLogin = token => {
    this.clearTokenCookies();
    const [type] = getAuthTypeFromToken(token);
    if (token && type) {
      this.setTokenCookie(token);
      setLastLoginMethodCookie(type);
    }
    this.setStateFromToken();
  };

  clearLogin = () => {
    this.clearTokenCookies();
    this.setStateFromToken();
  };

  render() {
    const { children } = this.props;
    const { isLoggedIn, uid } = this.state;

    // Cannot useMemo-ise as this is a class component
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
      isLoggedIn,
      uid,
      setStateFromToken: this.setStateFromToken,
      setTokenCookie: this.setTokenCookie,
      setNewLogin: this.setNewLogin,
      clearLogin: this.clearLogin,
      checkLogin: this.checkLogin,
    };
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }
}

// eslint-disable-next-line no-shadow
export const withContext = Component => {
  return props => {
    return (
      <AuthContext.Consumer>
        {globalState => {
          return <Component {...globalState} {...props} />;
        }}
      </AuthContext.Consumer>
    );
  };
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
