import React from 'react';
import PropTypes from 'prop-types';
import {
  FIELD_NAMES,
  LICENCE_TYPES,
  PreviousClaimsArrayModalContext,
  PreviousConvictionsArrayModalContext,
} from '../motorYourDetailsHelper';
import PreviousClaimsConfirmUpdate from '../ConfirmUpdate/PreviousClaimsConfirmUpdate';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import StandardDate from '../../../components/Forms/StandardDate/StandardDate';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';
import ConvictionsConfirmUpdate from '../ConfirmUpdate/ConvictionsConfirmUpdate';
import StandardAutoComplete from '../../../components/Forms/StandardAutoComplete/StandardAutoComplete';

/**
 * Component for displaying data relating to specific driver, for confirmation/
 * update
 */
const validateAutoComplete = (
  validateFields,
  { label, value } = {},
  allValues,
  meta,
) => {
  return (
    validateFields &&
    (validateFields(label, allValues, meta) ||
      validateFields(value, allValues, meta))
  );
};

const OtherDriverConfirmDetails = ({ validateFields }) => (
  <>
    <InvolvedParty
      name={FIELD_NAMES.OTHER_DRIVER_DETAILS}
      header="Please confirm the name and address of the driver"
      values={null}
      validate={validateFields}
      hideContact
    />
    <StandardDate
      name={FIELD_NAMES.DATE_OF_BIRTH}
      id={FIELD_NAMES.DATE_OF_BIRTH}
      label="Driver's date of birth"
      validate={validateFields}
    />
    <YesOrNo
      name={FIELD_NAMES.OTHER_DRIVER_DRIVING_OWN_POLICY}
      title="Were they driving under their own motor policy?"
      validate={validateFields}
    />
    <StandardAutoComplete
      name={FIELD_NAMES.LICENCE_TYPE}
      label="Licence type held by the driver"
      selectionMsg="Please select from the list below"
      validate={(...args) => validateAutoComplete(validateFields, ...args)}
      suggestions={LICENCE_TYPES}
      minCharacters={0}
      openOnZeroInput
      noSuggestionsMsg="No results found, please try typing another licence type"
      placeholder="Start typing"
      blockProps={{ large: true }}
    />
    <StandardInput
      name={FIELD_NAMES.LICENCE_YEARS}
      label="Years licence held by the driver"
      maxLength="2"
      xSmall
      inputmode="numeric"
      validate={validateFields}
    />
    <ConvictionsConfirmUpdate
      title="Have they been convicted of any driving offences in the past 5 years?"
      yesNoName={FIELD_NAMES.OTHER_DRIVER_HAS_CONVICTIONS}
      updateFieldName={FIELD_NAMES.OTHER_DRIVER_CONVICTIONS}
      validate={validateFields}
      value={null}
      Context={PreviousConvictionsArrayModalContext}
      confirmUpdateProps={{ hasButtonsTitle: false }}
    />
    <PreviousClaimsConfirmUpdate
      title="Have they made any previous motor insurance claims in the past 3 years (either with ageas or a previous insurer)?"
      yesNoName={FIELD_NAMES.OTHER_DRIVER_HAS_CLAIMS}
      updateFieldName={FIELD_NAMES.OTHER_DRIVER_CLAIMS}
      validate={validateFields}
      value={null}
      Context={PreviousClaimsArrayModalContext}
      confirmUpdateProps={{ hasButtonsTitle: false }}
    />
  </>
);

OtherDriverConfirmDetails.propTypes = {
  validateFields: PropTypes.func,
};

OtherDriverConfirmDetails.defaultProps = {
  validateFields: undefined,
};

export default OtherDriverConfirmDetails;
