import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemedButton } from 'ageas-ui-components';

const IconContainer = styled.span`
  padding: 0px;
  font-size: 2em;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 6px;
`;

const TextContainer = styled.span`
  flex-grow: 1;
`;

const Button = styled(ThemedButton)`
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const ThemedIconButton = ({ iconLeft, iconRight, children, ...props }) => {
  return (
    <Button {...props}>
      {iconLeft && (
        <IconContainer>
          <FontAwesomeIcon icon={iconLeft} />
        </IconContainer>
      )}
      <TextContainer>{children}</TextContainer>
      {iconRight && (
        <IconContainer>
          <FontAwesomeIcon icon={iconRight} />
        </IconContainer>
      )}
    </Button>
  );
};

export default ThemedIconButton;

ThemedIconButton.propTypes = {
  children: PropTypes.node,
  iconLeft: PropTypes.shape({}),
  iconRight: PropTypes.shape({}),
};

ThemedIconButton.defaultProps = {
  children: undefined,
  iconLeft: undefined,
  iconRight: undefined,
};
