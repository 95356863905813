import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { range } from 'ageasvalidation/module/schemas/Range';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { createContext } from 'react';
import { yesOrNoSchema } from '../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import { getStandardAutoCompleteRequiredSchema } from '../../components/Forms/StandardAutoComplete/StandardAutoComplete';
import { getStandardDateRequiredPastSchema } from '../../components/Forms/StandardDate/StandardDate';
import { getInvolvedPartyNameSchema } from '../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedPartyName';
import { getInvolvedPartyAddressSchema } from '../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedPartyAddress';
import { getStandardInputRequiredSchema } from '../../components/Forms/StandardInput/StandardInput';
import arrayMinSize from '../../helpers/validation/arrayMinSize';
import { getStandardDropdownSchema } from '../../components/Forms/StandardDropdown/StandardDropdown';

export const FIELD_NAMES = {
  DRIVER: 'driver',
  OCCUPATION: 'occupation',
  OCCUPATION_IS_CORRECT: 'occupationIsCorrect',
  OCCUPATION_CHANGED_DATE: 'occupationChangedDate',
  OCCUPATION_CHANGED_VEHICLE_USED: 'occupationChangedVehicleUsed',
  OCCUPATION_CHANGED_COMMUTING: 'occupationChangedCommuting',
  DRIVER_CLAIMS_IS_CORRECT: 'driverClaimsIsCorrect',
  DRIVER_CLAIMS: 'driverClaims',
  DRIVER_CONVICTIONS_IS_CORRECT: 'driverConvictionsIsCorrect',
  DRIVER_CONVICTIONS: 'driverConvictions',
  DATE_OF_BIRTH: 'dob',
  DATE_OF_BIRTH_IS_CORRECT: 'dobIsCorrect',
  LICENCE_TYPE: 'licenceType',
  LICENCE_TYPE_IS_CORRECT: 'licenceTypeIsCorrect',
  LICENCE_YEARS: 'licenceYears',
  LICENCE_YEARS_IS_CORRECT: 'licenceYearsIsCorrect',
  OTHER_DRIVER_DETAILS: 'otherDriverDetails',
  OTHER_DRIVER_DRIVING_OWN_POLICY: 'otherDriverOwnPolicy',
  OTHER_DRIVER_HAS_CLAIMS: 'otherDriverHasClaims',
  OTHER_DRIVER_CLAIMS: 'otherDriverClaims',
  OTHER_DRIVER_HAS_CONVICTIONS: 'otherDriverHasConvictions',
  OTHER_DRIVER_CONVICTIONS: 'otherDriverConvictions',
  PH_ADDRESS_IS_CORRECT: 'phAddressIsCorrect',
  PH_ADDRESS: 'phAddress',
  PH_ADDRESS_CHANGED_DATE: 'phAddressChangedDate',
  VEHICLE_OWNER_IS_CORRECT: 'vehicleOwnerIsCorrect',
  VEHICLE_OWNER_CHANGED_DATE: 'vehicleOwnerChangedDate',
  VEHICLE_OWNER_NAME: 'vehicleOwnerName',
  VEHICLE_OWNER_DATE_OF_BIRTH: 'vehicleOwnerDob',
  VEHICLE_OWNER_STATUS: 'vehicleOwnerStatus',
  VEHICLE_OWNER_RELATIONSHIP_TO_PH: 'vehicleOwnerRelationshipToPh',
  VEHICLE_KEEPER_IS_CORRECT: 'vehicleKeeperIsCorrect',
  VEHICLE_KEEPER_CHANGED_DATE: 'vehicleKeeperChangedDate',
  VEHICLE_KEEPER_NAME: 'vehicleKeeperName',
  VEHICLE_KEEPER_DATE_OF_BIRTH: 'vehicleKeeperDob',
  VEHICLE_KEEPER_RELATIONSHIP_TO_PH: 'vehicleKeeperRelationshipToPh',
  VEHICLE_KEPT: 'vehicleKept',
  VEHICLE_MODIFICATIONS_TO_ADD: 'vehicleModificationsToAdd',
  VEHICLE_MODIFICATIONS_DETAILS: 'vehicleModificationsDetails',
  VEHICLE_MODIFICATIONS_DATE: 'vehicleModificationsDate',
  VEHICLE_USAGE: 'vehicleUsage',
  VEHICLE_USAGE_DETAILS: 'vehicleUsageDetails',
  MAIN_USER: 'mainUser',
  MAIN_USER_NAME: 'mainUserName',
};

// Fields to delete/reinitialise if driver changes
export const DRIVER_SPECIFIC_FIELDS = [
  FIELD_NAMES.OCCUPATION,
  FIELD_NAMES.OCCUPATION_IS_CORRECT,
  FIELD_NAMES.OCCUPATION_CHANGED_DATE,
  FIELD_NAMES.OCCUPATION_CHANGED_VEHICLE_USED,
  FIELD_NAMES.OCCUPATION_CHANGED_COMMUTING,
  FIELD_NAMES.DRIVER_CLAIMS_IS_CORRECT,
  FIELD_NAMES.DRIVER_CLAIMS,
  FIELD_NAMES.DRIVER_CONVICTIONS_IS_CORRECT,
  FIELD_NAMES.DRIVER_CONVICTIONS,
  FIELD_NAMES.DATE_OF_BIRTH,
  FIELD_NAMES.DATE_OF_BIRTH_IS_CORRECT,
  FIELD_NAMES.LICENCE_TYPE,
  FIELD_NAMES.LICENCE_TYPE_IS_CORRECT,
  FIELD_NAMES.LICENCE_YEARS,
  FIELD_NAMES.LICENCE_YEARS_IS_CORRECT,
  FIELD_NAMES.OTHER_DRIVER_DETAILS,
  FIELD_NAMES.OTHER_DRIVER_DRIVING_OWN_POLICY,
  FIELD_NAMES.OTHER_DRIVER_HAS_CLAIMS,
  FIELD_NAMES.OTHER_DRIVER_CLAIMS,
  FIELD_NAMES.OTHER_DRIVER_HAS_CONVICTIONS,
  FIELD_NAMES.OTHER_DRIVER_CONVICTIONS,
];

export const OTHER_UNKNOWN_DRIVER = '-1';

export const whatIsDriver = driverId => {
  if (driverId === OTHER_UNKNOWN_DRIVER) {
    return 'other';
  }
  return driverId ? 'actual' : 'notSet';
};

export const isAnActualDriver = driverId => whatIsDriver(driverId) === 'actual';
export const isAnOtherDriver = driverId => whatIsDriver(driverId) === 'other';

export const buildDriversList = drivers => {
  return !drivers
    ? []
    : [
        ...drivers.map(driver => {
          return {
            label: driver.name,
            value: driver.id.toString(),
          };
        }),
        {
          label: 'Other/Unknown',
          value: OTHER_UNKNOWN_DRIVER,
        },
      ];
};

export const LICENCE_TYPES = [
  { value: 'F', label: 'Full UK Car Licence' },
  { value: 'E', label: 'Full EU Licence' },
  { value: 'D', label: 'Full (Eire)' },
  { value: 'P', label: 'Provisional (UK) Car Licence' },
  { value: 'O', label: 'Provisional EU Licence' },
  { value: 'G', label: 'Provisional (Eire)' },
  { value: 'R', label: 'Restricted (Full) Less Than 3 Years' },
  { value: 'S', label: 'Restricted (Full) 3 Years Or More Licence' },
  { value: 'J', label: 'Restricted Provisional - 3 Years Or More' },
  { value: 'K', label: 'Restricted Provisional  - Less Then 3 Years' },
  { value: 'T', label: 'HGV Class 1' },
  { value: 'V', label: 'HGV Class 2' },
  { value: 'W', label: 'HGV Class 3' },
  { value: '9', label: 'Provisional (UK) HGV Licence' },
  { value: 'Z', label: 'Other' },
  { value: 'H', label: 'Full European (Non-EU)' },
  { value: 'I', label: 'Foreign' },
  { value: 'N', label: 'Full International Licence' },
  { value: 'U', label: 'Provisional European (Non-EU)' },
  { value: '0', label: 'Provisional International' },
  { value: 'L', label: 'A1 (up to 125 cc, 11 kw, 14.6 bhp)' },
  { value: 'M', label: 'Category A Full Motorcycle Licence' },
  { value: 'Q', label: 'Provisional Motorcycle Licence' },
  { value: 'X', label: 'Medical Restricted (Full) 3 Years Or More - Car' },
  {
    value: 'Y',
    label: 'Medical Restricted (Full) 3 Years Or More - Motorcycle',
  },
  { value: '1', label: 'Medical Restricted Provisional 3 Years Or More - Car' },
  {
    value: '2',
    label: 'Medical Restricted Provisional 3 Years Or More Motorcycle',
  },
  {
    value: '3',
    label: 'Medical Restricted Provisional  - Less Then 3 Years - Car',
  },
  {
    value: '4',
    label: 'Medical Restricted Provisional  - Less Then 3 Years - Motorcycle',
  },
  { value: '5', label: 'Medical Restricted (Full) Less Than 3 Years - Car' },
  {
    value: '6',
    label: 'Medical Restricted (Full) Less Than 3 Years - Motorcycle',
  },
  { value: '7', label: 'AM (Moped Licence)' },
  { value: '8', label: 'A2 Licence (Restricted full test)' },
  { value: 'A', label: 'No Licence Ever Held' },
  { value: 'B', label: 'Licence Revoked Due To Disqualification' },
  { value: 'C', label: 'Licence Revoked (Reason Other Than Disqualification)' },
];

export const RELATIONSHIP_LIST = [
  {
    value: 'daughter',
    label: 'Daughter',
  },
  {
    value: 'father',
    label: 'Father',
  },
  {
    value: 'mother',
    label: 'Mother',
  },
  {
    value: 'son',
    label: 'Son',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
  {
    value: 'spouse',
    label: 'Spouse',
  },
];

export const MAIN_USER_LIST = [
  {
    value: 'policyHolder',
    label: 'Policyholder',
  },
  {
    value: 'newOwner',
    label: 'New owner',
  },
  {
    value: 'newKeeper',
    label: 'New keeper',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const VEHICLE_USAGE_LIST = [
  {
    value: 'shopping',
    label: 'Going shopping',
  },
  {
    value: 'visiting',
    label: 'Visiting friends/relatives',
  },
  {
    value: 'leisure',
    label: 'Leisure purposes',
  },
  {
    value: 'holiday',
    label: 'Going on holiday',
  },
  {
    value: 'commuting',
    label: 'Driving to work',
  },
  {
    value: 'school',
    label: 'Dropping children off at school',
  },
  {
    value: 'work',
    label: 'Work purposes',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const VEHICLE_KEPT_LIST = [
  {
    value: 'lockedBuilding',
    label: 'Locked building',
  },
  {
    value: 'carPort',
    label: 'Car port',
  },
  {
    value: 'drive',
    label: 'Drive',
  },
  {
    value: 'unlockedCompound',
    label: 'Unlocked compound',
  },
  {
    value: 'garage',
    label: 'Garage',
  },
  {
    value: 'carPark',
    label: 'Car park',
  },
  {
    value: 'lockedCompound',
    label: 'Locked compound',
  },
  {
    value: 'unlockedBuilding',
    label: 'Unlocked building',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'privateProperty',
    label: 'Private property',
  },{
    value: 'publicRoad',
    label: 'Public Road',
  },{
    value: 'tpPremises',
    label: 'Third Party premises',
  },{
    value: 'unknown',
    label: 'Unknown',
  },
];

export const getFilteredMainUserList = (ownerIsCorrect, keeperIsCorrect) =>
  MAIN_USER_LIST.filter(
    ({ value }) =>
      !(value === 'newOwner' && ownerIsCorrect !== 'no') &&
      !(value === 'newKeeper' && keeperIsCorrect !== 'no'),
  );

const localYesOrNoSchema = fieldName =>
  yesOrNoSchema(fieldName, 'Please confirm');
const localArrayMinSize = (fieldName, minimumSize) =>
  arrayMinSize(fieldName, minimumSize, 'Please confirm');

export const schema = {
  [FIELD_NAMES.DRIVER]: stringRequired(
    [FIELD_NAMES.DRIVER],
    'Please select one of the driver options',
  ),
  ...localYesOrNoSchema(FIELD_NAMES.OCCUPATION_IS_CORRECT),
  ...getStandardAutoCompleteRequiredSchema(
    FIELD_NAMES.OCCUPATION,
    'Please enter a value and select an occupation',
  ),
  ...getStandardDateRequiredPastSchema(FIELD_NAMES.OCCUPATION_CHANGED_DATE),
  ...localYesOrNoSchema(FIELD_NAMES.OCCUPATION_CHANGED_VEHICLE_USED),
  ...localYesOrNoSchema(FIELD_NAMES.OCCUPATION_CHANGED_COMMUTING),
  ...localYesOrNoSchema(FIELD_NAMES.LICENCE_TYPE_IS_CORRECT),
  ...getStandardAutoCompleteRequiredSchema(
    FIELD_NAMES.LICENCE_TYPE,
    'Please enter a value and select a licence type',
  ),
  ...getStandardDateRequiredPastSchema(
    FIELD_NAMES.DATE_OF_BIRTH,
    'Please select the date of birth',
  ),
  ...localYesOrNoSchema(FIELD_NAMES.DATE_OF_BIRTH_IS_CORRECT),
  ...localYesOrNoSchema(FIELD_NAMES.LICENCE_YEARS_IS_CORRECT),

  [FIELD_NAMES.LICENCE_YEARS]: stringRequired(
    [FIELD_NAMES.LICENCE_YEARS],
    'Please enter the number of years the licence has been held',
  )
    .concat(
      range(
        0,
        99,
        [FIELD_NAMES.LICENCE_YEARS],
        'Please enter a valid value, from 0 to 99',
      ),
    )
    .concat(
      regularExpression(
        /^\d{1,2}$/,
        [FIELD_NAMES.LICENCE_YEARS],
        'Please enter a valid value, from 0 to 99',
      ),
    ),

  ...localYesOrNoSchema(FIELD_NAMES.DRIVER_CLAIMS_IS_CORRECT),
  [FIELD_NAMES.DRIVER_CLAIMS]: localArrayMinSize(FIELD_NAMES.DRIVER_CLAIMS, 1),
  ...localYesOrNoSchema(FIELD_NAMES.DRIVER_CONVICTIONS_IS_CORRECT),
  [FIELD_NAMES.DRIVER_CONVICTIONS]: localArrayMinSize(
    FIELD_NAMES.DRIVER_CONVICTIONS,
    1,
  ),

  ...getInvolvedPartyNameSchema(FIELD_NAMES.OTHER_DRIVER_DETAILS),
  ...getInvolvedPartyAddressSchema(FIELD_NAMES.OTHER_DRIVER_DETAILS),
  ...localYesOrNoSchema(FIELD_NAMES.OTHER_DRIVER_DRIVING_OWN_POLICY),
  ...localYesOrNoSchema(FIELD_NAMES.OTHER_DRIVER_HAS_CLAIMS),
  [FIELD_NAMES.OTHER_DRIVER_CLAIMS]: localArrayMinSize(
    FIELD_NAMES.OTHER_DRIVER_CLAIMS,
    1,
  ),
  ...localYesOrNoSchema(FIELD_NAMES.OTHER_DRIVER_HAS_CONVICTIONS),
  [FIELD_NAMES.OTHER_DRIVER_CONVICTIONS]: localArrayMinSize(
    FIELD_NAMES.OTHER_DRIVER_CONVICTIONS,
    1,
  ),
  ...localYesOrNoSchema(FIELD_NAMES.PH_ADDRESS_IS_CORRECT),
  ...getInvolvedPartyAddressSchema(FIELD_NAMES.PH_ADDRESS),
  ...getStandardDateRequiredPastSchema(FIELD_NAMES.PH_ADDRESS_CHANGED_DATE),
  ...localYesOrNoSchema(FIELD_NAMES.VEHICLE_OWNER_IS_CORRECT),
  ...getStandardDateRequiredPastSchema(FIELD_NAMES.VEHICLE_OWNER_CHANGED_DATE),
  ...getInvolvedPartyNameSchema(
    FIELD_NAMES.VEHICLE_OWNER_NAME,
    'Please confirm',
  ),
  ...getStandardDateRequiredPastSchema(FIELD_NAMES.VEHICLE_OWNER_DATE_OF_BIRTH),
  ...getStandardAutoCompleteRequiredSchema(
    FIELD_NAMES.VEHICLE_OWNER_STATUS,
    'Please confirm',
  ),
  ...getStandardDropdownSchema(
    FIELD_NAMES.VEHICLE_OWNER_RELATIONSHIP_TO_PH,
    'Please confirm',
  ),
  ...localYesOrNoSchema(FIELD_NAMES.VEHICLE_KEEPER_IS_CORRECT),
  ...getStandardDateRequiredPastSchema(FIELD_NAMES.VEHICLE_KEEPER_CHANGED_DATE),
  ...getInvolvedPartyNameSchema(
    FIELD_NAMES.VEHICLE_KEEPER_NAME,
    'Please confirm',
  ),
  ...getStandardDateRequiredPastSchema(
    FIELD_NAMES.VEHICLE_KEEPER_DATE_OF_BIRTH,
  ),
  ...getStandardDropdownSchema(
    FIELD_NAMES.VEHICLE_KEEPER_RELATIONSHIP_TO_PH,
    'Please confirm',
  ),
  ...localYesOrNoSchema(FIELD_NAMES.VEHICLE_MODIFICATIONS_TO_ADD),
  ...getStandardInputRequiredSchema(
    FIELD_NAMES.VEHICLE_MODIFICATIONS_DETAILS,
    'Please enter details',
  ),
  ...getStandardDateRequiredPastSchema(FIELD_NAMES.VEHICLE_MODIFICATIONS_DATE),
  [FIELD_NAMES.MAIN_USER]: stringRequired(
    [FIELD_NAMES.MAIN_USER],
    'Please confirm',
  ),
  ...getInvolvedPartyNameSchema(FIELD_NAMES.MAIN_USER_NAME, 'Please confirm'),
  ...getStandardDropdownSchema(FIELD_NAMES.VEHICLE_USAGE, 'Please confirm'),
  ...getStandardInputRequiredSchema(
    FIELD_NAMES.VEHICLE_USAGE_DETAILS,
    'Please enter details',
  ),
};

export const PreviousClaimsArrayModalContext = createContext({});
export const PreviousConvictionsArrayModalContext = createContext({});
