import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ageas-ui-components';
import {
  HeaderContainer,
  SubTextNoMargin,
  SummaryContainer,
} from '../../StandardPageWrapper/StandardPageWrapperStyle';
import { getSupplierMilestoneDescription } from '../../../helpers/hubSupplierMilestonesHelper';
import NewUpdatesStyle from './NewUpdatesStyle';

const SupplierMilestoneDetails = ({ milestone }) => {
  return (
    <SummaryContainer>
      <Icon
        className="icon-warning"
        brandIconSet="ageas"
        icon="tick-roundel"
        color="green"
        size={34}
      />
      <HeaderContainer>
        <SubTextNoMargin>
          {getSupplierMilestoneDescription(
            milestone.milestoneID,
            milestone.milestoneData,
            milestone?.additionalData,
          )}
          {!milestone.read && <NewUpdatesStyle>New</NewUpdatesStyle>}
        </SubTextNoMargin>
      </HeaderContainer>
    </SummaryContainer>
  );
};

export default SupplierMilestoneDetails;

SupplierMilestoneDetails.propTypes = {
  milestone: PropTypes.shape({
    sequence: PropTypes.number,
    milestoneID: PropTypes.string,
    milestoneTimestamp: PropTypes.string,
    notificationSequence: PropTypes.number,
    milestoneData: PropTypes.shape({}),
    additionalData: PropTypes.shape({}),
    read: PropTypes.bool,
  }),
};
SupplierMilestoneDetails.defaultProps = {
  milestone: undefined,
};
