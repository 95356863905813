import PropTypes from 'prop-types';
import React from 'react';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import StandardRadio from '../../../components/Forms/StandardRadio/StandardRadio';
import {
  getInvolvedPartyTypeList,
  incidentDetailsFields,
} from '../motorIncidentDetailsHelper';

const IncidentDamageRoadSurfaceUngrittedFields = ({
  values,
  validateFields,
}) => {
  return (
    <>
      <YesOrNo
        name={incidentDetailsFields.UNGRITTED_ROAD_MAIN_ROAD}
        title="Is the road a main road?"
        validate={validateFields}
      />
      <YesOrNo
        name={incidentDetailsFields.UNGRITTED_ROAD_ACCESS_ROAD}
        title="Is the road used for access to a school, hospital or shopping centre?"
        validate={validateFields}
      />
      <YesOrNo
        name={incidentDetailsFields.UNGRITTED_ROAD_PUBLIC_TRANSPORT}
        title="Is the road a route for frequent public transport?"
        validate={validateFields}
      />
      {(values[incidentDetailsFields.UNGRITTED_ROAD_MAIN_ROAD] === 'yes' ||
        values[incidentDetailsFields.UNGRITTED_ROAD_ACCESS_ROAD] === 'yes' ||
        values[incidentDetailsFields.UNGRITTED_ROAD_PUBLIC_TRANSPORT] ===
          'yes') && (
        <>
          <YesOrNo
            name={incidentDetailsFields.UNGRITTED_ROAD_OWNER_KNOWN}
            title="Do you know who the road owner is?"
            validate={validateFields}
          />
          {values[incidentDetailsFields.UNGRITTED_ROAD_OWNER_KNOWN] ===
            'yes' && (
            <>
              <StandardRadio
                name={incidentDetailsFields.UNGRITTED_ROAD_OWNER_TYPE}
                title="Is the road owned by a company, local authority or a private owner?"
                options={getInvolvedPartyTypeList('Company/Local Authority')}
                medium
                vertical
                validate={validateFields}
              />
              {!!values[incidentDetailsFields.UNGRITTED_ROAD_OWNER_TYPE] && (
                <InvolvedParty
                  name={incidentDetailsFields.UNGRITTED_ROAD_OWNER_DETAILS}
                  header="Please confirm the name, address and contact details of the owner"
                  values={values}
                  company={
                    values[incidentDetailsFields.UNGRITTED_ROAD_OWNER_TYPE] ===
                    'company'
                  }
                  companyLabel="Company or local authority name"
                  validate={validateFields}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default IncidentDamageRoadSurfaceUngrittedFields;

IncidentDamageRoadSurfaceUngrittedFields.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  validateFields: PropTypes.func,
};

IncidentDamageRoadSurfaceUngrittedFields.defaultProps = {
  validateFields: undefined,
};
