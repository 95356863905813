import { contentsInfoTitle, contentsInfoContent } from './ContentsInfo';
import {
  contentsInsuranceCoverageTitle,
  contentsInsuranceCoverageContent,
} from './ContentsInsuranceCoverage';
import {
  replaceDamagedItemsTitle,
  replaceDamagedItemsContent,
} from './ReplaceDamagedItems';
import {
  contentsOutsideHomeTitle,
  contentsOutsideHomeContent,
} from './ContentsOutsideHome';
import {
  contentsExcessPayCollectTitle,
  contentsExcessPayCollectContent,
} from './ContentsExcessPayCollect';
import {
  contentsClaimEventTitle,
  contentsClaimEventContent,
} from './ContentsClaimEvent';
import {
  affectContentsClaimTitle,
  affectContentsClaimContent,
} from './AffectContentsClaim';

const contentsClaimQuestions = [
  {
    title: contentsInfoTitle,
    content: contentsInfoContent,
  },
  {
    title: contentsInsuranceCoverageTitle,
    content: contentsInsuranceCoverageContent,
  },
  {
    title: replaceDamagedItemsTitle,
    content: replaceDamagedItemsContent,
  },
  {
    title: contentsOutsideHomeTitle,
    content: contentsOutsideHomeContent,
  },
  {
    title: contentsExcessPayCollectTitle,
    content: contentsExcessPayCollectContent,
  },
  {
    title: contentsClaimEventTitle,
    content: contentsClaimEventContent,
  },
  {
    title: affectContentsClaimTitle,
    content: affectContentsClaimContent,
  },
];

export default contentsClaimQuestions;
