import { makeAComplaintTitle, makeAComplaintContent } from './MakeAComplaint';
import { howDealtWithTitle, howDealtWithContent } from './HowDealtWith';
import { notSatisfiedTitle, notSatisfiedContent } from './NotSatisfied';
import {
  financialOmbudsmanTitle,
  financialOmbudsmanContent,
} from './FinancialOmbudsman';

const complaintsQuestions = [
  { title: makeAComplaintTitle, content: makeAComplaintContent },
  { title: howDealtWithTitle, content: howDealtWithContent },
  { title: notSatisfiedTitle, content: notSatisfiedContent },
  { title: financialOmbudsmanTitle, content: financialOmbudsmanContent },
];

export default complaintsQuestions;
