import React, { useEffect, useContext, useState, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import {
  ThemedLineBreak,
  ThemedButton,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import axios from 'axios';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import IncidentDetailsSummary from '../../components/Summary/IncidentDetailsSummary';
import ContactDetailsSummary from '../../components/Summary/ContactDetailsSummary';
import InvolvedPartiesDetailsSummary from '../../components/Summary/InvolvedPartiesDetailsSummary';
import ThemedLinkButton from '../../components/ThemedLinkButton/ThemedLinkButton';
import { MotorFormContext } from '../../helpers/motorFormStateHelper';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import BreadCrumb from '../../helpers/MotorClaimBreadcrumb';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import buildCreateClaimObject from '../../helpers/buildCreateClaimObject';
import { createClaimFailMotor } from '../../helpers/errorMessages';
import LoadingSpinner from '../../components/UI/LoadingSpinner/LoadingSpinner';
import { consoleError } from '../../helpers/consoleLog';

const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const CustomThemedTitle = styled.div`
  font-weight: normal;
  text-align: left;
  font-size: 20px;
  padding: 0px;
`;
const Theme = getTheme(brand);
const PageContainer = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const ButtonStyle = styled.div`
@media screen and (max-width: 576px) {
  width: 100%;
`;

const Confirmation = () => {
  const { dataState, clearAllDataState } = useContext(MotorFormContext);
  const [navigatePath, setNavigatePath] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [claimReference, setClaimReference] = useState({});
  const [incompleteClaim, setIncompleteClaim] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const axiosCancelToken = useRef(null);

  const abortAxios = message => {
    if (axiosCancelToken?.current?.cancel) {
      axiosCancelToken.current.cancel(message);
    }
  };

  const createAxiosCancelToken = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosCancelToken.current;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // Since context is built from localStorage which isn't present
    // server-side, if rendering is based directly on context a console
    // error for a difference between client and server will fire,
    // to work around get context after mount here.
    setIsValid(
      dataState?.motorContactDetail?.formIsValid &&
        dataState?.motorIncidentDetail?.formIsValid &&
        dataState?.involvedPartiesDetail?.formIsValid,
    );
    return () => {
      abortAxios();
    };
  }, []);

  const renderThanksPage = data => {
    clearAllDataState();
    setClaimReference(data);
    setIsLoading(false);
    setNavigatePath('/motorclaim/thankyou');
  };

  const createClaim = async () => {
    setIsLoading(true);
    const cancelToken = createAxiosCancelToken().token;
    try {
      const getClaimObject = buildCreateClaimObject(dataState);

      const res = await axiosHelper.post(
        config.client.claimnumber_endpoint,
        getClaimObject,
        {
          cancelToken,
        },
      );
      renderThanksPage(res.data);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIncompleteClaim(true);
        setIsLoading(false);
        consoleError('axios error', error);
      }
    }
  };

  const renderSubmit = () => {
    return (
      <ButtonStyle>
        <ThemedButton
          secondary
          small
          type="button"
          onClick={createClaim}
          disabled={incompleteClaim}
        >
          Submit
        </ThemedButton>
      </ButtonStyle>
    );
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      <ThemeProvider theme={Theme}>
        <BrandHeader />
        <BreadCrumb activeItem="Summary" />
        <main>
          <NavigateTo path={navigatePath} data={claimReference} />
          <PageContainer>
            <StandardPanel
              primary
              title="Summary details"
              padding="23px 21px 0px 21px"
              panelPadding="0px 0px 16px 0px"
            >
              <CustomThemedTitle>
                You have now entered all of your claim details.
              </CustomThemedTitle>
              <CustomThemedTitle>
                Please review the details below and once you are happy, you can
                submit your claim.
              </CustomThemedTitle>
              <br />
              <ThemedLineBreak tertiary xLarge />

              <IncidentDetailsSummary key="MotorIncidentDetail" />
              <ThemedLineBreak tertiary xLarge />
              <ContactDetailsSummary key="MotorContactDetail" />
              <ThemedLineBreak tertiary xLarge />
              <InvolvedPartiesDetailsSummary key="InvolvedParties" />

              {incompleteClaim && (
                <ThemedErrorMessage hasIcon>
                  {createClaimFailMotor()}
                </ThemedErrorMessage>
              )}
              <br />

              <BottomButtonsContainer>
                <ThemedLinkButton
                  secondary
                  inverted
                  small
                  to="/motorclaim/involvedpartiesdetail"
                >
                  Back
                </ThemedLinkButton>
                {isValid && renderSubmit()}
              </BottomButtonsContainer>
            </StandardPanel>
          </PageContainer>
        </main>
        <BrandFooter />
      </ThemeProvider>
    </>
  );
};
export default Confirmation;
