import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const stormDamageClaimTitle =
  'What should I expect when making a storm damage claim?';

export const StormDamageClaimContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        Once you report storm damage, the claims department will check local
        weather records to see if the weather you experienced meets the
        requirements to be considered a storm. For more information, please
        refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Storm damage and claims',
              'What counts as storm damage?',
            ])
          }
        >
          &apos;What counts as storm damage?&apos;
        </ButtonLink>
      </FaqP>
      <FaqP>
        If the requirements for a storm damage claim are met, then a storm
        specialist supplier will be appointed to view your property, either in
        person or remotely.
      </FaqP>
      <FaqP marginBottom={false}>
        After they have assessed the damage, a decision will be made by the
        supplier as to whether they think the damage to your property was caused
        by these recent weather conditions or by something else, such as general
        wear and tear. If they agree the damage was caused by the weather
        conditions, we will consider which of the following options is best
        suited to your circumstances:
      </FaqP>
      <StandardUl>
        <li>
          We may be able to offer you a cash settlement for you to pay for the
          repairs
        </li>
        <li>
          If there is more work to be done, we may instruct one of our approved
          contractors to contact you directly and make arrangements for the
          repairs
        </li>
        <li>
          If the damage is substantial, we&apos;ll appoint an expert to manage
          the claim - either remotely or in person - and talk you through the
          best course of action
        </li>
      </StandardUl>
    </>
  );
};

StormDamageClaimContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
StormDamageClaimContent.defaultProps = {
  nestingControl: undefined,
};
