import React from 'react';
import PropTypes from 'prop-types';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { ThemedButton } from 'ageas-ui-components';
import ThemedIconButton from '../ThemedIconButton/ThemedIconButton';
import media from '../MediaQuery/MediaQuery';

const CompleteButtonsContainer = styled.div`
  margin-bottom: 1em;
  & > * {
    margin-right: 6px;
    ${media.phone`
      margin-right: 0;
    `}
  }
`;

const YesNoButton = props => {
  const { yesButtonLabel, acceptOnClick, declineOnClick } = props;

  return (
    <CompleteButtonsContainer>
      <ThemedButton type="button" primary inverted onClick={declineOnClick}>
        No
      </ThemedButton>
      <ThemedIconButton
        type="button"
        secondary
        iconRight={faChevronCircleRight}
        onClick={acceptOnClick}
      >
        {yesButtonLabel}
      </ThemedIconButton>
    </CompleteButtonsContainer>
  );
};

YesNoButton.propTypes = {
  yesButtonLabel: PropTypes.string,
  acceptOnClick: PropTypes.func,
  declineOnClick: PropTypes.func,
};

YesNoButton.defaultProps = {
  yesButtonLabel: undefined,
  acceptOnClick: undefined,
  declineOnClick: undefined,
};

export default YesNoButton;
