import React from 'react';
import FaqP from '../../FaqP';

export const title = 'What is Excess Protection';
export const content = (
  <FaqP>
    Our Excess Protection optional cover is designed to cover you if the excess
    on your policy (up to a maximum of £500) can&apos;t be recovered by a third
    party, for up to two claims a year during your period of insurance.
  </FaqP>
);

export const pair = { title, content };
