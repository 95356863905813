import React from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import HomeClaimsWrapper from '../HomeClaimsWrapper/HomeClaimsWrapper';
import {
  PageSectionBare,
  Subsection,
} from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import { brand } from '../../../data/whitelabel.config';
import WeatherResult from '../../components/WeatherResult/WeatherResult';
import ClaimBreadcrumbStandard from '../../helpers/ClaimBreadcrumbStandard';

const Theme = getTheme(brand);

const StormVerificationResult = () => {
  return (
    <ThemeProvider theme={Theme}>
      <HomeClaimsWrapper>
        <ClaimBreadcrumbStandard activeItem="results" />
        <PageSectionBare>
          <Subsection>
            <WeatherResult />
          </Subsection>
        </PageSectionBare>
      </HomeClaimsWrapper>
    </ThemeProvider>
  );
};

export default StormVerificationResult;
