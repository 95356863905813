import React from 'react';
import FaqP from '../../FaqP';
import StandardUL from '../../../StandardTags/UL';

export const contentsAccidentalDamageTitle =
  'Why would my contents accidental damage claim not be covered?';
export const contentsAccidentalDamageContent = (
  <>
    <FaqP>
      One reason that your claim may not be covered is because you do not have
      the Accidental Damage extension on your Contents policy.
    </FaqP>
    <FaqP>
      If you have chosen this cover, it will be shown on your policy
      documentation.
    </FaqP>
    <FaqP marginBottom={false}>
      Common exclusions applicable to Contents accidental damage extension
      claims include (but are not limited to) the following:
    </FaqP>
    <StandardUL>
      <li>Damage caused by wear and tear</li>
      <li>
        Damage caused by scratches or dents unless they prevent you from using
        the item
      </li>
      <li>Damage caused by computer viruses</li>
      <li>Damage if the property is unoccupied</li>
      <li>
        Damage caused by chewing, scratching, tearing or fouling by domestic
        animals or pets
      </li>
      <li>Damage to sports equipment when it&apos;s being used</li>
    </StandardUL>
    <FaqP>
      <strong>PLEASE NOTE:</strong>
      <br />
      There will be general exclusions that apply to all sections of your policy
      cover. Please refer to your policy booklet for full details of these
      exclusions.
    </FaqP>
  </>
);
