export const homeBaseRoute = '/homeclaim/';

export const perilBaseRoutes = {
  AD: `${homeBaseRoute}accidentaldamage/`,
  AL: `${homeBaseRoute}accidentalloss/`,
  TF: `${homeBaseRoute}theft/`,
  FF: `${homeBaseRoute}freezerfood/`,
  ST: `${homeBaseRoute}storm/`,
  EW: `${homeBaseRoute}escapeofwater/`,
};

export const buildPerilRoute = peril => {
  return {
    [peril]: {
      history: `${perilBaseRoutes[peril]}history`,
      incidentDetails: `${perilBaseRoutes[peril]}incidentdetails`,
      results: `${perilBaseRoutes[peril]}results`,
      thankYou: `${perilBaseRoutes[peril]}thankyoupage`,
    },
  };
};

export const perilRoutes = {
  ...buildPerilRoute('AD'),
  ...buildPerilRoute('AL'),
  ...buildPerilRoute('TF'),
  ...buildPerilRoute('FF'),
  ...buildPerilRoute('ST'),
  ...buildPerilRoute('EW'),
};
