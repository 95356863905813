// import axiosHelper from '../../components/HomeContactDetails/axiosStub';
import config from '../config';
import axiosHelper from '../axios';
import { dateISOConverter } from '../dateTimeConverter';

// keySet must be an array:
// ["damaged Date in dd/mm/yyyy format", "Peril Code", itemType]
// E.g.
// ["/31/12/2019","AD","carpets"]
// ItemType is optional, if not required pass date/peril only or
// set itemType to a falsey value
// cancelToken must be applied to the axios request
// Return falsey to indicate validation failure
// Return string to indicate validation failure with custom status
// Else return promise, with the data that will be stored to context
const policyDetailsAxios = (keySet, cancelToken) => {
  // validate/convert
  // 1 keys
  if (keySet.length < 1) {
    return false;
  }

  // All truthy values
  if (!keySet.every(key => !!key)) {
    return false;
  }

  const damagedDate = dateISOConverter(keySet[0]);
  // Build URL
  let url = `${
    config.client.gethomepolicyholder_endpoint
  }?effectiveDate=${encodeURIComponent(
    damagedDate,
  )}&perilCode=${encodeURIComponent(keySet[1])}`;
  if (keySet[2]) {
    url += `&itemType=${encodeURIComponent(keySet[2])}`;
  }
  // Return axios request promise
  return (
    axiosHelper
      .get(url, {
        cancelToken,
      })
      // Our own .then to extract the required data from the larger object
      .then(data => {
        return data?.data || {};
      })
  );
};

export default policyDetailsAxios;
