import React from 'react';
import {
  accidentalDamageInfoTitle,
  accidentalDamageInfoContent,
} from './AccidentalDamageInfo';
import {
  expectAccidentalDamageTitle,
  expectAccidentalDamageContent,
} from './ExpectAccidentalDamage';
import HowLongAccidentalDamageContent, {
  howLongAccidentalDamageTitle,
} from './HowLongAccidentalDamage';
import {
  excessPayCollectTitle,
  excessPayCollectContent,
} from './ExcessPayCollect';
import AccidentalDamageDeclinedContent, {
  accidentalDamageDeclinedTitle,
} from './AccidentalDamageDeclined';
import {
  contentsAccidentalDamageTitle,
  contentsAccidentalDamageContent,
} from './ContentsAccidentalDamage';
import {
  buildingsAccidentalDamageTitle,
  buildingsAccidentalDamageContent,
} from './BuildingsAccidentalDamage';
import { affectClaimTitle, affectClaimContent } from './AffectClaim';

const accidentalDamageQuestions = [
  {
    title: accidentalDamageInfoTitle,
    content: accidentalDamageInfoContent,
  },
  {
    title: expectAccidentalDamageTitle,
    content: expectAccidentalDamageContent,
  },
  {
    title: howLongAccidentalDamageTitle,
    content: <HowLongAccidentalDamageContent />,
  },
  {
    title: excessPayCollectTitle,
    content: excessPayCollectContent,
  },
  {
    title: accidentalDamageDeclinedTitle,
    content: <AccidentalDamageDeclinedContent />,
  },
  {
    title: contentsAccidentalDamageTitle,
    content: contentsAccidentalDamageContent,
  },
  {
    title: buildingsAccidentalDamageTitle,
    content: buildingsAccidentalDamageContent,
  },
  {
    title: affectClaimTitle,
    content: affectClaimContent,
  },
];

export default accidentalDamageQuestions;
