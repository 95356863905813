import Joi from '@hapi/joi';
import { messages } from '../constants/ErrorMessages';
import {
  lessThanEqual,
  greaterThanEqual,
  greaterThanEqualToCompare,
  purchaseDateManufactureYearCompare,
} from '../extensions/ValidDate';

const JoiExtended = Joi.extend(lessThanEqual);
const JoiExt = Joi.extend(greaterThanEqual);
const validKeys = ['date'];
const JoiExtnd = Joi.extend(purchaseDateManufactureYearCompare);

/**
 * @description: Validate the date field is less than or equal to a given date
 * @param compareDate the date to compare the field to
 * @param keys Fields to validate
 * @param message the message to display when the validation fails
 * @param config the config is flag to confirm whether provided compareDate is refrence or a value
 * @succeeds when the date field is less than or equal to the compare date
 * @fails when date field is greater than the compare date.
 */
export const dateLessThanOrEqualTo = (
  compareDate,
  keys = validKeys,
  message = messages.DATE_LESSTHANOREQUALTO,
  config = {},
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExtended.string()
      .lessThanEqual(config.isRef ? Joi.ref(compareDate) : compareDate)
      .error(() => message),
  );
};
/**
 * @description: Validate the year field is greater than or equal to a given year
 * @param compareYear the year to compare the field to
 * @param keys Fields to validate
 * @param message the message to display when the validation fails
 * @succeeds when the year field is greater than or equal to the compare year
 * @fails when year field is less than the compare year.
 */
export const greaterThanOrEqualToYear = (
  compareYear,
  keys = validKeys,
  message = messages.GREATERTHANOREQUALTO_YEAR,
) => {
  const compareYearVerify = 1 * compareYear; // ensure year is a number

  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.number()
      .min(compareYearVerify)
      .error(() => message),
  );
};

/**
 * @description: Validate the date field is greater than or equal to a given date
 * @param compareDate the date to compare the field to
 * @param keys Fields to validate
 * @param message the message to display when the validation fails
 * @param config the config is flag to confirm whether provided compareDate is refrence or a value
 * @succeeds when the date field is greater than or equal to the compare date
 * @fails when date field is less than the compare date.
 */
export const dateGreaterThanOrEqualTo = (
  compareDate,
  keys = validKeys,
  // eslint-disable-next-line no-unused-vars
  message = messages.DATE_LESSTHANOREQUALTO,
  config = {},
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExt.string()
      .greaterThanEqual(config.isRef ? Joi.ref(compareDate) : compareDate)
      .error(() => message),
  );
};
/**
 * @description: Validate the date field is greater than or equal to a given date reference key
 * @param dateOfBirth the date of the policy holder's date of birth (DD/MM/YYYY)
 * @param keys Fields to validate
 * @param message the message to display when the validation fails
 * @param config the config is flag to confirm whether provided compareDate is refrence or a value
 * @succeeds when the date field is greater than or equal to the dateOfBirth refernce
 * @fails when date field is less than the dateOfBirth refernce date.
 */
export const dateGreaterThanOrEqualToCompare = (
  dateOfBirth,
  keys = validKeys,
  // eslint-disable-next-line no-unused-vars
  message = messages.DATE_LESSTHANOREQUALTO,
) => {
  const JoiExtend = Joi.extend(greaterThanEqualToCompare);
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExtend.string()
      .greaterThanEqualToCompare(dateOfBirth)
      .error(() => message),
  );
};

/**
 * @description: Validate the purchase date is greater than or equal to year of manufacture
 * @param yearofManufacture the year to compare the field to
 * @param keys Fields to validate
 * @param message the message to display when the validation fails
 * @param config the config is flag to confirm whether provided yearofManufacture is refrence or a value
 * @succeeds when the purchase date field is greater than or equal to the yearofManufacture
 * @fails when purchase date field is less than the yearofManufacture.
 */
export const purchaseDateGreaterThanEqualManufacture = (
  yearofManufacture,
  keys = validKeys,
  message = messages.INVALID_PURCHASE_DATE,
  config = {},
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    JoiExtnd.string()
      .purchaseDateManufactureYearCompare(
        config.isRef ? Joi.ref(yearofManufacture) : yearofManufacture,
      )
      .error(() => message),
  );
};
