/**
 * Creates and returns a function that appends ":x" to a string, where x is a
 * number unique to the string.
 * x increments by 1 for each repeat occurrence of a string.
 *
 * This is intended to let you generate stable react element keys for a
 * non-unique array of strings.
 * If one of the non-unique entries is removed, it is always the last entry
 *
 * E.g. in -> out
 * "foo"  ->  "foo:0"
 * "bar"  ->  "bar:0"
 * "foo"  ->  "foo:1"
 * @returns mapper function
 */
const uniqueKeyMapperFactory = () => {
  const counts = new Map();

  /**
   * Appends ":x" to a string, where x is a
   * number unique to the string.
   * x increments by 1 for each repeat occurrence of a string
   * @param {string} value
   * @returns value + ":x" where x is unique to this string
   */
  const mapper = value => {
    const keyIndex = counts.get(value) || 0;
    counts.set(value, keyIndex + 1);
    return `${value}:${keyIndex}`;
  };

  /**
   * Reset factory
   */
  const reset = () => {
    counts.clear();
  };
  return { mapper, reset };
};

export default uniqueKeyMapperFactory;
