import React from 'react';
import { Input, ErrorMessage } from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import PropTypes from 'prop-types';
import ThemedLabel from 'ageas-ui-components/lib/components/ThemedLabel/ThemedLabel';
import FieldStyled from '../../FieldStyled/FieldStyled';

export const getHomeMakeModelValidationSchema = (
  makeFieldName,
  modelFieldName,
) => {
  return {
    [makeFieldName]: stringRequired(
      [makeFieldName],
      'Please enter a Make',
    ).concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(/\S/, [makeFieldName], 'Please enter a Make'),
    ),
    [modelFieldName]: stringRequired(
      [modelFieldName],
      'Please enter a Model number',
    ).concat(
      // Nonblank (at least one non-whitespace char)
      regularExpression(/\S/, [modelFieldName], 'Please enter a Model number'),
    ),
  };
};

const HomeMakeModelDetails = props => {
  const { name = [], validate = () => {} } = props;

  const [ITEM_MAKE_NAME, ITEM_MODEL_NAME] = name;

  return (
    <>
      <FieldStyled name={ITEM_MAKE_NAME} validate={validate}>
        {({ input, meta }) => (
          <>
            <ThemedLabel fieldName={input.name}>
              Please confirm the Make
            </ThemedLabel>
            <Input
              size="small"
              maxLength="78"
              id={input.name}
              aria-label="item make"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
      <FieldStyled name={ITEM_MODEL_NAME} validate={validate}>
        {({ input, meta }) => (
          <>
            <ThemedLabel fieldName={input.name}>
              Please confirm the Model number
            </ThemedLabel>
            <Input
              size="small"
              maxLength="78"
              id={input.name}
              aria-label="item model"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
    </>
  );
};

export default HomeMakeModelDetails;

HomeMakeModelDetails.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string),
  validate: PropTypes.func,
};

HomeMakeModelDetails.defaultProps = {
  name: undefined,
  validate: undefined,
};
