// Take in a numeric value in numeric or string form
// If is an integer, return string with no dp
// If is not an integer, return string with 2 dp
// E.g.
// 1234    ->  1234
// 1234.1  ->  1234.10
const formatAmount = rawAmount => {
  const amount = Number.parseFloat(rawAmount);
  if (Number.isInteger(amount)) {
    return amount.toFixed(0);
  }
  return amount.toFixed(2);
};

export default formatAmount;
