import React from 'react';
import FaqP from '../../FaqP';

export const replaceDamagedItemsTitle =
  'How will you replace my damaged items?';
export const replaceDamagedItemsContent = (
  <>
    <FaqP>
      In the first instance, we will look at repairing the damaged item. If this
      is not possible, we will consider replacement options.
    </FaqP>
    <FaqP>
      We will look to replace your items as &apos;new for old&apos;. This means
      that, if your item is available to buy, we will aim to replace it by
      giving you a new one through one of our suppliers. We may alternatively
      give you a cash settlement for the value of the item.
    </FaqP>
    <FaqP>
      If the item is no longer available, we will look to either replace it with
      the nearest item available or give you a cash payment for the amount that
      the item is worth.
    </FaqP>
  </>
);
