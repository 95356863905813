import 'fastestsmallesttextencoderdecoder';
import 'core-js'; // <- at the top of your entry point
import 'react-app-polyfill/ie11'; // For IE 11 support
import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { rehydrateMarks } from 'react-imported-component';
import TagManager from 'react-gtm-module';
import config from './helpers/config';
import App from './App';

const supportsHistory = 'pushState' in window.history;
const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: config.client.gtm_id,
};

TagManager.initialize(tagManagerArgs);

const ClientApp = () => (
  <Router history={history} forceRefresh={!supportsHistory}>
    <App />
  </Router>
);

rehydrateMarks().then(() => {
  ReactDOM.hydrate(<ClientApp />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
}
