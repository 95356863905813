import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ThemedButton } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import PostcodeField from './PostcodeField';
import StandardInput from '../StandardInput/StandardInput';
import SectionError from '../SectionError/SectionError';
import {
  addressValidatorsPropType,
  addressFieldNamesPropType,
} from './AddressPropTypes';

// For documentation see:
// /docs/components/Forms/Address/AddressSubform.md

// Basic hideable div
const HideDiv = styled.div`
  ${({ show }) => {
    if (!show) {
      return 'display:none;';
    }
    return undefined;
  }}
`;

// Address Preview Panel (grey box to display non-editable address
// summary with edit button)
const AddressPreviewPanel = styled.div`
  border: 1px solid rgb(235, 235, 235);
  border-radius: 4px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px inset;
  padding: 20px;
  color: rgb(100, 100, 100);
  display: inline-block;
  min-width: 50%;
  margin-bottom: 15px;
`;
// Spacing between address summary and edit button
const AddressSummary = styled.div`
  margin-bottom: 1em;
`;

// Default field names for form
const defaultFieldnames = {
  houseNumberName: 'houseNumberName',
  address1: 'address1',
  address2: 'address2',
  address3: 'address3',
  address4: 'address4',
  postcode: 'postcode',
  addressSectionError: 'addressSectionError',
};

// Is address valid?
const addressIsValid = (form, fieldNames) => {
  return (
    form.getFieldState(fieldNames.houseNumberName)?.valid &&
    form.getFieldState(fieldNames.address1)?.valid &&
    form.getFieldState(fieldNames.address2)?.valid &&
    form.getFieldState(fieldNames.address3)?.valid &&
    form.getFieldState(fieldNames.address4)?.valid &&
    form.getFieldState(fieldNames.postcode)?.valid
  );
};

// Is any of the address entered?
const rffValueToString = (form, fieldName) => {
  const fieldState = form.getFieldState(fieldName);
  if (!fieldState) return '';
  return (fieldState?.value || '').trim();
};
const addressIsNonBlank = (form, fieldNames) => {
  return (
    rffValueToString(form, fieldNames.houseNumberName) !== '' ||
    rffValueToString(form, fieldNames.address1) !== '' ||
    rffValueToString(form, fieldNames.address2) !== '' ||
    rffValueToString(form, fieldNames.address3) !== '' ||
    rffValueToString(form, fieldNames.address4) !== '' ||
    rffValueToString(form, fieldNames.postcode) !== ''
  );
};

// Derive initial state on mount - if valid address, show summary,
// else fullForm
const initialDisplayState = (form, fieldNames) => {
  if (!addressIsNonBlank(form, fieldNames)) {
    return 'fullForm';
  }
  if (addressIsValid(form, fieldNames)) {
    return 'summary';
  }
  return 'fullForm';
};

const AddressSubform = ({ fieldNames, validators, validate, values, form }) => {
  const actualFieldNames = { ...defaultFieldnames, ...fieldNames };

  const [addressDisplayMode, setAddressDisplayMode] = useState('none');

  // Set initial display mode on first render
  useEffect(() => {
    if (addressDisplayMode === 'none') {
      setAddressDisplayMode(initialDisplayState(form, actualFieldNames));
    }
  }, []);

  // Auto update display mode when required
  // If address is nonblank and invalid, force override display mode
  // summary to edit
  useEffect(() => {
    if (
      addressDisplayMode === 'summary' &&
      !addressIsValid(form, actualFieldNames) &&
      addressIsNonBlank(form, actualFieldNames)
    ) {
      setAddressDisplayMode('fullForm');
    }
  });

  // Render address manual edit fields
  // inside a hideable div so formc an be hiddne if needed
  const renderAddressFieldset = () => {
    return (
      <HideDiv show={addressDisplayMode === 'fullForm'}>
        <StandardInput
          name={actualFieldNames.houseNumberName}
          label="House number or name"
          validate={validators?.houseNumberName || validate}
          small
          maxLength="30"
        />

        <StandardInput
          name={actualFieldNames.address1}
          label="Address line 1"
          validate={validators?.address1 || validate}
          small
          maxLength="32"
        />

        <StandardInput
          name={actualFieldNames.address2}
          label="Address line 2"
          validate={validators?.address2 || validate}
          small
          maxLength="32"
        />

        <StandardInput
          name={actualFieldNames.address3}
          label="Address line 3"
          validate={validators?.address3 || validate}
          small
          maxLength="32"
        />

        <StandardInput
          name={actualFieldNames.address4}
          label="Address line 4"
          validate={validators?.address4 || validate}
          small
          maxLength="32"
        />

        <PostcodeField
          name={actualFieldNames.postcode}
          label="Postcode"
          xSmall
          validate={validators?.postcode || validate}
          format
        />
      </HideDiv>
    );
  };

  // Render Address Summary pane - address as plain text (not fields)
  //  with edit button
  const renderSummary = () => {
    let summaryContent;
    if (
      !values[actualFieldNames.houseNumberName]?.trim() &&
      !values[actualFieldNames.address1]?.trim() &&
      !values[actualFieldNames.address2]?.trim() &&
      !values[actualFieldNames.address3]?.trim() &&
      !values[actualFieldNames.address4]?.trim() &&
      !values[actualFieldNames.postcode]?.trim()
    ) {
      summaryContent = <p>No address provided</p>;
    } else {
      summaryContent = (
        <>
          <p>
            {values[actualFieldNames.houseNumberName]}{' '}
            {values[actualFieldNames.address1]}
          </p>
          <p>{values[actualFieldNames.address2]}</p>
          <p>{values[actualFieldNames.address3]}</p>
          <p>{values[actualFieldNames.address4]}</p>
          <p>{values[actualFieldNames.postcode]}</p>
        </>
      );
    }
    if (addressDisplayMode === 'summary') {
      return (
        <AddressPreviewPanel>
          <AddressSummary>
            <p>Address:</p>
            {summaryContent}
          </AddressSummary>
          <ThemedButton
            small
            primary
            inverted
            type="button"
            onClick={() => {
              setAddressDisplayMode('fullForm');
            }}
          >
            Edit
          </ThemedButton>
        </AddressPreviewPanel>
      );
    }
    return null;
  };

  // Main render
  return (
    <HideDiv show={addressDisplayMode !== 'none'}>
      {renderSummary()}
      {renderAddressFieldset()}
      <SectionError
        name={actualFieldNames.addressSectionError}
        validate={validators.addressSectionError || validate}
      />
    </HideDiv>
  );
};

AddressSubform.propTypes = {
  validate: PropTypes.func,
  validators: addressValidatorsPropType,
  fieldNames: addressFieldNamesPropType,
  values: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
};

AddressSubform.defaultProps = {
  fieldNames: {},
  validators: {},
  validate: () => {},
};

export default AddressSubform;
