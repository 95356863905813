import React from 'react';
import styled from 'styled-components';
import BarLoader from 'react-spinners/BarLoader';
import PropTypes from 'prop-types';

const ContainerDiv = styled.div`
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '300px'};
`;

const FieldSpinner = ({ ...props }) => {
  return (
    <ContainerDiv {...props?.containerProps} aria-label="loading...">
      <BarLoader
        height={8}
        width="100%"
        margin={2}
        color="#8d409c"
        {...props}
      />
    </ContainerDiv>
  );
};

FieldSpinner.propTypes = {
  containerProps: PropTypes.shape({}),
};

FieldSpinner.defaultProps = {
  containerProps: undefined,
};

export default FieldSpinner;
