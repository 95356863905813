import React, { useState, useContext, useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { ThemedErrorMessage, ThemedButton } from 'ageas-ui-components';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-final-form';
import ThemedLinkButton from '../../../components/ThemedLinkButton/ThemedLinkButton';
import StandardPanel from '../../../components/StandardPanel/StandardPanel';
import P from '../../../components/StandardTags/P';
import { brand } from '../../../../data/whitelabel.config';
import BrandFooter from '../../../assets/BrandFooter';
import BrandHeader from '../../../assets/BrandHeader';
import NavigateTo from '../../../components/Navigation/NavigateTo/NavigateTo';
import {
  brokerDetail,
  BrokerContext,
} from '../../../helpers/brokerContextHelper';
import {
  MotorClaimsTelLink,
  HomeClaimsFNOL,
  TPAssistTelLink,
} from '../../../helpers/ageasTelephoneNumbers';
import BrokerLoginBreadcrumb from '../../../helpers/BrokerLoginBreadcrumb';
import ButtonLink from '../../../components/ThemedButtonLink/ThemedButtonLink';
import LoadingSpinner from '../../../components/UI/LoadingSpinner/LoadingSpinner';
import OTPAxios from '../../../helpers/axiosFunctions/OTPAxios';
import {
  getOtpVerifyContactPathByLob,
  getRootLoginPathByLob,
} from '../../../helpers/getLineOfBusinessSubfolder';
import useQuery from '../../../hooks/useQuery/useQuery';
import { getBusinessHoursDescriptive } from '../../../helpers/businessHoursData';
import {
  noAuthCodeOTP,
  noIdentityMatchedOTP,
  problemInRequestOTP,
} from '../../../helpers/errorMessages';
import StandardRadio from '../../../components/Forms/StandardRadio/StandardRadio';
import { consoleError } from '../../../helpers/consoleLog';

const Theme = getTheme(brand);
const QuestionStyle = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 32px;
`;

const PanelContent = styled.div`
  text-align: left;
  max-width: 1100px;
  margin: auto;
`;

const BottomButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const BackButtonStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
`;

const renderContactRadio = (name, label, contacts = []) => {
  // Use [contacts].flat() so contacts prop can be string or array
  const contactsList = [contacts].flat().map(contact => ({
    value: contact,
    label: contact,
  }));

  // Set initial value to only option if only option
  const initialValue =
    contactsList.length === 1 ? contactsList[0].value : undefined;

  return (
    <StandardRadio
      options={contactsList}
      name={name}
      title={label}
      fieldProps={{ initialValue }}
      vertical
    />
  );
};

const landingPagePhoneNumbers = {
  motor: <MotorClaimsTelLink />,
  home: <HomeClaimsFNOL />,
  motorTPA: <TPAssistTelLink />,
};

const phoneNumberToContact = landingPage => {
  return landingPagePhoneNumbers[landingPage] || landingPagePhoneNumbers.home;
};

const CONTACT_TYPE_DESCRIPTIONS = {
  SHORT: {
    mobile: 'number',
    email: 'address',
    generic: 'contact',
  },
  MEDIUM: {
    mobile: 'mobile phone number',
    email: 'email address',
    generic: 'contact',
  },
  FULL: {
    mobile: 'mobile telephone number',
    email: 'email address',
    generic: 'contact',
  },
};

const authCodeDestination = (
  contactType = 'generic',
  subType = 'MEDIUM',
  pleural = true,
) => {
  if (contactType === 'mobile') {
    return CONTACT_TYPE_DESCRIPTIONS[subType].mobile + (pleural ? '(s)' : '');
  }
  if (contactType === 'email') {
    return CONTACT_TYPE_DESCRIPTIONS[subType].email + (pleural ? '(es)' : '');
  }
  /* istanbul ignore next */
  return undefined;
};

const initialContactType = brokerContactContext => {
  if (brokerContactContext.contactList?.mobileTelephone?.length) {
    return 'mobile';
  }
  if (brokerContactContext.contactList?.emailAddress?.length) {
    return 'email';
  }
  return '';
};

const MobileEmailAuthentication = ({
  landingPage,
  includeHubWording,
  thirdParty = false,
}) => {
  const [navigatePath, setNavigatePath] = useState(null);
  const {
    dataState: brokerDataContext,
    updateDataState: setBrokerDataContext,
  } = useContext(BrokerContext);
  const brokerContactContext = { ...brokerDataContext };
  const [showContactType, setShowContactType] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [otpFailure, setOtpFailure] = useState(false);
  const axiosCancelToken = useRef(null);
  const queryParams = useQuery();

  useEffect(() => {
    // Set initial contact type
    setShowContactType(initialContactType(brokerContactContext));
    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const addTargetToPath = path => {
    const target = queryParams.get('target');
    if (target) {
      return `${path}?target=${encodeURIComponent(target)}`;
    }
    return path;
  };

  const navigateToSuccessDestination = () => {
    let path = `${getOtpVerifyContactPathByLob(landingPage)}`;
    path = addTargetToPath(path);
    setNavigatePath(path);
  };

  const onloadInitializeValue = () => {
    if (isEmpty(brokerDataContext)) {
      let path = `${getRootLoginPathByLob(landingPage)}`;
      path = addTargetToPath(path);
      setNavigatePath(path);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onloadInitializeValue();
  }, [brokerDataContext]);

  const handleContactChange = () => {
    if (showContactType === 'email') {
      setShowContactType('mobile');
    } else if (showContactType === 'mobile') {
      setShowContactType('email');
    }
  };

  const validateBrokerContacts = async values => {
    let contactData;
    if (showContactType === 'email') {
      contactData = {
        emailAddressSent: values.email,
      };
    } else {
      contactData = {
        mobileTelephoneSent: values.mobile,
      };
    }
    axiosCancelToken.current = axios.CancelToken.source();
    const { data, error } = await OTPAxios(
      'requestCode',
      brokerContactContext,
      contactData,
      axiosCancelToken.current.token,
      undefined,
    );
    if (error?.isCancel) {
      return; // Nothing to do if Axios request cancelled
    }
    setIsLoading(false);
    if (data) {
      if (
        data?.otpStatus === 'emailOTPSent' ||
        data?.otpStatus === 'mobileOTPSent'
      ) {
        setBrokerDataContext(brokerDetail.contactData, contactData);
        setBrokerDataContext(brokerDetail.preferredContact, data.otpStatus);
        navigateToSuccessDestination();
      } else {
        consoleError('unexpected otpStatus', data?.otpStatus);
        setOtpFailure(true);
      }
    } else if (error?.status === 400 || error?.status === 500) {
      setOtpFailure(true);
    } else {
      consoleError('unexpected response', error?.status);
      setOtpFailure(true);
    }
  };

  const formValidator = values => {
    if (showContactType === 'email' && !values.email) {
      return {
        email: `Please select an ${CONTACT_TYPE_DESCRIPTIONS.FULL.email}`,
      };
    }
    if (showContactType === 'mobile' && !values.mobile) {
      return {
        mobile: `Please select a ${CONTACT_TYPE_DESCRIPTIONS.FULL.mobile}`,
      };
    }
    return undefined;
  };

  const onSubmit = values => {
    setIsLoading(true);
    validateBrokerContacts(values);
  };

  const toggleContact = () => {
    return (
      <P>
        Alternatively, use your{' '}
        <ButtonLink primary onClick={handleContactChange}>
          {showContactType === 'email'
            ? 'mobile phone number'
            : 'email address'}
        </ButtonLink>{' '}
        to receive the code.
      </P>
    );
  };

  const renderFormEnding = () => {
    return (
      <>
        {!(
          brokerContactContext.displayError ||
          brokerContactContext.displayServerDownError
        ) && (
          <BottomButtonsContainer>
            <ThemedButton secondary small type="submit">
              Continue
            </ThemedButton>
          </BottomButtonsContainer>
        )}
      </>
    );
  };

  const renderBackButton = () => {
    // preserve the query params
    const path = addTargetToPath(getRootLoginPathByLob(landingPage));

    return (
      <>
        {(brokerContactContext.displayError ||
          brokerContactContext.displayServerDownError) && (
          <BackButtonStyle>
            <ThemedLinkButton secondary inverted small to={path}>
              Back
            </ThemedLinkButton>
          </BackButtonStyle>
        )}
      </>
    );
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      <ThemeProvider theme={Theme}>
        <BrandHeader />
        <BrokerLoginBreadcrumb activeItem="validateContact" />
        <main>
          <NavigateTo path={navigatePath} />
          <QuestionStyle>
            <PanelContent>
              <StandardPanel primary title="Authentication" padding="23px 21px">
                <Form onSubmit={onSubmit} validate={formValidator}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      {showContactType && (
                        <Heading>
                          For your online safety, we will send you an Ageas
                          authentication code. If more than one{' '}
                          {authCodeDestination(
                            showContactType,
                            'MEDIUM',
                            false,
                          )}{' '}
                          is shown, please select the{' '}
                          {authCodeDestination(showContactType, 'SHORT', false)}{' '}
                          you would like us to send the code to.
                        </Heading>
                      )}
                      {showContactType === 'mobile' && (
                        <>
                          {renderContactRadio(
                            'mobile',
                            'Mobile phone number(s)',
                            brokerContactContext.contactList?.mobileTelephone,
                          )}
                        </>
                      )}
                      {showContactType === 'email' && (
                        <>
                          {renderContactRadio(
                            'email',
                            'Email address(es)',
                            brokerContactContext.contactList?.emailAddress,
                          )}
                        </>
                      )}
                      {brokerContactContext.displayError && (
                        <ThemedErrorMessage hasIcon>
                          {noIdentityMatchedOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {brokerContactContext.displayServerDownError && (
                        <ThemedErrorMessage hasIcon>
                          {problemInRequestOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {showContactType && (
                        <>
                          {!!brokerContactContext.contactList?.emailAddress
                            ?.length &&
                            !!brokerContactContext.contactList?.mobileTelephone
                              ?.length && <>{toggleContact()}</>}
                          <P>
                            If the{' '}
                            {authCodeDestination(
                              showContactType,
                              'MEDIUM',
                              'true',
                            )}{' '}
                            above look incorrect,{' '}
                            {includeHubWording && (
                              <>and you want to make a new claim, </>
                            )}
                            please contact the claims team on{' '}
                            {phoneNumberToContact(landingPage)} between the
                            hours of {getBusinessHoursDescriptive(landingPage)}.
                            {includeHubWording && (
                              <>
                                {' '}
                                If you&apos;re looking for a progress update on
                                an existing claim, please call the claims team
                                between the hours of 9am-5pm, Monday to Friday
                                on the number provided when you reported your
                                claim.
                              </>
                            )}
                          </P>
                        </>
                      )}
                      <br />
                      {otpFailure && (
                        <ThemedErrorMessage hasIcon>
                          {noAuthCodeOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {renderFormEnding()}
                      {renderBackButton()}
                    </form>
                  )}
                </Form>
              </StandardPanel>
            </PanelContent>
          </QuestionStyle>
        </main>
        <BrandFooter thirdParty={thirdParty} />
      </ThemeProvider>
    </>
  );
};

export default MobileEmailAuthentication;

MobileEmailAuthentication.propTypes = {
  landingPage: PropTypes.string,
  includeHubWording: PropTypes.bool,
  thirdParty: PropTypes.bool,
};
MobileEmailAuthentication.defaultProps = {
  landingPage: undefined,
  includeHubWording: undefined,
  thirdParty: undefined,
};

export const MobileEmailAuthenticationMotor = props => {
  return <MobileEmailAuthentication {...props} landingPage="motor" />;
};
export const MobileEmailAuthenticationHome = props => {
  return (
    <MobileEmailAuthentication
      {...props}
      landingPage="home"
      includeHubWording
    />
  );
};

export const MobileEmailAuthenticationMotorTPA = props => {
  return (
    <MobileEmailAuthentication {...props} landingPage="motorTPA" thirdParty />
  );
};
