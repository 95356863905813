import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';

export const freezerTypeSchema = freezerTypeFieldName => {
  return {
    [freezerTypeFieldName]: stringRequired(
      [freezerTypeFieldName],
      'Please select a freezer type',
    ),
  };
};

const FREEZER_TYPE_LIST = [
  {
    value: 'standaloneUpright',
    label: 'Standalone upright',
  },
  {
    value: 'drawer',
    label: 'Drawer',
  },
  {
    value: 'chest',
    label: 'Chest',
  },
  {
    value: 'freezerBelow',
    label: 'Fridge with freezer below',
  },
  {
    value: 'freezerAbove',
    label: 'Fridge with freezer above',
  },
  {
    value: 'sideBySideFridge',
    label: 'Side-by-side fridge freezer',
  },
];

const FreezerType = ({ name, ...rest }) => {
  const FREEZER_TYPE_FIELD_NAME = name;
  return (
    <StandardRadio
      name={FREEZER_TYPE_FIELD_NAME}
      title="What type of freezer do you have?"
      options={FREEZER_TYPE_LIST}
      medium
      vertical
      {...rest}
    />
  );
};

FreezerType.propTypes = {
  name: PropTypes.string,
};
FreezerType.defaultProps = {
  name: undefined,
};

export default FreezerType;
