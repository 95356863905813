import React from 'react';

export const localStorageEscapeOfWaterPropertyName =
  'escapeOfWaterClaimFormState';

export const escapeOfWaterDataContext = {
  policyDetails: 'policyDetails',
  escapeOfWaterData: 'escapeOfWaterData',
  userContactNumber: 'userContactNumber',
  detailsCompleted: 'detailsCompleted',
};

export const EscapeOfWaterContext = React.createContext({});
