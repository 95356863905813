import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { HubContextMotor } from '../../helpers/hubContextHelper';
import {
  StatusText,
  TitleTextSpaced,
  LabelSet,
} from '../StandardPageWrapper/StandardPageWrapperStyle';
import LabelSetEntry from '../StandardPageWrapper/LabelSetEntry';
import { getStatusDesc } from '../../helpers/motorClaimStatusDescriptions';
import { reverseDate } from '../../helpers/dateTimeConverter';
import { getClaimTypeByCode } from '../../helpers/motorClaimTypeDescriptions';

const MotorHubClaimDetails = ({
  policyReference,
  externalPolicyReference,
  policyHolder,
  vrn,
  driver,
}) => {
  const { dataState = {} } = useContext(HubContextMotor);
  const { claimReference, statusCode, incidentDate, claimType } =
    dataState.claimDetails || {};

  if (!policyReference) {
    return null;
  }

  return (
    <>
      <TitleTextSpaced black>
        Claim reference {claimReference} -{' '}
        <StatusText>{getStatusDesc(statusCode)}</StatusText>
      </TitleTextSpaced>

      <LabelSet>
        <LabelSetEntry label="Policyholder name:">
          {policyHolder?.name || 'Unspecified'}
        </LabelSetEntry>

        <LabelSetEntry label="Policy number:">
          {externalPolicyReference || policyReference}
        </LabelSetEntry>

        <LabelSetEntry label="Vehicle reg:">
          {vrn || 'Unspecified'}
        </LabelSetEntry>

        <LabelSetEntry label="Claim type:">
          {getClaimTypeByCode(claimType)?.capitalise || claimType}
        </LabelSetEntry>

        <LabelSetEntry label="Incident date:">
          {incidentDate && reverseDate(incidentDate)}
        </LabelSetEntry>

        <LabelSetEntry label="Driver:">
          {driver?.name || 'Unspecified'}
        </LabelSetEntry>
      </LabelSet>
    </>
  );
};

MotorHubClaimDetails.propTypes = {
  policyReference: PropTypes.string,
  externalPolicyReference: PropTypes.string,
  policyHolder: PropTypes.shape({ name: PropTypes.string }),
  vrn: PropTypes.string,
  driver: PropTypes.shape({ name: PropTypes.string }),
};

MotorHubClaimDetails.defaultProps = {
  policyReference: undefined,
  externalPolicyReference: undefined,
  policyHolder: undefined,
  vrn: undefined,
  driver: undefined,
};

export default MotorHubClaimDetails;
