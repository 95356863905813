import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import StandardP from '../StandardTags/P';

const FaqP = styled(StandardP)`
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  ${props =>
    props.marginBottom === false &&
    css`
      margin-bottom: 0px;
    `}
`;
FaqP.propTypes = {
  marginBottom: PropTypes.bool,
};
FaqP.defaultProps = {
  marginBottom: true,
};

export default FaqP;
