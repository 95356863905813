import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const startedRepairsQuestion =
  'I have already started repairs, how will this affect the claim?';
export const startedRepairsContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        We would ask that you contact us before you undertake any repairs or
        clean up, unless repairs are essential for the immediate protection of
        the property/item(s).
      </FaqP>
      <FaqP>
        Please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Leaking or Escape of water in the home',
              'Can I carry out temporary repairs?',
            ])
          }
        >
          &apos;Can I carry out temporary repairs?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

startedRepairsContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
startedRepairsContent.defaultProps = {
  nestingControl: undefined,
};

export default startedRepairsContent;
