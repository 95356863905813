import styled from 'styled-components';
import media from '../../../components/MediaQuery/MediaQueryMin';

const Logo = styled.figure`
  height: 65px;
  overflow: hidden;
  max-width: 100%;
  display: block;
  margin: 0;
  display: flex;
  align-items: center;
  img {
    width: auto;
    height: 110px;
    padding-right: 5px;
  }
  figcaption {
    text-decoration: none;
  }
  ${media.phone`
    height: 110px;
    overflow: visible;
    margin: 10px 0 0 0;
  `}
`;

const Header = styled.header`
  width: 100%;
  a {
    color: ${props => props.theme.header.title};
    text-decoration: none;
    font-size: 1.3em;
  }
`;

const Container = styled.div`
  display: flex;
  max-width: 100%;
  min-width: 100%;
  padding: 0 0.5em;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  ${media.desktop`
    max-width: 1170px;
    min-width: auto;
    display: grid;
    grid-template-columns: 1fr 255px;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'main sidebar' 'formNav sidebar';
    grid-gap: 1em;
    padding: 0 2em;
  `}
`;

export { Logo, Container, Header };
