import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UL from '../StandardTags/UL';

const Container = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const ScrollList = ({ className, items, valueIsKey = true }) => {
  return (
    <Container className={className}>
      <UL>
        {items?.map(({ key, value }, index) => (
          <li
            key={
              key || (typeof value === 'string' && valueIsKey && value) || index
            }
          >
            {value}
          </li>
        ))}
      </UL>
    </Container>
  );
};

ScrollList.propTypes = {
  className: PropTypes.string,
  valueIsKey: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.node,
    }),
  ),
};
ScrollList.defaultProps = {
  className: undefined,
  valueIsKey: undefined,
  items: undefined,
};

export default ScrollList;
