import styled from 'styled-components';

import media from '../../../components/MediaQuery/MediaQueryMin';

const Text = styled.p`
  color: ${props => props.theme.title.secondary.text};
  margin-top: 5px;
  padding: 0 1em;
  font-size: 1em;
`;

const CustomModalFooter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  padding-top: 2em;
  margin: 0 1em;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}
`;

export { Text, CustomModalFooter };
