import Joi from '@hapi/joi';

/**
 * @description: Validate the direct debit form in the renewals journey
 * @succeeds when all fields in the form have a value
 * @fails when not  all fields in the form have a value
 */
const directDebitRenewals = Joi.object({
  accountHolder: Joi.string()
    .required()
    .error(
      () =>
        `
        The AccountHolderName field is required
        `,
    ),
  accountNumber: Joi.number()
    .required()
    .error(() => 'The AccountNumber field is required'),
  bankSortCode: Joi.string()
    .required()
    .error(() => 'The SortCode field is required'),
  bankName: Joi.string()
    .required()
    .error(
      () =>
        `
        The BankSocietyName field is required
        `,
    ),
  directDebitAgreement: Joi.string()
    .required()
    .invalid('disagree')
    .error(() => 'You must agree to the terms.'),
});

export default directDebitRenewals;
