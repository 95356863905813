export const MOTOR_CLAIM_TYPES_CORE = [
  { value: 'faultAccident', label: 'Fault accident' },
  { value: 'nonFaultAccident', label: 'Non fault accident' },
  { value: 'accident', label: 'Accident', notUserAddable: true },
  { value: 'theft', label: 'Theft' },
  { value: 'fire', label: 'Fire' },
  { value: 'windscreen', label: 'Windscreen' },
];

// User selectable
const motorClaimTypes = MOTOR_CLAIM_TYPES_CORE.filter(
  ({ notUserAddable }) => !notUserAddable,
).map(({ label, value }) => ({ label: `${label} claim`, value }));

export const motorClaimTypeToObject = (
  motorClaimType,
  typesList = motorClaimTypes,
) => {
  return typesList.find(({ value }) => value === motorClaimType);
};
export const motorClaimTypeToText = motorClaimType => {
  return motorClaimTypeToObject(motorClaimType)?.label;
};

export default motorClaimTypes;
