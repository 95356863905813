import styled, { css } from 'styled-components';
import media from '../../MediaQuery/MediaQuery';

// ThemedDropdownV2 defaults to inline which we don't want
const FieldInnerBlockContainer = styled.div`
  margin-top: 16px;
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
  display: block;
  width: 100%;

  ${props =>
    props.small &&
    css`
      width: 25%;
    `};

  ${props =>
    props.medium &&
    css`
      width: 50%;
    `}

  ${props =>
    props.large &&
    css`
      width: 78%;
    `}

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
    
  ${media.tablet`
    width: 100%;
    `}
`;

export default FieldInnerBlockContainer;
