import { yesNoToBoolean } from '../helpers';

/**
 * Map vehicleDamageDetails context/pageForms data to JSON Blob vehicleDamage
 * section
 * @param {*} data incidentDetails in MotorClaimContextNew, claimEntryData
 * format
 * @returns data in JSON Blob incidentDetails section format
 */
const mapLocalToVehicleDamageSection = ({ vehicleDamageDetails } = {}) => {
  const {
    vehicleIsDamaged,
    damageLevels,
    undersideIsDamaged,
    undersideDamageLevel,
    hasMechanicalDamage,
    mechanicalDamages,
    hasPreviousDamage,
    previousDamageDetails,
    airbagsTriggered,
    wasDrivenAway,
    isDrivable,
  } = vehicleDamageDetails || {};

  if (!vehicleIsDamaged) {
    return undefined;
  }
  const out = {
    isDamaged: yesNoToBoolean(vehicleIsDamaged),
  };

  if (!out.isDamaged) {
    return out;
  }

  const undersideIsDamagedServer = yesNoToBoolean(undersideIsDamaged);
  const hasMechanicalDamageServer = yesNoToBoolean(hasMechanicalDamage);

  out.damageDetails = {
    damageLevels,
    undersideIsDamaged: undersideIsDamagedServer,
    hasMechanicalDamage: hasMechanicalDamageServer,
    mechanicalDamages:
      hasMechanicalDamageServer && mechanicalDamages?.length
        ? mechanicalDamages
        : undefined,
    hasPreviousDamage,
    previousDamageDetails:
      hasPreviousDamage === 'yes' ? previousDamageDetails?.trim() : undefined,
    airbagsTriggered: yesNoToBoolean(airbagsTriggered),
    wasDrivenAway: yesNoToBoolean(wasDrivenAway),
    isDrivable: yesNoToBoolean(isDrivable),
  };

  if (undersideIsDamagedServer && undersideDamageLevel) {
    out.damageDetails.damageLevels = {
      ...out.damageDetails.damageLevels,
      underside: undersideDamageLevel,
    };
  }

  // Return mapped object
  return out;
};

export default mapLocalToVehicleDamageSection;
