import React from 'react';
import PropTypes from 'prop-types';
import useStandardModalControl from '../../hooks/useStandardModalControl';

/**
 * Context for useStandardModalControl(), to enable integration between
 * FieldArrayDisplay/other usages and ModalForm
 */
const StandardFormModalContext = ({ Context, children }) => {
  const contextValue = useStandardModalControl();

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

StandardFormModalContext.propTypes = {
  Context: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
};
StandardFormModalContext.defaultProps = {
  children: undefined,
};

export default StandardFormModalContext;
