import { useCallback, useMemo, useState } from 'react';

/**
 * For use when using e.g. FieldArrayDisplay together with ModalForm
 * FieldArrayDisplay/usage has the functions for inserting/updating
 * individual entries in the array field in the form, that the
 * modal buttons need to call
 *
 * So onAdd/onEdit buttons on modal call callbacks that provide
 * access to those add/update methods. onAdd/onEdit then generate
 * the onSubmit/onEditSubmit callbacks that the modal needs, and
 * put into the modal state to be passed as props to the modal
 *
 * Functions are wrapped in useCallback, and output object in useMemo
 * for performance, so output object only updates when necessary
 */

const useStandardModalControl = () => {
  /**
   * If null, modal should not be rendered
   * Else is an object of props - modal should be rendered, and passed those
   * props
   */
  const [modalState, setModalState] = useState(null);
  /**
   * Record if modal has been opened and closed at least once.
   * As this is how we define "touched" for a modal array field.
   */
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  /**
   * Close modal, by setting state to null
   * Record modal has been touched
   */
  const closeModal = useCallback(() => {
    setModalState(null);
    setHasBeenTouched(true);
  }, [setModalState, setHasBeenTouched]);

  /**
   * Open modal with provided state/props
   */
  const openModal = useCallback(
    state => {
      setModalState(state);
    },
    [setModalState],
  );

  /**
   * onAdd callback for FieldArrayDisplay/any other usage
   * Open modal with no initial values, with onSubmit callback that will
   * call provided add function with new value
   */
  const onAdd = useCallback(
    (add, title, initialValues = {}, modalProps = {}) => {
      const onAddSubmit = values => {
        add(values);
        closeModal();
      };
      openModal({
        onSubmit: onAddSubmit,
        initialValues,
        title,
        ...modalProps,
      });
    },
    [closeModal],
  );

  /**
   * onAdd callback for FieldArrayDisplay
   * Open modal with no initial values, with onSubmit callback that will
   * update specified entry to array field
   */
  const onEdit = useCallback(
    (index, initialValues, update, title, modalProps = {}) => {
      const onEditSubmit = values => {
        update(index, values);
        closeModal();
      };
      openModal({
        index,
        onSubmit: onEditSubmit,
        initialValues,
        title,
        ...modalProps,
      });
    },
    [closeModal],
  );

  /** Combined state object */
  const contextValue = useMemo(
    () => ({
      onAdd,
      onEdit,
      openModal,
      closeModal,
      modalState,
      hasBeenTouched,
    }),
    [onAdd, onEdit, closeModal, modalState, hasBeenTouched],
  );

  return contextValue;
};

export default useStandardModalControl;
