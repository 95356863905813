const brand = 'ageas';
const ageasLogo = require('./assets/brandLogo/ageas_nest_RGB.svg');

const headerInfo = {
  logoText: 'Claims',
  homeLink: 'https://www.ageas.co.uk/',
  showHelpBox: true,
  texts: [
    'Wait! Are you sure you want to leave this page?'
  ],
  telephoneNumbers: [''],
};

// think this is superfluous to claims at the moment but copying it in so that the build doesn't fail
const benefitsBanner = {
  activeList: ['/yourproperty', '/yourdetails'],
  benefits: [
    {
      title: "One of UK's largest home insurers",
      description: 'Around 5 million customers already trust us',
    },
    {
      title: 'Defaqto 5 Star cover for your home',
      description: 'One of the highest levels of cover in the market',
    },
    {
      title: 'Make a claim 24/7',
      description: 'With our UK based claims service',
    },
  ],
};

module.exports = {
  brand,
  brandLogo: ageasLogo,
  headerInfo,
  benefitsBanner,
};
