import React, { useRef } from 'react';
import {
  ThemedLabel,
  ThemedDropdownV2,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';
import StandardHint from '../../StandardHint/StandardHint';
import ClearOnInvalid from '../ClearOnInvalid/ClearOnInvalid';

// For documentation see:
// /docs/components/Forms/StandardDropdown/StandardDropdown.md

export const getStandardDropdownSchema = (fieldName, error) => {
  return {
    [fieldName]: stringRequired(
      [fieldName],
      error || 'Please select an option',
    ),
  };
};

const StandardDropdown = ({
  id,
  name,
  label,
  secondaryLabel,
  validate,
  alwaysShowError,
  options,
  children,
  onChange,
  beforeBlur,
  fieldProps,
  small,
  medium,
  large,
  blockContainerProps,
  autoClearOnInvalid,
  hint,
  hintProps,
  ...props
}) => {
  const elementId = useRef(id);
  if (!elementId.current) {
    elementId.current = `StandardDropdown-${name}-${_uniqueId()}`;
  }

  const onSelectChange = (event, input) => {
    input.onChange(event);
    onChange(event);
  };

  const onSelectBlur = (event, input) => {
    if (!beforeBlur || beforeBlur(event) !== false) input.onBlur(event);
  };

  const containerProps = {
    small,
    medium,
    large,
  };

  const renderOptions = () => {
    return options.map(option => {
      return (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      );
    });
  };

  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input, meta }) => (
        <>
          {autoClearOnInvalid && (
            <ClearOnInvalid
              value={input.value}
              options={options}
              onChange={() => onSelectChange(undefined, input)}
            />
          )}
          <ThemedLabel fieldName={elementId.current}>
            {label}
            {hint && (
              <StandardHint
                field={elementId.current}
                hint={hint}
                hintProps={hintProps}
              />
            )}
            {secondaryLabel && (
              <>
                <br />
                <small>{secondaryLabel}</small>
              </>
            )}
          </ThemedLabel>
          <FieldInnerBlockContainer marginTop="0px" {...blockContainerProps}>
            <ThemedDropdownV2
              noTextBehindIcon
              {...props}
              id={elementId.current}
              {...input}
              onBlur={event => {
                onSelectBlur(event, input);
              }}
              selected={input.value}
              onChange={event => {
                onSelectChange(event, input);
              }}
              hasError={meta.error && (meta.touched || alwaysShowError)}
              containerProps={containerProps}
            >
              {renderOptions()}
            </ThemedDropdownV2>
          </FieldInnerBlockContainer>
          {meta.error && (meta.touched || alwaysShowError) && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validate: PropTypes.func,
  alwaysShowError: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  onChange: PropTypes.func,
  beforeBlur: PropTypes.func,
  fieldProps: PropTypes.shape({}),
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  blockContainerProps: PropTypes.shape({}),
  autoClearOnInvalid: PropTypes.bool,
  hint: PropTypes.node,
  hintProps: PropTypes.instanceOf(Object),
};

StandardDropdown.defaultProps = {
  id: undefined,
  label: '',
  secondaryLabel: undefined,
  validate: () => {},
  alwaysShowError: false,
  options: [],
  children: undefined,
  onChange: () => {},
  beforeBlur: undefined,
  fieldProps: {},
  small: undefined,
  medium: undefined,
  large: undefined,
  blockContainerProps: undefined,
  autoClearOnInvalid: false,
  hint: undefined,
  hintProps: {},
};

export default StandardDropdown;
