import React from 'react';
import FaqP from '../../FaqP';

export const contentsExcessPayCollectTitle =
  'What is my excess, when will I have to pay it and how will you collect it?';
export const contentsExcessPayCollectContent = (
  <>
    <FaqP>Your excess is shown on your policy documentation.</FaqP>
    <FaqP>
      If your claim is settled via a cash payment, the excess will be deducted
      from the total amount before it is paid to you. Alternatively, if one of
      our repair networks carry out repairs or replacement, they will collect
      your excess from you on our behalf.
    </FaqP>
  </>
);
