import React from 'react';
import FaqP from '../../FaqP';

export const excessPaymentQuestion =
  'What is my excess, when will I have to pay it and how will you collect it?';
export const excessPaymentContent = (
  <>
    <FaqP>Your excess is shown on your policy documentation.</FaqP>
    <FaqP>
      You will either pay the repair network directly or, when we settle the
      claim with cash, we will deduct the excess from the total to be paid.
    </FaqP>
  </>
);
