import { messages } from '../constants/ErrorMessages';

let totalMileageValue;
let businessMileageValue;

export function mileageCalculation(Joi) {
  return {
    base: Joi.number(),
    name: 'number',
    language: {
      mileageCalculation: messages.CAR_MILEAGE,
      mileagePerWeek: messages.MILES_PER_WEEK,
    },
    rules: [
      {
        name: 'mileageCalculation',
        params: {
          mileage: Joi.alternatives([
            Joi.number().required(),
            Joi.func().ref(),
          ]),
        },
        validate(params, value, state, options) {
          if (value !== null || value !== undefined) {
            totalMileageValue = value;
            if (totalMileageValue >= 1000000) {
              return this.createError(
                'string.maximumValue',
                { value, businessMileage: params.businessMileage },
                state,
                options,
              );
            }
          }
          return value;
        },
      },
    ],
  };
}

export function lessThanEqual(Joi) {
  return {
    base: Joi.number(),
    name: 'number',
    language: {
      novalue: messages.CAR_BUSINESS_MILEAGE,
      lessThanEqual: messages.CAR_BUSINESS_MILEAGE_LESSTHANOREQUALTO,
    },
    rules: [
      {
        name: 'lessThanEqual',
        params: {
          businessMileage: Joi.alternatives([
            Joi.number().required(),
            Joi.func().ref(),
          ]),
        },
        validate(params, value, state, options) {
          if (value !== null || value !== undefined) {
            businessMileageValue = value;
            if (businessMileageValue > totalMileageValue) {
              return this.createError(
                'string.lessThanEqual',
                { value, businessMileage: params.businessMileage },
                state,
                options,
              );
            }
          }
          if (businessMileageValue <= totalMileageValue) {
            return businessMileageValue;
          }
          return value;
        },
      },
    ],
  };
}
