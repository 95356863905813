import styled, { css } from 'styled-components';
import { ThemedButton } from 'ageas-ui-components';
import maxMedia, { sizes } from '../MediaQuery/MediaQuery';
import { narrowModeCSSDisplay } from '../../helpers/narrowModeCSSDisplay';

// This controls at what screen width the claim ref wraps to a new line
export const NARROW_MODE_MAX_WIDTH = `${sizes.phone}px`; // "600px";

export const ActionButton = styled(ThemedButton)`
  margin: 0px;
`;

export const EntryComponent = styled.div`
  min-width: 20px;
  min-height: 20px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const ButtonContainer = styled(EntryComponent)`
  align-self: center;
  flex-grow: 1;
  text-align: right;
  ${maxMedia.phone`
    width: 100%;
  `}
`;

export const Entry = styled.div`
  background-color: rgb(235, 235, 235);
  margin: 20px ${({ sideMargin }) => sideMargin || ''};
  padding: 10px;
  display: flex;
  column-gap: 10px;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const DataContainer = styled(EntryComponent)`
  flex-grow: 1;
  flex-shrink: 1;
`;

// column-gap is approx width of a space
export const DataLineContainer = styled.div`
  ${({ narrowMode }) =>
    narrowModeCSSDisplay(NARROW_MODE_MAX_WIDTH, narrowMode, 'flex')};

  column-gap: 0.25em;
  line-height: 1.5em;

  ${({ wrap }) =>
    !wrap
      ? ''
      : css`
          flex-wrap: ${wrap};
        `};
`;

export const DataLineItem = styled.div`
  ${({ narrowMode }) =>
    narrowModeCSSDisplay(NARROW_MODE_MAX_WIDTH, narrowMode, 'block')};
  flex-grow: 0;
  flex-shrink: ${({ shrink }) => (shrink !== undefined ? shrink : 0)};
  min-width: 20px;
  min-height: 10px;

  ${({ primary }) =>
    primary &&
    css`
      font-weight: bold;
      color: #8e419c;
    `};

  ${({ complete }) =>
    complete &&
    css`
      font-weight: bold;
      color: green;
    `};
`;

export const IconContainer = styled(EntryComponent)`
  align-self: center;
  width: 40px;
`;

export const IconImg = styled.img`
  max-width: 100%;
`;
