import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import {
  ClaimButtonStyle,
  NavLinkButtonIconRight,
  NavLinkButtonIconContainer,
} from '../assets/styles/GenericInsurance.style';
import { checkBusinessHours } from './checkBusinessHours';
import { businessHoursDescriptive } from './businessHoursData';
import { HomeClaimsFNOL, AxaAssistanceTelLink } from './ageasTelephoneNumbers';
import ThemedIconButton from '../components/ThemedIconButton/ThemedIconButton';
import P from '../components/StandardTags/P';
import { checkFeatureSwitch } from '../components/FeatureSwitch/FeatureSwitch';

const excessWarning = (
  <>
    As your policy has an excess, it is important that you check what this
    excess is <u>before</u> submitting your claim. The excess is the amount that
    you will have to pay when making your claim.
  </>
);

const renderReferralFormLink = () => {
  const businessHours = checkBusinessHours('home');

  if (!checkFeatureSwitch('FEATURE_HOMEPAGE_REFERRAL_LINK', ['version1'])) {
    return null;
  }
  const alwaysShow = checkFeatureSwitch(
    'FEATURE_HOMEPAGE_REFERRAL_LINK_ALWAYS_ON',
    ['version1'],
  );
  return (
    <>
      {(alwaysShow || !businessHours) && (
        <>
          <P>
            Alternatively, please fill out a few details on the Claim form and
            one of our claims handlers will be in touch by the end of the next
            working day.
          </P>
          <ClaimButtonStyle>
            <NavLinkButtonIconRight
              secondary
              href="https://www.ageas.co.uk/make-a-claim/home-insurance/"
            >
              Claim form
              <NavLinkButtonIconContainer>
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </NavLinkButtonIconContainer>
            </NavLinkButtonIconRight>
          </ClaimButtonStyle>
        </>
      )}
    </>
  );
};

const renderClaimFormSection = () => {
  return (
    <>
      <P>
        Please note that, currently, we are unable to accept online claims for
        Home insurance policies that have Excess Protection cover.
      </P>
      <P>
        If your Excess Protection policy started or renewed on or before
        01/02/2023 (provided by Ageas), please call{' '}
        <strong>
          <HomeClaimsFNOL />
        </strong>{' '}
        between the hours of {businessHoursDescriptive}.
      </P>
      <P>
        If your Excess Protection policy started or renewed on or after
        02/02/2023 (services provided by AXA Assistance Limited), please visit{' '}
        <a
          href="https://www.excessclaim.co.uk"
          target="_blank"
          rel="noreferrer"
        >
          https://www.excessclaim.co.uk
        </a>{' '}
        to log this part of your claim with them or alternatively call{' '}
        <strong>
          <AxaAssistanceTelLink />
        </strong>
      </P>
      {renderReferralFormLink()}
    </>
  );
};

const renderClaimFormContent = () => {
  return (
    <>
      <P>
        Please call the Claims Team on{' '}
        <strong>
          <HomeClaimsFNOL />
        </strong>{' '}
        between the hours of {businessHoursDescriptive}.
      </P>
      {renderReferralFormLink()}
    </>
  );
};

const renderFirstParagraph = () => {
  return (
    <P>
      Please submit your claim using our online claim journey. By completing
      your claim online, we can validate your claim and process it today.*{' '}
      {excessWarning}
    </P>
  );
};
const renderContinueButton = (renderDisclaimer = () => {}, queryParameters) => {
  return (
    <ThemedIconButton
      type="button"
      secondary
      onClick={() => renderDisclaimer('AD', queryParameters)}
      iconRight={faChevronCircleRight}
    >
      Continue
    </ThemedIconButton>
  );
};
const renderLastParagraph = (renderDisclaimer = () => {}, queryParameters) => {
  return (
    <>
      <P>
        *If you need to claim for more than one item, please call the Claims
        Team on{' '}
        <strong>
          <HomeClaimsFNOL />{' '}
        </strong>
        between the hours of {businessHoursDescriptive}.
      </P>
      {renderContinueButton(renderDisclaimer, queryParameters)}
    </>
  );
};

const PROGRESSIVE_QUESTIONS_ECLAIMS = (renderDisclaimer = () => {}) => {
  return [
    {
      id: 1,
      answers: [
        {
          id: 'escapeOfWater',
          description: 'Escape of water',
          content: (
            <P>
              Damage to your buildings and contents caused by a water leak in
              your home, or from a neighbouring property. This could include a
              burst pipe, washing machine or water tank.
            </P>
          ),
          nextId: 10,
          switchOff: !checkFeatureSwitch('FEATURE_HOME_CLAIM_ESCAPE_OF_WATER', [
            'version1',
          ]),
        },
        {
          id: 'stormDamage',
          description: 'Storm damage',
          content: (
            <P>
              Damage caused by an extreme weather event, usually including very
              high winds, extreme rain, hail or snow.
            </P>
          ),
          nextId: 2,
          switchOff: !checkFeatureSwitch('FEATURE_HOME_CLAIM_STORM', [
            'version1',
          ]),
        },
        {
          id: 'accidentalDamage',
          description: 'Accidental damage',
          content: (
            <P>
              Damage that is unexpected and unintended, by something sudden
              which is not deliberate. Examples of accidental damage include
              spilling a drink and staining the carpet, or accidentally drilling
              through a pipe.
            </P>
          ),
          nextId: 4,
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE',
            ['version1'],
          ),
        },
        {
          id: 'lossOfPersonalBelongings',
          description: 'Loss of personal belongings',
          content: (
            <>
              <P>
                This covers your personal belongings if they are lost within
                Europe (or worldwide for up to 60 days).
              </P>
              <P>
                Before you make a claim online, you must report the loss to the
                appropriate authority and obtain a lost property reference
                number. If you haven&apos;t done this already, you can do so by
                following the link{' '}
                <a
                  href="https://www.reportmyloss.com/uk"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.reportmyloss.com/uk
                </a>
                .
              </P>
            </>
          ),
          nextId: 7,
          switchOff: !checkFeatureSwitch('FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS', [
            'version1',
          ]),
        },
        {
          id: 'theftOfPersonalBelongings',
          description: 'Theft of personal belongings',
          content: <>{renderClaimFormContent()}</>,
          switchOff: !checkFeatureSwitch('FEATURE_HOME_CLAIM_THEFT', [
            'version1',
          ]),
        },
        {
          id: 'freezerFood',
          description: 'Freezer food',
          content: (
            <P>
              Damage to fridge or freezer food due to a rise or fall in
              temperature, or contamination by refrigerant or refrigerant fumes.
            </P>
          ),
          nextId: 9,
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_FREEZERFOOD_DAMAGE',
            ['version1'],
          ),
        },
        {
          id: 'something',
          description: 'Something else',
          content: <>{renderClaimFormContent()}</>,
        },
      ],
    },
    {
      id: 2,
      title: 'Does your policy have Excess Protection cover?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: <>{renderClaimFormSection()}</>,
        },
        {
          id: 'no',
          description: 'No',
          nextId: 3,
        },
      ],
    },
    {
      id: 3,
      title: 'Do you have Buildings or Contents damage?',
      answers: [
        {
          id: 'buildings',
          description: 'Buildings damage',
          content: (
            <>
              <P>
                Please submit your claim using our online claim journey. By
                completing your claim online, we will be able to validate your
                claim and take the appropriate action needed immediately.{' '}
                {excessWarning}
              </P>
              <ThemedIconButton
                type="button"
                secondary
                onClick={() => renderDisclaimer('ST')}
                iconRight={faChevronCircleRight}
              >
                Continue
              </ThemedIconButton>
            </>
          ),
        },
        {
          id: 'contents',
          description: 'Contents damage',
          content: <>{renderClaimFormContent()}</>,
        },
      ],
    },
    {
      id: 4,
      title: 'Does your policy have Excess Protection cover?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: <>{renderClaimFormSection()}</>,
        },
        {
          id: 'no',
          description: 'No',
          nextId: 5,
        },
      ],
    },
    {
      id: 5,
      title: 'What item would you like to claim for?',
      answers: [
        {
          id: 'electronicDevices',
          description: 'Electronic devices',
          nextId: 6,
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_ELECTRONIC_DEVICES',
            ['version1'],
          ),
        },
        {
          id: 'carpets',
          description: 'Carpets',
          content: (
            <>
              <P>
                Please submit your claim using our online claim journey. By
                completing your claim online, we can validate your claim and
                process it today. {excessWarning}
              </P>
              {renderContinueButton(renderDisclaimer, '?itemType=carpets')}
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_CARPETS',
            ['version1'],
          ),
        },
        {
          id: 'fixedGlazing',
          description: 'Fixed glazing',
          content: (
            <>
              {renderFirstParagraph()}
              <P>
                <strong>Note - </strong>Damage caused to fixed glass in windows
                and doors <strong>only. </strong>If you’ve had the repairs done
                already and/or have an estimate, invoice or receipt, please have
                the document to hand as you will need to upload a copy.
              </P>
              {renderLastParagraph(renderDisclaimer, '?itemType=fixedGlazing')}
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING',
            ['version1'],
          ),
        },
        {
          id: 'accidentalDamageJewellery',
          description: 'Jewellery',
          content: (
            <>
              {renderFirstParagraph()}
              <P>
                *If you need to claim for more than two items, please call the
                Claims Team on{' '}
                <strong>
                  <HomeClaimsFNOL />{' '}
                </strong>
                between the hours of {businessHoursDescriptive}.
              </P>
              <ThemedIconButton
                type="button"
                secondary
                onClick={() => renderDisclaimer('AD', '?itemType=jewellery')}
                iconRight={faChevronCircleRight}
              >
                Continue
              </ThemedIconButton>
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_JEWELLERY',
            ['version1'],
          ),
        },
        {
          id: 'somethingElse',
          description: 'Something else',
          content: <>{renderClaimFormContent()}</>,
        },
      ],
    },
    {
      id: 6,
      title: 'Please select an electronic device',
      answers: [
        {
          id: 'tv',
          description: 'TV',
          content: (
            <>
              {renderFirstParagraph()}
              <P>
                Make sure you know your Make and Model before you start the
                claim. The Model should be on the rear of the TV or in the
                manual.
              </P>
              {renderLastParagraph(renderDisclaimer, '?itemType=tv')}
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_TV',
            ['version1'],
          ),
        },
        {
          id: 'mobilePhone',
          description: 'Mobile phone',
          content: (
            <>
              {renderFirstParagraph()}
              <P>
                Make sure you know your Make and Model before you start the
                claim. We’ll also need your IMEI number if your phone is
                accessible – you’ll be able to find it in the Settings.
              </P>
              {renderLastParagraph(renderDisclaimer, '?itemType=mobile')}
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_MOBILE',
            ['version1'],
          ),
        },
        {
          id: 'laptop',
          description: 'Laptop',
          content: (
            <>
              {renderFirstParagraph()}
              <P>
                Make sure you know your Make and Model before you start the
                claim. The Model should be on the laptop or in the manual.
              </P>
              {renderLastParagraph(renderDisclaimer, '?itemType=laptop')}
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_LAPTOP',
            ['version1'],
          ),
        },
        {
          id: 'tablet',
          description: 'Tablet',
          content: <>{renderClaimFormContent()}</>,
        },
        {
          id: 'electronicSomethingElse',
          description: 'Something else',
          content: <>{renderClaimFormContent()}</>,
        },
      ],
    },
    {
      id: 7,
      title: 'Does your policy have Excess Protection cover?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: <>{renderClaimFormSection()}</>,
        },
        {
          id: 'no',
          description: 'No',
          nextId: 8,
        },
      ],
    },
    {
      id: 8,
      title: 'What item would you like to claim for?',
      answers: [
        {
          id: 'accidentalLossJewellery',
          description: 'Jewellery',
          content: (
            <>
              {renderFirstParagraph()}
              <P>
                Please ensure you have your lost property number for your
                item(s) of jewellery, as you will need to have this to hand when
                you complete your claim.
              </P>
              <P>
                *If you need to claim for more than two items, please call the
                Claims Team on{' '}
                <strong>
                  <HomeClaimsFNOL />{' '}
                </strong>
                between the hours of {businessHoursDescriptive}.
              </P>
              <ThemedIconButton
                type="button"
                secondary
                onClick={() => renderDisclaimer('AL', '?itemType=jewellery')}
                iconRight={faChevronCircleRight}
              >
                Continue
              </ThemedIconButton>
            </>
          ),
          switchOff: !checkFeatureSwitch(
            'FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS_JEWELLERY',
            ['version1'],
          ),
        },
        {
          id: 'electronicSomethingElse',
          description: 'Something else',
          content: <>{renderClaimFormContent()}</>,
        },
      ],
    },
    {
      id: 9,
      title: 'Does your policy have Excess Protection cover?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: <>{renderClaimFormSection()}</>,
        },
        {
          id: 'no',
          description: 'No',
          content: (
            <>
              <P>
                Please submit your claim using our online claim journey. By
                completing your claim online, we may be able to validate and
                agree your claim today. {excessWarning}
              </P>
              <ThemedIconButton
                type="button"
                secondary
                onClick={() => renderDisclaimer('FF')}
                iconRight={faChevronCircleRight}
              >
                Continue
              </ThemedIconButton>
            </>
          ),
        },
      ],
    },
    {
      id: 10,
      title: 'Does your policy have Excess Protection cover?',
      answers: [
        {
          id: 'yes',
          description: 'Yes',
          content: <>{renderClaimFormSection()}</>,
        },
        {
          id: 'no',
          description: 'No',
          content: (
            <>
              <P>
                Please submit your claim using our online claim journey. By
                completing your claim online, we will be able to validate your
                claim and take the appropriate action needed immediately.{' '}
                {excessWarning}
              </P>
              <ThemedIconButton
                type="button"
                secondary
                onClick={() => renderDisclaimer('EW')}
                iconRight={faChevronCircleRight}
              >
                Continue
              </ThemedIconButton>
            </>
          ),
        },
      ],
    },
  ];
};
export default PROGRESSIVE_QUESTIONS_ECLAIMS;
