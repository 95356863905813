import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ThemedErrorMessage } from 'ageas-ui-components';
import FieldSpinner from '../../components/Forms/FieldSpinner/FieldSpinner';
import DropdownRadio from '../../components/Forms/DropdownRadio/DropdownRadio';
import { noDataMotor, callUsMotor } from '../../helpers/errorMessages';

// We now have the onchange item only firing when one of the parms changes
// SO can we simply always do a request in this instance, and use abort
// to cancel the existing if present?
// No, we need to check keys because of context.

export const OTHER_UNKNOWN_DRIVER = '000';

const dropdownProps = {
  containerProps: {
    medium: true,
  },
};

const DriverSelectField = ({
  dataContextObject,
  dataContextName,
  selectedOption,
  incidentDetailsLink,
  suppressErrorOnOther,
  ...props
}) => {
  const { dataState: policyDataContext } = useContext(dataContextObject);
  const driverListContext = { ...policyDataContext[dataContextName] };

  const buildOptionsList = () => {
    const optionsList = driverListContext.data.map(driver => {
      return {
        label: driver.name,
        value: driver.id.toString(),
      };
    });
    optionsList.push({
      label: 'Other/Unknown',
      value: OTHER_UNKNOWN_DRIVER,
    });
    return optionsList;
  };

  // Render
  if (driverListContext.status === 'loading') {
    return <FieldSpinner />;
  }

  if (driverListContext.status === 'nokey') {
    return (
      <ThemedErrorMessage hasIcon>
        Please fill in the missing details on the{' '}
        <Link to={incidentDetailsLink}>Incident Details</Link> screen
      </ThemedErrorMessage>
    );
  }

  if (driverListContext.status === 'error') {
    return <ThemedErrorMessage hasIcon>{noDataMotor()}</ThemedErrorMessage>;
  }

  if (driverListContext.status === 'loaded') {
    return (
      <>
        <DropdownRadio
          dropdownProps={dropdownProps}
          {...props}
          selectedOption={selectedOption}
          options={buildOptionsList()}
        />
        {selectedOption === OTHER_UNKNOWN_DRIVER && !suppressErrorOnOther && (
          <ThemedErrorMessage hasIcon>{callUsMotor()}</ThemedErrorMessage>
        )}
      </>
    );
  }
  return null;
};

DriverSelectField.defaultProps = {
  selectedOption: undefined,
  incidentDetailsLink: '/motorclaim/incidentdetails',
  suppressErrorOnOther: false,
};

DriverSelectField.propTypes = {
  selectedOption: PropTypes.string,
  incidentDetailsLink: PropTypes.string,
  suppressErrorOnOther: PropTypes.bool,
  dataContextObject: PropTypes.instanceOf(Object).isRequired,
  dataContextName: PropTypes.string.isRequired,
};

export default DriverSelectField;
