import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { ThemedErrorMessage } from 'ageas-ui-components';
import axiosHelper from '../../../helpers/axios';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import statementRequestPropType, {
  getQuestionsForType,
  isCausation,
} from '../hubStatementBuilderHelper';
import ModalForm from '../../Forms/ModalForm/ModalForm';
import Causation, {
  payloadMapperOut as payloadMapperOutCausation,
} from './Causation';
import useAxiosCancelToken from '../../../hooks/useAxiosCancelToken/useAxiosCancelToken';
import config from '../../../helpers/config';
import { consoleError } from '../../../helpers/consoleLog';
import { serverErrorCustomTelNoOOH } from '../../../helpers/errorMessages';

const payloadMappersOut = {
  causation: payloadMapperOutCausation,
};
const getPayloadMapperOut = type =>
  payloadMappersOut[type] ||
  /* istanbul ignore next */
  (x => x);

const Container = styled.div`
  margin-bottom: 24px;
`;

const axiosCall = (payload, cancelToken) => {
  return axiosHelper.post(
    config.client.updateMotorHubStatementAnswers_endpoint,
    payload,
    {
      cancelToken,
    },
  );
};

const Questions = ({
  claimReference,
  statementRequest,
  onSave,
  onCancel,
  errorTelephone,
}) => {
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createToken] = useAxiosCancelToken();

  const onSubmit = values => {
    const payloadValues = getPayloadMapperOut(statementRequest.type)(values);
    if (!isLoading) {
      setServerError(false);
      setIsLoading(true);
      const payload = {
        claimReference,
        sequenceNumber: statementRequest.sequenceNumber,
        [statementRequest.type]: payloadValues,
      };
      axiosCall(payload, createToken().token)
        .then(({ data }) => {
          onSave(values, data);
          // No need to unset isLoading, successful save will close modal
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            consoleError('axios error', error);
            setServerError(true);
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <ModalForm
        title="Statement of Truth"
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={getQuestionsForType(statementRequest)}
        focusLockProps={{ autoFocus: false }}
      >
        <Container>
          {isCausation(statementRequest) && (
            <Causation statementRequest={statementRequest} />
          )}

          {serverError && (
            <ThemedErrorMessage hasIcon>
              {serverErrorCustomTelNoOOH(
                'motor',
                errorTelephone,
                true,
                'motorClaims',
              )}
            </ThemedErrorMessage>
          )}
        </Container>
      </ModalForm>
    </>
  );
};

export default Questions;

Questions.propTypes = {
  claimReference: PropTypes.string.isRequired,
  statementRequest: statementRequestPropType.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorTelephone: PropTypes.string.isRequired,
};
