import { css } from 'styled-components';
import { sizes } from '../components/MediaQuery/MediaQuery';

// This controls at what screen width the claim ref wraps to a new line
export const DEFAULT_MAX_WIDTH = `${sizes.phone}px`;

export const onlyNarrowCSSDisplay = (
  maxWidth = DEFAULT_MAX_WIDTH,
  display = 'block',
) => css`
  display: none;
  @media (max-width: ${maxWidth}) {
    display: ${display};
  }
`;
export const onlyNotNarrowCSSDisplay = (
  maxWidth = DEFAULT_MAX_WIDTH,
  display = 'block',
) => css`
  display: ${display};
  @media (max-width: ${maxWidth}) {
    display: none;
  }
`;

// Return media queries to switch between display: values none and block (or
// whatever value the caller wants) at a specific screen width (default is phone
// breakpoiont)
export const narrowModeCSSDisplay = (
  maxWidth = DEFAULT_MAX_WIDTH,
  mode,
  display = 'block',
) => {
  if (mode === 'narrow') {
    return onlyNarrowCSSDisplay(maxWidth, display);
  }
  if (mode === 'notNarrow') {
    return onlyNotNarrowCSSDisplay(maxWidth, display);
  }
  return css`
    display: ${display};
  `;
};
