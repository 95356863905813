import React from 'react';
import EscapeOfWaterContent, { escapeOfWaterQuestion } from './EscapeOfWater';
import {
  damageInspectionContent,
  damageInspectionQuestion,
} from './DamageInspection';
import {
  eowClaimPeriodContent,
  eowClaimPeriodQuestion,
} from './EOWClaimPeriod';
import EOWDeclineClaimContent, {
  eowDeclineClaimQuestion,
} from './EOWDeclineClaim';
import CoverageEOWClaimContent, {
  coverageEOWClaimQuestion,
} from './CoverageEOWClaim';
import { excessPaymentContent, excessPaymentQuestion } from './ExcessPayment';
import {
  inhabitableDamageContent,
  inhabitableDamageQuestion,
} from './InhabitableDamage';
import MakeEscapeOfWaterClaimContent, {
  makeEscapeOfWaterClaimQuestion,
} from './MakeEscapeOfWaterClaim';
import {
  noCoverageWaterClaimContent,
  noCoverageWaterClaimQuestion,
} from './NoCoverageWaterClaim';
import PlumbingFixContent, { plumbingFixQuestion } from './PlumbingFix';
import StartedRepairsContent, {
  startedRepairsQuestion,
} from './StartedRepairs';
import {
  temporaryRepairContent,
  temporaryRepairQuestion,
} from './TemporaryRepairs';
import {
  traceAccessCoverContent,
  traceAccessCoverQuestion,
} from './TraceAccessCover';

const effectOfWaterDamageQuestions = [
  {
    title: escapeOfWaterQuestion,
    content: <EscapeOfWaterContent />,
  },
  {
    title: plumbingFixQuestion,
    content: <PlumbingFixContent />,
  },
  {
    title: makeEscapeOfWaterClaimQuestion,
    content: <MakeEscapeOfWaterClaimContent />,
  },
  {
    title: coverageEOWClaimQuestion,
    content: <CoverageEOWClaimContent />,
  },
  {
    title: damageInspectionQuestion,
    content: damageInspectionContent,
  },
  {
    title: inhabitableDamageQuestion,
    content: inhabitableDamageContent,
  },
  {
    title: traceAccessCoverQuestion,
    content: traceAccessCoverContent,
  },
  {
    title: eowClaimPeriodQuestion,
    content: eowClaimPeriodContent,
  },
  {
    title: excessPaymentQuestion,
    content: excessPaymentContent,
  },
  {
    title: noCoverageWaterClaimQuestion,
    content: noCoverageWaterClaimContent,
  },
  {
    title: eowDeclineClaimQuestion,
    content: <EOWDeclineClaimContent />,
  },
  {
    title: temporaryRepairQuestion,
    content: temporaryRepairContent,
  },
  {
    title: startedRepairsQuestion,
    content: <StartedRepairsContent />,
  },
];

export default effectOfWaterDamageQuestions;
