import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';

const validKeys = [];

/**
 * @description: Validate the field is not empty if the param passed is true.
 * @param boolParam pass true to validate the field is not empty pass false or any other value to ignore validation or pass null or undefined to fail validation
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds when boolParam is true and field is not empty or when boolParam contains any other value
 * @fails when boolParam is true and field is empty or if boolParam is null or undefined
 */
export const requiredIfTrue = (
  boolParam,
  keys = validKeys,
  message = messages.INVALID_REQUIRED_IF_TRUE,
) => {
  if (boolParam === null || boolParam === undefined) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.object()
        .forbidden()
        .error(() => message),
    );
  }
  if (boolParam === true) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.string()
        .required()
        .error(() => message),
    );
  }
  return Joi.object();
};

/**
 * @description: Validate the field is not empty if the param passed is true.
 * @param boolParam pass true to validate the field is not empty pass false or any other value to ignore validation or pass null or undefined to fail validation
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds when boolParam is true and field is not empty or when boolParam contains any other value
 * @fails when boolParam is true and field is empty or if boolParam is null or undefined
 */
export const requiredIf = (
  boolParam,
  keys = validKeys,
  message = messages.INVALID_REQUIRED_IF_TRUE,
) => {
  // this is a copy of requiredIfTrue.

  if (boolParam === null || boolParam === undefined) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.object()
        .forbidden()
        .error(() => message),
    );
  }
  if (boolParam === true) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.string()
        .required()
        .error(() => message),
    );
  }
  return Joi.object();
};

/**
 * @description: Validate the field is not empty if the param passed is FALSE.
 * @param boolParam pass false to validate the field is not empty pass true, null or undefined to ignore validation passing anything else will result in a fail
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds when boolParam is true, null or undefined or when param is false and field is not empty
 * @fails when boolParam is false and field is empty or when boolParam is something other than true, false, null or undefined
 */
export const requiredIfFalse = (
  boolParam,
  keys = validKeys,
  message = messages.INVALID_REQUIRED_IF_FALSE,
) => {
  if (boolParam === null || boolParam === undefined) {
    return Joi.object();
  }
  if (boolParam === false) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.string()
        .required()
        .error(() => message),
    );
  }
  if (boolParam === true) {
    return Joi.object();
  }
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.forbidden().error(() => message),
  );
};

/**
 * @description: Validate the field is not empty if the param passed is empty.
 * @example requiredIfEmpty(null,['myField']) will succeed only if myField contains a value. requiredIfEmpty('something', ['myField']) succeeds whether myField contains a value or not
 * @param param pass null, '' or undefined to make the validation required
 * @param keys Fields to validate
 * @param message Message to display when validation fails
 * @succeeds when param is not empty or when param is empty and field is not empty
 * @fails when param is empty and field is empty
 */
export const requiredIfEmpty = (
  param,
  keys = validKeys,
  message = messages.INVALID_REQUIRED_IF_EMPTY,
) => {
  if (param === null || param === '' || param === undefined) {
    return Joi.object().pattern(
      Joi.valid(keys),
      Joi.string()
        .required()
        .error(() => message),
    );
  }
  if (param !== null && param !== '') {
    return Joi.object();
  }
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.object()
      .forbidden()
      .error(() => message),
  );
};
