import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const stormClaimNotBeCoverTitle =
  'When would my storm claim not be covered?';
export const StormClaimNotBeCoverContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>
        In many declined claims it is not the weather event which caused the
        damage but an existing underlying problem, which the recent weather
        event highlighted.
      </FaqP>
      <FaqP>
        Wear and tear is a common reason as to why storm claims are declined.
        This is especially the case with flat roofs that are over 10 years old
        or old tiled roofs.
      </FaqP>
      <FaqP>
        It&apos;s difficult for a weather event to damage well-maintained
        properties, unless it is quite extreme. For more information, please
        refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Storm damage and claims',
              'What counts as storm damage?',
            ])
          }
        >
          &apos;What counts as storm damage?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

StormClaimNotBeCoverContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
StormClaimNotBeCoverContent.defaultProps = {
  nestingControl: undefined,
};
