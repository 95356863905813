import React from 'react';
import PropTypes from 'prop-types';

import ConfirmUpdate from './ConfirmUpdate';
import { FieldHeaderNoTop } from '../../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import InvolvedPartyAddress from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedPartyAddress';
import StandardDate from '../../../components/Forms/StandardDate/StandardDate';
import { FIELD_NAMES, VEHICLE_KEPT_LIST } from '../motorYourDetailsHelper';
import StandardDropdown from '../../../components/Forms/StandardDropdown/StandardDropdown';

const AddressConfirmUpdate = ({
  title,
  value,
  validateYesNo,
  validateField,
  validate,
  disabled,
  fieldProps = {},
}) => {
  return (
    <ConfirmUpdate
      title={title}
      value={value}
      yesNoName={FIELD_NAMES.PH_ADDRESS_IS_CORRECT}
      validate={validateYesNo || validate}
      disabled={disabled}
      valueInLegend={false}
      hint={"This needs to be the address of the main driver that is listed on the policy"}
    >
      <FieldHeaderNoTop>Please enter correct details</FieldHeaderNoTop>
      <InvolvedPartyAddress
        name={FIELD_NAMES.PH_ADDRESS}
        validate={validateField || validate}
        disabled={disabled}
        {...fieldProps}
      />
      <StandardDate
        name={FIELD_NAMES.PH_ADDRESS_CHANGED_DATE}
        id={FIELD_NAMES.PH_ADDRESS_CHANGED_DATE}
        label="Date your address changed"
        validate={validate}
      />
        <StandardDropdown
        name={FIELD_NAMES.VEHICLE_KEPT}
        label="Where is the vehicle being kept?"
        aria-label="Where is the vehicle being kept?"
        options={VEHICLE_KEPT_LIST}
        medium
        validate={validateField}
        hint="Please choose where the vehicle is normally kept when it is not in use."
      />
    </ConfirmUpdate>
  );
};

export default AddressConfirmUpdate;

AddressConfirmUpdate.propTypes = {
  title: PropTypes.string,
  value: ConfirmUpdate.propTypes.value,
  validateYesNo: PropTypes.func,
  validateField: PropTypes.func,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.instanceOf(Object),
};

AddressConfirmUpdate.defaultProps = {
  title: undefined,
  value: undefined,
  validateYesNo: undefined,
  validateField: undefined,
  validate: undefined,
  disabled: undefined,
  fieldProps: undefined,
  hint: undefined,
};
