import React from 'react';
import { Input, ErrorMessage } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import ThemedLabel from 'ageas-ui-components/lib/components/ThemedLabel/ThemedLabel';
import FieldStyled from '../Forms/FieldStyled/FieldStyled';

export const electricitySupplierDetailsSchema =
  electricitySupplierFieldName => {
    return {
      [electricitySupplierFieldName]: stringRequired(
        [electricitySupplierFieldName],
        'Please enter supplier details',
      ).concat(
        // Nonblank (at least one non-whitespace char)
        regularExpression(
          /\S/,
          [electricitySupplierFieldName],
          'Please enter supplier details',
        ),
      ),
    };
  };

const ElectricitySupplierDetails = props => {
  const { name = '', validate = () => {} } = props;
  const ELECTRICITY_SUPPLIER_FIELD_NAME = name;

  return (
    <FieldStyled name={ELECTRICITY_SUPPLIER_FIELD_NAME} validate={validate}>
      {({ input, meta }) => (
        <>
          <ThemedLabel fieldName={input.name}>
            Please tell us the name of your electricity supplier
          </ThemedLabel>
          <Input
            size="small"
            maxLength="100"
            id={input.name}
            aria-label="supplier details"
            {...input}
            invalidInput={{
              isInvalid: meta.error && meta.touched,
            }}
          />
          {meta.error && meta.touched && (
            <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
          )}
        </>
      )}
    </FieldStyled>
  );
};

ElectricitySupplierDetails.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
};
ElectricitySupplierDetails.defaultProps = {
  name: undefined,
  validate: undefined,
};

export default ElectricitySupplierDetails;
