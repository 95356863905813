import React from 'react';
import FaqP from '../../FaqP';
import StandardUL from '../../../StandardTags/UL';

export const title = 'How to make a claim';
export const content = (
  <>
    <FaqP marginBottom={false}>
      Instead of calling the claims team to report your claim, you can now
      report you claim online using our digital claims facility. You can report
      your claim online if you are claiming for:
    </FaqP>
    <StandardUL>
      <li>Storm damage to your home</li>
      <li>Perished freezer food</li>
      <li>
        Accidental damage to TV&apos;s, laptops, mobile phone, flooring, glazing
        and jewellery
      </li>
      <li>Accidental loss of jewellery</li>
    </StandardUL>
    <FaqP>
      This is a quick and easy way to make your claim. Depending upon what you
      are claiming for, you may be able to receive a settlement offer straight
      away.
    </FaqP>
    <FaqP>
      To check if you can submit your claim this way, please click the relevant
      claim type, under{' '}
      <strong>
        &apos;What type of claim can we help you with today?&apos;
      </strong>
    </FaqP>
    <FaqP>
      For support using our online claim journey, just click on our virtual
      assistant at the bottom right-hand side of your screen.
    </FaqP>
  </>
);

export const pair = { title, content };
