import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { ThemedErrorMessage } from 'ageas-ui-components';
import {
  SendButton,
  LoadMoreButton,
  MessagesContainer,
  InfoContainer,
  ErrorContainer,
  MoreButtonContainer,
  MessengerContainer,
  SendMessageContainer,
  MessageTextArea,
  SubHeader,
  SubSubText,
} from './HubMessenger.style';
import Message from './Message';
import config from '../../helpers/config';
import axiosHelper from '../../helpers/axios';
import { consoleError } from '../../helpers/consoleLog';
import allowedKeyboardCharactersRegex from '../../helpers/allowedKeyboardCharactersRegex';

// By default HubMessenger will fill its container.
// To constrain it to fixed width/height, you can simply wrap it in a div
// with fixed width/height
// To constrain to a height range, create a container div where HubMessenger
// is the only child, and has the following styling:
// const CustomContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   > * {
//     flex-grow: 1;
//     flex-shrink: 1;
//     min-height: 300px;
//     max-height: 600px;
//   }
// `;

const MAX_MESSAGE_LENGTH = 1000;

const HubMessenger = ({
  claimReference,
  autoRefreshOnInterval = false,
  className,
  autoRefreshInterval = 30000,
  autoRefreshOnActive = false,
  sendButtonProps = { primary: true },
  messagesContainerProps = { borderType: 'bar' },
  displayCharacterCount = false,
  getUrl = config.client.getHomeHubMessages_endpoint,
  sendUrl = config.client.createHomeHubMessage_endpoint,
  enableFirstFetch = true,
}) => {
  const [firstFetchDone, setFirstFetchDone] = useState(false);
  const lastYOffset = useRef(null);
  const nextScrollOffset = useRef(null);
  const messagesRef = useRef(null);
  const autoRefreshTimerId = useRef(null);
  const [triggerAutoRefreshOnActive, setTriggerAutoRefreshOnActive] =
    useState(false);

  const axiosCancelTokens = useRef({
    previous: null,
    new: null,
    send: null,
  });

  const messagesListTopAnchor = useRef(null);
  const messagesListPreviousTopAnchor = useRef(null);
  const messagesListUnreadAnchor = useRef(null);

  const [messagesListTopAnchorId, setMessagesListOlderAnchorId] =
    useState(undefined);
  const [messagesListPreviousTopAnchorId, setMessagesListCurrentAnchorId] =
    useState(undefined);
  const [messagesListFull, setMessagesListFull] = useState([]);
  const messagesListFullRef = useRef(messagesListFull);
  const updateMessagesListFull = newList => {
    setMessagesListFull(newList);
    messagesListFullRef.current = newList;
  };
  const getMessagesListFull = () => messagesListFullRef.current;

  const [newMessageState, setNewMessageState] = useState('');

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [topErrorStatus, setTopErrorStatus] = useState(undefined);
  const [messageEntryErrorStatus, setMessageEntryErrorStatus] =
    useState(undefined);
  const nextScroll = useRef('');

  const cancelAxios = tokenName => {
    if (!tokenName) {
      Object.keys(axiosCancelTokens.current).forEach(name => cancelAxios(name));
      return;
    }
    if (axiosCancelTokens.current[tokenName]?.cancel) {
      axiosCancelTokens.current[tokenName]?.cancel();
    }
  };

  const createAxiosToken = (tokenName, cancelExisting) => {
    if (cancelExisting) {
      cancelAxios(tokenName);
    }
    axiosCancelTokens.current[tokenName] = axios.CancelToken.source();
    return axiosCancelTokens.current[tokenName].token;
  };

  useEffect(() => {
    return () => {
      cancelAxios();
    };
  }, []);

  const fetchMessages = (tokenName, fromSequence, toSequence) => {
    const cancelToken = createAxiosToken(tokenName, true);

    let url = getUrl;

    const searchParams = new URLSearchParams();

    if (claimReference) {
      searchParams.append('claimReference', claimReference);
    }

    if (fromSequence) {
      searchParams.append('fromSequence', fromSequence);
    }
    if (toSequence) {
      searchParams.append('toSequence', toSequence);
    }
    url += `?${searchParams.toString()}`;
    return axiosHelper
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        return data;
      });
  };

  useEffect(() => {
    // First load, scroll messages to start position
    if (nextScroll.current === 'bottomStart' && messagesListFull.length) {
      // If unread messages, scroll to them
      if (messagesListUnreadAnchor.current) {
        messagesRef.current.scrollTo(
          0,
          messagesListUnreadAnchor.current.offsetTop,
        );
        // Else scroll to bottom
      } else {
        messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
      }
      // Update required offset for next load
      lastYOffset.current = messagesListPreviousTopAnchor.current.offsetTop;
      nextScroll.current = '';
    }

    // Sending or receiving message, scroll to bottom
    else if (nextScroll.current === 'bottomSent') {
      messagesRef.current.scrollTo({
        left: 0,
        top: messagesRef.current.scrollHeight,
        behavior: 'smooth',
      });
      nextScroll.current = '';
    }

    // Received message, scroll to bottom
    else if (nextScroll.current === 'bottomReceived') {
      messagesRef.current.scrollTo({
        left: 0,
        top: messagesRef.current.scrollHeight,
        behavior: 'smooth',
      });
      nextScroll.current = '';
    }

    // Loaded earlier messages, keep scroll position the same
    else if (
      nextScroll.current === 'currentStill' &&
      nextScrollOffset.current !== null
    ) {
      // Before messages list was updated, the scroll position as an offset to
      // top Anchor was recorded in nextScrollOffset.current
      // Now messages have updated, previous top Anchor is where top Anchor was
      // So compute the scroll position from previous top Anchor using the
      // saved offset
      const scrollTo =
        messagesListPreviousTopAnchor.current.offsetTop -
        nextScrollOffset.current;
      // And scroll to it
      messagesRef.current.scrollTo(0, scrollTo);
      // This ensures the div does not move when messages are inserted at the
      // top
      nextScroll.current = '';
    }
  }, [messagesListFull]);

  // Record scroll position of messages div as an offset from top scroll anchor
  const recordCurrentScrollOffset = () => {
    nextScrollOffset.current =
      messagesListTopAnchor?.current?.offsetTop === undefined
        ? 0
        : messagesListTopAnchor.current.offsetTop -
          messagesRef.current.scrollTop;
  };

  // Get more messages
  const fetchPreviousMessages = () => {
    setLoading(true);
    setTopErrorStatus(undefined);

    let toSequence = 0;
    const topSequence = getMessagesListFull().find(
      message => message.id > 0,
    )?.id;
    if (topSequence) {
      toSequence = topSequence - 1;
    }

    fetchMessages('previous', undefined, toSequence)
      .then(({ messages = [], more = false }) => {
        if (messages.length) {
          recordCurrentScrollOffset();
          messages.reverse(); // Expect messages newest first, store as oldest first

          // Sticky scroll if existing messages present
          if (getMessagesListFull().length) {
            nextScroll.current = 'currentStill';
          } else {
            nextScroll.current = 'bottomStart';
          }
          setMessagesListCurrentAnchorId(
            getMessagesListFull()[0]?.id || messages[0]?.id,
          );
          setMessagesListOlderAnchorId(messages[0]?.id);
          updateMessagesListFull([
            ...messages, // Expect messages newest first, store as oldest first
            ...getMessagesListFull(),
          ]);
        }

        if (!more || !messages.length) {
          setHasMore(false);
        }
        setLoading(false); // Must be last to prevent jumping of message pane
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          consoleError('Fetch older Messages error', e);
          setTopErrorStatus('Error retrieving messages');
          setLoading(false);
        }
      });
  };

  // On component mount, fetch initial messages
  useEffect(() => {
    if (enableFirstFetch && !firstFetchDone) {
      setLoading(true);
      setTopErrorStatus(undefined);
      fetchMessages('previous')
        .then(({ messages = [], more = false }) => {
          if (messages.length) {
            nextScroll.current = 'bottomStart';
            messages.reverse(); // Expect messages newest first, store as oldest first
            setMessagesListCurrentAnchorId(messages[0]?.id);
            setMessagesListOlderAnchorId(messages[0]?.id);
            updateMessagesListFull(messages);
          }
          if (!more || !messages.length) {
            setHasMore(false);
          }
          setLoading(false);
          setFirstFetchDone(true);
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            consoleError('Fetch Messages error', e);
            setTopErrorStatus('Error retrieving messages');
            setLoading(false);
          }
        });
    }
  }, [enableFirstFetch, firstFetchDone]);

  const onNewMessageChange = event => {
    setMessageEntryErrorStatus(undefined);
    setNewMessageState(event.target.value);
  };

  const validateNewMessage = message => {
    // Must be nonblank (at least one non-whitespace char)
    // istanbul ignore for safety, send button disabled in this scenario
    /* istanbul ignore next */
    if (!/\S/.test(message)) {
      setMessageEntryErrorStatus('Please enter a valid message');
      return false;
    }
    // Must consist only of valid characters
    if (!allowedKeyboardCharactersRegex.test(message)) {
      setMessageEntryErrorStatus(
        'Message contains invalid characters. Please only use standard keyboard characters such as letters, numbers and punctuation marks.',
      );
      return false;
    }
    return true;
  };

  const filterMessages = (messages, existingMessages = []) => {
    return messages.filter(
      message =>
        !existingMessages.some(
          existingMessage => existingMessage.id === message.id,
        ),
    );
  };

  const sendMessage = () => {
    if (!validateNewMessage(newMessageState)) {
      return;
    }
    setMessageEntryErrorStatus(undefined);
    const requestPayload = {
      claimReference,
      message: newMessageState.trim(),
    };

    setSending(true);
    const cancelToken = createAxiosToken('send');

    axiosHelper
      .post(sendUrl, requestPayload, {
        cancelToken,
      })
      .then(({ data }) => {
        // Construct final sent message by merging received into sent
        let sentMessage = {
          message: requestPayload.message,
          status: 'sent',
          sender: 'client',
          ...data,
        };
        const existingMessages = getMessagesListFull();
        setNewMessageState('');
        // Insert message if not already present (e.g. from a colliding auto
        // referesh)
        [sentMessage] = filterMessages([sentMessage], existingMessages);
        if (sentMessage) {
          updateMessagesListFull([...existingMessages, sentMessage]);
        }
        nextScroll.current = 'bottomSent';
        setSending(false);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          consoleError('Send Messages error', e);
          setMessageEntryErrorStatus(
            'Failed to send message, please try again',
          );
          setSending(false);
        }
      });
  };

  // This would be called e.g. by a socket process that notified of new messages
  // The socket would say "new messages available"
  // and that would trigger the react to request new messages
  // (as opposed to the socket payload containing the new messages)
  const getNewMessages = () => {
    let fromSequence = 0;
    // Find last real message (id > 0) and get its id
    for (let i = getMessagesListFull().length - 1; i >= 0; i -= 1) {
      if (getMessagesListFull()[i].id > 0) {
        fromSequence = getMessagesListFull()[i].id;
        break;
      }
    }
    if (fromSequence > 0) {
      fromSequence += 1;
    }

    fetchMessages('new', fromSequence)
      .then(({ messages = [] }) => {
        if (messages?.length) {
          const existingMessages = getMessagesListFull();
          // Filter out of new messages, any that exist in existingMessages
          // (in case of collision e.g. with a sent message)
          const filteredMessages = filterMessages(messages, existingMessages);
          filteredMessages.reverse();
          updateMessagesListFull([...existingMessages, ...filteredMessages]);
          nextScroll.current = 'bottomReceived';
        }
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          consoleError('failed to fetch new messages', e);
        }
      });
  };

  // Auto Refresh Interval control
  useEffect(() => {
    if (
      enableFirstFetch &&
      autoRefreshOnInterval &&
      !loading &&
      !topErrorStatus
    ) {
      autoRefreshTimerId.current = setInterval(
        getNewMessages,
        autoRefreshInterval,
      );
    }
    return () => {
      if (autoRefreshTimerId.current) {
        clearInterval(autoRefreshTimerId.current);
      }
    };
  }, [
    enableFirstFetch,
    claimReference,
    autoRefreshOnInterval,
    loading,
    topErrorStatus,
  ]);

  //------------------------------------------
  // Auto Refresh On Active

  // When prop autoRefreshOnActive becomes true, set triggerAutoRefreshOnActive
  // When triggerAutoRefreshOnActive becomes true, set it to false
  //   and, if conditions are right, do an immediate fetch new
  // This means an immediate fetch new should only happen when
  // autoRefreshOnActive is changed to true

  // Auto Refresh On Active Control
  useEffect(() => {
    if (triggerAutoRefreshOnActive) {
      setTriggerAutoRefreshOnActive(false);
      if (enableFirstFetch && !loading && !topErrorStatus) {
        getNewMessages();
      }
    }
  }, [
    enableFirstFetch,
    claimReference,
    triggerAutoRefreshOnActive,
    loading,
    topErrorStatus,
  ]);

  // Trigger autoRefreshOnActive
  useEffect(() => {
    if (autoRefreshOnActive) {
      setTriggerAutoRefreshOnActive(true);
    }
  }, [autoRefreshOnActive]);
  //------------------------------------------

  const renderAnchor = type => {
    if (type === 'top')
      return <div ref={messagesListTopAnchor} data-anchor-type="top" />;
    if (type === 'previous')
      return (
        <div ref={messagesListPreviousTopAnchor} data-anchor-type="previous" />
      );
    /* istanbul ignore next */
    return null;
  };

  //-----------------------------------------------
  // Render messages list
  const renderMessages = messagesList => {
    let lastDate = null;
    let foundUnread = false;

    const messageJSX = messagesList.map(messageData => {
      let newDate = false;
      let dateTemp;

      // Compute date for this line
      if (messageData.time) {
        dateTemp = DateTime.fromISO(messageData.time);
      }
      // Include the date above this line if different from previous date
      if (dateTemp) {
        const dateFormattedTemp = dateTemp.toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY,
        );
        if (dateFormattedTemp !== lastDate) {
          lastDate = dateFormattedTemp;
          newDate = true;
        }
      }

      // Is this the first unread message?
      const isFirstUnread = messageData.read === false && !foundUnread;
      if (isFirstUnread) {
        foundUnread = true;
      }

      // Do we need to render a positioning anchor above this message?
      const oldAnchor =
        messagesListTopAnchorId === messageData.id && renderAnchor('top');
      const currentAnchor =
        messagesListPreviousTopAnchorId === messageData.id &&
        renderAnchor('previous');

      return (
        <React.Fragment key={messageData.id}>
          {isFirstUnread && (
            <InfoContainer
              ref={messagesListUnreadAnchor}
              data-message-id={messageData.id}
            >
              New / Unactioned messages
            </InfoContainer>
          )}
          {newDate && <InfoContainer>{lastDate}</InfoContainer>}
          {oldAnchor}
          {currentAnchor}
          <Message messageData={messageData} />
        </React.Fragment>
      );
    });
    return messageJSX;
  };

  return (
    <MessengerContainer className={className}>
      <MessagesContainer {...messagesContainerProps} ref={messagesRef}>
        {hasMore && (
          <MoreButtonContainer>
            <LoadMoreButton
              type="button"
              onClick={() => {
                fetchPreviousMessages(false);
              }}
              disabled={!!loading}
              primary
              focusHighlight
            >
              {loading ? 'Loading...' : 'Load more'}
            </LoadMoreButton>
          </MoreButtonContainer>
        )}
        {topErrorStatus && <ErrorContainer>{topErrorStatus}</ErrorContainer>}

        {renderMessages(messagesListFull)}
      </MessagesContainer>

      <div>
        <SendMessageContainer>
          <SubHeader>Send a message</SubHeader>
          {displayCharacterCount && (
            <SubSubText>
              {newMessageState?.length || 0}/{MAX_MESSAGE_LENGTH} characters
            </SubSubText>
          )}

          <MessageTextArea
            fieldName="newMessage"
            value={newMessageState}
            onChange={onNewMessageChange}
            hasError={!!messageEntryErrorStatus}
            disabled={sending}
            aria-label="Enter your message"
            maxLength={MAX_MESSAGE_LENGTH}
          />
          <SendButton
            focusHighlight
            type="button"
            onClick={() => sendMessage()}
            disabled={
              loading ||
              sending ||
              topErrorStatus ||
              !newMessageState?.trim() ||
              newMessageState?.trim().length < 2
            }
            {...sendButtonProps}
          >
            {sending ? 'Sending...' : 'Send'}
          </SendButton>
          <SubSubText>
            Please be aware that while we aim to respond to you as soon as
            possible, it may take us up to 6 working days to do so.
          </SubSubText>
        </SendMessageContainer>
        {messageEntryErrorStatus && (
          <ThemedErrorMessage hasIcon>
            {messageEntryErrorStatus}
          </ThemedErrorMessage>
        )}
      </div>
    </MessengerContainer>
  );
};

export default HubMessenger;

HubMessenger.propTypes = {
  className: PropTypes.string,
  claimReference: PropTypes.string,
  autoRefreshOnInterval: PropTypes.bool,
  autoRefreshInterval: PropTypes.number,
  autoRefreshOnActive: PropTypes.bool,
  sendButtonProps: PropTypes.shape({}),
  messagesContainerProps: PropTypes.shape({}),
  displayCharacterCount: PropTypes.bool,
  getUrl: PropTypes.string,
  sendUrl: PropTypes.string,
  enableFirstFetch: PropTypes.bool,
};

HubMessenger.defaultProps = {
  className: undefined,
  claimReference: undefined,
  autoRefreshOnInterval: undefined,
  autoRefreshInterval: undefined,
  autoRefreshOnActive: undefined,
  sendButtonProps: undefined,
  messagesContainerProps: undefined,
  displayCharacterCount: undefined,
  getUrl: undefined,
  sendUrl: undefined,
  enableFirstFetch: undefined,
};
