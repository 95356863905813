import React from 'react';
import FaqP from '../../FaqP';
import StandardUL from '../../../StandardTags/UL';

export const expectAccidentalDamageTitle =
  'What should I expect when making a claim for accidental damage?';
export const expectAccidentalDamageContent = (
  <>
    <FaqP>
      Based on the information you provide, we will check your policy to confirm
      if you are covered for accidental damage to either your buildings or
      contents and let you know how we can help.
    </FaqP>
    <FaqP marginBottom={false}>
      When settling your claim we have the following options available to us,
      and we will decide which is most appropriate:
    </FaqP>
    <StandardUL>
      <li>Repair</li>
      <li>Replace</li>
      <li>Rebuild</li>
      <li>Payment</li>
    </StandardUL>
    <FaqP>
      We may offer to repair, replace or rebuild any damage through one of our
      approved suppliers. However, you may use your own supplier if you prefer,
      providing you agree this with us beforehand. Should you use your own
      supplier, any payment made would not normally exceed the discounted amount
      we would have paid our approved supplier. All repairs carried out by our
      approved suppliers are guaranteed for one year.
    </FaqP>
    <FaqP marginBottom={false}>
      If we decide it is not appropriate to repair, rebuild or replace your
      buildings and/or contents, we will send you a payment, less the excess,
      representing either:
    </FaqP>
    <StandardUL>
      <li>
        The amount by which the buildings and/or contents has gone down in value
        as a result of the claim
      </li>
      <li>
        The estimated cost to repair, replace or rebuild your buildings and/or
        contents, whichever is the lowest
      </li>
    </StandardUL>
    <FaqP>
      Terms and conditions apply. Please refer to your policy booklet for
      further information.
    </FaqP>
  </>
);
