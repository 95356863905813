import {
  policyExcessInfoTitle,
  policyExcessInfoContent,
} from './PolicyExcessInfo';

const policyExcessQuestions = [
  {
    title: policyExcessInfoTitle,
    content: policyExcessInfoContent,
  },
];

export default policyExcessQuestions;
