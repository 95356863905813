import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ConfirmUpdate from './ConfirmUpdate';
import FieldArrayDisplay from '../../../components/Forms/FieldArrayDisplay/FieldArrayDisplay';
import { motorClaimTypeToText } from '../../../helpers/motorClaimTypes';

const previousClaimDisplayMapper = props => {
  const { claimDate, claimType } = props;
  return `${claimDate} ${motorClaimTypeToText(claimType)}`.trim() || 'Unknown';
};

const PreviousClaimsConfirmUpdate = ({
  title,
  yesNoName,
  updateFieldName,
  value,
  validateYesNo,
  validateField,
  validate,
  disabled,
  fieldProps = {},
  confirmUpdateProps = {},
  Context,
}) => {
  const { onAdd, onEdit, hasBeenTouched } = useContext(Context);

  return (
    <ConfirmUpdate
      title={title}
      value={value}
      yesNoName={yesNoName}
      validate={validateYesNo || validate}
      disabled={disabled}
      renderChildrenOn={['yes']}
      allowUnknown
      hint="If the driver has had any claims during the past 3 years, please provide the claim details and claim date"
      {...confirmUpdateProps}
    >
      <FieldArrayDisplay
        name={updateFieldName}
        label="Please enter the details of the claim(s)"
        onAdd={onAdd}
        onEdit={onEdit}
        addLabel="Add claim"
        addButtonLabel="Add claim"
        editLabel="Edit claim"
        maxRows={10}
        displayMapper={previousClaimDisplayMapper}
        validate={validateField || validate}
        displayArrayErrors={hasBeenTouched ? 'always' : true}
        disabled={disabled}
        {...fieldProps}
      />
    </ConfirmUpdate>
  );
};

export default PreviousClaimsConfirmUpdate;

PreviousClaimsConfirmUpdate.propTypes = {
  title: PropTypes.string,
  yesNoName: PropTypes.string.isRequired,
  updateFieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  validateYesNo: PropTypes.func,
  validateField: PropTypes.func,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.instanceOf(Object),
  confirmUpdateProps: PropTypes.instanceOf(Object),
  Context: PropTypes.instanceOf(Object),
};
PreviousClaimsConfirmUpdate.defaultProps = {
  title: undefined,
  value: undefined,
  validateYesNo: undefined,
  validateField: undefined,
  validate: undefined,
  disabled: undefined,
  fieldProps: undefined,
  confirmUpdateProps: undefined,
  Context: undefined,
};
