import axios from 'axios';
import { useEffect, useRef } from 'react';

const useAxiosCancelToken = ({
  cancelOnUnmount = true,
  cancelOnCreate = true,
} = {}) => {
  const token = useRef(null);
  const props = useRef();
  props.current = { cancelOnUnmount, cancelOnCreate };

  /**
   * Cancel current token if exists
   */
  const cancelToken = () => {
    if (token?.current?.cancel) {
      token.current.cancel();
    }
  };

  /**
   * Cancel token on unmount, if enabled
   */
  useEffect(() => {
    return () => {
      if (props.current.cancelOnUnmount) {
        cancelToken();
      }
    };
  }, []);

  /**
   * Create and return ewn token, cancelling current token if enabled
   * @returns new token object
   */
  const createToken = () => {
    if (props.current.cancelOnCreate) {
      cancelToken();
    }
    token.current = axios.CancelToken.source();
    return token.current;
  };

  return [createToken, cancelToken];
};
export default useAxiosCancelToken;
