import { checkFeatureSwitch } from '../components/FeatureSwitch/FeatureSwitch';

const ITEM_TV = {
  value: 'tv',
  label: 'TV',
  icon: 'eclaims-tv',
};
const ITEM_MOBILE = {
  value: 'mobile',
  label: 'Mobile phone',
  icon: 'eclaims-phone',
};
const ITEM_LAPTOP = {
  value: 'laptop',
  label: 'Laptop',
  icon: 'eclaims-laptop',
};
const ITEM_FIXED_GLAZING = {
  value: 'fixedGlazing',
  label: 'Fixed glazing',
};
const ITEM_CARPETS = {
  value: 'carpets',
  label: 'Carpets',
  icon: 'eclaims-carpet',
};
const ITEM_JEWELLERY = {
  value: 'jewellery',
  label: 'Jewellery',
};

export const ITEM_TYPE_LIST = [
  {
    ...ITEM_TV,
    switchOff: !checkFeatureSwitch('FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_TV', [
      'version1',
    ]),
  },
  {
    ...ITEM_MOBILE,
    switchOff: !checkFeatureSwitch(
      'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_MOBILE',
      ['version1'],
    ),
  },
  {
    ...ITEM_LAPTOP,
    switchOff: !checkFeatureSwitch(
      'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_LAPTOP',
      ['version1'],
    ),
  },
  {
    ...ITEM_FIXED_GLAZING,
    switchOff: !checkFeatureSwitch(
      'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_FIXED_GLAZING',
      ['version1'],
    ),
  },
  {
    ...ITEM_CARPETS,
    switchOff: !checkFeatureSwitch(
      'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_CARPETS',
      ['version1'],
    ),
  },
  {
    ...ITEM_JEWELLERY,
    switchOff: !checkFeatureSwitch(
      'FEATURE_HOME_CLAIM_ACCIDENTAL_DAMAGE_JEWELLERY',
      ['version1'],
    ),
  },
];

export const ITEM_TYPE_LIST_AL = [
  {
    ...ITEM_JEWELLERY,
    switchOff: !checkFeatureSwitch(
      'FEATURE_HOME_CLAIM_ACCIDENTAL_LOSS_JEWELLERY',
      ['version1'],
    ),
  },
];

export const ITEM_TYPE_LIST_TF = [
  {
    ...ITEM_JEWELLERY,
    switchOff: !checkFeatureSwitch('FEATURE_HOME_CLAIM_THEFT_JEWELLERY', [
      'version1',
    ]),
  },
];

export const ITEM_TYPE_LISTS = {
  AD: ITEM_TYPE_LIST,
  AL: ITEM_TYPE_LIST_AL,
  TF: ITEM_TYPE_LIST_TF,
};

export const isValidItemType = (itemType, itemTypeList = ITEM_TYPE_LIST) => {
  return !!itemTypeList.find(
    item => item.value === itemType && !item.switchOff,
  );
};

export const getItemByType = (itemType, itemTypeList = ITEM_TYPE_LIST) => {
  return itemTypeList.find(item => item.value === itemType);
};

export const isValidItemTypeForPeril = (itemType, perilCode) => {
  return (
    ITEM_TYPE_LISTS[perilCode] &&
    isValidItemType(itemType, ITEM_TYPE_LISTS[perilCode])
  );
};
