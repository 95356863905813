import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemedButton, ThemedErrorMessage } from 'ageas-ui-components';
import { FieldArray } from 'react-final-form-arrays';
import Spacer from '../FieldSpacer/FieldSpacer';
import media from '../../MediaQuery/MediaQuery';

// For documentation see:
// /docs/components/Forms/FieldArrayDisplay/FieldArrayDisplay.md

const ArrayContainer = styled.fieldset`
  border: 0px;
`;
const Legend = styled.legend`
  margin-bottom: 16px;
`;

const ButtonSet = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  padding-left: 5px;
  ${media.phone`
    margin-bottom: -1em;
  `}
`;

const DisplayValueContainer = styled.div`
  min-width: 100px;
  overflow-wrap: anywhere;
`;

const ArrayRecord = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid lightgrey;
  &:first-child {
    margin-top: 0.5em;
  }
  &:last-child {
    border-bottom: 1px solid lightgrey;
  }
`;

const FieldArrayDisplay = ({
  name,
  label,
  ariaLabel,
  onAdd,
  onEdit,
  addLabel,
  addButtonLabel = 'Add details',
  editLabel,
  showAddButton,
  enableAddButton,
  defaultRows,
  maxRows,
  displayMapper,
  validate,
  children,
  displayArrayErrors,
}) => {
  return (
    <Spacer>
      <ArrayContainer aria-label={ariaLabel}>
        {label && <Legend>{label}</Legend>}

        <FieldArray name={name} validate={validate}>
          {({ fields, meta }) => {
            const { touched, error } = meta;
            const totalRows = Math.max(defaultRows, fields.length);
            const atMaxRows = maxRows !== undefined && totalRows >= maxRows;
            return (
              <>
                {showAddButton && !atMaxRows && (
                  <ThemedButton
                    small
                    secondary
                    onClick={() => onAdd(fields.push, addLabel)}
                    disabled={!enableAddButton}
                  >
                    {addButtonLabel}
                  </ThemedButton>
                )}
                <div>
                  {fields.map((fieldName, index) => {
                    const fieldValues = fields.value[index];
                    return (
                      <ArrayRecord key={fieldName}>
                        <DisplayValueContainer>
                          {displayMapper(
                            fieldValues,
                            totalRows === 1 ? undefined : index + 1,
                          )}
                        </DisplayValueContainer>
                        <ButtonSet>
                          <ButtonContainer>
                            <ThemedButton
                              small
                              primary
                              inverted
                              onClick={() =>
                                onEdit(
                                  index,
                                  fieldValues,
                                  fields.update,
                                  editLabel,
                                )
                              }
                            >
                              Edit
                            </ThemedButton>
                          </ButtonContainer>
                          <ButtonContainer>
                            <ThemedButton
                              small
                              primary
                              inverted
                              onClick={() => fields.remove(index)}
                            >
                              Clear
                            </ThemedButton>
                          </ButtonContainer>
                        </ButtonSet>
                      </ArrayRecord>
                    );
                  })}

                  {fields.length < defaultRows &&
                    [...Array(defaultRows - fields.length).keys()].map(
                      (_element, index) => {
                        const rowName = `defaultRow-${index}`;
                        return (
                          <ArrayRecord key={rowName}>
                            <DisplayValueContainer>
                              <em>No details</em>
                            </DisplayValueContainer>
                            <ButtonSet>
                              <ButtonContainer>
                                <ThemedButton
                                  small
                                  secondary
                                  onClick={() => onAdd(fields.push, addLabel)}
                                >
                                  {addButtonLabel}
                                </ThemedButton>
                              </ButtonContainer>
                            </ButtonSet>
                          </ArrayRecord>
                        );
                      },
                    )}
                </div>
                {displayArrayErrors &&
                  error &&
                  (touched || displayArrayErrors === 'always') && (
                    <ThemedErrorMessage hasIcon>{error}</ThemedErrorMessage>
                  )}
              </>
            );
          }}
        </FieldArray>
        {children}
      </ArrayContainer>
    </Spacer>
  );
};

export default FieldArrayDisplay;

FieldArrayDisplay.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ariaLabel: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  addLabel: PropTypes.string.isRequired,
  editLabel: PropTypes.string.isRequired,
  defaultRows: PropTypes.number,
  maxRows: PropTypes.number,
  showAddButton: PropTypes.bool,
  addButtonLabel: PropTypes.string,
  enableAddButton: PropTypes.bool,
  displayMapper: PropTypes.func,
  validate: PropTypes.func,
  children: PropTypes.node,
  displayArrayErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FieldArrayDisplay.defaultProps = {
  label: '',
  ariaLabel: undefined,
  onAdd: () => {},
  onEdit: () => {},
  defaultRows: 0,
  maxRows: undefined,
  showAddButton: true,
  addButtonLabel: undefined,
  enableAddButton: true,
  /* istanbul ignore next */
  displayMapper: () => '',
  validate: () => undefined,
  children: undefined,
  displayArrayErrors: undefined,
};
