import PropTypes from 'prop-types';

export const DAMAGE_LEVELS = {
  NONE: {
    name: 'none',
    label: 'No damage',
    secondaryLabel: 'Area of the vehicle has no visible damage.',
  },
  SCRATCH: {
    name: 'scratch',
    label: 'Slight scratching',
    secondaryLabel:
      'The paintwork has been scratched but there is no damage to the metal/plastic area.',
  },
  DENT: {
    name: 'dent',
    label: 'Slight denting',
    secondaryLabel:
      'The paintwork and area has been dented in one or two areas (Each no longer than 12 inches/30cm).',
  },
  MEDIUM: {
    name: 'medium',
    label: 'Medium damage',
    secondaryLabel:
      'There is significant damage to over 50% of the area. It remains intact and on the vehicle, not torn.',
  },
  HEAVY: {
    name: 'heavy',
    label: 'Heavy damage',
    secondaryLabel:
      'There is heavy damage to the area. It is torn, hanging off and the integrity is compromised.',
  },
};

export const getDamageLevelByName = name =>
  Object.values(DAMAGE_LEVELS)?.filter(x => x.name === name)?.[0];

export const damageLevelPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
});

const DAMAGE_AREAS = {
  REAR_DRIVER: {
    name: 'offsideRear',
    label: 'Offside rear/Driver side rear',
    gridRow: '1',
    gridColumn: '1',
  },
  REAR: { label: 'Rear', name: 'rear', gridRow: '1', gridColumn: '2' },
  REAR_PASSENGER: {
    name: 'nearsideRear',
    label: 'Nearside rear/Passenger side rear',
    gridRow: '1',
    gridColumn: '3',
  },
  SIDE_DRIVER: {
    label: 'Offside/Driver side',
    name: 'offside',
    gridRow: '2',
    gridColumn: '1',
  },
  ROOF: { label: 'Roof', name: 'roof', gridRow: '2', gridColumn: '2' },
  SIDE_PASSENGER: {
    name: 'nearside',
    label: 'Nearside/Passenger side',
    gridRow: '2',
    gridColumn: '3',
  },
  FRONT_DRIVER: {
    name: 'offsideFront',
    label: 'Offside front/Driver side front',
    gridRow: '3',
    gridColumn: '1',
  },
  FRONT: { label: 'Front', name: 'front', gridRow: '3', gridColumn: '2' },
  FRONT_PASSENGER: {
    name: 'nearsideFront',
    label: 'Nearside front/Passenger side front',
    gridRow: '3',
    gridColumn: '3',
  },
  UNDERSIDE: {
    name: 'underside',
    label: 'Underneath the vehicle',
  },
};

export const damageAreaPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
});

const valuesAreaType = PropTypes.oneOf(
  Object.values(DAMAGE_LEVELS)
    .map(x => x.name)
    .filter(x => x !== DAMAGE_LEVELS.NONE.name),
);

export const damageAreaValuesPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.oneOf([
      'offsideRear',
      'rear',
      'nearsideRear',
      'offside',
      'roof',
      'nearside',
      'offsideFront',
      'front',
      'nearsideFront',
    ]),
  ),

  PropTypes.shape({
    offsideRear: valuesAreaType,
    rear: valuesAreaType,
    nearsideRear: valuesAreaType,
    offside: valuesAreaType,
    roof: valuesAreaType,
    nearside: valuesAreaType,
    offsideFront: valuesAreaType,
    front: valuesAreaType,
    nearsideFront: valuesAreaType,
  }),
]);

export default DAMAGE_AREAS;
