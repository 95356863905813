import React from 'react';

export const localStorageHubPropertyName = 'hubClaimState';

export const hubDataContext = {
  claimDetails: 'claimDetails',
  notifications: 'notifications',
};

export const HubContext = React.createContext({});

export const homeHubContextData = {
  localStorageHubPropertyName,
  hubDataContext,
  HubContext,
};

export const localStorageHubPropertyNameMotor = 'motorHubClaimState';

export const hubDataContextMotor = {
  claimDetails: 'claimDetails',
  notifications: 'notifications',
};

export const HubContextMotor = React.createContext({});

export const motorHubContextData = {
  localStorageHubPropertyName: localStorageHubPropertyNameMotor,
  hubDataContext: hubDataContextMotor,
  HubContext: HubContextMotor,
};

export const localStorageHubPropertyNameMotorTp = 'motorTpHubClaimState';

export const hubDataContextMotorTp = {
  claimDetails: 'claimDetails',
  notifications: 'notifications',
};

export const HubContextMotorTp = React.createContext({});

export const motorTpHubContextData = {
  localStorageHubPropertyName: localStorageHubPropertyNameMotorTp,
  hubDataContext: hubDataContextMotorTp,
  HubContext: HubContextMotorTp,
};
