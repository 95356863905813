import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import {
  FIELD_NAMES,
  VEHICLE_USAGE_LIST,
  getFilteredMainUserList,
} from '../motorYourDetailsHelper';
import { FieldHeaderSpaced } from '../../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import ConfirmUpdate from '../ConfirmUpdate/ConfirmUpdate';
import StandardTextarea from '../../../components/Forms/StandardTextarea/StandardTextarea';
import StandardDate from '../../../components/Forms/StandardDate/StandardDate';
import OwnerKeeperConfirmDetails from './OwnerKeeperConfirmDetails';
import StandardRadio from '../../../components/Forms/StandardRadio/StandardRadio';
import InvolvedParty from '../../../components/Forms/ClaimEntryFields/InvolvedParty/InvolvedParty';
import StandardDropdown from '../../../components/Forms/StandardDropdown/StandardDropdown';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';

const getDriverName = (policyData, id) =>
  policyData?.drivers?.find(driver => driver.id === id)?.name || 'Unknown';

const isVehicleModified = policyData =>
  !!policyData?.vehicles[0]?.modifications?.length;

const buildVehicleModifications = policyData =>
  policyData?.vehicles[0]?.modifications?.map(
    modification => modification.label,
  );

const isSimplifiedProduct = policyData =>
  policyData?.historyDetails?.productGroup === 'S';

/**
 * Component for displaying data relating to vehicle, for confirmation/
 * update
 */
const VehicleConfirmDetails = ({ policyData, validateFields }) => {
  const { values } = useFormState({ subscription: { values: true } });

  const ownerIsCorrect = values[FIELD_NAMES.VEHICLE_OWNER_IS_CORRECT];
  const keeperIsCorrect = values[FIELD_NAMES.VEHICLE_KEEPER_IS_CORRECT];
  const mainUserNeeded = [ownerIsCorrect, keeperIsCorrect].includes('no');

  const mappedPolicyData = useMemo(
    () => ({
      modifications: buildVehicleModifications(policyData),
      ownerName: getDriverName(
        policyData,
        policyData?.vehicles[0]?.ownerDriverSequence,
      ),
      keeperName: getDriverName(
        policyData,
        policyData?.vehicles[0]?.keeperDriverSequence,
      ),
    }),
    [policyData],
  );

  const filteredMainUserList = useMemo(
    () => getFilteredMainUserList(ownerIsCorrect, keeperIsCorrect),
    [ownerIsCorrect, keeperIsCorrect],
  );

  if (!policyData) {
    return null;
  }

  return (
    <>
      <FieldHeaderSpaced>
        These are the details we have about your vehicle, are they correct?
      </FieldHeaderSpaced>
      <OwnerKeeperConfirmDetails
        confirmTitle="Owner of the vehicle: "
        driverName={mappedPolicyData.ownerName}
        validateFields={validateFields}
        subjectType="O"
        simplifiedProduct={isSimplifiedProduct(policyData)}
        hint="The owner of the car is the person who purchased the vehicle and registered it with the DVLA"
      />
      <OwnerKeeperConfirmDetails
        confirmTitle="Registered keeper of the car: "
        driverName={mappedPolicyData.keeperName}
        validateFields={validateFields}
        subjectType="K"
        simplifiedProduct={isSimplifiedProduct(policyData)}
        hint="The registered keeper of the car is the person who drives the vehicle and generally has the vehicle in their possession"
      />

      {mainUserNeeded && (
        <>
          <StandardRadio
            name={FIELD_NAMES.MAIN_USER}
            title="Who is the main user of the vehicle?"
            options={filteredMainUserList}
            autoClearOnInvalid
            medium
            vertical
            validate={validateFields}
          />
          {values[FIELD_NAMES.MAIN_USER] === 'other' && (
            <InvolvedParty
              name={FIELD_NAMES.MAIN_USER_NAME}
              header="Please confirm the name of the main user"
              hideAddress
              hideContact
              validate={validateFields}
            />
          )}
        </>
      )}

      <ConfirmUpdate
        title={
          isVehicleModified(policyData)
            ? 'Does your vehicle have any modifications other than those listed below?'
            : 'Has your vehicle been modified in any way?'
        }
        value={
          isVehicleModified(policyData) ? mappedPolicyData.modifications : null
        }
        yesNoName={FIELD_NAMES.VEHICLE_MODIFICATIONS_TO_ADD}
        validate={validateFields}
        hasButtonsTitle={false}
        valueInLegend={false}
        renderChildrenOn={['yes']}
        hint="Vehicle modification is any change that has been made to your vehicle that is not part of the original manufacturer specification"
      >
        <StandardTextarea
          name={FIELD_NAMES.VEHICLE_MODIFICATIONS_DETAILS}
          label="Please provide a description of the modification(s)"
          aria-label="Please provide a description of the modification(s)"
          maxLength={500}
          validate={validateFields}
          blockProps={{ marginTop: '0' }}
          resize="none"
        />
        <StandardDate
          name={FIELD_NAMES.VEHICLE_MODIFICATIONS_DATE}
          id={FIELD_NAMES.VEHICLE_MODIFICATIONS_DATE}
          label="Date of modification"
          validate={validateFields}
        />
      </ConfirmUpdate>
      <StandardDropdown
        name={FIELD_NAMES.VEHICLE_USAGE}
        label="What was the main reason your vehicle was being used for at the time of the incident?"
        aria-label="What was the main reason your vehicle was being used for at the time of the incident?"
        options={VEHICLE_USAGE_LIST}
        medium
        validate={validateFields}
        hint="If the driver was using the vehicle for multiple reasons at the time of the incident, please select the reason that took the longest amount of time"
      />
      {values[FIELD_NAMES.VEHICLE_USAGE] === 'other' && (
        <StandardInput
          name={FIELD_NAMES.VEHICLE_USAGE_DETAILS}
          label="Please provide brief details"
          maxLength="60"
          medium
          validate={validateFields}
        />
      )}
    </>
  );
};

VehicleConfirmDetails.propTypes = {
  policyData: PropTypes.instanceOf(Object),
  validateFields: PropTypes.func,
};

VehicleConfirmDetails.defaultProps = {
  policyData: undefined,
  validateFields: undefined,
};

export default VehicleConfirmDetails;
