const STATUSES = {
  NEW: { value: 'new', label: 'New' },
  OPENED: { value: 'opened', label: 'Open' },
  PROCESSING: { value: 'processing', label: 'Processing' },
  COMPLETE: { value: 'complete', label: 'Complete' },
};

export const EDITABLE_STATUSES = [STATUSES.NEW, STATUSES.OPENED];

export const getStatusByValue = value =>
  Object.values(STATUSES).find(entry => entry.value === value);

export default STATUSES;
