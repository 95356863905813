import { messages } from '../constants/ErrorMessages';

export function subMotorClaim(Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      subClaimType: messages.SUB_MOTOR_CLAIMS_TYPE,
      theftClaim: messages.CONTTHEFT_CLAIMS_TYPE,
      fireClaim: messages.FIRE_CLAIMS_TYPE,
      multipleVehicleClaim: messages.MULTVEH_CLAIMS_TYPE,
      singleVehicleClaim: messages.SINGVEH_CLAIMS_TYPE,
      stormClaim: messages.STORM_CLAIMS_TYPE,
      vehicleRecoveredClaim: messages.THEFTCTGRY_CLAIMS_TYPE,
      vehicleNotrecoveredClaim: messages.TOTALTHFTL_CLAIMS_TYPE,
      vandalClaim: messages.VANDAL_CLAIMS_TYPE,
      windscreenClaim: messages.WINDSCREEN_CLAIMS_TYPE,
    },
    rules: [
      {
        name: 'subMotorClaim',
        params: {
          motorClaimType: Joi.alternatives([
            Joi.string().required(),
            Joi.func().ref(),
            Joi.empty(),
          ]),
        },
        validate(params, value, state, options) {
          const motorClaimValue = state.parent[params.motorClaimType];
          if (value === null || value === undefined || value === '') {
            switch (motorClaimValue) {
              case 'CONTTHEFT':
                return this.createError(
                  'string.theftClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'FIRE':
                return this.createError(
                  'string.fireClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'MULTVEH':
                return this.createError(
                  'string.multipleVehicleClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'SINGVEH':
                return this.createError(
                  'string.singleVehicleClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'STORM':
                return this.createError(
                  'string.stormClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'THEFTCTGRY':
                return this.createError(
                  'string.vehicleRecoveredClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'TOTALTHFTL':
                return this.createError(
                  'string.vehicleNotrecoveredClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'VANDAL':
                return this.createError(
                  'string.vandalClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );

              case 'WINDSCREEN':
                return this.createError(
                  'string.windscreenClaim',
                  { value: motorClaimValue },
                  state,
                  options,
                );
              default:
                return this.createError(
                  'string.subClaimType',
                  { value: motorClaimValue },
                  state,
                  options,
                );
            }
          }
          return value;
        },
      },
    ],
  };
}

export default subMotorClaim;
