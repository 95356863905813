import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { ThemedModal, ThemedButton } from 'ageas-ui-components';

export const ModalButtonsContainer = styled.div`
  text-align: right;
  width: 100%;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const mapText = (text, index) => {
  if (!text) {
    if (index === 0) {
      return 'OK';
    }
    if (index === 1) {
      return 'Cancel';
    }
  }
  return text;
};

const renderButtons = (buttons = []) =>
  buttons.map(({ text, ...props }, index) => {
    const label = mapText(text, index);
    return (
      <ThemedButton primary type="button" key={label} {...props}>
        {label}
      </ThemedButton>
    );
  });

const StandardPopup = ({
  modalProps = {},
  buttons = [],
  children,
  focusLockProps = {},
}) => {
  return (
    <FocusLock {...focusLockProps}>
      <ThemedModal
        minHeight="100px"
        primary
        contentLeft
        modalContentPadding="25px 25px"
        {...modalProps}
      >
        {children}
        {buttons.length > 0 && (
          <ModalButtonsContainer>
            {renderButtons(buttons)}
          </ModalButtonsContainer>
        )}
      </ThemedModal>
    </FocusLock>
  );
};

export default StandardPopup;

StandardPopup.propTypes = {
  modalProps: PropTypes.shape({}),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  focusLockProps: PropTypes.instanceOf(Object),
};

StandardPopup.defaultProps = {
  modalProps: undefined,
  buttons: undefined,
  children: undefined,
  focusLockProps: undefined,
};
