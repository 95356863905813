/**
 * Map contactDetails context/pageForms data to JSON Blob contactDetails
 * section
 * @param {*} data contactDetails in MotorClaimContextNew, claimEntryData
 * format
 * @returns data in JSON Blob contactDetails section format, or undefined if
 * section not present
 */
const mapLocalToContactDetailsSection = ({ contactDetails } = {}) => {
  if (!contactDetails) {
    return undefined;
  }
  const { mobileTelephone, emailAddress } = contactDetails;
  // Return mapped object
  return {
    mobileTelephone: mobileTelephone?.trim(),
    email: emailAddress?.trim(),
  };
};

export default mapLocalToContactDetailsSection;
