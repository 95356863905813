import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ageas-ui-components';
import {
  SubText,
  SummaryContainer,
  ToggleIcon,
  BorderLine,
  IconContainer,
  SubContainer,
  HeaderContainer,
  LabelText,
  ToggleIconButton,
} from '../StandardPageWrapper/StandardPageWrapperStyle';
import arrowIcon from '../../assets/icons/weatherIcons/ui_icon_arrow_open.svg';
import { reverseDate, formatTime } from '../../helpers/dateTimeConverter';
import formatAmount from '../../helpers/formatAmount';

export const HubPayment = ({ payment, isOpen = false, isTP = false }) => {
  const [open, setOpen] = useState(isOpen);
  return (
    <>
      <SummaryContainer onClick={() => setOpen(!open)}>
        <Icon
          className="icon-warning"
          brandIconSet="ageas"
          icon="tick-roundel"
          color="green"
          size={34}
        />
        <HeaderContainer>
          <SubText>
            Payment made at {formatTime(payment.paymentDate)} on{' '}
            {reverseDate(payment.paymentDate)}
          </SubText>
        </HeaderContainer>
        <IconContainer>
          <ToggleIconButton
            type="button"
            onClick={() => setOpen(!open)}
            aria-label={`${open ? 'Close' : 'Open'} payment`}
          >
            <ToggleIcon
              open={open}
              src={arrowIcon}
              alt="chevron"
              data-testid="chevron-icon"
            />
          </ToggleIconButton>
        </IconContainer>
      </SummaryContainer>
      {open && (
        <SubContainer>
          {!isTP && (
            <SubText>
              <LabelText>Agreed claim value:</LabelText>£
              {formatAmount(payment.amount + payment.excess)}
            </SubText>
          )}
          {!isTP && (
            <SubText>
              <LabelText>Excess:</LabelText>£{formatAmount(payment.excess)}
            </SubText>
          )}
          <SubText>
            <LabelText>Payment made:</LabelText>£{formatAmount(payment.amount)}
          </SubText>
        </SubContainer>
      )}
      <BorderLine />
    </>
  );
};

const HubPayments = ({ payments, isTP }) =>
  payments?.map((payment, index) => (
    <HubPayment
      key={payment.sequence}
      payment={payment}
      isOpen={index === 0}
      isTP={isTP}
    />
  ));

export const HubPaymentsFull = ({ payments, isLoading, isTP }) => {
  if (isLoading) {
    return <></>;
  }
  if (!payments?.length) {
    return <SubText>No payments have been made.</SubText>;
  }

  return <HubPayments payments={payments} isTP={isTP} />;
};

const paymentPropType = PropTypes.shape({
  sequence: PropTypes.number,
  paymentDate: PropTypes.string,
  amount: PropTypes.number,
  excess: PropTypes.number,
});

HubPaymentsFull.propTypes = {
  payments: PropTypes.arrayOf(paymentPropType),
  isLoading: PropTypes.bool,
  isTP: PropTypes.bool,
};
HubPaymentsFull.defaultProps = {
  payments: undefined,
  isLoading: undefined,
  isTP: undefined,
};

HubPayments.propTypes = {
  payments: PropTypes.arrayOf(paymentPropType),
  isTP: PropTypes.bool,
};
HubPayments.defaultProps = {
  payments: undefined,
  isTP: undefined,
};

HubPayment.propTypes = {
  payment: paymentPropType,
  isOpen: PropTypes.bool,
  isTP: PropTypes.bool,
};
HubPayment.defaultProps = {
  payment: undefined,
  isOpen: undefined,
  isTP: undefined,
};

export default HubPayments;
