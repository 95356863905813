// The order here is the dropdown order
export const documentUploadTypesList = [
  {
    code: 'DAMAGE_PHOTOS',
    description: 'Photos of damage',
    category: 'damagePhotos',
    notificationCode: 'HP',
    correspondenceType: 'UP',
    hubUploadable: true,
  },
  {
    code: 'ESTIMATE',
    description: 'Estimate',
    bulletDescription: 'Estimates',
    category: 'estimate',
    notificationCode: 'HE',
    correspondenceType: 'UR',
    hubUploadable: true,
  },
  {
    code: 'RECEIPT_INVOICE',
    description: 'Receipt or invoice',
    bulletDescription: 'Receipts',
    sentenceDescription: 'Receipt',
    category: 'receiptInvoice',
    notificationCode: 'HR',
    correspondenceType: 'UR',
    hubUploadable: true,
  },
  {
    code: 'RECEIPT_ESTIMATE_COMBINED',
    description: 'Receipt/estimate/invoice',
    notificationCode: null,
    correspondenceType: 'UR',
  },
  {
    code: 'PROBATE',
    description: 'Grant of Probate',
    category: 'probate',
    notificationCode: 'HG',
    correspondenceType: 'UG',
    hubUploadable: true,
  },
  {
    code: 'WILL',
    description: "Late customer's will",
    category: 'will',
    notificationCode: 'HW',
    correspondenceType: 'UW',
    hubUploadable: true,
  },
  {
    code: 'LOSS_ASSESSOR',
    description: 'Loss Assessor mandate',
    category: 'lossAssessor',
    notificationCode: 'HL',
    correspondenceType: 'UL',
    hubUploadable: true,
  },
  {
    code: 'TENANCY_AGREEMENT',
    description: 'Tenancy agreement',
    category: 'tenancy',
    notificationCode: 'HT',
    correspondenceType: 'UT',
    hubUploadable: true,
  },
];

const buildDocumentUploadTypesObject = (types = documentUploadTypesList) => {
  const out = {};
  types.forEach(type => (out[type.code] = type));
  return out;
};

const documentUploadTypes = buildDocumentUploadTypesObject();

export default documentUploadTypes;

export const FILE_TYPES = [
  { extension: '.pdf', mimeType: 'application/pdf', validateTrueType: true },
  { extension: '.png', mimeType: 'image/png', validateTrueType: true },
  { extension: '.gif', mimeType: 'image/gif', validateTrueType: true },
  { extension: '.jpg', mimeType: 'image/jpeg', validateTrueType: true },
  { extension: '.jpeg', mimeType: 'image/jpeg', validateTrueType: true },
  { extension: '.jpe', mimeType: 'image/jpeg', validateTrueType: true },
];

const documentTypeEntryFromType = type => {
  return { [type.correspondenceType]: type };
};

export const HOME_HUB_DOCUMENT_TYPES_BY_CORRESPONDENCE_TYPE = {
  ...documentTypeEntryFromType(documentUploadTypes.PROBATE),
  ...documentTypeEntryFromType(documentUploadTypes.LOSS_ASSESSOR),
  ...documentTypeEntryFromType(documentUploadTypes.DAMAGE_PHOTOS),
  ...documentTypeEntryFromType(documentUploadTypes.RECEIPT_ESTIMATE_COMBINED),
  ...documentTypeEntryFromType(documentUploadTypes.TENANCY_AGREEMENT),
  ...documentTypeEntryFromType(documentUploadTypes.WILL),
};

export const mapToValueLabel = ({ code, description }) => ({
  value: code,
  label: description,
});

export const homeHubUploadaleMatcher = data => data.hubUploadable;

export const getUploadableTypes = (types, matcher) =>
  types.filter(entry => matcher(entry));

export const HOME_HUB_UPLOADABLE_TYPES = getUploadableTypes(
  documentUploadTypesList,
  homeHubUploadaleMatcher,
);

export const HOME_HUB_UPLOADABLE_TYPES_LIST =
  HOME_HUB_UPLOADABLE_TYPES.map(mapToValueLabel);
