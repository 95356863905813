import React from 'react';

import questionsConsiderations from './Considerations';
import iconConsiderations from '../../../assets/icons/claimTypes/what-to-consider-when-making-a-claim.svg';
import iconActiveConsiderations from '../../../assets/icons/claimTypes/what-to-consider-when-making-a-claim-active.svg';

import questionsOnlineClaimSupport from './OnlineClaimSupport';
import iconOnlineClaimSupport from '../../../assets/icons/claimTypes/online-claim-support.svg';
import iconActiveOnlineClaimSupport from '../../../assets/icons/claimTypes/online-claim-support-active.svg';

import questionsWearAndTear from './WearAndTear';
import iconWearAndTear from '../../../assets/icons/claimTypes/wear-and-tear.svg';
import iconActiveWearAndTear from '../../../assets/icons/claimTypes/wear-and-tear-active.svg';

import questionsExcessProtection from './ExcessProtection';
import iconExcessProtection from '../../../assets/icons/claimTypes/excess-protection.svg';
import iconActiveExcessProtection from '../../../assets/icons/claimTypes/excess-protection-active.svg';

import questionsAD from './AccidentalDamage';
import iconAD from '../../../assets/icons/claimTypes/accidental-damage.svg';
import iconActiveAD from '../../../assets/icons/claimTypes/accidental-damage-active.svg';

import questionsEOW from './EffectOfWaterDamage';
import iconEOW from '../../../assets/icons/claimTypes/eow.svg';
import iconActiveEOW from '../../../assets/icons/claimTypes/eow-active.svg';

import questionsContent from './ContentsClaim';
import iconContents from '../../../assets/icons/claimTypes/contents.svg';
import iconActiveContents from '../../../assets/icons/claimTypes/contents-active.svg';

import questionsStorm from './WeatherDamage';
import iconStorm from '../../../assets/icons/claimTypes/storm-damage.svg';
import iconActiveStorm from '../../../assets/icons/claimTypes/storm-damage-active.svg';

import questionsFreezerFood from './FreezerFood';
import iconFreezer from '../../../assets/icons/claimTypes/freezer.svg';
import iconActiveFreezer from '../../../assets/icons/claimTypes/freezer-active.svg';

import questionsPolicyExcess from './PolicyExcess';
import iconPolicy from '../../../assets/icons/claimTypes/policy-excess.svg';
import iconActivePolicy from '../../../assets/icons/claimTypes/policy-excess-active.svg';

import questionsComplaints from './Complaints';
import iconComplaints from '../../../assets/icons/claimTypes/complaints.svg';
import iconActiveComplaints from '../../../assets/icons/claimTypes/complaints-active.svg';

import buildingDamageQuestions from './BuildingDamage';
import iconBuildings from '../../../assets/icons/claimTypes/buildings.svg';
import iconActiveBuildings from '../../../assets/icons/claimTypes/buildings - active.svg';

import ageasApprovedSuppliersFactors from './AgeasApprovedSuppliers';
import iconInfoRoundel from '../../../assets/icons/claimTypes/info-roundel.svg';
import iconActiveInfoRoundel from '../../../assets/icons/claimTypes/info-roundel-active.svg';

import NestedAccordion from '../../NestedAccordion/NestedAccordion';
import nestedAccordionBuilder, {
  nestedAccordionSectionBuilder,
} from '../../NestedAccordion/nestedAccordionBuilder';
import IconImage from '../../IconImage/IconImage';

export const claimQuestions = [
  {
    title: 'What to consider when making a claim',
    content: nestedAccordionSectionBuilder(questionsConsiderations, true),
    icon: <IconImage src={iconConsiderations} />,
    activeIcon: <IconImage src={iconActiveConsiderations} />,
  },
  {
    title: 'Online Claim Support',
    content: nestedAccordionSectionBuilder(questionsOnlineClaimSupport, true),
    icon: <IconImage src={iconOnlineClaimSupport} />,
    activeIcon: <IconImage src={iconActiveOnlineClaimSupport} />,
  },
  {
    title: 'Wear and tear',
    content: nestedAccordionSectionBuilder(questionsWearAndTear, true),
    icon: <IconImage src={iconWearAndTear} />,
    activeIcon: <IconImage src={iconActiveWearAndTear} />,
  },
  {
    title: 'Storm damage and claims',
    content: nestedAccordionSectionBuilder(questionsStorm, true),
    icon: <IconImage src={iconStorm} />,
    activeIcon: <IconImage src={iconActiveStorm} />,
  },
  {
    title: 'Contents damage',
    content: nestedAccordionSectionBuilder(questionsContent, true),
    icon: <IconImage src={iconContents} />,
    activeIcon: <IconImage src={iconActiveContents} />,
  },
  {
    title: 'Leaking or Escape of water in the home',
    content: nestedAccordionSectionBuilder(questionsEOW, true),
    icon: <IconImage src={iconEOW} />,
    activeIcon: <IconImage src={iconActiveEOW} />,
  },
  {
    title: 'Accidental damage',
    content: nestedAccordionSectionBuilder(questionsAD, true),
    icon: <IconImage src={iconAD} />,
    activeIcon: <IconImage src={iconActiveAD} />,
  },
  {
    title: 'Damage to fridge/freezer food in the home',
    content: nestedAccordionSectionBuilder(questionsFreezerFood, true),
    icon: <IconImage src={iconFreezer} />,
    activeIcon: <IconImage src={iconActiveFreezer} />,
  },
  {
    title: 'Building damage',
    content: nestedAccordionSectionBuilder(buildingDamageQuestions, true),
    icon: <IconImage src={iconBuildings} />,
    activeIcon: <IconImage src={iconActiveBuildings} />,
  },
  {
    title: 'Important notice concerning Ageas approved suppliers',
    content: nestedAccordionSectionBuilder(ageasApprovedSuppliersFactors, true),
    icon: <IconImage src={iconInfoRoundel} />,
    activeIcon: <IconImage src={iconActiveInfoRoundel} />,
  },
  {
    title: 'Policy excess',
    content: nestedAccordionSectionBuilder(questionsPolicyExcess, true),
    icon: <IconImage src={iconPolicy} />,
    activeIcon: <IconImage src={iconActivePolicy} />,
  },
  {
    title: 'Excess Protection',
    content: nestedAccordionSectionBuilder(questionsExcessProtection, true),
    icon: <IconImage src={iconExcessProtection} />,
    activeIcon: <IconImage src={iconActiveExcessProtection} />,
  },
  {
    title: 'How to complain',
    content: nestedAccordionSectionBuilder(questionsComplaints, true),
    icon: <IconImage src={iconComplaints} />,
    activeIcon: <IconImage src={iconActiveComplaints} />,
  },
];

export const typeQuestions = [
  {
    title: 'I have a question about a claim',
    content: nestedAccordionBuilder(claimQuestions),
    contentHeader: 'My question is about',
    titlePaddingLeft: '44px',
    defaultOpen: true,
  },
  /* {
    title: 'I have a question about my policy',
    content: 'TBA',
    titlePaddingLeft: '44px',
  }, */
];
export const typeQuestionsDefault = typeQuestions.find(
  q => q?.defaultOpen,
)?.title;

const HomeFAQAccordion = ({ ...props }) => {
  return (
    <NestedAccordion defaultSelection={typeQuestionsDefault} {...props}>
      {nestedAccordionBuilder(typeQuestions, undefined)}
    </NestedAccordion>
  );
};
export default HomeFAQAccordion;
