import React from 'react';
import FaqP from '../../FaqP';

export const temporaryRepairQuestion = 'Can I carry out temporary repairs?';
export const temporaryRepairContent = (
  <>
    <FaqP>
      Unless it is an emergency, please do not carry out any repairs or replace
      items without us agreeing to this first.
    </FaqP>
    <FaqP>
      If you need to take action to protect your property we would recommend
      that you do so, provided the temporary repairs can be easily removed for a
      full inspection, if required.
    </FaqP>
    <FaqP>
      <strong>PLEASE NOTE:</strong>
      <br />
      The cost of any temporary repairs will not be reimbursed if your claim is
      declined.
    </FaqP>
  </>
);
