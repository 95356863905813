import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ThemedCheckbox, ThemedButton } from 'ageas-ui-components';
import Accordion from '../../components/FAQs/HomeFAQ/Accordion';
import Ul from '../../components/StandardTags/UL';
import BrandHeader from '../../assets/BrandHeader';
import BrandFooter from '../../assets/BrandFooter';
import {
  PageContainer,
  TitleText,
  TextContainer,
  PageSection,
  PageSectionBare,
  TopText,
  TopTextSection,
  TopTextTitle,
  PhoneNumbersSection,
  NavLink,
  PurpleBox,
  ContentHolder,
} from '../../assets/styles/GenericInsurance.style';
import { HomeClaimsLandingFNOL } from '../../helpers/ageasTelephoneNumbers';
import { sizes } from '../../components/MediaQuery/MediaQuery';
import makingClaimsEasyText from '../../helpers/makingClaimsEasyText';
import StandardPopup, {
  ModalButtonsContainer,
} from '../../components/StandardPopup/StandardPopup';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import Spacer from '../../components/Forms/FieldSpacer/FieldSpacer';
import ProgressiveQuestionsPanel from '../../components/ProgressiveQuestions/ProgressiveQuestionPanel';
import ProgressiveQuestionEclaimsHelper from '../../helpers/ProgressiveQuestionEclaimsHelper';
import ChatBot from '../../components/ChatBot/ChatBot';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';
import { brand } from '../../../data/whitelabel.config';

const Theme = getTheme(brand);

const getDesktopHeaderOffset = () => {
  if (window.innerWidth >= sizes.desktop) {
    return -75;
  }
  return 0;
};

const HomeInsurance = () => {
  const [disclaimer, setDisclaimer] = useState(false);
  const [perilCode, setPerilCode] = useState(' ');
  const [checked, setChecked] = useState(false);
  const [navigatePath, setNavigatePath] = useState(null);
  const [disclaimerQueryParameters, setDisclaimerQueryParameters] =
    useState(null);

  const renderDisclaimer = (name, queryParameters = '') => {
    setDisclaimer(true);
    setPerilCode(name);
    setDisclaimerQueryParameters(queryParameters);
  };

  const navigateWithQueryParameters = (path, queryParameters) => {
    let newPath = path;
    if (queryParameters) {
      newPath += queryParameters;
    }
    setNavigatePath(newPath);
  };

  const onConfirm = () => {
    setDisclaimer(false);

    // All perils navigate to history
    if (perilRoutes[perilCode]?.history) {
      navigateWithQueryParameters(
        perilRoutes[perilCode].history,
        disclaimerQueryParameters,
      );
    }
  };

  const onConfirmClose = () => {
    setDisclaimer(false);
    setChecked(false);
  };
  const onConfirmationCheckboxChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <ThemeProvider theme={Theme}>
      <PageContainer>
        <NavigateTo path={navigatePath} />
        <BrandHeader suppressWarningOnClick />
        <div id="wholewindow">
          <TopText>
            <TopTextSection>
              <TopTextTitle>{makingClaimsEasyText.titleJSX}</TopTextTitle>
              {makingClaimsEasyText.textJSX}
            </TopTextSection>
          </TopText>

          <PageSection>
            <TitleText primary>Home claims</TitleText>
            <NavLink to="/">
              <FontAwesomeIcon icon={faChevronLeft} /> back to claims
            </NavLink>
          </PageSection>
          {disclaimer && (
            <StandardPopup>
              <Spacer marginBottom="10px">
                <ThemedCheckbox
                  fieldName="Checkbox"
                  name="fraudDisclaimerBox"
                  checked={checked}
                  onChange={onConfirmationCheckboxChange}
                  text=" Please confirm that you will take care to answer all questions honestly and to the best of your knowledge. Failure to do so may affect your claim."
                  boldLabel={false}
                />
              </Spacer>
              <ModalButtonsContainer>
                <ThemedButton
                  primary
                  small
                  disabled={!checked}
                  type="button"
                  onClick={() => onConfirm()}
                >
                  Yes
                </ThemedButton>
                <ThemedButton
                  inverted
                  primary
                  small
                  type="button"
                  onClick={onConfirmClose}
                >
                  Close
                </ThemedButton>
              </ModalButtonsContainer>
            </StandardPopup>
          )}

          <TextContainer>
            <ContentHolder>
              <TitleText>
                What type of claim can we help you with today?
              </TitleText>
              Just select an option below to get started. If none of these
              apply, select &apos;Something else&apos; so that you can speak to
              someone in our Claims Team who will be happy to help.
              <ProgressiveQuestionsPanel
                noBorder
                itemContainerProps={{
                  color: 'black',
                  fontSize: '19px',
                  marginLeft: '0px',
                }}
                primary
                padding="25px"
                questions={ProgressiveQuestionEclaimsHelper(renderDisclaimer)}
                localProps={{ minWidth: '140px' }}
              />
            </ContentHolder>
          </TextContainer>

          <PurpleBox>
            <TitleText>Do you have any questions?</TitleText>
            We&apos;ve put together some answers to the most frequently asked
            questions and made it easy for you to find the information you need.
            If you still need help, please use our Virtual Assistant.
          </PurpleBox>

          <PageSectionBare>
            <Accordion
              nestingControl={{ autoPositionOffsetTop: getDesktopHeaderOffset }}
            />
          </PageSectionBare>

          <PhoneNumbersSection>
            <PageSection>
              <TitleText>Do you need urgent support?</TitleText>
              <Ul>
                <li>Is your property uninhabitable or unsecured?</li>
                <li>
                  Do you need to make us aware of any exceptional circumstances
                  affecting you or someone you live with?
                </li>
                <li>
                  If so, then please get in touch by calling{' '}
                  <HomeClaimsLandingFNOL /> and we&apos;ll get you to the right
                  person as soon as possible.
                </li>
              </Ul>
            </PageSection>
          </PhoneNumbersSection>

          <BrandFooter />
        </div>
        <ChatBot />
      </PageContainer>
    </ThemeProvider>
  );
};

export default HomeInsurance;
