import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { ThemedButton } from 'ageas-ui-components';
import { ukPostcode } from 'ageasvalidation/lib/schemas/UKPostcode';
import { email } from 'ageasvalidation/lib/schemas/Email';
import { ukTelephoneNumber } from 'ageasvalidation/lib/schemas/Telephone';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { stringEqualTo } from 'ageasvalidation/lib/schemas/String';
import mobileTelephoneValidationRegex from '../../helpers/mobileTelephoneValidationRegex';
import { validateField } from '../../helpers/validationHelper';
import PersonSubform from '../../components/Forms/Person/PersonSubform';

const recordIsNonBlank = values => {
  return !!(
    values.title?.trim() ||
    values.firstName?.trim() ||
    values.lastName?.trim() ||
    values.houseNumberName?.trim() ||
    values.address1?.trim() ||
    values.address2?.trim() ||
    values.address3?.trim() ||
    values.address4?.trim() ||
    values.postcode?.trim() ||
    values.homeTelephone?.trim() ||
    values.mobileTelephone?.trim() ||
    values.emailAddress?.trim()
  );
};
const recordIsNonBlankYesNo = values => {
  return recordIsNonBlank(values) ? 'yes' : 'no';
};

// Invalid schema error usually means a field is being validated with no
// schema in here
const schema = {
  // addToMainPage Start
  personSectionError: stringEqualTo(
    'yes',
    ['personSectionError'],
    'Please input at least one item of data',
  ),
  postcode: ukPostcode(['postcode'], 'please enter a valid UK postcode'),
  // TODO uncomment when address picker re-enabled
  /*
  addressPicker: stringRequired(
    ['addressPicker'],
    'Please select an address or click Manual input',
  ),

  postcodeLookup: ukPostcode(
    ['postcodeLookup'],
    'please enter a valid UK postcode',
  ),
  */

  emailAddress: email(['emailAddress'], 'Please enter a valid Email address'),
  mobileTelephone: regularExpression(
    mobileTelephoneValidationRegex,
    ['mobileTelephone'],
    'Please enter a valid Mobile Telephone number',
  ),
  homeTelephone: ukTelephoneNumber(
    ['homeTelephone'],
    'Please enter a valid Home Telephone number',
  ),
  // addToMainPage End
};

const validateFieldSchema = (value, allValues, meta) => {
  let result;
  if (schema[meta.name]) {
    // addToMainPage Start

    // Mandatory fields - nonblank and formatting

    // TODO uncomment when address picker re-enabled
    /*
    switch (meta.name) {
      case 'addressPicker':
        result = validateField(value?.trim(), meta, schema);
        if (result) return result;
        break;
      default:
    }
    */

    // Optional fields - validating only if nonblank
    if (value?.trim()) {
      switch (meta.name) {
        case 'postcode':
        // falls through

        // TODO uncomment when address picker re-enabled
        // case 'postcodeLookup':
        // falls through
        case 'emailAddress':
        // falls through
        case 'mobileTelephone':
        // falls through
        case 'homeTelephone':
          result = validateField(value, meta, schema);
          if (result) return result;
          break;
        default:
      }
    }

    // Combinations - must enter at least one field
    if (meta.name === 'personSectionError') {
      result = validateField(recordIsNonBlankYesNo(allValues), meta, schema);
      if (result) return result;
    }
  }
  return result;
};

/* Standalone RFF passenger form

RFF Form with:
* Instance of PersonSubform
* Save button that submits the form
* Optional cancel button that calls onCancel callback prop
* onSubmit prop that is called by RFF onSubmit

props:
* initialValues: Object to default RFF form state
* onSubmit: RFF OnSubmit callback (called with values object if form is valid)
* onCancel: If set, displays a cancel button. Function is called on click of
  this button

Validation:
All fields are optional, but at least one data field (i.e. not postcode lookup
field) must be entered
Telephone number fields must be valid formatted if not blank
Email address must be valid formatted if not blank
Postcode must be valid formatted if not blank

*/

const PassengerForm = ({ initialValues, onSubmit, onCancel }) => {
  const fieldUpdateMutator = ([fieldName, value], state, utils) => {
    utils.changeValue(state, fieldName, () => value);
  };

  const onFormSubmit = values => {
    onSubmit(values);
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      mutators={{ fieldUpdateMutator }}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, form }) => (
        <form method="post" onSubmit={handleSubmit}>
          <PersonSubform
            values={values}
            form={form}
            validate={validateFieldSchema}
          />
          <ThemedButton small primary type="submit">
            Save
          </ThemedButton>{' '}
          {onCancel && (
            <ThemedButton
              small
              primary
              inverted
              type="button"
              onClick={onCancel}
            >
              Cancel
            </ThemedButton>
          )}
        </form>
      )}
    </Form>
  );
};

export default PassengerForm;

PassengerForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

PassengerForm.defaultProps = {
  initialValues: {},
  onCancel: undefined,
};
