/**
 * Map incidentDetails context/pageForms data to JSON Blob incidentDetails
 * section
 * @param {*} data incidentDetails in MotorClaimContextNew, claimEntryData
 * format
 * @returns data in JSON Blob incidentDetails section format
 */
const mapLocalToLocationDetailsSection = ({ incidentDetails } = {}) => {
  const { incidentStreet, incidentTown, incidentCountry } =
    incidentDetails || {};
  // Return mapped object
  return {
    street: incidentStreet?.trim(),
    town: incidentTown?.trim(),
    country: incidentCountry?.trim(),
  };
};

export default mapLocalToLocationDetailsSection;
