import React from 'react';
import PropTypes from 'prop-types';
import {
  LabelSetContent,
  LabelSetLabel,
  LabelSetRow,
} from './StandardPageWrapperStyle';

export const LabelSetEntry = ({ label, column = false, children }) => (
  <LabelSetRow column={column}>
    <LabelSetLabel>{label}</LabelSetLabel>
    {children && <LabelSetContent>{children}</LabelSetContent>}
  </LabelSetRow>
);

LabelSetEntry.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  column: PropTypes.bool,
};
LabelSetEntry.defaultProps = {
  label: undefined,
  children: undefined,
  column: undefined,
};

export default LabelSetEntry;
