import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UploadDocuments, CustomList } from 'ageas-ui-components';
import { getImages } from 'ageas-ui-themes';

import styled from 'styled-components';
import UploaderCategoryCustomField from './UploaderCategoryCustomField';
import P from '../StandardTags/P';

import { hubUploadErrorMotor } from '../../helpers/errorMessages';
import config from '../../helpers/config';
import { brand } from '../../../data/whitelabel.config';
import MOTOR_TP_HUB_UPLOADABLE_TYPES_OBJECT, {
  MOTOR_TP_HUB_UPLOADABLE_TYPES,
  MOTOR_TP_HUB_UPLOADABLE_TYPES_LIST,
} from '../../helpers/motorTPDocumentUploadTypes';
import { FILE_TYPES } from '../../helpers/documentUploadTypes';
import axiosHelper from '../../helpers/axios';
import { UploadModalHeader } from './DocumentUploaderComponents';
import media from '../MediaQuery/MediaQuery';

const ImageStore = getImages(brand);

const PNoBottom = styled(P)`
  margin-bottom: 0px;
`;

const UploadInstructionsContainer = styled.div`
  padding-left: 10px;
  ${media.phone`
    padding-left: 0px;
  `}
`;

const UPLOAD_INSTRUCTIONS = (
  <UploadInstructionsContainer>
    <P>We can accept the following:</P>
    <CustomList
      items={['Estimates', 'Receipts', 'Legal documents', 'Photos of damage']}
      bulletIcon={ImageStore.greenTick}
    />
  </UploadInstructionsContainer>
);

const UPLOAD_SUCCESS_INFO = (
  <>
    <P>Thank you for providing your document.</P>
    <P>
      If you need to upload more than one document click &quot;Add
      another&quot;, otherwise click &quot;Close&quot; when you&apos;ve
      finished.
    </P>
  </>
);

const buildAxiosErrorMessage = (
  claimReference,
  documentUploadTypes,
  _payload,
  data,
) =>
  hubUploadErrorMotor(
    claimReference,
    '',
    documentUploadTypes[data.category]?.correspondenceType,
    '',
  );

/* eslint-disable import/prefer-default-export */
const getUploaderProps = (
  claimReference,
  documentUploadTypes,
  axiosPostURL,
) => {
  return {
    axiosPost: axiosHelper.post,
    axiosPostURL,
    header: 'Upload a document',
    instructionsTopSection:
      'This is where you can provide us with documentation in support of your claim.',
    instructionsSideSection: UPLOAD_INSTRUCTIONS,
    footer: (
      <PNoBottom>
        We can accept original copies, photos or screenshots (as long as we can
        see the whole document).
      </PNoBottom>
    ),
    uploadModalHeader: (
      <UploadModalHeader>
        This is where you can upload proof of your legal document, estimate,
        receipt or photos of damage.
      </UploadModalHeader>
    ),
    successfulUploadHeader:
      'Upload successful, we have received your documents',
    successfulUploadInfo: UPLOAD_SUCCESS_INFO,
    uploadFailError: (...props) =>
      buildAxiosErrorMessage(claimReference, documentUploadTypes, ...props),
    acceptFileTypesList: FILE_TYPES,
    fileSizeLimit: 7,
    brand,
    customUploadFieldsDataFormatter: ({ category }) => {
      return { category: documentUploadTypes[category].category };
    },
    showUploadedDocumentsSection: false,
    CustomUploadFields: () => (
      <UploaderCategoryCustomField
        uploadableTypes={MOTOR_TP_HUB_UPLOADABLE_TYPES}
        uploadableTypesList={MOTOR_TP_HUB_UPLOADABLE_TYPES_LIST}
      />
    ),
    modalWrapperProps: {
      upload: {
        role: 'dialog',
        'aria-label': 'Upload a document',
      },
      another: {
        role: 'dialog',
        'aria-label': 'Upload another document',
      },
      close: {
        role: 'dialog',
        'aria-label': 'Confirm close document upload',
      },
    },
  };
};

const MotorHubUploader = ({
  claimReference,
  notificationCodes,
  notificationSequence,
  documentUploadTypes,
  externalModalState,
  onSuccessfulUpload,
  axiosPostURL,
  ...rest
}) => {
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const uploadedDocumentsState = [uploadedDocuments, setUploadedDocuments];

  const NotificationSpecificUploaderFields = () => (
    <UploaderCategoryCustomField
      notificationCodes={notificationCodes}
      uploadableTypes={MOTOR_TP_HUB_UPLOADABLE_TYPES}
      uploadableTypesList={MOTOR_TP_HUB_UPLOADABLE_TYPES_LIST}
    />
  );

  return (
    <>
      {/* Instance that renders its own add button */}
      <UploadDocuments
        payloadKeys={{}}
        onSuccessfulUpload={onSuccessfulUpload}
        uploadedDocumentsState={uploadedDocumentsState}
        {...getUploaderProps(claimReference, documentUploadTypes, axiosPostURL)}
        {...rest}
      />
      {/* instance that renders modals only, with external add button */}
      <UploadDocuments
        payloadKeys={{ notificationSequence }}
        onSuccessfulUpload={onSuccessfulUpload}
        uploadedDocumentsState={uploadedDocumentsState}
        {...getUploaderProps(claimReference, documentUploadTypes, axiosPostURL)}
        uploaderModalsOnly
        modalState={externalModalState}
        CustomUploadFields={NotificationSpecificUploaderFields}
        {...rest}
      />
    </>
  );
};

export const MotorTPHubUploader = ({ ...props }) => (
  <MotorHubUploader
    documentUploadTypes={MOTOR_TP_HUB_UPLOADABLE_TYPES_OBJECT}
    axiosPostURL={config.client.motorTPHubUploadDocument_endpoint}
    {...props}
  />
);

export default MotorHubUploader;

MotorHubUploader.propTypes = {
  axiosPostURL: PropTypes.string.isRequired,
  claimReference: PropTypes.string,
  notificationCodes: PropTypes.arrayOf(PropTypes.string),
  notificationSequence: PropTypes.number,
  documentUploadTypes: PropTypes.shape({}),
  externalModalState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ),
  onSuccessfulUpload: PropTypes.func,
};

MotorHubUploader.defaultProps = {
  claimReference: undefined,
  notificationCodes: undefined,
  notificationSequence: undefined,
  documentUploadTypes: undefined,
  externalModalState: undefined,
  onSuccessfulUpload: undefined,
};
