import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Form } from 'react-final-form';

import { getTheme } from 'ageas-ui-themes';
import PropTypes from 'prop-types';
import { brand } from '../../../../../data/whitelabel.config';
import IncidentDate, {
  getIncidentDateValidationSchema,
  run3YearDateValidation,
} from './IncidentDate';
import { validateField } from '../../../../helpers/validationHelper';
import { consoleLog } from '../../../../helpers/consoleLog';

const Theme = getTheme(brand);

const PageContainer = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const IncidentDateTestRenderer = ({ initialValues = {}, name, ...props }) => {
  const schema = {
    ...getIncidentDateValidationSchema(name),
  };

  const validateFields = (value, allFields, meta) => {
    let error;
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return error;
      }
    }

    if (meta.name === name) {
      const incidentError = run3YearDateValidation(value, 'home');
      if (incidentError) {
        return incidentError;
      }
    }
    return undefined;
  };

  const beforeSubmit = () => {
    consoleLog('beforeSubmit');
  };

  const onSubmit = () => {
    consoleLog('onSubmit');
  };

  return (
    <ThemeProvider theme={Theme}>
      <PageContainer>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, values }) => (
            <form
              method="post"
              onSubmit={handleSubmit}
              onSubmitCapture={event => beforeSubmit(event, values)}
            >
              <IncidentDate
                name={name}
                label="date picker test"
                secondaryLabel="this is the date picker test"
                {...props}
                validate={validateFields}
              />
              <input type="text" placeholder="dummy" />
              <button type="submit">Submit</button>
            </form>
          )}
        </Form>
      </PageContainer>
    </ThemeProvider>
  );
};
export default IncidentDateTestRenderer;

IncidentDateTestRenderer.propTypes = {
  name: PropTypes.string,
  initialValues: PropTypes.func,
};

IncidentDateTestRenderer.defaultProps = {
  name: undefined,
  initialValues: undefined,
};
