import React, { useState } from 'react';

import {
  brand,
  brandLogo,
  headerInfo,
} from '../../../../data/whitelabel.config';
import capitalizeWord from '../../../helpers/capitalizeWord';

import useGetPhoneNumber from '../useGetPhoneNumber/useGetPhoneNumber';
import useModalOnExit from '../useModalOnExit/useModalOnExit';

import {
  Header,
  Container,
  LogoContainer,
  Logo,
  LogoText,
  HelpContainer,
  TitleWrapper,
  MobileTitle,
  PlusIcon,
  HelpTitle,
  ContactDetails,
  PhoneNumber,
  PhoneIcon,
  OpenHours,
} from './RiasHeader.style';

const RiasHeader = () => {
  const { telephoneNumber } = useGetPhoneNumber();
  const { logoText, showHelpBox = false } = headerInfo;
  const [detailsVisible, setDetailsVisible] = useState(false);

  const { toggleModal, Modal } = useModalOnExit();

  return (
    <Header>
      <Container>
        <LogoContainer data-testid="logo-container" onClick={toggleModal}>
          <Logo alt={`${capitalizeWord(brand)} logo`} src={brandLogo} />
          <LogoText>{logoText}</LogoText>
        </LogoContainer>
        {showHelpBox && (
          <HelpContainer>
            <TitleWrapper
              onClick={() =>
                setDetailsVisible(currDetailsVisible => !currDetailsVisible)
              }
            >
              <MobileTitle>Need Help?</MobileTitle>
              <PlusIcon icon="plus" size={40} />
            </TitleWrapper>
            <HelpTitle>Need Help?</HelpTitle>
            <ContactDetails visible={detailsVisible}>
              <PhoneNumber href={`tel:${telephoneNumber}`}>
                <PhoneIcon icon="phone" size={29} />
                <span>{telephoneNumber}</span>
              </PhoneNumber>
              <OpenHours>
                Lines are open:
                <br />
                <span>Mon - Fri:</span> <span>8:30am - 6:00pm</span>
                <br />
                <span>Saturday:</span> <span>9:00am - 1:00pm</span>
              </OpenHours>
            </ContactDetails>
          </HelpContainer>
        )}
      </Container>
      <Modal />
    </Header>
  );
};

export default RiasHeader;
