import React from 'react';
import { ThemedTitle } from 'ageas-ui-components';
import ContactBanner from '../../components/ContactBanner/ContactBanner';
import { BannerIcon } from '../HelperData/HelperData';
import {
  ComponentTitle,
  TextAlign,
  HeaderComponent,
  ComponentText,
  ComponentIcon,
  ContentWrapper,
} from '../../assets/styles/GenericInsurance.style';
import makingClaimsEasyText from '../../helpers/makingClaimsEasyText';

const MiddleLayer = () => {
  return (
    <ContentWrapper>
      <TextAlign>
        <HeaderComponent>
          <ThemedTitle>
            <ComponentTitle>{makingClaimsEasyText.titleJSX}</ComponentTitle>
          </ThemedTitle>
          <ThemedTitle>
            <ComponentText>{makingClaimsEasyText.textJSX}</ComponentText>
          </ThemedTitle>
        </HeaderComponent>
        <ComponentIcon>
          <ContactBanner fieldName="coverType-themed" srcImg={BannerIcon} />
        </ComponentIcon>
      </TextAlign>
    </ContentWrapper>
  );
};

export default MiddleLayer;
