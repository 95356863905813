import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const damageInspectionQuestion =
  'Will someone come out to look at the damage?';
export const damageInspectionContent = (
  <>
    <FaqP marginBottom={false}>
      When you report the claim, we will do everything we can to establish the
      level of damage and will consider which of the following options are best
      suited to your circumstances:
    </FaqP>
    <StandardUl>
      <li>
        We may be able to offer you a cash settlement for the water damage
        repairs and raise a cash payment for you to use in getting the repairs
        completed yourself. We may ask you to contact a local tradesman in order
        to assess the damage
      </li>

      <li>
        If there is more work to be done, we may instruct one of our approved
        contractors to contact you directly and make arrangements for the
        repairs
      </li>

      <li>
        If the damage is substantial we will send out an expert to manage the
        claim and talk you through the best course of action
      </li>
    </StandardUl>
  </>
);
