import React from 'react';
import FaqP from '../../FaqP';

export const eowClaimPeriodQuestion = 'How long will my claim take to resolve?';
export const eowClaimPeriodContent = (
  <>
    <FaqP>
      Getting your home back to normal can take time depending on the
      circumstances and the extent of the damage. On average, escape or leakage
      of water claims are usually dealt with within 95 days.
    </FaqP>

    <FaqP>
      This is just an estimated average, your claim could take a shorter or
      longer amount of time.
    </FaqP>
  </>
);
