import React from 'react';
import {
  freezerFoodClaimTitle,
  freezerFoodClaimContent,
} from './FreezerFoodClaim';
import FreezerFoodClaimDeclinedContent, {
  freezerFoodClaimDeclinedTitle,
} from './FreezerFoodClaimDeclined';
import {
  freezerFoodClaimNotCoveredTitle,
  freezerFoodClaimNotCoveredContent,
} from './FreezerFoodClaimNotCovered';
import {
  freezerFoodCoverTitle,
  freezerFoodCoverContent,
} from './FreezerFoodCover';
import {
  freezerFoodRuinedTitle,
  freezerFoodRuinedContent,
} from './FreezerFoodRuined';
import {
  freezerStopWorkingTitle,
  freezerStopWorkingContent,
} from './FreezerStopWorking';
import { myExcessPayTitle, myExcessPayContent } from './MyExcessPay';
import {
  perishedFoodAffectClaimTitle,
  perishedFoodAffectClaimContent,
} from './PerishedFoodAffectsClaim';
import TimeTakenForFreezerClaimContent, {
  timeTakenForFreezerClaimTitle,
} from './TimeTakenForFreezerClaim';

const freezerFoodQuestions = [
  {
    title: freezerFoodClaimTitle,
    content: freezerFoodClaimContent,
  },
  {
    title: freezerFoodCoverTitle,
    content: freezerFoodCoverContent,
  },
  {
    title: freezerStopWorkingTitle,
    content: freezerStopWorkingContent,
  },
  {
    title: freezerFoodRuinedTitle,
    content: freezerFoodRuinedContent,
  },
  {
    title: timeTakenForFreezerClaimTitle,
    content: <TimeTakenForFreezerClaimContent />,
  },
  {
    title: myExcessPayTitle,
    content: myExcessPayContent,
  },
  {
    title: freezerFoodClaimDeclinedTitle,
    content: <FreezerFoodClaimDeclinedContent />,
  },
  {
    title: freezerFoodClaimNotCoveredTitle,
    content: freezerFoodClaimNotCoveredContent,
  },
  {
    title: perishedFoodAffectClaimTitle,
    content: perishedFoodAffectClaimContent,
  },
];

export default freezerFoodQuestions;
