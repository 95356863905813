import React from 'react';
import PropTypes from 'prop-types';
import ButtonBody from './ThemedAnchorButton.style';

// For documentation see:
// /docs/components/ThemedAnchorButton/ThemedAnchorButton.md

const ThemedAnchorButton = React.forwardRef((props, ref) => {
  // Remove format props and assign to sub object
  // So we don't pass them to Link, which causes console errors

  const {
    primary,
    secondary,
    tertiary,
    plain,
    inverted,
    xsmall,
    small,
    medium,
    large,
    xlarge,
    squared,
    disabled,
    ...rest
  } = { ...props };

  const formatProps = {
    primary,
    secondary,
    tertiary,
    plain,
    inverted,
    xsmall,
    small,
    medium,
    large,
    xlarge,
    squared,
    disabled,
  };

  return (
    <ButtonBody ref={ref} formatting={formatProps} {...rest}>
      {props.children}
    </ButtonBody>
  );
});

export default ThemedAnchorButton;

ThemedAnchorButton.propTypes = {
  children: PropTypes.node.isRequired,
};
