import React from 'react';
import PropTypes from 'prop-types';
import ClaimBreadcrumbStandard from './ClaimBreadcrumbStandard';

export const ITEM_TYPES = ['jewellery', 'fixedGlazing', 'carpets'];

const ClaimBreadcrumbPL = ({ itemType, ...props }) => {
  if (!itemType || !ITEM_TYPES.includes(itemType)) {
    return null;
  }
  return <ClaimBreadcrumbStandard {...props} />;
};

export default ClaimBreadcrumbPL;

// For some reason (maybe the forwardRef), defaultProps are not working
// So instead default are applied in the prop destructuring above.
ClaimBreadcrumbPL.propTypes = {
  itemType: PropTypes.string,
};

ClaimBreadcrumbPL.defaultProps = {
  itemType: undefined,
};
