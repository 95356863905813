import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProgressiveQuestionItem from './ProgressiveQuestionItem';
import { ProgressiveQuestionsPanelPropTypes } from './ProgressiveQuestionsPropTypes';
import StandardPanel from '../StandardPanel/StandardPanel';

// For documentation see:
// /docs/components/ProgressiveQuestions/ProgressiveQuestionPanel.md

const QuestionsContainer = styled.div`
  padding: ${props => props.padding || '0'};
`;

const ProgressiveQuestionsPanel = ({
  title = undefined,
  noBorder = false,
  itemContainerProps = undefined,
  questions = [],
  initialAnswers = {},
  onAnswersChange = undefined,
  firstQuestionId = undefined,
  radioProps = {},
  dropdownProps = {},
  localProps = {},
  maxButtons = 99,
}) => {
  const [answers, setAnswers] = useState(initialAnswers);
  const [firstQuestion, setFirstQuestion] = useState(null);

  const findQuestion = id => {
    return questions.find(question => question.id === id);
  };

  useEffect(() => {
    if (firstQuestionId && findQuestion(firstQuestionId)) {
      setFirstQuestion(firstQuestionId);
    } else if (questions.length) {
      setFirstQuestion(questions[0].id);
    }
  }, [questions, firstQuestionId]);

  const unAnswerQuestion = (id, answersState) => {
    const question = findQuestion(id);

    if (!question || !answersState[id]) {
      return answersState;
    }
    let currentAnswers = { ...answersState };

    // Remove question's answer
    delete currentAnswers[id];

    // Recursively remove this question's descendents
    // eslint-disable-next-line no-use-before-define
    currentAnswers = unAnswerQuestionDescendents(question, currentAnswers);

    return currentAnswers;
  };

  const unAnswerQuestionDescendents = (question, answersState) => {
    let currentAnswers = { ...answersState };
    question.answers.forEach(answer => {
      if (answer.nextId) {
        currentAnswers = unAnswerQuestion(answer.nextId, currentAnswers);
      }
    });
    return currentAnswers;
  };

  const updateAnswers = answersState => {
    setAnswers(answersState);
    if (onAnswersChange) {
      onAnswersChange(answersState);
    }
  };
  const onButtonClick = (question, answerId) => {
    const answer = question.answers.find(ans => ans.id === answerId);

    if (answers[question.id] === answer.id) {
      return;
    }

    let currentAnswers = { ...answers };
    currentAnswers[question.id] = answer.id;

    if (answer.action) {
      answer.action(question, answer);
    }

    currentAnswers = unAnswerQuestionDescendents(question, currentAnswers);

    updateAnswers(currentAnswers);
  };

  const renderQuestions = (id, displayedIds = []) => {
    // Get question
    const question = findQuestion(id);
    if (!question) {
      return null;
    }
    if (displayedIds.includes(id)) {
      return null;
    }
    displayedIds.push(id);

    // Render this question and its descendents if any
    const thisQuestion = (
      <ProgressiveQuestionItem
        itemContainerProps={itemContainerProps}
        itemQuestion={question}
        key={question.id}
        answerId={answers[question.id]}
        onButtonClick={onButtonClick}
        radioProps={radioProps}
        dropdownProps={dropdownProps}
        localProps={localProps}
        maxButtons={maxButtons}
      />
    );

    // Render descendent questions if this question is answered
    let descendentQuestions = [];
    if (answers[question.id]) {
      // Find question object wit this ID
      const currentAnswer = question.answers.find(
        answer => answer.id === answers[question.id],
      );

      // If found and has a next Id, render that question
      if (currentAnswer && currentAnswer.nextId) {
        descendentQuestions = renderQuestions(
          currentAnswer.nextId,
          displayedIds,
        );
      }
    }

    // Return this question, and if set its descendents
    if (descendentQuestions) {
      return [thisQuestion, ...descendentQuestions];
    }
    return [thisQuestion];
  };

  return (
    <StandardPanel title={title} noBorder={noBorder} primary>
      <QuestionsContainer>{renderQuestions(firstQuestion)}</QuestionsContainer>
    </StandardPanel>
  );
};

ProgressiveQuestionsPanel.propTypes = ProgressiveQuestionsPanelPropTypes;

export default ProgressiveQuestionsPanel;
