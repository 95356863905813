import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import P from '../../StandardTags/P';
import StandardPopup from '../../StandardPopup/StandardPopup';
import { PageContainer } from '../../StandardPageWrapper/StandardPageWrapperStyle';

const PNoTop = styled(P)`
  margin-top: 0;
`;

export const Completion = ({ onClose }) => (
  <StandardPopup
    modalProps={{
      withHeader: true,
      headerText: 'Thank you',
      withClose: true,
      onCloseClick: onClose,
    }}
    buttons={[{ text: 'Finish', onClick: onClose }]}
  >
    <PageContainer>
      <PNoTop>
        Thank you for taking the time to raise concerns about the injury claim
        presented and prepare your statement.
      </PNoTop>
      <P>
        <strong>What happens next</strong>
      </P>
      <P>
        Our handler will be undertaking their own review of the claim presented
        and making enquiries. They will also review the statement you have
        provided.
      </P>
      <P>
        If our handler thinks we have a suitable case to challenge they may need
        to contact you to make amendments to your statement, if not they will
        submit your prepared statement to the representative of the claimant.
      </P>
      <P>
        Once the final statement has been submitted, the independent medical
        expert will compile their report and give their opinion on the alleged
        injury.They will take into account both yours and the claimant&apos;s
        comments and undertake a physical examination of the claimant.
      </P>
      <P>
        The expert will then send the report to the other driver and/or their
        legal representative to then send on to us.
      </P>
      <P>
        We will review the findings and again decide whether or not the case is
        suitable to challenge.
      </P>
    </PageContainer>
  </StandardPopup>
);

Completion.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Completion;
