import React from 'react';
import PropTypes from 'prop-types';
import motorClaimTypes from '../../../helpers/motorClaimTypes';
import StandardDate from '../StandardDate/StandardDate';
import StandardDropdown from '../StandardDropdown/StandardDropdown';

const PreviousMotorClaim = ({ fieldNames, validateFields }) => {
  return (
    <>
      <StandardDropdown
        name={fieldNames.claimType || 'claimType'}
        label="Please select the type of claim from the list below"
        aria-label="Please select the type of claim from the list below"
        validate={validateFields}
        options={motorClaimTypes}
        medium
      />
      <StandardDate
        name={fieldNames.claimDate || 'claimDate'}
        id={fieldNames.claimDate || 'claimDate'}
        label="Date of claim"
        validate={validateFields}
        maxYears={4}
      />
    </>
  );
};

PreviousMotorClaim.propTypes = {
  fieldNames: PropTypes.instanceOf(Object),
  validateFields: PropTypes.func,
};

PreviousMotorClaim.defaultProps = {
  fieldNames: {},
  validateFields: undefined,
};

export default PreviousMotorClaim;
