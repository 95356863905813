/* eslint-disable no-control-regex */
export const regex = {
  channelIslandsPostcode: /^$|^(?!GY|IM|JE).+/,
  emailAddress: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  registrationNumber: /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)/i,
  telephoneNumber: /^(?:(?:0(?:0|11)?\(?|\+)44?\(?(?:0\)?\(?)?|0)(?:\d{2}?\d{4}?\d{4}|\d{3}?\d{3}?\d{3,4}|\d{4}?(?:\d{5}|\d{3}?\d{3})|\d{5}?\d{4,5}|8(?:00?11?11|45?46?4\d))(?:(?:(?:x|ext\.?\s?|#)\d+)?)$/,
  ukBankAccount: /^(\d){8}$/,
  ukPostcode: /^$|(((^[BEGLMNS][1-9]\d?)|(^W[2-9])|(^(A[BL]|B[ABDHLNRST]|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]|F[KY]|G[LUY]|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]|M[EKL]|N[EGNPRW]|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKL-PRSTWY]|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)\d\d?)|(^W1[A-HJKSTUW0-9])|(((^WC[1-2])|(^EC[1-4])|(^SW1))[ABEHMNPRVWXY]))(\s*)?([0-9][ABD-HJLNP-UW-Z]{2}))$|(^GIR\s?0AA$)/,
  ukSortCode: /^((\d{2}-\d{2}-\d{2})|(\d{6}))$/,
  webReference: /^(\d{5}-\d{5}-\d{5})|(\d{4}-\d{6}-\d{4})$/,
  addressLine: /^[a-zA-Z0-9\s,./&-']*$/,
  dobYear: /^\d+$/,
};

export default regex;
