import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { relationship } from '../constants/LookupValues';

/**
 * @description: Creates a validator to prevent specific relationship type duplicates. A proposer cannot have two
 * spouses for example.
 * @param relationshipType to check for e.g. SPOUSEREL for spouse
 * @param duplicateRelationshipMessage to return when more than one of relationship type found
 *
 * In order to allow editing of an existing additional driver it is necessary to pass the driver id of the driver being
 * amended/added within the context when calling the validator as shown below -
 *
 *       const state = {
 *         additionalInsuredRelationshipToProposer: relationship.spouse,
 *         additionalDrivers: [
 *           { id: 1, relationshipToProposer: relationship.offspring },
 *         ],
 *       };
 *
 *       const valid = Joi.validate(state, schema, {
 *         context: {
 *           additionalDriverId: 181,
 *         },
 *         allowUnknown: true,
 *         abortEarly: false,
 *       });
 *
 *
 * @succeeds when relationship is consistent with marital status
 * @fails when relationship fields are not consistent with marital status. e.g when relationship to proposer is 'Married' but marital status is 'Single'
 */
export const onlyOneRelationshipOfType = (
  relationshipType,
  duplicateRelationshipMessage,
) => (
  additionalDriverIdField = 'additionalDriverId',
  relationshipToProposerField = 'customerRelation',
  additionalDriversField = 'additionalDrivers',
) => {
  return Joi.object().keys({
    [relationshipToProposerField]: Joi.string(),
    [additionalDriversField]: Joi.when(relationshipToProposerField, {
      is: relationshipType,
      then: Joi.array().items(
        Joi.object().keys({
          relationshipToProposer: Joi.when('id', {
            is: Joi.ref(`$${additionalDriverIdField}`),
            otherwise: Joi.string()
              .invalid(relationshipType)
              .required()
              .error(() => duplicateRelationshipMessage),
          }),
        }),
      ),
    }),
  });
};

export const onlyOneSpouse = onlyOneRelationshipOfType(
  relationship.spouse,
  messages.ONLY_ONE_SPOUSE,
);

export const onlyOneCivilPartner = onlyOneRelationshipOfType(
  relationship.civilPartner,
  messages.ONLY_ONE_CIVIL_PARTNER,
);

export const mustHaveRelationshipStatus = (
  relationshipType,
  maritalStatus,
  duplicateRelationshipMessage,
) => (
  proposerMaritalStatus = 'proposerMaritalStatus',
  relationshipToProposerField = 'customerRelation',
) => {
  return Joi.object().keys({
    [proposerMaritalStatus]: Joi.when(relationshipToProposerField, {
      is: relationshipType,
      then: Joi.object().keys({
        maritalStatus: Joi.when(proposerMaritalStatus, {
          is: maritalStatus,
          otherwise: Joi.string()
            .valid(maritalStatus)
            .required()
            .error(() => duplicateRelationshipMessage),
        }),
      }),
    }),
  });
};

export const mustBeMarried = mustHaveRelationshipStatus(
  relationship.spouse,
  'M',
  messages.MUST_BE_MARRIED,
);

export const mustBeCivilPartner = mustHaveRelationshipStatus(
  relationship.civilPartner,
  'V',
  messages.MUST_BE_CIVIL_PARTNER,
);
