import React from 'react';
import { ThemedRadioGroupInput, ThemedErrorMessage } from 'ageas-ui-components';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';
import ClearOnInvalid from '../ClearOnInvalid/ClearOnInvalid';
import StandardHint from '../../StandardHint/StandardHint';

// For documentation see:
// /docs/components/Forms/StandardRadio/StandardRadio.md

const RadioContainer = styled.fieldset`
  border: 0px;
`;

const StandardRadio = ({
  name,
  title,
  secondaryTitle,
  validate,
  options,
  alwaysShowError,
  children,
  onChange,
  fieldProps,
  blockProps,
  labelProps,
  hint,
  autoClearOnInvalid,
  hintProps,
  ...props
}) => {
  const onRadioChange = (event, input) => {
    input.onChange(event?.target?.value);
    onChange(event);
  };

  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input, meta }) => (
        <>
          {autoClearOnInvalid && (
            <ClearOnInvalid
              value={input.value}
              options={options}
              onChange={() => onRadioChange(undefined, input)}
            />
          )}
          <RadioContainer>
            {title && (
              <legend>
                {title}
                {hint && (
                  <StandardHint
                    field={input.name}
                    hint={hint}
                    hintProps={hintProps}
                  />
                )}
                {secondaryTitle && (
                  <div>
                    <small>{secondaryTitle}</small>
                  </div>
                )}
              </legend>
            )}
            <FieldInnerBlockContainer {...blockProps}>
              <ThemedRadioGroupInput
                {...props}
                {...input}
                fieldName={input.name}
                options={options}
                onChange={event => {
                  onRadioChange(event, input);
                }}
                selectedValue={input.value}
              />
            </FieldInnerBlockContainer>
          </RadioContainer>
          {meta.error && (meta.touched || alwaysShowError) && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardRadio.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryTitle: PropTypes.string,
  validate: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  alwaysShowError: PropTypes.bool,
  children: PropTypes.node,
  fieldProps: PropTypes.shape({}),
  blockProps: PropTypes.shape({}),
  small: PropTypes.bool,
  labelProps: PropTypes.shape({}),
  hint: PropTypes.node,
  autoClearOnInvalid: PropTypes.bool,
  hintProps: PropTypes.instanceOf(Object),
};

StandardRadio.defaultProps = {
  title: '',
  secondaryTitle: undefined,
  validate: () => {},
  options: [],
  alwaysShowError: undefined,
  children: undefined,
  onChange: () => {},
  fieldProps: {},
  blockProps: {},
  small: true,
  labelProps: {},
  hint: undefined,
  autoClearOnInvalid: false,
  hintProps: {},
};

export default StandardRadio;
