import React from 'react';
import FaqP from '../../FaqP';
import ButtonLink from '../../../ThemedButtonLink/ThemedButtonLink';
import { nestingControlPropTypes } from '../../../NestedAccordion/accordionNestingProp';

export const howLongAccidentalDamageTitle =
  'How long will my accidental damage claim take?';
const HowLongAccidentalDamageContent = ({ nestingControl }) => {
  return (
    <>
      <FaqP>This will depend on the circumstances of your claim.</FaqP>
      <FaqP>
        To validate a claim, we need confirmation of the cause of the damage,
        from either your chosen repairer, or we may decide to instruct one of
        our repair networks to inspect the damage.
      </FaqP>
      <FaqP>
        For more information, please refer to the section{' '}
        <ButtonLink
          primary
          onClick={() =>
            nestingControl?.setForcePath([
              'I have a question about a claim',
              'Accidental damage',
              'What should I expect when making a claim for accidental damage?',
            ])
          }
        >
          &apos;What should I expect when making a claim for accidental
          damage?&apos;
        </ButtonLink>
      </FaqP>
    </>
  );
};

HowLongAccidentalDamageContent.propTypes = {
  nestingControl: nestingControlPropTypes,
};
HowLongAccidentalDamageContent.defaultProps = {
  nestingControl: undefined,
};

export default HowLongAccidentalDamageContent;
