import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemedErrorMessage } from 'ageas-ui-components';

const Container = styled.div`
  padding-bottom: 25px;
`;

const SummaryIncompleteError = ({ isValid, ...props }) => {
  return (
    <>
      {!isValid && (
        <Container>
          <ThemedErrorMessage {...props}>
            This section is incomplete, please click Edit and complete any
            missing or invalid information
          </ThemedErrorMessage>
        </Container>
      )}
    </>
  );
};

SummaryIncompleteError.propTypes = {
  isValid: PropTypes.bool,
};

SummaryIncompleteError.defaultProps = {
  isValid: false,
};

export default SummaryIncompleteError;
