import React from 'react';
import PropTypes from 'prop-types';

import ConfirmUpdate from './ConfirmUpdate';
import StandardAutoComplete from '../../../components/Forms/StandardAutoComplete/StandardAutoComplete';
import ABICodeAutoComplete from '../../../components/Forms/ABICodeAutoComplete/ABICodeAutoComplete';

const AutoCompleteConfirmUpdate = ({
  title,
  yesNoName,
  updateFieldName,
  value,
  validateYesNo,
  validateField,
  validate,
  disabled,
  fieldProps = {},
}) => {
  const AutoComplete = fieldProps.url
    ? ABICodeAutoComplete
    : StandardAutoComplete;
  return (
    <ConfirmUpdate
      title={title}
      value={value}
      yesNoName={yesNoName}
      validate={validateYesNo || validate}
      disabled={disabled}
    >
      <AutoComplete
        name={updateFieldName}
        label="Please enter correct details"
        selectionMsg="Please select from the list below"
        validate={validateField || validate}
        disabled={disabled}
        {...fieldProps}
      />
    </ConfirmUpdate>
  );
};

export default AutoCompleteConfirmUpdate;

AutoCompleteConfirmUpdate.propTypes = {
  title: PropTypes.string,
  yesNoName: PropTypes.string.isRequired,
  updateFieldName: PropTypes.string.isRequired,
  value: PropTypes.string,
  validateYesNo: PropTypes.func,
  validateField: PropTypes.func,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.instanceOf(Object),
};
AutoCompleteConfirmUpdate.defaultProps = {
  title: undefined,
  value: undefined,
  validateYesNo: undefined,
  validateField: undefined,
  validate: undefined,
  disabled: undefined,
  fieldProps: undefined,
};
