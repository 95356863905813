import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import EclaimsRoute from '../EclaimsRoute';
import { HomeClaimsHistoryAccidentalDamage } from '../../views/HomeClaimsHistory/HomeClaimsHistory';
import { PersonalLossClaimAccidentalDamage } from '../../views/PersonalLossClaim/PersonalLossClaim';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  AccidentalDamageContext,
  localStorageAccidentalDamagePropertyName,
} from '../../helpers/personalLossContextHelper';
import { PersonalLossResultAccidentalDamage } from '../../views/PersonalLossResult/PersonalLossResult';
import PersonalLossThankYouPage from '../../views/PersonalLossThankYouPage/PersonalLossThankYouPage';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import { perilRoutes } from './HomeClaimRoutePaths';

const homeClaimsPrivateProps = {
  private: true,
  authTypes: ['otp', 'basic'],
  lobs: 'home',
};
const AccidentalDamageClaimRoutes = () => {
  return (
    <DataContextProvider
      Context={AccidentalDamageContext}
      localStoragePropertyName={localStorageAccidentalDamagePropertyName}
      localStorageExpiryDays={
        config.client.localStorageAccidentalDamageExpiryDays
      }
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path={perilRoutes.AD.history}
          component={HomeClaimsHistoryAccidentalDamage}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.AD.incidentDetails}
          component={PersonalLossClaimAccidentalDamage}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.AD.results}
          component={PersonalLossResultAccidentalDamage}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.AD.thankYou}
          component={PersonalLossThankYouPage}
          {...homeClaimsPrivateProps}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default AccidentalDamageClaimRoutes;
