import React from 'react';
import FaqP from '../../FaqP';

export const claimRequirementTitle =
  'What will I need to provide in the event of a buildings claim?';
export const claimRequirementContent = (
  <>
    <FaqP>
      In the first instance we will aim to validate your claim during the first
      notification call, using video call and repair estimate scoping tool
      technology.
    </FaqP>
    <FaqP>
      Where this is not possible you may be asked to provide pictures of the
      damage, a quote to repair or replace, a report confirming the cause of the
      damage, receipts or proof of purchase in some cases and/or replacement
      costs from websites.
    </FaqP>
    <FaqP>
      You may also be required to provide measurements of the damaged area(s).
    </FaqP>
    <FaqP>
      If an approved repairer is appointed to deal with your claim, you might
      not be required to provide the above items as they will attend to a number
      of these for you.
    </FaqP>
  </>
);
