import React, { useState } from 'react';
import { Panel, Paragraph, Button } from 'ageas-ui-components';
import styled from 'styled-components';
import { withRouter, Redirect } from 'react-router-dom';

const PanelContents = styled.div`
  padding: 40px;
  padding-bottom: 0;
`;

const bodyTopText = `Sorry, there seems to be a problem with this page. It's possible the page you're looking for might have been moved, updated, or deleted.`;
const bodyBottomText = `Let's get you back on track. To return to your homepage, please click below.`;
const buttonText = `Return to my homepage`;

const NotFoundErrorView = () => {
  const [redirectToRoot, setRedirectToRoot] = useState(false);

  if (redirectToRoot === true) {
    return <Redirect to="/" />;
  }

  return (
    <Panel title="Page not found">
      <PanelContents>
        <Paragraph fontSize="m">{bodyTopText}</Paragraph>
        <Paragraph fontSize="m">{bodyBottomText}</Paragraph>
        <Button theming="primary" onClick={() => setRedirectToRoot(true)}>
          {buttonText}
        </Button>
      </PanelContents>
    </Panel>
  );
};

export default withRouter(NotFoundErrorView);
