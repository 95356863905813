import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ActionListEntry from '../ActionList/ActionListEntry';
import {
  DataContainer,
  DataLineContainer,
  DataLineItem,
} from '../ActionList/ActionList.style';
import STATUSES, {
  EDITABLE_STATUSES,
  getStatusByValue,
} from '../../helpers/statementBuilder';
import HubStatementBuilderModal from './HubStatementBuilderModal';
import {
  sanitiseText,
  statementRequestPropType,
} from './hubStatementBuilderHelper';
import {
  BasicSection,
  SubTextCore,
} from '../StandardPageWrapper/StandardPageWrapperStyle';
import uniqueKeyMapperFactory from '../../helpers/uniqueKeyMakerFactory';
import TelephoneLink from '../TelephoneLink/TelephoneLink';
import P from '../StandardTags/P';
import { closeNotificationsByCode } from '../../helpers/hubNotificationsHelper';
import {
  HubContextMotor,
  hubDataContextMotor,
} from '../../helpers/hubContextHelper';

const StatusSpan = styled.span`
  ${({ complete }) =>
    complete &&
    css`
      font-weight: bold;
      color: green;
    `};
`;

const StatementRequestEntry = ({ statementRequest, openModal }) => {
  const { tpName, status, injuries } = statementRequest;

  const onClick = () => {
    openModal(statementRequest);
  };

  const statusObject = useMemo(() => getStatusByValue(status), [status]);
  const complete = statusObject === STATUSES.COMPLETE;
  let buttonText;

  if (EDITABLE_STATUSES.includes(statusObject)) {
    buttonText = 'Create statement';
  }

  const { mapper } = uniqueKeyMapperFactory();

  return (
    <ActionListEntry
      onClick={buttonText && onClick}
      buttonText={buttonText}
      sideMargin="0"
    >
      <DataContainer>
        <DataLineContainer wrap="wrap">
          <DataLineItem primary shrink="1">
            Claimant: {sanitiseText(tpName)}
          </DataLineItem>
        </DataLineContainer>
        <DataLineContainer wrap="wrap">
          <DataLineItem complete={complete} shrink="1">
            Status:{' '}
            <StatusSpan complete={complete}>
              {sanitiseText(statusObject?.label, '-Status:unknown-')}
            </StatusSpan>
          </DataLineItem>
        </DataLineContainer>

        <DataLineContainer wrap="wrap">
          <DataLineItem shrink="1">
            Alleged injury:{!injuries?.length && ' Unknown'}
          </DataLineItem>
        </DataLineContainer>

        {injuries?.map(({ type, location }) => (
          <DataLineContainer key={mapper(type + location)} wrap="wrap">
            <DataLineItem shrink="1">
              {sanitiseText(type)} - {sanitiseText(location)}
            </DataLineItem>
          </DataLineContainer>
        ))}
      </DataContainer>
    </ActionListEntry>
  );
};

StatementRequestEntry.propTypes = {
  statementRequest: statementRequestPropType.isRequired,
  openModal: PropTypes.func.isRequired,
};

const HubStatementBuilder = ({
  claimReference,
  statementRequests = [],
  setStatementRequests,
  piHandlerTelephone = '-NotFound-',
  Context = HubContextMotor,
}) => {
  const hubContextMotor = useContext(Context);
  const [modalStatementRequestSequence, setModalStatementRequestSequence] =
    useState(undefined);

  // Open modal for a specific statement request
  const openModal = statementRequest => {
    setModalStatementRequestSequence(statementRequest.sequenceNumber);
  };
  const closeModal = () => {
    setModalStatementRequestSequence(undefined);
  };

  // call on completion of statement
  const onSave = sequenceNumber => {
    // Clone statementRequests array, update this entry to processing status
    const newRequests = [...statementRequests];
    const i = newRequests.findIndex(
      statementRequest => sequenceNumber === statementRequest.sequenceNumber,
    );
    if (i > -1) {
      newRequests[i] = { ...newRequests[i], status: STATUSES.PROCESSING.value };
      setStatementRequests(newRequests);
    }

    // if no more New status entries, close notification
    if (
      !newRequests.some(request =>
        EDITABLE_STATUSES.includes(getStatusByValue(request.status)),
      )
    ) {
      closeNotificationsByCode(hubContextMotor, hubDataContextMotor, 'SB');
    }
  };

  if (!statementRequests?.length) {
    return (
      <SubTextCore>
        You currently have no outstanding statement requests.
      </SubTextCore>
    );
  }

  return (
    <>
      {/* Modal goes here */}
      {modalStatementRequestSequence !== undefined && (
        <HubStatementBuilderModal
          claimReference={claimReference}
          sequenceNumber={modalStatementRequestSequence}
          onSave={onSave}
          closeModal={closeModal}
          piHandlerTelephone={piHandlerTelephone}
        />
      )}

      <BasicSection>
        <SubTextCore>
          <P>Welcome to our statement builder.</P>
          <P>
            We&apos;ve been made aware of injuries to someone as a result of the
            accident relating to this claim and we&apos;re going to ask you a
            series of questions about the accident to create your statement of
            truth, which our file handler will check before submitting to the
            independent medical expert to consider.
          </P>
          <P>
            <strong>
              Please answer the questions as honestly as you can and take care
              to read the declaration at the end before signing.
            </strong>
          </P>
          <P>
            If you change your mind at any time and would prefer to draft your
            statement with one of our team, please contact us on{' '}
            <TelephoneLink number={piHandlerTelephone} suffix="." />
          </P>
        </SubTextCore>
      </BasicSection>
      <BasicSection>
        <SubTextCore>Statement requests:</SubTextCore>
      </BasicSection>
      {statementRequests?.map(statementRequest => (
        <StatementRequestEntry
          key={statementRequest.sequenceNumber}
          statementRequest={statementRequest}
          openModal={openModal}
        />
      ))}
    </>
  );
};

export default HubStatementBuilder;

HubStatementBuilder.propTypes = {
  claimReference: PropTypes.string.isRequired,
  statementRequests: PropTypes.arrayOf(statementRequestPropType),
  setStatementRequests: PropTypes.func.isRequired,
  piHandlerTelephone: PropTypes.string,
  Context: PropTypes.instanceOf(Object),
};
HubStatementBuilder.defaultProps = {
  statementRequests: undefined,
  piHandlerTelephone: undefined,
  Context: undefined,
};
