import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  HeaderContainer,
  IconContainer,
  SubText,
  SummaryContainer,
  ToggleIcon,
} from '../../StandardPageWrapper/StandardPageWrapperStyle';
import arrowIcon from '../../../assets/icons/weatherIcons/ui_icon_arrow_open.svg';
import SupplierMilestoneDetails from './SupplierMilestoneDetails';
import NewUpdatesStyle from './NewUpdatesStyle';

const SupplierDetails = ({
  supplier,
  isOpen = false,
  expandNewSupplierUpdates,
  closeNotification,
}) => {
  const [open, setOpen] = useState(isOpen);

  const hasNewUpdates =
    !isEmpty(supplier.milestones) &&
    supplier.milestones.some(milestone => milestone.read === false);

  const toggleOpen = () => {
    setOpen(prevOpen => {
      if (!prevOpen && hasNewUpdates) closeNotification();
      return !prevOpen;
    });
  };

  useEffect(() => {
    if (hasNewUpdates && expandNewSupplierUpdates) setOpen(true);
  }, [hasNewUpdates, expandNewSupplierUpdates]);

  return (
    <>
      <SummaryContainer>
        <HeaderContainer>
          <SubText>
            <strong>{supplier.name}</strong>: {supplier.telephoneNumber}
            {hasNewUpdates && (
              <NewUpdatesStyle>New&nbsp;updates</NewUpdatesStyle>
            )}
          </SubText>
        </HeaderContainer>
        <IconContainer>
          {!isEmpty(supplier.milestones) && (
            <ToggleIcon
              open={open}
              src={arrowIcon}
              alt="chevron"
              data-testid="chevron-icon"
              onClick={toggleOpen}
            />
          )}
        </IconContainer>
      </SummaryContainer>
      {open && !isEmpty(supplier.milestones) && (
        <>
          {supplier.milestones
            .sort((a, b) => b.sequence - a.sequence)
            .map(milestone => (
              <SupplierMilestoneDetails
                key={milestone.sequence}
                milestone={milestone}
              />
            ))}
        </>
      )}
    </>
  );
};

export default SupplierDetails;

SupplierDetails.propTypes = {
  supplier: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    telephoneNumber: PropTypes.string,
    milestones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isOpen: PropTypes.bool,
  expandNewSupplierUpdates: PropTypes.bool,
  closeNotification: PropTypes.func,
};
SupplierDetails.defaultProps = {
  supplier: undefined,
  isOpen: undefined,
  expandNewSupplierUpdates: undefined,
  closeNotification: undefined,
};
