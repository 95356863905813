import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';

export const carpetTimelineSchema = carpetTimelineFieldName => {
  return {
    [carpetTimelineFieldName]: stringRequired(
      [carpetTimelineFieldName],
      'Please select an age',
    ),
  };
};

const CARPET_TIMELINE_LIST = [
  {
    value: 'zeroToFiveYearsOld',
    label: '0-5 years old',
  },
  {
    value: 'fiveToTenYearsOld',
    label: '5-10 years old',
  },
  {
    value: 'overTenYearsOld',
    label: 'Over 10 years old',
  },
];

const CarpetTimeline = ({ name, ...rest }) => {
  const CARPET_TIMELINE_FIELD_NAME = name;

  return (
    <StandardRadio
      name={CARPET_TIMELINE_FIELD_NAME}
      title="How old is the carpet?"
      options={CARPET_TIMELINE_LIST}
      medium
      vertical
      {...rest}
    />
  );
};

CarpetTimeline.propTypes = {
  name: PropTypes.string,
};
CarpetTimeline.defaultProps = {
  name: undefined,
};

export default CarpetTimeline;
