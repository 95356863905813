import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { Form } from 'react-final-form';
import {
  Input,
  ThemedInput,
  ErrorMessage,
  ThemedLabel,
  ThemedButton,
} from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { email } from 'ageasvalidation/lib/schemas/Email';
import { ukTelephoneNumber } from 'ageasvalidation/lib/schemas/Telephone';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import PropTypes from 'prop-types';
import { optionsType } from 'ageas-ui-components/lib/types';
import mobileTelephoneValidationRegex from '../../helpers/mobileTelephoneValidationRegex';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import FieldStyled from '../../components/Forms/FieldStyled/FieldStyled';
import { validateField } from '../../helpers/validationHelper';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import ThemedLinkButton from '../../components/ThemedLinkButton/ThemedLinkButton';
import {
  MotorFormContext,
  formNames,
} from '../../helpers/motorFormStateHelper';
import { policyName, PolicyContext } from '../../helpers/policyContextHelper';
import driverListAxiosFunction from '../../helpers/axiosFunctions/driverListAxios';
import ContextAxios from '../../components/ContextAxios/ContextAxios';
import DriverSelectField, { OTHER_UNKNOWN_DRIVER } from './DriverSelectField';
import BreadCrumb from '../../helpers/MotorClaimBreadcrumb';
import StandardRadio from '../../components/Forms/StandardRadio/StandardRadio';

const Theme = getTheme(brand);
const QuestionStyle = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  font-weight: normal;
  font-size: 21px;
  margin-bottom: 12px;
`;

const Styling = styled.div`
  @media only screen and (max-width: 768px) {
    margin-right: -4px;
  }
`;

const PannelContent = styled.div`
  text-align: left;
  max-width: 1100px;
  margin: auto;
`;
const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const MotorContactDetail = ({
  fieldNamePrimary,
  radioGrouptitle,
  radioOptions,
  formUpdateId,
  homeUpdateId,
  mobileUpdateId,
  smsUpdateId,
  emailUpdateId,
}) => {
  const { dataState, updateDataState } = useContext(MotorFormContext);
  const [pageFormState, setPageFormState] = useState({});
  const [navigatePath, setNavigatePath] = useState(null);
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const driverListContext = { ...policyDataContext.driverList };
  const incidentDetailsContext = { ...dataState?.motorIncidentDetail };

  // On mount
  useEffect(() => {
    // We need RFF Form initial values to not change, so cannot populate direct
    // from context. So clone to local state variable on mount and default
    // using that
    setPageFormState({ ...dataState.motorContactDetail });
    window.scrollTo(0, 0);
  }, []);

  const schema = {
    email: stringRequired(['email'], 'Please enter an Email address').concat(
      email(['email'], 'Please enter a valid Email address'),
    ),
    smsUpdate: stringRequired(
      ['smsUpdate'],
      'Please select one of the options',
    ),
    driver: stringRequired(
      ['driver'],
      'Please select one of the driver options',
    ),
  };
  const optionalSchema = {
    mobileTelephone: stringRequired(
      ['mobileTelephone'],
      'Please enter a valid Mobile Telephone number',
    ).concat(
      regularExpression(
        mobileTelephoneValidationRegex,
        ['mobileTelephone'],
        'Please enter a valid Mobile Telephone number',
      ),
    ),
    homeTelephone: ukTelephoneNumber(
      ['homeTelephone'],
      'Please enter a valid Home Telephone number',
    ),
  };

  const validateMotorContact = (value, allValues, meta) => {
    let error;

    // Standard validation - mandatory fields
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return error;
      }
    }

    // Standard validation - optional fields
    if (value && optionalSchema[meta.name]) {
      error = validateField(value, meta, optionalSchema);
      if (error) {
        return error;
      }
    }

    // Custom validation (combinations etc.)

    // Must provide one of the phone numbers
    if (
      meta.name === 'mobileTelephone' &&
      !value?.trim() &&
      !allValues?.homeTelephone?.trim()
    ) {
      return 'Please tell us your Home or Mobile telephone number';
    }

    return undefined;
  };

  const onSubmit = values => {
    // Form is valid, save context and navigate to next page
    const stateValues = { ...values, formIsValid: true };
    updateDataState(formNames.motorContactDetail, stateValues);
    setNavigatePath('/motorclaim/involvedpartiesdetail');
  };

  const beforeSubmit = (event, values) => {
    const stateValues = { ...values };
    delete stateValues.formIsValid;
    updateDataState(formNames.motorContactDetail, stateValues);
  };

  const driverDropdownIsOk = values => {
    return (
      driverListContext.status === 'loaded' &&
      driverListContext?.data?.length &&
      values.driver !== OTHER_UNKNOWN_DRIVER
    );
  };
  const showContinue = values => {
    return driverDropdownIsOk(values);
  };

  return (
    <ThemeProvider theme={Theme}>
      <BrandHeader />
      {/* <BreadCrumb /> */}
      <BreadCrumb activeItem="Contact" />
      <main>
        <NavigateTo path={navigatePath} />
        <QuestionStyle>
          <PannelContent>
            <StandardPanel primary title="Contact details" padding="23px 21px">
              <Form onSubmit={onSubmit} initialValues={pageFormState}>
                {({ handleSubmit, /* submitting, */ values }) => (
                  <form
                    method="post"
                    data-testid={formUpdateId}
                    onSubmit={handleSubmit}
                    onSubmitCapture={event => beforeSubmit(event, values)}
                  >
                    <ContextAxios
                      keyList={[
                        incidentDetailsContext.incidentDate,
                        incidentDetailsContext.incidentTime,
                        incidentDetailsContext.policyNumber,
                      ]}
                      axiosFunction={driverListAxiosFunction}
                      dataContextObject={PolicyContext}
                      dataContextName={policyName.driverList}
                    />

                    <Heading>
                      Please enter <strong>at least one phone number</strong>{' '}
                      and an email address
                    </Heading>
                    <FieldStyled
                      name="homeTelephone"
                      validate={validateMotorContact}
                    >
                      {({ input, meta }) => (
                        <>
                          <ThemedLabel>Home telephone number</ThemedLabel>
                          <ThemedInput
                            stretchOnNarrow
                            xSmall
                            data-testid={homeUpdateId}
                            {...input}
                            invalidInput={{
                              isInvalid: meta.error && meta.touched,
                            }}
                            maxLength="20"
                          />
                          {meta.error && meta.touched && (
                            <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                          )}
                        </>
                      )}
                    </FieldStyled>
                    <FieldStyled
                      name="mobileTelephone"
                      validate={validateMotorContact}
                    >
                      {({ input, meta }) => (
                        <>
                          <ThemedLabel>Mobile telephone number</ThemedLabel>
                          <ThemedInput
                            stretchOnNarrow
                            xSmall
                            data-testid={mobileUpdateId}
                            {...input}
                            invalidInput={{
                              isInvalid: meta.error && meta.touched,
                            }}
                            maxLength="20"
                          />
                          {meta.error && meta.touched && (
                            <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                          )}
                        </>
                      )}
                    </FieldStyled>
                    {values.mobileTelephone && (
                      <StandardRadio
                        medium
                        vertical
                        name={fieldNamePrimary}
                        validate={validateMotorContact}
                        data-testid={smsUpdateId}
                        title={radioGrouptitle}
                        options={radioOptions}
                      />
                    )}
                    <FieldStyled name="email" validate={validateMotorContact}>
                      {({ input, meta }) => (
                        <>
                          <ThemedLabel>Email address*</ThemedLabel>
                          <Input
                            data-testid={emailUpdateId}
                            size="small"
                            {...input}
                            invalidInput={{
                              isInvalid: meta.error && meta.touched,
                            }}
                          />
                          {meta.error && meta.touched && (
                            <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                          )}
                        </>
                      )}
                    </FieldStyled>
                    <FieldStyled name="driver" validate={validateMotorContact}>
                      {({ input, meta }) => (
                        <>
                          <Styling>
                            <Heading>
                              Who was the last driver of the vehicle?*
                            </Heading>
                          </Styling>
                          <DriverSelectField
                            {...input}
                            fieldName={input.name}
                            id={input.name}
                            selectedOption={input.value}
                            onChange={input.onChange}
                            errorMsg={
                              meta.error && meta.touched
                                ? meta.error
                                : undefined
                            }
                            maxButtons={4}
                            dataContextObject={PolicyContext}
                            dataContextName={policyName.driverList}
                          />
                        </>
                      )}
                    </FieldStyled>
                    <BottomButtonsContainer>
                      <ThemedLinkButton
                        secondary
                        inverted
                        small
                        to="/motorclaim/incidentdetails"
                      >
                        Back
                      </ThemedLinkButton>
                      {showContinue(values) && (
                        <ThemedButton secondary small type="submit">
                          Continue
                        </ThemedButton>
                      )}
                    </BottomButtonsContainer>
                  </form>
                )}
              </Form>
            </StandardPanel>
          </PannelContent>
        </QuestionStyle>
      </main>
      <BrandFooter />
    </ThemeProvider>
  );
};

MotorContactDetail.propTypes = {
  fieldNamePrimary: PropTypes.string,
  radioGrouptitle: PropTypes.string,
  radioOptions: optionsType,
  formUpdateId: PropTypes.string,
  homeUpdateId: PropTypes.string,
  mobileUpdateId: PropTypes.string,
  smsUpdateId: PropTypes.string,
  emailUpdateId: PropTypes.string,
};
MotorContactDetail.defaultProps = {
  formUpdateId: 'formUpdateId',
  homeUpdateId: 'homeUpdateId',
  mobileUpdateId: 'mobileUpdateId',
  smsUpdateId: 'smsUpdateYesNo',
  emailUpdateId: 'emailUpdateId',
  fieldNamePrimary: 'smsUpdate',
  radioGrouptitle: 'Are you happy to receive SMS updates?*',
  radioOptions: [
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
  ],
};

export default MotorContactDetail;
