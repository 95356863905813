import * as ageasvalidation from 'ageasvalidation';

const getFieldError = (result, fieldName) => {
  const fieldError = result.error.details.find(
    detail => detail.context.key === fieldName,
  );
  if (fieldError) {
    return fieldError.message;
  }
  return undefined;
};

export const validateField = (value, meta, schema, legacy = true) => {
  /* istanbul ignore next  */
  if (
    legacy &&
    ((meta.name === 'homeTelephone' && value === undefined) ||
      (meta.name === 'mobileTelephone' && value === undefined))
  ) {
    return null;
  }

  const result = ageasvalidation.individualValidator(value, schema, meta.name);
  if (result.error === null) {
    return undefined;
  }

  return getFieldError(result, meta.name);
};

export default validateField;
