import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import axios from 'axios';

import GenericMotorFormPage from './GenericMotorFormPage';

import { MotorClaimContextNew } from '../../helpers/motorClaimHelperNew';
import { PolicyContext } from '../../helpers/policyContextHelper';
import motorContinuableClaimFetchPolicyData from '../../helpers/motorContinuableClaimFetchPolicyData';
import { dmyAndHmsToIso } from '../../helpers/dateTimeConverter';

const GenericMotorFormPageWithPolicyFetch = ({
  renderForm = true,
  spinner = false,
  showContinue = true,
  serverError = false,
  setFieldDefaultsFromPolicyContext,
  children,
  ...rest
}) => {
  const [render, setRender] = useState(false);
  const [serverErrorInternal, setServerErrorInternal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { dataState } = useContext(MotorClaimContextNew);
  const {
    dataState: policyDataContext,
    updateDataState: updatePolicyDataContext,
  } = useContext(PolicyContext);
  const policyDetails = { ...policyDataContext.policyDetails };
  const axiosCancelToken = useRef(null);
  const historyHook = useHistory();

  const createAxiosCancelToken = () => {
    axiosCancelToken.current = axios.CancelToken.source();
    return axiosCancelToken.current;
  };

  // On page load
  useEffect(() => {
    const { claimReference, policyReference } = dataState?.claimDetails || {};
    const { incidentDate, incidentTime, vehicleRegistration } =
      dataState?.claimEntryData?.incidentDetails || {};
    if (
      !claimReference ||
      !policyReference ||
      !incidentDate ||
      !incidentTime ||
      !vehicleRegistration
    ) {
      historyHook.push('/motorclaim/history');
      return undefined;
    }
    setRender(true);

    // Default fields from policy details, requesting policy details from server
    // if needed
    const cancelToken = createAxiosCancelToken().token;
    motorContinuableClaimFetchPolicyData(
      policyReference,
      policyDetails,
      dmyAndHmsToIso(incidentDate, incidentTime),
      vehicleRegistration,
      updatePolicyDataContext,
      cancelToken,
      setFieldDefaultsFromPolicyContext,
    )
      .then(policyDetailsNew => {
        if (policyDetailsNew) {
          setIsLoading(false);
          // Ensure vehicle details returned are for the one requested
          if (
            policyDetailsNew?.data?.vehicles?.length !== 1 ||
            policyDetailsNew.data.vehicles[0].vrn !== vehicleRegistration ||
            !policyDetailsNew?.data?.drivers?.length
          ) {
            setServerErrorInternal(true);
          }
        }
      })
      .catch(() => {
        setServerErrorInternal(true);
        setIsLoading(false);
      });

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  if (!render) {
    return null;
  }

  return (
    <GenericMotorFormPage
      spinner={isLoading || spinner}
      renderForm={!isLoading && renderForm}
      serverError={serverErrorInternal || serverError}
      showContinue={!serverErrorInternal && showContinue}
      {...rest}
    >
      {children}
    </GenericMotorFormPage>
  );
};

export default GenericMotorFormPageWithPolicyFetch;

GenericMotorFormPageWithPolicyFetch.propTypes = {
  ...GenericMotorFormPage.propTypes,
  setFieldDefaultsFromPolicyContext: PropTypes.func,
};

GenericMotorFormPageWithPolicyFetch.defaultProps = {
  ...GenericMotorFormPage.defaultProps,
  setFieldDefaultsFromPolicyContext: undefined,
};
