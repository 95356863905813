import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Icon } from 'ageas-ui-components';
import {
  StatusContainer,
  MessageContainer,
  MessageInner,
  MessageBody,
  Badge,
  RIGHT_COLOUR,
} from './HubMessenger.style';

const SENDERS = {
  client: (
    <Icon brandIconSet="ageas" color={RIGHT_COLOUR} icon="person" size={35} />
  ),
  ageas: 'ageas',
};

const formatTime = time => {
  return DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE);
};

const Message = ({ messageData }) => {
  const { id, sender, message, status, time } = messageData;

  const left = sender !== 'client';
  let size;
  if (!message || message.length <= 100) {
    size = 'small';
  } else if (message.length <= 200) {
    size = 'medium';
  } else {
    size = 'large';
  }

  return (
    <MessageContainer left={left} status={status} data-message-id={id}>
      <StatusContainer status={status}>
        {time && formatTime(time)}
      </StatusContainer>
      <MessageInner left={left} status={status} size={size}>
        <MessageBody left={left} status={status} size={size}>
          {message}
        </MessageBody>
        <Badge left={left} status={status}>
          {SENDERS[sender] || sender}
        </Badge>
      </MessageInner>
    </MessageContainer>
  );
};

export default Message;

Message.propTypes = {
  messageData: PropTypes.shape({
    id: PropTypes.number,
    sender: PropTypes.string,
    message: PropTypes.string,
    status: PropTypes.string,
    time: PropTypes.string,
  }).isRequired,
};
