import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { benefitsBanner } from '../../../../data/whitelabel.config';
import {
  Container,
  Text,
  BenefitsWrapper,
  BoldText,
  Icon,
  InnerContainer,
} from './BenefitsBanner.style';

const BenefitsBanner = ({ location }) => {
  const { activeList, benefits } = benefitsBanner;

  const isActive = activeList.includes(location.pathname);

  const allBenefits = benefits.map((benefit, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <BenefitsWrapper key={index}>
      <div>
        <Icon icon="tick" size={32} />
      </div>
      <div>
        <BoldText>{benefit.title}</BoldText>
        <Text>{benefit.description}</Text>
      </div>
    </BenefitsWrapper>
  ));

  if (isActive) {
    return (
      <Container>
        <InnerContainer>{allBenefits}</InnerContainer>
      </Container>
    );
  }
  return null;
};

BenefitsBanner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(props => <BenefitsBanner {...props} />);
