import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ThemedButton } from 'ageas-ui-components';
import { Form } from 'react-final-form';
import StandardPopup from '../../StandardPopup/StandardPopup';
import { PageContainer } from '../../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';

const buttonsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    props: PropTypes.instanceOf(Object),
  }),
);

const MappedButtons = ({ buttons }) => {
  return buttons.map(({ text, ...props }) => (
    <ThemedButton
      small
      primary
      inverted
      focusHighlight
      type="button"
      {...props}
      key={text}
    >
      {text}
    </ThemedButton>
  ));
};
MappedButtons.propTypes = {
  buttons: buttonsPropType.isRequired,
};

export const ModalButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  row-gap: 10px;
  flex-wrap: wrap;
  & > * {
    margin: 0;
  }
  ${({ fullWidthButtons }) =>
    fullWidthButtons &&
    css`
      flex-direction: column;
      & > * {
        width: 100%;
      }
    `}
`;

const ModalForm = ({
  initialValues = {},
  children,
  onSubmit,
  onCancel,
  title,
  saveDisabled = false,
  renderForm = true,
  buttonsMid,
  fullWidthButtons = false,
  saveButtonText = 'Save',
  focusLockProps,
}) => {
  const fieldUpdateMutator = ([fieldName, value], state, utils) => {
    utils.changeValue(state, fieldName, () => value);
  };

  const onFormSubmit = values => {
    return saveDisabled ? undefined : onSubmit(values);
  };

  return (
    <StandardPopup
      modalProps={{
        withHeader: !!title,
        headerText: title,
        withClose: true,
        onCloseClick: onCancel,
      }}
      focusLockProps={focusLockProps}
    >
      <PageContainer>
        {renderForm && (
          <Form
            onSubmit={onFormSubmit}
            mutators={{ fieldUpdateMutator }}
            initialValues={initialValues}
            subscription={{}}
          >
            {({ handleSubmit }) => (
              <form method="post" onSubmit={handleSubmit}>
                {children}
                <ModalButtonsContainer fullWidthButtons={fullWidthButtons}>
                  <ThemedButton
                    small
                    primary
                    focusHighlight
                    type="submit"
                    disabled={saveDisabled}
                  >
                    {saveButtonText}
                  </ThemedButton>

                  {buttonsMid?.length && <MappedButtons buttons={buttonsMid} />}

                  <ThemedButton
                    small
                    primary
                    inverted
                    focusHighlight
                    type="button"
                    onClick={onCancel}
                  >
                    Cancel
                  </ThemedButton>
                </ModalButtonsContainer>
              </form>
            )}
          </Form>
        )}
      </PageContainer>
    </StandardPopup>
  );
};

export default ModalForm;

ModalForm.propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  saveDisabled: PropTypes.bool,
  renderForm: PropTypes.bool,
  buttonsMid: buttonsPropType,
  fullWidthButtons: PropTypes.bool,
  saveButtonText: PropTypes.string,
  focusLockProps: PropTypes.instanceOf(Object),
};

ModalForm.defaultProps = {
  initialValues: undefined,
  children: undefined,
  onSubmit: () => {},
  onCancel: () => {},
  title: undefined,
  saveDisabled: undefined,
  renderForm: undefined,
  buttonsMid: undefined,
  fullWidthButtons: undefined,
  saveButtonText: undefined,
  focusLockProps: undefined,
};
