import React from 'react';

export const localStorageStormPropertyName = 'stormClaimFormState';

export const stormDamageDataContext = {
  policyDetails: 'policyDetails',
  weatherData: 'weatherData',
  weatherIncidentDate: 'weatherIncidentDate',
  userContactNumber: 'userContactNumber',
  detailsCompleted: 'detailsCompleted',
};

export const StormContext = React.createContext({});
