import React from 'react';
import PropTypes from 'prop-types';
import { ThemedHyperlink } from 'ageas-ui-components';
import { TitleText } from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../../components/StandardTags/P';
import { HomeClaimsFNOL } from '../../helpers/ageasTelephoneNumbers';
import { businessHoursDescriptive } from '../../helpers/businessHoursData';
import config from '../../helpers/config';
import formatAmount from '../../helpers/formatAmount';

const formatDescription = description => (description ? `${description} ` : '');

const renderHomeHubText = () => (
  <P>
    If you have any questions relating to your claim, want to upload any
    documents to support your claim or want to check the progress, the quickest
    and easiest way to do this is by logging on to the claims hub{' '}
    <ThemedHyperlink
      primary
      href={config.client.homeHubUrl}
      text={config.client.homeHubUrl}
    />
    .
  </P>
);

export const WithinXSExcessText = ({ claimReference, applicableExcess }) => {
  return (
    <>
      <P>
        As your policy has an excess of <strong>£{applicableExcess}</strong>,
        which is the amount you have to pay towards the cost of your claim, we
        are unable to process your claim as the value you have specified to
        replace the food is within your excess amount.
      </P>
      <P>
        A reference of <strong>{claimReference}</strong> has been allocated to
        this incident.
      </P>
      <P>
        Please call the claims team on <HomeClaimsFNOL /> between the hours of{' '}
        {businessHoursDescriptive} if you wish to discuss the matter further.
      </P>
      <P>We are sorry we cannot be of any assistance on this occasion.</P>
    </>
  );
};
WithinXSExcessText.propTypes = {
  claimReference: PropTypes.string.isRequired,
  applicableExcess: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export const ExcessNoAcceptText = ({ claimReference }) => {
  return (
    <>
      <TitleText>
        Thank you for confirming you no longer wish to continue with this claim.
      </TitleText>
      <strong>
        <P>What happens next?</P>
      </strong>

      <P>
        A reference of <strong>{claimReference}</strong> has been allocated to
        this incident.
      </P>
      <P>
        If you decide to continue with this claim, please call the claims team
        on{' '}
        <strong>
          <HomeClaimsFNOL />
        </strong>{' '}
        between the hours of {businessHoursDescriptive}.
      </P>
    </>
  );
};
ExcessNoAcceptText.propTypes = {
  claimReference: PropTypes.string.isRequired,
};

export const PaymentSettlementText = ({
  itemDescription,
  claimReference,
  settlementAmount,
}) => {
  return (
    <>
      <TitleText>
        Thanks. Your {formatDescription(itemDescription)}claim has been settled.
      </TitleText>
      <strong>
        <P>What happens next?</P>
      </strong>

      <P>
        Your claim reference is <strong>{claimReference}</strong>.
      </P>
      <P>
        A payment of <strong>£{formatAmount(settlementAmount)}</strong> will be
        credited to your bank account within 3 working days.
      </P>
      <P>
        We will send confirmation of all of this together with details of your
        claim by text to the number you provided earlier.
      </P>
      <P>Thank you for making your claim.</P>
    </>
  );
};
PaymentSettlementText.propTypes = {
  claimReference: PropTypes.string.isRequired,
  settlementAmount: PropTypes.number.isRequired,
  itemDescription: PropTypes.string,
};
PaymentSettlementText.defaultProps = {
  itemDescription: undefined,
};

export const ReferredText = ({ claimReference, itemDescription }) => {
  return (
    <>
      <TitleText>
        Thanks. Your {formatDescription(itemDescription)}claim has been
        submitted for referral.
      </TitleText>
      <strong>
        <P>What happens next?</P>
      </strong>
      <P>
        Your claim reference for this incident is{' '}
        <strong>{claimReference}</strong>. One of our claims handlers will be in
        touch by the end of the next working day.
      </P>
      {renderHomeHubText()}
    </>
  );
};
ReferredText.propTypes = {
  claimReference: PropTypes.string.isRequired,
  itemDescription: PropTypes.string,
};
ReferredText.defaultProps = {
  itemDescription: undefined,
};

export const InstructSupplierText = ({
  claimReference,
  userContactNumber,
  supplierName,
  supplierNumber,
  perilDescription,
}) => {
  return (
    <>
      <TitleText>
        Thanks. Your {formatDescription(perilDescription)}claim has been
        submitted.
      </TitleText>
      <strong>
        <P>What happens next?</P>
      </strong>

      <P>
        Your claim reference is <strong>{claimReference}</strong>.
      </P>
      <P>
        We have instructed <strong>{supplierName}</strong>, who are our
        preferred specialist supplier, to assist you with the final steps of
        your claim.
      </P>
      <P>
        They will contact you on <strong>{userContactNumber}</strong> within 2
        working hours to gather more information and manage your claim on behalf
        of Ageas Insurance Ltd.
      </P>
      <P>
        Their contact details, should you need them, are{' '}
        <strong>{supplierNumber}</strong>. A text containing this information
        will be sent to you shortly.
      </P>
      {renderHomeHubText()}
      <P>Thank you for making your claim.</P>
    </>
  );
};
InstructSupplierText.propTypes = {
  claimReference: PropTypes.string.isRequired,
  userContactNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierNumber: PropTypes.string.isRequired,
  perilDescription: PropTypes.string,
};
InstructSupplierText.defaultProps = {
  perilDescription: undefined,
};

export const EndorsementReferText = ({ claimReference, perilDescription }) => {
  return (
    <>
      <TitleText>
        Thanks. Your {formatDescription(perilDescription)}claim has been
        submitted.
      </TitleText>
      <strong>
        <P>What happens next?</P>
      </strong>
      <P>
        Due to endorsements that you have on your policy, your claim will need
        to be referred for review.
      </P>
      <P>
        A reference of <strong>{claimReference}</strong> has been allocated to
        this incident. One of our claims handlers will be in touch by the end of
        the next working day.
      </P>
      {renderHomeHubText()}
    </>
  );
};
EndorsementReferText.propTypes = {
  claimReference: PropTypes.string.isRequired,
  perilDescription: PropTypes.string,
};
EndorsementReferText.defaultProps = {
  perilDescription: undefined,
};

export const EOWReferAndInstructSupplierText = ({
  claimReference,
  userContactNumber,
  supplierName,
  supplierNumber,
}) => {
  return (
    <>
      <TitleText>
        Thanks. Your escape of water claim has been submitted.
      </TitleText>
      <strong>
        <P>What happens next?</P>
      </strong>

      <P>
        Your claim reference is <strong>{claimReference}</strong>.
      </P>
      <P>
        One of our claims handlers will contact you on{' '}
        <strong>{userContactNumber}</strong> by the end of the next working day
        to discuss your contents damage.
      </P>
      <P>
        In the meantime, with regard to the buildings part of your claim, we
        have instructed <strong>{supplierName}</strong>, who are our preferred
        specialist supplier, to contact you within 2 working hours.
      </P>
      <P>
        They will gather more information and manage this part of your claim on
        behalf of Ageas Insurance Ltd.
      </P>
      <P>
        Their contact details, should you need them, are{' '}
        <strong>{supplierNumber}</strong>. A text containing this information
        will be sent to you shortly.
      </P>
      {renderHomeHubText()}
      <P>Thank you for making your claim.</P>
    </>
  );
};
EOWReferAndInstructSupplierText.propTypes = {
  claimReference: PropTypes.string.isRequired,
  userContactNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierNumber: PropTypes.string.isRequired,
};
