import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import StormDamageClaim from '../../views/StormDamageClaim/StormDamageClaim';
import EclaimsRoute from '../EclaimsRoute';
import { HomeClaimsHistoryStorm } from '../../views/HomeClaimsHistory/HomeClaimsHistory';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  StormContext,
  localStorageStormPropertyName,
} from '../../helpers/stormContextHelper';
import StormVerificationResult from '../../views/StormVerificationResult/StormVerificationResult';
import ThankYouStormClaim from '../../views/ThankYouStormClaim/ThankYouStormClaim';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';

const homeClaimsPrivateProps = {
  private: true,
  authTypes: ['otp', 'basic'],
  lobs: 'home',
};
const HomeStormClaimRoutes = () => {
  return (
    <DataContextProvider
      Context={StormContext}
      localStoragePropertyName={localStorageStormPropertyName}
      localStorageExpiryDays={config.client.localStorageStormExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path="/homeclaim/storm/history"
          component={HomeClaimsHistoryStorm}
          {...homeClaimsPrivateProps}
        />

        <EclaimsRoute
          exact
          path="/homeclaim/storm/incidentdetails"
          component={StormDamageClaim}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path="/homeclaim/storm/results"
          component={StormVerificationResult}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path="/homeclaim/storm/thankyoustormclaim"
          component={ThankYouStormClaim}
          {...homeClaimsPrivateProps}
        />
        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default HomeStormClaimRoutes;
