import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
  faDotCircle,
} from '@fortawesome/free-solid-svg-icons';
import _uniqueId from 'lodash/uniqueId';
import {
  nestingControlPropTypes,
  nestingControlDefault,
} from './accordionNestingProp';

import {
  AccordionContentHeader,
  AccordionSectionContainer,
  AccordionHeaderContainer,
  AccordionHeader,
  AccordionHeaderIconLeft,
  AccordionHeaderIconLeftActive,
  AccordionHeaderIconLeftInactive,
  AccordionHeaderIconLeft003,
  AccordionHeaderIconRight,
  AccordionHeaderText,
  AccordionBody,
} from './AccordionSection.style';

const returnToggleIcon = (accordionNestLevel, isSelected) => {
  let icon;
  if (accordionNestLevel === 1) {
    icon = (isSelected && faChevronUp) || faChevronDown;
  } else if (accordionNestLevel === 2) {
    icon = (isSelected && faMinus) || faPlus;
  }

  if (icon) {
    return (
      <AccordionHeaderIconRight
        accordionNestLevel={accordionNestLevel}
        isSelected={isSelected}
      >
        <FontAwesomeIcon icon={icon} />
      </AccordionHeaderIconRight>
    );
  }

  return <AccordionHeaderIconRight />;
};

const returnLeftIcon = (icon, activeIcon, accordionNestLevel, isSelected) => {
  // Default icon for level 3, if not overridden
  if (accordionNestLevel === 3 && !icon) {
    return (
      <AccordionHeaderIconLeft003 accordionNestLevel={accordionNestLevel}>
        <FontAwesomeIcon icon={faDotCircle} />
      </AccordionHeaderIconLeft003>
    );
  }
  // Apply single icon
  if (icon && !activeIcon) {
    return (
      <AccordionHeaderIconLeft accordionNestLevel={accordionNestLevel}>
        {icon}
      </AccordionHeaderIconLeft>
    );
  }
  // Apply paired icons
  if (icon && activeIcon) {
    return (
      <>
        <AccordionHeaderIconLeftActive
          accordionNestLevel={accordionNestLevel}
          isSelected={isSelected}
        >
          {activeIcon}
        </AccordionHeaderIconLeftActive>
        <AccordionHeaderIconLeftInactive
          accordionNestLevel={accordionNestLevel}
          isSelected={isSelected}
        >
          {icon}
        </AccordionHeaderIconLeftInactive>
      </>
    );
  }
  // No icon specified
  return null;
};

const generateIds = () => {
  const uid = _uniqueId();
  const prefix = `AccordionSection-${uid}`;
  return {
    uid,
    section: `${prefix}-Section`,
    button: `${prefix}-Control`,
    body: `${prefix}-Body`,
  };
};

const cloneChild = (child, nestingControl, index) => {
  // Child is not a valid element for cloning (e.g. is text node or undefined)
  // return as-is
  if (!React.isValidElement(child)) {
    return child;
  }

  // Basic new props - applicable to any chldren
  const newProps = {
    key: index,
  };
  // Component new props - applicable only if child is a component
  if (typeof child.type === 'function') {
    newProps.nestingControl = { ...nestingControl };
  }
  return React.cloneElement(child, newProps);
};

const cloneChildren = (children, nestingControl) => {
  // Multiple children
  if (Array.isArray(children)) {
    return children.map((child, index) => {
      return cloneChild(child, nestingControl, index);
    });
  }
  // Single child, or no child
  return cloneChild(children, nestingControl);
};

const nestingControlNextLevel = (nestingControl, isSelected) => {
  return {
    ...nestingControl,
    nestLevel: nestingControl.nestLevel + 1,
    forceClose:
      nestingControl.cascadeClose && (!isSelected || nestingControl.forceClose),
  };
};

const AccordionSection = ({
  children,
  toggle,
  isSelected,
  scrollIntoView,
  title,
  titlePaddingLeft,
  icon,
  activeIcon,
  contentHeader,
  maxHeight,
  nestingControl,
}) => {
  const headerRef = useRef(null);
  const [ids] = useState(generateIds());
  const nestingControlFull = {
    ...nestingControlDefault,
    ...nestingControl,
  };

  // Scroll into view logic
  useEffect(() => {
    if (isSelected && scrollIntoView && headerRef.current) {
      window.setTimeout(() => {
        // Sometimes headerRef can be set when setTimeout is called but not
        // when it runs, so recheck
        if (headerRef.current) {
          // Scroll into view if:
          const rect = headerRef.current.getBoundingClientRect();
          // header top is off top of screen
          if (
            rect.y < 0 ||
            // header bottom is <100px from bottom (room for some content)
            rect.bottom + 100 >=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            window.scrollTo(
              rect.x,
              rect.y +
                window.scrollY +
                nestingControlFull.autoPositionOffsetTop(),
            );
          }
        }
      }, 10); // short delay to give elements enough time to show.hide
    }
  }, [isSelected, scrollIntoView]);

  return (
    <AccordionSectionContainer
      accordionNestLevel={nestingControl.nestLevel}
      id={ids.section}
    >
      <AccordionHeaderContainer
        ref={headerRef}
        data-testid="header-container"
        accordionNestLevel={nestingControl.nestLevel}
        isSelected={isSelected}
        onClick={toggle}
      >
        <AccordionHeader
          type="button"
          onClick={toggle}
          accordionNestLevel={nestingControl.nestLevel}
          id={ids.button}
          aria-controls={ids.body}
          aria-expanded={isSelected}
        >
          {returnLeftIcon(
            icon,
            activeIcon,
            nestingControl.nestLevel,
            isSelected,
          )}
          <AccordionHeaderText
            accordionNestLevel={nestingControl.nestLevel}
            titlePaddingLeft={titlePaddingLeft}
          >
            {title}
          </AccordionHeaderText>
          {returnToggleIcon(nestingControl.nestLevel, isSelected)}
        </AccordionHeader>
      </AccordionHeaderContainer>

      <AccordionBody
        accordionNestLevel={nestingControl.nestLevel}
        isSelected={isSelected}
        id={ids.body}
        role="region"
        aria-labelledby={ids.button}
        maxHeight={maxHeight}
      >
        {contentHeader && (
          <AccordionContentHeader accordionNestLevel={nestingControl.nestLevel}>
            {contentHeader}
          </AccordionContentHeader>
        )}
        <div>
          {cloneChildren(
            children,
            nestingControlNextLevel(nestingControlFull, isSelected),
          )}
        </div>
      </AccordionBody>
    </AccordionSectionContainer>
  );
};

AccordionSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  toggle: PropTypes.func,
  isSelected: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titlePaddingLeft: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  activeIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  contentHeader: PropTypes.string,
  maxHeight: PropTypes.string,
  nestingControl: nestingControlPropTypes,
};

AccordionSection.defaultProps = {
  children: [],
  toggle: undefined,
  isSelected: false,
  scrollIntoView: false,
  titlePaddingLeft: undefined,
  icon: undefined,
  activeIcon: undefined,
  contentHeader: undefined,
  maxHeight: undefined,
  nestingControl: undefined,
};

export default AccordionSection;
