import React from 'react';
import PropTypes from 'prop-types';
import StandardDate from '../StandardDate/StandardDate';
import ABICodeAutoComplete from '../ABICodeAutoComplete/ABICodeAutoComplete';
import config from '../../../helpers/config';

const validateAutoComplete = (
  validateFields,
  { label, value } = {},
  allValues,
  meta,
) => {
  return (
    validateFields &&
    (validateFields(label, allValues, meta) ||
      validateFields(value, allValues, meta))
  );
};

const PreviousConviction = ({ fieldNames, validateFields }) => {
  return (
    <>
      <ABICodeAutoComplete
        name={fieldNames.convictionType || 'convictionType'}
        label="Please enter the DVLA code or description for the conviction below"
        url={config.client.getConvictionsList_endpoint}
        noSuggestionsMsg="No results found"
        placeholder="Type the first three characters"
        validate={(...args) => validateAutoComplete(validateFields, ...args)}
      />
      <StandardDate
        name={fieldNames.convictionDate || 'convictionDate'}
        id={fieldNames.convictionDate || 'convictionDate'}
        label="Date of conviction"
        validate={validateFields}
        maxYears={6}
      />
    </>
  );
};

PreviousConviction.propTypes = {
  fieldNames: PropTypes.instanceOf(Object),
  validateFields: PropTypes.func,
};

PreviousConviction.defaultProps = {
  fieldNames: {},
  validateFields: undefined,
};

export default PreviousConviction;
