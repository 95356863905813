import React, { useRef } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

import { ThemedLabel, ThemedErrorMessage } from 'ageas-ui-components';
import { dateRequired } from 'ageasvalidation/lib/schemas/Required';
import { stringNotEqualTo } from 'ageasvalidation/lib/schemas/String';
import { dateLessThanOrEqualTo } from 'ageasvalidation/lib/schemas/Date';

import FieldStyled from '../FieldStyled/FieldStyled';
import DateDropdownInput from '../../DateDropdownInput/DateDropdownInput';
import expandArrow from '../../../assets/expandArrow.svg';
import { luxonToDmy } from '../../../helpers/dateTimeConverter';

const requiredValidations = (
  fieldName,
  pleaseSelectMessage = 'Please select the date',
) =>
  stringNotEqualTo('Incomplete date', [fieldName], pleaseSelectMessage)
    .concat(
      stringNotEqualTo(
        'Invalid date',
        [fieldName],
        'Please select a valid date',
      ),
    )
    .concat(dateRequired([fieldName], pleaseSelectMessage));

const requiredPastValidations = (
  fieldName,
  pleaseSelectMessage,
  futureMessage = 'The date cannot be in the future',
) => {
  const dateToday = luxonToDmy(DateTime.now());
  return requiredValidations(fieldName, pleaseSelectMessage).concat(
    dateLessThanOrEqualTo(dateToday, [fieldName], futureMessage),
  );
};

export const getStandardDateRequiredSchema = (
  fieldName,
  pleaseSelectMessage,
) => {
  return {
    [fieldName]: requiredValidations(fieldName, pleaseSelectMessage),
  };
};

export const getStandardDateRequiredPastSchema = (
  fieldName,
  pleaseSelectMessage,
  futureMessage,
) => {
  return {
    [fieldName]: requiredPastValidations(
      fieldName,
      pleaseSelectMessage,
      futureMessage,
    ),
  };
};

const LabelStyling = styled.div`
  margin-bottom: -18px;
`;
const Spacer = styled.div`
  margin-top: 10px;
`;

const StandardDate = ({
  id,
  name,
  label,
  secondaryLabel,
  themedLabel = true,
  validate = () => {},
  alwaysShowError = false,
  children,
  fieldProps,
  ...props
}) => {
  const idRef = useRef(id);
  if (!idRef.current) {
    idRef.current = `StandardDate-${name}-${_uniqueId()}`;
  }

  const onDateChange = (returnedDate, input) => {
    let dateString;
    if (returnedDate === null) {
      dateString = 'Incomplete date';
      input.onFocus(dateString);
    } else if (Number.isNaN(returnedDate)) {
      dateString = 'Invalid date';
      input.onBlur(dateString);
    } else {
      dateString = luxonToDmy(DateTime.fromMillis(returnedDate));
      input.onBlur(dateString);
    }
    if (dateString !== input.value) {
      input.onChange(dateString);
    }
  };

  return (
    <FieldStyled name={name} validate={validate} {...fieldProps}>
      {({ input, meta }) => (
        <>
          {themedLabel && (
            <LabelStyling>
              <ThemedLabel fieldName={id}>
                {label}
                {secondaryLabel && (
                  <Spacer>
                    <small>{secondaryLabel}</small>
                  </Spacer>
                )}
              </ThemedLabel>
            </LabelStyling>
          )}
          <DateDropdownInput
            formInput={input}
            handleDate={value => onDateChange(value, input)}
            aria-label={label}
            inclDay
            dropdownId={idRef.current}
            dropdownIcon={expandArrow}
            placeholderDay="DD"
            placeholderMonth="MM"
            placeholderYear="YYYY"
            dateDefaultValue={input.value.split('/')[0]}
            monthDefaultValue={input.value.split('/')[1]}
            yearDefaultValue={input.value.split('/')[2]}
            {...props}
          />
          {meta.error && (meta.touched || alwaysShowError) && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

export default StandardDate;

StandardDate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  themedLabel: PropTypes.bool,
  validate: PropTypes.func,
  alwaysShowError: PropTypes.bool,
  children: PropTypes.node,
  fieldProps: PropTypes.shape({}),
};

StandardDate.defaultProps = {
  id: undefined,
  label: undefined,
  secondaryLabel: undefined,
  themedLabel: undefined,
  validate: undefined,
  fieldProps: undefined,
  alwaysShowError: undefined,
  children: undefined,
};
