import React from 'react';
import PropTypes from 'prop-types';
import RenderMessageOrOOH from '../components/RenderMessageOrOOH/RenderMessageOrOOH';
import {
  businessHoursMidweekDescriptive,
  getBusinessHoursDescriptive,
  homeReferLink,
  motorReferLink,
  businessHoursMotorTPADescriptive,
} from './businessHoursData';

import { checkBusinessHours } from './checkBusinessHours';
import {
  MotorClaimsTelLink,
  HomeClaimsFNOL,
  HomeClaimsTelLink,
  TPAssistTelLink,
} from './ageasTelephoneNumbers';
import P from '../components/StandardTags/P';
import config from './config';
import TelephoneLink from '../components/TelephoneLink/TelephoneLink';

const homeFnolOrMotorTel = lob => {
  if (lob === 'motor') {
    return <MotorClaimsTelLink />;
  }
  if (lob === 'motorTPA') {
    return <TPAssistTelLink />;
  }
  return <HomeClaimsFNOL />;
};

const homeOrMotorTel = lob => {
  if (lob === 'motor') {
    return <MotorClaimsTelLink />;
  }
  if (lob === 'motorTPA') {
    return <TPAssistTelLink />;
  }
  return <HomeClaimsTelLink />;
};

export const standardOOH = clickHereLink => (
  <>
    Unfortunately, we are unable to record your claim online at this time.
    Please <a href={clickHereLink}>click here</a> to provide us with some
    details and we will be in touch by the end of the next working day.
  </>
);

export const otpOOH = (clickHereLink, lob) => (
  <>
    Unfortunately, we are unable to proceed with your request at this time. For
    new claims, please <a href={clickHereLink}>click here</a> to provide us with
    some details and we will be in touch by the end of the next working day.
    Alternatively, please call the claims team on {homeFnolOrMotorTel(lob)}{' '}
    between the hours of {getBusinessHoursDescriptive(lob)}. If you’re looking
    for a progress update on an existing claim, please call the claims team
    between the hours of {businessHoursMidweekDescriptive} on the number
    provided when you reported your claim.
  </>
);

export const standardMotorOOH = standardOOH(motorReferLink);
export const otpMotorOOH = standardOOH(motorReferLink); // motor otp page not changing yet

export const standardHomeOOH = standardOOH(homeReferLink);
export const otpHomeOOH = otpOOH(homeReferLink, 'home');

export const getStandardOOH = lob => {
  if (lob === 'motor' || lob === 'motorTPA') {
    return standardMotorOOH;
  }
  return standardHomeOOH;
};

export const getOtpOOH = lob => {
  if (lob === 'motor' || lob === 'motorTPA') {
    return otpMotorOOH;
  }
  return otpHomeOOH;
};

export const RenderMessageOrOOHDefault = props => {
  const { lob } = props;
  // No OOH by default for motorTPA. Setting forceNoOOH in props will override
  return (
    <RenderMessageOrOOH
      forceNoOOH={lob === 'motorTPA'}
      getStandardOOH={getStandardOOH}
      {...props}
    />
  );
};

RenderMessageOrOOHDefault.propTypes = {
  lob: PropTypes.string,
};
RenderMessageOrOOHDefault.defaultProps = {
  lob: undefined,
};

export const noDataMotor = () => {
  return (
    <RenderMessageOrOOHDefault lob="motor">
      Unfortunately we can&apos;t get that data right now, please call us on{' '}
      <MotorClaimsTelLink /> and we can take the details of your claim
    </RenderMessageOrOOHDefault>
  );
};

export const noDataMotorWithHours = () => {
  return (
    <>
      Unfortunately we can&apos;t get that data right now, please call us on{' '}
      <MotorClaimsTelLink /> between the hours of{' '}
      {getBusinessHoursDescriptive('motor')} and we can take the details of your
      claim
    </>
  );
};

export const noActivePolicyMotor = () => {
  return (
    <RenderMessageOrOOHDefault lob="motor">
      We can&apos;t find a policy that was active on the date entered. Please
      call us on <MotorClaimsTelLink /> to discuss your claim.
    </RenderMessageOrOOHDefault>
  );
};

export const noActivePolicyMotorWithHours = () => {
  return (
    <>
      We can&apos;t find a policy that was active on the date entered. Please
      call us on <MotorClaimsTelLink /> between the hours of{' '}
      {getBusinessHoursDescriptive('motor')} to discuss your claim.
    </>
  );
};

export const callUsMotor = () => {
  return (
    <RenderMessageOrOOHDefault lob="motor">
      Please call us on <MotorClaimsTelLink />
    </RenderMessageOrOOHDefault>
  );
};

export const callUsMotorWithHours = () => {
  return (
    <>
      Please call the claims team on <MotorClaimsTelLink /> between the hours of{' '}
      {getBusinessHoursDescriptive('motor')}{' '}
    </>
  );
};

export const genericErrorCallUsMotor = () => {
  return (
    <>
      Unfortunately, there has been a problem with your request. Please call the
      claims team on <MotorClaimsTelLink /> between the hours of{' '}
      {getBusinessHoursDescriptive('motor')}.
    </>
  );
};

export const otherPolicyHolder = () => {
  const businessHours = checkBusinessHours('home');
  return (
    <>
      {businessHours && (
        <>
          As you are not the main policyholder, please call us on{' '}
          <HomeClaimsFNOL /> to discuss this claim
        </>
      )}
      {!businessHours && (
        <>
          Unfortunately, we are currently unable to record this claim online.
          Please <a href={homeReferLink}>click here</a> to provide us with some
          details and we will be in touch by the end of the next working day.
        </>
      )}
    </>
  );
};

export const date3Years = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob}>
      If the incident occurred over 3 years ago please call us -{' '}
      {lob === 'motor' ? <MotorClaimsTelLink /> : <HomeClaimsFNOL />}
    </RenderMessageOrOOHDefault>
  );
};

export const date3YearsMotorWithHours = () => {
  return (
    <>
      If the incident occurred over 3 years ago please call us on{' '}
      <MotorClaimsTelLink /> between the hours of{' '}
      {getBusinessHoursDescriptive('motor')}
    </>
  );
};

export const otherCircumstanceMotor = () => {
  return (
    <RenderMessageOrOOHDefault lob="motor">
      If none of these circumstances describe the incident please call{' '}
      <MotorClaimsTelLink />
    </RenderMessageOrOOHDefault>
  );
};

export const createClaimFailMotor = () => {
  return (
    <RenderMessageOrOOHDefault lob="motor">
      We are unable to complete your claim at this time, please call us on{' '}
      <MotorClaimsTelLink /> and we will be able to assist you
    </RenderMessageOrOOHDefault>
  );
};
// Broker login page

export const noActivePolicyOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      You cannot make a claim online under this policy, please call us on{' '}
      {homeFnolOrMotorTel(lob)} to discuss further
    </RenderMessageOrOOHDefault>
  );
};

export const referenceNotSuitableOTPStandard = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      We are unable to proceed with your request as the details you have
      provided are no longer valid. For new claims, please call us on{' '}
      {homeFnolOrMotorTel(lob)} to discuss further. If you’re looking for a
      progress update on an existing claim, please call the claims team on the
      number provided when you reported your claim.
    </RenderMessageOrOOHDefault>
  );
};

export const referenceNotSuitableOTPMotorTPA = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      We are unable to proceed with your request as the details you have
      provided are no longer valid. Please call us on {homeFnolOrMotorTel(lob)}{' '}
      to discuss further.
    </RenderMessageOrOOHDefault>
  );
};

export const referenceNotSuitableOTP = lob =>
  lob === 'motorTPA'
    ? referenceNotSuitableOTPMotorTPA(lob)
    : referenceNotSuitableOTPStandard(lob);

export const noPolicyOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      The details you have provided do not match our records, please check and
      try again. If you think they are correct, please call us on{' '}
      {homeFnolOrMotorTel(lob)}.
    </RenderMessageOrOOHDefault>
  );
};

export const noContactDetailsOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      We are unable to retrieve your contact details. To proceed, please call us
      on {homeFnolOrMotorTel(lob)}.
    </RenderMessageOrOOHDefault>
  );
};

export const noRecordsMatchedOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      The details you have provided do not match our records, please call us on{' '}
      {homeFnolOrMotorTel(lob)}
    </RenderMessageOrOOHDefault>
  );
};

// Mobile Email Authentication.
export const noIdentityMatchedOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      We&apos;re sorry, we can&apos;t find a suitable contact method to verify
      your identity. Please contact our claims line on {homeFnolOrMotorTel(lob)}{' '}
      between the hours of {getBusinessHoursDescriptive(lob)}. Alternatively, if
      you think you may have typed your details incorrectly, you can click
      &apos;Back&apos; and try again.{' '}
      {lob !== 'motorTPA' && (
        <>
          You can also get in touch with your broker to add a mobile number or
          email address to your policy.
        </>
      )}
    </RenderMessageOrOOHDefault>
  );
};

export const problemInRequestOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      There has been a problem with your request, please call us on{' '}
      {homeFnolOrMotorTel(lob)}.
    </RenderMessageOrOOHDefault>
  );
};

export const noAuthCodeOTP = lob => {
  return (
    <RenderMessageOrOOHDefault lob={lob} oohMessage={getOtpOOH(lob)}>
      There’s been a problem sending the Ageas authentication code
    </RenderMessageOrOOHDefault>
  );
};

// OTP Verification
export const doesNotMatchOTP = () => {
  return <>Ageas authentication code entered does not match</>;
};

export const serverErrorHome = () => {
  return (
    <RenderMessageOrOOHDefault lob="home">
      There has been a problem with your request, please call us on{' '}
      <HomeClaimsFNOL />
    </RenderMessageOrOOHDefault>
  );
};

export const serverErrorCustomTelNoOOH = (
  lob,
  tel,
  formatTel,
  businessHours,
) => {
  return (
    <>
      There has been a problem with your request, please call us on{' '}
      {formatTel ? <TelephoneLink number={tel} /> : tel}
      {businessHours && (
        <>
          {' '}
          between the hours of{' '}
          {getBusinessHoursDescriptive(
            businessHours === true ? lob : businessHours,
          )}
        </>
      )}
    </>
  );
};

export const serverErrorCustomTel = (
  lob = 'home',
  tel,
  formatTel,
  businessHours,
) => {
  return (
    <RenderMessageOrOOHDefault lob={lob}>
      {serverErrorCustomTelNoOOH(lob, tel, formatTel, businessHours)}
    </RenderMessageOrOOHDefault>
  );
};

export const concurrentErrorCustomTel = tel => {
  return (
    <>
      This record has been updated on another device. Please refresh the page
      {!tel && '.'}
      {tel && (
        <>
          {' '}
          or call us on <TelephoneLink number={tel} suffix="." />
        </>
      )}
    </>
  );
};

export const validPolicyErrorHome = (perilType = 'damage') => {
  return (
    <RenderMessageOrOOHDefault lob="home">
      We cannot find a valid policy for the date the {perilType} occurred.
      Please call the claims team on <HomeClaimsFNOL />.
    </RenderMessageOrOOHDefault>
  );
};

export const NoActivePolicyErrorHome = () => {
  return (
    <RenderMessageOrOOHDefault lob="home">
      We can&apos;t find a policy that was active on the date entered. Please
      call us on <HomeClaimsFNOL /> to discuss your claim.
    </RenderMessageOrOOHDefault>
  );
};

export const OtherItemSelectedHome = () => {
  return (
    <RenderMessageOrOOHDefault lob="home">
      As you have selected <strong>&apos;Other item&apos;</strong>, please call
      us on <HomeClaimsFNOL /> to discuss your claim
    </RenderMessageOrOOHDefault>
  );
};

export const SomethingElseSelectedHome = () => {
  return (
    <RenderMessageOrOOHDefault lob="home">
      As you have selected <strong>&apos;Something else&apos;</strong>, please
      call us on <HomeClaimsFNOL /> to discuss your claim
    </RenderMessageOrOOHDefault>
  );
};

export const UnableToProceedWithClaimHome = () => {
  return (
    <>
      We are currently unable to proceed with your claim online. Please call us
      on <HomeClaimsFNOL /> between the hours of {getBusinessHoursDescriptive()}{' '}
      to discuss your claim.
    </>
  );
};

export const IncorrectHomeClaimCover = claimReference => {
  return (
    <>
      <P>
        Unfortunately, we are unable to consider your claim as you do not have
        the correct cover in force.
      </P>
      <P>
        A reference of <strong>{claimReference}</strong> has been allocated to
        this incident.
      </P>
      <P>
        Please call the claims team on{' '}
        <strong>
          <HomeClaimsFNOL />
        </strong>{' '}
        between the hours of {getBusinessHoursDescriptive()} if you would like
        to discuss this matter further.
      </P>
    </>
  );
};

export const checkBankDetails = () => {
  return <>Please check the details you have entered are correct</>;
};

export const invalidBankDetails = (telephone, businessHours) => {
  return (
    <>
      Unfortunately, we are still unable to validate your banking details online
      at this time. Please call the claims team on {telephone} between the hours
      of {businessHours}.
    </>
  );
};

export const InvalidBankDetailsHome = () => {
  return invalidBankDetails(<HomeClaimsFNOL />, getBusinessHoursDescriptive());
};

export const invalidBankDetailsMotor = telephoneLink => {
  return invalidBankDetails(telephoneLink, businessHoursMidweekDescriptive);
};

const buildUploadErrorEmailLink = (
  emailAddress,
  claimReference,
  perilDescription,
  correspondenceType = 'UR',
  documentTypesList = 'legal document/estimate/receipt/photos of damage',
) => (
  <a
    href={`mailto:${emailAddress}?subject=${claimReference}${correspondenceType}&body=Please find attached a copy of the ${documentTypesList} for my claim${
      perilDescription ? ` for ${perilDescription}` : ''
    }.`}
  >
    {emailAddress}
  </a>
);

export const hubUploadError = (
  emailAddress,
  claimReference,
  perilDescription,
  correspondenceType = 'UR',
) => {
  return (
    <P>
      There has been a problem with your upload. Please try again, or
      alternatively, please send a copy of your legal document, estimate,
      receipt or photos of damage to{' '}
      {buildUploadErrorEmailLink(
        emailAddress,
        claimReference,
        perilDescription,
        correspondenceType,
      )}
      .
    </P>
  );
};

export const hubUploadErrorHome = (...props) =>
  hubUploadError(config.client.email.householdclaims, ...props);

export const hubUploadErrorMotor = (...props) =>
  hubUploadError(config.client.email.motorclaims, ...props);

export const receiptUploadErrorHome = (
  claimReference,
  perilDescription,
  correspondenceType = 'UR',
) => {
  return (
    <P>
      There has been a problem with your upload. Please try again, or
      alternatively, please send a copy of your estimate, invoice or receipt to{' '}
      {buildUploadErrorEmailLink(
        config.client.email.householdclaims,
        claimReference,
        perilDescription,
        correspondenceType,
        'invoice/estimate/receipt',
      )}
      .
    </P>
  );
};

export const perilCategoryDocumentUploadErrorHome = (
  claimReference,
  perilDescription,
  correspondenceType = 'UR',
  documentType = 'document',
) => {
  return (
    <P>
      There has been a problem with your upload. Please try again, or
      alternatively, please send a copy of your {documentType} to{' '}
      <a
        href={`mailto:${
          config.client.email.householdclaims
        }?subject=${claimReference}${correspondenceType}&body=Please find attached a copy of the ${documentType} for my claim${
          perilDescription ? ` for ${perilDescription}` : ''
        }.`}
      >
        {config.client.email.householdclaims}
      </a>
      .
    </P>
  );
};

export const dataLoadFailingError = (lob = 'home') => {
  return (
    <>
      Unfortunately, we are currently unable to retrieve your details. Please
      try again later. If you need to contact us urgently, please call your
      dedicated claims handler on the number provided when you reported your
      claim. Alternatively, please call us on{' '}
      <strong>{homeOrMotorTel(lob)}</strong> {businessHoursMidweekDescriptive}.
    </>
  );
};

export const motorTpDataLoadFailingError = () => {
  return (
    <>
      Unfortunately, we are currently unable to retrieve your details. Please
      try again later. If you need to contact us urgently, please call your
      dedicated claims handler on the number provided when you reported your
      claim. Alternatively, please call us on{' '}
      <strong>
        <TPAssistTelLink />
      </strong>{' '}
      {businessHoursMotorTPADescriptive}.
    </>
  );
};

export const tpAssistLinkExpired = () => {
  return (
    <>
      Unfortunately, this link has expired. Please contact our dedicated Third
      Party Assistance team on{' '}
      <strong>
        <TPAssistTelLink />
      </strong>
      {'; '}
      {businessHoursMotorTPADescriptive}.
    </>
  );
};

export const tpAssistLinkPreviouslyUsed = () => {
  return (
    <>
      Unfortunately, this link has already been used. Please contact our
      dedicated Third Party Assistance team on{' '}
      <strong>
        <TPAssistTelLink />
      </strong>
      {'; '}
      {businessHoursMotorTPADescriptive}.
    </>
  );
};

export const tpAssistServerError = () => {
  return (
    <>
      Unfortunately, there has been a problem with your request. Please contact
      our dedicated Third Party Assistance team on{' '}
      <strong>
        <TPAssistTelLink />
      </strong>
      {'; '}
      {businessHoursMotorTPADescriptive}.
    </>
  );
};

export const errorMessages = {
  _noDataMotor: noDataMotor,
  _noDataMotorWithHours: noDataMotorWithHours,
  _noActivePolicyMotor: noActivePolicyMotor,
  _noActivePolicyMotorWithHours: noActivePolicyMotorWithHours,
  _callUsMotor: callUsMotor,
  _genericMotorSaveClaimError: genericErrorCallUsMotor,
  _callUsMotorWithHours: callUsMotorWithHours,
  _otherPolicyHolder: otherPolicyHolder,
  _date3Years: date3Years,
  _date3YearsMotorWithHours: date3YearsMotorWithHours,
  _otherCircumstanceMotor: otherCircumstanceMotor,
  _createClaimFailMotor: createClaimFailMotor,
  _noActivePolicyOTP: noActivePolicyOTP,
  _noPolicyOTP: noPolicyOTP,
  _noContactDetailsOTP: noContactDetailsOTP,
  _noRecordsMatchedOTP: noRecordsMatchedOTP,
  _noIdentityMatchedOTP: noIdentityMatchedOTP,
  _problemInRequestOTP: problemInRequestOTP,
  _noAuthCodeOTP: noAuthCodeOTP,
  _doesNotMatchOTP: doesNotMatchOTP,
  _serverErrorHome: serverErrorHome,
  _validPolicyErrorHome: validPolicyErrorHome,
  _NoActivePolicyErrorHome: NoActivePolicyErrorHome,
  _OtherItemSelectedHome: OtherItemSelectedHome,
  _SomethingElseSelectedHome: SomethingElseSelectedHome,
  _UnableToProceedWithClaimHome: UnableToProceedWithClaimHome,
  _IncorrectHomeClaimCover: IncorrectHomeClaimCover,
  _checkBankDetails: checkBankDetails,
  _InvalidBankDetailsHome: InvalidBankDetailsHome,
  _invalidBankDetailsMotor: invalidBankDetailsMotor,
  _ReceiptUploadErrorHome: receiptUploadErrorHome,
  _hubUploadErrorHome: hubUploadErrorHome,
  _eowUploadErrorHome: perilCategoryDocumentUploadErrorHome,
  _dataLoadFailingError: dataLoadFailingError,
  _motorTpDataLoadFailingError: motorTpDataLoadFailingError,
  _tpAssistLinkExpired: tpAssistLinkExpired,
  _tpAssistLinkPreviouslyUsed: tpAssistLinkPreviouslyUsed,
  _tpAssistServerError: tpAssistServerError,
};

export const getErrorMessage = (name, ...rest) => {
  if (name.substring(0, 1) !== '_') {
    return name;
  }
  if (errorMessages[name]) return errorMessages[name](...rest);
  return name;
};
