import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  ErrorMessage,
  ThemedLabel,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import { email } from 'ageasvalidation/lib/schemas/Email';
import DamagedDate from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';
import { FieldHeader } from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';
import FieldStyled from '../../components/Forms/FieldStyled/FieldStyled';
import mobileTelephoneValidationRegex from '../../helpers/mobileTelephoneValidationRegex';
import policyDetailsAxios from '../../helpers/axiosFunctions/policyDetailsAxios';
import ContextAxios from '../../components/ContextAxios/ContextAxios';
import PolicyHolderField from '../../components/HomeContactDetails/PolicyHolderField';
import {
  getPolicyDataContextError,
  POLICY_DATA_AXIOS_ERROR,
} from '../../helpers/policyDataContextHelper';

import { getValidationErrorMessage } from '../../components/HomeContactDetails/validationErrorMapping';
import { serverErrorHome } from '../../helpers/errorMessages';

export const getStandardClaimDetailsValidationSchema = (
  policyFieldName,
  mobileFieldName,
  emailFieldName,
) => {
  return {
    [policyFieldName]: stringRequired(
      [policyFieldName],
      'Please select a contact',
    ),
    [mobileFieldName]: stringRequired(
      [mobileFieldName],
      'Please enter your mobile telephone number',
    ).concat(
      regularExpression(
        mobileTelephoneValidationRegex,
        [mobileFieldName],
        'Please enter a valid mobile telephone number',
      ),
    ),
    [emailFieldName]: stringRequired(
      [emailFieldName],
      'Please enter an email address',
    ).concat(email([emailFieldName], 'Please enter a valid email address')),
  };
};

const renderPolicyDataContextError = (
  policyDataContextError,
  policyDetailsContext,
) => {
  if (policyDataContextError === POLICY_DATA_AXIOS_ERROR.VALIDATION_CODES) {
    return (
      <ThemedErrorMessage hasIcon>
        {getValidationErrorMessage(
          policyDetailsContext.data.status.validations[0],
        )}
      </ThemedErrorMessage>
    );
  }
  if (policyDataContextError === POLICY_DATA_AXIOS_ERROR.OTHER) {
    return <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>;
  }

  return null;
};

const HomeStandardClaimDetails = props => {
  const {
    name = [],
    dateSecondaryLabel,
    validate = () => {},
    dateValue,
    perilCode,
    itemType,
    dateChecker,
    contextName,
    policyHolderNameList,
  } = props;
  const { dataState: policyDataContext } = useContext(contextName);
  const policyDetailsContext = { ...policyDataContext[policyHolderNameList] };

  const policyDataContextError =
    getPolicyDataContextError(policyDetailsContext);

  const [
    INCIDENT_DATE_FIELD_NAME,
    POLICYHOLDER_NAME,
    MOBILE_TELEPHONE_NAME,
    EMAIL_ADDRESS_NAME,
  ] = name;

  const keyList = [dateValue, perilCode];
  if (itemType) {
    keyList.push(itemType);
  }

  return (
    <>
      <DamagedDate
        name={INCIDENT_DATE_FIELD_NAME || 'damagedDate'}
        id="incidentDate"
        label="Date of incident"
        secondaryLabel={dateSecondaryLabel}
        validate={validate}
      />
      <ContextAxios
        keyList={keyList}
        axiosFunction={policyDetailsAxios}
        enableUpdate={dateChecker}
        dataContextObject={contextName}
        dataContextName={policyHolderNameList}
      />

      {dateChecker && (
        <>
          {renderPolicyDataContextError(
            policyDataContextError,
            policyDetailsContext,
          )}
          <FieldStyled
            name={POLICYHOLDER_NAME || 'policyholderName'}
            marginBottom="16px"
            validate={validate}
          >
            {({ input, meta }) => (
              <>
                {!policyDataContextError && (
                  <PolicyHolderField
                    {...input}
                    title="Please confirm the main contact for this claim"
                    fieldName={input.name}
                    id={input.name}
                    selectedOption={input.value}
                    maxButtons={4}
                    errorMsg={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                    dataContextObject={contextName}
                    dataContextName={policyHolderNameList}
                  />
                )}
              </>
            )}
          </FieldStyled>
        </>
      )}
      <FieldHeader>Please enter your preferred mobile number below</FieldHeader>
      <FieldStyled
        name={MOBILE_TELEPHONE_NAME || 'mobileNumber'}
        validate={validate}
      >
        {({ input, meta }) => (
          <>
            <ThemedLabel>
              <small>
                We will send you a text confirmation of the claim details to
                this number.
              </small>
            </ThemedLabel>
            <Input
              aria-label="mobile telephone number"
              size="small"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
      <FieldHeader>Please enter your preferred email address below</FieldHeader>
      <FieldStyled
        name={EMAIL_ADDRESS_NAME || 'emailAddress'}
        validate={validate}
      >
        {({ input, meta }) => (
          <>
            <ThemedLabel>
              <small>
                In some instances we will send email confirmation of the claim
                details to this address.
              </small>
            </ThemedLabel>
            <Input
              size="small"
              aria-label="email address"
              maxlength="100"
              {...input}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </FieldStyled>
    </>
  );
};

HomeStandardClaimDetails.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string),
  dateSecondaryLabel: PropTypes.string,
  validate: PropTypes.func,
  dateValue: PropTypes.string,
  perilCode: PropTypes.string,
  itemType: PropTypes.string,
  dateChecker: PropTypes.bool,
  contextName: PropTypes.instanceOf(Object).isRequired,
  policyHolderNameList: PropTypes.string.isRequired,
};
HomeStandardClaimDetails.defaultProps = {
  name: undefined,
  dateSecondaryLabel: undefined,
  validate: undefined,
  dateValue: undefined,
  perilCode: undefined,
  itemType: undefined,
  dateChecker: undefined,
};

export default HomeStandardClaimDetails;
