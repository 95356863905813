// Based on ThemedHyperlink from ageas-ui-components

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ButtonLinkStyled = styled.span`
  /* Default styling */
  color: black;
  text-decoration: none;
  font: inherit;
  appearance: none;
  border: 0;
  background: none;
  display: inline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    `}

  /* Without underline, Without hover functionality */
  ${props =>
    props.primary &&
    props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.primary};
      &:hover {
        text-decoration: none;
      }
    `}

  ${props =>
    props.secondary &&
    props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.secondary};

      &:hover {
        color: props.theme.hyperlink.secondary;
        text-decoration: none;
      }
    `}

  ${props =>
    props.tertiary &&
    props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.tertiary};
      &:hover {
        text-decoration: none;
      }
    `}

  ${props =>
    props.white &&
    props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.white};
      &:hover {
        text-decoration: none;
      }
    `}

 /* With underline, Without hover functionality */
  ${props =>
    props.primary &&
    props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.primary};
      &:hover {
        color: ${props.theme.hyperlink.primary};
        text-decoration: underline;
      }
    `}

  ${props =>
    props.secondary &&
    props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.secondary};
      &:hover {
        color: ${props.theme.hyperlink.secondary};
        text-decoration: underline;
      }
    `}

  ${props =>
    props.tertiary &&
    props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.tertiary};
      &:hover {
        color: ${props.theme.hyperlink.tertiary};
        text-decoration: underline;
      }
    `}

  ${props =>
    props.white &&
    props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.white};
      &:hover {
        color: ${props.theme.hyperlink.white};
        text-decoration: underline;
      }
    `}

    
  /* Without underline, with Hover */
  ${props =>
    props.primary &&
    !props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.primary};
      &:hover {
        color: ${props.theme.hyperlink.hover.primary};
        text-decoration: underline;
      }
    `}

  ${props =>
    props.secondary &&
    !props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.secondary};
      &:hover {
        color: ${props.theme.hyperlink.hover.secondary};
        text-decoration: underline;
      }
    `}

  ${props =>
    props.tertiary &&
    !props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.tertiary};
      &:hover {
        color: ${props.theme.hyperlink.hover.tertiary};
        text-decoration: underline;
      }
    `}

  ${props =>
    props.white &&
    !props.noHover &&
    !props.underline &&
    css`
      color: ${props.theme.hyperlink.white};
      &:hover {
        color: ${props.theme.hyperlink.hover.white};
        text-decoration: underline;
      }
    `}

  /* With Underline, with hover */
  ${props =>
    props.primary &&
    !props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.primary};
      text-decoration: underline;

      &:hover {
        color: ${props.theme.hyperlink.hover.primary};
        text-decoration: none;
      }
    `}

  ${props =>
    props.secondary &&
    !props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.secondary};
      text-decoration: underline;

      &:hover {
        color: ${props.theme.hyperlink.hover.secondary};
        text-decoration: none;
      }
    `}

  ${props =>
    props.tertiary &&
    !props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.tertiary};
      text-decoration: underline;

      &:hover {
        color: ${props.theme.hyperlink.hover.tertiary};
        text-decoration: none;
      }
    `}

  ${props =>
    props.white &&
    !props.noHover &&
    props.underline &&
    css`
      color: ${props.theme.hyperlink.white};
      text-decoration: underline;

      &:hover {
        color: ${props.theme.hyperlink.hover.white};
      }
    `}
`;

// TODO skyBlue not available in our theme copy, add when available
// ${props =>
// props.skyBlue &&
// !props.noHover &&
// props.underline &&
// css`
//     color: ${props.theme.hyperlink.skyBlue};
//     text-decoration: underline;

//     &:hover {
//       color: ${props.theme.hyperlink.hover.skyBlue};
//       text-decoration: none;
//     }
//   `}

const ButtonLink = ({ children, onClick, ...rest }) => {
  const onKeyDown = event => {
    if (
      onClick &&
      (event.key === 'Enter' ||
        event.key === ' ' ||
        event.which === 13 || // enter (fallback)
        event.which === 32) // space (fallback)
    ) {
      onClick(event);
    }
  };
  return (
    <ButtonLinkStyled
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </ButtonLinkStyled>
  );
};

ButtonLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};

ButtonLink.defaultProps = {
  children: undefined,
  onClick: undefined,
};

export default ButtonLink;
