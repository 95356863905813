import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Breadcrumb from '../components/Breadcrumb2/Breadcrumb';

export const items = [
  {
    id: 'incidentDetails',
    label: 'Incident Details',
  },
  {
    id: 'contactDetails',
    label: 'Contact Details',
  },
  {
    id: 'yourDetails',
    label: 'Your Details',
  },
  {
    id: 'vehicleDamage',
    label: 'Vehicle Damage',
  },
  {
    id: 'claimOutcome',
    label: 'Claim Outcome',
  },
];

const BreadcrumbWrapper = styled.div`
  margin-top: 10px;
`;

const MotorClaimBreadcrumbNew = ({ activeItem, ...props }) => {
  let currentItem;
  if (activeItem) {
    currentItem = items.findIndex(item => item.id === activeItem);
    if (currentItem < 0) {
      currentItem = undefined;
    }
  }

  return (
    <BreadcrumbWrapper>
      <Breadcrumb
        items={items}
        currentItem={currentItem}
        paddingBottomOffset="-20px"
        maxInnerWidth="700px"
        maxNarrowInnerWidth="280px"
        {...props}
      />
    </BreadcrumbWrapper>
  );
};

export default MotorClaimBreadcrumbNew;

MotorClaimBreadcrumbNew.propTypes = {
  activeItem: PropTypes.string,
};

MotorClaimBreadcrumbNew.defaultProps = {
  activeItem: undefined,
};
