import React from 'react';
import Breadcrumb from '../components/BreadcrumbClaims/BreadcrumbClaims';

export const items = [
  {
    id: 'brokerLogin',
    label: '1',
  },
  {
    id: 'verifyPolicyDetails',
    label: '2',
  },
];

const BrokerLoginHomePolicyDetailsBreadcrumb = ({ ...props }) => {
  return <Breadcrumb items={items} {...props} />;
};

export default BrokerLoginHomePolicyDetailsBreadcrumb;
