import React, { useState, useContext, useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { Form } from 'react-final-form';
import axios from 'axios';
import {
  ErrorMessage,
  ThemedLabel,
  ThemedButton,
  ThemedErrorMessage,
  ThemedHyperlink,
} from 'ageas-ui-components';
import moment from 'moment';
import {
  stringRequired,
  dateRequired,
} from 'ageasvalidation/lib/schemas/Required';
import { stringNotEqualTo } from 'ageasvalidation/lib/schemas/String';
import { dateLessThanOrEqualTo } from 'ageasvalidation/lib/schemas/Date';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import PropTypes from 'prop-types';
import StandardPanel from '../../../components/StandardPanel/StandardPanel';
import P from '../../../components/StandardTags/P';
import FieldStyled from '../../../components/Forms/FieldStyled/FieldStyled';
import { brand } from '../../../../data/whitelabel.config';
import BrandFooter from '../../../assets/BrandFooter';
import BrandHeader from '../../../assets/BrandHeader';
import DateDropdownInput from '../../../components/DateDropdownInput/DateDropdownInput';
import expandArrow from '../../../assets/expandArrow.svg';
import NavigateTo from '../../../components/Navigation/NavigateTo/NavigateTo';
import { validateField } from '../../../helpers/validationHelper';
import axiosHelper from '../../../helpers/axios';
import {
  brokerDetail,
  BrokerContext,
} from '../../../helpers/brokerContextHelper';
import LoadingSpinner from '../../../components/UI/LoadingSpinner/LoadingSpinner';
import config from '../../../helpers/config';
import {
  noPolicyOTP,
  noActivePolicyOTP,
  noContactDetailsOTP,
  noRecordsMatchedOTP,
  referenceNotSuitableOTP,
} from '../../../helpers/errorMessages';
import BrokerLoginBreadcrumb from '../../../helpers/BrokerLoginBreadcrumb';
import { dateISOConverter } from '../../../helpers/dateTimeConverter';
import { getLoginPathByLob } from '../../../helpers/getLineOfBusinessSubfolder';
import useQuery from '../../../hooks/useQuery/useQuery';
import StandardInput from '../../../components/Forms/StandardInput/StandardInput';
import media from '../../../components/MediaQuery/MediaQuery';
import { AuthContext } from '../../../contexts/AuthContext';
import { consoleError } from '../../../helpers/consoleLog';
import sanitiseVRN from '../../../helpers/sanitiseVRN';
import ButtonLink from '../../../components/ThemedButtonLink/ThemedButtonLink';

const Theme = getTheme(brand);
const QuestionStyle = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  font-weight: normal;
  font-size: 21px;
  margin-bottom: 12px;
`;

const PanelContent = styled.div`
  text-align: left;
  max-width: 1100px;
  margin: auto;
`;

const LabelStyling = styled.div`
  margin-bottom: -18px;
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TopP = styled(P)`
  margin-top: 0px;
`;

// Reference fields media.tablet media queries
// below, must match ageas ui components
// (max-width: 767px)
const ReferenceFieldsWrapper = styled.div`
  display: flex;
  ${media.tablet`
    flex-direction: column;
  `}
`;

const ReferenceFieldWrapper = styled.div`
  width: 264px;
  ${media.tablet`
    width: 100%;
  `}
`;

// 2.8em is amount to put "or" in line with text boxes
// only if label is one line
const ReferenceFieldSeparator = styled.div`
  margin: 2.9em 2em 0;
  ${media.tablet`
    margin: 0 0 24px;
  `};
`;

const TARGET_APPLICATION_MAP = {
  default: 'claim',
  motorclaim: 'claim',
  motorhub: 'hub',
  motortphub: 'hub',
  homeclaim: 'claim',
  homehub: 'hub',
};
const TARGET_LOB_MAP = {
  motorTPA: 'hub',
};

const getApplicationFromTargetLob = (target, landingPage) => {
  if (!target && landingPage && TARGET_LOB_MAP[landingPage]) {
    return TARGET_LOB_MAP[landingPage];
  }

  return TARGET_APPLICATION_MAP[
    target?.toLowerCase()?.split('/')[1] || 'default'
  ];
};

const standardDataEntryDescription = (
  <>
    Please enter <strong>either</strong> your policy number <strong>or</strong>{' '}
    a claim reference number
  </>
);

const BrokerLogin = ({
  landingPage,
  showClaimReference = true,
  showPolicyReference = true,
  showDOB = true,
  showVRN = false,
  postURL = config.client.brokercontact_endpoint,
  dataEntryDescription = standardDataEntryDescription,
  contactSourceDescription = 'stored on your policy information or provided by you when you reported your claim',
  thirdParty = false,
}) => {
  const { clearLogin } = useContext(AuthContext);
  // Must be blank, or contain 8/7 to 50 alphanumeric characters, but regex
  // allows special characters in between.
  // Payload construction should remove any non alphanumerics.
  const policyReferenceFormatRegex =
    landingPage === 'motor'
      ? /^$|^([^0-9A-Za-z]*[0-9A-Za-z]){8,50}[^0-9A-Za-z]*$/
      : /^$|^([^0-9A-Za-z]*[0-9A-Za-z]){7,50}[^0-9A-Za-z]*$/;
  // Must be 8 alphanumeric or blank
  const claimReferenceFormatRegex = /^$|^[0-9A-Za-z]{8}$/;

  const { updateDataState, clearAllDataState } = useContext(BrokerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [navigatePath, setNavigatePath] = useState(null);
  const [policyNotInForce, setPolicyNotInForce] = useState(false);
  const [isErrorNotMatch, setIsErrorNotMatch] = useState(false);
  const [isErrorNotFound, setIsErrorNotFound] = useState(false);
  const [isErrorRefNotSuitable, setIsErrorRefNotSuitable] = useState(false);
  const [authCount, setAuthCount] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [isDisplayPolicyHelp, setDisplayPolicyHelp] = useState(false);
  const axiosCancelToken = useRef(null);
  const axiosEventCancelToken = useRef(null);
  const dateToday = moment().format('DD/MM/YYYY');
  const queryParams = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    // clear the value in context
    clearAllDataState();
    // Log user out if logout flag is set
    if (queryParams.has('logout')) {
      clearLogin();
    }

    // Abort axios on unmount
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
      if (axiosEventCancelToken?.current?.cancel) {
        axiosEventCancelToken.current.cancel();
      }
    };
  }, []);

  // Mandatory fields schema
  const schema = {
    dateOfBirth: stringNotEqualTo(
      'Incomplete date',
      ['dateOfBirth'],
      'Please select the date of birth',
    )
      .concat(
        stringNotEqualTo(
          'Invalid date',
          ['dateOfBirth'],
          'Please select a valid date',
        ),
      )
      .concat(dateRequired(['dateOfBirth'], 'Please select the date of birth'))
      .concat(
        dateLessThanOrEqualTo(
          dateToday,
          ['dateOfBirth'],
          'The date of birth cannot be in the future',
        ),
      ),
    vrn: stringRequired(
      ['vrn'],
      'Please enter a vehicle registration number',
    ).concat(
      regularExpression(
        /[a-zA-Z0-9]/,
        ['vrn'],
        'Please enter a vehicle registration number',
      ),
    ),
  };

  // Policy ref is mandatory if not showing claim reference
  if (!showClaimReference) {
    schema.policyNumber = stringRequired(
      ['policyNumber'],
      'Please enter the policy number',
    );
  }

  // Claim ref is mandatory if not showing policy reference
  if (!showPolicyReference) {
    schema.claimReference = stringRequired(
      ['claimReference'],
      'Please enter a claim reference number',
    );
  }

  // Optional fields schema
  const optionalSchema = {
    policyNumber: regularExpression(
      policyReferenceFormatRegex,
      ['policyNumber'],
      'Please enter a valid policy number',
    ),
    claimReference: regularExpression(
      claimReferenceFormatRegex,
      ['claimReference'],
      'Please enter a valid claim reference number',
    ),
  };

  const validateGenericFields = (value, _allValues, meta) => {
    let error;
    // Standard validation - mandatory fields
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return error;
      }
    }
    // Additional validation - optional fields
    if (optionalSchema[meta.name] && value) {
      error = validateField(value, meta, optionalSchema);
      if (error) {
        return error;
      }
    }

    return error;
  };

  const formValidator = values => {
    const errors = {};
    if (showClaimReference) {
      if (values.policyNumber && values.claimReference) {
        errors.claimReference =
          'Please enter either a policy number or a claim reference number, not both';
      }
      if (!values.policyNumber && !values.claimReference) {
        errors.policyNumber =
          'Please enter a policy number or a claim reference number';
      }
    }
    return errors;
  };

  const pageNavigation = (navigateToPage = 'validatecontact') => {
    const target = queryParams.get('target');
    let path = getLoginPathByLob(landingPage, navigateToPage);
    if (target) {
      path += `?target=${encodeURIComponent(target)}`;
    }
    setNavigatePath(path);
  };

  const axiosRequestReset = () => {
    updateDataState(brokerDetail.contactList, {});
    updateDataState(brokerDetail.displayError, false);
    updateDataState(brokerDetail.lineOfBusiness, landingPage);
    setIsErrorNotFound(false);
    setIsErrorNotMatch(false);
    setPolicyNotInForce(false);
    setIsDisable(false);
    setIsErrorRefNotSuitable(false);
    setIsLoading(false);
  };

  /**
   * Keeps track of the user attempts
   *
   * @param count A value to keep track of user attempt
   * @returns true if count exceeded, else false
   */
  const axiosCount = count => {
    setAuthCount(prevCount => prevCount + 1);
    if (count >= 2) {
      setIsDisable(true);
      return true;
    }
    return false;
  };

  const getBrokerDetails = values => {
    setIsLoading(true);
    const dateConverter =
      values.dateOfBirth && dateISOConverter(values.dateOfBirth, true); // Include only if set
    const application = getApplicationFromTargetLob(
      queryParams.get('target'),
      landingPage,
    );
    const brokerFormData = {
      lineOfBusiness: landingPage,
      application,
      policyReference: values.policyNumber,
      claimReference: values.claimReference,
      dateOfBirth: dateConverter,
      vrn: sanitiseVRN(values.vrn), // Upper case, remove all whitespace chars
    };

    axiosCancelToken.current = axios.CancelToken.source();
    axiosHelper
      .post(postURL, brokerFormData, {
        cancelToken: axiosCancelToken.current.token,
      })
      .then(data => {
        axiosRequestReset();
        if (data.status === 200) {
          updateDataState(brokerDetail.application, application);
          if (data?.data?.otpStatus === 'policyDetailsRequired') {
            // update this if-statement if we add policy details login to other
            // lobs
            if (landingPage !== 'home') {
              consoleError(
                `cannot authenticate via policy details on ${landingPage}`,
                data.status,
              );
              updateDataState(brokerDetail.displayError, true);
              pageNavigation();
            } else {
              pageNavigation('policydetailsverify');
            }
          } else {
            updateDataState(brokerDetail.contactList, data.data);
            pageNavigation();
          }
        } else {
          consoleError('unexpected response', data.status);
          updateDataState(brokerDetail.displayServerDownError, true);
          pageNavigation();
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          axiosRequestReset();
          const errorCode = error.response?.data?.data?.errorCode;

          // If any 40x error and axios count exceeded, stop here
          if (
            [400, 404].includes(error.response?.status) &&
            axiosCount(authCount)
          ) {
            return;
          }
          if (error.response?.status === 400) {
            if (
              ['INCOMP', 'CLAIMEXP', 'POLEXP', 'INVCSTS'].includes(errorCode)
            ) {
              setIsErrorRefNotSuitable(true);
            } else if (['POLNOSTART'].includes(errorCode)) {
              setPolicyNotInForce(true);
            }
            return;
          }
          if (error.response?.status === 404) {
            if (['NOCONTACT', 'INVCONTACT'].includes(errorCode)) {
              setIsErrorNotFound(true);
            } else {
              setIsErrorNotMatch(true);
            }
            return;
          }
          if (error.response?.status === 503) {
            updateDataState(brokerDetail.displayServerDownError, true);
            pageNavigation();
            return;
          }
          consoleError('unexpected catch error', error.response?.status);
          updateDataState(brokerDetail.displayServerDownError, true);
          pageNavigation();
        }
      });
  };

  const formatReference = reference =>
    reference.toUpperCase().replace(/[^0-9A-Za-z]/g, '');

  const onSubmit = values => {
    setIsLoading(true);
    const stateValues = { ...values };
    if (stateValues.policyNumber) {
      stateValues.policyNumber = formatReference(stateValues.policyNumber);
    }
    if (stateValues.claimReference) {
      stateValues.claimReference = formatReference(stateValues.claimReference);
    }
    getBrokerDetails(stateValues);
    updateDataState(brokerDetail.brokerPolicyDetail, stateValues);
  };

  const onDateChange = (returnedDate, input) => {
    let dateString;
    if (returnedDate === null) {
      dateString = 'Incomplete date';
      input.onFocus(dateString);
    } else if (Number.isNaN(returnedDate)) {
      dateString = 'Invalid date';
      input.onBlur(dateString);
    } else {
      dateString = moment.utc(returnedDate, 'x', true).format('DD/MM/YYYY');
      input.onBlur(dateString);
    }
    input.onChange(dateString);
  };

  const renderFormEnding = () => {
    return (
      <BottomButtonsContainer>
        <ThemedButton
          secondary
          small
          type="submit"
          disabled={
            policyNotInForce ||
            isErrorNotFound ||
            isDisable ||
            isErrorRefNotSuitable
          }
        >
          Continue
        </ThemedButton>
      </BottomButtonsContainer>
    );
  };

  const displayPolicyHelp = () => {
    if (!isDisplayPolicyHelp) {
      setDisplayPolicyHelp(true);
      axiosEventCancelToken.current = axios.CancelToken.source();
      axiosHelper
        .post(
          config.client.event_endpoint,
          {
            lineOfBusiness: 'H',
            application: 'P',
          },
          {
            cancelToken: axiosEventCancelToken.current.token,
          },
        )
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          // Do nothing
        });
    }
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : ''}
      <ThemeProvider theme={Theme}>
        <BrandHeader />
        <BrokerLoginBreadcrumb activeItem="brokerLogin" />
        <main>
          <NavigateTo path={navigatePath} />
          <QuestionStyle>
            <PanelContent>
              <StandardPanel primary title="Authentication" padding="23px 21px">
                {queryParams.has('portalLink') && (
                  <TopP>
                    If you have an Ageas online account, please{' '}
                    <ThemedHyperlink
                      primary
                      href={`${config.client.portalLoginUrl}&target=makeMotorClaim`}
                      text="click here"
                    />{' '}
                    to log in, otherwise please continue with the authentication
                    below.
                  </TopP>
                )}
                <Form onSubmit={onSubmit} validate={formValidator}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      <Heading>
                        {dataEntryDescription} so we can verify your identity.
                        We&apos;ll do this by sending you an Ageas
                        authentication code to either a mobile phone number or
                        email address {contactSourceDescription}.
                      </Heading>
                      <ReferenceFieldsWrapper>
                        {showPolicyReference && (
                          <ReferenceFieldWrapper>
                            <StandardInput
                              name="policyNumber"
                              aria-label="Policy Number"
                              label="Policy number"
                              maxLength="50"
                              validate={validateGenericFields}
                            />
                          </ReferenceFieldWrapper>
                        )}
                        {showClaimReference && showPolicyReference && (
                          <ReferenceFieldSeparator>or</ReferenceFieldSeparator>
                        )}
                        {showClaimReference && (
                          <ReferenceFieldWrapper>
                            <StandardInput
                              name="claimReference"
                              aria-label="Claim Reference Number"
                              label="Claim reference number"
                              maxLength="8"
                              validate={validateGenericFields}
                            />
                          </ReferenceFieldWrapper>
                        )}
                      </ReferenceFieldsWrapper>

                      {showDOB && (
                        <FieldStyled
                          name="dateOfBirth"
                          validate={validateGenericFields}
                        >
                          {({ input, meta }) => (
                            <>
                              <LabelStyling>
                                <ThemedLabel>Date of birth</ThemedLabel>
                              </LabelStyling>
                              <DateDropdownInput
                                formInput={input}
                                handleDate={value => onDateChange(value, input)}
                                inclDay
                                dropdownId="registrationDoi"
                                dropdownIcon={expandArrow}
                                placeholderDay="DD"
                                placeholderMonth="MM"
                                placeholderYear="YYYY"
                                dateDefaultValue={input.value.split('/')[0]}
                                monthDefaultValue={input.value.split('/')[1]}
                                yearDefaultValue={input.value.split('/')[2]}
                              />
                              {meta.error && meta.touched && (
                                <ErrorMessage hasIcon>
                                  {meta.error}
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </FieldStyled>
                      )}

                      {showVRN && (
                        <ReferenceFieldWrapper>
                          <StandardInput
                            name="vrn"
                            label="Vehicle registration number"
                            aria-label="Vehicle registration number"
                            maxLength="10"
                            validate={validateGenericFields}
                          />
                        </ReferenceFieldWrapper>
                      )}

                      {config.client.FEATURE_POLICY_NUMBER_HELP_MESSAGE &&
                        landingPage === 'home' &&
                        getApplicationFromTargetLob(
                          queryParams.get('target'),
                          landingPage,
                        ) === 'claim' && (
                          <>
                            <P>
                              If you cannot find your policy number, please{' '}
                              <ButtonLink primary onClick={displayPolicyHelp}>
                                click&nbsp;here
                              </ButtonLink>
                              .
                            </P>
                            {isDisplayPolicyHelp && (
                              <P>
                                You can find your policy number on your
                                insurance schedule or Statement of Fact.
                              </P>
                            )}
                          </>
                        )}

                      {policyNotInForce && (
                        <ThemedErrorMessage hasIcon>
                          {noActivePolicyOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {isErrorRefNotSuitable && (
                        <ThemedErrorMessage hasIcon>
                          {referenceNotSuitableOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {isErrorNotMatch && (
                        <ThemedErrorMessage hasIcon>
                          {noPolicyOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {isErrorNotFound && (
                        <ThemedErrorMessage hasIcon>
                          {noContactDetailsOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {isDisable && (
                        <ThemedErrorMessage hasIcon>
                          {noRecordsMatchedOTP(landingPage)}
                        </ThemedErrorMessage>
                      )}
                      {renderFormEnding()}
                    </form>
                  )}
                </Form>
              </StandardPanel>
            </PanelContent>
          </QuestionStyle>
        </main>
        <BrandFooter thirdParty={thirdParty} />
      </ThemeProvider>
    </>
  );
};

export default BrokerLogin;

BrokerLogin.propTypes = {
  showClaimReference: PropTypes.bool,
  showPolicyReference: PropTypes.bool,
  showDOB: PropTypes.bool,
  showVRN: PropTypes.bool,
  landingPage: PropTypes.string.isRequired,
  postURL: PropTypes.string,
  dataEntryDescription: PropTypes.node,
  contactSourceDescription: PropTypes.node,
  thirdParty: PropTypes.bool,
};
BrokerLogin.defaultProps = {
  showClaimReference: undefined,
  showPolicyReference: undefined,
  showDOB: undefined,
  showVRN: undefined,
  postURL: undefined,
  dataEntryDescription: undefined,
  contactSourceDescription: undefined,
  thirdParty: undefined,
};

export const BrokerLoginMotor = props => {
  return (
    <BrokerLogin
      {...props}
      landingPage="motor"
      showClaimReference={config.client.FEATURE_MOTOR_OTP_ALLOW_CLAIM_REF_LOGIN}
      dataEntryDescription={
        !config.client.FEATURE_MOTOR_OTP_ALLOW_CLAIM_REF_LOGIN
          ? 'Please enter your policy number and date of birth'
          : undefined // Will use default
      }
    />
  );
};

export const BrokerLoginHome = props => {
  return <BrokerLogin {...props} landingPage="home" />;
};

export const BrokerLoginMotorTPA = props => {
  return (
    <BrokerLogin
      {...props}
      landingPage="motorTPA"
      showPolicyReference={false}
      showDOB={false}
      showVRN
      dataEntryDescription="Please enter a claim reference number and your vehicle registration number"
      contactSourceDescription="provided by you when you decided to claim through Ageas Insurance"
      thirdParty
    />
  );
};
