import React from 'react';
import styled from 'styled-components';
import {
  noDataMotor,
  noDataMotorWithHours,
  noActivePolicyMotor,
  noActivePolicyMotorWithHours,
  callUsMotor,
  callUsMotorWithHours,
  genericErrorCallUsMotor,
  otherPolicyHolder,
  date3Years,
  otherCircumstanceMotor,
  createClaimFailMotor,
  serverErrorHome,
  serverErrorCustomTel,
  serverErrorCustomTelNoOOH,
  validPolicyErrorHome,
  NoActivePolicyErrorHome,
  noActivePolicyOTP,
  noPolicyOTP,
  noContactDetailsOTP,
  noRecordsMatchedOTP,
  noIdentityMatchedOTP,
  problemInRequestOTP,
  noAuthCodeOTP,
  doesNotMatchOTP,
  OtherItemSelectedHome,
  SomethingElseSelectedHome,
  UnableToProceedWithClaimHome,
  IncorrectHomeClaimCover,
  checkBankDetails,
  InvalidBankDetailsHome,
  invalidBankDetailsMotor,
  receiptUploadErrorHome,
  hubUploadErrorHome,
  hubUploadErrorMotor,
  perilCategoryDocumentUploadErrorHome,
  dataLoadFailingError,
  motorTpDataLoadFailingError,
  tpAssistLinkExpired,
  tpAssistLinkPreviouslyUsed,
  tpAssistServerError,
  date3YearsMotorWithHours,
  concurrentErrorCustomTel,
} from '../errorMessages';

const PageDiv = styled.div`
  padding: 5px;
  max-width: 1300px;
`;
const ErrorDiv = styled.div`
  padding: 5px;
  border: 1px solid black;
`;

const ErrorMessagesRenderer = () => {
  return (
    <PageDiv>
      <h1>
        NOTE: This page is only for text content and OOH. If text
        length/formatting changes it should be reviewed in situ
      </h1>
      <hr />
      <h1>Motor</h1>
      <ErrorDiv>
        invalidBankDetailsMotor: {invalidBankDetailsMotor('13245678')}
      </ErrorDiv>
      <ErrorDiv>noDataMotor: {noDataMotor()}</ErrorDiv>
      <ErrorDiv>noDataMotorWithHours: {noDataMotorWithHours()}</ErrorDiv>
      <ErrorDiv>
        serverErrorCustomTel: {serverErrorCustomTel('motor', '12345678')}
      </ErrorDiv>
      <ErrorDiv>
        serverErrorCustomTelNoOOH:{' '}
        {serverErrorCustomTelNoOOH('motor', '12345678', true, 'motorClaims')}
      </ErrorDiv>
      <ErrorDiv>
        concurrentErrorCustomTel: {concurrentErrorCustomTel('12345678')}
      </ErrorDiv>
      <ErrorDiv>noActivePolicyMotor: {noActivePolicyMotor()}</ErrorDiv>
      <ErrorDiv>
        noActivePolicyMotorWithHours: {noActivePolicyMotorWithHours()}
      </ErrorDiv>
      <ErrorDiv>callUsMotor: {callUsMotor()}</ErrorDiv>
      <ErrorDiv>callUsMotorWithHours: {callUsMotorWithHours()}</ErrorDiv>
      <ErrorDiv>
        genericMotorSaveClaimError:{genericErrorCallUsMotor()}
      </ErrorDiv>
      <ErrorDiv>otherPolicyHolder: {otherPolicyHolder()}</ErrorDiv>
      <ErrorDiv>date3YearsMotor: {date3Years('motor')}</ErrorDiv>
      <ErrorDiv>date3YearsHome: {date3Years('home')}</ErrorDiv>
      <ErrorDiv>
        date3YearsMotorWithHours: {date3YearsMotorWithHours()}
      </ErrorDiv>
      <ErrorDiv>otherCircumstanceMotor: {otherCircumstanceMotor()}</ErrorDiv>
      <ErrorDiv>createClaimFailMotor: {createClaimFailMotor()}</ErrorDiv>
      <br />
      <hr />
      <h1>Home</h1>
      <ErrorDiv>InvalidBankDetailsHome: {InvalidBankDetailsHome()}</ErrorDiv>
      <ErrorDiv>serverErrorHome: {serverErrorHome()}</ErrorDiv>
      <ErrorDiv>
        serverErrorCustomTel: {serverErrorCustomTel('home', '12345678')}
      </ErrorDiv>
      <ErrorDiv>validPolicyErrorHome: {validPolicyErrorHome()}</ErrorDiv>
      <ErrorDiv>NoActivePolicyErrorHome: {NoActivePolicyErrorHome()}</ErrorDiv>
      <ErrorDiv>
        receiptUploadErrorHome:{' '}
        {receiptUploadErrorHome('claimRef', 'perilDesc')}
      </ErrorDiv>
      <ErrorDiv>
        receiptUploadErrorHome AB:{' '}
        {receiptUploadErrorHome('claimRef', 'perilDesc', 'AB')}
      </ErrorDiv>
      <ErrorDiv>
        hubUploadErrorHome: {hubUploadErrorHome('claimRef', 'perilDesc')}
      </ErrorDiv>
      <ErrorDiv>
        hubUploadErrorHome AB:{' '}
        {hubUploadErrorHome('claimRef', 'perilDesc', 'AB')}
      </ErrorDiv>
      <ErrorDiv>
        hubUploadErrorMotor: {hubUploadErrorMotor('claimRef', '')}
      </ErrorDiv>
      <ErrorDiv>
        hubUploadErrorMotor perilDesc AB:{' '}
        {hubUploadErrorMotor('claimRef', 'perilDesc', 'AB')}
      </ErrorDiv>
      <ErrorDiv>
        perilCategoryDocumentUploadErrorHome:{' '}
        {perilCategoryDocumentUploadErrorHome('claimRef')}
      </ErrorDiv>
      <ErrorDiv>
        perilCategoryDocumentUploadErrorHome EOW:{' '}
        {perilCategoryDocumentUploadErrorHome('claimRef', 'escape of water')}
      </ErrorDiv>
      <ErrorDiv>
        perilCategoryDocumentUploadErrorHome EOW AB:{' '}
        {perilCategoryDocumentUploadErrorHome(
          'claimRef',
          'escape of water',
          'AB',
        )}
      </ErrorDiv>
      <ErrorDiv>
        perilCategoryDocumentUploadErrorHome EOW AB doctype:{' '}
        {perilCategoryDocumentUploadErrorHome(
          'claimRef',
          'escape of water',
          'AB',
          'tenancy agreement',
        )}
      </ErrorDiv>
      <ErrorDiv>dataLoadFailingError: {dataLoadFailingError()}</ErrorDiv>
      <ErrorDiv>
        dataLoadFailingErrorMotor: {dataLoadFailingError('motor')}
      </ErrorDiv>
      <ErrorDiv>
        dataLoadFailingErrorMotorTpLob: {dataLoadFailingError('motorTPA')}
      </ErrorDiv>
      <ErrorDiv>
        motorTpDataLoadFailingError: {motorTpDataLoadFailingError()}
      </ErrorDiv>
      <ErrorDiv>tpAssistLinkExpired: {tpAssistLinkExpired()}</ErrorDiv>
      <ErrorDiv>
        tpAssistLinkPreviouslyUsed: {tpAssistLinkPreviouslyUsed()}
      </ErrorDiv>
      <ErrorDiv>tpAssistServerError: {tpAssistServerError()}</ErrorDiv>
      <br />
      <hr />
      <h1>OTP (Motor)</h1>
      <ErrorDiv>noActivePolicyOTP: {noActivePolicyOTP('motor')}</ErrorDiv>
      <ErrorDiv>noPolicyOTP: {noPolicyOTP('motor')}</ErrorDiv>
      <ErrorDiv>noContactDetailsOTP: {noContactDetailsOTP('motor')}</ErrorDiv>
      <ErrorDiv>noRecordsMatchedOTP: {noRecordsMatchedOTP('motor')}</ErrorDiv>
      <ErrorDiv>noIdentityMatchedOTP: {noIdentityMatchedOTP('motor')}</ErrorDiv>
      <ErrorDiv>problemInRequestOTP: {problemInRequestOTP('motor')}</ErrorDiv>
      <ErrorDiv>noAuthCodeOTP: {noAuthCodeOTP('motor')}</ErrorDiv>
      <ErrorDiv>
        doesNotMatchOTP (not an OOH): {doesNotMatchOTP('motor')}
      </ErrorDiv>
      <br />
      <hr />
      <h1>OTP (Home)</h1>
      <ErrorDiv>noActivePolicyOTP: {noActivePolicyOTP('home')}</ErrorDiv>
      <ErrorDiv>noPolicyOTP: {noPolicyOTP('home')}</ErrorDiv>
      <ErrorDiv>noContactDetailsOTP: {noContactDetailsOTP('home')}</ErrorDiv>
      <ErrorDiv>noRecordsMatchedOTP: {noRecordsMatchedOTP('home')}</ErrorDiv>
      <ErrorDiv>noIdentityMatchedOTP: {noIdentityMatchedOTP('home')}</ErrorDiv>
      <ErrorDiv>problemInRequestOTP: {problemInRequestOTP('home')}</ErrorDiv>
      <ErrorDiv>noAuthCodeOTP: {noAuthCodeOTP('home')}</ErrorDiv>
      <ErrorDiv>
        doesNotMatchOTP (not an OOH): {doesNotMatchOTP('home')}
      </ErrorDiv>
      <ErrorDiv>OtherItemSelectedHome: {OtherItemSelectedHome()}</ErrorDiv>
      <ErrorDiv>
        SomethingElseSelectedHome: {SomethingElseSelectedHome()}
      </ErrorDiv>
      <ErrorDiv>
        UnableToProceedWithClaimHome: {UnableToProceedWithClaimHome()}
      </ErrorDiv>
      <ErrorDiv>
        IncorrectHomeClaimCover: {IncorrectHomeClaimCover('CLAIMREF')}
      </ErrorDiv>
      <br />
      <hr />
      <h1>Misc</h1>
      <ErrorDiv>checkBankDetails: {checkBankDetails()}</ErrorDiv>
    </PageDiv>
  );
};

export default ErrorMessagesRenderer;
