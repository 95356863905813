import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemedNotification } from 'ageas-ui-components';
import P from '../StandardTags/P';
import media from '../MediaQuery/MediaQuery';

export const PageContainer = styled.div`
  font-size: 19px;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
`;

export const PageSectionBare = styled.div`
  margin: 0px auto;
  padding: 0px;
  width: 100%;
  max-width: 1100px;
`;

export const PageSection = styled(PageSectionBare)`
  padding: 25px 50px;
`;

export const PageSectionV2 = styled(PageSectionBare)`
  padding: 25px 10px;
  ${media.phone`
    padding-left: 5px;
    padding-right: 5px;
  `}
`;

export const TitleText = styled.h2`
  font-size: 20px;
  ${props =>
    props.primary &&
    css`
      color: #8e419c;
    `}
  ${props =>
    props.white &&
    css`
      color: white;
    `}
  ${props =>
    props.black &&
    css`
      color: black;
    `}
  margin-bottom: 0.25em;
`;

export const TitleTextSpaced = styled(TitleText)`
  margin-bottom: 0.75em;
  ${({ topMargin }) =>
    topMargin &&
    css`
      margin-top: 0.75em;
    `};
`;

export const TitleTextSpacedFlex = styled(TitleTextSpaced)`
  display: flex;
  align-items: center;
  column-gap: 0.4em;
`;

export const StatusText = styled.span`
  font-size: 20px;
  color: #6fa313;
`;

export const SubTextCore = styled.div`
  font-size: 18px;
  color: #646464;
  margin-top: 8px;
`;

export const SubText = styled(SubTextCore)`
  font-weight: 100;
`;

export const PanelInner = styled.div`
  padding: 21px 21px;
  background-color: white;
`;
export const PanelInnerGrey = styled(PanelInner)`
  background-color: rgb(235, 235, 235);
`;
export const PZeroTop = styled(P)`
  margin-top: 0px;
`;
export const BoldBlack = styled.span`
  font-weight: bold;
  color: black;
`;

export const SubTextNoMargin = styled(SubText)`
  margin-top: 0px;
`;

export const LabelText = styled.span`
  display: inline-block;
  min-width: 170px;
`;

export const SubTextCenter = styled(SubText)`
  text-align: center;
  line-height: 1.6;
  margin: 17px 0px 20px 0px;
  padding: 0% 20% 0% 20%;
`;

export const ErrorTextContainer = styled(SubTextCenter)`
  text-align: left;
  padding: 0% 2%;
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
`;

export const NavigationLink = styled(Link)`
  color: #208de9;
  text-decoration: none;
`;

export const BackButtonContainer = styled.div`
  margin: 12px 0px 43px 0px;
`;

export const BasicSection = styled.div`
  margin-bottom: 20px;
`;

export const Subsection = styled.div`
  padding: 25px 0px;
`;

export const ButtonsBottomContainer = styled(Subsection)`
  text-align: right;
  width: 100%;
`;

export const TextColor = styled.span`
  color: #8e419c;
`;

export const SummaryContainer = styled(BasicSection)`
  display: flex;
  column-gap: 16px;
  align-items: stretch;
  margin-top: 20px;
`;

export const BorderLine = styled.div`
  border-bottom: 2px solid #646464;
  &:last-child {
    border-bottom: none;
  }
`;

export const SettlementPaymentSeparator = styled.div`
  border-bottom: 2px solid #8e419c;
`;

export const ToggleIcon = styled.img`
  transition: transform 0.2s linear;
  transform: ${({ open }) => (open ? 'rotate(-180deg)' : 'rotate(-360deg)')};
`;
export const ToggleIconButton = styled.button`
  border: 1px solid transparent;
  border-radius: 18.5px;
  background-color: transparent;
  padding: 5px;
  justify-content: left;
  align-items: center;
  &:hover,
  &:focus {
    background-color: #fff;
    cursor: pointer;
    border-color: ${props => (props.isChecked ? '#db2828' : 'transparent')};
  }
`;

export const IconContainer = styled.div`
  padding-left: 30px;
  ${media.phone`
    padding-left: 15px;
  `}
`;

export const SubContainer = styled.div`
  padding-bottom: 20px;
`;

export const HeaderContainer = styled.div`
  min-width: 142px;
  min-height: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: center;
  padding: 2px 5px;
  align-items: stretch;
`;

export const ParagraphContainer = styled.div`
  margin-left: 9px;
`;

export const LabelSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.25em;
  text-align: left;
`;
export const LabelSetRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 5px;
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `};
`;
export const LabelSetLabel = styled(SubTextNoMargin)`
  color: #8e419c;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
`;
export const LabelSetContent = styled(SubTextNoMargin)`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-wrap: anywhere;
`;

export const OfferValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 16px;
  text-align: left;
  justify-content: flex-start;
  margin: 16px 0;
`;

export const OfferValue = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 200px;
  flex-grow: 1;
  ${({ isSummary }) =>
    isSummary &&
    css`
      font-weight: bold;
    `}
`;

export const ClosedNotification = styled(ThemedNotification)`
  color: green;
  border-color: green;
  margin-top: 16px;
`;

export const VrnDisplay = styled.div`
  font-family:
    Proxima nova,
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-size: 20px;
  font-weight: 700;
  padding: 4px 8px;
  border: 1px solid darkgray;
  border-radius: 3px;
  background-color: #ffdd00;
  margin-bottom: 16px;
  width: fit-content;
`;
