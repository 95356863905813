import { caseConverter } from './caseConverter';

const CLAIM_STATUSES_BASIC = {
  OPEN: {
    code: '4',
    description: 'Open',
  },
  CLOSED: {
    code: '5',
    description: 'Closed',
  },
  INCOMPLETE: {
    code: '6',
    description: 'Incomplete',
  },
  PROCESSING: {
    code: '',
    description: 'Processing',
  },
};

export const CLAIM_STATUS_LONG_CODES = Object.keys(CLAIM_STATUSES_BASIC);

export const CLAIM_STATUS_CODES = CLAIM_STATUS_LONG_CODES.map(
  status => CLAIM_STATUSES_BASIC[status].code,
);

// Add other descriptions
// lower:             lower case
// upper:             UPPER CASE
// capitaliseFirst:   Capitalise first word
// capitalise:        Capitalise All Words
CLAIM_STATUS_LONG_CODES.forEach(statusCode => {
  const status = CLAIM_STATUSES_BASIC[statusCode];
  status.lower = caseConverter(status.description, 'lower');
  status.upper = caseConverter(status.description, 'upper');
  status.capitaliseFirst = caseConverter(status.description, 'capitaliseFirst');
  status.capitalise = caseConverter(status.description, 'capitalise');
});

export const CLAIM_STATUSES = CLAIM_STATUSES_BASIC;

export const CLAIM_STATUSES_LIST = Object.keys(CLAIM_STATUSES).map(
  peril => CLAIM_STATUSES_BASIC[peril],
);

export const getStatusByCode = code =>
  CLAIM_STATUSES_LIST.find(status => status.code === code);
