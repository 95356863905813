import React from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundErrorView } from '../../views/ErrorViews/NotFoundErrorView';
import NotFoundRoute from '../NotFoundRoute';
import EclaimsRoute from '../EclaimsRoute';
import { perilRoutes } from './HomeClaimRoutePaths';
import { HomeClaimsHistoryEscapeOfWater } from '../../views/HomeClaimsHistory/HomeClaimsHistory';
import DataContextProvider from '../../contexts/DataContext/DataContext';
import {
  EscapeOfWaterContext,
  localStorageEscapeOfWaterPropertyName,
} from '../../helpers/escapeOfWaterContextHelper';
import config from '../../helpers/config';
import { getHashedUidFromCookies } from '../../contexts/AuthContext';
import EscapeOfWaterClaim from '../../views/EscapeOfWaterClaim/EscapeOfWaterClaim';
import EscapeOfWaterResult from '../../views/EscapeOfWaterResult/EscapeOfWaterResult';
import EscapeOfWaterThankYouPage from '../../views/EscapeOfWaterThankYouPage/EscapeOfWaterThankYouPage';

const homeClaimsPrivateProps = {
  private: true,
  authTypes: ['otp', 'basic'],
  lobs: 'home',
};
const paths = perilRoutes.EW;
const EscapeOfWaterClaimRoutes = () => {
  return (
    <DataContextProvider
      Context={EscapeOfWaterContext}
      localStoragePropertyName={localStorageEscapeOfWaterPropertyName}
      localStorageExpiryDays={config.client.localStorageEscapeOfWaterExpiryDays}
      localStorageGetMatchUID={getHashedUidFromCookies}
      localStorageGetEncryptUserKey={getHashedUidFromCookies}
    >
      <Switch>
        <EclaimsRoute
          exact
          path={paths.history}
          component={HomeClaimsHistoryEscapeOfWater}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.EW.incidentDetails}
          component={EscapeOfWaterClaim}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.EW.results}
          component={EscapeOfWaterResult}
          {...homeClaimsPrivateProps}
        />
        <EclaimsRoute
          exact
          path={perilRoutes.EW.thankYou}
          component={EscapeOfWaterThankYouPage}
          {...homeClaimsPrivateProps}
        />

        <NotFoundRoute component={NotFoundErrorView} />
      </Switch>
    </DataContextProvider>
  );
};

export default EscapeOfWaterClaimRoutes;
