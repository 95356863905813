import React from 'react';

const title = 'Making claims easy';
const titleJSX = <>{title}</>;
const text =
  'Whether you bought your insurance directly from Ageas, or through an intermediary such as a broker or price comparison website, we’re here to help and get the ball rolling as quickly as we can.';
const textJSX = <>{text}</>;

const makingClaimsEasy = {
  title,
  titleJSX,
  text,
  textJSX,
};

export default makingClaimsEasy;
