import React from 'react';
import FaqP from '../../FaqP';

export const title = 'Not sure whether to make a claim?';
export const content = (
  <FaqP>
    You can find what your home insurance covers you for by checking your policy
    booklet. Your policy documents will also tell you what excess may be
    applicable, depending on the type of claim you wish to make.
  </FaqP>
);

export const pair = { title, content };
