import styled, { css } from 'styled-components';

import ThemedButton from 'ageas-ui-components/lib/components/ThemedButton/ThemedButton';
import media from '../../../components/MediaQuery/MediaQueryMin';
import maxMedia from '../../../components/MediaQuery/MediaQuery';
import ThemedLinkButton from '../../../components/ThemedLinkButton/ThemedLinkButton';

const LinkContainer = styled.div`
  display: flex;
`;

const Link = styled.a`
  text-decoration: none;
`;

const Logo = styled.figure`
  height: 65px;
  overflow: hidden;
  max-width: 100%;
  display: block;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  color: ${props => props.theme.header.title};
  text-decoration: none;
  img {
    width: auto;
    height: 110px;
    padding-right: 5px;
  }
  figcaption {
    text-decoration: none;
  }

  @media (min-width: 576px) {
    height: 110px;
    overflow: visible;
    margin: 10px 0 0 0;
    max-width: 30%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Header = styled.header`
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  width: 100%;
  ${({ maxOuterWidth }) =>
    maxOuterWidth &&
    css`
      max-width: ${maxOuterWidth};
    `};
  margin-right: auto;
  margin-left: auto;
  ${props =>
    props.logoutPosition &&
    css`
      display: flex;
      position: relative;
    `}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 100%;
  min-width: 100%;
  padding: 0 0.5em;
  margin: 0 auto;
  box-sizing: border-box;

  ${media.desktop`
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: auto;
  display: grid;
  grid-template-columns: 1fr 255px;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'main sidebar' 'formNav sidebar';
  grid-gap: 1em;
  padding: 0 2em;
  ${props =>
    props.fixedWidthMode &&
    css`
      display: flex;
      flex-direction: row;
      max-width: none;
      padding: 0 0.5em;
    `}
    `}
`;

const UnorderedListHeaderContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  ${maxMedia.desktop`
    left: 50%;
  `}

  ${maxMedia.tablet`
    left: 38%;
  `}

  li {
    padding: 0px 10px;
    text-decoration: none;
  }
  li:last-child {
    padding-right: 0px;
  }

  @media only screen and (max-width: 575px) {
    flex-flow: column nowrap;
    background-color: #712d7d;
    position: fixed;
    transition: all -0.4s ease-out;
    transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(-100%)')};
    top: 63px;
    left: 0%;
    height: 100vh;
    width: 100%;
    z-index: 1;
    li {
      background-color: #8e419c;
      color: white;
      height: 55px;
      border-bottom: 1px solid #591f64;
    }
  }
`;

const HeaderLinkButton = styled(ThemedLinkButton)`
  font-size: 14px;
  ${maxMedia.phone`
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  `}
`;

const HeaderButton = styled(ThemedButton)`
  font-size: 14px;
  ${maxMedia.phone`
  text-align: left;
  will-change: auto;
  font-size: 20px;
  font-weight: 700;
  `}
`;

export {
  LinkContainer,
  Link,
  Logo,
  Container,
  Header,
  HeaderLinkButton,
  HeaderButton,
  UnorderedListHeaderContainer,
};
