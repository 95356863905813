import Joi from '@hapi/joi';

import moment from 'moment';
import { maritalStatus, maritalStatusAddDriver } from './schemas/MaritalStatus';
import { email } from './schemas/Email';
import { parentChild } from './schemas/ParentChild';
import {
  stringRequired,
  dateRequired,
  numberRequired,
  objectRequired,
} from './schemas/Required';
import { webReference } from './schemas/WebReference';
import { ukBankAccount, ukSortCode } from './schemas/UKBankAccount';
import { excludeChannelIslands } from './schemas/ExcludeChannelIslands';
import { registrationNumber } from './schemas/RegistrationNumber';
import { ukPostcode } from './schemas/UKPostcode';
import { stringLength, stringLengthBetween } from './schemas/String';
import { ukTelephoneNumber } from './schemas/Telephone';
import { requiredIfEmpty } from './schemas/RequiredIf';
import { regularExpression } from './schemas/RegularExpression';
import { range } from './schemas/Range';
import { employmentValidations } from './schemas/Employment';
import { integer } from './schemas/Types';
import { mileage, businessMileage } from './schemas/Mileage';
import { messages } from './constants/ErrorMessages';
import {
  dateLessThanOrEqualTo,
  dateGreaterThanOrEqualTo,
} from './schemas/Date';
import { minLength } from './schemas/Min';
import { maxLength, max } from './schemas/Max';
import {
  onlyOneCivilPartner,
  onlyOneSpouse,
  mustBeMarried,
  mustBeCivilPartner,
} from './schemas/Relationship';
import { dependentArray } from './schemas/DependentArray';
import { licenceHeldForYears } from './schemas/Licence';
import { mustBeNo } from './schemas/MustBe';
import directDebitRenewalsSchema from './schemas/DirectDebitRenewals';
import { houseNameOrNumber, addressLine } from './schemas/Address';
import { subMotorClaimType } from './schemas/MotorClaimsType';

const defaultSchemas = email()
  .concat(maritalStatus())
  .concat(maritalStatusAddDriver())
  .concat(parentChild())
  .concat(dateRequired(['proposerDateOfBirth', 'additionalInsuredDateOfBirth']))
  .concat(
    stringRequired(['additionalInsuredRelationshipToProposer', 'addInsMarSta']),
  )
  .concat(webReference())
  .concat(ukBankAccount())
  .concat(ukSortCode())
  .concat(excludeChannelIslands())
  .concat(registrationNumber())
  .concat(ukPostcode())
  .concat(stringLength(50, ['email']))
  .concat(ukTelephoneNumber())
  .concat(requiredIfEmpty(['registration']))
  .concat(regularExpression(/^[0-9]*.*$/, 'email'))
  .concat(range(0, 10, ['rangeTest']))
  .concat(employmentValidations());

const date18YearsAgo = moment()
  .subtract(18, 'years')
  .format('DD/MM/YYYY');

const date17YearsAgo = moment()
  .subtract(17, 'years')
  .format('DD/MM/YYYY');

const date1YearAgo = moment()
  .subtract(1, 'years')
  .format('DD/MM/YYYY');

const today = moment().format('DD/MM/YYYY');

export const noSpacesOrSpecialCharactersRegex = /^(\d|\w)+$/;
export const europeanCharacterNameValidationRegex = /^[ a-zA-ZÀ-ÖØ-öø-ÿ'-]+$/;
export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*-]+$/;

export const mtaSchema = {
  seats: integer(['seats'], messages.EMPTY_OPTION),
  registration: registrationNumber(
    ['registration'],
    'Please enter a valid registration number.',
  ),
  carMake: stringRequired(['carMake'], 'Please enter a valid make of car.'),
  yearOfRegistration: integer(
    ['yearOfRegistration'],
    'Please enter a valid integer.',
  ),
  typeOfFuel: stringRequired(['typeOfFuel'], 'Please enter a valid fuel type.'),
  carModel: stringRequired(['carModel'], 'Please enter a valid car model.'),
  manualOrAutomatic: stringRequired(
    ['manualOrAutomatic'],
    messages.EMPTY_OPTION,
  ),
  dateOfCarPurchase: dateRequired(
    ['dateOfCarPurchase'],
    'Please enter a purchase date.',
  ),
  parkDuringDay: stringRequired(['parkDuringDay'], messages.DROPDOWN_VALUE),
  carMileage: mileage(['carMileage']),
  carBusinessMileage: businessMileage(['carBusinessMileage'], 'carMileage'),
  carValue: integer(['carValue'], messages.CAR_VALUE),
  isTrackingDeviceFitted: stringRequired(
    ['isTrackingDeviceFitted'],
    messages.TRACKING_DEVICE_NOT_ENTERED,
  ),
  keeper: stringRequired(['keeper'], messages.DROPDOWN_VALUE),
  ownerAndRegisteredKeeper: stringRequired(
    ['ownerAndRegisteredKeeper'],
    messages.EMPTY_OPTION,
  ),
  dashcamFitted: stringRequired(['dashcamFitted'], messages.DASHCAM_MISSING),
  owner: stringRequired(['owner'], messages.DROPDOWN_VALUE),
  isCarModified: dependentArray(
    'isCarModified',
    'modifications',
    messages.EMPTY_OPTION,
    'Please enter some modifications details',
  ),
  isCarModifiedYN: stringRequired(
    ['isCarModifiedYN'],
    messages.EMPTY_OPTION,
  ).concat(mustBeNo(['isCarModifiedYN'], messages.CAR_MODIFIED)),

  category: stringRequired(['category'], messages.EMPTY_OPTION),
  type: stringRequired(['type'], messages.EMPTY_OPTION),
  isCarKeptOvernight: stringRequired(
    ['isCarKeptOvernight'],
    messages.SELECT_KEPT_OVERNIGHT,
  ),
  whereKept: stringRequired(['whereKept'], messages.EMPTY_OPTION),
  whereKeptDUQs: stringRequired(
    ['whereKeptDUQs'],
    messages.WHERE_KEPT_DUQS_NOT_ENTERED,
  ),
  parkOvernightPostcode: ukPostcode(
    ['parkOvernightPostcode'],
    'We need to know this postcode',
  ),
  firstName: stringRequired(['firstName'], messages.FIRST_NAME_NOT_ENTERED)
    .concat(
      stringLengthBetween(
        2,
        50,
        ['firstName'],
        messages.FIRST_NAME_INCORRECT_LENGTH,
      ),
    )
    .concat(
      regularExpression(
        europeanCharacterNameValidationRegex,
        ['firstName'],
        messages.NAME_INVALID,
      ),
    ),
  surname: stringRequired(['surname'], messages.SURNAME_NOT_ENTERED)
    .concat(
      stringLengthBetween(
        2,
        50,
        ['surname'],
        messages.SURNAME_INCORRECT_LENGTH,
      ),
    )
    .concat(
      regularExpression(
        europeanCharacterNameValidationRegex,
        ['surname'],
        messages.NAME_INVALID,
      ),
    ),
  dob: dateRequired(['dob'], messages.INVALID_DATE_OF_BIRTH).concat(
    dateLessThanOrEqualTo(
      date17YearsAgo,
      ['dob'],
      messages.DATE_OF_BIRTH_UNDER_17,
    ),
  ),
  customerTitle: stringRequired(['customerTitle'], messages.TITLE_MISSED),
  customerGender: stringRequired(['customerGender'], messages.GENDER_MISSED),
  customerResidency: stringRequired(
    ['customerResidency'],
    messages.SELECT_OPTION,
  ),
  residencyDate: dateRequired(['residencyDate'], 'Please pick a date').concat(
    dateLessThanOrEqualTo(
      today,
      ['residencyDate'],
      'Residency date cannot be in the future',
    ),
  ),
  employmentStatus: stringRequired(['employmentStatus'], messages.EMPTY_OPTION),
  driverOccupation: objectRequired(['driverOccupation'], messages.EMPTY_OPTION),
  industryType: objectRequired(['industryType'], messages.INDUSTRY_TYPE),
  secondOccupationEmploymentStatus: stringRequired(
    ['secondOccupationEmploymentStatus'],
    messages.EMPTY_OPTION,
  ),
  secondOccupationRole: objectRequired(
    ['secondOccupationRole'],
    messages.EMPTY_OPTION,
  ),
  secondOccupationIndustry: objectRequired(
    ['secondOccupationIndustry'],
    messages.INDUSTRY_TYPE,
  ),
  secondJob: stringRequired(['secondJob'], messages.SELECT_OPTION),
  customerRelation: stringRequired(
    ['customerRelation'],
    'Please pick an option',
  )
    .concat(maritalStatusAddDriver())
    .concat(onlyOneSpouse())
    .concat(onlyOneCivilPartner())
    .concat(mustBeMarried())
    .concat(mustBeCivilPartner()),
  newDriverMaritalStatus: stringRequired(
    ['newDriverMaritalStatus'],
    'Please pick a marital status',
  ).concat(maritalStatusAddDriver()),
  customerHasClaims: dependentArray(
    'customerHasClaims',
    'claims',
    messages.SELECT_OPTION,
    'Please enter some claims details',
  ),
  claimsDropdown: stringRequired(['claimsDropdown'], messages.EMPTY_OPTION),
  claimsSubDropdown: stringRequired(
    ['claimsSubDropdown'],
    messages.EMPTY_OPTION,
  ),
  isDriverAtFault: stringRequired(['isDriverAtFault'], messages.SELECT_OPTION),
  claimDate: dateRequired(['claimDate'], messages.PROVIDE_DATE).concat(
    dateLessThanOrEqualTo(
      today,
      ['claimDate'],
      "Date must be today's date or before",
    ),
  ),
  customerHasConvictions: dependentArray(
    'customerHasConvictions',
    'convictions',
    messages.SELECT_OPTION,
    'Please enter some conviction details',
  ),
  offenceTypeDropdown: stringRequired(
    ['offenceTypeDropdown'],
    messages.EMPTY_OPTION,
  ),
  offenceCodeDropdown: stringRequired(
    ['offenceCodeDropdown'],
    messages.EMPTY_OPTION,
  ),
  offenceReading: stringRequired(['offenceReading'], messages.EMPTY_OPTION),
  offenceReadingLevel: integer(
    ['offenceReadingLevel'],
    messages.CAR_VALUE,
  ).concat(
    max(250, ['offenceReadingLevel'], true, messages.DRINK_LEVEL_MAX_250),
  ),
  offencePoints: integer(['offencePoints'], messages.CAR_VALUE),
  offenceFine: integer(['offenceFine'], messages.CAR_VALUE),
  offenceDate: dateRequired(['offenceDate'], messages.PROVIDE_DATE).concat(
    dateLessThanOrEqualTo(
      today,
      ['offenceDate'],
      "Date must be today's date or before",
    ),
  ),
  offenceBan: stringRequired(['offenceBan'], messages.SELECT_OPTION),
  offenceBanSub: integer(['offenceBanSub'], messages.CAR_VALUE),
  customerLicenceType: stringRequired(
    ['customerLicenceType'],
    messages.LICENCE_TYPE_MISSING,
  ),
  customerLicenceDuration: numberRequired(
    ['customerLicenceDuration'],
    messages.LICENCE_DURATION_MISSING,
  ).concat(licenceHeldForYears()),
  customerMultipleCarUsage: stringRequired(
    ['customerMultipleCarUsage'],
    messages.MULTIPLE_CAR_INFO_MISSING,
  ),
  customerLicenceDurationDate: stringRequired(
    ['customerLicenceDurationDate'],
    'Please pick a date',
  )
    .concat(
      dateLessThanOrEqualTo(
        today,
        ['customerLicenceDurationDate'],
        messages.LICENCE_ACQUIRED_IN_THE_FUTURE,
      ),
    )
    .concat(
      dateGreaterThanOrEqualTo(
        date1YearAgo,
        ['customerLicenceDurationDate'],
        messages.CUSTOM_LICENCE_DATE_MORE_THAN_ONE_YEAR,
      ),
    ),
  hasMedicalCondition: stringRequired(
    ['hasMedicalCondition'],
    messages.SELECT_OPTION,
  ).concat(mustBeNo(['hasMedicalCondition'], messages.HAS_MEDICAL_CONDITION)),
  hasConvictions: stringRequired(
    ['hasConvictions'],
    messages.SELECT_OPTION,
  ).concat(mustBeNo(['hasConvictions'], messages.HAS_CONVICTIONS)),
  hasPolicyRefused: stringRequired(
    ['hasPolicyRefused'],
    messages.SELECT_OPTION,
  ).concat(mustBeNo(['hasPolicyRefused'], messages.HAS_POLICY_REFUSED)),
  houseNameOrNumber: stringRequired(
    ['houseNameOrNumber'],
    messages.EMPTY_STRING,
  ).concat(
    houseNameOrNumber(['houseNameOrNumber'], messages.INVALID_ADDRESS_LINE),
  ),
  addressLine1: stringRequired(['addressLine1'], messages.EMPTY_STRING).concat(
    addressLine(['addressLine1']),
  ),
  addressLine2: stringRequired(['addressLine2'], messages.EMPTY_STRING).concat(
    addressLine(['addressLine2']),
  ),
  addressLine3: addressLine(['addressLine3'], messages.INVALID_ADDRESS_LINE),
  addressLine4: addressLine(['addressLine4'], messages.INVALID_ADDRESS_LINE),
  postCode: stringRequired(['postCode'], messages.POSTCODE_MISSING).concat(
    ukPostcode(['postCode'], messages.INVALID_POSTCODE),
  ),
  subClaimType: subMotorClaimType(
    ['subClaimType'],
    ['motorClaimType'],
    messages.SUB_MOTOR_CLAIMS_TYPE,
  ),
};

export const quoteSummarySchema = {
  payInFull: stringRequired(
    ['payInFull'],
    messages.PAYMENT_OPTION_NOT_SELECTED,
  ),
  installmentsPayment: stringRequired(
    ['installmentsPayment'],
    messages.PAYMENT_OPTION_NOT_SELECTED,
  ),
  payViaWallet: stringRequired(
    ['payViaWallet'],
    messages.PAYMENT_OPTION_NOT_SELECTED,
  ),
};

export const registrationSchema = {
  policyId: stringRequired(['policyId'], messages.POLICY_NUMBER_MISSING).concat(
    regularExpression(
      noSpacesOrSpecialCharactersRegex,
      ['policyId'],
      messages.POLICY_NUMBER_INVALID,
    ),
  ),
  dob: dateRequired(['dob'], messages.DATE_OF_BIRTH_MISSING).concat(
    dateLessThanOrEqualTo(
      date18YearsAgo,
      ['dob'],
      messages.DATE_OF_BIRTH_UNDER_18,
    ),
  ),
  postcode: stringRequired(['postcode'], messages.POSTCODE_MISSING).concat(
    ukPostcode(['postcode'], messages.INVALID_POSTCODE),
  ),
  password: stringRequired(
    ['password'],
    messages.REGISTRATION_PASSWORD_EMPTY,
  ).concat(
    minLength(8, ['password'], messages.INVALID_REGISTRATION_PASSWORD)
      .concat(maxLength(16, ['password'], messages.PASSWORD_CHARS_LENGTH))
      .concat(
        regularExpression(
          passwordRegex,
          ['password'],
          messages.INVALID_REGISTRATION_PASSWORD,
        ),
      ),
  ),
  username: stringRequired(['username'], messages.EMAIL).concat(
    email(['username'], messages.EMAIL),
  ),
};

export const forgottenUsernameSchema = {
  surname: stringRequired(['surname'], messages.SURNAME_MISSING)
    .concat(
      stringLengthBetween(
        2,
        50,
        ['surname'],
        messages.SURNAME_INCORRECT_LENGTH,
      ),
    )
    .concat(
      regularExpression(
        europeanCharacterNameValidationRegex,
        ['surname'],
        messages.NAME_INVALID,
      ),
    ),
  dob: dateRequired(['dob'], messages.DATE_OF_BIRTH_MISSING).concat(
    dateLessThanOrEqualTo(
      date18YearsAgo,
      ['dob'],
      messages.DATE_OF_BIRTH_UNDER_18,
    ),
  ),
  postcode: ukPostcode(['postcode'], messages.INVALID_POSTCODE).concat(
    stringRequired(['postcode'], 'Please enter a valid postcode'),
  ),
};

const defaultConfig = {
  abortEarly: false,
  allowUnknown: true,
  presence: 'required',
};

export const validate = (
  form,
  schema = defaultSchemas,
  config = defaultConfig,
) => Joi.validate(form, schema, config);

export const validateNoDefault = (form, schema, config = defaultConfig) =>
  Joi.validate(form, schema, config);

// validates an individual part of a form
export const individualValidator = (
  value,
  schema,
  inputFieldName,
  config = defaultConfig,
) => {
  const form = {};
  form[inputFieldName] = value;
  const result = Joi.validate(form, schema[inputFieldName], config);
  return result;
};

export { directDebitRenewalsSchema };
export default validate;
