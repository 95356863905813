import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

const validKeys = ['telephoneNumber'];

/**
 * @description: Validate an uk telephone number
 * @param keys Fields to validate
 * @param message Message to display when not a valid date.
 * @succeeds When value is a valid uk postcode
 * @fails When value is not a valid uk postcode
 */
export const ukTelephoneNumber = (
  keys = validKeys,
  message = messages.INVALID_TELEPHONE_NUMBER,
) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex.telephoneNumber)
      .required()
      .error(() => message),
  );
};

export default ukTelephoneNumber;
