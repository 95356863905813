import React, { useState } from 'react';
import {
  ThemedLabel,
  ThemedInput,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import { regularExpression } from 'ageasvalidation/lib/schemas/RegularExpression';
import FieldStyled from '../FieldStyled/FieldStyled';
import FieldInnerBlockContainer from '../FieldInnerBlockContainer/FieldInnerBlockContainer';
import allowedKeyboardCharactersRegex from '../../../helpers/allowedKeyboardCharactersRegex';
import regularExpressionOptional from '../../../helpers/validation/regularExpressionOptional';

// For documentation see:
// /docs/components/Forms/StandardInput/StandardInput.md

const DEFAULT_INVALID_CHARACTER_MESSAGE =
  'Please only use standard keyboard characters such as letters, numbers and punctuation marks';

export const getStandardInputRequiredSchema = (
  fieldName,
  customRequiredError,
) => {
  return {
    [fieldName]: stringRequired(
      [fieldName],
      customRequiredError || 'Please enter a value',
    )
      .concat(
        // Nonblank (at least one non-whitespace char)
        regularExpression(
          /\S/,
          [fieldName],
          customRequiredError || 'Please enter a value',
        ),
      )
      .concat(
        regularExpression(
          allowedKeyboardCharactersRegex,
          [fieldName],
          DEFAULT_INVALID_CHARACTER_MESSAGE,
        ),
      ),
  };
};

export const getStandardInputOptionalSchema = fieldName => {
  return {
    [fieldName]: regularExpressionOptional(
      allowedKeyboardCharactersRegex,
      [fieldName],
      DEFAULT_INVALID_CHARACTER_MESSAGE,
    ),
  };
};

const StandardInput = ({
  id,
  name,
  label,
  secondaryLabel,
  validate,
  alwaysShowError,
  type,
  children,
  onChange,
  beforeBlur,
  fieldProps,
  labelProps,
  ...props
}) => {
  const [elementId] = useState(id || `StandardInput-${name}-${_uniqueId()}`);

  const onInputChange = (event, input) => {
    input.onChange(event);
    onChange(event);
  };

  const onInputBlur = (event, input) => {
    if (!beforeBlur || beforeBlur(event) !== false) input.onBlur(event);
  };

  return (
    <FieldStyled name={name} validate={validate} type={type} {...fieldProps}>
      {({ input, meta }) => (
        <>
          <ThemedLabel fieldName={elementId} {...labelProps}>
            {label}
            {secondaryLabel && (
              <>
                <br />
                <small>{secondaryLabel}</small>
              </>
            )}
          </ThemedLabel>
          <FieldInnerBlockContainer marginTop="-6px">
            <ThemedInput
              stretchOnNarrow
              {...props}
              id={elementId}
              {...input}
              onBlur={event => {
                onInputBlur(event, input);
              }}
              onChange={event => {
                onInputChange(event, input);
              }}
              validInput={{
                isValid: !(meta.error && meta.touched),
              }}
              invalidInput={{
                isInvalid: meta.error && meta.touched,
              }}
              hasError={meta.error && (meta.touched || alwaysShowError)}
            />
          </FieldInnerBlockContainer>
          {meta.error && (meta.touched || alwaysShowError) && (
            <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
          )}
          {children}
        </>
      )}
    </FieldStyled>
  );
};

StandardInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  validate: PropTypes.func,
  children: PropTypes.node,
  alwaysShowError: PropTypes.bool,
  onChange: PropTypes.func,
  beforeBlur: PropTypes.func,
  fieldProps: PropTypes.shape({}),
  labelProps: PropTypes.shape({}),
};

StandardInput.defaultProps = {
  id: undefined,
  label: '',
  secondaryLabel: undefined,
  type: undefined,
  validate: () => {},
  children: undefined,
  alwaysShowError: false,
  onChange: () => {},
  beforeBlur: undefined,
  fieldProps: {},
  labelProps: {},
};

export default StandardInput;
