import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DAMAGE_AREAS, {
  DAMAGE_LEVELS,
  damageAreaValuesPropType,
  getDamageLevelByName,
} from './damageValues';

const BORDER_RADIUS = '4px';
const Container = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || `16px`};
  background-color: #ebebeb;
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;
const Header = styled.div`
  border-top-right-radius: ${BORDER_RADIUS};
  border-top-left-radius: ${BORDER_RADIUS};
  background-color: #dedede;

  padding: 8px 16px;
`;
const Body = styled.div`
  border-bottom-right-radius: ${BORDER_RADIUS};
  border-bottom-left-radius: ${BORDER_RADIUS};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const EntryStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NoEntryStyled = styled(EntryStyled)`
  align-items: center;
`;
const NoEntry = ({ isSingle = false }) => (
  <NoEntryStyled>
    <span>--- No{isSingle ? '' : ' area of'} damage selected ---</span>
  </NoEntryStyled>
);

NoEntry.propTypes = {
  isSingle: PropTypes.bool,
};

NoEntry.defaultProps = {
  isSingle: undefined,
};

const Entry = ({ values, damageArea, booleanMode }) => {
  const value = booleanMode
    ? values?.includes(damageArea.name)
    : values[damageArea.name];

  if (!value || value === DAMAGE_LEVELS.NONE.name) {
    return null;
  }
  return (
    <EntryStyled>
      <strong>{damageArea.label}</strong>
      {typeof value === 'string' && (
        <span>{getDamageLevelByName(value)?.label}</span>
      )}
    </EntryStyled>
  );
};

Entry.propTypes = {
  values: PropTypes.instanceOf(Object),
  damageArea: PropTypes.instanceOf(Object).isRequired,
  booleanMode: PropTypes.bool.isRequired,
};

Entry.defaultProps = {
  values: undefined,
};

const VehicleDamageResultsDisplay = ({
  className,
  values,
  children,
  marginBottom,
  isSingle,
  booleanMode = false,
}) => {
  const hasEntries =
    (booleanMode && values?.length) || !!Object.keys(values).length;

  const entryProps = {
    booleanMode,
    values,
  };

  return (
    <Container marginBottom={marginBottom} className={className}>
      <Header>Selected {booleanMode ? 'area(s)' : 'level'} of damage</Header>
      <Body>
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.REAR_DRIVER} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.REAR} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.REAR_PASSENGER} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.SIDE_DRIVER} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.ROOF} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.SIDE_PASSENGER} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.FRONT_DRIVER} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.FRONT} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.FRONT_PASSENGER} />
        <Entry {...entryProps} damageArea={DAMAGE_AREAS.UNDERSIDE} />
        {!hasEntries && <NoEntry isSingle={isSingle} />}
        {children}
      </Body>
    </Container>
  );
};

export default VehicleDamageResultsDisplay;

VehicleDamageResultsDisplay.propTypes = {
  className: PropTypes.string,
  values: damageAreaValuesPropType,
  children: PropTypes.node,
  marginBottom: PropTypes.string,
  isSingle: PropTypes.bool,
  booleanMode: PropTypes.bool,
};

VehicleDamageResultsDisplay.defaultProps = {
  className: undefined,
  values: undefined,
  children: undefined,
  marginBottom: undefined,
  isSingle: undefined,
  booleanMode: undefined,
};
