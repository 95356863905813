import Joi from '@hapi/joi';

import { messages } from '../constants/ErrorMessages';
import { regex } from '../constants/RegularExpressions';

const validKeys = ['postcode', 'KeptPostcode'];

/**
 * @description: Validates fields to ensure they are not a channel islands postcode
 * @param keys The fields to validate
 * @succeeds when the field value is not a channel islands postcode
 * @fails when the field value is a channel islands postcode
 */
export const excludeChannelIslands = (keys = validKeys) => {
  return Joi.object().pattern(
    Joi.valid(keys),
    Joi.string()
      .regex(regex.channelIslandsPostcode)
      .required()
      .error(() => messages.CHANNEL_ISLANDS_NOT_ALLOWED),
  );
};

export default excludeChannelIslands;
