import React, { useState, useContext, useEffect } from 'react';

import { AccordionSection, FieldDisplay } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { MotorFormContext } from '../../helpers/motorFormStateHelper';
import { motorCircumstanceCodes } from '../../helpers/motorCircumstanceCodesHelper';
import ThemedLinkButton from '../ThemedLinkButton/ThemedLinkButton';
import {
  EditWrapper,
  SummaryContent,
  FieldFlexColumn,
  EditFlexColumn,
} from './Summary.style';
import SummaryIncompleteError from './SummaryIncompleteError';

const IncidentDetailsSummary = ({ title, theming }) => {
  const { dataState } = useContext(MotorFormContext);
  const [pageFormState, setPageFormState] = useState({});
  const [displayBody, setDisplayBody] = useState(true);

  const toggleBody = () => {
    setDisplayBody(!displayBody);
  };
  const getCircumstanceValue = code => {
    const circumvalue = motorCircumstanceCodes.find(
      element => element.value === code,
    );
    return circumvalue?.label || circumvalue?.value || 'Unknown';
  };

  useEffect(() => {
    setPageFormState({ ...dataState?.motorIncidentDetail });
  }, []);

  const renderFields = () => {
    return (
      <>
        <FieldDisplay title="Date &amp; time">
          {pageFormState.incidentDate && pageFormState.incidentTime
            ? `${pageFormState.incidentDate} ${pageFormState.incidentTime}`
            : 'Unknown'}
        </FieldDisplay>

        <FieldDisplay title="Vehicle registration">
          {pageFormState.vehicleRegistration || 'Unknown'}
        </FieldDisplay>

        <FieldDisplay title="Circumstance">
          {getCircumstanceValue(pageFormState.circumstanceCode) || 'Unknown'}
        </FieldDisplay>
        <SummaryIncompleteError isValid={pageFormState.formIsValid} />
      </>
    );
  };

  return (
    <AccordionSection
      title={title}
      theming={theming}
      color="blue"
      displayBody={displayBody}
      toggleBody={toggleBody}
      key="MotorIncidentDetail"
    >
      <SummaryContent>
        <FieldFlexColumn>{renderFields()}</FieldFlexColumn>
        <EditFlexColumn>
          <EditWrapper>
            <ThemedLinkButton
              primary
              inverted
              xsmall
              to="/motorclaim/incidentdetails"
            >
              Edit
            </ThemedLinkButton>
          </EditWrapper>
        </EditFlexColumn>
      </SummaryContent>
    </AccordionSection>
  );
};

export default IncidentDetailsSummary;

IncidentDetailsSummary.propTypes = {
  title: PropTypes.string,
  theming: PropTypes.string,
};

IncidentDetailsSummary.defaultProps = {
  title: 'Incident details',
  theming: 'secondary',
};
