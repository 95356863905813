import React from 'react';
import TypeAccordion, {
  EdgeCasesAccordion,
} from './_tests_/nestedAccordionTestData';
import HomeFAQAccordion from '../FAQs/HomeFAQ/Accordion';
// import NestedAccordion from './NestedAccordion';
// import AccordionSection from './AccordionSection';

// TODO remove this demo page once it's no longer needed

// export const TestJSXComponent = ({ nestingControl }) => {
//   return (
//     <React.Fragment>
//       <p>hello content 1 1 1</p>
//       <a
//         href="javascript:;"
//         onClick={() =>
//           nestingControl?.setForcePath([
//             'nested 1',
//             'nested 1 2',
//             'nested 1 2 1',
//           ])
//         }
//       >
//         nav test
//       </a>
//     </React.Fragment>
//   );
// };

/* istanbul ignore next */
const AccordionDemo = () => {
  return (
    <div>
      {/* <div style={{ width: "1100px", border: "3px solid blue" }}>
        <NestedAccordion>
          <NestedAccordion title="nested 1">
            <NestedAccordion title="nested 1 1">
              <AccordionSection title="nested 1 1 1">
                <TestJSXComponent />
              </AccordionSection>
            </NestedAccordion>
            <NestedAccordion title="nested 1 2">
              <AccordionSection title="nested 1 2 1">boo</AccordionSection>
            </NestedAccordion>
          </NestedAccordion>
        </NestedAccordion>
      </div> */}

      <div
        style={{
          width: '100%',
          maxWidth: '1100px',
          border: '3px solid blue',
          fontSize: '14px',
        }}
      >
        <HomeFAQAccordion id="testFAQAccordion" />
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: '1100px',
          border: '3px solid blue',
          fontSize: '14px',
        }}
      >
        <TypeAccordion id="testAccordion" />
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: '1100px',
          border: '3px solid blue',
          fontSize: '14px',
        }}
      >
        <EdgeCasesAccordion id="edgeCasesAccordion" />
      </div>
      {/* 1 === 1 && (
        <div
          style={{
            width: '100%',
            maxWidth: '1100px',
            border: '3px solid blue',
            fontSize: '14px',
          }}
        >
          <NestedAccordion cascadeClose={false}>
            <NestedAccordion title="nested 1" icon={customIconVar}>
              <NestedAccordion title="nested 1 1" icon={customIconVar}>
                <Section title="nested 1 1 1" icon={customIconVar}>
                  hello content 1 1 1
                </Section>
                <Section title="nested 1 1 2" icon={customIconVar}>
                  hello content 1 1 2
                </Section>
                <Section title="nested 1 1 3">hello content 1 1 3</Section>
              </NestedAccordion>

              <NestedAccordion title="nested 1 2">
                <Section title="nested 1 2 1">hello content 1 2 1</Section>
                <Section title="nested 1 2 2">hello content 1 2 2</Section>
                <Section title="nested 1 2 3">hello content 1 2 3</Section>
              </NestedAccordion>

              <NestedAccordion title="nested 1 1">
                <Section title="nested 1 3 1">hello content 1 3 1</Section>
                <Section title="nested 1 3 2">hello content 1 3 2</Section>
                <Section title="nested 1 3 3">hello content 1 3 3</Section>
              </NestedAccordion>
            </NestedAccordion>

            <NestedAccordion title="nested 2">
              <NestedAccordion title="nested 2 1">
                <Section title="nested 2 1 1">hello content 2 1 1</Section>
                <Section title="nested 2 1 2">hello content 2 1 2</Section>
                <Section title="nested 2 1 3">hello content 2 1 3</Section>
              </NestedAccordion>

              <NestedAccordion title="nested 2 2">
                <Section title="nested 2 2 1">hello content 2 2 1</Section>
                <Section title="nested 2 2 2">hello content 2 2 2</Section>
                <Section title="nested 2 2 3">hello content 2 2 3</Section>
              </NestedAccordion>

              <NestedAccordion title="nested 2 1">
                <Section title="nested 2 3 1">hello content 2 3 1</Section>
                <Section title="nested 2 3 2">hello content 2 3 2</Section>
                <Section title="nested 2 3 3">hello content 2 3 3</Section>
              </NestedAccordion>
            </NestedAccordion>
          </NestedAccordion>
        </div>
      ) */}
      {/* <div style={{ width: "1100px", border: "3px solid orange" }}>
        <Accordion accordionNestLevel={1}>
          <div>hello</div>
          <Section title="hello">hello content</Section>
          <Section title="hello2">hello2 content</Section>
          <Section title="hello3">hello3 content</Section>
        </Accordion>
      </div>
      <div style={{ width: "1100px", border: "3px solid orange" }}>
        <Accordion accordionNestLevel={2}>
          <Section title="hello">hello content</Section>
          <Section title="hello2">hello2 content</Section>
          <Section title="hello3">hello3 content</Section>

        </Accordion>
      </div>
      <div style={{ width: "1100px", border: "3px solid orange" }}>
        <Accordion accordionNestLevel={3}>
          <Section title="hello">hello content</Section>
          <Section title="hello2">hello2 content</Section>
          <Section title="hello3">hello3 content</Section>
        </Accordion>
      </div>
      <div style={{ width: "1100px", border: "3px solid orange" }}>
        single
        <Accordion accordionNestLevel={3}>
          <Section title="hellosingle">hellosingle content</Section>
        </Accordion>
        single
      </div>

      <br />
      <div style={{ width: "1100px", border: "3px solid red" }}>
        <Accordion>

          <Section title="hello1">

            <Accordion >
              <Section title="hello11">hello11 content</Section>
              <Section title="hello12">hello12 content</Section>
              <Section title="hello13">hello13 content</Section>
            </Accordion>

            <Accordion>
              <Section title="hello21">hello11 content</Section>
              <Section title="hello22">hello12 content</Section>
              <Section title="hello23">hello13 content</Section>
            </Accordion>

          </Section>

          <Section title="hello2">

            <Accordion>
              <Section title="hello21">

                <Accordion>
                  <Section title="hello211">hello211 content</Section>
                  <Section title="hello212">hello212 content</Section>
                  <Section title="hello213">hello213 content</Section>
                </Accordion>

              </Section>
              <Section title="hello22">
                <Accordion>
                  <Section title="hello221">hello221 content</Section>
                  <Section title="hello222">hello222 content</Section>
                  <Section title="hello223">hello223 content</Section>
                </Accordion>

              </Section>
            </Accordion>

          </Section>

          <Section title="hello3">hello3 content</Section>

          <Section title="hello4">hello3 content</Section>

        </Accordion>
      </div> */}
    </div>
  );
};

export default AccordionDemo;
