import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getTheme } from 'ageas-ui-themes';
import { Form } from 'react-final-form';
import {
  Label,
  Input,
  ErrorMessage,
  ThemedDropdownV2,
  ThemedButton,
} from 'ageas-ui-components';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import moment from 'moment';
import StandardPanel from '../../components/StandardPanel/StandardPanel';
import FieldStyled from '../../components/Forms/FieldStyled/FieldStyled';
import { validateField } from '../../helpers/validationHelper';
import {
  motorCircumstanceCodes,
  motorCircumstancesNoneOption,
} from '../../helpers/motorCircumstanceCodesHelper';
import { brand } from '../../../data/whitelabel.config';
import BrandFooter from '../../assets/BrandFooter';
import BrandHeader from '../../assets/BrandHeader';
import TimeDropdownInput from '../../components/TimeDropdownInput/TimeDropdownInput';
import DateDropdownInput from '../../components/DateDropdownInput/DateDropdownInput';
import expandArrow from '../../assets/expandArrow.svg';
import {
  MotorFormContext,
  formNames,
} from '../../helpers/motorFormStateHelper';
import { hideThirdParty } from '../../helpers/SingleDriveCircumstanceHelper';
import NavigateTo from '../../components/Navigation/NavigateTo/NavigateTo';
import BreadCrumb from '../../helpers/MotorClaimBreadcrumb';
import { otherCircumstanceMotor } from '../../helpers/errorMessages';
import { policyName, PolicyContext } from '../../helpers/policyContextHelper';
import policyVRNListAxiosFunction from '../../helpers/axiosFunctions/policyVRNListAxios';
import PolicyVRNSelectField, {
  OTHER_UNKNOWN_VRN,
} from './PolicyVRNSelectField';
import ContextAxios from '../../components/ContextAxios/ContextAxios';
import {
  buildStateFromCookies,
  getLastLoginMethodCookie,
} from '../../contexts/AuthContext';
import config from '../../helpers/config';
import {
  getIncidentDateValidationSchema,
  run3YearDateValidation,
} from '../../components/Forms/ClaimEntryFields/IncidentDate/IncidentDate';

const Theme = getTheme(brand);
const PageContainer = styled.div`
  max-width: 1100px;
  margin: auto;
`;
const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Heading = styled.div`
  font-weight: normal;
  font-size: 21px;
  margin-bottom: 12px;
`;

const LabelStyling = styled.div`
  margin-bottom: -11px;
`;

const MotorIncidentDetail = () => {
  const { dataState, updateDataState } = useContext(MotorFormContext);
  const [pageFormState, setPageFormState] = useState({});
  const [navigatePath, setNavigatePath] = useState(null);
  const [involvedPartiesPageFormState, setInvolvedPartiesPageFormState] =
    useState({});
  let lastLoginMethod = '';

  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyVRNListContext = { ...policyDataContext.policyVRNList };

  const maxYears = moment().year() - 2009;

  // We need RFF Form initial values to not change, so cannot populate direct
  // from context. So clone to local state variable on mount and default
  // using that
  useEffect(() => {
    setPageFormState({ ...dataState?.motorIncidentDetail });
    setInvolvedPartiesPageFormState({ ...dataState?.involvedPartiesDetail });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    lastLoginMethod = getLastLoginMethodCookie();
    if (
      !buildStateFromCookies().token &&
      policyVRNListContext?.status === 'nokey'
    ) {
      if (lastLoginMethod === 'portal') {
        window.location.replace(
          `${config.client.portalLoginUrl}&target=makeMotorClaim`,
        );
      } else {
        let path = `/motorclaim/brokerlogin?target=${encodeURIComponent(
          window.location.pathname,
        )}`;
        if (lastLoginMethod !== 'otp') {
          path += '&portalLink=true';
        }
        setNavigatePath(path);
      }
    }
  }, [policyVRNListContext?.status]);

  const schema = {
    ...getIncidentDateValidationSchema('incidentDate'),
    circumstanceCode: stringRequired(
      ['circumstanceCode'],
      'Please select a circumstance',
    ),
    incidentTime: stringRequired(
      ['incidentTime'],
      'Please select the time of incident',
    ),
    vehicleRegistration: stringRequired(
      ['vehicleRegistration'],
      'Please select the vehicle that was involved',
    ),
  };

  const validateGenericFields = (value, allValues, meta) => {
    let error;

    // Standard validation - mandatory fields
    if (schema[meta.name]) {
      error = validateField(value, meta, schema);
      if (error) {
        return error;
      }
    }

    // Custom validation - incident happened 3yrs ago
    if (meta.name === 'incidentDate') {
      const incidentError = run3YearDateValidation(
        allValues.incidentDate,
        'motor',
      );
      if (incidentError) {
        return incidentError;
      }
    }

    // Custom validation - date and time not in the future
    if (meta.name === 'incidentTime' && value && allValues.incidentDate) {
      const splitDate = allValues.incidentDate.split('/');
      const splitTime = value.split(':');
      const dateObj = new Date(
        splitDate[2],
        splitDate[1] - 1,
        splitDate[0],
        splitTime[0],
        splitTime[1],
      );
      const now = new Date();
      if (dateObj.getTime() > now) {
        return 'The date and time of the claim cannot be in the future';
      }
    }
    return undefined;
  };

  const vrnToPolicy = vrn => {
    if (policyVRNListContext.data !== undefined) {
      // find the entry in the vrnPolicy state for this VRN

      return policyVRNListContext.data.find(element => element.vrn === vrn)
        ?.policyNumber;
      // call the mutator function to update the policyNumber hidden field with
      // the found policy number
    }
    return undefined;
  };

  const onSubmit = values => {
    // Form is valid
    // Single Vehicle Circumstance? Involved Parties screen
    if (hideThirdParty(values.circumstanceCode)) {
      // Involved Parties screen already has TP vehicle = Yes?
      if (involvedPartiesPageFormState?.tpVehicleKnown === 'yes') {
        // Remove the value from form state so backend ignores the TP vehicle
        // (will be entirely removed by Involved Parties Screen anyway, this
        // is insurance in case of browser back button shenanigans)
        const newInvolvedPartiesState = { ...involvedPartiesPageFormState };
        delete newInvolvedPartiesState.tpVehicleKnown;
        updateDataState(
          formNames.involvedPartiesDetail,
          newInvolvedPartiesState,
        );
      }
    }

    // Derive policyNumber from incident dateTime and VRN for inclusion in
    // state (edge cases mean the field will not always auto update)
    const policyNumber = vrnToPolicy(values.vehicleRegistration);

    // Save context and navigate to next page
    const stateValues = { ...values, policyNumber, formIsValid: true };
    updateDataState(formNames.motorIncidentDetail, stateValues);
    setNavigatePath('/motorclaim/contactdetails');
  };

  const beforeSubmit = (event, values) => {
    // Derive policyNumber from incident dateTime and VRN for inclusion in
    // state (edge cases mean the field will not always auto update)
    const policyNumber = vrnToPolicy(values.vehicleRegistration);

    const stateValues = { ...values, policyNumber };
    delete stateValues.formIsValid;
    updateDataState(formNames.motorIncidentDetail, stateValues);
  };

  const onDateChange = (returnedDate, input) => {
    let dateString;
    if (returnedDate === null) {
      dateString = 'Incomplete date';
      input.onFocus(dateString);
    } else if (Number.isNaN(returnedDate)) {
      dateString = 'Invalid date';
      input.onBlur(dateString);
    } else {
      dateString = moment.utc(returnedDate, 'x', true).format('DD/MM/YYYY');
      input.onBlur(dateString);
    }
    input.onChange(dateString);
  };

  const onTimeChange = (returnedTime, input) => {
    if (!returnedTime || !/^[0-9]{2}:[0-9]{2}$/.test(returnedTime)) {
      input.onChange(undefined);
      input.onFocus(undefined);
      return;
    }
    const splitTime = returnedTime.split(':');
    const hours = splitTime[0];
    const minutes = splitTime[1];

    if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
      input.onChange(returnedTime);
      input.onBlur(returnedTime);
    }
  };

  const onVRNChange = (input, vrn, fieldUpdateMutator) => {
    // Call Standard RFF onchange
    input.onChange(vrn);

    // Update RFF field
    fieldUpdateMutator('policyNumber', vrnToPolicy(vrn));
  };

  const fieldUpdateMutator = ([fieldName, value], state, utils) => {
    utils.changeValue(state, fieldName, () => value);
  };

  const incidentDateTimeIsValid = (values, errors) => {
    return !!(
      !errors.incidentDate &&
      !errors.incidentTime &&
      values.incidentDate &&
      values.incidentTime
    );
  };

  const vrnDropdownIsOk = values => {
    return (
      policyVRNListContext.status === 'loaded' &&
      policyVRNListContext?.data?.length &&
      values.vehicleRegistration !== OTHER_UNKNOWN_VRN
    );
  };

  const showContinue = (values, errors) => {
    return vrnDropdownIsOk(values) || !incidentDateTimeIsValid(values, errors);
  };

  // Render message if form fields require it, else submit/continue button
  const renderFormEnding = (values, errors) => {
    if (
      values.circumstanceCode &&
      values.circumstanceCode === motorCircumstancesNoneOption.value
    ) {
      return <strong>{otherCircumstanceMotor()}</strong>;
    }
    return (
      showContinue(values, errors) && (
        <BottomButtonsContainer>
          <ThemedButton secondary small type="submit">
            Continue
          </ThemedButton>
        </BottomButtonsContainer>
      )
    );
  };
  return (
    <ThemeProvider theme={Theme}>
      <BrandHeader />
      <BreadCrumb activeItem="Incident" />
      <main>
        <NavigateTo path={navigatePath} />
        <PageContainer>
          <StandardPanel
            primary
            title="Incident details"
            padding="23px 21px 0px 21px"
            panelPadding="0px 0px 16px 0px"
          >
            <Form
              onSubmit={onSubmit}
              initialValues={pageFormState}
              mutators={{
                fieldUpdateMutator,
              }}
            >
              {({ handleSubmit, submitting, values, form, errors }) => (
                <form
                  method="post"
                  onSubmit={handleSubmit}
                  onSubmitCapture={event => beforeSubmit(event, values)}
                >
                  <ContextAxios
                    keyList={[values.incidentDate, values.incidentTime]}
                    axiosFunction={policyVRNListAxiosFunction}
                    enableUpdate={incidentDateTimeIsValid(values, errors)}
                    dataContextObject={PolicyContext}
                    dataContextName={policyName.policyVRNList}
                  />

                  <FieldStyled
                    name="incidentDate"
                    validate={validateGenericFields}
                  >
                    {({ input, meta }) => (
                      <>
                        <Heading>
                          Please tell us when the incident happened*
                        </Heading>
                        <LabelStyling>
                          <Label fieldName="incidentDate">
                            Date of incident
                          </Label>
                        </LabelStyling>
                        <DateDropdownInput
                          formInput={input}
                          handleDate={value => onDateChange(value, input)}
                          inclDay
                          dropdownId="registrationDoi"
                          dropdownIcon={expandArrow}
                          placeholderDay="DD"
                          placeholderMonth="MM"
                          placeholderYear="YYYY"
                          dateDefaultValue={input.value.split('/')[0]}
                          monthDefaultValue={input.value.split('/')[1]}
                          yearDefaultValue={input.value.split('/')[2]}
                          maxYears={maxYears}
                        />
                        {meta.error && meta.touched && (
                          <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </FieldStyled>
                  <FieldStyled
                    name="incidentTime"
                    validate={validateGenericFields}
                  >
                    {({ input, meta }) => (
                      <>
                        <Label fieldName="incidentTime">Time of incident</Label>
                        <TimeDropdownInput
                          formInput={input}
                          handleTime={value => onTimeChange(value, input)}
                          inclDay
                          dropdownId="registrationTime"
                          dropdownIcon={expandArrow}
                          placeholderHours="Hours"
                          placeholderMinutes="Minutes"
                          hoursDefaultValue={input.value.split(':')[0]}
                          minutesDefaultValue={input.value.split(':')[1]}
                        />
                        {meta.error && meta.touched && (
                          <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </FieldStyled>
                  {incidentDateTimeIsValid(values, errors) && (
                    /* dateValuePolicy && incidentInvalidDate && */ <FieldStyled
                      name="vehicleRegistration"
                      validate={validateGenericFields}
                    >
                      {({ input, meta }) => (
                        <PolicyVRNSelectField
                          {...input}
                          title="Please select your vehicle registration*"
                          fieldName={input.name}
                          id={input.name}
                          selectedOption={input.value}
                          onChange={vrn => {
                            onVRNChange(
                              input,
                              vrn,
                              form.mutators.fieldUpdateMutator,
                            );
                          }}
                          maxButtons={4}
                          errorMsg={
                            meta.error && meta.touched ? meta.error : undefined
                          }
                          dataContextObject={PolicyContext}
                          dataContextName={policyName.policyVRNList}
                        />
                      )}
                    </FieldStyled>
                  )}
                  <FieldStyled name="policyNumber">
                    {({ input }) => (
                      <input
                        {...input}
                        type="hidden"
                        aria-label="policyNumber"
                      />
                    )}
                  </FieldStyled>
                  <FieldStyled name="incidentStreet">
                    {({ input, meta }) => (
                      <>
                        <Heading>
                          Please tell us where the incident happened
                        </Heading>
                        <Label fieldName={input.name}>
                          Closest street to the incident
                        </Label>
                        <Input
                          size="small"
                          maxLength="50"
                          placeholder="Street name"
                          id={input.name}
                          {...input}
                          invalidInput={{
                            isInvalid: meta.error && meta.touched,
                          }}
                        />
                        {meta.error && meta.touched && (
                          <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </FieldStyled>
                  <FieldStyled name="incidentTown">
                    {({ input, meta }) => (
                      <>
                        <Label fieldName={input.name}>
                          Closest town to the incident
                        </Label>
                        <Input
                          size="small"
                          maxLength="50"
                          placeholder="Town name"
                          id={input.name}
                          {...input}
                          invalidInput={{
                            isInvalid: meta.error && meta.touched,
                          }}
                        />
                        {meta.error && meta.touched && (
                          <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </FieldStyled>
                  <FieldStyled
                    name="circumstanceCode"
                    validate={validateGenericFields}
                  >
                    {({ input, meta }) => (
                      <>
                        <Heading>
                          Please tell us what happened (description that best
                          fits the situation)*
                        </Heading>
                        <ThemedDropdownV2
                          noTextBehindIcon
                          placeholder="Please select a circumstance..."
                          aria-label="circumstance"
                          options={motorCircumstanceCodes}
                          name={input.name}
                          selected={input.value}
                          onChange={input.onChange}
                          id={input.name}
                          hasError={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorMessage hasIcon>{meta.error}</ErrorMessage>
                        )}
                      </>
                    )}
                  </FieldStyled>
                  {renderFormEnding(values, errors)}
                  {submitting && <div>submitting...</div>}
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </form>
              )}
            </Form>
          </StandardPanel>
        </PageContainer>
      </main>
      <BrandFooter />
    </ThemeProvider>
  );
};

export default MotorIncidentDetail;
