import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import arrowIcon from '../../assets/icons/weatherIcons/ui_icon_arrow_open.svg';

const NARROW_MODE_LIMIT = '888px';

const ResultGridContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  width: 100%;
`;

const ResultItem = styled.div`
  font-size: 16px;
  background-color: white;
  border: 2px solid rgb(211, 211, 211);
  margin: 5px;
  padding: 25px;
  grid-column-start: ${({ gridCoords }) => gridCoords.wide.column};
  grid-row-start: ${({ gridCoords }) => gridCoords.wide.row};
  @media only screen and (max-width: ${NARROW_MODE_LIMIT}) {
    grid-column-start: ${({ gridCoords }) => gridCoords.narrow.column};
    grid-row-start: ${({ gridCoords }) => gridCoords.narrow.row};
  }
`;

const ResultItemHeader = styled(ResultItem)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  font-weight: bold;
  border-bottom: 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0px;
  padding-bottom: 15px;
  @media only screen and (max-width: ${NARROW_MODE_LIMIT}) {
    cursor: pointer;
    ${({ open }) =>
      !open &&
      css`
        padding-bottom: 0px;
      `};
  }
`;

const ResultItemMid = styled(ResultItem)`
  border-top: 0px;
  border-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 15px;
  @media only screen and (max-width: ${NARROW_MODE_LIMIT}) {
    ${({ open }) =>
      !open &&
      css`
        display: none;
      `};
  }
`;

const ResultItemFooter = styled(ResultItem)`
  text-align: center;
  border-top: 0px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 0px;
  padding-top: 0px;
  @media only screen and (max-width: ${NARROW_MODE_LIMIT}) {
    text-align: left;
    ${({ open }) =>
      !open &&
      css`
        cursor: pointer;
        & > * {
          display: none;
        }
      `};
  }
`;

const ResultIcon = styled.img`
  width: ${({ resultIconName }) => resultIconName};
  margin-left: auto;
  margin-right: auto;
  display: block;
`;
const WeatherIconImage = styled.img`
  display: block;
  width: 35px;
  height: 35px;
  margin-right: 16px;
`;

const HeaderText = styled.div`
  flex-grow: 1;
  min-width: 130px;
  margin-top: 4px;
  @media only screen and (max-width: ${NARROW_MODE_LIMIT}) {
    margin-right: 35px;
  }
`;

const HeaderExpandButton = styled.button`
  display: none;
  background: none;
  border: 0;
  height: 35px;
  margin-right: 20px;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  right: 0px;
  cursor: pointer;
  @media only screen and (max-width: ${NARROW_MODE_LIMIT}) {
    display: block;
  }
`;
const HeaderExpandButtonIcon = styled.img`
  transition: transform 0.2s linear;
  transform: ${({ open }) => (open ? 'rotate(-180deg)' : 'rotate(-360deg)')};
`;

const LimitTextMetStyle = styled.span`
  ${({ conditionMetValue }) =>
    conditionMetValue &&
    css`
      color: green;
    `};
`;
const LimitTextStyle = styled.span`
  font-weight: bold;
`;

// Calculate wide and narrow mode css grid coordinates of each item in the
// grid
// Wide:
//   Each tile is its own column, so column = tile pos (column starts
//   at 1, tile pos starts at 0)
//   Each item in the tile is a row starting at 1
// Narrow:
//    All tiles are in a single column, so column = 1
//    First item starts at row 1 plus offset to tile position
//    E.g. first tile starts at row 1, second tile starts at roe 5, etc.
// Tile and item positions muict both start at 0
const calculateGridCoords = (tilePosition, itemPosition, totalItems) => {
  return {
    wide: {
      column: tilePosition + 1,
      row: itemPosition + 1,
    },
    narrow: {
      column: 1,
      row: tilePosition * totalItems + itemPosition + 1,
    },
  };
};

const getUnit = name => {
  if (name === 'Wind Speed') {
    return 'mph';
  }
  return 'mm';
};

const LimitText = ({ conditionMetValue, name, speed, date }) => {
  if (name !== 'Wind Speed' && name !== 'Rainfall') return null;

  return (
    <LimitTextStyle>
      {name}{' '}
      <LimitTextMetStyle conditionMetValue={conditionMetValue}>
        {speed}
        {getUnit(name)}
      </LimitTextMetStyle>
      {' - '}
      {date}
    </LimitTextStyle>
  );
};

const WeatherResultTile = ({ tileData, tilePosition }) => {
  const [open, setOpen] = useState(false);
  const { name, description, resultIconName, resultIcon, weatherIcon } =
    tileData;
  const MAX_ITEMS = 4;

  const ariaButtonLabel = `Show/Hide ${name} detail`;

  const toggleClose = event => {
    event.preventDefault();
    event.stopPropagation();
    // Only process clicks in narrow view
    if (window.matchMedia(`(max-width: ${NARROW_MODE_LIMIT})`)?.matches) {
      setOpen(!open);
    }
  };

  return (
    <>
      <ResultItemHeader
        gridCoords={calculateGridCoords(tilePosition, 0, MAX_ITEMS)}
        open={!open}
        onClick={toggleClose}
      >
        <WeatherIconImage src={weatherIcon} />
        <HeaderText>{name}</HeaderText>
        <HeaderExpandButton aria-label={ariaButtonLabel} onClick={toggleClose}>
          <HeaderExpandButtonIcon
            open={!open}
            src={arrowIcon}
            alt="chevron"
            title={ariaButtonLabel}
          />
        </HeaderExpandButton>
      </ResultItemHeader>
      <ResultItemMid
        gridCoords={calculateGridCoords(tilePosition, 1, MAX_ITEMS)}
        open={!open}
      >
        {description}
      </ResultItemMid>
      <ResultItemMid
        gridCoords={calculateGridCoords(tilePosition, 2, MAX_ITEMS)}
        open={!open}
      >
        <ResultIcon resultIconName={resultIconName} src={resultIcon} />
      </ResultItemMid>
      <ResultItemFooter
        gridCoords={calculateGridCoords(tilePosition, 3, MAX_ITEMS)}
        open={!open}
        onClick={!open ? toggleClose : undefined}
      >
        <LimitText {...tileData} />
      </ResultItemFooter>
    </>
  );
};

const renderResultTiles = data => {
  return data.map((tileData, index) => (
    <WeatherResultTile
      key={tileData.id}
      tileData={tileData}
      tilePosition={index}
    />
  ));
};

const WeatherResultSet = ({ data = [] }) => {
  return <ResultGridContainer>{renderResultTiles(data)}</ResultGridContainer>;
};

export default WeatherResultSet;

export const tileDataPropTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  weatherIcon: PropTypes.string,
  description: PropTypes.string,
  resultIcon: PropTypes.string,
  resultIconName: PropTypes.string,
  MoreSpaceTop: PropTypes.string,
  MoreSpaceBottom: PropTypes.string,
  speed: PropTypes.number,
  date: PropTypes.string,
  conditionMetValue: PropTypes.bool,
};
export const tileDataPropDefaults = {
  id: undefined,
  title: undefined,
  weatherIcon: undefined,
  description: undefined,
  resultIcon: undefined,
  resultIconName: undefined,
  MoreSpaceTop: undefined,
  MoreSpaceBottom: undefined,
  speed: undefined,
  date: undefined,
  conditionMetValue: undefined,
};

WeatherResultSet.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(tileDataPropTypes)),
};

WeatherResultSet.defaultProps = {
  data: [],
};

WeatherResultTile.propTypes = {
  tileData: PropTypes.shape(tileDataPropTypes),
  tilePosition: PropTypes.number,
};

WeatherResultTile.defaultProps = {
  tileData: undefined,
  tilePosition: undefined,
};

LimitText.propTypes = {
  ...tileDataPropTypes,
};

LimitText.defaultProps = {
  ...tileDataPropDefaults,
};
