import React from 'react';
import FaqP from '../../FaqP';

export const title = 'How do I know if I have this cover?';
export const content = (
  <FaqP>
    If you have opted for this extended cover, it will be shown in your policy
    documentation.
  </FaqP>
);

export const pair = { title, content };
