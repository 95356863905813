import { brand } from '../../data/whitelabel.config';
import footers from './footers';

/*  Set footer according to brand
    Check if corresponding brand object exists in footers
    revert to defaultFooter if brand is not set or doesn't exist */

const footer = footers[brand] ? brand : 'defaultFooter';

export default footers[footer];
