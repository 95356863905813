import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemedButton } from 'ageas-ui-components';
import { FieldArray } from 'react-final-form-arrays';
import ThemedErrorMessage from 'ageas-ui-components/lib/components/ThemedErrorMessage/ThemedErrorMessage';
import Spacer from '../Forms/FieldSpacer/FieldSpacer';
import FieldSpinner from '../Forms/FieldSpinner/FieldSpinner';
import { buildJewelleryItemName } from '../../helpers/buildJewelleryItemName';
import media from '../MediaQuery/MediaQuery';
import { filterAllowedSpecifiedItems } from '../../helpers/specifiedItemsFilter';

const ArrayContainer = styled.fieldset`
  border: 0px;
`;
const Legend = styled.legend`
  margin-bottom: 16px;
`;

const ButtonSet = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  padding-left: 5px;
  ${media.phone`
    margin-bottom: -1em;
  `}
`;

const RecordName = styled.div`
  min-width: 100px;
  overflow-wrap: anywhere;
`;

const JewelleryRecord = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  max-width: 650px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid lightgrey;
  &:first-child {
    margin-top: 0.5em;
  }
  &:last-child {
    border-bottom: 1px solid lightgrey;
  }
`;

const Button = styled(ThemedButton)`
  border-width: 1px;
`;

const hasDisallowedSpecifiedItem = (
  specifiedItemName,
  allowedSpecifiedItems,
) => {
  return (
    specifiedItemName &&
    specifiedItemName !== 'other' &&
    allowedSpecifiedItems.findIndex(
      item => specifiedItemName === item.itemKey,
    ) < 0
  );
};

const RenderPopulatedRow = ({
  fields,
  index,
  onEdit,
  editLabel,
  specifiedItems = [],
  onInvalidItemRemove,
}) => {
  const entryValues = fields.value[index];

  // Filter specified items for this arrayRecord
  const allowedSpecifiedItems = filterAllowedSpecifiedItems(
    fields.value,
    specifiedItems,
    index,
  );

  // Remove this arrayRecord if invalid
  useEffect(() => {
    if (
      hasDisallowedSpecifiedItem(
        fields.value[index].specifiedItemName,
        allowedSpecifiedItems,
      )
    ) {
      fields.remove(index);
      if (onInvalidItemRemove) onInvalidItemRemove(index);
    }
  }, [specifiedItems, fields.value, index]);

  return (
    <JewelleryRecord>
      <RecordName>
        {buildJewelleryItemName(entryValues, index, specifiedItems)}
      </RecordName>
      <ButtonSet>
        <ButtonContainer>
          <Button
            small
            primary
            inverted
            onClick={() =>
              onEdit(
                index,
                entryValues,
                fields.update,
                editLabel,
                allowedSpecifiedItems,
              )
            }
          >
            Edit
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button small primary inverted onClick={() => fields.remove(index)}>
            Clear
          </Button>
        </ButtonContainer>
      </ButtonSet>
    </JewelleryRecord>
  );
};

const JewelleryItemFieldArray = ({
  name,
  label,
  onAdd,
  onEdit,
  addLabel,
  editLabel,
  minRows,
  maxRows,
  validate,
  specifiedItems = [],
  specifiedItemsStatus,
  onInvalidItemRemove,
}) => {
  const validateMinRows = (fields, ...rest) => {
    if (!fields?.length || fields.length < minRows) {
      return 'Please add at least one item of jewellery';
    }
    return validate ? validate(fields, ...rest) : undefined;
  };

  const renderArrayList = (fields, meta) => {
    // No status yet or no key provided, return nothing
    if (!specifiedItemsStatus || specifiedItemsStatus === 'nokey') {
      return null;
    }

    // Is loading, display spinner - no title
    if (specifiedItemsStatus === 'loading') {
      return <FieldSpinner />;
    }

    // Render nothing for error (calling component must handle error message)
    if (specifiedItemsStatus === 'error') {
      return null;
    }

    return (
      <>
        <div>
          {fields.map((fieldName, index) => {
            return (
              <RenderPopulatedRow
                key={fieldName}
                fields={fields}
                index={index}
                onEdit={onEdit}
                editLabel={editLabel}
                specifiedItems={specifiedItems}
                onInvalidItemRemove={onInvalidItemRemove}
              />
            );
          })}

          {fields.length < maxRows && (
            <JewelleryRecord>
              <RecordName>
                <em>No details</em>
              </RecordName>
              <ButtonSet>
                <ButtonContainer>
                  <Button
                    small
                    secondary
                    onClick={() =>
                      onAdd(
                        fields.push,
                        addLabel,
                        filterAllowedSpecifiedItems(
                          fields.value,
                          specifiedItems,
                          undefined,
                        ),
                      )
                    }
                  >
                    Add details
                  </Button>
                </ButtonContainer>
              </ButtonSet>
            </JewelleryRecord>
          )}
        </div>
        {meta.error && meta.touched && (
          <ThemedErrorMessage hasIcon>{meta.error}</ThemedErrorMessage>
        )}
      </>
    );
  };

  return (
    <Spacer>
      <ArrayContainer>
        {label && <Legend>{label}</Legend>}

        <FieldArray
          name={name}
          aria-label={!label && 'Jewellery Items'}
          validate={validateMinRows}
        >
          {({ fields, meta }) => {
            return renderArrayList(fields, meta);
          }}
        </FieldArray>
      </ArrayContainer>
    </Spacer>
  );
};

export default JewelleryItemFieldArray;

RenderPopulatedRow.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        specifiedItemName: PropTypes.string,
      }),
    ),
    remove: PropTypes.func,
    update: PropTypes.func,
  }),
  index: PropTypes.number,
  onEdit: PropTypes.func,
  editLabel: PropTypes.string.isRequired,
  specifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  onInvalidItemRemove: PropTypes.func,
};

RenderPopulatedRow.defaultProps = {
  fields: undefined,
  index: undefined,
  onEdit: () => {},
  specifiedItems: undefined,
  onInvalidItemRemove: undefined,
};

JewelleryItemFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  addLabel: PropTypes.string.isRequired,
  editLabel: PropTypes.string.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  validate: PropTypes.func,
  specifiedItems: PropTypes.arrayOf(PropTypes.shape({})),
  specifiedItemsStatus: PropTypes.string,
  onInvalidItemRemove: PropTypes.func,
};

JewelleryItemFieldArray.defaultProps = {
  label: '',
  onAdd: () => {},
  onEdit: () => {},
  minRows: 0,
  maxRows: 1,
  validate: undefined,
  specifiedItems: undefined,
  specifiedItemsStatus: undefined,
  onInvalidItemRemove: undefined,
};
