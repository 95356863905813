import React from 'react';

const PortalIntegrationContext = React.createContext();
const PortalIntegrationDefaultProvider = PortalIntegrationContext.Provider;

const initialState = {
  referer: '',
  userName: '',
};
// eslint is a pain in my butt
// eslint-disable-next-line react/prefer-stateless-function
class PortalIntegrationProvider extends React.Component {
  constructor() {
    super();
    this.state = initialState;
  }

  setReferer = referer => {
    this.setState({ referer });
  };

  setUserName = userName => {
    this.setState({ userName });
  };

  render() {
    return (
      <PortalIntegrationDefaultProvider
        value={{
          ...this.state,
          setReferer: this.setReferer,
          setUserName: this.setUserName,
        }}
      >
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
        {this.props.children}
      </PortalIntegrationDefaultProvider>
    );
  }
}

export { PortalIntegrationProvider, PortalIntegrationContext };
