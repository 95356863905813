import React, { useState, useEffect, useRef, useContext } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  ThemedButton,
  UploadDocuments,
  ThemedErrorMessage,
} from 'ageas-ui-components';
import { Form } from 'react-final-form';
import axiosHelper from '../../helpers/axios';
import config from '../../helpers/config';
import BankDetails, {
  getBankDetailsValidationSchema,
} from '../Forms/BankDetails/BankDetails';
import { ButtonsBottomContainer } from '../../views/HomeClaimsWrapper/HomeClaimsWrapperStyle';
import P from '../StandardTags/P';
import { getReceiptUploaderText } from './ReceiptUploaderConfig';
import { validateField } from '../../helpers/validationHelper';
import {
  getErrorMessage,
  checkBankDetails as checkBankDetailsError,
  InvalidBankDetailsHome,
  serverErrorHome,
} from '../../helpers/errorMessages';
import NavigateTo from '../Navigation/NavigateTo/NavigateTo';
import { personalLossDataContext } from '../../helpers/personalLossContextHelper';
import LoadingSpinner from '../UI/LoadingSpinner/LoadingSpinner';
import { perilRoutes } from '../../routes/HomeClaimRoutes/HomeClaimRoutePaths';
import documentUploadTypes, {
  FILE_TYPES,
} from '../../helpers/documentUploadTypes';
import formatAmount from '../../helpers/formatAmount';

const ACCOUNT_NAME = 'accountName';
const ACCOUNT_SORT_CODE = 'sortCode';
const ACCOUNT_NUMBER = 'accountNumber';

const schema = {
  ...getBankDetailsValidationSchema(
    ACCOUNT_NAME,
    ACCOUNT_SORT_CODE,
    ACCOUNT_NUMBER,
  ),
};

const validateFields = (value, allFields, meta) => {
  let error;
  if (schema[meta.name]) {
    error = validateField(value, meta, schema);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const PersonalLossReceiptResult = ({
  perilCode,
  perilDescription,
  perilContext,
}) => {
  const [navigatePath, setNavigatePath] = useState(null);
  const [renderPage, setRenderPage] = useState(false);
  const [navigateToData, setNavigateToData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [checkBankDetails, setCheckBankDetails] = useState(false);
  const [invalidBankDetails, setInvalidBankDetails] = useState(false);
  const [serverError, setServerError] = useState(false);
  const axiosCancelToken = useRef(null);
  const [personalLossState, setPersonalLossState] = useState(null);
  const {
    dataState: personalLossContext,
    clearAllDataState,
    updateDataState,
  } = useContext(perilContext);

  // Use context data if present, else state if present
  let personalLossDataFull = personalLossState;
  if (
    !isEmpty({
      ...personalLossContext?.personalLossData,
    })
  ) {
    personalLossDataFull = personalLossContext;
  }

  const personalLossDataListContext = {
    ...personalLossDataFull?.personalLossData,
  };

  const {
    claimReference,
    status = {},
    relevantPolicyData = {},
  } = personalLossDataListContext;
  const { applicableExcess } = relevantPolicyData;

  const { referrals = [] } = status;

  const amountClaimed = personalLossDataFull?.amount;
  const settlementAmount = amountClaimed - applicableExcess;
  const itemTypeSelected = personalLossDataFull?.itemType;
  const isFixedGlazingRefer = referrals.includes(
    'GlazingEstimateOverThreshold',
  );

  const setUploadedDocuments = newUploadedDocumentsList => {
    updateDataState(
      personalLossDataContext.uploadedDocuments,
      newUploadedDocumentsList,
    );
  };
  const uploadedDocumentsState = [
    personalLossContext?.uploadedDocuments || [],
    setUploadedDocuments,
  ];
  const uploaded = !!uploadedDocumentsState[0].length;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!navigatePath) {
      if (isEmpty(personalLossDataListContext)) {
        setNavigatePath('/homeinsurance');
      } else {
        // If context set but not state, Move context to state
        if (!personalLossState) {
          setPersonalLossState(personalLossDataFull);
        }
        setRenderPage(true);
      }
    }
  }, [personalLossDataFull]);

  useEffect(() => {
    return () => {
      if (axiosCancelToken?.current?.cancel) {
        axiosCancelToken.current.cancel();
      }
    };
  }, []);

  const renderCheckBankDetailsError = () => {
    return (
      checkBankDetails && (
        <ThemedErrorMessage hasIcon>
          {checkBankDetailsError()}
        </ThemedErrorMessage>
      )
    );
  };

  const renderInvalidBankDetailsError = () => {
    return (
      invalidBankDetails && (
        <ThemedErrorMessage hasIcon>
          {InvalidBankDetailsHome()}
        </ThemedErrorMessage>
      )
    );
  };

  const renderContinueButton = () => {
    return (
      !invalidBankDetails && (
        <ButtonsBottomContainer>
          <ThemedButton secondary type="submit">
            Continue
          </ThemedButton>
        </ButtonsBottomContainer>
      )
    );
  };

  const showBankDetails = values => {
    return (
      values[ACCOUNT_NAME] &&
      values[ACCOUNT_SORT_CODE] &&
      values[ACCOUNT_NUMBER]
    );
  };

  const buildRequestPayload = values => {
    return {
      claimReference,
      ...(showBankDetails(values) && {
        bankDetails: {
          accountName: values.accountName,
          sortCode: +values.sortCode,
          accountNumber: +values.accountNumber,
        },
      }),
    };
  };

  const renderThankYouPage = payment => {
    updateDataState(personalLossDataContext.settlementAmount, settlementAmount);
    const thankYouData = {
      isAccept: true,
      isComplete: true,
      isClaimRef: claimReference,
      itemType: itemTypeSelected,
      isReferredData: isFixedGlazingRefer,
      isSettlementAmount: settlementAmount,
      settled: payment,
      evidenceUploaded: uploaded,
      description: perilDescription,
    };

    setNavigateToData(thankYouData);
    setIsLoading(false);
    setNavigatePath(perilRoutes[perilCode].thankYou);
    clearAllDataState();
  };

  const createPersonalReceiptClaim = (values, payment) => {
    setInvalidBankDetails(false);
    setCheckBankDetails(false);
    setServerError(false);
    axiosCancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosHelper
      .post(
        config.client.acceptHomeClaim_endpoint,
        buildRequestPayload(values),
        {
          cancelToken: axiosCancelToken.current.token,
        },
      )
      .then(data => {
        setIsLoading(false);
        if (payment) {
          const { paymentSuccess = false, errorCode = '' } = data?.data || {};
          if (paymentSuccess && !errorCode) {
            renderThankYouPage(payment);
          } else if (errorCode === 'TooManyAttempts') {
            setInvalidBankDetails(true);
          } else if (errorCode === 'InvalidDetails') {
            setCheckBankDetails(true);
          } else {
            setServerError(true);
          }
        } else {
          renderThankYouPage(payment);
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  };

  const renderServerErrorComponent = () => {
    return (
      serverError && (
        <ThemedErrorMessage hasIcon>{serverErrorHome()}</ThemedErrorMessage>
      )
    );
  };

  const onSubmit = (values, payment) => {
    createPersonalReceiptClaim(values, payment);
  };

  const renderRegularContinueButton = () => {
    return (
      <>
        {renderServerErrorComponent()}
        <ButtonsBottomContainer>
          <ThemedButton
            secondary
            type="button"
            onClick={() => {
              onSubmit({}, false);
            }}
          >
            Continue
          </ThemedButton>
        </ButtonsBottomContainer>
      </>
    );
  };

  const renderBankDetailsSection = () => {
    return (
      <>
        {uploaded && (
          <Form onSubmit={values => onSubmit(values, true)}>
            {({ handleSubmit }) => (
              <form method="post" onSubmit={handleSubmit}>
                <P>
                  We are able to pay you{' '}
                  <strong>£{formatAmount(settlementAmount)}</strong> today in
                  settlement of your claim. We&apos;ll pay this money directly
                  into your bank account. Please enter the following details:
                </P>
                <BankDetails
                  name={[ACCOUNT_NAME, ACCOUNT_SORT_CODE, ACCOUNT_NUMBER]}
                  validate={validateFields}
                  headerInfo=""
                />
                {renderCheckBankDetailsError()}
                {renderInvalidBankDetailsError()}
                {renderServerErrorComponent()}
                {renderContinueButton()}
              </form>
            )}
          </Form>
        )}
      </>
    );
  };

  const renderSection = () => {
    if (uploaded && amountClaimed <= 750) {
      return renderBankDetailsSection();
    }
    return renderRegularContinueButton();
  };

  if (navigatePath) {
    return <NavigateTo path={navigatePath} data={navigateToData} />;
  }
  if (!renderPage) {
    return null;
  }

  return (
    <>
      <UploadDocuments
        axiosPost={axiosHelper.post}
        axiosPostURL={config.client.uploadDocument_endpoint}
        payloadKeys={{ claimReference }}
        uploadedDocumentsState={uploadedDocumentsState}
        {...getReceiptUploaderText(claimReference, perilDescription)}
        acceptFileTypesList={FILE_TYPES}
        fileSizeLimit={7}
        brand="ageas"
        customUploadFieldsDataFormatter={() => {
          return { category: documentUploadTypes.RECEIPT_INVOICE.category }; // hard-code return category receiptInvoice in axios payload
        }}
      />
      {renderSection()}
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default PersonalLossReceiptResult;

PersonalLossReceiptResult.propTypes = {
  amount: PropTypes.string,
  perilCode: PropTypes.string,
  perilDescription: PropTypes.string,
  perilContext: PropTypes.shape({}).isRequired,
  itemType: PropTypes.string,
};

PersonalLossReceiptResult.defaultProps = {
  amount: undefined,
  perilCode: undefined,
  perilDescription: undefined,
  itemType: undefined,
};
