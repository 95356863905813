import axios from 'axios';
import config from '../config';
import axiosHelper from '../axios';
import { dateISOConverter } from '../dateTimeConverter';
import sanitiseVRN from '../sanitiseVRN';

const modeToUrl = {
  standard: config.client.brokercontact_endpoint,
  validateCode: config.client.brokercontact_validate_otp_endpoint,
};

const getUrl = mode => modeToUrl[mode] || modeToUrl.standard;

const OTPAxios = async (
  mode,
  brokerContactContext,
  contactData,
  cancelToken = undefined,
  headers = {},
) => {
  const dateConverter =
    brokerContactContext.brokerPolicyDetail?.dateOfBirth &&
    dateISOConverter(brokerContactContext.brokerPolicyDetail?.dateOfBirth);
  const otpFormData = {
    lineOfBusiness: brokerContactContext.lineOfBusiness,
    application: brokerContactContext.application,
    policyReference:
      brokerContactContext.brokerPolicyDetail?.policyNumber?.toUpperCase(),
    claimReference:
      brokerContactContext.brokerPolicyDetail?.claimReference?.toUpperCase(),
    dateOfBirth: dateConverter,
    vrn: sanitiseVRN(brokerContactContext.brokerPolicyDetail?.vrn),
    ...contactData,
  };

  const url = getUrl(mode);
  // Return axios request promise
  return axiosHelper
    .post(url, otpFormData, {
      cancelToken,
      headers,
    })
    .then(({ data }) => {
      return { data };
    })
    .catch(error => {
      const isCancel = !!axios.isCancel(error);
      return {
        error: {
          status: error?.response?.status,
          isCancel,
          errorObject: error,
        },
      };
    });
};

export default OTPAxios;
