import React from 'react';
import FaqP from '../../FaqP';

export const title = 'What is wear and tear?';
export const content = (
  <>
    <FaqP>
      Almost everything in your home will suffer from general wear and tear over
      time. You can extend the lifetime of your property and the possessions
      inside it by taking care of your home through general maintenance and
      upkeep. So, for example, from time to time it would be worth having your
      roof checked for missing or cracked tiles.
    </FaqP>
    <FaqP>
      Wear and tear is a common reason that claims are declined especially in a
      weather event like a storm. It&apos;s difficult for rain or wind to damage
      well-maintained properties unless it is an extreme weather event, but a
      poorly maintained roof could let rain in that could also damage your
      contents.
    </FaqP>
    <FaqP>
      If you look after your property and something unexpected happens,
      that&apos;s when your insurance should be there to help.
    </FaqP>
  </>
);

export const pair = { title, content };
