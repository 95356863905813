import React from 'react';
import FaqP from '../../FaqP';
import StandardUL from '../../../StandardTags/UL';

export const title = 'What is not covered?';
export const content = (
  <>
    <FaqP>
      Unfortunately, there are things we do not cover. It is therefore important
      to check your policy booklet before reporting any claim, for more
      information on what is and is not covered. Your policy documents will also
      tell you what cover you have selected and what excess will apply,
      depending on the type of claim you wish to make.
    </FaqP>
    <FaqP marginBottom={false}>
      Common examples of some areas not covered under your policy:
    </FaqP>
    <StandardUL>
      <li>Damage to gates, hedges and fences caused by storms</li>
      <li>Breakdown and maintenance of items such as boilers</li>
      <li>
        Damage caused by wear and tear or gradual operating causes e.g. fading
        or worn patches on furniture or carpets, blockage or cracking of
        guttering causing damp walls, deterioration of sealant in shower causing
        gradual seepage of water into wall
      </li>
      <li>Mechanical and electrical failure of appliances</li>
    </StandardUL>
  </>
);

export const pair = { title, content };
