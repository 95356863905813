import React from 'react';
import FaqP from '../../FaqP';
import StandardUl from '../../../StandardTags/UL';

export const buildingDamageTitle = 'What are buildings?';
export const buildingDamageContent = (
  <>
    <FaqP>
      In insurance, Buildings are defined as the property shown in your
      insurance schedule/statement of fact. They include the structure of your
      home, including any fixtures and fittings, garden walls, gates, decking,
      paths, drives, patios, permanent swimming pools built of brick, stone or
      concrete, permanently fixed hot tubs, solar panels, fully enclosed
      outbuildings and hard tennis courts.
    </FaqP>
    <FaqP marginBottom={false}>
      Unless otherwise agreed, outbuildings do not include:
    </FaqP>
    <StandardUl>
      <li>Any building which is not on a permanent foundation or base</li>
      <li>Tree houses</li>
      <li>Inflatable buildings (whether over swimming pools or not)</li>
      <li>
        Any structure which is made of canvas, PVC or any other non-rigid
        material
      </li>
    </StandardUl>

    <FaqP>
      <strong>PLEASE NOTE:</strong>
      <br />
      Carpets are considered contents.
    </FaqP>
  </>
);
