import React from 'react';
import PropTypes from 'prop-types';

import ConfirmUpdate from './ConfirmUpdate';
import StandardDate from '../../../components/Forms/StandardDate/StandardDate';

const DateConfirmUpdate = ({
  title,
  yesNoName,
  updateFieldName,
  value,
  validateYesNo,
  validateField,
  validate,
  disabled,
  idField,
}) => {
  return (
    <ConfirmUpdate
      title={title}
      value={value}
      yesNoName={yesNoName}
      validate={validateYesNo || validate}
      disabled={disabled}
    >
      <StandardDate
        name={updateFieldName}
        id={idField}
        label="Please enter correct details"
        validate={validateField || validate}
        disabled={disabled}
      />
    </ConfirmUpdate>
  );
};

export default DateConfirmUpdate;

DateConfirmUpdate.propTypes = {
  title: PropTypes.string,
  yesNoName: PropTypes.string.isRequired,
  updateFieldName: PropTypes.string.isRequired,
  value: PropTypes.string,
  validateYesNo: PropTypes.func,
  validateField: PropTypes.func,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  idField: PropTypes.string,
};
DateConfirmUpdate.defaultProps = {
  title: undefined,
  value: undefined,
  validateYesNo: undefined,
  validateField: undefined,
  validate: undefined,
  disabled: undefined,
  idField: undefined,
};
