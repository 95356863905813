import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  ThemedButton,
  ThemedNotification,
  ContentWrapper,
  Title,
  NotificationChildren,
} from 'ageas-ui-components';
import styled, { css } from 'styled-components';
import media from '../MediaQuery/MediaQuery';
import { brand } from '../../../data/whitelabel.config';

const statusToColour = status => (status === 'closed' ? 'green' : '#EE5623');
const statusToIcon = status =>
  status === 'closed' ? 'tick-roundel' : 'warning-roundel';

const defaultUploadAction = { description: 'Upload', action: 'upload' };
const defaultViewAction = { description: 'View', action: 'view' };

const notificationTypeConfig = {
  HE: defaultUploadAction,
  HG: defaultUploadAction,
  HL: defaultUploadAction,
  HO: defaultViewAction,
  OF: defaultViewAction,
  HP: defaultUploadAction,
  HR: defaultUploadAction,
  HT: defaultUploadAction,
  HW: defaultUploadAction,
  SM: defaultViewAction,
  SB: defaultViewAction,
  PY: defaultViewAction,
  PO: defaultViewAction,
  ME: defaultUploadAction,
  MP: defaultUploadAction,
  MV: defaultUploadAction,
  ML: defaultUploadAction,
  MR: defaultUploadAction,
  MD: { ...defaultUploadAction, subAction: 'allDocTypes' },
};

const StyledThemedNotification = styled(ThemedNotification)`
  ${({ status }) => css`
    color: ${statusToColour(status)};
    border-color: ${statusToColour(status)};
  `}
`;
const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionButton = styled(ThemedButton)`
  margin-bottom: 0;
  width: 110px;
`;

const NotificationContent = styled(NotificationChildren)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  ${media.phone`
    flex-wrap: wrap;
  `}
`;
const NotificationText = styled.div`
  flex-grow: 1;
`;

const getOnClick = (actions, notification) => {
  if (notificationTypeConfig[notification.code]) {
    const { action } = notificationTypeConfig[notification.code];
    if (notification.status !== 'closed' && actions[action]) {
      return e => {
        actions[action](
          notification,
          e,
          notificationTypeConfig[notification.code],
        );
      };
    }
  }
  return undefined;
};

export const HubNotificationsPlain = ({ notifications = [], actions = {} }) => {
  return (
    <NotificationsContainer>
      {notifications.map(notification => {
        const { sequence, code, text, status } = notification;
        const onClick = getOnClick(actions, notification);
        return (
          <StyledThemedNotification
            ChildrenContainer={NotificationContent}
            key={sequence}
            status={status}
            leftContent={
              <Icon
                className="icon-warning"
                brandIconSet={brand}
                icon={statusToIcon(status)}
                color={statusToColour(status)}
                size={34}
              />
            }
          >
            <>
              <NotificationText>
                {text}
                {status === 'closed' && ' - Complete'}
              </NotificationText>
              {onClick && (
                <ActionButton
                  primary
                  focusHighlight
                  type="button"
                  onClick={onClick}
                >
                  {notificationTypeConfig[code]?.description}
                </ActionButton>
              )}
            </>
          </StyledThemedNotification>
        );
      })}
    </NotificationsContainer>
  );
};

const HubNotifications = ({ notifications = [], actions = {} }) => {
  return (
    <ContentWrapper>
      <Title color="grey" size="l">
        Notifications
      </Title>
      <HubNotificationsPlain notifications={notifications} actions={actions} />
    </ContentWrapper>
  );
};

export default HubNotifications;

const propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.number,
      code: PropTypes.string,
      text: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
  actions: PropTypes.objectOf(PropTypes.func),
};

const defaultProps = {
  notifications: undefined,
  actions: undefined,
};

HubNotifications.propTypes = propTypes;

HubNotifications.defaultProps = defaultProps;
HubNotificationsPlain.propTypes = propTypes;

HubNotificationsPlain.defaultProps = defaultProps;
