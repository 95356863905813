import React, { useState, useContext, useEffect } from 'react';

import { AccordionSection, FieldDisplay } from 'ageas-ui-components';
import PropTypes from 'prop-types';
import { MotorFormContext } from '../../helpers/motorFormStateHelper';
import ThemedLinkButton from '../ThemedLinkButton/ThemedLinkButton';
import {
  EditWrapper,
  SummaryContent,
  FieldFlexColumn,
  EditFlexColumn,
} from './Summary.style';
import SummaryIncompleteError from './SummaryIncompleteError';

const ContactDetailsSummary = ({ title, theming }) => {
  const { dataState } = useContext(MotorFormContext);
  const [pageFormState, setPageFormState] = useState({});

  const [displayBody, setDisplayBody] = useState(true);
  const toggleBody = () => {
    setDisplayBody(!displayBody);
  };

  useEffect(() => {
    setPageFormState({ ...dataState?.motorContactDetail });
  }, []);

  const renderFields = () => {
    return (
      <>
        <FieldDisplay title="Home telephone">
          {pageFormState.homeTelephone !== undefined
            ? pageFormState.homeTelephone
            : '--'}
        </FieldDisplay>
        <FieldDisplay title="Mobile telephone">
          {pageFormState.mobileTelephone !== undefined
            ? pageFormState.mobileTelephone
            : '--'}
        </FieldDisplay>
        <FieldDisplay title="Email">
          {pageFormState.email || 'Unknown'}
        </FieldDisplay>
        <SummaryIncompleteError isValid={pageFormState.formIsValid} />
      </>
    );
  };

  return (
    <AccordionSection
      title={title}
      theming={theming}
      color="blue"
      displayBody={displayBody}
      toggleBody={toggleBody}
      key="MotorContactDetail"
    >
      <SummaryContent>
        <FieldFlexColumn>{renderFields()}</FieldFlexColumn>
        <EditFlexColumn>
          <EditWrapper>
            <ThemedLinkButton
              primary
              inverted
              xsmall
              to="/motorclaim/contactdetails"
            >
              Edit
            </ThemedLinkButton>
          </EditWrapper>
        </EditFlexColumn>
      </SummaryContent>
    </AccordionSection>
  );
};

export default ContactDetailsSummary;

ContactDetailsSummary.propTypes = {
  title: PropTypes.string,
  theming: PropTypes.string,
};

ContactDetailsSummary.defaultProps = {
  title: 'Contact details',
  theming: 'secondary',
};
