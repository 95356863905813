import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../helpers/config';
import ConfirmUpdate from './ConfirmUpdate';
import StandardDate from '../../../components/Forms/StandardDate/StandardDate';
import { FIELD_NAMES } from '../motorYourDetailsHelper';
import YesOrNo from '../../../components/Forms/ClaimEntryFields/YesOrNo/YesOrNo';
import ABICodeAutoComplete from '../../../components/Forms/ABICodeAutoComplete/ABICodeAutoComplete';

const OccupationConfirmUpdate = ({
  title = 'Occupation: ',
  yesNoName,
  value,
  validate,
  validateField,
}) => {
  return (
    <ConfirmUpdate
      title={title}
      value={value}
      yesNoName={yesNoName}
      validate={validate}
    >
      <ABICodeAutoComplete
        name={FIELD_NAMES.OCCUPATION}
        label="Please enter correct details"
        selectionMsg="Please select from the list below"
        validate={validateField || validate}
        url={config.client.getOccupationsList_endpoint}
        noSuggestionsMsg="No results found, please try typing another job title"
        endingSuggestionMsg="(Can't find your job title? Look for the nearest alternative)"
      />
      <StandardDate
        name={FIELD_NAMES.OCCUPATION_CHANGED_DATE}
        id={FIELD_NAMES.OCCUPATION_CHANGED_DATE}
        label="Date your occupation changed"
        validate={validate}
      />
      <YesOrNo
        name={FIELD_NAMES.OCCUPATION_CHANGED_VEHICLE_USED}
        title="Is the vehicle used in connection with the new occupation?"
        validate={validate}
      />
      <YesOrNo
        name={FIELD_NAMES.OCCUPATION_CHANGED_COMMUTING}
        title="Is the vehicle used for commuting?"
        validate={validate}
      />
    </ConfirmUpdate>
  );
};

export default OccupationConfirmUpdate;

OccupationConfirmUpdate.propTypes = {
  title: PropTypes.string,
  yesNoName: PropTypes.string.isRequired,
  value: PropTypes.string,
  validate: PropTypes.func,
  validateField: PropTypes.func,
};
OccupationConfirmUpdate.defaultProps = {
  title: undefined,
  value: undefined,
  validate: undefined,
  validateField: undefined,
};
