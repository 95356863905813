import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import car from '../../../assets/images/car-top-wireframe.svg';
import DAMAGE_AREAS, {
  DAMAGE_LEVELS,
  damageAreaPropType,
  damageAreaValuesPropType,
} from './damageValues';

const border = '1px solid grey';

const DamageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);

  max-width: 400px;

  border: ${border};

  /** Pseudo element to provide background image, so it can have a margin */
  &::before {
    content: '';
    margin: 5px;
    background-image: url(${car});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    grid-row: 1 / 4;
    grid-column: 1 / 4;
  }

  /*
    Magic CSS so grid retains 1:1 ratio
    https://stackoverflow.com/questions/47713415/how-to-make-div-height-equal-to-width-in-css-grid
    Pseudo-element inserted at grid position 1, with padding-bottom 100%
    Padding-bottom percentage relative to width, so padding-bottom 100% = grid
    div width, forcing height to match width
  */
  &::after {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`;

const ButtonStyledCore = styled.button`
  background-color: white;
  &:focus {
    outline: revert;
  }
  border: ${border};
  cursor: pointer;
  mix-blend-mode: multiply;
`;
const ButtonStyled = styled(ButtonStyledCore)`
  /*
    Pseudo-elements count as first entries, moving buttons to later in grid.
    Each button therefore positions itself
  */
  grid-column-start: ${({ gridColumn }) => gridColumn};
  grid-row-start: ${({ gridRow }) => gridRow};

  ${({ selected }) =>
    selected &&
    css`
      background-color: rgba(51, 217, 236, 0.4); /* #33d9ec */
    `}
`;

const Button = ({ damageArea, values, onButtonClick, booleanMode }) => {
  const selected =
    // Boolean? values is array, damageArea is value inside
    (booleanMode && values?.includes(damageArea.name)) ||
    // Not boolean? values is object, damageArea is key
    (!!values[damageArea.name] &&
      values[damageArea.name] !== DAMAGE_LEVELS.NONE.name);

  let ariaLabel = damageArea.label;
  if (!selected) {
    ariaLabel = `Select ${ariaLabel}`;
  } else if (booleanMode) {
    ariaLabel = `Remove ${ariaLabel}`;
  } else {
    ariaLabel = `Update ${ariaLabel}`;
  }

  return (
    <ButtonStyled
      type="button"
      onClick={e => onButtonClick(damageArea, e)}
      selected={selected}
      gridColumn={damageArea.gridColumn}
      gridRow={damageArea.gridRow}
      title={damageArea.label}
      aria-label={ariaLabel}
    />
  );
};

Button.propTypes = {
  damageArea: damageAreaPropType.isRequired,
  values: damageAreaValuesPropType.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  booleanMode: PropTypes.bool.isRequired,
};

const VehicleDamageButtonGrid = ({
  className,
  values,
  onButtonClick,
  booleanMode = false,
}) => {
  const buttonProps = useMemo(
    () => ({
      booleanMode,
      onButtonClick,
      values,
    }),
    [onButtonClick, values],
  );

  return (
    <DamageGrid className={className}>
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.REAR_DRIVER} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.REAR} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.REAR_PASSENGER} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.SIDE_DRIVER} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.ROOF} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.SIDE_PASSENGER} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.FRONT_DRIVER} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.FRONT} />
      <Button {...buttonProps} damageArea={DAMAGE_AREAS.FRONT_PASSENGER} />
    </DamageGrid>
  );
};

export default VehicleDamageButtonGrid;

VehicleDamageButtonGrid.propTypes = {
  className: PropTypes.string,
  values: damageAreaValuesPropType.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  booleanMode: PropTypes.bool,
};
VehicleDamageButtonGrid.defaultProps = {
  className: undefined,
  booleanMode: undefined,
};
