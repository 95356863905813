import {
  booleanToYesNo,
  isoToDmyOrUndefined,
  mapClaimsToLocal,
  mapConvictionsToLocal,
  mapInvolvedPartyToLocal,
} from '../helpers';

export const mapActualDriverDetails = ({ actualDriverDetails } = {}) => {
  if (!actualDriverDetails) {
    return undefined;
  }
  const {
    isOccupationCorrect,
    occupationDetails,
    isDateOfBirthCorrect,
    dateOfBirth,
    isLicenceTypeCorrect,
    licenceType,
    isLicenceYearsCorrect,
    licenceYears,
    isPreviousConvictionsCorrect,
    previousConvictions,
    isPreviousClaimsCorrect,
    previousClaims,
  } = actualDriverDetails;
  const {
    occupation,
    changedDate: occupationChangedDate,
    changedVehicleUsed: occupationChangedVehicleUsed,
    changedCommuting: occupationChangedCommuting,
  } = occupationDetails || {};

  let driverConvictions;
  if (previousConvictions?.length) {
    driverConvictions = mapConvictionsToLocal(previousConvictions);
  }

  let driverClaims;
  if (previousClaims?.length) {
    driverClaims = mapClaimsToLocal(previousClaims);
  }

  return {
    dobIsCorrect: booleanToYesNo(isDateOfBirthCorrect),
    dob: isoToDmyOrUndefined(dateOfBirth)?.date,
    occupationIsCorrect: booleanToYesNo(isOccupationCorrect),
    occupation,
    occupationChangedDate: isoToDmyOrUndefined(occupationChangedDate)?.date,
    occupationChangedVehicleUsed: booleanToYesNo(occupationChangedVehicleUsed),
    occupationChangedCommuting: booleanToYesNo(occupationChangedCommuting),
    licenceTypeIsCorrect: booleanToYesNo(isLicenceTypeCorrect),
    licenceType,
    licenceYearsIsCorrect: booleanToYesNo(isLicenceYearsCorrect),
    licenceYears: licenceYears?.toString(),
    driverConvictionsIsCorrect: booleanToYesNo(isPreviousConvictionsCorrect),
    driverConvictions,
    driverClaimsIsCorrect: booleanToYesNo(isPreviousClaimsCorrect),
    driverClaims,
  };
};

export const mapOtherDriverDetails = ({ otherDriverDetails } = {}) => {
  if (!otherDriverDetails) {
    return undefined;
  }
  const {
    driverDetails,
    dateOfBirth,
    hasOwnPolicy,
    hasPreviousConvictions,
    previousConvictions,
    hasPreviousClaims,
    previousClaims,
    licenceType,
    licenceYears,
  } = otherDriverDetails;

  let licenceTypeObject;

  if (licenceType) {
    licenceTypeObject = licenceType;
  }

  let otherDriverConvictions;
  if (previousConvictions?.length) {
    otherDriverConvictions = mapConvictionsToLocal(previousConvictions);
  }

  let otherDriverClaims;
  if (previousClaims?.length) {
    otherDriverClaims = mapClaimsToLocal(previousClaims);
  }

  return {
    ...mapInvolvedPartyToLocal(driverDetails, 'otherDriverDetails'),
    dob: isoToDmyOrUndefined(dateOfBirth)?.date,
    otherDriverOwnPolicy: booleanToYesNo(hasOwnPolicy),
    otherDriverHasConvictions: hasPreviousConvictions?.trim(),
    otherDriverConvictions,
    otherDriverHasClaims: hasPreviousClaims?.trim(),
    otherDriverClaims,
    licenceType: licenceTypeObject,
    licenceYears: licenceYears?.toString(),
  };
};

export const mapPolicyDetails = ({ policyDetails } = {}) => {
  if (!policyDetails) {
    /* istanbul ignore next */
    return undefined;
  }
  const { isAddressCorrect, address, addressChangedDate, vehicleKept } = policyDetails;

  return {
    phAddressIsCorrect: booleanToYesNo(isAddressCorrect),
    phAddressChangedDate: isoToDmyOrUndefined(addressChangedDate)?.date,
    vehicleKept,
    ...mapInvolvedPartyToLocal(address, 'phAddress'),
  };
};

const mapVehicleOwnerKeeper = (vehicleOwnerKeeper, subjectType) => {
  if (!vehicleOwnerKeeper) {
    return undefined;
  }
  const subject = subjectType === 'O' ? 'Owner' : 'Keeper';

  const { changedDate, name, dateOfBirth, status, relationshipToPh } =
    vehicleOwnerKeeper;

  return {
    [`vehicle${subject}ChangedDate`]: isoToDmyOrUndefined(changedDate)?.date,
    ...mapInvolvedPartyToLocal(name, `vehicle${subject}Name`),
    [`vehicle${subject}Dob`]: isoToDmyOrUndefined(dateOfBirth)?.date,
    [`vehicle${subject}Status`]: status,
    [`vehicle${subject}RelationshipToPh`]: relationshipToPh?.trim(),
  };
};

export const mapVehicleDetails = ({ vehicleDetails } = {}) => {
  if (!vehicleDetails) {
    /* istanbul ignore next */
    return undefined;
  }
  const {
    isOwnerCorrect,
    isKeeperCorrect,
    modificationsToAdd,
    modificationsDetails,
    modificationsDate,
    vehicleOwner,
    vehicleKeeper,
    mainUser,
    mainUserName,
    vehicleUsage,
    vehicleUsageDetails,
  } = vehicleDetails;

  return {
    vehicleOwnerIsCorrect: booleanToYesNo(isOwnerCorrect),
    vehicleKeeperIsCorrect: booleanToYesNo(isKeeperCorrect),
    vehicleModificationsToAdd: booleanToYesNo(modificationsToAdd),
    vehicleModificationsDetails: modificationsDetails?.toString(),
    vehicleModificationsDate: isoToDmyOrUndefined(modificationsDate)?.date,
    vehicleUsage: vehicleUsage?.trim(),
    vehicleUsageDetails: vehicleUsageDetails?.trim(),

    ...mapVehicleOwnerKeeper(vehicleOwner, 'O'),
    ...mapVehicleOwnerKeeper(vehicleKeeper, 'K'),
    mainUser: mainUser?.trim(),
    ...mapInvolvedPartyToLocal(mainUserName, `mainUserName`),
  };
};

/**
 * Map JSON Blob returned from server to context/pageForm format
 * required for Your Details page
 * @param {*} claimData property of JSON Blob
 * @returns data in Your Details page form format
 */
export const mapServerToYourDetailsPage = ({
  incidentDetails,
  underwriterQuestions,
} = {}) => {
  const { driver } = incidentDetails || {};
  if (!driver && !underwriterQuestions) {
    return undefined;
  }

  return {
    driver: driver?.toString(),
    ...mapActualDriverDetails(underwriterQuestions),
    ...mapOtherDriverDetails(underwriterQuestions),
    ...mapPolicyDetails(underwriterQuestions),
    ...mapVehicleDetails(underwriterQuestions),
  };
};

export default mapServerToYourDetailsPage;
