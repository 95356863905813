import { messages } from '../constants/ErrorMessages';

export default function validAddress(Joi) {
  return {
    base: Joi.any(),
    name: 'any',
    language: {
      validAddress: messages.INVALID_ADDRESS_LINE,
    },
    rules: [
      {
        name: 'validAddress',
        params: {
          validAddress: Joi.alternatives([
            Joi.string().required(),
            Joi.number().required(),
            Joi.func().ref(),
          ]),
        },
        validate(params, value, state, options) {
          if (value) {
            const regex = /^[a-zA-Z0-9 ,.\-&-'()]*$/;

            if (typeof value !== 'number' && !value.match(regex)) {
              return this.createError('any.base', { value }, state, options);
            }
          } else {
            return this.createError('any.base', { value }, state, options);
          }
          return value;
        },
      },
    ],
  };
}
