import React from 'react';
import PropTypes from 'prop-types';
import { stringRequired } from 'ageasvalidation/lib/schemas/Required';
import StandardRadio from '../Forms/StandardRadio/StandardRadio';

export const regularShoppingSchema = periodicalFieldName => {
  return {
    [periodicalFieldName]: stringRequired(
      [periodicalFieldName],
      'Please select one of the intervals of shopping',
    ),
  };
};

const REGULAR_SHOPPING_LIST = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'fortnightly',
    label: 'Fortnightly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const PeriodicallyShopping = ({ name, ...rest }) => {
  const PERIODICAL_FIELD_NAME = name;
  return (
    <StandardRadio
      name={PERIODICAL_FIELD_NAME}
      title="How regularly do you shop?"
      options={REGULAR_SHOPPING_LIST}
      medium
      vertical
      {...rest}
    />
  );
};

PeriodicallyShopping.propTypes = {
  name: PropTypes.string,
};
PeriodicallyShopping.defaultProps = {
  name: undefined,
};

export default PeriodicallyShopping;
