import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { getTheme } from 'ageas-ui-themes';
import { ThemeProvider } from 'styled-components';
import GenericMotorFormPageWithPolicyFetch from './GenericMotorFormPageWithPolicyFetch';
import DriverConfirmDetails from './MotorYourDetailsFieldSets/DriverConfirmDetails';
import PolicyConfirmDetails from './MotorYourDetailsFieldSets/PolicyConfirmDetails';
import {
  isAnActualDriver,
  isAnOtherDriver,
  DRIVER_SPECIFIC_FIELDS,
  FIELD_NAMES,
  buildDriversList,
  schema,
  PreviousClaimsArrayModalContext,
  PreviousConvictionsArrayModalContext,
} from './motorYourDetailsHelper';

import StandardDropdownRadio from '../../components/Forms/StandardDropdownRadio/StandardDropdownRadio';

import { PolicyContext } from '../../helpers/policyContextHelper';
import { getErrorMessage } from '../../helpers/errorMessages';
import validateField from '../../helpers/validationHelper';
import VehicleConfirmDetails from './MotorYourDetailsFieldSets/VehicleConfirmDetails';
import StandardFormModalContext from '../../contexts/StandardFormModalContext/StandardFormModalContext';
import PreviousMotorClaimModalForm from '../../components/Forms/PreviousMotorClaim/PreviousMotorClaimModalForm';
import { brand } from '../../../data/whitelabel.config';
import OtherDriverConfirmDetails from './MotorYourDetailsFieldSets/OtherDriverConfirmDetails';
import PreviousConvictionModalForm from '../../components/Forms/PreviousConviction/PreviousConvictionModalForm';
import { FieldHeaderSpaced } from '../HomeClaimsWrapper/HomeClaimsWrapperStyle';

const Theme = getTheme(brand);

const driverDataFromContext = (drivers, driverId) =>
  !isAnActualDriver(driverId)
    ? undefined
    : drivers.find(driver => driver.id.toString() === driverId);

const ResetFormOnDriverChange = ({ newDriverCallback = () => {} }) => {
  const { values } = useFormState();
  const { change } = useForm();
  const { dataState } = useContext(PolicyContext);
  const initialDriverRef = useRef(false);

  // Mirror props/state/context to ref so we can access it in useEffect
  const stateRef = useRef();
  stateRef.current = {
    values,
    change,
    dataState,
    newDriverCallback,
  };

  // On driver Id change
  useEffect(() => {
    // Ignore initial render, we only care when it changes
    if (!initialDriverRef.current) {
      initialDriverRef.current = true;
      return;
    }

    // Driver ID has changed from a previous value, reset driver form fields
    DRIVER_SPECIFIC_FIELDS.forEach(field => {
      stateRef.current.change(field, undefined);
    });

    // Callback with new driver
    stateRef.current.newDriverCallback(values[FIELD_NAMES.DRIVER]);
  }, [values[FIELD_NAMES.DRIVER]]);

  return null;
};
ResetFormOnDriverChange.propTypes = {
  newDriverCallback: PropTypes.func.isRequired,
};

const ContextWrappers = ({ children }) => {
  return (
    <StandardFormModalContext Context={PreviousClaimsArrayModalContext}>
      <StandardFormModalContext Context={PreviousConvictionsArrayModalContext}>
        {children}
      </StandardFormModalContext>
    </StandardFormModalContext>
  );
};
ContextWrappers.propTypes = {
  children: PropTypes.node,
};
ContextWrappers.defaultProps = {
  children: undefined,
};

const Modals = () => {
  const {
    modalState: previousClaimModalState,
    closeModal: previousClaimModalClose,
  } = useContext(PreviousClaimsArrayModalContext);
  const {
    modalState: previousConvictionModalState,
    closeModal: previousConvictionModalClose,
  } = useContext(PreviousConvictionsArrayModalContext);

  return (
    <ThemeProvider theme={Theme}>
      {previousClaimModalState && (
        <PreviousMotorClaimModalForm
          onCancel={previousClaimModalClose}
          {...previousClaimModalState}
        />
      )}
      {previousConvictionModalState && (
        <PreviousConvictionModalForm
          onCancel={previousConvictionModalClose}
          {...previousConvictionModalState}
        />
      )}
    </ThemeProvider>
  );
};

const validateFields = (value, _allValues, meta) => {
  let error;

  // Standard validation - mandatory fields
  if (schema[meta.name]) {
    error = validateField(value, meta, schema, false);
    if (error) {
      return getErrorMessage(error);
    }
  }

  return undefined;
};

const PAGE_SECTION_NAME = 'yourDetails';

const DriverSection = ({ drivers }) => {
  const { values } = useFormState({ subscription: { values: true } });
  const driver = values[FIELD_NAMES.DRIVER];
  // When driver changes, fields with the same name that are still rendered
  // may still show their original value despite form state showing the value
  // is correctly cleared (believed to be browser issue)
  // To work around this, ensure there is a period where driver fields are not
  // rendered, by requiring an additional state update.
  // Driver changes, lastDriver now mismatches with current driver and fields
  // stop rendering. <ResetFormOnDriverChange> detects the driver change,
  // clears driver field values and then updates lastDriver so driver fields
  // start rendering.
  const [lastDriver, setLastDriver] = useState(driver);

  const currentDriver = useMemo(
    () => ({
      data: driverDataFromContext(drivers, driver),
      isActual: isAnActualDriver(driver),
      isOther: isAnOtherDriver(driver),
    }),
    [drivers, driver],
  );

  return (
    <>
      <ResetFormOnDriverChange newDriverCallback={setLastDriver} />

      {lastDriver === driver && (
        <>
          {currentDriver.isActual && (
            <DriverConfirmDetails
              driverData={currentDriver.data}
              validateFields={validateFields}
            />
          )}
          {currentDriver.isOther && (
            <OtherDriverConfirmDetails validateFields={validateFields} />
          )}
        </>
      )}
    </>
  );
};

DriverSection.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};
DriverSection.defaultProps = {
  drivers: undefined,
};

const MotorYourDetails = () => {
  const { dataState: policyDataContext } = useContext(PolicyContext);
  const policyDetails = { ...policyDataContext.policyDetails };

  // Check if policyDetails context contains required data
  const setFieldDefaultsFromPolicyContext = useCallback(
    (newPolicyDetails = policyDetails) => {
      return !!newPolicyDetails?.data?.drivers;
    },
    [policyDetails],
  );

  const driversOptions = useMemo(
    () => buildDriversList(policyDetails?.data?.drivers),
    [policyDetails?.data?.drivers],
  );

  return (
    <ContextWrappers>
      <Modals />
      <GenericMotorFormPageWithPolicyFetch
        pageSectionName={PAGE_SECTION_NAME}
        setFieldDefaultsFromPolicyContext={setFieldDefaultsFromPolicyContext}
        breadcrumbId="yourDetails"
        pageTitle="Your Details"
        nextPage="/motorclaim/newclaim/vehicledamage"
        previousPage="/motorclaim/newclaim/contactdetails"
      >
        <FieldHeaderSpaced>
          We need to confirm the details held on the policy are correct.
        </FieldHeaderSpaced>

        <StandardDropdownRadio
          name={FIELD_NAMES.DRIVER}
          label="Who was driving the vehicle at the time of the incident?"
          validate={validateFields}
          maxButtons={4}
          options={driversOptions}
        />
        <DriverSection drivers={policyDetails?.data?.drivers} />

        <PolicyConfirmDetails
          policyData={policyDetails?.data}
          validateFields={validateFields}
        />
        <VehicleConfirmDetails
          policyData={policyDetails?.data}
          validateFields={validateFields}
        />
      </GenericMotorFormPageWithPolicyFetch>
    </ContextWrappers>
  );
};

export default MotorYourDetails;
